import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import BankAccountIndex from "./tms-sm-ral-fdr-inner-pages/index-pages/bank-account";
import FdrCreationIndex from "./tms-sm-ral-fdr-inner-pages/index-pages/fdr-index";
import InvestmentIndex from "./tms-sm-ral-fdr-inner-pages/index-pages/investment";
import AccountCategoryIndex from "./tms-sm-ral-fdr-inner-pages/index-pages/account-category";
const TmsSmRalFdr = ({ router }) => {
  const [active, setActive] = useState("Creation");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (
      location === tmsRouteMap.sm_ral_fdr_account_creation ||
      location === tmsRouteMap.sm_ral_fdr
    ) {
      setActive("Account Category");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_ral_fdr_bank_account_creation)
    ) {
      setActive("Bank Account Creation");
      return;
    } else if (location.includes(tmsRouteMap.sm_ral_fdr_investment_creation)) {
      setActive("Investment Creation");
      return;
    } else if (location.includes(tmsRouteMap.sm_ral_fdr_fdr_creation)) {
      setActive("FDR Creation");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Account Category",
      Element: <AccountCategoryIndex router={router} />,
      key: "Account Category",
      route: tmsRouteMap.sm_ral_fdr_account_creation,
    },
    {
      name: "Bank Account Creation",
      Element: <BankAccountIndex router={router} />,
      key: "Bank Account Creation",
      route: tmsRouteMap.sm_ral_fdr_bank_account_creation,
    },
    {
      name: "Investment Creation",
      Element: <InvestmentIndex router={router} />,
      key: "Investment Creation",
      route: tmsRouteMap.sm_ral_fdr_investment_creation,
    },
    {
      name: "FDR Creation",
      Element: <FdrCreationIndex router={router} />,
      key: "FDR Creation",
      route: tmsRouteMap.sm_ral_fdr_fdr_creation,
    },
  ];

  return (
    <TmsTabsLayout
      components={components}
      active={active}
      setActive={setActive}
    />
  );
};
export default withRouter(TmsSmRalFdr);
