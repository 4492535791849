import React, { useEffect, useState } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";

import TmsUserService from "../../../services/tms-user/tms-user.service";
import moment from "moment";
import { Button, Col, Divider, Row, Statistic } from "antd";
import {
  TmsTable,
  TmsInput,
  TmsSelect,
} from "../../../components/tms-customs/index";

const UserBookingHistoryTable = ({ userId, userStats, userStatsCount }) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(20);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("success");
  const [upcoming, setUpcoming] = useState("all");
  const [templeId, setTempleId] = useState("");
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const fetchBooking = async () => {
    setLoading(true);
    console.log(userId);
    let res = await TmsUserService.userOrderStats(
      userId,
      tableCurrentPage,
      tablePageSize,
      paymentStatus,
      upcoming
    );
    setTableData(res?.items ?? []);
    setTabelTotalSize(res?.meta?.totalItems);
    setLoading(false);
  };
  useEffect(() => {
    userId && fetchBooking();
  }, [tableCurrentPage, tablePageSize, userId]);
  const columns = [
    {
      title: "Ticket ID",
      dataIndex: "ticketId",
      key: "ticketId  ",
    },
    {
      title: "Temple",
      dataIndex: "templeDisplayName",
      key: "templeDisplayName",
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
    },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
    },

    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      render: (record) => moment(record).format("DD-MM-YYYY"),
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      render: (record) => moment(record).format("DD-MM-YYYY"),
    },
    { title: "Amount", dataIndex: "onlineCartItemAmount" },
    {
      title: "Partner",
      dataIndex: "partnerCode",
      render: (record) => record ?? "-",
    },
  ];
  return (
    <>
      <h3 className="mt-10">
        User Stats{" "}
        <span style={{ fontSize: "12px", fontWeight: "400" }}>
          ( * past one year)
        </span>
      </h3>
      <Divider />

      {userStats && (
        <>
          <Row gutter={24} justify="center">
            <Col span={3}>
              <Statistic title="Sevas" value={userStats?.seva} />
            </Col>
            <Col span={3}>
              <Statistic title="Darshanam" value={userStats?.darshanam} />
            </Col>
            <Col span={3}>
              <Statistic
                title="Accommodation"
                value={userStats?.accommodation}
              />
            </Col>
            <Col span={3}>
              <Statistic title="Publication" value={userStats?.publication} />
            </Col>

            <Col span={3}>
              <Statistic title="Donation" value={userStats?.donation} />
            </Col>
            <Col span={3}>
              <Statistic
                title="Success Count"
                value={userStatsCount?.success}
              />
            </Col>

            <Col span={3}>
              <Statistic
                title="Failure Count"
                value={userStatsCount?.failure}
              />
            </Col>
          </Row>
        </>
      )}
      <Divider />
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={4}
          selectClass="w-100 ml-0"
          label="Payment Status"
          setValue={setPaymentStatus}
          value={paymentStatus}
          data={[
            { id: "created", name: "created" },
            { id: "success", name: "success" },
            { id: "failure", name: "failure" },
            { id: "refund", name: "refund" },
            { id: "canceled", name: "canceled" },
          ]}
          optional={true}
        />
        <TmsSelect
          type="col"
          span={4}
          selectClass="w-100 ml-0"
          label="Transactions"
          setValue={setUpcoming}
          value={upcoming}
          data={[
            { id: "Upcoming", name: "Upcoming" },
            { id: "all", name: "All" },
          ]}
          optional={true}
        />
        <TmsInput
          type="number"
          label={"Temple Id"}
          value={templeId}
          setValue={setTempleId}
          span={4}
          placeholder={"Enter Temple Id"}
          disabled={false}
        />
        <Col>
          <Row
            justify="start"
            style={{ marginBottom: "5px", color: "transparent" }}
          >
            .
          </Row>
          <Row justify="start">
            <Button loading={loading} type="primary" onClick={fetchBooking}>
              Fetch
            </Button>
          </Row>
        </Col>
      </Row>

      <br />
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={tableData ?? []}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: 20,
          pageSizeOptions: [10, 20],
        }}
      />
    </>
  );
};

export default withRouter(UserBookingHistoryTable);
