import axios from "axios";

const headers = {
  Accept: "application/json, text/javascript, */*; q=0.01",
  "Accept-Language": "en-US,en;q=0.9",
  Connection: "keep-alive",
  "Content-Type": "application/json; charset=UTF-8",
  Origin: "null",
  "Sec-Fetch-Dest": "empty",
  "Sec-Fetch-Mode": "cors",
  "Sec-Fetch-Site": "cross-site",
};

export const checkConnection = async () => {
  const res = await axios.get("http://localhost:8004/mfs100/info", {
    headers: headers,
  });
  return res;
};

export const captureBiometric = async () => {
  let data = '{"Quality":60,"TimeOut":10}';
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "http://localhost:8004/mfs100/capture",
    headers: headers,
    data: data,
  };
  const res = await axios.request(config);

  return res;
};

export const matchBiometric = async (IsoTemplate) => {
  let data = `{"Quality":60,"TimeOut":10,"BioType": "FMR","GalleryTemplate":"${IsoTemplate}"}`;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "http://localhost:8004/mfs100/match",
    headers: headers,
    data: data,
  };
  const res = await axios.request(config);
  return res;
};

export const verifyBiometric = async (OldIsoTemplate, NewIsoTemplate) => {
  let data = `{"Quality":60,"TimeOut":10,"BioType": "FMR","GalleryTemplate":"${OldIsoTemplate}","ProbTemplate":"${NewIsoTemplate}"}`;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "http://localhost:8004/mfs100/verify",
    headers: headers,
    data: data,
  };
  const res = await axios.request(config);
  return res;
};
