import { Button, Card, Col, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsAddOnService from "../../../../../services/tms-addons.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsDarshanamService from "../../../../../services/tms-darshanam/tms-darshanam.service";
import TmsPrasadamService from "../../../../../services/tms-prasadam.service";
import TmsSevasService from "../../../../../services/tms-sevas.service";
import TmsAccommodationService from "../../../../../services/tms-accommodation.service";

const TmsAddOnsInnerPagesAND = ({
  router,
  editFlag,
  productId,
  setEditFlag,
  viewAddOnsData,
  settmsModalVisible,
}) => {
  const [loading, setLoading] = useState(false);

  const [serviceType, setServiceType] = useState("DARSHANAM");
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [servicetypeData, setServicetypeData] = useState([]);

  const [addOnType, setAddOnType] = useState("PRASADAM");
  const [selectedAddOnType, setSelectedAddOnType] = useState("");
  const [addOnTypeData, setAddOnTypeData] = useState([]);

  // const [counterEnabled, setcounterEnabled] = useState(false);
  // const [onlineEnabled, setonlineEnabled] = useState(false);
  // const [price, setprice] = useState("");
  // const [fileList, setFileList] = useState([]);
  // const [changeRequestFile, setChangeRequestFile] = useState([]);
  // const [changeRequestDesc, setChangeRequestDesc] = useState("");

  const ServiceTypes = {
    SEVA: TmsSevasService.getSevas,
    PRASADAM: TmsPrasadamService.getPrasadam,
    DARSHANAM: TmsDarshanamService.getDarshanam,
    ACCOMMODATION: TmsAccommodationService.getAllBlocks,
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (
      selectedServiceType === "" ||
      selectedAddOnType === "" ||
      serviceType === "" ||
      addOnType === ""

      // fileList.length === 0
    ) {
      notification.error({ message: "Select all the fields" });
      setLoading(false);

      return;
    }
    // if (editFlag) {
    //   if (changeRequestFile?.length === 0 || changeRequestDesc === "") {
    //     notification.error({ message: "Fill all the fields" });
    //     setLoading(false);

    //     return;
    //   }
    // }
    let body = {
      parentType: serviceType,
      parentId: selectedServiceType,
      childType: addOnType,
      childId: selectedAddOnType,
    };
    // if (editFlag) {
    //   body.changeRequestPdfUrl = changeRequestFile[0].url;
    //   body.changeDescription = changeRequestDesc;
    // }
    try {
      if (editFlag) {
        await TmsAddOnService.editAddOns(productId, body);
        notification.success({ message: "Add-On Edited Successful" });
        setEditFlag(false);
        setLoading(false);
      } else {
        await TmsAddOnService.createAddOns(body);
        notification.success({ message: "Add-On Created Successful" });
        handleFuction();
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    setServiceType("");
    setAddOnType("");

    setSelectedAddOnType("");
    setSelectedServiceType("");

    setAddOnTypeData([]);
    setServicetypeData([]);

    // setcounterEnabled(false);
    // setonlineEnabled(false);
    // setFileList([]);
    // setChangeRequestFile([]);
    // setChangeRequestDesc("");
    setLoading(false);
  };
  useEffect(() => {
    if (editFlag) {
      setSelectedAddOnType(viewAddOnsData.childType);
      setSelectedServiceType(viewAddOnsData.parentType);
      setAddOnType(viewAddOnsData.childId);
      setServiceType(viewAddOnsData.parentId);
      // setcounterEnabled(viewAddOnsData.counterEnabled);
      // setonlineEnabled(viewAddOnsData.onlineEnabled);
    }
  }, [viewAddOnsData]);

  useEffect(() => {
    if (serviceType !== "" && serviceType) {
      getServiceData("service");
    }
  }, [serviceType]);

  useEffect(() => {
    if (addOnType !== "" && addOnType) {
      getServiceData("addon");
    }
  }, [addOnType]);

  const getServiceData = async (type) => {
    try {
      const data = type === "service" ? serviceType : addOnType;
      const res = await ServiceTypes[data](1, 300);
      if (type === "service") {
        setServicetypeData(res.items);
        setSelectedServiceType("");
      } else {
        setAddOnTypeData(res.items);
        setSelectedAddOnType("");
      }
    } catch (e) {
      catchErrorFunction(e, "Token expired", router);
    }
  };

  return (
    <Row style={{ width: "100%", minWidth: "600px" }}>
      <Card style={{ minWidth: "700px" }}>
        <Row className="formRow">
          <label className="formLabel">Service type</label>
          <Select
            size="medium"
            className="formInput"
            showSearch
            placeholder="Select a Seva Type"
            onChange={(e) => {
              setServiceType(e);
            }}
            value={serviceType}
          >
            <Select.Option value="SEVA">Seva</Select.Option>
            <Select.Option value="DARSHANAM">Darshanam</Select.Option>
            <Select.Option value="PRASADAM">Prasadam</Select.Option>
            <Select.Option value="ACCOMMODATION">Accommodation</Select.Option>
          </Select>
        </Row>
        <Row className="formRow">
          <label className="formLabel">Service Name</label>
          <Select
            size="medium"
            className="formInput"
            showSearch
            placeholder="Select a Seva Type"
            onChange={(e) => {
              setSelectedServiceType(e);
            }}
            value={selectedServiceType}
          >
            {servicetypeData?.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name ?? item.description}
                  {item.sevaType && `- (${item.sevaType})`}
                </Select.Option>
              );
            })}
          </Select>
        </Row>

        <Row className="formRow">
          <label className="formLabel">Add-On Type</label>
          <Select
            size="medium"
            className="formInput"
            showSearch
            placeholder="Select a Add-On Type"
            onChange={(e) => {
              setAddOnType(e);
            }}
            value={addOnType}
          >
            <Select.Option value="SEVA">Seva</Select.Option>
            <Select.Option value="DARSHANAM">Darshanam</Select.Option>
            <Select.Option value="PRASADAM">Prasadam</Select.Option>
            <Select.Option value="ACCOMMODATION">Accommodation</Select.Option>
          </Select>
        </Row>
        <Row className="formRow">
          <label className="formLabel">Add-On Name</label>
          <Select
            size="medium"
            className="formInput"
            showSearch
            placeholder="Select a Seva Type"
            onChange={(e) => {
              setSelectedAddOnType(e);
            }}
            value={selectedAddOnType}
          >
            {addOnTypeData?.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name ?? item.description}
                  {item.sevaType && `- (${item.sevaType})`}
                </Select.Option>
              );
            })}
          </Select>
        </Row>

        {/* <Row gutter={[20, 20]} className="formRow" justify="center">
          <label style={{ paddingRight: "10px" }}>Enable POS</label>
          <Checkbox
            style={{ paddingRight: "10px" }}
            checked={counterEnabled}
            onChange={(e) => setcounterEnabled(e.target.checked)}
          />
          <label style={{ paddingRight: "10px" }}>Enable Online</label>
          <Checkbox
            checked={onlineEnabled}
            onChange={(e) => setonlineEnabled(e.target.checked)}
          />
        </Row> */}

        {/* {editFlag && (
          <>
            <Row gutter={[20, 20]} className="formRow">
              <label className="formLabel">Request Copy</label>
              <Col className="formInput ">
                <TmsImageUpload
                  fileList={changeRequestFile}
                  setFileList={setChangeRequestFile}
                  router={router}
                  type="private"
                  accept={true}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="formRow">
              <label className="formLabel">Edit Description</label>
              <Input
                className="formInput"
                value={changeRequestDesc}
                onChange={(e) => setChangeRequestDesc(e.target.value)}
              />
            </Row>
          </>
        )} */}
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsAddOnsInnerPagesAND);
