import React, { useEffect, useState } from "react";
import TmsCalender from "../../../../components/tms-customs/tms-calender";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsAddonsInnerPagesAll from "./tms-sm-addons-inner-pages/tms-addons-inner-pages-all";
import TmsAddonsInnerPagesAnd from "./tms-sm-addons-inner-pages/tms-addons-inner-pages-and";

const TmsSmAddOns = ({ router }) => {
  const [active, setActive] = useState("all");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_addOns) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sm_addons_add_new_addon)) {
      setActive("Add New Add-On");
    } else if (location.includes(tmsRouteMap.sm_addons_manage_slots)) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sm_addons_manage_counters)) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sm_addons_manage_quota)) {
      setActive("all");
      return;
    }
  }, [router?.location?.pathname]);
  const location = router?.location?.pathname;
  const components = [
    {
      name: location.includes(tmsRouteMap.sm_addons_manage_counters)
        ? "(Slots)-Manage Counter"
        : location.includes(tmsRouteMap.sm_addons_manage_slots)
        ? "Manage Slots"
        : location.includes(tmsRouteMap.sm_addons_manage_quota)
        ? "Manage Online Quota"
        : "All",
      Element: location?.includes(tmsRouteMap.sm_addons_manage_quota) ? (
        <TmsCalender />
      ) : location?.includes(tmsRouteMap.sm_addons_manage_quota) ? (
        <TmsCmsSlots type="addons-counters" />
      ) : location?.includes(tmsRouteMap.sm_addons_manage_slots) ? (
        <TmsCmsSlots type="addons-slots" />
      ) : (
        <TmsAddonsInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_addOns,
    },

    {
      name: "Add New Add-On",
      Element: <TmsAddonsInnerPagesAnd />,
      key: "Add New Add-On",
      route: tmsRouteMap.sm_addons_add_new_addon,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmAddOns);
