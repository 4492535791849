import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsAddOnService = {
  getAddOns: async (page, limit) => {
    const resp = await Axios.get(`v1/add-on?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  createAddOns: async (body) => {
    const resp = await Axios.post(`v1/add-on`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteAddOns: async (productId) => {
    const resp = await Axios.delete(`v1/add-on/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewAddOns: async (productId) => {
    const resp = await Axios.get(`v1/add-on/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  editAddOns: async (productId, body) => {
    const resp = await Axios.put(`v1/add-on/update/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  //addons-slots
  getAddOnsSlots: async (id) => {
    const resp = await Axios.get(`v1/darshanam-slot/product-slots/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  createAddOnsSlots: async (body) => {
    const resp = await Axios.post(`v1/darshanam-slot/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editAddOnsSlots: async (body, slotId) => {
    const resp = await Axios.put(`v1/darshanam-slot/update/${slotId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  deleteAddOnsSlots: async (slotId) => {
    const resp = await Axios.delete(`v1/darshanam-slot/delete/${slotId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateTerms: async (productId, termsData, ticketType) => {
    const resp = await Axios.patch(
      `v1/add-on/metadata/${productId}`,
      {
        termsAndConditions: termsData,
        ticketType: ticketType ? "mini_ticket" : "full_ticket",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  //darshanam-slots-counters

  //darshanam-slots
  getAddOnsSlotCounter: async (id) => {
    const resp = await Axios.get(
      `v1/darshanam-counter-slot/by-product-slot-id/${id}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  createAddOnsSlotCounter: async (body) => {
    const resp = await Axios.post(`v1/darshanam-counter-slot/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editAddOnsSlotCounter: async (body, slotId) => {
    const resp = await Axios.put(
      `v1/darshanam-counter-slot/update/${slotId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteAddOnsSlotCounter: async (slotId) => {
    const resp = await Axios.delete(
      `v1/darshanam-counter-slot/delete/${slotId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //darshanam booking

  fetchAddOnsBookingSlots: async (tickets) => {
    const resp = await Axios.get(
      `v1/darshanam-counter-booking/valid-counter-slots?no_of_tickets=${tickets}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  darshanamBooking: async (body) => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        darshanamBookings: body,
        multiTicketsLinked: false,
        paymentType: "CASH",
        cashRemarks: "string",
        cashReference: "string",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  darshanamReport: async (
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    dateType,
    sortType,
    counter,
    shift,
    slot,
    darshanamType
  ) => {
    let query = `v1/darshanam-counter-report/booking?format=${format}&page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (slot && slot !== "") {
      query += `&darshanamSlotId=${slot}`;
    }
    if (darshanamType && darshanamType !== "") {
      query += `&productId=${darshanamType}`;
    }
    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  darshanamReportOnline: async (
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    dateType,
    sortType,
    counter,
    shift,
    slot,
    darshanamType
  ) => {
    let query = `v1/darshanam-online-report/booking?format=${format}&page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (slot && slot !== "") {
      query += `&darshanamSlotId=${slot}`;
    }
    if (darshanamType && darshanamType !== "") {
      query += `&productId=${darshanamType}`;
    }
    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  //disable-dates
  fetchdisabledates: async (productId, page, limit) => {
    const resp = await Axios.get(
      `v1/add-on/disable-date/by-product-id/${productId}?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createDisableDates: async (
    productIds,
    startDate,
    endDate,
    description,
    onlineDisabled,
    counterDisabled
  ) => {
    const resp = await Axios.post(
      `v1/add-on/disable-date/create`,
      {
        productIds: productIds,
        startDate: startDate,
        endDate: endDate,
        description: description,
        onlineDisabled: onlineDisabled,
        counterDisabled: counterDisabled,
        protocolDisabled: false,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteDisableDate: async (id) => {
    const resp = await Axios.delete(`v1/add-on/disable-date/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
  }) => {
    let query = `v1/darshanam-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter) {
      query += `&counterId=${counter}`;
    }
    if (shift) {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  getOnlineConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    groupBy,
  }) => {
    let query = `v1/darshanam-online-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  getQuotaData: async ({ from, to, productIds }) => {
    const resp = await Axios.get(
      `v1/darshanam-slot-daily-quota?fromDate=${from}&toDate=${to}&productSlotId=${productIds}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateQuotaData: async (body) => {
    const res = await Axios.post(`v1/darshanam-slot-daily-quota`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
};

export default TmsAddOnService;
