import { Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import useRentsAndLandStore from "../../../../../zustand/rents-and-leases.store";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import {
  additionalArrearStates,
  arrearStates,
} from "../../../../../constants/states-data.data";
import { InstitutionSelect } from "../../../../../components/tms-customs/rents-and-leases/index";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";

const TmsRalArrearsReports = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  // const [sortType, setSortType] = useState("ASC");
  // const [sortBy, setSortBy] = useState("createdAt");
  const {
    selectedInstitution,
    loading,
    fetchArrearReports,
    fetchInstitutionList,
    arrearReport,
    clearStates,
    fetchTotalReports,
  } = useRentsAndLandStore((state) => {
    return {
      selectedInstitution: state.selectedInstitution,
      loading: state.loading,
      fetchArrearReports: state.fetchArrearReports,
      fetchInstitutionList: state.fetchInstitutionList,
      clearStates: state.clearStates,
      fetchTotalReports: state.fetchTotalReports,
      arrearReport: state.arrearReport,
    };
  }, shallow);

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };
  useEffect(() => {
    return () => {
      clearStates(arrearStates, additionalArrearStates);
    };
  }, []);

  useEffect(() => {
    router.location.pathname === tmsRouteMap.reports_ral_lands_arrears &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (router.location?.pathname === tmsRouteMap.reports_ral_lands_arrears)
      if (selectedInstitution !== "" && selectedInstitution) {
        fetchArrearReports(
          tableCurrentPage,
          tablePageSize,
          // sortType,
          // sortBy,
          router
        );
      } else {
        fetchTotalReports("ARREAR", tableCurrentPage, tablePageSize, router);
      }
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedInstitution,
    router.location?.pathname,
  ]);

  const innerArrearColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Name of Temple",
      dataIndex: "institutionName",
      key: "institutionName",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Nature of Land",
      dataIndex: "natureOfLand",
      key: "natureOfLand",
    },
    {
      title: "Survey No.",
      dataIndex: "surveyNumber",
      key: "surveyNumber",
    },
    {
      title: "Extent(Acres)",
      dataIndex: "extent",
      key: "extent",
    },
  ];
  const totalArrearColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Institution",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Village",
      dataIndex: "village",
      key: "village",
    },

    {
      title: "Arrear Amount",
      dataIndex: "arrearAmount",
      key: "arrearAmount",
    },
  ];
  return (
    <>
      <Row justify="flex-start">
        <InstitutionSelect span={6} />
        {/* <TmsSelect
          selectClass="w-100 "
          classname="mr-20 align-center"
          labelClass="mr-10 mb-0"
          type="col"
          value={sortBy}
          setValue={setSortBy}
          label="Sort By:"
          data={sortByData}
          span={5}
        /> */}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        loading={loading}
        style={{
          width: "100%",
          textTransform: "capitalize",
        }}
        columns={
          selectedInstitution !== "" && selectedInstitution
            ? innerArrearColumns.map((item) => {
                return { ...item, align: "center" };
              })
            : totalArrearColumns.map((item) => {
                return { ...item, align: "center" };
              })
        }
        dataSource={arrearReport?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: arrearReport?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsRalArrearsReports);
