import React from "react";
import { Table } from "antd";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";

const TmsTable = ({
  loading,
  dataSource,
  columns,
  // eslint-disable-next-line no-unused-vars
  searchable,
  expandable,
  pagination,
  handleTableChange,
  rowSelection,
  bordered,
  fontSize,
  scroll,
  className,
}) => {
  return (
    <Table
      scroll={scroll ? scroll : { x: true }}
      style={{ width: "100%", textTransform: "capitalize" }}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      size="small"
      onChange={handleTableChange && handleTableChange}
      expandable={expandable && expandable}
      pagination={pagination && pagination}
      rowSelection={rowSelection && rowSelection}
      bordered={bordered && bordered}
      rowClassName={fontSize ? "f-12" : "f-13"}
      className={className ?? ""}
    ></Table>
  );
};

export default withRouter(TmsTable);
