import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Button, Drawer, Row } from "antd";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import TmsPublicationFMANF from "./tms-publication-fm-add";
import TmPublicationFileManagementActions from "../../../../../components/tms-service-management/tms-sm-publication/tms-publication-file-management-actions";
import TmsPublicationService from "../../../../../services/tms-publications.service";

const TmsPublicationFileMangement = ({ router }) => {
  const [updateFlag, setUpdateFlag] = useState("");
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewProductData, setViewProductData] = useState();
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState();
  const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [product, setProduct] = useState("");
  const colors = ["green", "blue", "red", "yellow", "violet", "orange"];

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    if (
      router.location?.pathname === tmsRouteMap.sm_publication_manage_files &&
      product !== ""
    )
      getPublications();
  }, [
    product,
    updateFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  useEffect(() => {
    let id = new URLSearchParams(router.location.search).get("id");
    // if (!id) {
    //   router.navigate(tmsRouteMap.serviceManagement_products);
    // } else {
    setProduct(id);
    // }
  }, []);

  const getPublications = async () => {
    setLoading(true);
    try {
      const data = await TmsPublicationService.getPublicationsFiles(
        product,
        tableCurrentPage,
        tablePageSize
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setProductData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return (
          <div
            key={text}
            style={{
              border:
                text === "purchase"
                  ? "1px solid blue"
                  : text === "subscription"
                  ? "1px solid #FC4B35"
                  : "1px solid green",
              padding: "3px 8px",
              margin: "0px 5px 5px 0px",
              borderRadius: "4px",
              color:
                text === "purchase"
                  ? "blue"
                  : text === "subscription"
                  ? "#FC4B35"
                  : "green",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Language Option",
      dataIndex: "language",
      key: "language",
      render: (record) => {
        return (
          <div style={{ display: "flex", maxWidth: "40vw", flexWrap: "wrap" }}>
            {record?.map((item, i) => (
              <div
                key={i}
                style={{
                  margin: "0px 5px 5px 0px",
                  padding: "0px 5px",
                  border: `1px solid ${colors[i]}`,
                  color: colors[i],
                  borderRadius: "4px",
                }}
              >
                {item}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price > b.price,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },
    {
      title: "Delivery Fee",
      dataIndex: "deliveryPrice",
      key: "deliveryPrice",
      sorter: (a, b) => a.deliveryPrice > b.deliveryPrice,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <TmPublicationFileManagementActions
            record={record}
            setLoading={setLoading}
            setViewProductData={setViewProductData}
            settmsModalVisible={settmsModalVisible}
            setUpdateFlag={setUpdateFlag}
            setEditFlag={setEditFlag}
            setProductId={setProductId}
          />
        );
      },
    },
  ];

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            setAddFlag(true);
          }}
          size="small"
          style={{ padding: "0 13px", height: "30px" }}
        >
          Add New File
        </Button>{" "}
        <label>
          {new URLSearchParams(router.location.search).get("name") ?? name}
        </label>
      </Row>
      <br />
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={productData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <Drawer
        className="DetailsModal"
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewProductData(null);
        }}
        title={editFlag ? "Edit Product" : "Product Details"}
      >
        {editFlag && (
          <TmsPublicationFMANF
            setUpdateFlag={setUpdateFlag}
            viewProductData={viewProductData}
            editFlag={editFlag}
            productId={productId}
            shopProductId={product}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
            width={true}
            setAddFlag={setAddFlag}
          />
        )}
      </Drawer>

      <Drawer
        className="DetailsModal"
        width={"800px"}
        visible={addFlag}
        onClose={() => {
          setAddFlag(false);
          setViewProductData(null);
        }}
        title={"Add a Publication type"}
      >
        <TmsPublicationFMANF
          setUpdateFlag={setUpdateFlag}
          viewProductData={viewProductData}
          editFlag={editFlag}
          productId={productId}
          shopProductId={product}
          addFlag={addFlag}
          setAddFlag={setAddFlag}
          width={true}
        />
      </Drawer>
    </>
  );
};

export default withRouter(TmsPublicationFileMangement);
