import { Layout } from "antd";
import React, { useState } from "react";
import { Route, Routes } from "react-router";
import tmsRouteMap from "../constants/tms-route.map";
import { withRouter } from "../utils/tms-with-router/tms-with-router";
import TmsSmManualEntry from "../views/tms-service-management/tms-service-management-pages/tms-sm-manual-entry/tms-sm-manual-entry";
import TmsSmManualEntryCategory from "../views/tms-service-management/tms-service-management-pages/tms-sm-manual-entry/tms-sm-manual-entry-category";
const { Content } = Layout;

const TmseManualEntryRoutes = () => {
  const [categoryData, setCategoryData] = useState([]);

  return (
    <Layout>
      <Content>
        <Routes>
          <Route
            exact
            path={"/"}
            element={<TmsSmManualEntry setCategoryData={setCategoryData} />}
          />
          <Route
            exact
            path={
              tmsRouteMap.serviceManagement_manual_entry_category?.replace(
                "/service-management/manual-entry",
                ""
              ) + "/*"
            }
            element={<TmsSmManualEntryCategory categoryData={categoryData} />}
          />
        </Routes>
      </Content>
    </Layout>
  );
};

export default withRouter(TmseManualEntryRoutes);
