import Axios from "../utils/tms-axios/tms-axios";
const TmsCouponService = {
  getAllCoupons: async (page, limit) => {
    const resp = await Axios.get(`v1/coupon/all?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  createNewCoupon: async (body) => {
    const resp = await Axios.post("v1/coupon/new", body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });

    return resp.data;
  },

  editCoupon: async (body, couponId) => {
    const resp = await Axios.post(`v1/coupon/edit/${couponId}`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });

    return resp.data;
  },

  deleteCoupon: async (id) => {
    const resp = await Axios.delete(`v1/coupon/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};
export default TmsCouponService;
