import { Col, Modal, notification, Row, Select, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import TmsAccommodationCheckoutTable from "../../../components/tms-pos/tms-accommodation-dashboard/tms-accommodation-table";
import TmsSRShiftDetailsCard from "../../../components/tms-reports/tms-shift-reports/tms-sr-view-transactions-card";
import TmsAccommodationStats from "../../../components/tms-pos/tms-accommodation-dashboard/tms-accommodation-stats";
import "../../../assets/css/tms-accommodation/tms-accommodation-dashboard.scss";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsAccommodationService from "../../../services/tms-accommodation.service";
import { BaseContext } from "../../../contexts/BaseContext";
import TmsModal from "../../../components/tms-customs/tms-modal";
import TmsAccDashboardCheckOutRenew from "../../../components/tms-pos/tms-accommodation-dashboard/tms-acc-dashboard-checkOutRenew";
import moment from "moment/moment";
import TmsDatePickerRange from "../../../components/tms-customs/tms-datepicker-range";

const TmsPosAccommodationDashboard = ({ router }) => {
  const { userDetails, shiftData } = useContext(BaseContext);
  const [roomId, setRoomId] = useState();
  const [checkOutRenewModal, setCheckOutRenewModal] = useState(false);
  const [checkOutRenewFlag, setCheckOutRenewFlag] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [selectedBlockId, setSelectedBlockId] = useState("all");
  const [reportData, setReportData] = useState("");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [availabilityModalFlag, setAvailabilityModalFlag] = useState(false);
  const [availabilityRooomsData, setAvailabilityRoomsData] = useState([]);
  const [availabilityLabel, setAvailabilityLabel] = useState("");
  const [tableCurrentLivePage, setTabelCurrentLivePage] = useState(1);
  const [tableLivePageSize, setTabelLivePageSize] = useState(15);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [page, setPage] = useState(0);
  const [checkOutRenewDatas, setCheckOutRenewDatas] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  // eslint-disable-next-line no-unused-vars
  const handleLiveTableChange = (pagination, filters, sorter) => {
    setTabelCurrentLivePage(pagination.current);
    setTabelLivePageSize(pagination.pageSize);
  };
  const getBlocks = async () => {
    try {
      if (
        userDetails?.permissions.includes("SUPER_ADMIN") ||
        userDetails?.permissions.includes("ADMIN")
      ) {
        let resp = await TmsAccommodationService.getAllBlocks(1, 100);
        setBlocks(resp?.items);
        // if (resp?.items[0]) {
        //   setSelectedBlockId(resp?.items[0]?.id);
        // }
      } else {
        let resp = await TmsAccommodationService.getCounterSpecificBlocks(
          shiftData?.counterId
        );
        let data = resp?.map((item) => {
          return { id: item.id, name: item.name };
        });
        setBlocks(data);
        // if (data[0]) {
        //   setSelectedBlockId(data[0].id);
        // }
      }
    } catch (error) {
      catchErrorFunction(error, "Token expired! Pleae login again.", router);
    }
  };

  const getReportData = async () => {
    if (!selectedBlockId) {
      return;
    }
    try {
      const reportData = await TmsAccommodationService.getRoomsStats(
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD"),
        selectedBlockId
      );
      setReportData(reportData);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again", router);
    }
  };
  const getLiveAvailableRoomsData = async (id, type) => {
    try {
      let res = await TmsAccommodationService.getLiveRoomsData(
        selectedBlockId,
        id,
        type
      );
      setAvailabilityRoomsData(res?.items);
      setAvailabilityModalFlag(true);
      setAvailabilityLabel(type);
    } catch (e) {
      setAvailabilityModalFlag(false);
      setAvailabilityLabel("");
      setAvailabilityRoomsData([]);
      notification.error({ message: e?.message });
    }
  };

  useEffect(() => {
    if (shiftData === "" || !shiftData) {
      let flag = permission();
      if (flag && userDetails) {
        getBlocks();
      }
    } else if (userDetails) {
      getBlocks();
    }
  }, [userDetails, shiftData]);

  // useEffect(() => {
  //   (async () => {
  //     if (
  //       userDetails?.role !== "admin" &&
  //       userDetails?.role !== "accommodation_read"
  //     ) {
  //       if (shiftData) {
  //         try {
  //           let res = await ShiftService.getCurrentShiftDetails();
  //           setUserShiftData(res);
  //         } catch (error) {
  //           catchErrorFunction(error, "Token expired!! Please login again.");
  //         }
  //       }
  //     } else {
  //       setUserShiftData("");
  //     }
  //   })();
  // }, [shiftData]);
  const getCheckoutTable = async () => {
    setLoading(true);
    if (!selectedBlockId) {
      setLoading(false);
      return;
    }
    try {
      const reportData = await TmsAccommodationService.getCheckoutReadyRooms(
        selectedBlockId
      );
      setList(
        reportData?.map((item) => {
          return { key: item?.roomBookingId, ...item };
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      catchErrorFunction(error, "Token expired!! Please login again.");
    }
  };

  const handleCancel = () => {
    setRoomId(undefined);
    setCheckOutRenewFlag("");
    setCheckOutRenewModal(false);
    setPage(0);
    setCheckOutRenewDatas([]);
    window?.location?.reload();
  };

  const checkOutRenewSubmit = (id, flag) => {
    setRoomId(id);
    setCheckOutRenewModal(true);
    setCheckOutRenewFlag(flag);
  };

  const permission = () => {
    return userDetails?.permissions.includes("SUPER_ADMIN") ||
      userDetails?.permissions.includes("ADMIN")
      ? true
      : false;
  };
  useEffect(() => {
    if (shiftData === "" || !shiftData) {
      let flag = permission();
      if (flag) {
        selectedBlockId !== "" && getReportData();
      }
    } else {
      selectedBlockId !== "" && getReportData();
    }
  }, [selectedBlockId, shiftData, fromDate, toDate, userDetails]);

  useEffect(() => {
    if (shiftData === "" || !shiftData) {
      let flag = permission();
      if (flag) {
        selectedBlockId !== "" && getCheckoutTable();
      }
    } else {
      selectedBlockId !== "" && getCheckoutTable();
    }
  }, [selectedBlockId, shiftData]);

  return !shiftData && !permission() ? (
    <div>
      <h1> Shift In To View Dashboard </h1>
    </div>
  ) : (
    <>
      <h2>Accommodation Dashboard</h2>
      <div style={{ background: "white", padding: "20px" }}>
        <Row gutter={[20, 10]}>
          <Col span={5}>
            <Row
              style={{ textAlign: "left", marginBottom: "5px", width: "100%" }}
            >
              Block Name
            </Row>

            <Select
              style={{ width: "100%" }}
              onChange={(e) => {
                setSelectedBlockId(e);
              }}
              value={selectedBlockId}
            >
              {blocks?.map((block) => (
                <Select.Option key={block?.id} value={block?.id}>
                  {block?.name}
                </Select.Option>
              ))}
              <Select.Option key={"all"} value={"all"}>
                All
              </Select.Option>
            </Select>
          </Col>
          <Col span={6}>
            <TmsDatePickerRange
              label="Select Occupancy Dates"
              to={toDate}
              from={fromDate}
              setFrom={setFromDate}
              setTo={setToDate}
              disabled={false}
              // disabledDate={false}
            />
          </Col>
          {shiftData !== "" && reportData !== "" ? (
            <Col span={13} style={{ display: "flex" }}>
              <Col span={4} className="col-padding">
                Total Rooms <br />
                <span style={{ fontSize: "22px" }}>
                  {reportData["TOTAL"]?.totalRooms ?? 0}
                </span>
              </Col>
              <Col span={7} className="col-padding">
                Total Available Rooms <br />
                <span style={{ fontSize: "22px" }}>
                  {reportData["TOTAL"]?.availableRooms ?? 0}
                </span>
              </Col>
              <Col span={6} className="col-padding">
                Total Booked Rooms <br />
                <span style={{ fontSize: "22px" }}>
                  {reportData["TOTAL"]?.occupiedRooms ?? 0}
                </span>
              </Col>
              <Col span={7} className="col-padding">
                Rooms Under Maintenance
                <br />
                <span style={{ fontSize: "22px" }}>
                  {reportData["TOTAL"]?.underMaintenanceRooms ?? 0}
                </span>
              </Col>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row
          style={{
            marginTop: "0px",
            justifyContent: "start",
            width: "100%",
          }}
        >
          {" "}
          <br />
          {shiftData !== "" && !permission() ? (
            <TmsSRShiftDetailsCard shiftData={shiftData} />
          ) : (
            ""
          )}
          {reportData !== "" && (
            <TmsAccommodationStats
              shiftData={reportData}
              getLiveAvailableRoomsData={getLiveAvailableRoomsData}
            />
          )}
          <br />
          {/* {Object.entries(reportData)?.map(([key, value]) => (
         <Card
            title={key}
            style={{
              width: "24%",
              marginBottom: "20px",
            }}
          >
            <Typography.Title style={{fontSize: "24px"}}>
              {value}
            </Typography.Title>
          </Card>
          ))}*/}
        </Row>
        <TmsAccommodationCheckoutTable
          list={list}
          loading={loading}
          setloading={setLoading}
          handleTableChange={handleTableChange}
          tableCurrentPage={tableCurrentPage}
          tablePageSize={tablePageSize}
          checkOutRenewSubmit={checkOutRenewSubmit}
        />
        <TmsModal
          visible={checkOutRenewModal}
          onCancel={() => handleCancel()}
          footer={false}
          centered
          width={600}
          closable={page === 3 ? false : true}
        >
          <TmsAccDashboardCheckOutRenew
            flag={checkOutRenewFlag}
            roomId={roomId}
            setCheckOutRenewModal={setCheckOutRenewModal}
            checkOutRenewModal={checkOutRenewModal}
            page={page}
            setPage={setPage}
            checkOutRenewDatas={checkOutRenewDatas}
            setCheckOutRenewDatas={setCheckOutRenewDatas}
          />
        </TmsModal>
        <Modal
          visible={availabilityModalFlag}
          onCancel={() => {
            setAvailabilityModalFlag(false);
            setAvailabilityRoomsData([]);
          }}
          footer={false}
          centered
          width={"70%"}
          height={"80%"}
        >
          <p style={{ fontWeight: "bold" }}>
            Block ID: {selectedBlockId} | Block Name:
            <span style={{ textTransform: "capitalize", margin: "0px 5px" }}>
              {selectedBlockId === "all"
                ? "All"
                : blocks?.find((item) => item.id === selectedBlockId)?.name}
            </span>
            | Room Type:
            <span style={{ textTransform: "capitalize", marginRight: "5px" }}>
              {availabilityLabel}
            </span>
            Rooms
          </p>
          <Table
            className={"accomadation_table"}
            tableLayout="fixed"
            scroll={{ x: true }}
            loading={loading}
            style={{ width: "100%", textTransform: "capitalize" }}
            columns={[
              {
                title: "S.no",
                key: "sno",
                width: "20px",
                render: (text, object, index) => index + 1,
              },
              {
                title: "Block Name",
                dataIndex: "block_name",
                key: "block_name",
              },
              {
                title: "Room Type",
                dataIndex: "room_type",
                key: "room_type",
              },
              {
                title: "Room Number",
                dataIndex: "room_number",
                key: "room_number",
              },
            ]}
            dataSource={availabilityRooomsData}
            onChange={handleLiveTableChange}
            pagination={{
              current: tableCurrentLivePage,
              pageSize: tableLivePageSize,
            }}
          ></Table>
        </Modal>
      </div>
    </>
  );
};

export default withRouter(TmsPosAccommodationDashboard);
