import React from "react";
import useFdrStore from "../../../../zustand/fdr.store";
import TmsSelect from "../../tms-select";

const BankAccountSelect = ({ span }) => {
  const { bankAccountData, setSelectedBankAccount, selectedBankAccount } =
    useFdrStore((state) => {
      return {
        bankAccountData: state.bankAccountData,
        setSelectedBankAccount: state.setSelectedBankAccount,
        selectedBankAccount: state.selectedBankAccount,
      };
    });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select a Bank "
      setValue={(e) => {
        setSelectedBankAccount(
          bankAccountData?.items?.find((item) => item.id === e)
        );
      }}
      selectClass="w-100"
      value={selectedBankAccount?.id}
      data={bankAccountData?.items?.map((item) => {
        return {
          name: `Name: ${item?.bankName}, Branch: ${item.branchName}`,
          id: item.id,
        };
      })}
      optional={true}
    />
  );
};

export default BankAccountSelect;
