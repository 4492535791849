import { Button, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import {
  additionalLicenseArrearStates,
  licenseLeaseCreationStates,
} from "../../../../../constants/states-data.data";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import useLicensesStore from "../../../../../zustand/licenses.store";
import { LicenseInstitutionSelect } from "../../../../../components/tms-customs/rents-and-leases";
import TmsDatepickerRange from "../../../../../components/tms-customs/tms-datepicker-range";

const TmsLicenseLeaseReports = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  // const [sortType, setSortType] = useState("ASC");
  // const [sortBy, setSortBy] = useState("createdAt");
  const {
    selectedInstitution,
    setSelectedInstitution,
    loading,
    fetchLeaseReports,
    fetchInstitutionList,
    leaseReports,
    clearStates,
    fetchTotalReports,
    licensePeriodStartDate,
    setLicensePeriodStartDate,
    setLicensePeriodEndDate,
    licensePeriodEndDate,
  } = useLicensesStore();

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };
  useEffect(() => {
    return () => {
      clearStates(licenseLeaseCreationStates, [
        ...additionalLicenseArrearStates,
        "leaseReports",
      ]);
    };
  }, []);
  useEffect(() => {
    (router.location.pathname === tmsRouteMap.reports_ral_licenses_leases ||
      router.location.pathname === tmsRouteMap.reports_ral_licenses) &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (router.location?.pathname === tmsRouteMap.reports_ral_licenses_leases) {
      if (selectedInstitution !== "" && selectedInstitution) {
        console.log("first");
        fetchLeaseReports(
          tableCurrentPage,
          tablePageSize,
          licensePeriodStartDate,
          licensePeriodEndDate,
          router
        );
      } else {
        fetchTotalReports("LEASE", tableCurrentPage, tablePageSize, router);
      }
    }
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedInstitution,
    licensePeriodStartDate,
    licensePeriodEndDate,
    router?.location?.pathname,
  ]);

  const innerLeaseColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    // {
    //   title: "Name of Temple",
    //   dataIndex: "institutionName",
    //   key: "institutionName",
    // },
    {
      title: "License",
      dataIndex: "licenseName",
      key: "licenseName",
    },
    {
      title: "Licensee Name",
      dataIndex: "licenseeName",
      key: "licenseeName",
    },
    {
      title: "License Fee",
      dataIndex: "licenseFee",
      key: "licenseFee",
    },

    {
      title: "Lease(From-To)",
      key: "lease",
      render: (record) =>
        `${moment(record.licensePeriodEndDate).format(
          "DD-MM-YYYY"
        )} to ${moment(record.licensePeriodStartDate).format("DD-MM-YYYY")}`,
    },
    { title: "Payment type", dataIndex: "paymentType", key: "paymentType" },
    { title: "Deposit ", dataIndex: "depositAmount" },
    // { title: "Arrear ", dataIndex: "arrearAmount" },
    // {
    //   title: "Balance ",
    //   render: (record) => `${record.arrearAmount - record.totalPaidAmount}`,
    // },

    { title: "Enchancement Value", dataIndex: "enhancementLicenseValue" },
  ];
  const totalLeaseColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Institution",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Village",
      dataIndex: "village",
      key: "village",
    },

    {
      title: "Total Lease Amount",
      dataIndex: "totalLeaseAmount",
      key: "totalLeaseAmount",
    },
    {
      title: "Total Arrear Amount",
      dataIndex: "totalArrearAmount",
      key: "totalArrearAmount",
    },
  ];
  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <LicenseInstitutionSelect span={6} />

        {selectedInstitution !== "" && (
          <>
            {" "}
            <TmsDatepickerRange
              label={"Range"}
              from={licensePeriodStartDate}
              to={licensePeriodEndDate}
              setFrom={setLicensePeriodStartDate}
              setTo={setLicensePeriodEndDate}
            />
            <Button
              style={{ marginTop: "27px", marginLeft: "10px" }}
              onClick={() => {
                setSelectedInstitution("");
              }}
              type="primary"
            >
              Clear
            </Button>
          </>
        )}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        loading={loading}
        scroll={{ x: "max-content" }}
        style={{
          width: "auto",
          textTransform: "capitalize",
        }}
        columns={
          selectedInstitution !== "" && selectedInstitution
            ? innerLeaseColumns.map((item) => {
                return { ...item, align: "center" };
              })
            : totalLeaseColumns.map((item) => {
                return { ...item, align: "center" };
              })
        }
        dataSource={leaseReports?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: leaseReports?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsLicenseLeaseReports);
