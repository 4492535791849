import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import { tms_footPrint } from "../../../../../assets/images/tms-image-list.js";
import moment from "moment";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsFootfallService from "../../../../../services/tms-footfall.service";
import TmsSelect from "../../../../../components/tms-customs/tms-select";
import TmsDatePickerRange from "../../../../../components/tms-customs/tms-datepicker-range";
import {
  CartesianGrid,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceLine,
  Legend,
} from "recharts";

const TmsSmFootfallCount = ({ router }) => {
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [count, setCount] = useState("");
  const [currentDate, setCurrentDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [type, setType] = useState("online");
  const [update, setUpdate] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [avg, setAvg] = useState();
  const [dateCount, setDateCount] = useState([
    { offlineCount: "", onlineCount: "" },
  ]);

  const submit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!currentDate || currentDate === "") {
      notification.error({ message: "Select Date" });
      setLoading(false);
      return;
    }
    if (!count || count === "") {
      notification.error({ message: "Enter Count" });
      setLoading(false);
      return;
    }
    try {
      await TmsFootfallService.setFootfallOffline(currentDate, count);
      setCount("");
      setUpdate(Math.random());
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );

      setLoading(false);
    }
  };

  const footfallRange = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let body = [];
    if (!type || type === "") {
      notification.error({ message: "Select Type" });
      setLoading(false);
      return;
    }
    if (!startDate || startDate === "") {
      notification.error({ message: "Select Start Date" });
      setLoading(false);
      return;
    }
    if (!endDate || endDate === "") {
      notification.error({ message: "Select End Date" });
      setLoading(false);
      return;
    }
    body = [
      {
        type: type,
        fromDate: startDate,
        toDate: endDate,
        current: 1,
        limit: 500,
      },
    ];
    try {
      let temp = [];
      let count = 0;
      let res = await TmsFootfallService.getFootfall(...body);
      res?.items?.map((data) => {
        count = count + data?.count;
        temp = [
          ...temp,
          { date: moment(data?.date).format("DD-MM-YYYY"), count: data?.count },
        ];
      });
      setTableData(temp?.reverse());
      setAvg(Math?.ceil(Number(count / temp?.length)));
      setLoading(false);
      setModalVisible(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );

      setLoading(false);
    }
  };

  const getFootfallDate = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      let off = await TmsFootfallService.getFootfallCurrentDate("offline");
      let on = await TmsFootfallService.getFootfallCurrentDate("online");
      setDateCount({ offlineCount: off, onlineCount: on });
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );

      setLoading(false);
    }
  };

  useEffect(() => {
    footfallRange();
  }, [startDate, endDate, type, update]);

  useEffect(() => {
    if (modalVisible === false) {
      getFootfallDate();
    }
  }, [modalVisible]);

  return (
    <>
      <br />
      <Row gutter={[10, 10]} style={{ textAlign: "left" }}>
        <Button
          onClick={() => {
            setModalVisible(true);
          }}
          type={"primary"}
        >
          Add Count
        </Button>
        <Modal
          visible={modalVisible}
          width={400}
          onOk={submit}
          closable={false}
          maskClosable={false}
          centered
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button key="back" onClick={() => setModalVisible(false)}>
              Return
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => loading === false && submit()}
            >
              Submit
            </Button>,
          ]}
        >
          <Col>
            <label>Date</label>
            <br />
            <DatePicker
              value={currentDate}
              format="DD-MM-YYYY"
              onChange={(date) => {
                setCurrentDate(date);
              }}
              size="medium"
              disabledDate={(d) => !d || d.isAfter(moment())}
            />
          </Col>
          <br />
          <Col>
            <label>Count</label>
            <Input
              maxLength={220}
              type="number"
              min="0"
              placeholder="Enter Count"
              value={count}
              onChange={(e) => setCount(e.target.value)}
            />
          </Col>
        </Modal>
      </Row>
      <br />
      <Row gutter={[20, 20]} style={{ marginBottom: "20px" }}>
        <Col style={{ width: "32%" }}>
          <Card title={"Total Offline"} style={{ minHeight: "200px" }}>
            <Row style={{ justifyContent: "center", alignItems: "center" }}>
              <img src={tms_footPrint} style={{ width: "15%" }} />
              <b style={{ color: "red", fontSize: "30px" }}>
                {dateCount?.offlineCount?.total}
              </b>
            </Row>
          </Card>
        </Col>
        <Col style={{ width: "32%" }}>
          <Card title={"Total Online"} style={{ minHeight: "200px" }}>
            <Row style={{ justifyContent: "center", alignItems: "center" }}>
              <img src={tms_footPrint} style={{ width: "15%" }} />
              <b style={{ color: "limegreen", fontSize: "30px" }}>
                {dateCount?.onlineCount?.total}
              </b>
            </Row>
          </Card>
        </Col>
        <Col style={{ width: "32%" }}>
          <Card
            title={`${moment(dateCount?.offlineCount?.date).format(
              "DD-MM-YYYY"
            )}`}
            style={{ minHeight: "200px" }}
          >
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <Row style={{ justifyContent: "center", alignItems: "center" }}>
                  <img src={tms_footPrint} style={{ width: "30%" }} />
                  <b style={{ color: "red", fontSize: "30px" }}>
                    {dateCount?.offlineCount?.today}
                  </b>
                </Row>
                <b style={{ color: "red" }}>Offline</b>
              </Col>
              <Col span={12}>
                <Row style={{ justifyContent: "center", alignItems: "center" }}>
                  <img src={tms_footPrint} style={{ width: "30%" }} />
                  <b style={{ color: "limegreen", fontSize: "30px" }}>
                    {dateCount?.onlineCount?.today}
                  </b>
                </Row>
                <b style={{ color: "limegreen" }}>Online</b>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <br />

      <Row gutter={[10, 10]} style={{ marginBottom: "20px", width: "100%" }}>
        <Col>
          <TmsDatePickerRange
            label="Select Dates"
            to={endDate}
            from={startDate}
            setFrom={setStartDate}
            setTo={setEndDate}
            // disabledDate={false}
          />
        </Col>
        <TmsSelect
          type="col"
          span={4}
          style={{ width: "100%" }}
          value={type}
          setValue={setType}
          label="Type"
          optional={true}
          data={[
            { id: "online", name: "Online" },
            { id: "offline", name: "Offline" },
          ]}
        />
      </Row>
      <LineChart
        width={1100}
        height={500}
        data={tableData}
        margin={{
          top: 20,
          right: 50,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis dataKey="count" />
        <Tooltip />
        <Legend />
        <ReferenceLine
          y={avg}
          label={{ value: `Avg(${avg})`, position: "left" }}
          stroke="red"
          strokeDasharray="4 4"
        />
        <Line type="monotone" dataKey="count" fill="#8884d8" />
      </LineChart>
    </>
  );
};

export default withRouter(TmsSmFootfallCount);
