import { notification, Popconfirm, Space } from "antd";
import React from "react";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsManualEntryService from "../../../services/tms-manual-entry.service";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import tmsRouteMap from "../../../constants/tms-route.map";

const TmsManualEntryAction = ({
  record,
  setLoading,
  setEditData,
  setUpdateFlag,
  router,
  setViewSlider,
  setCategoryData,
}) => {
  return (
    <Space size="middle">
      <a
        onClick={() => {
          router.navigate(
            `${tmsRouteMap.serviceManagement_manual_entry_category}?bookid=${record.id}`
          );
          setCategoryData(record?.manualEntryBookCategories);
        }}
      >
        Manage Category
      </a>
      {/* <a
        onClick={async () => {
          setLoading(true);
          try {
            const data = await TmsDonationService.viewDonation(record?.key);
            // console.log(darData);
            setViewDonationData(data);
            settmsModalVisible(true);
          } catch (error) {
            catchErrorFunction(
              error,
              "Token Expired, redirecting to login page in 5 seconds",
              router
            );
          }
          setLoading(false);
        }}
      >
        <EyeOutlined />
      </a> */}
      <Popconfirm
        title="Are you sure delete this Book?"
        onConfirm={async () => {
          setLoading(true);
          try {
            await TmsManualEntryService.deleteBook(record?.id);
            notification.success({
              message: "Book Deleted Successful",
            });
            setUpdateFlag(Math.random() * 100);
          } catch (error) {
            catchErrorFunction(
              error,
              "Token Expired, redirecting to login page in 5 seconds",
              router
            );
          }
          setLoading(false);
        }}
        okText="Yes"
        cancelText="No"
      >
        <a>
          <DeleteOutlined />
        </a>
      </Popconfirm>
      <a
        onClick={async () => {
          setEditData(record);
          setViewSlider(true);
        }}
      >
        <EditOutlined />
      </a>
      {/* <a
        onClick={() => {
          setShowDrawer(true);
          setProductId(record?.key);
          setTermsData(record?.metadata?.termsAndConditions);
        }}
      >
        <FileProtectOutlined />
      </a> */}
    </Space>
  );
};
export default withRouter(TmsManualEntryAction);
