import React, { useContext, useEffect, useState } from "react";
import "../../../../assets/css/tms-protocol/tms-protocol.scss";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { Button, Col, Drawer, Row, Tag } from "antd";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsProtocolService from "../../../../services/tms-protocol.service";
import TmsTable from "../../../../components/tms-customs/tms-table";
import moment from "moment";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsModal from "../../../../components/tms-customs/tms-modal";
import TextArea from "antd/lib/input/TextArea";
import { BaseContext } from "../../../../contexts/BaseContext";
import {
  EditOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import tmsRouteMap from "../../../../constants/tms-route.map";

const TmsPosProtocolDashboard = ({ router }) => {
  const [requestData, setRequestData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState("");
  const [level, setLevel] = useState("");
  const [comment, setComment] = useState("");
  const [requestId, setRequestId] = useState();
  const [historyId, setHistoryId] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const { userDetails, setRequestEditData } = useContext(BaseContext);

  const LevelsArr = [
    { label: "EO", level: "LEVEL_1" },
    { label: "AEO", level: "LEVEL_2" },
    { label: "SI", level: "LEVEL_3" },
    { label: "Clerk", level: "LEVEL_4" },
  ];

  useEffect(() => {
    userDetails?.permissions?.map((item) => {
      if (item?.includes("LEVEL")) {
        setLevel(item);
      }
    });
  }, [userDetails]);

  const ProtocolRequest = async () => {
    setLoading(true);
    try {
      let res = await TmsProtocolService.protocolRequestGet(
        currentPage,
        pageSize
      );
      setTotalSize(res?.meta?.totalItems);
      setRequestData(
        res?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      //   columnManagement(res?.items);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const reviewHistory = async (id) => {
    try {
      let res = await TmsProtocolService.protocolReviewHistoryGet(1, 100, id);
      setReviewData(
        res?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const ProtocolHistory = async (id) => {
    setLoading(true);
    try {
      let res = await TmsProtocolService.protocolHistoryGet(1, 100, id);
      setHistoryData(
        res?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const historyFunc = (id) => {
    ProtocolHistory(id);
    reviewHistory(id);
  };

  useEffect(() => {
    ProtocolRequest();
  }, [currentPage, modal]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "S.no",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Performance Date",
      key: "bookingDate",
      render: (text, object) => moment(object.bookingDate).format("DD-MM-YYYY"),
    },
    {
      title: "Updated At",
      key: "updatedAt",
      hidden: !showDrawer,
      render: (text, object) =>
        moment(object?.review?.updatedAt ?? object?.updatedAt).format(
          "DD-MM-YYYY hh:mm A"
        ),
    },
    {
      title: "Names",
      key: "id",
      render: (text, object, i) => {
        return (
          <Row key={i + Math.random()}>
            <TmsSelect
              value={object?.devoteeDetails[0]?.personName}
              type="col"
              data={object?.devoteeDetails?.map((list) => {
                return { name: list.personName, id: list?.documentId };
              })}
              optional={true}
              allowClear={false}
            />
          </Row>
        );
      },
    },
    {
      title: "No of Persons",
      dataIndex: "noOfDevotees",
      key: "noOfDevotees",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Reference Number",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
    },
    {
      title: "Darshanam",

      children: [
        {
          title: "Name",
          key: "name",

          render: (text, object, i) => {
            return object?.darshanamServices?.length > 0 ? (
              object?.darshanamServices?.map((item) => {
                return (
                  <Row key={i + Math.random()}>
                    {item.productQuota.productSlot.product.name}
                  </Row>
                );
              })
            ) : (
              <>-</>
            );
          },
        },
        {
          title: "Qty",
          key: "qty",
          render: (text, object, i) => {
            return object?.darshanamServices?.length > 0 ? (
              object?.darshanamServices?.map((item) => {
                return <Row key={i + Math.random()}>{item.quantity}</Row>;
              })
            ) : (
              <>-</>
            );
          },
        },
      ],
    },
    {
      title: "Seva",

      children: [
        {
          title: "Name",
          key: "name",

          render: (text, object, i) => {
            return object?.sevaServices?.length > 0 ? (
              object?.sevaServices?.map((item) => {
                return (
                  <Row key={i + Math.random()}>
                    {item.productQuota.productSlot.product.name}
                  </Row>
                );
              })
            ) : (
              <>-</>
            );
          },
        },
        {
          title: "Qty",
          key: "qty",
          render: (text, object, i) => {
            return object?.sevaServices?.length > 0 ? (
              object?.sevaServices?.map((item) => {
                return <Row key={i + Math.random()}>{item.quantity}</Row>;
              })
            ) : (
              <>-</>
            );
          },
        },
      ],
    },
    {
      title: "Accommodation",

      children: [
        {
          title: "Name",
          key: "name",

          render: (text, object, i) => {
            return object?.accommodationServices?.length > 0 ? (
              object?.accommodationServices?.map((item) => {
                return (
                  <Row key={i + Math.random()}>
                    {item?.productQuota?.accBlockCategory?.accBlock?.name}
                  </Row>
                );
              })
            ) : (
              <>-</>
            );
          },
        },
        {
          title: "Qty",
          key: "qty",
          render: (text, object, i) => {
            return object?.accommodationServices?.length > 0 ? (
              object?.accommodationServices?.map((item) => {
                return <Row key={i + Math.random()}>{item.quantity}</Row>;
              })
            ) : (
              <>-</>
            );
          },
        },
      ],
    },
    {
      title: "Status",
      key: "id",
      hidden: showDrawer,
      render: (text, object) => {
        return (
          <Row className="level">
            {LevelsArr?.map((item, i) => {
              return (
                <Row key={i} gutter={[20, 20]} style={{ width: "100%" }}>
                  <Col className="text-bold" span={6} style={{ width: "25%" }}>
                    {item?.label}
                  </Col>
                  <Col span={18} style={{ width: "75%" }}>
                    <Tag
                      color={
                        object?.reviewStatusAggregate[item?.level]?.includes(
                          "APPROVED"
                        )
                          ? "green"
                          : "red"
                      }
                    >
                      {object?.reviewStatusAggregate[item?.level]?.replaceAll(
                        "_",
                        " "
                      )}
                    </Tag>
                  </Col>
                </Row>
              );
            })}
          </Row>
        );
      },
    },
    {
      title: "Action",
      hidden: showDrawer,
      render: (text, object) => {
        return object?.status === "PENDING" ? (
          moment(object?.bookingDate).isAfter(moment()) ? (
            <Row gutter={[20, 20]}>
              <Col
                className="pointer"
                onClick={() => {
                  setModal(true);
                  setStatus("APPROVED");
                  setRequestId(
                    object?.protocolRequestHistory[0]?.protocolRequestId
                  );
                  setHistoryId(object?.protocolRequestHistory[0]?.id);
                }}
              >
                Approve
              </Col>
              <Col
                className="pointer"
                onClick={() => {
                  setModal(true);
                  setStatus("REJECTED");
                  setRequestId(
                    object?.protocolRequestHistory[0]?.protocolRequestId
                  );
                  setHistoryId(object?.protocolRequestHistory[0]?.id);
                }}
              >
                Reject
              </Col>
              <Col
                className="pointer"
                onClick={() => {
                  setRequestEditData(object);
                  router.navigate(
                    `${tmsRouteMap.pos_protocol}?id=${object?.protocolRequestHistory[0]?.protocolRequestId}`
                  );
                }}
              >
                <EditOutlined />
              </Col>
            </Row>
          ) : (
            <Tag icon={<ClockCircleOutlined />} color="warning">
              EXPIRED
            </Tag>
          )
        ) : (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {object?.status}
          </Tag>
        );
      },
    },
    {
      title: "",
      hidden: showDrawer,
      render: (text, object) => {
        return (
          <Row
            className="pointer"
            onClick={() => {
              setShowDrawer(true);
              historyFunc(object?.protocolRequestHistory[0]?.protocolRequestId);
            }}
          >
            History
          </Row>
        );
      },
    },
    {
      title: "",
      hidden: !showDrawer,
      render: (text, object) => {
        return (
          <Tag
            color={
              object?.status === "PENDING"
                ? object?.review?.reviewerLevel === "LEVEL_1"
                  ? "success"
                  : "warning"
                : object?.status === "REJECTED"
                ? "error"
                : "success"
            }
          >
            {object?.review?.reviewerLevel === "LEVEL_1"
              ? object?.review?.reviewerStatus
              : object?.status}
          </Tag>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const subTable = (tableData) => {
    const columns = [
      {
        title: "Designation",
        dataIndex: "cader",
        key: "cader",
      },
      {
        title: "Reference Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Mobile Number",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
    ];
    const data = [
      {
        key: tableData?.id,
        cader: tableData?.cader,
        name: tableData?.name,
        phoneNumber: tableData?.phoneNumber,
      },
    ];

    return (
      <TmsTable
        columns={columns}
        dataSource={data}
        pagination={false}
        className="sub-table"
        // scroll={false}
      />
    );
  };

  const reviewColumns = [
    {
      title: "S.no",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Updated At",
      key: "createdAt",
      render: (text, object) =>
        moment(object?.createdAt).format("DD-MM-YYYY hh:mm A"),
    },
    {
      title: "Reviewer Name",
      key: "id",
      render: (text, object) => {
        return <Row>{object?.reviewer?.displayName}</Row>;
      },
    },
    {
      title: "Phone Number",
      key: "id",
      render: (text, object) => {
        return <Row>{object?.reviewer?.phone}</Row>;
      },
    },
    {
      title: "Level",
      dataIndex: "reviewerLevel",
      key: "reviewerLevel",
      render: (text) => {
        return (
          <Row>
            {text?.includes("4")
              ? "Clerk"
              : text?.includes("3")
              ? "SI"
              : text?.includes("2")
              ? "AEO"
              : text?.includes("1")
              ? "EO"
              : "-"}
          </Row>
        );
      },
    },
    { title: "Comment", dataIndex: "reviewerComment", key: "reviewerComment" },
    {
      title: "Status",
      dataIndex: "reviewerStatus",
      key: "reviewerStatus",
      render: (text) => {
        return (
          <Tag color={text?.includes("APPROVED") ? "green" : "red"}>
            {text?.replaceAll("_", " ")}
          </Tag>
        );
      },
    },
  ];

  const request = async () => {
    setLoading(true);
    try {
      let body = {
        protocolRequestId: requestId,
        reviewerComment: comment,
        protocolRequestHistoryId: historyId,
        reviewerLevel: level?.replace("PROTOCOL_", ""),
        reviewerStatus: status,
      };
      let res = await TmsProtocolService.requestStatus(body);
      if (res) {
        ProtocolRequest();
      }
      setModal(false);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex align-center col protocolDashBoard">
      <Row
        className="bg-white w-full text-left flex justify-between col"
        gutter={[20, 20]}
      >
        <TmsTable
          loading={loading}
          dataSource={requestData}
          columns={columns}
          pagination={{
            total: totalSize,
            current: currentPage,
            pageSize: pageSize,
          }}
          handleTableChange={handleTableChange}
          expandable={{
            expandedRowRender: (record) => {
              return subTable(record);
            },
          }}
        />
      </Row>
      <TmsModal
        visible={modal}
        title={`${
          status === "APPROVED" ? "Approving" : "Rejecting"
        } the Request`}
        centered={true}
        closable={false}
        maskClosable={false}
        footer={
          <Row gutter={[10, 10]} style={{ justifyContent: "flex-end" }}>
            <Col>
              <Button onClick={() => setModal(false)}>Cancel</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => request()}>
                Ok
              </Button>
            </Col>
          </Row>
        }
      >
        <Row className="w-full">
          <Row className="w-full mb-5">
            <div>Comment: </div>
            <TextArea
              maxLength={220}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </Row>
          <TmsSelect
            classname="w-full mb-5"
            style={{ width: "100%" }}
            label="Level"
            mandatory={true}
            type="col"
            value={level}
            setValue={setLevel}
            data={LevelsArr?.map((res) => {
              return { name: res?.label, id: `PROTOCOL_${res?.level}` };
            })?.filter((item) => {
              return userDetails?.permissions?.includes("SUPER_ADMIN")
                ? true
                : item?.id === level;
            })}
            optional={true}
            allowClear={false}
          />
        </Row>
      </TmsModal>
      <Drawer
        centered={true}
        title="History"
        width="85%"
        visible={showDrawer}
        closable={true}
        onClose={() => {
          setHistoryData([]);
          setShowDrawer(false);
        }}
      >
        <Row>
          <b style={{ borderBottom: "1px solid black" }}>Changes History</b>
        </Row>
        <br />
        <TmsTable
          loading={loading}
          dataSource={historyData}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => {
              return subTable(record);
            },
          }}
        />
        <Row>
          <b style={{ borderBottom: "1px solid black" }}>Review History</b>
        </Row>
        <br />
        <TmsTable
          loading={loading}
          dataSource={reviewData}
          columns={reviewColumns}
        />
      </Drawer>
    </div>
  );
};

export default withRouter(TmsPosProtocolDashboard);
