import {
  tms_icon_accommodation,
  tms_icon_counter_management,
  tms_icon_coupon_management,
  tms_icon_darshanam,
  tms_icon_donation,
  tms_icon_kalyanakatta,
  tms_icon_prasadam,
  tms_icon_pratyaksha_Seva,
  tms_icon_publication,
  tms_icon_shift_managament,
  tms_icon_thulabharam,
  tms_icon_footfall,
  tms_icon_tollgate,
  tms_d_products,
  tms_icon_check_in_scan,
  tms_donor_p,
  parakamani,
  rentsLogo,
  busLogo,
} from "../assets/images/tms-image-list";
import tmsRouteMap from "./tms-route.map";

const TmsServiceCardMap = [
  {
    title: "Accommodation",
    hidden: "accommodationEnabled",
    route: tmsRouteMap.serviceManagement_accommodation,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#E6F8FD",
    logo: tms_icon_accommodation,
    textColor: "#0EB5E4",
  },
  {
    title: "Sevas",
    hidden: "sevaEnabled",
    route: tmsRouteMap.serviceManagement_sevas,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#EEF7EE",
    logo: tms_icon_pratyaksha_Seva,
    active: true,
    textColor: "#53B04A",
  },
  {
    title: "Darshanam",
    hidden: "darshanamEnabled",
    route: tmsRouteMap.serviceManagement_darshanam,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#F0F9FF",
    logo: tms_icon_darshanam,
    active: true,
    textColor: "#3399CC",
  },
  {
    title: "Donation",
    hidden: "donationEnabled",
    route: tmsRouteMap.serviceManagement_donation,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FFF6FB",
    logo: tms_icon_donation,
    textColor: "#CC3399",
  },
  {
    title: "Products",
    hidden: "productEnabled",
    route: tmsRouteMap.serviceManagement_products,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
  },
  {
    title: "Toll Gate",
    hidden: "tollgateEnabled",
    route: tmsRouteMap.serviceManagement_tollgate,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#F9F8FD",
    logo: tms_icon_tollgate,
    textColor: "#666699",
  },
  {
    title: "Prasadam",
    hidden: "prasadamEnabled",
    route: tmsRouteMap.serviceManagement_prasadam,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#F0F7D8",
    logo: tms_icon_prasadam,
    textColor: "#999966",
  },
  {
    title: "Prasadam Stock",
    hidden: "prasadamEnabled",
    route: tmsRouteMap.sm_prasadam_inventory,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "PRASAD_INVENTORY_MANAGEMENT"],
    color: "#F0I5J6",
    logo: tms_icon_prasadam,
    textColor: "#479079",
  },
  {
    title: "Kalyanakatta",
    hidden: "kalyanakattaEnabled",
    route: tmsRouteMap.serviceManagement_kalayanakatta,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#D9F7E0",
    logo: tms_icon_kalyanakatta,
    textColor: "#669966",
  },

  {
    title: "Thulabharam",
    hidden: "thulabaramEnabled",
    route: tmsRouteMap.serviceManagement_thulabharam,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#D6F8FF",
    logo: tms_icon_thulabharam,
    textColor: "#669999",
  },
  {
    title: "Footfall",
    hidden: "",
    route: tmsRouteMap.serviceManagement_footfall,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "VISITOR_COUNT"],
    color: "#F9F8FD",
    logo: tms_icon_footfall,
    textColor: "#666699",
  },
  {
    title: "POS Management",
    hidden: "",
    route: tmsRouteMap.serviceManagement_counter_management,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FFEBF7",
    logo: tms_icon_counter_management,
    textColor: "#4E8482",
  },
  {
    title: "Coupon Management",
    hidden: "",
    route: tmsRouteMap.serviceManagement_coupon_management,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "DONOR_PRIVILEGE"],
    color: "#FFE2E5",
    logo: tms_icon_coupon_management,
    textColor: "#848355",
  },
  {
    title: "Shift Management",
    hidden: "",
    route: tmsRouteMap.serviceManagement_shift_management,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FEF6D7",
    logo: tms_icon_shift_managament,
    textColor: "#EF7843",
  },
  {
    title: "Rents and Leases",
    hidden: "rentsAndLeasesEnabled",
    route: tmsRouteMap.serviceManagement_rents_and_leases,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "RENT_AND_LEASE_WRITE",
      "RENT_AND_LEASE_READ",
    ],
    color: "#EEEBFF",
    logo: rentsLogo,
    textColor: "#573BFF",
  },
  {
    title: "Manual Entry",
    hidden: "",
    route: tmsRouteMap.serviceManagement_manual_entry,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
  },
  {
    title: "Miscellaneous",
    hidden: "miscellaneousEnabled",
    route: tmsRouteMap.serviceManagement_misc,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#D9F7E0",
    logo: tms_d_products,
    textColor: "#669966",
  },
  {
    title: "Entry Scan",
    hidden: "",
    route: tmsRouteMap.serviceManagement_entryScan,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#EEEBFF",
    logo: tms_icon_check_in_scan,
    textColor: "#573BFF",
  },
  {
    title: "Add Ons",
    hidden: "addOnEnabled",
    route: tmsRouteMap.serviceManagement_addOns,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
  },
  {
    title: "Donor Privileges",
    hidden: "donorPrivilegeEnabled",
    route: tmsRouteMap.sm_donor_p_list,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#EEF7EE",
    logo: tms_donor_p,
    textColor: "#53B04A",
  },
  {
    title: "Parakamani",
    hidden: "hundiEnabled",
    route: tmsRouteMap.sm_parakamani,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "HUNDI"],
    color: "#FEF6D7",
    logo: parakamani,
    textColor: "#EF7843",
  },
  {
    title: "Bus",
    hidden: "busEnabled",
    route: tmsRouteMap.sm_bus,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "BUS"],
    color: "#EEF7EE",
    logo: busLogo,
    active: true,
    textColor: "#53B04A",
  },
  {
    title: "Publications",
    hidden: "publicationEnabled",
    route: tmsRouteMap.serviceManagement_publications,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
  },
]; // ADD ROLES IN Sider.map.js
export default TmsServiceCardMap;
