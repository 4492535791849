import { Row, Tabs, Button, Col, Select, Input, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import TmsUserCard from "../../../components/tms-user-management/tms-user-card";
// import {BaseContext} from "../../../contexts/BaseContext";
import TmsUserService from "../../../services/tms-user/tms-user.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import UserBookingHistoryTable from "./tms-um-inner-pages-booking-history";
import TmsUmInnerPagesUsersActionLogs from "./tms-um-inner-pages-user-action-logs";
import TmsUmInnerPagesUsersAuthLogs from "./tms-um-inner-pages-auth-logs";
const TmsUmInnerPagesUsersDetails = ({ router }) => {
  // const {userDetails} = useContext(BaseContext);

  const inputEl = useRef();

  const [queryBy, setQueryBy] = useState("userId");
  const [userId, setUserId] = useState("");
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [userStats, setUserStats] = useState({});
  const [userStatsCount, setUserStatsCount] = useState({});

  const setInputFocus = () => {
    inputEl?.current?.input?.focus();
  };

  const selectInput = () => {
    inputEl?.current?.input?.select();
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  useEffect(() => {
    const id = new URLSearchParams(router?.location?.search).get("userId");
    if (id) {
      setUserId(id);
      setQueryBy("userId");
      submit(id);
    }
  }, [router?.location?.search]);

  const submit = async (_userId = undefined) => {
    setLoading(true);
    setData(undefined);
    try {
      if (!userId && !_userId) {
        return notification.error({ message: "Please enter a value." });
      }
      const temp = _userId ?? userId;
      const data = await TmsUserService.userDetails(queryBy, temp);

      if (data) {
        setData(data);
        const userStats = await TmsUserService.getUsersPaymentStats(data?.id);
        const userCountStats = await TmsUserService.getUsersStats(data?.id);
        setUserStatsCount(userCountStats);
        setUserStats(userStats);
      } else {
        notification.error({ message: "No such user." });
      }
      selectInput();
      setInputFocus();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setInputFocus();
  }, []);

  return (
    <>
      <Row justify="start" gutter={[20, 20]}>
        <Col>
          <Select
            onChange={(e) => {
              setQueryBy(e);
            }}
            value={queryBy}
            style={{ width: "100px" }}
          >
            <Select.Option value="email"> Email </Select.Option>
            <Select.Option value="phone"> Phone </Select.Option>
            <Select.Option value="userId"> User ID </Select.Option>
          </Select>
        </Col>
        <Col>
          <Input
            maxLength={220}
            ref={inputEl}
            value={userId}
            onChange={(e) => {
              setUserId(e.target.value);
            }}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              submit();
            }}
          >
            GO!
          </Button>
        </Col>
      </Row>
      <br />
      <Tabs className="tms-tabs-inner-scroll">
        <Tabs.TabPane tab="User Details" key="1" animated>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              {data && <TmsUserCard userLoading={loading} data={data} />}
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Booking History" key="2">
          <UserBookingHistoryTable
            userId={data?.id}
            userStats={userStats}
            userStatsCount={userStatsCount}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Auth Logs" key="4">
          <TmsUmInnerPagesUsersAuthLogs
            phone={data?.phone?.replace("+91", "")}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="User Action Logs" key="5">
          <TmsUmInnerPagesUsersActionLogs userId={data?.id} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
export default withRouter(TmsUmInnerPagesUsersDetails);
