import { Col, Select } from "antd";
import React from "react";

const { Option } = Select;

export default function TmsCalenderMasam({
  masam,
  labelStyles,
  setMasam,
  percentage,
}) {
  return (
    <Col style={{ width: percentage, justifyContent: "start" }}>
      <label style={labelStyles}>
        Select Masam
        <span style={{ color: "red", marginLeft: "3px" }}>*</span>
      </label>
      <br />
      <Select
        size="medium"
        style={{ width: "100%" }}
        placeholder="Select Masam"
        optionFilterProp="children"
        value={masam}
        onChange={(e) => {
          setMasam(e);
        }}
      >
        <Option key={"Chaithram"} value={"Chaithram"}>
          Chaithram
        </Option>
        <Option key={"Vaisaakham"} value={"Vaisaakham"}>
          Vaisaakham
        </Option>
        <Option key={"Jyeshttam"} value={"Jyeshttam"}>
          Jyeshttam
        </Option>
        <Option key={"Ashadam"} value={"Ashadam"}>
          Ashadam
        </Option>
        <Option key={"Sraavanam"} value={"Sraavanam"}>
          Sraavanam
        </Option>
        <Option key={"Bhaadhrapadam"} value={"Bhaadhrapadam"}>
          Bhaadhrapadam
        </Option>
        <Option key={"Aasveeyujam"} value={"Aasveeyujam"}>
          Aasveeyujam
        </Option>
        <Option key={"Kaarthikam"} value={"Kaarthikam"}>
          Kaarthikam
        </Option>
        <Option key={"Maargaseersham"} value={"Maargaseersham"}>
          Maargaseersham
        </Option>
        <Option key={"Pushyam"} value={"Pushyam"}>
          Pushyam
        </Option>
        <Option key={"Maagham"} value={"Maagham"}>
          Maagham
        </Option>
        <Option key={"Phalgunam"} value={"Phalgunam"}>
          Phalgunam
        </Option>
      </Select>
    </Col>
  );
}
