import React, { useEffect, useState } from "react";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsSmFootfallCount from "./tms-sm-footfall-innerPages/tms-sm-footfall-count";
// import TmsThulabharamInnerPagesDbd from "./tms-sm-thulabharam-inner-pages/tms-thulabharam-inner-pages-dbd";

const TmsSmFootfall = ({ router }) => {
  const [active, setActive] = useState("all");

  useEffect(() => {
    let location = router.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_footfall) {
      setActive("visitors count");
      return;
    }
  }, [router.location?.pathname]);

  const components = [
    {
      name: "Visitors Count",
      Element: <TmsSmFootfallCount />,
      key: "visitors count",
      route: tmsRouteMap.serviceManagement_footfall,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmFootfall);
