import { Button, Col, Input, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import TmsCounterService from "../../../services/tms-counter.service";
import TmsManualEntryService from "../../../services/tms-manual-entry.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
const { Option } = Select;

const EditBook = ({
  router,
  editData,
  setViewSlider,
  setUpdateFlag,
  setEditData,
}) => {
  const [type, setType] = useState(editData?.name);
  const [loading, setLoading] = useState(false);
  const [counters, setCounters] = useState([]);
  const [counterId, setCounterId] = useState(editData?.counterId);
  const [from, setFrom] = useState(editData?.seriesStart);
  const [to, setTo] = useState(editData?.seriesEnd);

  useEffect(() => {
    fetchCounter();
  }, []);

  useEffect(() => {
    setType(editData?.name);
    setCounterId(editData?.counterId);
    setFrom(editData?.seriesStart);
    setTo(editData?.seriesEnd);
  }, [editData]);

  const fetchCounter = async () => {
    setLoading(true);
    try {
      const resp = await TmsCounterService.getAllCounter(1, 100);
      setCounters(resp?.items);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const clearFields = () => {
    setViewSlider(false);
    setUpdateFlag(Math.random() * 100);
    setType("");
    setCounters([]);
    setCounterId("");
    setFrom("");
    setTo("");
    setEditData([]);
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let body = [
      {
        id: editData?.id,
        name: type,
        counterId: Number(counterId),
        seriesStart: Number(from),
        seriesEnd: Number(to),
      },
    ];
    try {
      await TmsManualEntryService.editBook(...body);
      clearFields();
      notification.success({
        message: "Book Edited Successful",
      });
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Row gutter={[20, 20]}>
        <Col style={{ width: "50%" }}>
          <label>Name</label>
          <br />
          <Input
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            maxLength={220}
          />
        </Col>
        <Col style={{ width: "50%" }}>
          <label>POS</label>
          <br />
          <Select
            style={{ width: "100%" }}
            onChange={(e) => setCounterId(e)}
            value={counterId}
          >
            {counters?.map((a) => {
              return (
                <Option value={a?.id} key={a?.name}>
                  {a?.name}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <br />
      <Row gutter={[20, 20]}>
        <Col style={{ width: "50%" }}>
          <label>From</label>
          <br />
          <Input
            maxLength={220}
            value={from}
            onChange={(e) => setFrom(e.target.value)}
          />
        </Col>
        <Col style={{ width: "50%" }}>
          <label>To</label>
          <br />
          <Input
            maxLength={220}
            value={to}
            onChange={(e) => setTo(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <br />
      <Button type="primary" onClick={() => handleSubmit()}>
        Submit
      </Button>
    </div>
  );
};

export default EditBook;
