// import { Button, Col, Input, Row } from "antd";
import React from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
// import moment from "moment";
// import { SearchOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
// import TmsMiscService from "../../../../services/tms-misc.service";
// import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
// import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
// import TmsTable from "../../../../components/tms-customs/tms-table";
// import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
// // import TmsCounterService from "../../../../services/tms-counter.service";
// // import TmsShiftService from "../../../../services/tms-shift.service";
// import TmsSelect from "../../../../components/tms-customs/tms-select";
// import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
// import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
// import TmsDashboardService from "../../../../services/tms-dashboard.service";

// const TmsReportsAllTransactions = ({ router }) => {
//   const [loading, setLoading] = useState();
//   const [excelLoading, setExcelLoading] = useState(false);

//   const [bookingDetails, setBookingDetails] = useState([]);
//   const [bookingFromDate, setBookingFromDate] = useState(
//     moment().format("YYYY-MM-DD")
//   );
//   const [bookingToDate, setBookingToDate] = useState(
//     moment().format("YYYY-MM-DD")
//   );
//   const [searchText, setSearchText] = useState("");
//   const [searchedColumn, setSearchedColumn] = useState("");
//   const [bookingType, setBookingType] = useState("POS");
//   const [tableCurrentPage, setTabelCurrentPage] = useState(1);
//   const [tablePageSize, setTabelPageSize] = useState(15);
//   const [tableTotalSize, setTabelTotalSize] = useState(0);
//   const [miscType, setMiscType] = useState("");
//   const [miscList, setMiscList] = useState([]);

//   const [counter, setCounter] = useState("");
//   const [counterList, setCounterList] = useState([]);

//   const [shift, setShift] = useState("");
//   const [shiftList, setShiftList] = useState([]);

//   const fetchBookingDetails = async () => {
//     setLoading(true);
//     try {
//       const data = await TmsDashboardService.getAllTempleTransactions(
//         localStorage.getItem("templeId"),
//         Number(tableCurrentPage),
//         Number(tablePageSize),
//         ""
//       );
//       setTabelTotalSize(data?.meta?.totalItems);
//       setBookingDetails(
//         data?.items?.map((item) => {
//           return { key: item?.bookingId, ...item };
//         })
//       );
//     } catch (error) {
//       catchErrorFunction(
//         error,
//         "Token Expired, redirecting to login page in 5 seconds",
//         router
//       );
//     }
//     setLoading(false);
//   };
//   const handleSearch = (selectedKeys, confirm, dataIndex) => {
//     confirm();
//     setSearchText(selectedKeys[0]);
//     setSearchedColumn(dataIndex);
//   };
//   const handleReset = (clearFilters) => {
//     clearFilters();
//     setSearchText("");
//   };
//   const getColumnSearchProps = (dataIndex) => ({
//     filterDropdown: ({
//       setSelectedKeys,
//       selectedKeys,
//       confirm,
//       clearFilters,
//     }) => (
//       <div style={{ padding: 8 }}>
//         <Input
//           maxLength={220}
//           // ref={node => {
//           //   this.searchInput = node;
//           // }}
//           placeholder={`Search ${dataIndex}`}
//           value={selectedKeys[0]}
//           onChange={(e) =>
//             setSelectedKeys(e.target.value ? [e.target.value] : [])
//           }
//           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//           style={{ width: 188, marginBottom: 8, display: "block" }}
//         />
//         <Button
//           type="primary"
//           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//           size="small"
//           style={{ width: 90, marginRight: 8 }}
//         >
//           Search
//         </Button>
//         <Button
//           onClick={() => handleReset(clearFilters)}
//           size="small"
//           style={{ width: 90 }}
//         >
//           Reset
//         </Button>
//       </div>
//     ),
//     filterIcon: (filtered) => (
//       <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
//     ),
//     onFilter: (value, record) =>
//       record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
//     onFilterDropdownVisibleChange: (visible) => {
//       if (visible) {
//         // setTimeout(() => this.searchInput.select());
//       }
//     },
//     render: (text) =>
//       searchedColumn === dataIndex ? (
//         <Highlighter
//           highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
//           searchWords={[searchText]}
//           autoEscape
//           textToHighlight={text.toString()}
//         />
//       ) : (
//         text
//       ),
//   });

//   // eslint-disable-next-line no-unused-vars
//   const handleTableChange = (pagination, filters, sorter) => {
//     setTabelCurrentPage(pagination.current);
//     setTabelPageSize(pagination.pageSize);
//     // if (sorter.field === "createdDate") {
//     //   if (sorter.order === "ascend") {
//     //     setSortType("ASC");
//     //   } else if (sorter.order === "descend") {
//     //     setSortType("DESC");
//     //   }
//     // }
//   };
//   // const fetchTollGateList = async () => {
//   //   try {
//   //     let res = await TmsMiscService.getMisc(1, 200);
//   //     setMiscList(res.items);
//   //   } catch (e) {
//   //     notification.error({ message: e?.message });
//   //   }
//   // };
//   // const fetchCounterList = async () => {
//   //   try {
//   //     let res = await TmsCounterService.getAllCounter();
//   //     setCounterList(res?.items);
//   //   } catch (e) {
//   //     notification.error({ message: e?.message });
//   //   }
//   // };
//   // const fetchShiftList = async () => {
//   //   try {
//   //     let res = await TmsShiftService.getAllShifts();
//   //     setShiftList(res?.items);
//   //   } catch (e) {
//   //     notification.error({ message: e?.message });
//   //   }
//   // };
//   const handleExcelExport = async () => {
//     setExcelLoading(true);
//     try {
//       const data = await TmsMiscService.miscReport(
//         "json",
//         Number(tableCurrentPage),
//         Number(50000),
//         bookingFromDate,
//         bookingToDate,
//         counter,
//         shift,
//         miscType
//       );

//       const compiledData = data.items.map((item, i) => {
//         return {
//           "S.no": i + 1,
//           "POS Name": item.counterName,
//           "User Name": item.userName,
//           "User ID": item.userId,
//           "Shift ID": item.counterShiftTimingId,
//           "Booking Id": item.bookingId,
//           "Transaction Id": item?.categoryTransactionSequence
//             ?.split("-")
//             ?.pop(),
//           "Miscellaneous Name": item.miscProductName,
//           "Booking Date": item.createdDate
//             ? moment(item.createdDate).format("DD-MM-YYYY")
//             : "-",
//           Price: item.productPrice,
//         };
//       });
//       exportExcel(
//         compiledData,
//         `tollgate-report-${moment().format("DD-MM-YYYY")}`,
//         setExcelLoading
//       );
//     } catch (error) {
//       catchErrorFunction(error, "Token expired!! Please login again.", router);
//       setExcelLoading(false);
//     }
//   };
//   useEffect(() => {
//     // fetchTollGateList();
//     // fetchCounterList();
//     // fetchShiftList();
//   }, []);
//   useEffect(() => {
//     fetchBookingDetails();
//   }, [
//     bookingFromDate,
//     bookingToDate,
//     tableCurrentPage,
//     tablePageSize,
//     counter,
//     shift,
//     miscType,
//   ]);

//   const columns = [
//     {
//       title: "S.no",
//       key: "sno",
//       width: "20px",
//       render: (text, object, index) => index + 1,
//     },
//     {
//       title: "Service Type",
//       dataIndex: "serviceType",
//       render: (record) => record,
//       ...getColumnSearchProps("serviceType"),
//     },
//     {
//       title: "Service Name",
//       dataIndex: "serviceName",
//       key: "serviceName",
//     },

//     {
//       title: "Payment Status",
//       dataIndex: "paymentStatus",
//       key: "paymentStatus",
//     },

//     {
//       title: "No of tickets",
//       dataIndex: "noOfTickets",
//       key: "noOfTickets",
//     },
//     {
//       title: "Txn Id",
//       dataIndex: "categoryTransactionSequence",
//       key: "categoryTransactionSequence",
//     },
//     {
//       title: "Price",
//       dataIndex: "onlineCartItemAmount",
//       key: "onlineCartItemAmount",
//       render: (text) => {
//         return `Rs. ${text}`;
//       },
//       sorter: (a, b) => a.price > b.price,
//     },
//     {
//       title: "Booking Date",
//       dataIndex: "bookingDate",
//       render: (record) => {
//         return moment(record).format("DD-MM-YYYY");
//       },
//       sorter: (a, b) => a.bookingDate > b.bookingDate,
//     },
//     {
//       title: "Payment Date",
//       dataIndex: "paymentDate",
//       key: "paymentDate",
//       render: (text) => {
//         return moment(text).format("DD-MM-YYYY");
//       },
//       defaultSortOrder: "descend",
//       sorter: (a, b) => a.paymentDate > b.paymentDate,
//     },
//   ];

//   return (
//     <>
//       <Row gutter={[20, 20]}>
//         <TmsSelect
//           type="col"
//           span={3}
//           label="Booking Type"
//           setValue={setBookingType}
//           value={bookingType}
//           data={[
//             { id: "pos", name: "pos" },
//             { id: "online", name: "online" },
//           ]}
//         />
//         <TmsSelect
//           type="col"
//           span={4}
//           selectClass="w-100"
//           label="Miscellaneous Type"
//           setValue={(e) => {
//             setMiscType(e);
//           }}
//           value={miscType}
//           data={miscList}
//         />
//         <TmsSelect
//           type="col"
//           span={3}
//           label="POS"
//           setValue={setCounter}
//           value={counter}
//           data={counterList}
//         />
//         <TmsSelect
//           type="col"
//           span={3}
//           label="Shift"
//           setValue={setShift}
//           value={shift}
//           data={shiftList}
//         />

//         <Col span={6}>
//           <TmsDatePickerRange
//             className={"m-0"}
//             label="Time period"
//             to={bookingToDate}
//             from={bookingFromDate}
//             setFrom={setBookingFromDate}
//             setTo={setBookingToDate}
//           />
//         </Col>
//         <TmsExcelButton
//           loading={excelLoading}
//           handleExcelExport={handleExcelExport}
//           top={true}
//         />
//       </Row>
//       <br />
//       <Row>
//         <TmsTable
//           columns={columns}
//           dataSource={bookingDetails}
//           loading={loading}
//           handleTableChange={handleTableChange}
//           pagination={{
//             total: tableTotalSize,
//             current: tableCurrentPage,
//             pageSize: tablePageSize,
//           }}
//         ></TmsTable>
//       </Row>
//     </>
//   );
// };
const TmsReportsAllTransactions = () => {
  return <p>Coming Soon</p>;
};

export default withRouter(TmsReportsAllTransactions);
