import { Button, Checkbox, Col, Radio, Row, notification } from "antd";
import React from "react";
import TmsSelect from "../../tms-customs/tms-select";
import moment from "moment";

const TmsPosProtocolDevoteeList = ({
  type,
  commonLogicList,
  commonSelectedArray,
  setCommonSelectedArray,
  commonSlotsArray,
  bookingData,
  setBookingData,
  personStates,
  commonSlotQuota,
  roomtypesArray,
  roomType,
  setRoomType,
  setBlockPersons,
}) => {
  let arr = [];
  let booking = [];
  const arrManagment = () => {
    arr = commonSelectedArray;
    booking = bookingData;
  };

  const radioVerfiy = (slotID, productId) => {
    arrManagment();

    if (arr?.includes(productId)) {
      if (
        booking[arr?.indexOf(productId)]?.productData[0]?.productSlotId ===
        slotID
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const toggleRadio = (item) => {
    arrManagment();
    let id =
      type === "Accommodation"
        ? item?.productId
        : item?.productSlot?.product?.id;
    if (arr?.includes(id)) {
      booking[arr?.indexOf(id)]?.productData?.splice(0, 1);
      booking[arr?.indexOf(id)]?.productData?.push(item);
    }

    setCommonSelectedArray(arr);
    setBookingData([...booking]);
  };

  const toggleCheckBox = (item) => {
    arrManagment();

    if (arr?.includes(item?.productId)) {
      arr?.splice(arr?.indexOf(item?.productId), 1);
      booking?.splice(arr?.indexOf(item?.productId), 1);
    } else {
      arr.push(item?.productId);
      booking?.push({ productData: [], devoteeData: [""] });
    }
    setCommonSelectedArray(arr);
    setBookingData([...booking]);
    if (arr?.length > 0) {
      commonSlotQuota(arr, type);
    }
  };

  const removeDevotee = (item, index) => {
    arrManagment();

    if (arr?.includes(item?.productId)) {
      booking[arr?.indexOf(item?.productId)].devoteeData?.splice(index, 1);
    }

    setBookingData([...booking]);
  };

  const addDevotee = (item) => {
    arrManagment();

    if (arr?.includes(item?.productId)) {
      booking[arr?.indexOf(item?.productId)].devoteeData?.push("");
    }

    setBookingData([...booking]);
  };

  const handleBookingList = (e, item) => {
    // arrManagment();
    // if (arr?.includes(item?.productId)) {
    //   booking[arr?.indexOf(item?.productId)]?.devoteeData?.map((names) => {
    //     if (names?.includes(e)) {
    //       notification?.error({ message: "Devotee Name is already selected" });
    //     } else if (names?.includes("")) {
    //       names[names?.indexOf("")] = e;
    //     }
    //   });
    // }

    arrManagment();
    if (arr?.includes(item?.productId)) {
      if (booking[arr?.indexOf(item?.productId)]?.devoteeData?.includes(e)) {
        notification?.error({ message: "Devotee Name is already selected" });
      } else if (
        booking[arr?.indexOf(item?.productId)]?.devoteeData?.includes("")
      ) {
        booking[arr.indexOf(item?.productId)].devoteeData[
          booking[arr.indexOf(item?.productId)].devoteeData.indexOf("")
        ] = e;
      }
    }

    setBookingData([...booking]);
    if (type === "Accommodation") {
      toggleRadio(item);
    }
  };

  return (
    <Row className="align-baseline w-full">
      <h1 className="text-lg px-10">{type}</h1>
      <Row className="w-full m-0" gutter={[20, 20]}>
        {type === "Accommodation" ? (
          <>
            <Row className="w-full m-0 p-0" gutter={[20, 20]}>
              <TmsSelect
                classname="w-3"
                label="Room Type"
                type="col"
                data={roomtypesArray?.map((type) => {
                  return { name: type, id: type };
                })}
                optional={true}
                mandatory={true}
                value={roomType}
                setValue={setRoomType}
              />
              <TmsSelect
                classname="w-3"
                label="Block"
                type="col"
                data={commonLogicList?.map((block) => {
                  return {
                    name: `${block.name} - Rs. ${block.price}`,
                    id: block?.productId,
                  };
                })}
                optional={true}
                mandatory={true}
                value={commonSelectedArray[0]}
                onChange={(e) => {
                  let temp = commonLogicList?.filter((a) => a.productId === e);
                  setBlockPersons(temp[0]?.persons);
                  setCommonSelectedArray([e]);
                }}
              />
              <Col className="buttonDiv align-end flex">
                <Button
                  className="add"
                  onClick={() =>
                    setBookingData([{ productData: [], devoteeData: [""] }])
                  }
                >
                  <span className="m-0 rotate-45 add">+</span>
                </Button>
              </Col>
            </Row>
            {commonLogicList?.length > 0 && (
              <Row className="w-full m-0 align-end" gutter={[20, 20]}>
                {bookingData[0]?.devoteeData?.map((devotee, j) => {
                  return (
                    <Row
                      className="w-full m-0 align-end"
                      gutter={[20, 20]}
                      key={j}
                    >
                      <TmsSelect
                        classname="w-2"
                        label="Devotee Name"
                        type="col"
                        data={personStates?.map((list) => {
                          return { name: list?.name, id: list?.id };
                        })}
                        optional={true}
                        mandatory={true}
                        value={devotee}
                        onChange={(e) => {
                          handleBookingList(
                            e,
                            commonLogicList?.find(
                              (block) =>
                                block?.productId === commonSelectedArray[0]
                            )
                          );
                        }}
                      />
                      {personStates?.length > j + 1 ? (
                        bookingData[0]?.devoteeData?.length >= j + 1 ? (
                          bookingData[0]?.devoteeData?.length === j + 1 ? (
                            <Col className="buttonDiv">
                              <Button
                                className="add"
                                onClick={() =>
                                  addDevotee(
                                    commonLogicList?.find(
                                      (block) =>
                                        block?.productId ===
                                        commonSelectedArray[0]
                                    ),
                                    type
                                  )
                                }
                              >
                                +
                              </Button>
                            </Col>
                          ) : (
                            <Col className="buttonDiv">
                              <Button
                                className="add"
                                onClick={() =>
                                  removeDevotee(
                                    commonLogicList?.find(
                                      (block) =>
                                        block?.productId ===
                                        commonSelectedArray[0]
                                    ),
                                    j,
                                    type
                                  )
                                }
                              >
                                <span className="m-0 rotate-45 add">+</span>
                              </Button>
                            </Col>
                          )
                        ) : (
                          " "
                        )
                      ) : (
                        personStates?.length !== 1 && (
                          <Col className="buttonDiv">
                            <Button
                              className="add"
                              onClick={() =>
                                removeDevotee(
                                  commonLogicList?.find(
                                    (block) =>
                                      block?.productId ===
                                      commonSelectedArray[0]
                                  ),
                                  j,
                                  type
                                )
                              }
                            >
                              <span className="m-0 rotate-45 add">+</span>
                            </Button>
                          </Col>
                        )
                      )}
                    </Row>
                  );
                })}
              </Row>
            )}
          </>
        ) : (
          <>
            <Row className="w-full m-0 p-0" gutter={[20, 20]}>
              {commonLogicList?.length > 0 ? (
                commonLogicList?.map((item, i) => {
                  return (
                    <Col
                      className="w-3"
                      key={i}
                      onClick={() => {
                        toggleCheckBox(item);
                      }}
                    >
                      <Row className="w-full m-0 card p-10" gutter={[20, 20]}>
                        <Col className="align-center flex ">
                          <Checkbox
                            checked={commonSelectedArray?.includes(
                              item?.productId
                            )}
                          />
                        </Col>
                        <Col className="">
                          <p className="m-0 text-bold">{item?.name}</p>
                          <p className="m-0 ">Rs. {item?.price}</p>
                          {/* <p className="m-0">
                      {moment(item?.startTime, "HH:mm:ss").format("hh:mm A")} -{" "}
                      {moment(item?.endTime, "HH:mm:ss").format("hh:mm A")}
                    </p> */}
                          {/* <p className="m-0">
                      ({item?.availableBookingCount} Tickets are available)
                    </p> */}
                        </Col>
                      </Row>
                    </Col>
                  );
                })
              ) : (
                <p
                  className="px-10"
                  style={{ color: "red" }}
                >{`No ${type} available`}</p>
              )}
            </Row>
            {commonLogicList
              ?.filter((item) => commonSelectedArray?.includes(item?.productId))
              ?.map((item, i) => {
                return (
                  <Row className="w-full m-0" key={i} gutter={[20, 20]}>
                    <h1 className="px-10">{item?.name}</h1>
                    <Row className="w-full m-0" key={i} gutter={[20, 20]}>
                      {commonSlotsArray
                        ?.filter(
                          (slot) =>
                            slot.productSlot.product.id === item?.productId
                        )
                        ?.map((slot, s) => {
                          return (
                            <Col
                              className="w-3"
                              key={s}
                              onClick={() => {
                                if (1 > slot?.availableQuantity) {
                                  return;
                                }
                                toggleRadio(slot);
                              }}
                            >
                              <Row
                                className="w-full m-0 card p-10"
                                gutter={[20, 20]}
                              >
                                <Col className="align-center flex ">
                                  <Radio
                                    defaultChecked={false}
                                    value={slot?.productSlotId}
                                    checked={radioVerfiy(
                                      slot?.productSlotId,
                                      slot.productSlot.product.id,
                                      type
                                    )}
                                    id={slot?.productSlotId}
                                    onClick={() => {
                                      if (1 > slot?.availableQuantity) {
                                        return;
                                      }
                                      toggleRadio(slot);
                                    }}
                                  />
                                </Col>
                                <Col className="">
                                  <p className="m-0 text-bold">
                                    {moment(
                                      slot?.productSlot?.startTime,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}
                                  </p>
                                  <p className="m-0">
                                    ({slot?.availableQuantity} Tickets are
                                    available)
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                      {commonSlotsArray?.filter(
                        (slot) =>
                          slot.productSlot.product.id === item?.productId
                      )?.length === 0 && (
                        <Row>
                          <p className="px-10" style={{ color: "red" }}>
                            No Slots Available
                          </p>
                        </Row>
                      )}
                    </Row>
                    {bookingData[i]?.productData?.length > 0 && (
                      <Row className="w-full m-0 align-end" gutter={[20, 20]}>
                        {bookingData[i]?.devoteeData?.map((devotee, j) => {
                          return (
                            <Row
                              className="w-full m-0 align-end"
                              gutter={[20, 20]}
                              key={j}
                            >
                              {/* {bookingData[i]?.productData[0]?.productSlot
                                ?.product?.noOfPeople === 2 ? (
                                personStates?.length > 1 ? (
                                  <>
                                    <TmsSelect
                                      classname="w-3"
                                      label="Husband Name"
                                      type="col"
                                      data={personStates?.map((list) => {
                                        return {
                                          name: list?.name,
                                          id: list?.id,
                                        };
                                      })}
                                      optional={true}
                                      mandatory={true}
                                      value={devotee[0]}
                                      onChange={(e) => {
                                        handleBookingList(e, item);
                                      }}
                                    />
                                    <TmsSelect
                                      classname="w-3"
                                      label="Wife Name"
                                      type="col"
                                      data={personStates?.map((list) => {
                                        return {
                                          name: list?.name,
                                          id: list?.id,
                                        };
                                      })}
                                      optional={true}
                                      mandatory={true}
                                      value={devotee[1]}
                                      onChange={(e) => {
                                        handleBookingList(e, item);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <TmsSelect
                                    classname="w-3"
                                    label="Devotee Name"
                                    type="col"
                                    data={personStates?.map((list) => {
                                      return { name: list?.name, id: list?.id };
                                    })}
                                    optional={true}
                                    mandatory={true}
                                    value={devotee[0]}
                                    onChange={(e) => {
                                      handleBookingList(e, item);
                                    }}
                                  />
                                )
                              ) : ( */}
                              <TmsSelect
                                classname="w-2"
                                label="Devotee Name"
                                type="col"
                                data={personStates?.map((list) => {
                                  return { name: list?.name, id: list?.id };
                                })}
                                optional={true}
                                mandatory={true}
                                value={devotee}
                                onChange={(e) => {
                                  handleBookingList(e, item);
                                }}
                              />
                              {/* )} */}

                              {personStates?.length > j + 1 ? (
                                bookingData[i]?.devoteeData?.length >= j + 1 ? (
                                  bookingData[i]?.devoteeData?.length ===
                                  j + 1 ? (
                                    <Col className="buttonDiv">
                                      <Button
                                        className="add"
                                        onClick={() => addDevotee(item)}
                                      >
                                        +
                                      </Button>
                                    </Col>
                                  ) : (
                                    <Col className="buttonDiv">
                                      <Button
                                        className="add"
                                        onClick={() => removeDevotee(item, j)}
                                      >
                                        <span className="m-0 rotate-45 add">
                                          +
                                        </span>
                                      </Button>
                                    </Col>
                                  )
                                ) : (
                                  " "
                                )
                              ) : (
                                personStates?.length !== 1 && (
                                  <Col className="buttonDiv">
                                    <Button
                                      className="add"
                                      onClick={() => removeDevotee(item, j)}
                                    >
                                      <span className="m-0 rotate-45 add">
                                        +
                                      </span>
                                    </Button>
                                  </Col>
                                )
                              )}
                            </Row>
                          );
                        })}
                      </Row>
                    )}
                  </Row>
                );
              })}{" "}
          </>
        )}
      </Row>
    </Row>
  );
};

export default TmsPosProtocolDevoteeList;
