import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportsRequestsAll from "./tms-reports-all-requests";
import TmsReportsRequestNew from "./tms-reports-request-new";

const TmsReportsRequests = ({ router }) => {
  const [active, setActive] = useState("Reports All");

  useEffect(() => {
    let location = router?.location?.pathname;
    console.log(location);
    if (location === tmsRouteMap.reports_requests) {
      setActive("Reports All");
      return;
    } else if (location === tmsRouteMap.reports_requests_new) {
      setActive("New Report Request");
      return;
    }
  }, [router?.location]);

  const components = [
    {
      name: "Reports All",
      Element: <TmsReportsRequestsAll />,
      key: "Reports All",
      route: tmsRouteMap.reports_requests,
    },
    {
      name: "New Report Request",
      Element: <TmsReportsRequestNew />,
      key: "New Report Request",
      route: tmsRouteMap.reports_requests_new,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
        key={active}
      />
    </div>
  );
};
export default withRouter(TmsReportsRequests);
