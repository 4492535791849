import { Button, Col, notification, Row } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";

import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";

import "../../../../assets/css/tms-reports/tms-reports.scss";
import TmsAccommodationService from "../../../../services/tms-accommodation.service";

const TmsReportAccommodationSummaryReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [setSortType] = useState("DESC");
  const [bookingType, setBookingType] = useState("POS");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [roomType] = useState("");
  const [blocksList, setblocksList] = useState([]);
  const [blockId, setBlockId] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [roomList, setRoomList] = useState([]);

  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await TmsAccommodationService.occupancyReport(
        Number(tableCurrentPage),
        Number(tablePageSize),
        bookingFromDate,
        bookingToDate,
        Number(blockId),
        selectedRoom
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const data = await TmsAccommodationService.occupancyReport(
        Number(tableCurrentPage),
        Number(50000),
        bookingFromDate,
        bookingToDate,
        Number(blockId),
        selectedRoom
      );

      const compiledData = data.items.map((item, i) => {
        return {
          "S.no": i + 1,
          "Block Name": item.blockName,
          "Room Type ": item.roomType,
          "Room Id": item.roomId,
          "Room Number": item.roomNumber,
          "Room Price": item.price,
          "Occupied Days": item.occupiedDays,
        };
      });
      exportExcel(
        compiledData,
        `accommodation-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  const fetchblocksList = async () => {
    try {
      let data = [];
      setblocksList([]);
      setBlockId("");
      // setSelectedRoom("");
      // setRoomList([]);
      {
        data = await TmsAccommodationService.getAllBlocks(1, 200);
      }

      setTabelTotalSize(data?.meta?.totalItems);
      setblocksList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  const fetchRoomList = async () => {
    try {
      setSelectedRoom("");
      setRoomList([]);
      const res = await TmsAccommodationService.getRoomsByBlockId(blockId);
      // console.log(res);
      setRoomList(
        res?.map((item) => {
          return {
            key: item.id,
            name: `${item.description} - ${item.roomNumber}`,
            ...item,
          };
        })
      );
      // if (res?.length > 0) {
      //   setSelectedRoom("");
      //   res?.items[0]?.productSlotId;
      // }
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  useEffect(() => {
    if (blockId !== "") {
      fetchRoomList();
    }
  }, [blockId]);

  useEffect(() => {
    fetchblocksList();
  }, [roomType]);

  useEffect(() => {
    if (router.location?.pathname === "/reports/accommodation/summary-report") {
      fetchBookingDetails();
    }
  }, [tableCurrentPage, tablePageSize, bookingType, router.location?.pathname]);

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Block Name",
      dataIndex: "blockName",
      key: "blockName",
    },
    {
      title: "Room Id",
      dataIndex: "roomId",
      key: "roomId",
    },
    {
      title: "Room Number",
      dataIndex: "roomNumber",
      key: "roomNumber",
    },

    {
      title: "Room Type",
      dataIndex: "roomType",
      key: "roomType",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },

    {
      title: "occupiedDays",
      dataIndex: "occupiedDays",
      key: "occupiedDays",
    },
  ];

  return (
    <div>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
        />

        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Block Name"
          setValue={(e) => {
            setBlockId(e);
            setSelectedRoom("");
          }}
          value={blockId}
          data={blocksList}
        />
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Room Number"
          setValue={setSelectedRoom}
          value={selectedRoom}
          data={roomList}
        />

        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <Col>
          <Row justify="start" style={{ marginBottom: "5px" }}>
            Update
          </Row>
          <Button type="primary" onClick={fetchBookingDetails}>
            Fetch
          </Button>
        </Col>

        <Col style={{ padding: "0" }}>
          <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
          <TmsExcelButton
            loading={excelLoading}
            handleExcelExport={handleExcelExport}
            top={false}
          />
        </Col>

        {/* <TmsSelect
          type="col"
          span={3}
          label="Date Type"
          setValue={setDateType}
          value={dateType}
          data={[
            { id: "booking", name: "Booking" },
            { id: "payment", name: "Payment" },
          ]}
        /> */}
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </div>
  );
};

export default withRouter(TmsReportAccommodationSummaryReport);
