import { Button, Col, notification, Row } from "antd";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { FormatNumberINRCurrency } from "../../../../utils/tms-currency-formatter/tms-curreny-formatter";
import "../../../../assets/css/tms-reports/tms-reports.scss";
import { BaseContext } from "../../../../contexts/BaseContext";
import TmsAccommodationService from "../../../../services/tms-accommodation.service";

const TmsReportAccommodationReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [dateType, setDateType] = useState("booking");
  const [sortType, setSortType] = useState("DESC");
  const [bookingType, setBookingType] = useState("pos");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [roomType, setRoomType] = useState("");
  const [blocksList, setblocksList] = useState([]);
  const [blockId, setBlockId] = useState("");
  const { roomMapData } = useContext(BaseContext);

  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);
  const [sortBy, setSortBy] = useState("bookingDate");
  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [roomList, setRoomList] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("success");

  const API = {
    online: TmsAccommodationService.accommodationReportOnline,
    pos: TmsAccommodationService.accommodationReport,
    advance: TmsAccommodationService.accommodationReportAdvance,
  };

  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await API[bookingType]({
        format: "json",
        tableCurrentPage: Number(tableCurrentPage),
        tablePageSize: Number(tablePageSize),
        fromDate: bookingFromDate,
        toDate: bookingToDate,
        roomType,
        blockId: Number(blockId),
        selectedRoom,
        counter: Number(counter),
        shift: shift,
        dateType,
        sortType,
        sortBy,
        paymentStatus,
      });
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return bookingType === "advance"
            ? { key: item?.id, ...item }
            : { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      let data = await API[bookingType]({
        format: "json",
        tableCurrentPage: Number(1),
        tablePageSize: Number(70000),
        fromDate: bookingFromDate,
        toDate: bookingToDate,
        roomType,
        blockId: Number(blockId),
        selectedRoom,
        counter: Number(counter),
        shift: shift,
        dateType,
        sortType,
        sortBy,
        paymentStatus,
      });

      // data = data?.items?.reverse();
      let compiledDataOffile = [];
      let compiledDataOnline = [];
      let compiledDataOffileAdv = [];
      if (bookingType === "online") {
        compiledDataOnline = data.items.map((item, i) => {
          return {
            "S.no": i + 1,
            "Booking Id": item?.bookingId,
            "Payment ID": item?.onlinePaymentId,
            "Payment gateway": item?.onlinePaymentType,
            "Order ID":
              item?.onlinePaymentType === "razorpay"
                ? item?.razorPayOrderId
                : item?.ccavenueOrderId,
            "Room Type ": item?.roomType,
            "Block Name": item?.blockName,
            "Room Number": item?.roomNumber,
            "Devotee Name": `${item?.firstName} ${item?.lastName}`,
            "Devotee DOB": moment(item?.dob)?.format("DD-MM-YYYY"),
            "Aadhar No": item?.documentId,
            Contact: item?.contact ?? "-",
            Email: item?.email ?? "-",
            address: `${item?.address ?? "-"} ${item?.address2 ?? ""} ${
              item?.city ?? ""
            } ${item?.state ?? ""} ${item?.country ?? ""} ${
              item?.pinCode ?? ""
            }`,
            "Check In Date": item?.checkIn
              ? moment(item?.checkIn)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            "Check Out Date": item?.checkOut
              ? moment(item?.checkOut)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            "Booking Date": item?.bookingDate
              ? moment(item?.bookingDate)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            "Booked On": item?.createdAt
              ? moment(item?.createdAt)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            // "Payment Date": item?.bookingDate
            //   ? moment(item?.paymentDate)?.format("DD-MM-YYYY hh:mm A")
            //   : "-",
            "Room Price": item?.price ?? 0,
            Gst: item?.gst ?? 0,
            Maintenance: item?.maintenanceCharge ?? 0,
            "Total Amount": Number(
              `${
                (item?.gst ?? 0) +
                (item?.price ?? 0) +
                (item?.maintenanceCharge ?? 0)
              }`
            ),
          };
        });
      } else if (bookingType === "pos") {
        compiledDataOffile = data.items.map((item, i) => {
          return {
            "S.no": i + 1,
            "POS Name": item?.counterName,
            "Shift ID": item?.counterShiftTimingId,
            "Booking Id": item?.bookingId,
            "Transaction Id": `${
              item?.categoryTransactionSequence?.split("-")[3]
            }-${item?.categoryTransactionSequence?.split("-")[4]}`,
            "Room Type ": item?.roomType,
            "Block Name": item?.blockName,
            "Room Number": item?.roomNumber,
            "Devotee Name": item?.devoteeName,
            "Devotee DOB": moment(item?.dob)?.format("DD-MM-YYYY"),
            "Aadhar No": item?.documentId,
            Contact: item?.contact ?? "-",
            Email: item?.email ?? "-",
            Address: `${item?.address ?? ""} ${item?.address2 ?? ""} ${
              item?.city ?? ""
            } ${item?.state ?? ""} ${item?.country ?? ""} ${
              item?.pinCode ?? ""
            }`,
            "Check In Date": item?.checkIn
              ? moment(item?.checkIn)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            "Check Out Date": item?.checkOut
              ? moment(item?.checkOut)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            "Booking Date": item?.bookingDate
              ? moment(item?.bookingDate)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            "Booked On": item?.createdAt
              ? moment(item?.createdAt)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            "Room Price": item?.price ?? 0,
            Gst: item?.gst ?? 0,
            Maintenance: item?.maintenanceCharge ?? 0,
            "Total Amount": Number(
              `${
                (item?.gst ?? 0) +
                (item?.price ?? 0) +
                (item?.maintenanceCharge ?? 0)
              }`
            ),
          };
        });
      } else {
        compiledDataOffileAdv = data.items.map((item, i) => {
          return {
            "S.no": i + 1,
            "POS Name": item?.counter?.name,
            "Shift ID":
              item?.advanceCounterCartItem?.counterCart?.counterShiftLoginLog
                ?.counterShiftTimingId,
            "Booking Id": item?.id,
            "Transaction Id": `${
              item?.advanceCounterCartItem?.categoryTransactionSequence?.split(
                "-"
              )[3]
            }-${
              item?.advanceCounterCartItem?.categoryTransactionSequence?.split(
                "-"
              )[4]
            }`,
            "Room Type ": item?.accBlockCategory?.roomType,
            "Block Name": item?.accBlockCategory?.accBlock?.name,
            "Room Number": item?.accPreAssignedRoom?.roomNumber ?? "-",
            "Devotee Name": item?.devoteeName,
            "Devotee DOB": moment(item?.dob)?.format("DD-MM-YYYY"),
            "Aadhar No": item?.documentId,
            Contact: item?.contact ?? "-",
            Email: item?.email ?? "-",
            Address: `${item?.address ?? ""} ${item?.address2 ?? ""} ${
              item?.city ?? ""
            } ${item?.state ?? ""} ${item?.country ?? ""} ${
              item?.pinCode ?? ""
            }`,
            "Booking Date": item?.bookingDate
              ? moment(item?.bookingDate)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            "Booked On": item?.createdAt
              ? moment(item?.createdAt)?.format("DD-MM-YYYY hh:mm A")
              : "-",
            "Room Price": item?.price ?? 0,
            Gst: item?.gst ?? 0,
            "Advance Booking Charge": item?.advanceBookingCharge ?? 0,
            "Total Amount": Number(
              `${
                (item?.gst ?? 0) +
                (item?.price ?? 0) +
                (item?.advanceBookingCharge ?? 0)
              }`
            ),
          };
        });
      }

      exportExcel(
        bookingType === "online"
          ? compiledDataOnline
          : bookingType === "pos"
          ? compiledDataOffile
          : compiledDataOffileAdv,
        `accommodation-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };
  const fetchblocksList = async () => {
    try {
      let data = [];
      setblocksList([]);
      setBlockId("");
      // setSelectedRoom("");
      // setRoomList([]);
      {
        data = await TmsAccommodationService.getAllBlocks(1, 200);
      }

      setTabelTotalSize(data?.meta?.totalItems);
      setblocksList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchRoomList = async () => {
    try {
      setSelectedRoom("");
      setRoomList([]);
      const res = await TmsAccommodationService.getRoomsByBlockId(blockId);
      // console.log(res);
      setRoomList(
        res?.map((item) => {
          return {
            key: item.id,
            name: `${item.description} - ${item.roomNumber}`,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  useEffect(() => {
    fetchCounterList();
    fetchShiftList();
  }, []);

  useEffect(() => {
    if (blockId !== "") {
      fetchRoomList();
    }
  }, [blockId]);

  useEffect(() => {
    // if (roomType !== "") {
    fetchblocksList();
    // }
  }, [roomType]);

  useEffect(() => {
    fetchBookingDetails();
  }, [tableCurrentPage, tablePageSize, bookingType]);

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },

    {
      title: "Devotee Name",
      render: (record) => {
        return `${
          bookingType === "online"
            ? `${record?.firstName} ${record?.lastName}`
            : record?.devoteeName
        }`;
      },
    },

    {
      title: "Room Type",
      dataIndex: bookingType === "advance" ? "accBlockCategory" : "roomType",
      key: bookingType === "advance" ? "accBlockCategory" : "roomType",
      render: (record) => {
        return `${
          bookingType === "advance"
            ? record?.roomType?.replace("_", " ")
            : record?.replace("_", " ")
        }`;
      },
    },
    {
      title: "Block Name",
      dataIndex: bookingType === "advance" ? "accBlockCategory" : "blockName",
      key: "blockName",
      render: (record) => {
        return `${bookingType === "advance" ? record?.accBlock?.name : record}`;
      },
    },
    {
      title: "Room Number",
      dataIndex:
        bookingType === "advance" ? "accPreAssignedRoom" : "roomNumber",
      render: (record) => {
        return `${
          bookingType === "advance" ? record?.roomNumber ?? "-" : record ?? "-"
        }`;
      },
    },

    {
      title: "Check In Date",
      dataIndex: "checkIn",
      render: (record) => {
        return record ? moment(record).format("DD-MM-YYYY hh:mm A") : "Not yet";
      },
      sorter: (a, b) => a.checkIn > b.checkIn,
      hidden: bookingType === "advance",
    },
    {
      title: "Check out Date",
      dataIndex: "checkOut",
      render: (record) => {
        return record ? moment(record).format("DD-MM-YYYY hh:mm A") : "Not Yet";
      },
      sorter: (a, b) => a.checkOut > b.checkOut,
      hidden: bookingType === "advance",
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Booked On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return text ? moment(text).format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: "Order ID",
      key: "bookingId",
      render: (text, record) => {
        return record?.onlinePaymentType === "razorpay"
          ? record?.razorPayOrderId
          : record?.ccavenueOrderId;
      },
      hidden: bookingType !== "online",
    },
    {
      title: "Deposit Amount",
      render: (text) => {
        return `Rs. ${text?.depositAmount ?? 0}`;
      },
    },
    {
      title: "Total Amount",
      render: (text) => {
        return `Rs. ${
          (text?.gst ?? 0) + (text?.price ?? 0) + (text?.maintenanceCharge ?? 0)
        }`;
      },
    },
  ].filter((item) => !item.hidden);

  return (
    <div>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "POS" },
            { id: "online", name: "Online" },
            { id: "advance", name: "POS Advance" },
          ]}
          optional={true}
        />
        {bookingType !== "online" && (
          <TmsSelect
            type="col"
            span={3}
            selectClass="w-100"
            label="Room Type"
            setValue={setRoomType}
            value={roomType}
            data={roomMapData}
          />
        )}
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Block Name"
          setValue={(e) => {
            setBlockId(e);
            if (e === "") {
              setSelectedRoom("");
              return;
            }
          }}
          value={blockId}
          data={blocksList}
        />
        {bookingType !== "online" && (
          <TmsSelect
            type="col"
            span={3}
            selectClass="w-100"
            label="Room Number"
            setValue={setSelectedRoom}
            value={selectedRoom}
            data={roomList}
          />
        )}
        <TmsSelect
          type="col"
          span={3}
          label="POS"
          setValue={setCounter}
          value={counter}
          data={counterList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shift"
          setValue={setShift}
          value={shift}
          data={shiftList}
        />
        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Sort By"
          setValue={setSortBy}
          value={sortBy}
          data={[
            { id: "bookingDate", name: "Booking Date" },
            { id: "checkInDate", name: "Check In" },
            { id: "checkOutDate", name: "Check Out" },
          ]}
          optional={true}
        />
        <TmsSelect
          type="col"
          label="Date Type"
          span={3}
          setValue={setDateType}
          value={dateType}
          data={[
            { id: "booking", name: "Performance" },
            { id: "payment", name: "Booking" },
          ]}
        />
        {bookingType === "online" && (
          <>
            {" "}
            <TmsSelect
              type="col"
              span={3}
              selectClass="w-100"
              label="Payment Status"
              setValue={setPaymentStatus}
              value={paymentStatus}
              data={[
                { id: "created", name: "created" },
                { id: "success", name: "success" },
                { id: "failure", name: "failure" },
                { id: "refund", name: "refund" },
                { id: "canceled", name: "canceled" },
              ]}
              optional={true}
            />
          </>
        )}
        <Col>
          <Row justify="start" style={{ marginBottom: "5px" }}>
            Update
          </Row>
          <Button type="primary" onClick={fetchBookingDetails}>
            Fetch
          </Button>
        </Col>
        <Col style={{ padding: "0" }}>
          <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
          <TmsExcelButton
            loading={excelLoading}
            handleExcelExport={handleExcelExport}
            top={false}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          fontSize={true}
          expandable={{
            expandedRowRender: (record) => (
              <Row justify="space-evenly">
                <Col className="mr-10">
                  <b>Booking ID:</b> {record.key}
                </Col>
                |
                <Col className="mr-10">
                  <b> User phone</b>:
                  {record.contact !== null ? record.contact : "-"}
                </Col>
                |
                <Col className="mr-10">
                  <b> Room Price</b>: {FormatNumberINRCurrency(record.price)}
                </Col>
                |
                <Col className="mr-10">
                  <b> GST</b>: {FormatNumberINRCurrency(record.gst)}
                </Col>
                |
                <Col className="mr-10">
                  <b> Maintenance</b>:
                  {FormatNumberINRCurrency(record.maintenanceCharge)}
                </Col>
              </Row>
            ),
          }}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </div>
  );
};

export default withRouter(TmsReportAccommodationReport);
