import { Button, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import {
  shopleaseAddtionalStates,
  shopLeaseCreationStates,
} from "../../../../../constants/states-data.data";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import useBuildingsStore from "../../../../../zustand/buildings-and-shops.store";
import { BuildingInstitutionSelect } from "../../../../../components/tms-customs/rents-and-leases";
import TmsDatepickerRange from "../../../../../components/tms-customs/tms-datepicker-range";

const TmsBasLeaseReports = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  // const [sortType, setSortType] = useState("ASC");
  // const [sortBy, setSortBy] = useState("createdAt");
  const {
    selectedInstitution,
    setSelectedInstitution,
    loading,
    fetchLeaseReports,
    fetchInstitutionList,
    leaseReports,
    clearStates,
    fetchTotalReports,
    leasePeriodEndDate,
    leasePeriodStartDate,
    setLeasePeriodEndDate,
    setLeasePeriodStartDate,
  } = useBuildingsStore();

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    router.location.pathname === tmsRouteMap.reports_ral_buildings_leases &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(shopLeaseCreationStates, [
        ...shopleaseAddtionalStates,
        "leaseReports",
        "leasePeriodStartDate",
        "leasePeriodEndDate",
      ]);
    };
  }, [router.location.pathname]);

  useEffect(() => {
    if (
      router.location?.pathname === tmsRouteMap.reports_ral_buildings_leases
    ) {
      if (selectedInstitution !== "" && selectedInstitution) {
        fetchLeaseReports(
          tableCurrentPage,
          tablePageSize,
          leasePeriodStartDate,
          leasePeriodEndDate,
          router
        );
      } else {
        fetchTotalReports("LEASE", tableCurrentPage, tablePageSize, router);
      }
    }
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedInstitution,
    leasePeriodEndDate,
    leasePeriodStartDate,
    router?.location?.pathname,
  ]);

  const innerLeaseColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    { title: "Lesse ID", dataIndex: "lesseId", key: "lesseId" },
    {
      title: "Name of Temple",
      dataIndex: "institutionName",
      key: "institutionName",
    },
    {
      title: "Building name",
      dataIndex: "buildingName",
      key: "buildingName",
    },
    {
      title: "Block name",
      dataIndex: "blockOrFloorName",
      key: "blockOrFloorName",
    },
    {
      title: "Shop name",
      dataIndex: "shopId",
      key: "shopId",
    },
    {
      title: "Lesse Name",
      dataIndex: "lesseName",
      key: "lesseName",
    },
    {
      title: "Mobile ",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },

    {
      title: "Lease(From-To)",
      key: "lease",
      render: (record) =>
        `${moment(record.leasePeriodStartDate).format("DD-MM-YYYY")} - ${moment(
          record.leasePeriodEndDate
        ).format("DD-MM-YYYY")}`,
    },
    { title: "Lease Term", dataIndex: "paymentTerm", key: "paymentTerm" },
    { title: "Lease Amount", dataIndex: "leaseRate" },
    { title: "GoodWill Amount", dataIndex: "goodWillAmount" },
    { title: "Deposit ", dataIndex: "depositAmount" },
    { title: "Arrear ", dataIndex: "arrearAmount" },
    {
      title: "Balance ",
      render: (record) => `${record.arrearAmount - record.totalPaidAmount}`,
    },
  ];
  const totalLeaseColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Institution",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Village",
      dataIndex: "village",
      key: "village",
    },
    {
      title: "leased Building Count",
      dataIndex: "leasedBuildingCount",
      key: "leasedBuildingCount",
    },
    {
      title: "leased Shop Count",
      dataIndex: "leasedShopCount",
      key: "leasedShopCount",
    },
    {
      title: "Leased Block Count",
      dataIndex: "leasedBlockCount",
      key: "leasedBlockCount",
    },
    {
      title: "Total Length extent",
      dataIndex: "leasedLengthExtent",
      key: "leasedLengthExtent",
    },
    {
      title: "Total Breadth extent",
      dataIndex: "leasedBreadthExtent",
      key: "leasedBreadthExtent",
    },
  ];
  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <BuildingInstitutionSelect span={6} />

        {selectedInstitution !== "" && (
          <>
            {" "}
            <TmsDatepickerRange
              label={"Range"}
              from={leasePeriodStartDate}
              to={leasePeriodEndDate}
              setFrom={setLeasePeriodStartDate}
              setTo={setLeasePeriodEndDate}
            />
            <Button
              style={{ marginTop: "27px", marginLeft: "10px" }}
              onClick={() => {
                setSelectedInstitution("");
              }}
              type="primary"
            >
              Clear
            </Button>
          </>
        )}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        loading={loading}
        scroll={{ x: "max-content" }}
        style={{
          width: "auto",
          textTransform: "capitalize",
        }}
        columns={
          selectedInstitution !== "" && selectedInstitution
            ? innerLeaseColumns.map((item) => {
                return { ...item, align: "center" };
              })
            : totalLeaseColumns.map((item) => {
                return { ...item, align: "center" };
              })
        }
        dataSource={leaseReports?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: leaseReports?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsBasLeaseReports);
