import { notification } from "antd";
import tmsRouteMap from "../../constants/tms-route.map";

const catchErrorFunction = (error, message, router) => {
  if (error?.response?.status === 401) {
    notification.error({ message: message });
    router.navigate(tmsRouteMap.login);
    return;
  }
  if (error?.response?.status === 500) {
    notification.error({ message: error.response?.data?.message });
    router.navigate("/error/500");
    return;
  } else if (error?.response?.status === 403) {
    notification.error({ message: error.response?.data?.message });
    // console.log("resource");
    return;
  } else if (error.response?.data?.extra?.response?.message) {
    notification.error({
      message: error.response?.data?.extra?.response?.message,
    });
    return;
  } else if (error.response?.data?.message) {
    notification.error({ message: error.response?.data?.message });
    return;
  } else {
    console.error(error);
    notification.error({ message: error?.message ?? "something is wrong" });
    return;
  }
};
export default catchErrorFunction;
