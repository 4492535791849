import React, { useEffect, useState } from "react";
import TmsUserService from "../../services/tms-user/tms-user.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import CustomButton from "../tms-customs/tms-custom-button";
import { ArrowRightOutlined } from "@ant-design/icons";
import InputPhoneNumber from "../tms-customs/tms-input-phone-number";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import { notification } from "antd";
import TmsAuthService from "../../services/tms-user/tms-auth.service";
// import crypto from "crypto";
const SITE_KEY = "6LfKG_QaAAAAAI9rFw0CjwXxTjEUWZkCMzVqyba6";

const TmsLoginStepTwo = ({ setPage, router, setPhone, phone }) => {
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let isregistered = await TmsUserService?.checkUserRegistered(phone);
      if (isregistered) {
        window.grecaptcha.ready(async () => {
          window.grecaptcha
            .execute(SITE_KEY, { action: "submit" })
            .then(async (token) => {
              try {
                let res = await TmsAuthService.sendOtp(phone, token);
                if (res) {
                  notification.success({
                    message: "OTP Sent",
                  });
                }
                setPage(3);
              } catch (error) {
                catchErrorFunction(
                  error,
                  "Token expired!! Login Again",
                  router
                );
                setLoading(false);
              }
            });
        });
        setLoading(false);
      } else {
        notification.error({
          message: "Provided Phone Number is not registered.",
        });
        setLoading(false);
      }
    } catch (e) {
      catchErrorFunction(e, "Token expired! Please login again.", router);
      setLoading(false);
    }
  };
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        // console.log("Script loaded!");
      }
    );
  }, []);

  return (
    <div className="tms-login-step-one">
      <h3 className="login-two-heading">Forgot Password</h3>
      <div className="tms-input">
        <InputPhoneNumber
          size="medium"
          setState={setPhone}
          value={phone}
          // onKeyDown={onKeyDown}
          error={phoneError}
          setError={setPhoneError}
        />
        <p className="tms-error-p">{phoneError !== "" ? phoneError : ""}</p>
      </div>
      <p className="cursor">
        If you remember password,{" "}
        <span
          style={{ color: "#407dd0" }}
          onClick={() => {
            setPage(1);
          }}
        >
          Login
        </span>
      </p>
      <CustomButton
        type={"danger"}
        size="medium"
        loading={loading}
        onClickFunction={handleSubmit}
        icon={
          <ArrowRightOutlined
            style={{ marginRight: "-5px", fontSize: "12px" }}
          />
        }
      />
    </div>
  );
};
export default withRouter(TmsLoginStepTwo);
