import { Row } from "antd";
import React, { useEffect } from "react";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import "../../../../../../assets/css/tms-common/tms-common.scss";
import { FdrInstitutionSelect } from "../../../../../../components/tms-customs/rents-and-leases/index";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  createInvestmentStates,
  investmentTypesData,
} from "../../../../../../constants/states-data.data";
import useFdrStore from "../../../../../../zustand/fdr.store";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
const TmsInvestmentCreation = ({ router, setVisible }) => {
  const {
    investmentType,
    setInvestmentType,
    setSubmitLoading,
    submitLoading,
    fetchInstitutionList,
    handleInvestmentCreation,
    clearStates,
  } = useFdrStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createInvestmentStates, ["institutionData"]);
    };
  }, []);

  return (
    <div>
      <Row gutter={[20, 30]} justify="center" style={{ marginBottom: "15px" }}>
        <FdrInstitutionSelect span={8} />
        <TmsSelect
          selectClass="w-100 "
          classname="mr-0 "
          type="col"
          value={investmentType}
          setValue={setInvestmentType}
          label="Account Type"
          data={investmentTypesData}
          span={8}
          optional={true}
        />
      </Row>

      <TmsCancelSubmitButtons
        loading={submitLoading}
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(createInvestmentStates, ["institutionData"]);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleInvestmentCreation(router);
        }}
      />
    </div>
  );
};

export default withRouter(TmsInvestmentCreation);
