import { Col, Divider, Input, Row } from "antd";
import React, { useEffect } from "react";
import TmsAddress from "../../../../../../components/tms-customs/tms-address";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import TmsImageUpload from "../../../../../../components/tms-customs/tms-image-upload";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import TmsLandAcreCentsInput from "../../../../../../components/tms-customs/tms-land-acre-cents-input";
import TmsBoundayElement from "../../../../../../components/tms-customs/tms-bounday-element";
import {
  createSurveyNoStates,
  documentTypesData,
  natureOfLandData,
  surveyLandtypes,
  waterSourceData,
  yesOrNotData,
  additionalSurveyStates,
} from "../../../../../../constants/states-data.data";
import { InstitutionSelect } from "../../../../../../components/tms-customs/rents-and-leases";
import LandCreationSelect from "../../../../../../components/tms-customs/rents-and-leases/land-creation-select";
import TmsInput from "../../../../../../components/tms-customs/tms-input";
const TmsSurveyNoCreation = ({ router, setVisible }) => {
  const {
    selectedLand,
    pattadarPassbookReason,
    pattadarPassbookDocument,
    setPattadarPassbookDocument,
    setPattadarPassbookReason,
    natureOfLand,
    setNatureOfLand,
    nonAgriculturalAcres,
    setNonAgriculturalAcres,
    agriculturalAcres,
    setAgriculturalAcres,
    agriculturalCents,
    setAgriculturalCents,
    nonAgriculturalCents,
    setNonAgriculturalCents,
    pattadarPassbook,
    setPattadarPassbook,
    surveyNumber,
    setSurveyNumber,
    zip,
    setZip,
    state,
    setState,
    country,
    setCountry,
    district,
    setDistrict,
    totalLandValue,
    setTotalLandValue,
    village,
    setVillage,
    mandal,
    setMandal,
    setGeoTagging,
    geoTagging,
    waterSource,
    setWaterSource,
    donorName,
    setDonorName,
    submitLoading,
    fetchInstitutionList,
    selectedInstitution,
    remarks,
    setRemarks,
    donorDoc,
    setDonorDoc,
    donorDocNo,
    setDonorDocNo,
    donorVillage,
    setDonorVillage,
    donorCity,
    setDonorCity,
    donorDistrict,
    setDonorDistrict,
    donorCountry,
    setDonorCountry,
    donorState,
    setDonorState,
    donorPincode,
    setDonorPincode,
    donorDocType,
    setDonorDocType,
    setDonorAddress,
    donorAddress,
    setDonorPhone,
    donorPhone,
    setDonorRelationName,
    DonorRelationName,
    fetchCreatedLandList,
    handleCreateSurveyNumber,
    clearStates,
    setSubmitLoading,
    status,
    setStatus,
    typeOfLand,
    setTypeOfLand,
    calculateAcresAndCents,
  } = useRentsAndLandStore();
  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createSurveyNoStates, additionalSurveyStates);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedLandList(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  return (
    <div>
      {" "}
      <Row gutter={[20, 20]} style={{ marginBottom: "15px" }}>
        <InstitutionSelect span={6} />
        <LandCreationSelect span={6} />
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={typeOfLand}
          setValue={setTypeOfLand}
          label="Land type"
          data={surveyLandtypes}
          span={6}
          optional={true}
        />
        <Col span={6}>
          <Row className={"t-left"}>Survey Number</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Survey Number"
            value={surveyNumber}
            onChange={(e) => {
              setSurveyNumber(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={pattadarPassbook}
          setValue={setPattadarPassbook}
          label="Pattadar Passbook"
          data={yesOrNotData}
          span={6}
          optional={true}
        />
        {pattadarPassbook ? (
          <Col span={6}>
            <Row className={"t-left"} style={{ marginBottom: "5px" }}>
              If yes, Upload Document
            </Row>
            <TmsImageUpload
              fileList={pattadarPassbookDocument}
              setFileList={setPattadarPassbookDocument}
              router={router}
              type="private"
              accept={true}
              className="w-100"
            />
          </Col>
        ) : (
          <Col span={6}>
            <Row className={"t-left"}>If No, Enter Reason</Row>
            <Input
              maxLength={220}
              type="text"
              placeholder="Enter Reason"
              value={pattadarPassbookReason}
              onChange={(e) => setPattadarPassbookReason(e.target.value)}
            />
          </Col>
        )}
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={natureOfLand}
          setValue={setNatureOfLand}
          label="Nature of Land"
          data={natureOfLandData}
          span={6}
          optional={true}
        />
        <Divider />
        <TmsLandAcreCentsInput
          valueOne={
            calculateAcresAndCents(
              selectedLand?.agricultureLandAcres,
              selectedLand?.agricultureLandCents,
              selectedLand?.nonAgricultureLandAcres,
              selectedLand?.nonAgricultureLandCents
            )?.totalAcres
          }
          setValueOne={() => {}}
          valueTwo={
            calculateAcresAndCents(
              selectedLand?.agricultureLandAcres,
              selectedLand?.agricultureLandCents,
              selectedLand?.nonAgricultureLandAcres,
              selectedLand?.nonAgricultureLandCents
            )?.totalCents
          }
          setValueTwo={() => {}}
          colspace={8}
          label={` Total Land`}
          disabled={true}
        />
        <TmsLandAcreCentsInput
          valueOne={
            calculateAcresAndCents(
              selectedLand?.balanceAgriLandAcres,
              selectedLand?.balanceAgriLandCents,
              selectedLand?.balanceNonAgriLandAcres,
              selectedLand?.balanceNonAgriLandCents
            )?.totalAcres
          }
          setValueOne={() => {}}
          valueTwo={
            calculateAcresAndCents(
              selectedLand?.balanceAgriLandAcres,
              selectedLand?.balanceAgriLandCents,
              selectedLand?.balanceNonAgriLandAcres,
              selectedLand?.balanceNonAgriLandCents
            )?.totalCents
          }
          setValueTwo={() => {}}
          colspace={8}
          label={` Total Available Land`}
          disabled={true}
        />
        <Col span={8}>
          <Row className={"t-left"}>Total Land Value</Row>
          <Input
            maxLength={220}
            prefix="Rs."
            type="number"
            min="0"
            placeholder="Enter land Value"
            value={totalLandValue}
            onChange={(e) => setTotalLandValue(e.target.value)}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <TmsLandAcreCentsInput
          valueOne={selectedLand?.agricultureLandAcres}
          setValueOne={() => {}}
          valueTwo={selectedLand?.agricultureLandCents}
          setValueTwo={() => {}}
          colspace={8}
          label="Agricultural Land"
          disabled={true}
        />
        <TmsLandAcreCentsInput
          valueOne={selectedLand?.balanceAgriLandAcres}
          setValueOne={() => {}}
          valueTwo={selectedLand?.balanceAgriLandCents}
          setValueTwo={() => {}}
          colspace={8}
          label="Available Agricultural Land"
          disabled={true}
        />{" "}
        <TmsLandAcreCentsInput
          valueOne={agriculturalAcres}
          setValueOne={setAgriculturalAcres}
          valueTwo={agriculturalCents}
          setValueTwo={setAgriculturalCents}
          colspace={8}
          label=" Agricultural Land"
          maxValueOne={selectedLand?.agricultureLandAcres}
          // maxValueTwo={selectedLand?.agricultureLandCents}
        />
        <TmsLandAcreCentsInput
          valueOne={selectedLand?.nonAgricultureLandAcres}
          setValueOne={() => {}}
          valueTwo={selectedLand?.nonAgricultureLandCents}
          setValueTwo={() => {}}
          colspace={8}
          label="Non-Agricultural Land"
          disabled={true}
        />
        <TmsLandAcreCentsInput
          valueOne={selectedLand?.balanceNonAgriLandAcres}
          setValueOne={() => {}}
          valueTwo={selectedLand?.balanceNonAgriLandCents}
          setValueTwo={() => {}}
          colspace={8}
          label="Available Non-Agricultural Land"
          disabled={true}
        />{" "}
        <TmsLandAcreCentsInput
          valueOne={nonAgriculturalAcres}
          setValueOne={setNonAgriculturalAcres}
          valueTwo={nonAgriculturalCents}
          setValueTwo={setNonAgriculturalCents}
          colspace={8}
          label="Non-Agricultural Land"
          maxValueOne={selectedLand?.nonAgricultureLandAcres}
          // maxValueTwo={selectedLand?.nonAgricultureLandCents}
        />
        <TmsBoundayElement />
        <TmsAddress
          span={8}
          village={village}
          setVillage={setVillage}
          mandal={mandal}
          setMandal={setMandal}
          district={district}
          setDistrict={setDistrict}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          type="general"
        />{" "}
        <Col span={8}>
          <Row className={"t-left"}>Geo tagging </Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Boundary Value"
            value={geoTagging}
            onChange={(e) => setGeoTagging(e.target.value)}
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={waterSource}
          setValue={setWaterSource}
          label="Water Source"
          data={waterSourceData}
          span={8}
          optional={true}
        />
      </Row>
      <Divider />
      <h2 className="t-left">Land Donated By</h2>
      <Row gutter={[20, 20]}>
        <TmsInput
          type="text"
          label="Donor Name"
          value={donorName}
          setValue={setDonorName}
          span={6}
          placeholder={"Donor Name"}
        />
        <TmsInput
          type="text"
          label="Father of / Son of / Daughter of, Wife of"
          value={DonorRelationName}
          setValue={setDonorRelationName}
          span={6}
          placeholder={"Father of / Son of / Daughter of, Wife of"}
        />
        <Col span={6}>
          <Row className={"t-left"}>Contact Number</Row>
          <Input
            size="medium"
            type="number"
            min="0"
            onWheel={(event) => event.currentTarget.blur()}
            maxLength={10}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            addonBefore={"+91"}
            placeholder="Please Enter Mobile Number"
            style={{ width: "100%" }}
            value={donorPhone}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              setDonorPhone(`${e.target.value}`);
            }}
          />
        </Col>
        <Col span={6}>
          <Row className={"t-left"}>Address </Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Donor Address"
            value={donorAddress}
            onChange={(e) => setDonorAddress(e.target.value)}
          />
        </Col>
        <TmsAddress
          span={6}
          village={donorVillage}
          setVillage={setDonorVillage}
          mandal={donorCity}
          setMandal={setDonorCity}
          district={donorDistrict}
          setDistrict={setDonorDistrict}
          country={donorCountry}
          setCountry={setDonorCountry}
          state={donorState}
          setState={setDonorState}
          zip={donorPincode}
          setZip={setDonorPincode}
          type="donor"
        />{" "}
      </Row>
      <Row gutter={[20, 20]} className="mt-20">
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={donorDocType}
          setValue={setDonorDocType}
          label="Donor Document Type"
          data={documentTypesData}
          span={6}
          optional={true}
        />
        <Col span={6}>
          <Row className={"t-left"}>Document Number</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Document Number"
            value={donorDocNo}
            onChange={(e) => setDonorDocNo(e.target.value)}
          />
        </Col>
        <Col span={6}>
          <Row className={"t-left"} style={{ marginBottom: "5px" }}>
            Document Upload
          </Row>
          <TmsImageUpload
            fileList={donorDoc}
            setFileList={setDonorDoc}
            router={router}
            type="private"
            accept={true}
            className="w-100"
          />
        </Col>
        <Col span={6}>
          <Row className={"t-left"}>Remarks</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <TmsCheckbox
        label={"Survey Status"}
        value={status}
        setValue={setStatus}
      />
      <TmsCancelSubmitButtons
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(createSurveyNoStates, additionalSurveyStates);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => !submitLoading && handleCreateSurveyNumber(router)}
        loading={submitLoading}
      />
    </div>
  );
};

export default withRouter(TmsSurveyNoCreation);
