import { Button, Row } from "antd";
import React, { useState } from "react";
import TmsModal from "../../../../../../components/tms-customs/tms-modal";
import TmsSmBasArrearList from "../created-list-inner-pages/tms-sm-bas-arears-list";
import TmsArearsCreation from "../creation-inner-pages/tms-bas-arears-creation";

const BuildingArrear = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Row justify="start">
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
          }}
        >
          New Arrear Creation
        </Button>
      </Row>
      <br />
      {!visible && <TmsSmBasArrearList />}
      <TmsModal
        centered={true}
        width="80%"
        closable={false}
        visible={visible}
        title={"Building Arrear Creation "}
        onClose={() => {
          setVisible(false);
        }}
      >
        {visible && <TmsArearsCreation setVisible={setVisible} />}
      </TmsModal>
    </div>
  );
};

export default BuildingArrear;
