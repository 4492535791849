import { Col, Popconfirm, Row, Space, Switch, Table } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import { SortTypeAndBy } from "../../../../../../components/tms-customs/rents-and-leases";
import { DeleteOutlined } from "@ant-design/icons";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";

const TmsCreatedSurveyNoList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");

  const {
    selectedInstitution,
    setSelectedInstitution,
    loading,
    clearStates,
    institutionData,
    createdSurveryNumberData,
    fetchInstitutionList,
    fetchSurveyNumbers,
    handleChangeStatus,
    deleteCreatedSurvey,
    deleteLoading,
    excelLoading,
    handleExcelExport,
  } = useRentsAndLandStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  useEffect(() => {
    return () => {
      clearStates(
        [
          "institutionData",
          "createdSurveryNumberData",
          "selectedInstitution",
          "selectedSurveyNumber",
        ],
        []
      );
    };
  }, []);
  useEffect(() => {
    router.location.pathname === tmsRouteMap.sm_ral_lands_survey_no_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution) {
      fetchSurveyNumbers(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
    }
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedInstitution,
    update,
  ]);

  const switchTrigger = async (record, status) => {
    await handleChangeStatus(record, status, router);
    setUpdate(Math.random() * 100000 + 21);
  };
  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <TmsSelect
          selectClass="w-100 "
          classname=" align-center"
          labelClass="mr-10 mb-0"
          type="col"
          value={selectedInstitution}
          setValue={setSelectedInstitution}
          label="Select Institution"
          data={institutionData?.items?.map((item) => {
            return { name: item.name, id: item.id };
          })}
          span={6}
          optional
        />
        <SortTypeAndBy
          span={6}
          sortBy={sortBy}
          sortType={sortType}
          setSortBy={setSortBy}
          setSortType={setSortType}
        />
        {selectedInstitution !== "" && selectedInstitution && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() => handleExcelExport("survey", router)}
              top={false}
            />
          </Col>
        )}
      </Row>

      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          { title: "Donor Name", dataIndex: "donorName" },
          {
            title: "Land ID",
            key: "landId",
            dataIndex: "landId",
          },
          { title: "Survey No", dataIndex: "surveyNo" },
          { title: "type", dataIndex: "typeOfLand" },
          {
            title: "Agricultural",
            children: [
              {
                title: "Acres",
                dataIndex: "agricultureLandAcres",
                key: "agricultureLandAcres",
                align: "center",
              },
              {
                title: "Cents",
                dataIndex: "agricultureLandCents",
                key: "agricultureLandCents",
                align: "center",
              },
            ],
          },
          {
            title: "Non Agricultural",
            children: [
              {
                title: "Acres",
                dataIndex: "nonAgricultureLandAcres",
                key: "nonAgricultureLandAcres",
                align: "center",
              },
              {
                title: "Cents",
                dataIndex: "nonAgricultureLandCents",
                key: "nonAgricultureLandCents",
                align: "center",
              },
            ],
          },
          {
            title: "Total Land",
            children: [
              {
                title: "Acres",
                dataIndex: "totalLandAcres",
                key: "totalLandAcres",
                align: "center",
              },
              {
                title: "Cents",
                dataIndex: "totalLandCents",
                key: "totalLandCents",
                align: "center",
              },
            ],
          },

          {
            title: "Village",
            dataIndex: "village",
            align: "center",
          },

          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (record) => moment(record).format("DD-MM-YYYY hh:mm A"),
          },
          {
            title: "Status",
            key: "status",
            render: (record) => {
              return (
                <Switch
                  size={"small"}
                  checked={record.status}
                  onClick={() => {
                    switchTrigger(record?.id, !record.status);
                  }}
                />
              );
            },
          },
          {
            title: "Action",
            render: (record) => {
              return (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure delete this institution?"
                    onConfirm={async () => {
                      await deleteCreatedSurvey(record.id, router);
                      setUpdate(Math.random() * 10000 + 999);
                    }}
                    okText="Yes"
                    cancelText="No"
                    loading={deleteLoading}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Space>
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={
          createdSurveryNumberData?.items?.map((item) => {
            return {
              key: item.id,
              ...item,
            };
          }) ?? []
        }
        onChange={handleTableChange}
        pagination={{
          total: createdSurveryNumberData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                <p>
                  {" "}
                  Donor Doc: {record.donorDocumentType}-{" "}
                  {record.donorDocumentNumber}, Donor Address:{" "}
                  {record.donorAddress} - Mandal - {record.mandal}, City:{" "}
                  {record.donorCity}, State: {record.donorState}, Pincode:{" "}
                  {record.donorPincode} Country: {record.donorCountry}
                </p>
              </>
            );
          },
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsCreatedSurveyNoList);
