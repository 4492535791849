import React, { useRef } from "react";
import {
  Button,
  Col,
  notification,
  Row,
  Table,
  Drawer,
  Card,
  Statistic,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsDonationService from "../../../../services/tms-donation.service";
import TmsDarshanamService from "../../../../services/tms-darshanam/tms-darshanam.service";
import TmsPrasadamService from "../../../../services/tms-prasadam.service";
import TmsTollgateService from "../../../../services/tms-tollgate.service";
import TmsThulabharamService from "../../../../services/tms-thulabharam.service";
import "../../../../assets/css/tms-reports/tms-reports.scss";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import { TmsConsildatedColums } from "../../../../constants/tms-consolidated-report-columns";
import TmsSevasService from "../../../../services/tms-sevas.service";
import TmsModal from "../../../../components/tms-customs/tms-modal";
import { useReactToPrint } from "react-to-print";
import { DownloadOutlined } from "@ant-design/icons";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import TmsCommonService from "../../../../services/tms-common.service";
import TmsKalayanakattaService from "../../../../services/tms-kalayanakatta.service";
import { tmsLogo } from "../../../../assets/images/tms-image-list";
import TmsAccommodationService from "../../../../services/tms-accommodation.service";
import TmsManualEntryService from "../../../../services/tms-manual-entry.service";
import TmsMiscService from "../../../../services/tms-misc.service";
import TmsProductsService from "../../../../services/tms-products.service";
import TmsBusService from "../../../../services/tms-bus-service";
import TmsPublicationService from "../../../../services/tms-publications.service";

const TmsConsolidatedReport = ({ router }) => {
  const [FromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [countersList, setCounterList] = useState([]);
  const [selectedCounter, setSelectedCounter] = useState("");
  const [shiftList, setShiftList] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [active, setActive] = useState("Sevas");
  const [printLoading, setPrintLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modal, setModal] = useState(false);
  const refContainer = useRef(null);
  const [bookingType, setBookingType] = useState("pos");
  const [menu, setMenu] = useState([]);
  const [accType, setAccType] = useState("counter_accommodation");

  const [dateType, setDateType] = useState("payment");

  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  const [groupBy, setGroupBy] = useState("");

  const groupByList = [
    { name: "Date", id: "date" },
    { name: "None", id: "" },
  ];
  const keys = {
    counter_accommodation: "Accommodation",
    counter_accommodation_update: "Accommodation Update",
    counter_darshanam: "Darshanam",
    counter_donation: "Donation",
    counter_kalyanakatta: "Kalyanakatta",
    counter_manual_entry: "Manual Entry",
    counter_misc_product: "Products",
    counter_prasadam: "Prasadam",
    counter_seva: "Sevas",
    counter_thulabharam: "Thulabharam",
    counter_tollgate: "Toll Gate",
    counter_publications: "Publications",
    online_accommodation: "Online Accommodation",
    online_darshanam: "Online Darshanam",
    online_donation: "Online Donation",
    online_publication: "Online Publication",
    online_seva: "Online Seva",
  };
  const menuService = {
    Sevas: TmsSevasService,
    Darshanam: TmsDarshanamService,
    Donation: TmsDonationService,
    Prasadam: TmsPrasadamService,
    Thulabharam: TmsThulabharamService,
    Tollgate: TmsTollgateService,
    Kalyanakatta: TmsKalayanakattaService,
    Accommodation: TmsAccommodationService,
    "Manual Entry": TmsManualEntryService,
    Miscellaneous: TmsMiscService,
    Products: TmsProductsService,
    "Bus Booking": TmsBusService,
    Publications: TmsPublicationService,
    All: TmsCommonService,
  };

  useEffect(() => {
    getCounters();
    getShifts();
  }, []);

  useEffect(() => {
    bookingType === "pos"
      ? setMenu([
          "Sevas",
          "Darshanam",
          "Donation",
          "Accommodation",
          "Prasadam",
          "Thulabharam",
          "Kalyanakatta",
          "Tollgate",
          "Products",
          "Miscellaneous",
          "Manual Entry",
          "Publications",
          "Bus Booking",
          "All",
        ])
      : setMenu(["Sevas", "Darshanam", "Donation", "Accommodation", "All"]);
  }, [bookingType]);

  useEffect(() => {
    fetchReport();
  }, [active, bookingType, accType, groupBy, dateType]);

  const fetchReport = async () => {
    setDataLoading(true);
    if (!FromDate || !toDate) {
      notification.error({ message: "Please select a valid date Range" });
      setDataLoading(false);
      return;
    }
    // if (["Products"].includes(active)) {
    //   setData([]);
    //   setDataLoading(false);
    //   return;
    // }
    try {
      // let CounterIds = selectedCounter.map((item) => item.id)?.join(",");
      // let ShiftIds = selectedShift.map((item) => item.id)?.join(",");

      let respData = {};
      if (bookingType === "pos") {
        respData = await menuService[active].getConsolidateReport({
          format: "json",
          productId: "",
          from: FromDate,
          to: toDate,
          counter: selectedCounter,
          shift: selectedShift,
          accType: accType,
          groupBy: groupBy,
          bookingType: dateType,
        });
      } else {
        respData = await menuService[active].getOnlineConsolidateReport({
          format: "json",
          productId: "",
          from: FromDate,
          to: toDate,
          groupBy: groupBy,
          bookingType: dateType,
        });
      }

      const tempData =
        active === "All"
          ? respData
          : respData.map((item) => {
              return {
                ...item,
                Date:
                  item.bookingDate && dateType === "booking"
                    ? `${moment(item.bookingDate).format("DD-MM-YYYY")}`
                    : item.createdDate
                    ? `${moment(item.createdDate).format("DD-MM-YYYY")}`
                    : `${moment(FromDate).format("DD-MM-YYYY")} -> ${moment(
                        toDate
                      ).format("DD-MM-YYYY")}`,
              };
            });
      setData(tempData);
      respData = {};
      setDataLoading(false);

      // setTabelTotalSize(respData?.length);
    } catch (error) {
      catchErrorFunction(error, "Token expired! Please login again.", router);
      setDataLoading(false);
    }
  };

  const getCounters = async () => {
    try {
      const counters = await TmsCounterService.getAllCounter();
      setCounterList(counters?.items);
    } catch (error) {
      catchErrorFunction(error, "Token exipred! Please login again.", router);
    }
  };

  const getShifts = async () => {
    try {
      const res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (error) {
      catchErrorFunction(error, "Token expired! please login again.", router);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "tms-report-container",
    onAfterPrint: () => {
      setModal(false);
      setModalData([]);
      setPrintLoading(false);
    },
  });

  const handleUserDetails = () => {
    // setUserDetails(undefined);
    setShowUserDetails(false);
  };

  const printReport = async () => {
    setPrintLoading(true);
    try {
      const respData =
        bookingType === "pos"
          ? await menuService[active].getConsolidateReport({
              format: "json",
              productId: "",
              from: FromDate,
              to: toDate,
              counter: selectedCounter,
              shift: selectedShift,
              accType: accType,
              groupBy: groupBy,
              bookingType: dateType,
            })
          : await menuService[active].getOnlineConsolidateReport({
              format: "json",
              productId: "",
              from: FromDate,
              to: toDate,
              groupBy: groupBy,
              bookingType: dateType,
            });

      const tempData =
        active === "All"
          ? respData
          : respData.map((item) => {
              return {
                ...item,
                Date: `${moment(FromDate).format("DD-MM-YYYY")} -> ${moment(
                  toDate
                ).format("DD-MM-YYYY")}`,
              };
            });
      setData(tempData);
      setDataLoading(false);
      setModalData(tempData ?? []);
      setModal(true);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setModalData(false);
      setPrintLoading(false);
      setModal(false);
    }
  };

  const groupDataByDate = (originalData) => {
    const groupedData = {};

    // Iterate over the original data
    originalData.forEach((item) => {
      // Extract the date from the item's createdDate using Moment.js
      const createdDate = moment(
        dateType === "booking" && item.bookingDate
          ? item.bookingDate
          : item.createdDate
      );
      const dateKey = createdDate.format("DD-MM-YYYY");

      // If the date key doesn't exist in groupedData, initialize it with an empty array
      if (!groupedData[dateKey]) {
        groupedData[dateKey] = [];
      }

      // Push the item to the array corresponding to the date key
      groupedData[dateKey].push(item);
    });
    // Extract keys (dates) from the groupedData object and sort them
    const sortedDates = Object.keys(groupedData).sort((a, b) => {
      // Convert date strings to Moment.js objects for comparison
      const dateA = moment(a, "DD-MM-YYYY");
      const dateB = moment(b, "DD-MM-YYYY");
      return dateA - dateB;
    });
    let sortedGroupedData = {};
    // Construct the sorted array of grouped data
    sortedDates.forEach((date) => {
      sortedGroupedData[date] = groupedData[date];
    });
    return sortedGroupedData;
  };

  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const data =
        bookingType === "pos"
          ? await menuService[active].getConsolidateReport({
              format: "json",
              productId: "",
              from: FromDate,
              to: toDate,
              counter: selectedCounter,
              shift: selectedShift,
              groupBy: groupBy,
              bookingType: dateType,
            })
          : await menuService[active].getOnlineConsolidateReport({
              format: "json",
              productId: "",
              from: FromDate,
              to: toDate,
              groupBy: groupBy,
              bookingType: dateType,
            });

      const compiledData = data.map((item, i) => {
        let data =
          bookingType === "pos"
            ? {
                "S.no": i + 1,
                "Product ID": item.productId,
                "Product Name": item.productName,
                Date:
                  dateType === "booking" && item.bookingDate
                    ? moment(item.bookingDate).format("DD-MM-YYYY")
                    : item.createdDate
                    ? moment(item.createdDate).format("DD-MM-YYYY")
                    : `${moment(FromDate).format("DD-MM-YYYY")} -> ${moment(
                        toDate
                      ).format("DD-MM-YYYY")}`,
                "Ticket S.no (Start)": item.ticketStart,
                "Ticket S.no (End)": item.ticketEnd,
              }
            : {
                "S.no": i + 1,
                "Product ID": item.productId,
                "Product Name": item.productName,
                Date: `${moment(FromDate).format("DD-MM-YYYY")} -> ${moment(
                  toDate
                ).format("DD-MM-YYYY")}`,
              };
        if (active !== "Donation") {
          data["Unit Price"] = item.productPrice;
        }
        data.Quantity = item.totalTickets;
        data.Amount = item.totalCollection;
        return data;
      });

      exportExcel(
        groupBy === "date"
          ? compiledData.sort((a, b) => moment(a) - moment(b))
          : compiledData,
        `${active}-consolidated-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  const renderSingleCategoryTable = (type, upData, keyValue) => {
    if (type === "sort" && groupBy === "date") {
      const updatedData = groupDataByDate(upData ?? data);
      return Object.entries(updatedData).map(([key, value], i) => {
        return (
          <div key={i} style={{ marginBottom: "10px", background: "white" }}>
            <div className="heading-center">
              {groupBy !== "date" ? active : key}
            </div>
            <Table
              style={{ width: "100%", textTransform: "capitalize" }}
              columns={TmsConsildatedColums(active, bookingType, groupBy)}
              dataSource={
                value?.map((item) => {
                  return item;
                }) ?? []
              }
              loading={dataLoading}
              scroll={{ x: true }}
              size="small"
              align="center"
              className={"row-align"}
              pagination={false}
            />
          </div>
        );
      });
    } else {
      return (
        <div style={{ background: "white" }}>
          {keyValue && <div className="heading-center">{keyValue}</div>}
          <Table
            style={{ width: "100%", textTransform: "capitalize" }}
            columns={TmsConsildatedColums(
              keyValue ?? active,
              bookingType,
              groupBy
            )}
            dataSource={upData ?? data ?? []}
            loading={dataLoading}
            scroll={{ x: true }}
            size="small"
            align="center"
            className={"row-align"}
            pagination={false}
          />
        </div>
      );
    }
  };

  const renderConsolidatedTables = () => {
    if (dataLoading) {
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "white",
          padding: "50px",
          margin: "0",
        }}
      >
        <img
          src={tmsLogo}
          alt="logo"
          height={window.innerWidth < 650 ? "50px" : "70px"}
          style={{
            border: "2px solid orange",
            borderRadius: "50%",
          }}
        />
        <br />
        <p style={{ textAlign: "center" }}>Loading...</p>
      </div>;
    } else {
      if (data?.meta?.totalTickets > 0) {
        if (groupBy === "date") {
          const dataFiltered = groupDataByDate(
            Object.entries(data.data).reduce((accummulator, [key, value]) => {
              if (value.length === 0) return accummulator;

              accummulator = [
                ...accummulator,
                ...value.map((item) => {
                  return { ...item, key: key };
                }),
              ];

              return accummulator;
            }, [])
          );
          const extractedData = {};
          Object.entries(dataFiltered).forEach(([key, value]) => {
            const filteredObject = {};
            value.forEach((obj) => {
              const key = obj.key;
              if (!filteredObject[key]) {
                filteredObject[key] = [];
              }
              filteredObject[key].push(obj);
            });
            extractedData[key] = filteredObject;
          });

          return Object.entries(extractedData).map(([key, value], i) => {
            return (
              <div key={"consolidated" + i}>
                <div className="heading-center">{key}</div>
                <div>
                  {Object.entries(value).map(([key2, value2]) => {
                    return renderSingleCategoryTable("", value2, keys[key2]);
                  })}
                </div>
              </div>
            );
          });
        } else {
          return (
            <>
              {Object.entries(data.data).map(([key, value]) => {
                return value.length === 0
                  ? null
                  : renderSingleCategoryTable(
                      "",
                      value.map((item) => {
                        return {
                          ...item,
                          Date:
                            dateType === "booking" && item.bookingDate
                              ? moment(item.bookingDate).format("DD-MM-YYYY")
                              : item.createdDate
                              ? `${moment(item.createdDate).format(
                                  "DD-MM-YYYY"
                                )}`
                              : `${moment(FromDate).format(
                                  "DD-MM-YYYY"
                                )} -> ${moment(toDate).format("DD-MM-YYYY")}`,
                        };
                      }),
                      keys[key]
                    );
              })}
            </>
          );
        }
      }
    }
  };

  return (
    <>
      <Row style={{ marginBottom: "20px" }} justify={"start"} gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
          optional={true}
        />
        <Col>
          <TmsDatePickerRange
            label={"Date range"}
            from={FromDate}
            to={toDate}
            setFrom={setFromDate}
            setTo={setToDate}
          />
        </Col>

        <TmsSelect
          type="col"
          span={3}
          label="Counters"
          selectClass="w-100"
          setValue={setSelectedCounter}
          value={selectedCounter}
          data={countersList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shifts"
          selectClass="w-100"
          setValue={setSelectedShift}
          value={selectedShift}
          data={shiftList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Group By"
          selectClass="w-100"
          setValue={setGroupBy}
          value={groupBy}
          data={groupByList}
          optional={true}
        />
        {active === "Accommodation" && (
          <>
            <TmsSelect
              type="col"
              span={3}
              label="Accommodation Type"
              selectClass="w-100"
              setValue={setAccType}
              value={accType}
              data={[
                { id: "counter_accommodation", name: "General" },
                { id: "counter_accommodation_advance", name: "Advance" },
                {
                  id: "counter_accommodation_update",
                  name: "Check Out/Renewal",
                },
              ]}
              optional={true}
            />
          </>
        )}
        <TmsSelect
          type="col"
          span={3}
          label="Results By"
          setValue={setDateType}
          value={dateType}
          data={[
            { id: "payment", name: "Booking" },
            { id: "booking", name: "Performance" },
          ]}
          optional={true}
        />
        <br />
        <>
          <Col>
            <Row
              justify="start"
              style={{ marginBottom: "5px", color: "transparent" }}
            >
              .
            </Row>
            <Row justify="start">
              <Button
                loading={dataLoading}
                type="primary"
                onClick={fetchReport}
              >
                Fetch
              </Button>
            </Row>
          </Col>
          <Col>
            <Row
              justify="start"
              style={{ marginBottom: "5px", color: "transparent" }}
            >
              .
            </Row>
            <Row justify="start">
              <Button
                loading={printLoading}
                type="primary"
                onClick={printReport}
              >
                Print
              </Button>
            </Row>
          </Col>
          {active !== "All" && (
            <Col>
              <Row
                justify="start"
                style={{ marginBottom: "5px", color: "transparent" }}
              >
                .
              </Row>
              <Row justify="start">
                <Button
                  loading={excelLoading}
                  type="primary"
                  onClick={handleExcelExport}
                >
                  <DownloadOutlined /> Excel
                </Button>
              </Row>
            </Col>
          )}
        </>

        <Col
          style={{
            width: "250px",
            textAlign: "right",
            margin: "auto",
            marginRight: "0",
          }}
        >
          <Card hoverable size="small" style={{ fontWeight: "bold" }}>
            <Statistic
              prefix="₹"
              title="Total Collection"
              value={
                data?.meta
                  ? data?.meta?.totalCollection
                  : data.reduce(
                      (t, item) => t + Number(item.totalCollection),
                      0
                    )
              }
            />
          </Card>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={3}>
          <div className="tms-left-menu">
            {menu.map((item, i) => {
              return (
                <p
                  key={"tms-left-side-menu" + i}
                  className={active === item ? "tms-lm-active" : ""}
                  onClick={() => {
                    setData([]);
                    setActive(item);
                  }}
                >
                  {item}
                </p>
              );
            })}
          </div>
        </Col>
        <Col span={21}>
          {active === "All" ? (
            <>{renderConsolidatedTables()}</>
          ) : (
            <>{renderSingleCategoryTable("sort")}</>
          )}
        </Col>
      </Row>
      <Drawer
        title={`User`}
        placement="right"
        closable={false}
        onClose={handleUserDetails}
        visible={showUserDetails}
        width={500}
        destroyOnClose={true}
      >
        {/* <SevaCounterTable userid={userDetails} /> */}
      </Drawer>
      <TmsModal
        visible={modal}
        width={800}
        closable={true}
        centered
        onCancel={() => {
          setModal(false);
          setModalData([]);
          setPrintLoading(false);
        }}
      >
        <div className="tms-modal-div">
          <Button type="primary" className="print-button" onClick={handlePrint}>
            Print
          </Button>
        </div>
        <br />
        <div className="tms-report-container" ref={refContainer}>
          <h3 className="ta-center">{templeDetails?.name}</h3>

          <h3 className="ta-center">Consolidated Report</h3>
          <h6 className="ta-center">
            {moment().format("DD-MM-YYYY HH:mm:ss ")}
          </h6>
          {active !== "All" ? (
            <h3 className="ta-center">{active}</h3>
          ) : (
            <h4 className="ta-center">
              Report Range: {moment(FromDate).format("DD-MM-YYYY")}
              {moment(toDate).format("DD-MM-YYYY")}
            </h4>
          )}
          {active === "All" ? (
            <>{renderConsolidatedTables()}</>
          ) : (
            <>{renderSingleCategoryTable("sort")}</>
          )}
          <br />
          <div>
            <h4 style={{ float: "left" }}>
              Total Tickets :{" "}
              {active === "All"
                ? modalData?.meta?.totalTickets
                : modalData?.reduce((t, item) => {
                    return t + item.totalTickets;
                  }, 0)}
            </h4>
            <h4 style={{ float: "right" }}>
              Total Collection : Rs.{" "}
              {active === "All"
                ? modalData?.meta?.totalCollection
                : modalData?.reduce((t, item) => {
                    return t + item.totalCollection;
                  }, 0)}
            </h4>
          </div>
        </div>
      </TmsModal>
    </>
  );
};

export default withRouter(TmsConsolidatedReport);
