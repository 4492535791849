import { Button, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import {
  additionalLicenseArrearStates,
  arrearLicenseStates,
} from "../../../../../constants/states-data.data";
import { LicenseInstitutionSelect } from "../../../../../components/tms-customs/rents-and-leases/index";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import useLicensesStore from "../../../../../zustand/licenses.store";
import { FormatNumberINRCurrency } from "../../../../../utils/tms-currency-formatter/tms-curreny-formatter";

const TmsLicenseArrearsReports = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);

  const {
    selectedInstitution,
    loading,
    fetchArrearReports,
    fetchInstitutionList,
    arrearReport,
    clearStates,
    fetchTotalReports,
    setSelectedInstitution,
  } = useLicensesStore();

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };
  useEffect(() => {
    return () => {
      clearStates(arrearLicenseStates, additionalLicenseArrearStates);
    };
  }, []);

  useEffect(() => {
    router.location.pathname === tmsRouteMap.reports_ral_licenses_arrears &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (router.location?.pathname === tmsRouteMap.reports_ral_licenses_arrears)
      if (selectedInstitution !== "" && selectedInstitution) {
        fetchArrearReports(tableCurrentPage, tablePageSize, router);
      } else {
        fetchTotalReports("ARREAR", tableCurrentPage, tablePageSize, router);
      }
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedInstitution,
    router.location?.pathname,
  ]);

  const innerArrearColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },

    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },

    {
      title: "License",
      dataIndex: "licenseName",
      key: "licenseName",
    },
    {
      title: "Licensee Name",
      dataIndex: "licenseeName",
      key: "licenseeName",
    },
    {
      title: "License Fee",
      dataIndex: "licenseAmount",
      key: "licenseAmount",
    },
    { title: "Arrear ", dataIndex: "arrearAmount" },
    { title: "Adjusted Amount ", dataIndex: "adjustedAmount" },
    { title: "Arrear Period ", dataIndex: "arrearPeriodInMonths" },
  ];
  const totalArrearColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Institution",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Village",
      dataIndex: "village",
    },

    {
      title: "Arrear Amount",
      dataIndex: "totalArrearAmount",
      render: (record) => FormatNumberINRCurrency(record),
    },
  ];
  return (
    <>
      <Row justify="flex-start">
        <LicenseInstitutionSelect span={6} />
        {selectedInstitution !== "" && (
          <Button
            style={{ marginTop: "27px" }}
            onClick={() => {
              setSelectedInstitution("");
            }}
          >
            Clear
          </Button>
        )}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        loading={loading}
        style={{
          width: "100%",
          textTransform: "capitalize",
        }}
        columns={
          selectedInstitution !== "" && selectedInstitution
            ? innerArrearColumns.map((item) => {
                return { ...item, align: "center" };
              })
            : totalArrearColumns.map((item) => {
                return { ...item, align: "center" };
              })
        }
        dataSource={arrearReport?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: arrearReport?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsLicenseArrearsReports);
