import { Col, Input, Row } from "antd";
import React, { memo } from "react";
import useRentsAndLandStore from "../../zustand/rents-and-leases.store";

const TmsBoundayElement = ({ disabled, data, span }) => {
  const {
    eastBoundary,
    setEastBoundary,
    westBoundary,
    setWestBoundary,
    southBoundary,
    setSouthBoundary,
    northBoundary,
    setNorthBoundary,
  } = useRentsAndLandStore();
  return (
    <>
      {" "}
      <Col span={span ? span : 8}>
        <Row className={"t-left"}>Bounday East</Row>
        <Input
          type="text"
          maxLength={220}
          placeholder="Enter Boundary Value"
          onKeyDown={(e) =>
            ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
          }
          value={data ? data.eastBoundary : eastBoundary}
          onChange={(e) => setEastBoundary(e.target.value)}
          disabled={disabled ? true : false}
        />
      </Col>{" "}
      <Col span={span ? span : 8}>
        <Row className={"t-left"}>Bounday West</Row>
        <Input
          type="text"
          maxLength={220}
          placeholder="Enter Boundary Value"
          onKeyDown={(e) =>
            ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
          }
          value={data ? data.westBoundary : westBoundary}
          onChange={(e) => setWestBoundary(e.target.value)}
          disabled={disabled ? true : false}
        />
      </Col>{" "}
      <Col span={span ? span : 8}>
        <Row className={"t-left"}>Bounday South</Row>
        <Input
          type="text"
          maxLength={220}
          placeholder="Enter Boundary Value"
          onKeyDown={(e) =>
            ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
          }
          value={data ? data.southBoundary : southBoundary}
          onChange={(e) => setSouthBoundary(e.target.value)}
          disabled={disabled ? true : false}
        />
      </Col>{" "}
      <Col span={span ? span : 8}>
        <Row className={"t-left"}>Bounday North</Row>
        <Input
          type="text"
          maxLength={220}
          placeholder="Enter Boundary Value"
          onKeyDown={(e) =>
            ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
          }
          value={data ? data.northBoundary : northBoundary}
          onChange={(e) => setNorthBoundary(e.target.value)}
          disabled={disabled ? true : false}
        />
      </Col>
    </>
  );
};

export default memo(TmsBoundayElement);
