import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import TmsTabsLayout from "../../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import TmsBasEncroachmentReports from "./tms-bas-encroachment-reports";
import TmsBasLeaseReports from "./tms-bas-lease-reports";
import TmsBasArrearsReports from "./tms-bas-building-reports";
import TmsBasBuildingReports from "./tms-bas-building-reports";

const TmsReportsBuildings = ({ router }) => {
  const [active, setActive] = useState("Buildings Report");
  useEffect(() => {
    let location = router?.location?.pathname;

    if (
      location === tmsRouteMap.reports_ral_buildings_building ||
      location === "/reports/rents-and-leases/buildings"
    ) {
      setActive("Buildings Report");
      return;
    } else if (location.includes(tmsRouteMap.reports_ral_buildings_leases)) {
      setActive("Lease Report");
      return;
    } else if (
      location.includes(tmsRouteMap.reports_ral_buildings_encroachment)
    ) {
      setActive("Encroachment Report");
      return;
    } else if (location.includes(tmsRouteMap.reports_ral_buildings_arrears)) {
      setActive("Arrear Report");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Buildings Report",
      Element: <TmsBasBuildingReports router={router} />,
      key: "Buildings Report",
      route: tmsRouteMap.reports_ral_buildings_building,
    },
    {
      name: "Lease Report",
      Element: <TmsBasLeaseReports router={router} />,
      key: "Lease Report",
      route: tmsRouteMap.reports_ral_buildings_leases,
    },
    {
      name: "Encroachment Report",
      Element: <TmsBasEncroachmentReports router={router} />,
      key: "Encroachment Report",
      route: tmsRouteMap.reports_ral_buildings_encroachment,
    },
    {
      name: "Arrear Report",
      Element: <TmsBasArrearsReports router={router} />,
      key: "Arrear Report",
      route: tmsRouteMap.reports_ral_buildings_arrears,
    },
  ];

  return (
    <TmsTabsLayout
      components={components}
      active={active}
      setActive={setActive}
      //
    />
  );
};
export default withRouter(TmsReportsBuildings);
