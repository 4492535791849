import { Col, Popconfirm, Row, Space, Switch, Table } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import { DeleteOutlined } from "@ant-design/icons";
import {
  InstitutionSelect,
  SortTypeAndBy,
  SurveyNoSelect,
} from "../../../../../../components/tms-customs/rents-and-leases/index";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";
const TmsCreatedSurveyBreakupList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");
  const {
    selectedInstitution,
    loading,
    createdSNBreakupData,
    fetchInstitutionList,
    fetchSurveyNumbers,
    selectedSurveyNumber,
    fetchSurveyNumbersBreakUp,
    clearStates,
    handleChangeStatus,
    deleteLoading,
    deleteCreatedSurveyBreakUp,
    handleExcelExport,
    excelLoading,
  } = useRentsAndLandStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  useEffect(() => {
    return () => {
      clearStates(
        [
          "institutionData",
          "createdSurveryNumberData",
          "selectedInstitution",
          "createdSNBreakupData",
          "selectedBKo",
        ],
        []
      );
    };
  }, []);
  useEffect(() => {
    router.location.pathname ===
      tmsRouteMap.sm_ral_lands_survey_breakup_no_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    selectedInstitution !== "" &&
      selectedInstitution &&
      fetchSurveyNumbers(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  useEffect(() => {
    if (
      selectedInstitution &&
      selectedSurveyNumber &&
      selectedSurveyNumber !== ""
    )
      fetchSurveyNumbersBreakUp(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedSurveyNumber,
    update,
  ]);

  const switchTrigger = async (record, status) => {
    await handleChangeStatus(record, status, router);
    setUpdate(Math.random() * 100000 + 21);
  };

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <InstitutionSelect span={5} />
        <SurveyNoSelect span={5} />
        <SortTypeAndBy
          span={5}
          sortBy={sortBy}
          sortType={sortType}
          setSortBy={setSortBy}
          setSortType={setSortType}
        />{" "}
        {selectedInstitution &&
        selectedSurveyNumber &&
        selectedSurveyNumber !== "" ? (
          <Col span={4} style={{ padding: "0" }}>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() =>
                handleExcelExport("surveyNoBreakup", router)
              }
              top={true}
            />
          </Col>
        ) : (
          ""
        )}
      </Row>

      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          {
            title: "Survey No",
            key: "surveyNumber",
            dataIndex: "surveyNumber",
            render: (record) => record.surveyNo,
          },
          {
            title: "BreakUp/Block No",
            key: "blockNumber",
            dataIndex: "blockNumber",
          },
          {
            title: "Extent type",
            dataIndex: "extent",
            align: "extent",
          },
          {
            title: "Extent ",
            children: [
              {
                title: "Acres",
                dataIndex: "extentAcres",
                key: "extentAcres",
                align: "center",
              },
              {
                title: "Cents",
                dataIndex: "extentCents",
                key: "extentCents",
                align: "center",
              },
            ],
          },
          {
            title: "Holding Status",
            key: "holdingStatus",
            dataIndex: "holdingStatus",
          },

          {
            title: "Boundary",
            render: (record) =>
              `${record.northBoundary} N , ${record.southBoundary} S, ${record.eastBoundary} E, ${record.westBoundary} W`,
            align: "center",
          },
          {
            title: "Geo tagging",
            dataIndex: "geoTagging",
            align: "center",
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (record) => moment(record).format("DD-MM-YYYY"),
            align: "center",
          },
          {
            title: "Status",
            dataIndex: "status",
            render: (record) => (record ? `active` : `unactive`),
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (record) => moment(record).format("DD-MM-YYYY hh:mm A"),
          },
          {
            title: "Status",
            key: "status",
            render: (record) => {
              return (
                <Switch
                  size={"small"}
                  checked={record.status}
                  onClick={() => {
                    switchTrigger(record.id, !record.status);
                  }}
                />
              );
            },
          },
          {
            title: "Action",
            render: (record) => {
              return (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure delete this institution?"
                    onConfirm={async () => {
                      await deleteCreatedSurveyBreakUp(record.id, router);
                      setUpdate(Math.random() * 100000 + 78);
                    }}
                    okText="Yes"
                    cancelText="No"
                    loading={deleteLoading}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Space>
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={
          createdSNBreakupData?.items?.map((item) => {
            return {
              key: item.id,
              ...item,
            };
          }) ?? []
        }
        onChange={handleTableChange}
        pagination={{
          total: createdSNBreakupData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <p>
                {" "}
                Donor Doc: {record.donorDocumentType}-{" "}
                {record.donorDocumentNumber}, Donor Address:{" "}
                {record.donorAddress} - City: {record.donorCity}, State:{" "}
                {record.donorState}, Pincode: {record.donorPincode} Country:{" "}
                {record.donorCountry}
              </p>
            );
          },
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsCreatedSurveyBreakupList);
