import React from "react";
import useRentsAndLandStore from "../../../zustand/rents-and-leases.store";
import TmsSelect from "../tms-select";

const SurveyNoSelect = ({ span }) => {
  const {
    setSelectedSurveyNumber,
    selectedSurveyNumber,
    createdSurveryNumberData,
    clearStates,
  } = useRentsAndLandStore((state) => {
    return {
      setSelectedSurveyNumber: state.setSelectedSurveyNumber,
      selectedSurveyNumber: state.selectedSurveyNumber,
      createdSurveryNumberData: state.createdSurveryNumberData,
      clearStates: state.clearStates,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select Survey No"
      setValue={(e) => {
        setSelectedSurveyNumber(
          createdSurveryNumberData?.items?.find((item) => item.id === e)
        );
        clearStates(
          [
            "createdSNBreakupData",
            "selectedSNBreakup",
            "selectedLandLease",
            "createdLandLeaseData",
          ],
          []
        );
      }}
      selectClass="w-100"
      value={selectedSurveyNumber?.id}
      data={createdSurveryNumberData?.items?.map((item) => {
        return { name: `Survey.no: ${item.surveyNo}`, id: item.id };
      })}
      optional={true}
    />
  );
};

export default SurveyNoSelect;
