import { DatePicker, Row } from "antd";
import React, { memo } from "react";
// import moment from "moment";
const TmsDatePicker = ({ value, setValue, label, mandatory, picker }) => {
  return (
    <Row style={{ width: "100%", padding: "20px 0px 10px 0px" }}>
      {label}
      {mandatory && <span className="mandatory">*</span>}
      <DatePicker
        allowClear
        picker={picker ? picker : "time"}
        style={{ width: "100%", marginTop: "5px" }}
        defaultValue={value}
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
      />
    </Row>
  );
};
export default memo(TmsDatePicker);
