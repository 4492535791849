import { Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import {
  createBlockStates,
  createSurveyNoStates,
} from "../../../../../../constants/states-data.data";
import {
  BuildingInstitutionSelect,
  BuildingSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";
import useBuildingsStore from "../../../../../../zustand/buildings-and-shops.store";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
const TmsBlocksCreation = ({ router, setVisible }) => {
  const {
    selectedInstitution,
    fetchInstitutionList,
    fetchCreatedBuildingsList,
    handleCreateBlock,
    clearStates,
    setSubmitLoading,
    blockNumber,
    setBlockNumber,
    submitLoading,
    numberOfShops,
    setNumberOfShops,
    selectedBuilding,
    setStatus,
    status,
  } = useBuildingsStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createBlockStates, [
        "institutionData",
        "createdBuildingsData",
        "selectedBuilding",
      ]);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedBuildingsList(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  return (
    <div>
      {" "}
      <Row gutter={[20, 20]} style={{ marginBottom: "15px" }}>
        <BuildingInstitutionSelect span={12} />
        <BuildingSelect span={12} from="block" />
        <Col span={12}>
          <Row className={"t-left"}>Property Type</Row>
          <Input
            maxLength={220}
            value={selectedBuilding?.propertyType}
            disabled={true}
          />
        </Col>

        <Col span={12}>
          <Row className={"t-left"}>Land Mark</Row>
          <Input
            value={selectedBuilding?.landmark}
            disabled={true}
            maxLength={220}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Block Number</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Block Number"
            value={blockNumber}
            onChange={(e) => {
              setBlockNumber(e.target.value);
            }}
          />
        </Col>

        <Col span={12}>
          <Row className={"t-left"}>No of Shops</Row>
          <Input
            maxLength={220}
            suffix={`Max: ${selectedBuilding?.numberOfShops ?? 0}`}
            type="number"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            min="1"
            placeholder="Enter Shops"
            value={numberOfShops}
            onChange={(e) => {
              selectedBuilding?.numberOfShops >= e.target.value &&
                setNumberOfShops(e.target.value);
            }}
          />
        </Col>
      </Row>
      <TmsCheckbox label={"Block Status"} value={status} setValue={setStatus} />
      <TmsCancelSubmitButtons
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(createSurveyNoStates, [
            "institutionData",
            "createdBlocksData",
          ]);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => !submitLoading && handleCreateBlock(router)}
        loading={submitLoading}
      />
    </div>
  );
};

export default withRouter(TmsBlocksCreation);
