import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportDonationReport from "./tms-report-donation-report";
import TmsReportDonationSummaryReport from "./tms-report-donation-summary-report";

const TmsReportDonation = ({ router }) => {
  const [active, setActive] = useState("Donation Report");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.reports_donation) {
      setActive("Donation Report");
      return;
    } else if (location.includes(tmsRouteMap.reports_donation_summary_report)) {
      setActive("Summary Report");
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Donation Report",
      Element: <TmsReportDonationReport />,
      key: "Donation Report",
      route: tmsRouteMap.reports_donation,
    },

    {
      name: "Summary Report",
      Element: <TmsReportDonationSummaryReport />,
      key: "Summary Report",
      route: tmsRouteMap.reports_donation_summary_report,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportDonation);
