import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import useContactUsStore from "../../zustand/contact-us.store";
const TmsSupport = ({ router }) => {
  const { clearStates, fetchSupportRequests, loading, data } =
    useContactUsStore();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(20);
  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "User Name",
      key: "sno",
      render: (text) => {
        return `${text?.firstName} ${text?.lastName ?? ""}`;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title: "Service Type",
      dataIndex: "service",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  useEffect(() => {
    fetchSupportRequests(tableCurrentPage, tablePageSize, router);
    return () => {
      clearStates(["data"], []);
    };
  }, [tableCurrentPage, tablePageSize]);

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    // if (sorter.field === "createdAt") {
    //   if (sorter.order === "ascend") {
    //     setSortType("ASC");
    //   } else if (sorter.order === "descend") {
    //     setSortType("DESC");
    //   }
    // }
  };
  return (
    <div>
      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={columns}
        dataSource={
          data?.items?.map((item) => {
            return { key: item?.id, ...item };
          }) ?? []
        }
        onChange={handleTableChange}
        pagination={{
          total: data?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div>
                Message:
                {record?.message}
              </div>
            );
          },
        }}
      ></Table>
    </div>
  );
};
export default withRouter(TmsSupport);
