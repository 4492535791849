import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import TmsAddOnService from "../../../../../services/tms-addons.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Col, Drawer } from "antd";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmsAddonsInnerPagesAnd from "./tms-addons-inner-pages-and";
// import TmsDInnerPagesActions from "../../../../../components/tms-service-management/tms-sm-darshanam/tms-d-inner-pages-actions";

const TmsAddOnsInnerPagesAll = ({ router }) => {
  // const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addOnData, setAddOnsData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewAddOnsData, setViewAddOnsData] = useState();
  const [editFlag, setEditFlag] = useState();
  // const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  const tableColums = [
    ["Service Type", viewAddOnsData?.parentType],
    [
      "Service Name",
      `${viewAddOnsData?.parent.name} ${
        viewAddOnsData?.parent.sevaType &&
        `- ${viewAddOnsData?.parent.sevaType}`
      }`,
    ],
    ["Add-On Service", `${viewAddOnsData?.childType}`],
    [
      "Add-On Name",
      `${viewAddOnsData?.child.name} ${
        viewAddOnsData?.child.sevaType && `- ${viewAddOnsData?.child.sevaType}`
      }`,
    ],
  ];

  // const switchTrigger = async (key, status, from) => {
  //   setLoading(true);
  //   try {
  //     const darData = await TmsAddOnService.viewAddOns(key);
  //     setViewAddOnsData(darData);
  //     settmsModalVisible(true);
  //     setEditFlag(true);
  //     setProductId(key);
  //   } catch (error) {
  //     catchErrorFunction(
  //       error,
  //       "Token Expired, redirecting to login page in 5 seconds",
  //       router
  //     );
  //   }
  //   // try {
  //   //   const darData = await TmsDarshanamService.viewDarshanam(key);

  //   //   let body = {
  //   //     maxCounterBookings: darData?.maxCounterBookings,
  //   //     maxOnlineBookings: darData?.maxOnlineBookings,
  //   //     minOnlineHours: darData?.minOnlineHours / 24,
  //   //     maxOnlineHours: darData?.maxOnlineHours / 24,
  //   //     minCounterHours: darData?.minCounterHours,
  //   //     maxCounterHours: darData?.maxCounterHours,
  //   //     darshanamName: darData?.name,
  //   //     description: darData?.description,
  //   //     counterEnabled: from === "counter" ? status : darData?.counterEnabled,
  //   //     onlineEnabled: from === "online" ? status : darData?.onlineEnabled,
  //   //     price: darData?.price,
  //   //     imageUrl: darData?.imageUrl,
  //   //   };
  //   //   await TmsDarshanamService.editDarshanam(key, body);
  //   //   setUpdateFlag(Math.random() + 1);
  //   // } catch (error) {
  //   //   catchErrorFunction(
  //   //     error,
  //   //     "Token Expired, redirecting to login page in 5 seconds",
  //   //     router
  //   //   );
  //   // }
  //   setLoading(false);
  // };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    if (router.location?.pathname === "/service-management/add-ons") {
      getAddons();
      if (!editFlag) {
        settmsModalVisible(false);
      }
    }
  }, [
    // updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  const getAddons = async () => {
    setLoading(true);
    try {
      setAddOnsData([]);
      const data = await TmsAddOnService.getAddOns(
        tableCurrentPage,
        tablePageSize
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setAddOnsData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Service type",
      dataIndex: "parentType",
      key: "parentType",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Service Name",
      dataIndex: "parent",
      render: (record) => record?.name,
      //   ...getColumnSearchProps("darshanam_name"),
    },

    {
      title: "Add-On Type",
      dataIndex: "childType",
      key: "childType",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Add-On Name",
      dataIndex: "child",
      render: (record) => record?.name,

      //   ...getColumnSearchProps("darshanam_name"),
    },

    // {
    //   title: "Action",
    //   render: (record) => {
    //     return (
    //       <TmsDInnerPagesActions
    //         record={record}
    //         setLoading={setLoading}
    //         setViewAddOnsData={setViewAddOnsData}
    //         settmsModalVisible={settmsModalVisible}
    //         setUpdateFlag={setUpdateFlag}
    //         setEditFlag={setEditFlag}
    //         setProductId={setProductId}
    //       />
    //     );
    //   },
    // },
  ];

  return (
    <>
      {" "}
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={addOnData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <Drawer
        className="DetailsModal"
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewAddOnsData(null);
        }}
        title={editFlag ? "Edit Darshanam" : "Darshanam Details"}
      >
        {editFlag ? (
          <TmsAddonsInnerPagesAnd
            viewAddOnsData={viewAddOnsData}
            editFlag={editFlag}
            // productId={productId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
          />
        ) : (
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
            <br />
            <Col className="ImgCol">
              <img src={viewAddOnsData?.imageUrl} alt="Image" />
            </Col>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default withRouter(TmsAddOnsInnerPagesAll);
