import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsProductsService from "../../../services/tms-products.service";
import "../../../assets/css/tms-pos-products/tms-pos-products.scss";
import moment from "moment";
import Search from "antd/lib/transfer/search";
import { RightOutlined } from "@ant-design/icons";
import TmsTable from "../../../components/tms-customs/tms-table";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import TmsPosCoupon from "../../../components/tms-pos/tms-pos-coupon/tms-pos-coupon";
import { BaseContext } from "../../../contexts/BaseContext";

const TmsPosProducts = ({ router }) => {
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [type, setType] = useState("GENERAL");
  const [loading, setLoading] = useState("false");
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [cart, setCart] = useState([]);

  // const [country, setCountry] = useState("India");
  // const [city, setCity] = useState("");
  // const [state, setState] = useState("");
  // const [zip, setZip] = useState("");
  // const [devoteeName, setDevoteeName] = useState("");
  // const [phone, setPhone] = useState("");
  // const [address, setAddress] = useState("");
  const [array, setArray] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  const [noofItems, setNoofItems] = useState(1);

  const {
    couponCode,
    setCouponCode,
    verifiedCouponData,
    setVerifiedCouponData,
    usedBy,
  } = useContext(BaseContext);

  let cartArray = [];

  const ProductData = (data) => {
    let schemaBody = [];
    data?.cartItems?.shop_product?.map((item) => {
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
        ],
        ["Ticket Id", item?.counterCartItem?.ticketId, { margin: "0" }],
        [
          "Product",
          type === "PUBLICATION"
            ? `${item?.productDetails?.productName} - ${item?.shopProductBooking?.language}`
            : item?.productDetails?.productName,
          { margin: "0", fontWeight: "bold" },
        ],

        [
          "Devotee Name",
          item?.shopProductBooking?.devoteeName
            ? item?.shopProductBooking?.contact
              ? `${item?.shopProductBooking?.devoteeName
                  ?.split(",")[0]
                  .trim()} | ${item?.shopProductBooking?.contact}`
              : item?.shopProductBooking?.devoteeName?.split(",")[0].trim()
            : item?.shopProductBooking?.contact ?? "null",
          { margin: "0" },
        ],
        [
          "Address",
          type === "PUBLICATION"
            ? `${item?.shopProductBooking?.address}, ${item?.shopProductBooking?.city}, ${item?.shopProductBooking?.state}-${item?.shopProductBooking?.pinCode}`
            : "null",
          { margin: "0" },
        ],
        ["Date", moment().format("DD-MM-YYYY"), { margin: "0" }],
        [
          "Qty",
          item?.shopProductBooking?.noOfItems,
          { margin: "0", fontWeight: "bold" },
        ],
        ["Price", `Rs. ${item?.shopProductBooking?.price}.00`, { margin: "0" }],
        [
          "Delivery Amount",
          item?.shopProductBooking?.deliveryAmount > 0
            ? `Rs. ${item?.shopProductBooking?.deliveryAmount}.00`
            : "null",
          { margin: "0" },
        ],
        [
          "GST",
          item?.shopProductBooking?.gst > 0
            ? `Rs. ${item?.shopProductBooking?.gst}.00`
            : "null",
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${Number(
            item?.shopProductBooking?.price +
              item?.shopProductBooking?.deliveryAmount +
              item?.shopProductBooking?.gst
          )}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(
            Number(
              item?.shopProductBooking?.price +
                item?.shopProductBooking?.deliveryAmount +
                item?.shopProductBooking?.gst
            )
          )} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
    });
    setArray([...schemaBody]);
  };

  const addCart = (item) => {
    // cartArray = [...cart, item]; //multiple selection
    if (item?.shopProductId !== cart[0]?.shopProductId) {
      setCouponCode(null);
      setVerifiedCouponData(null);
    }
    cartArray = [item]; //single selection
    setCart(cartArray);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Language",
      dataIndex: "lang",
      hidden: type === "GENERAL" ? true : false,
    },
    {
      title: "Year - Month",
      dataIndex: "year",
      hidden: type === "GENERAL" ? true : false,
      render: (text, record) => {
        return (
          <>
            {record?.year} - {record?.month}
          </>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "qty",
      render: (text) => text,
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (text) => {
        return <>Rs. {text}.00</>;
      },
    },
    {
      title: "GST",
      dataIndex: "gst",
      render: (text) => {
        return <>Rs. {text ?? 0}.00</>;
      },
    },
    {
      title: "Delivery Charges",
      dataIndex: "deliveryPrice",
      render: (text) => {
        return <>Rs. {text ?? 0}.00</>;
      },
    },
    {
      title: "Total",
      dataIndex: "price",
      render: (text, record) => {
        return (
          <>
            Rs.{" "}
            {Number(
              (record?.price + record?.gst) * record?.qty +
                record?.deliveryPrice
            ) ?? 0}
            {/* {Number(record?.price + record?.gst + record?.deliveryPrice) ?? 0} */}
            .00
          </>
        );
      },
    },
    {
      title: "Action",
      render: (text, record, i) => {
        return (
          <Button
            onClick={() => {
              let a = [...cart];
              delete a[i];
              setCart(a?.filter((item) => item));
            }}
          >
            Remove
          </Button>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const fetchProducts = async () => {
    setLoading(true);
    let array = [];
    try {
      let resp = await TmsProductsService.fetchValidProducts(
        type,
        moment(startDate).format("YYYY-MM-DD"),
        noofItems
      );
      if (type === "PUBLICATION") {
        resp?.map((item) => {
          item?.product?.pubFiles?.map((prod, i) =>
            prod?.languageOptions?.map((lang, j) => {
              array = [
                ...array,
                {
                  key: `${lang}+${i}+${j}`,
                  shopProductId: prod?.shopProductId,
                  shopProductPubFileId: prod?.id,
                  img: prod?.fileUrl,
                  name: prod?.name,
                  price: prod?.price,
                  gst: item?.product?.gst,
                  deliveryPrice: prod?.deliveryPrice,
                  year: prod?.year,
                  month: prod?.month,
                  lang: lang,
                  qty: 1,
                },
              ];
            })
          );
        });
        setProducts(array);
      } else {
        resp?.map((prod, i) => {
          array = [
            ...array,
            {
              key: i,
              shopProductId: prod?.product?.id,
              img: prod?.product?.imageUrl,
              name: prod?.product?.name,
              price: prod?.product?.price,
              gst: prod?.product?.gst,
              deliveryPrice: 0,
              qty: 1,
            },
          ];
        });
        setProducts(array);
      }
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    setCouponCode(null);
    setVerifiedCouponData(null);
    setCart([]);
    fetchProducts();
  }, [type]);

  const sum = () => {
    let total = 0;
    cart?.map(
      (item) =>
        (total += Number(
          (item?.price + item?.gst) * item?.qty + item?.deliveryPrice
        ))
    );
    return verifiedCouponData
      ? total -
          Number(
            verifiedCouponData?.gstDiscountAmount +
              verifiedCouponData?.priceDiscountAmount
          )
      : total;
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    // if (type === "PUBLICATION") {
    //   if (
    //     phone === "" ||
    //     devoteeName === "" ||
    //     zip === "" ||
    //     address === "" ||
    //     city === "" ||
    //     state === "" ||
    //     country === ""
    //   ) {
    //     notification.error({ message: "Please fill all mandatory fields" });
    //     setLoading(false);
    //     return;
    //   }
    // }
    let body = [];
    cart?.map((item) =>
      body.push({
        type: type,
        date: startDate,
        noOfItems: item?.qty,
        // noOfItems: 1,
        shopProductId: item?.shopProductId,
        shopProductPubFileId: item?.shopProductPubFileId,
        // publicationFileDetails: {
        //   name: devoteeName,
        //   contact: `+91${phone}`,
        //   pinCode: Number(zip),
        //   address: address,
        //   address2: "string",
        //   city: city,
        //   state: state,
        //   country: country,
        //   language: item?.lang,
        // },
      })
    );
    if (verifiedCouponData) {
      body[0].couponCode = couponCode;
      body[0].couponType = usedBy;
    }
    try {
      const resp = await TmsProductsService.productBooking(
        body,
        "CASH",
        "",
        ""
      );
      notification.success({ message: "Success" });
      ProductData(resp);
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const validation = (prod, item) => {
    if (
      prod?.shopProductId === item?.shopProductId &&
      prod?.lang === item?.lang &&
      prod?.year === item?.year &&
      prod?.month === item?.month &&
      prod?.key === item?.key
    ) {
      return true;
    } else {
      return false;
    }
  };

  const editCart = (prod) => {
    let cartitems = [...cart];
    cart?.forEach((item, i) => {
      if (validation(prod, item)) {
        cartitems[i].qty = prod?.qty;
      }
    });
    setCart([...cartitems]);
  };

  const handleAddAndSub = (type, i) => {
    setCouponCode("");
    setVerifiedCouponData(null);
    let items = [...products];
    let add = Number(items[i]?.qty);
    if (type === "add") {
      add += 1;
    } else if (type === "sub") {
      add -= 1;
    }
    if (add >= 1 && add < 100) {
      items[i].qty = Number(add);
      setProducts(items);
      editCart(products[i]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
          textAlign: "left",
        }}
      >
        <Row gutter={[20, 20]}>
          <Col style={{ width: "25%", justifyContent: "start" }}>
            <label style={labelStyles}>
              Booking Date
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />
            <DatePicker
              disabled
              value={startDate}
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              disabledDate={(d) =>
                !d || d.isBefore(TmsStringUtils.addDays(new Date(), -1))
              }
              onChange={(date) => {
                // getSlots(selectedSevaId, moment(date).format("YYYY-MM-DD"));
                setStartDate(date);
              }}
            />
          </Col>
          <Col style={{ width: "25%", justifyContent: "start" }}>
            <label style={labelStyles}>Product Type</label>
            <br />
            <Select
              style={{ width: "100%" }}
              onChange={(e) => {
                setType(e);
              }}
              value={type}
              defaultValue={type}
            >
              <Select.Option value="GENERAL">General</Select.Option>
              <Select.Option value="GENERAL_WITH_QUOTA">
                General with Quota
              </Select.Option>
              {/* <Select.Option value="PUBLICATION">Publication</Select.Option> */}
              <Select.Option value="BAHUMANAM">Bahumanam</Select.Option>
            </Select>
          </Col>
          {type === "GENERAL_WITH_QUOTA" && (
            <Col style={{ width: "25%", justifyContent: "start" }}>
              <label style={labelStyles}>No. of Items</label>
              <Input
                type="number"
                min="0"
                value={noofItems}
                onChange={(e) => setNoofItems(e.target.value)}
              />
            </Col>
          )}
          <Col style={{ width: "25%", justifyContent: "start" }}>
            <label style={labelStyles}>Search</label>
            <Search
              type="text"
              style={{ textTransform: "capitalize" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Row>
        <br />
        <Row
          style={{
            backgroundColor: "#ECF0F5",
            borderRadius: "2px 2px 0px 0px",
          }}
        >
          <Col
            style={{
              width: "50%",
              padding: "15px 10px",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            Item Name
          </Col>
          <Col
            style={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Unit Price
          </Col>
          <Col
            style={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Quantity
          </Col>
          <Col
            style={{
              width: "20%",
              display: "flex",
              alignItems: "center",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Action
          </Col>
        </Row>
        <Row
          // gutter={[20, 20]}
          className="itemCard"
          style={{
            // height:
            //   products?.filter((item) =>
            //     item?.name?.toLowerCase().includes(search?.toLowerCase())
            //   )?.length > 4
            //     ? "525px"
            //     : "100%",
            marginTop: "0px",
          }}
        >
          {products
            ?.filter((item) =>
              item?.name?.toLowerCase().includes(search?.toLowerCase())
            )
            ?.map((item, i) => {
              return (
                <Col
                  key={i}
                  style={{
                    width: "100%",
                    justifyContent: "start",
                    textTransform: "capitalize",
                  }}
                >
                  {/* <Card hoverable>
                    <div
                      style={{
                        backgroundImage: `url(${item?.img})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "130px",
                      }}
                    ></div>
                    <div style={{ padding: "16px" }}>
                      <Row
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Col style={{ fontWeight: "bold", width: "40%" }}>
                          Name
                        </Col>
                        <Col style={{ width: "10%" }}>:</Col>
                        <Col style={{ width: "50%" }}>{item?.name}</Col>
                      </Row>
                      <Row
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Col style={{ fontWeight: "bold", width: "40%" }}>
                          Price
                        </Col>
                        <Col style={{ width: "10%" }}>:</Col>
                        <Col style={{ width: "50%" }}>Rs. {item?.price}</Col>
                      </Row>
                      {item?.year && (
                        <Row
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Col style={{ fontWeight: "bold", width: "40%" }}>
                            Year-Month
                          </Col>
                          <Col style={{ width: "10%" }}>:</Col>
                          <Col style={{ width: "50%" }}>
                            {item?.year}-{item?.month}
                          </Col>
                        </Row>
                      )}
                      {item?.lang && (
                        <Row
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Col style={{ fontWeight: "bold", width: "40%" }}>
                            Language
                          </Col>
                          <Col style={{ width: "10%" }}>:</Col>
                          <Col style={{ width: "50%" }}>{item?.lang}</Col>
                        </Row>
                      )}

                      <br />
                      <Row
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                          margin: "0",
                        }}
                      >
                        <Col
                          style={{
                            width: "25%",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Button
                            type="primary"
                            onClick={() =>
                              handleAddAndSub("sub", products?.indexOf(item))
                            }
                          >
                            -
                          </Button>
                        </Col>
                        <Col
                          style={{
                            width: "50%",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Input
                            onWheel={(event) => event.currentTarget.blur()}
                            style={{ width: "100%", textAlign: "center" }}
                            type="number"
                            min="0"
                            max="99"
                            maxLength="2"
                            key={i}
                            placeholder="0"
                            controls={false}
                            step="false"
                            value={item?.qty}
                            onChange={(e) => {
                              if (e.target.value?.includes(".")) {
                                return;
                              }
                              if (e.target.value < 0 || e.target.value > 99) {
                                return;
                              } else {
                                let items = [...products];
                                items[i].qty = Number(e.target.value);
                                setProducts(items);
                                // setTicketsCount({
                                //   id: item?.product?.id,
                                //   price: item?.product?.price,
                                //   count: e.target.value,
                                // });
                              }
                            }}
                          />
                        </Col>
                        <Col
                          style={{
                            width: "25%",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Button
                            type="primary"
                            onClick={() =>
                              handleAddAndSub("add", products?.indexOf(item))
                            }
                          >
                            +
                          </Button>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={[10, 10]}>
                        <Col
                          style={{
                            width: cart?.find((prod) => validation(prod, item))
                              ? "50%"
                              : "100%",
                          }}
                        >
                          <Button
                            disabled={item?.qty === 0}
                            style={{ width: "100%" }}
                            onClick={() => {
                              cart?.find((prod) => validation(prod, item))
                                ? ""
                                : addCart(item);
                            }}
                          >
                            {cart?.find((prod) => validation(prod, item))
                              ? "Added"
                              : "Add to Cart"}
                          </Button>
                        </Col>
                        <Col style={{ width: "50%" }}>
                          {cart?.find((prod) => validation(prod, item)) && (
                            <Button
                              type="primary"
                              style={{ width: "100%" }}
                              onClick={() => {
                                let a = [...cart];
                                delete a[
                                  cart?.indexOf(
                                    cart?.find((prod) => validation(prod, item))
                                  )
                                ];
                                setCart(a?.filter((item) => item));
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Card> */}
                  <Card
                    key={i}
                    hoverable
                    style={{ width: "100%" }}
                    className="itemCard"
                  >
                    <Row style={{ padding: "0" }}>
                      <Col
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          color: "red",
                          fontWeight: "600",
                          padding: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {item?.name}
                          <Divider type="vertical" />
                        </div>
                        <div
                          style={{
                            backgroundImage: `url(${item?.img})`,
                            height: "40px",
                            width: "40px",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                      </Col>
                      <Col
                        style={{
                          width: "15%",
                          display: "flex",
                          alignItems: "center",
                          color: "red",
                          fontWeight: "600",
                          padding: "10px",
                          borderLeft: "1px solid #CDD7E2",
                        }}
                      >
                        Rs. {item?.price}
                      </Col>
                      <Col
                        style={{
                          width: "15%",
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                          borderLeft: "1px solid #CDD7E2",
                        }}
                      >
                        <Row
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                            margin: "0",
                          }}
                        >
                          <Col
                            style={{
                              width: "25%",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Button
                              type="primary"
                              onClick={() =>
                                handleAddAndSub("sub", products?.indexOf(item))
                              }
                            >
                              -
                            </Button>
                          </Col>
                          <Col
                            style={{
                              width: "50%",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Input
                              onWheel={(event) => event.currentTarget.blur()}
                              style={{ width: "100%", textAlign: "center" }}
                              type="number"
                              min="0"
                              max="99"
                              maxLength="2"
                              key={i}
                              placeholder="0"
                              controls={false}
                              step="false"
                              value={item?.qty}
                              onChange={(e) => {
                                if (e.target.value?.includes(".")) {
                                  return;
                                }
                                if (e.target.value < 0 || e.target.value > 99) {
                                  return;
                                } else {
                                  let items = [...products];
                                  items[i].qty = Number(e.target.value);
                                  setProducts(items);
                                  setCouponCode(null);
                                  setVerifiedCouponData(null);
                                  // setTicketsCount({
                                  //   id: item?.product?.id,
                                  //   price: item?.product?.price,
                                  //   count: e.target.value,
                                  // });
                                }
                              }}
                            />
                          </Col>
                          <Col
                            style={{
                              width: "25%",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Button
                              type="primary"
                              onClick={() =>
                                handleAddAndSub("add", products?.indexOf(item))
                              }
                            >
                              +
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          width: "20%",
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                          borderLeft: "1px solid #CDD7E2",
                          justifyContent: "center",
                        }}
                      >
                        <Row gutter={[10, 0]}>
                          <Col
                            style={{
                              width: cart?.find((prod) =>
                                validation(prod, item)
                              )
                                ? "50%"
                                : "100%",
                            }}
                          >
                            <Button
                              disabled={item?.qty === 0}
                              style={{ width: "100%" }}
                              onClick={() => {
                                cart?.find((prod) => validation(prod, item))
                                  ? ""
                                  : addCart(item);
                              }}
                            >
                              {cart?.find((prod) => validation(prod, item))
                                ? "Added"
                                : "Add to Cart"}
                            </Button>
                          </Col>
                          <Col style={{ width: "50%" }}>
                            {cart?.find((prod) => validation(prod, item)) && (
                              <Button
                                type="primary"
                                style={{ width: "100%" }}
                                onClick={() => {
                                  let a = [...cart];
                                  delete a[
                                    cart?.indexOf(
                                      cart?.find((prod) =>
                                        validation(prod, item)
                                      )
                                    )
                                  ];
                                  setCart(a?.filter((item) => item));
                                }}
                              >
                                Remove
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
        </Row>
        <br />
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Col style={{ width: "48%" }}>
            <Row
              style={{
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              Coupon Details
            </Row>
            <br />
            <Row style={{ justifyContent: "center", width: "100%" }}>
              <TmsPosCoupon
                width="100%"
                Id={cart?.map((e) => {
                  return e?.shopProductId;
                })}
                price={Number(cart[0]?.price * cart[0]?.qty)}
                gst={Number(cart[0]?.gst * cart[0]?.qty)}
                type="DONOR_SHOP_PRODUCT"
                numberOfTickets={cart[0]?.qty}
              />
            </Row>
          </Col>
        </Row>

        <br />
        <Row style={{ width: "100%" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "400",
              paddingBottom: "20px",
              margin: "0",
            }}
          >
            Cart
          </p>
          <TmsTable columns={columns} dataSource={cart} pagination={false} />
        </Row>
      </div>
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          padding: "10px 50px",
          background: "#FFFCDC",
        }}
      >
        {verifiedCouponData && (
          <Row
            style={{
              height: "50px",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: "500",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                textAlign: "left",
                margin: "0",
              }}
            >
              Discount Amount :
            </p>
            <p
              style={{
                fontSize: "18px",
                margin: "0",
                textAlign: "right",
              }}
            >
              Rs.{" "}
              {Number(
                verifiedCouponData?.gstDiscountAmount +
                  verifiedCouponData?.priceDiscountAmount
              ) ?? 0}
              .00
            </p>
          </Row>
        )}
        <Row
          style={{
            height: "50px",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "500",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              textAlign: "left",
              margin: "0",
            }}
          >
            Total Amount :
          </p>
          <p
            style={{
              fontSize: "24px",
              margin: "0",
              textAlign: "right",
            }}
          >
            Rs. {sum() ?? 0}.00
          </p>
        </Row>
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              type="primary"
              onClick={() => {
                handleSubmit();
              }}
              loading={loading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="product"
          from="Counter"
          setData={setArray}
          data={array}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setLoading}
          setPrintTicketModal={setIsModalVisible}
          printType="a4"
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosProducts);
