import { DatePicker, Row } from "antd";
import React, { memo } from "react";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";
const TmsDatePickerRange = ({
  className,
  label,
  to,
  from,
  setFrom,
  setTo,
  disabled,
  disabledDate,
}) => {
  return (
    <Row justify="start" style={{ flexDirection: "column" }}>
      {label !== "" && (
        <div
          style={{
            marginBottom: label === "Select Batch Dates" ? "3px" : "5px",
            textAlign: "left",
          }}
        >
          {label}
        </div>
      )}

      <DatePicker.RangePicker
        className={className}
        format="DD-MM-YYYY"
        onChange={(e) => {
          if (e?.[0] && e?.[1]) {
            setFrom(moment(e[0]).format(`YYYY-MM-DD`));
            setTo(moment(e[1]).format(`YYYY-MM-DD`));
          } else {
            setFrom(undefined);
            setTo(undefined);
          }
        }}
        ranges={{
          Today: [moment(), moment()],
          "Last 7 Days": [moment().subtract(7, "days"), moment()],
          "Last 15 Days": [moment().subtract(15, "days"), moment()],
          // "This Month": [moment().startOf("month"), moment().endOf("month")],
        }}
        defaultValue={[moment(from), moment(to)]}
        defaultPickerValue={[moment(from), moment(to)]}
        value={[moment(from), moment(to)]}
        disabled={disabled ?? false}
        allowClear={false}
        renderExtraFooter={() => {
          return disabledDate ? (
            <Row style={{ alignItems: "center" }}>
              <InfoCircleOutlined
                style={{ marginRight: "5px", color: "#888888" }}
              />{" "}
              <span style={{ color: "#888888" }}>
                Dates must be within the last 32 days.
              </span>
            </Row>
          ) : (
            ""
          );
        }}
        // disabledDate={
        //   disabledDate ??
        //   ((d) => !d || !d.isBefore(TmsStringUtils.addDays(new Date(), -1)))
        // }
      />
    </Row>
  );
};
export default memo(TmsDatePickerRange);
