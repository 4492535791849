import { Row } from "antd";
import React, { useEffect } from "react";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import "../../../../../../assets/css/tms-common/tms-common.scss";
import {
  AccountCategorySelect,
  FdrInstitutionSelect,
} from "../../../../../../components/tms-customs/rents-and-leases/index";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  accountTypeData,
  createBankAccountStates,
} from "../../../../../../constants/states-data.data";
import useFdrStore from "../../../../../../zustand/fdr.store";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import TmsInput from "../../../../../../components/tms-customs/tms-input";
const TmsBankAccountCreation = ({ router, setVisible }) => {
  const {
    setSubmitLoading,
    submitLoading,
    fetchInstitutionList,
    handleBankAccountCreation,
    clearStates,
    setBankName,
    bankName,
    setBranchName,
    branchName,
    accountNumber,
    setAccountNumber,
    accountType,
    setAccountType,
    ifsccode,
    setIfsccode,
    location,
    setLocation,
    selectedInstitution,
    fetchCreatedAccountCategory,
  } = useFdrStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createBankAccountStates, [
        "institutionData",
        "accountCategoryData",
      ]);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedAccountCategory(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  return (
    <div>
      <Row gutter={[20, 20]} justify="start" style={{ marginBottom: "15px" }}>
        <FdrInstitutionSelect span={8} />
        <AccountCategorySelect span={8} />
      </Row>
      <Row
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#333333",
          padding: "10px 20px 10px 0px",
          width: "100%",
        }}
      >
        Bank Details
      </Row>
      <Row gutter={[20, 20]}>
        <TmsInput
          type="text"
          label="Name of the Bank"
          value={bankName}
          setValue={setBankName}
          span={8}
          placeholder={"Name of the Bank"}
        />
        <TmsInput
          type="text"
          label="Enter Branch Name"
          value={branchName}
          setValue={setBranchName}
          span={8}
          placeholder={"Enter Branch Name"}
        />
        <TmsInput
          type="number"
          label="Account number"
          value={accountNumber}
          setValue={setAccountNumber}
          span={8}
          placeholder={"Account number"}
        />

        <TmsSelect
          selectClass="w-100 "
          classname="mr-0 "
          type="col"
          value={accountType}
          setValue={setAccountType}
          label="Account Type"
          data={accountTypeData}
          span={8}
          optional={true}
        />

        <TmsInput
          type="text-number"
          label="IFSC Code"
          value={ifsccode}
          setValue={setIfsccode}
          span={8}
          placeholder={"Enter IFSC Code"}
        />
        <TmsInput
          type="text"
          label="Location"
          value={location}
          setValue={setLocation}
          span={8}
          placeholder={"Location"}
        />
      </Row>

      <TmsCancelSubmitButtons
        loading={submitLoading}
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(createBankAccountStates, [
            "institutionData",
            "accountCategoryData",
          ]);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleBankAccountCreation(router);
        }}
      />
    </div>
  );
};

export default withRouter(TmsBankAccountCreation);
