import { notification } from "antd";
import moment from "moment";
import { create } from "zustand";
import TmsPartnerService from "../services/partners.service";
import catchErrorFunction from "../utils/tms-catch-error/tms-catch-error";
import React from "react";
const usePartnersStore = create((set, get) => {
  const initialState = {
    loading: false,
    submitLoading: false,
    data: [],
    datesArray: [""],
    arrayStates: ["data"],
    newPartner: null,
    partnerName: "",
    partnerCode: "",
    permissionsData: [],
    keyPopup: false,
    partner: {},
    editFlag: false,
    visible: false,
    selectedTemplesData: [],
    editData: null,
    permissionFlag: false,
    rotateFlag: false,
  };
  const generateSetterFunctions = (stateObject) => {
    const setters = {};
    for (const key in stateObject) {
      setters[`set${key.charAt(0).toUpperCase()}${key.slice(1)}`] = (value) =>
        set({ [key]: value });
    }
    return setters;
  };
  const permsisionDataColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Temple Name",
      dataIndex: "temple",
      key: "temple",
      render: (record) => (
        <span>
          {record.name} - <b>({record?.shortName})</b>
        </span>
      ),
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      width: "33%",
      key: "permissions",
      render: (record) => {
        return (
          <div style={{ display: "flex", maxWidth: "40vw", flexWrap: "wrap" }}>
            {record?.map((item, i) => {
              return (
                <div
                  key={i + "permissions"}
                  style={{
                    margin: "0px 5px 5px 0px",
                    border: item?.includes("DARSHANAM")
                      ? "1px solid blue"
                      : item?.includes("SEVA")
                      ? "1px solid #FC4B35"
                      : "green",

                    padding: "3px 8px",
                    color: item?.includes("DARSHANAM") ? "blue" : "#FC4B35",

                    borderRadius: "4px",
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  return {
    ...initialState,
    ...generateSetterFunctions(initialState),
    permsisionDataColumns,
    checkForEmptyStates(array) {
      array.forEach((element) => {
        const value = get()[element];
        if (value === "") {
          throw new Error(
            `${element
              .split(/(?=[A-Z])/)
              .join(" ")} should not be empty. Please fill all the fields`
          );
        }
      });
    },
    clearStates(array, dataArrays) {
      const setters = {};
      const data = [...array, ...dataArrays];
      data.forEach((item) => {
        if (get().datesArray.includes(item)) {
          setters[item] = moment();
        } else if (get().arrayStates.includes(item)) {
          setters[item] = [];
        } else setters[item] = "";
      });
      set({ ...setters });
    },
    async fetchPartners(tableCurrentPage, tablePageSize, router) {
      try {
        set({ loading: true });
        let res = await TmsPartnerService.getPartners(
          tableCurrentPage,
          tablePageSize,
          router
        );
        set({ data: res, loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchPartnerPermissions(partnerId, router, returnFlag) {
      try {
        console.log(returnFlag);
        set({ loading: true });
        let res = await TmsPartnerService.getPartnerPermissionsById(
          partnerId,
          router
        );
        if (returnFlag) {
          set({
            loading: false,
          });
          return res.items;
        } else {
          set({
            permissionsData: res.items,
            loading: false,
            permissionFlag: true,
          });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handlePartnerCreation(router) {
      try {
        set({ submitLoading: true });
        const body = {
          partnerName: get().partnerName,
          partnerCode: get().partnerCode,
          permissions: get().selectedTemplesData,
        };
        let res = await TmsPartnerService.partnerCreation(body);
        notification.success({ message: "Partner created successfully" });
        set({ submitLoading: false, newPartner: res, keyPopup: true });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ submitLoading: false });
        return;
      }
    },
    async handlePartnerPermissions(router) {
      try {
        set({ submitLoading: true });
        const body = {
          partnerId: get().editData.id,
          permissionsDTO: get().selectedTemplesData,
        };
        await TmsPartnerService.partnerPermissionUpdate(body);
        notification.success({
          message: "Partner permisions updated successfully",
        });
        set({ submitLoading: false, editData: null, editFlag: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ submitLoading: false });
        return;
      }
    },
    async handleKeyRotate(type, partnerId, router) {
      let res;
      set({ rotateFlag: true });
      try {
        if (type === "primary") {
          res = await TmsPartnerService.rotatePrimaryKey(partnerId);
        } else {
          res = await TmsPartnerService.rotateSecondaryKey(partnerId);
        }
        set({ submitLoading: true });

        notification.success({ message: "Key updated successfully" });
        set({
          rotateFlag: false,
          submitLoading: false,
          newPartner: res,
          keyPopup: true,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ submitLoading: false });
        return;
      }
    },
    async fetchPartnersByTemple(router) {
      try {
        set({ loading: true });
        let res = await TmsPartnerService.getPartnersByTemple();
        set({ data: res, loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchPartnersByTempleIDorCode(type, id, router, returnFlag) {
      try {
        set({ loading: true });
        let res = null;
        if (type == "Id") {
          res = await TmsPartnerService.getPartnerByTemplePId(id);
        } else {
          res = await TmsPartnerService.getPartnerByTempleCode(id);
        }
        if (returnFlag) {
          set({
            loading: false,
          });
          return res.items;
        } else {
          set({
            permissionsData: res.items,
            loading: false,
            permissionFlag: true,
          });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
  };
});
export default usePartnersStore;
