import { Button, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import useRentsAndLandStore from "../../../../../zustand/rents-and-leases.store";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import {
  additionalArrearStates,
  leaseCreationStates,
} from "../../../../../constants/states-data.data";
import TmsSelect from "../../../../../components/tms-customs/tms-select";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import TmsDatepickerRange from "../../../../../components/tms-customs/tms-datepicker-range";

const TmsRalLeaseReports = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  // const [sortType, setSortType] = useState("ASC");
  // const [sortBy, setSortBy] = useState("createdAt");
  const {
    selectedInstitution,
    setSelectedInstitution,
    loading,
    institutionData,
    fetchLeaseReports,
    fetchInstitutionList,
    leaseReports,
    clearStates,
    fetchTotalReports,
    leasePeriodStartDate,
    leasePeriodEndDate,
    setLeasePeriodEndDate,
    setLeasePeriodStartDate,
  } = useRentsAndLandStore();

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    router.location.pathname === tmsRouteMap.reports_ral_lands_leases &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(leaseCreationStates, [
        ...additionalArrearStates,
        "leaseReports",
      ]);
    };
  }, [router.location.pathname]);

  useEffect(() => {
    if (router.location?.pathname === tmsRouteMap.reports_ral_lands_leases) {
      if (selectedInstitution !== "" && selectedInstitution) {
        fetchLeaseReports(
          tableCurrentPage,
          tablePageSize,
          leasePeriodStartDate,
          leasePeriodEndDate,
          router
        );
      } else {
        fetchTotalReports("LEASE", tableCurrentPage, tablePageSize, router);
      }
    }
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedInstitution,
    leasePeriodStartDate,
    leasePeriodEndDate,
    router?.location?.pathname,
  ]);

  const innerLeaseColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    { title: "Lesse ID", dataIndex: "lesseId", key: "lesseId" },
    {
      title: "Name of Temple",
      dataIndex: "institutionName",
      key: "institutionName",
    },
    {
      title: "Lesse Name",
      dataIndex: "lesseName",
      key: "lesseName",
    },
    {
      title: "Mobile ",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },

    {
      title: "Survey No.",
      dataIndex: "surveyNumber",
      key: "surveyNumber",
    },
    {
      title: "Lease(From-To)",
      key: "lease",
      render: (record) =>
        `${moment(record.leasePeriodStartDate).format("DD-MM-YYYY")} - ${moment(
          record.leasePeriodEndDate
        ).format("DD-MM-YYYY")}`,
    },
    { title: "Lease Term", dataIndex: "paymentTerm", key: "paymentTerm" },
    { title: "Lease Amount", dataIndex: "leaseRate" },
    { title: "Deposit ", dataIndex: "depositAmount" },
    { title: "Arrear ", dataIndex: "arrearAmount" },
    {
      title: "Balance ",
      render: (record) => `${record.arrearAmount - record.totalPaidAmount}`,
    },

    { title: "Txn no", dataIndex: "transactionId" },
  ];
  const totalLeaseColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Institution",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Village",
      dataIndex: "village",
      key: "village",
    },

    {
      title: "Leased Land (In Acres)",
      dataIndex: "leasedAcres",
      key: "leasedAcres",
    },
    {
      title: "Leased Land (In Cents)",
      dataIndex: "leasedCents",
      key: "leasedCents",
    },

    {
      title: "Total Leased Land (In Acres)",
      dataIndex: "totalLeasedAcres",
      key: "totalLeasedAcres",
    },
  ];
  return (
    <>
      <Row justify="flex-start">
        <TmsSelect
          selectClass="w-100 "
          classname="mr-20 align-center"
          labelClass="mr-10 mb-0"
          type="col"
          value={selectedInstitution}
          setValue={setSelectedInstitution}
          label="Select Institution"
          data={institutionData?.items?.map((item) => {
            return { name: item.name, id: item.id };
          })}
          span={5}
          optional
        />

        {selectedInstitution !== "" && (
          <>
            {" "}
            <TmsDatepickerRange
              label={"Range"}
              from={leasePeriodStartDate}
              to={leasePeriodEndDate}
              setFrom={setLeasePeriodStartDate}
              setTo={setLeasePeriodEndDate}
            />
            <Button
              style={{ marginTop: "27px", marginLeft: "10px" }}
              onClick={() => {
                setSelectedInstitution("");
              }}
              type="primary"
            >
              Clear
            </Button>
          </>
        )}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        loading={loading}
        scroll={{ x: "max-content" }}
        style={{
          width: "auto",
          textTransform: "capitalize",
        }}
        columns={
          selectedInstitution !== "" && selectedInstitution
            ? innerLeaseColumns.map((item) => {
                return { ...item, align: "center" };
              })
            : totalLeaseColumns.map((item) => {
                return { ...item, align: "center" };
              })
        }
        dataSource={leaseReports?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: leaseReports?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsRalLeaseReports);
