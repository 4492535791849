import {
  Button,
  Card,
  Col,
  Drawer,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import TmsTable from "../../../../components/tms-customs/tms-table";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsManualEntryService from "../../../../services/tms-manual-entry.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const TmsSmManualEntryCategory = ({ router, categoryData }) => {
  const [viewSlider, setViewSlider] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [editData, setEditData] = useState([]);

  const clearFields = () => {
    setLoading(false);
    setViewSlider(false);
    setName("");
    setPrice("");
    router.navigate(`${tmsRouteMap.serviceManagement_manual_entry}`);
  };

  useEffect(() => {
    console.log(editData);
    setName(editData?.name ? editData?.name : "");
    setPrice(editData?.price ? editData?.price : "");
  }, [editData]);

  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let body = {
      name: name,
      price: Number(price),
    };
    if (editData?.id > 0) {
      body.id = editData?.id;
    }

    try {
      editData?.id > 0
        ? await TmsManualEntryService.editCategory(body)
        : await TmsManualEntryService.addCategory(
            Number(new URLSearchParams(router?.location?.search).get("bookid")),
            body
          );
      clearFields();
      notification.success({
        message: `New Category ${
          editData?.id > 0 ? "Edited" : "Added"
        } Successful`,
      });
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    { title: "Id", dataIndex: "id", key: "id" },
    {
      title: "Category",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price > b.price,
      render: (text) => {
        return (
          <>
            Rs. {text}
            <br />
          </>
        );
      },
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Are you sure delete this Category?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await TmsManualEntryService.deleteCategory(record?.id);
                  notification.success({
                    message: "Category Deleted Successful",
                  });
                  router.navigate(
                    `${tmsRouteMap.serviceManagement_manual_entry}`
                  );
                } catch (error) {
                  catchErrorFunction(
                    error,
                    "Token Expired, redirecting to login page in 5 seconds",
                    router
                  );
                }
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
            <a
              onClick={async () => {
                setEditData(record);
                setViewSlider(true);
              }}
            >
              <EditOutlined />
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Row justify="start">
        <Button
          type="primary"
          onClick={() => {
            setViewSlider(true);
          }}
          loading={loading}
          style={{ color: "white !important" }}
        >
          Add Category
        </Button>
      </Row>
      <br />
      <Row style={{ width: "100%" }}>
        <Card style={{ width: "100%" }}>
          <TmsTable columns={columns} dataSource={categoryData} />
        </Card>
      </Row>
      <Drawer
        visible={viewSlider}
        onClose={() => {
          setViewSlider(false);
          setEditData([]);
        }}
        width={500}
        title={editData?.id > 0 ? "Edit Category" : "Add Category"}
        closable={false}
      >
        <Row gutter={[20, 20]}>
          <Col width={"50%"}>
            <label>Category</label>
            <br />
            <Input
              maxLength={220}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col width={"50%"}>
            <label>Price</label>
            <br />
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Col>
        </Row>
        <br />
        <Button loading={loading} onClick={() => handleSubmit()} type="primary">
          Submit
        </Button>
      </Drawer>
    </div>
  );
};

export default withRouter(TmsSmManualEntryCategory);
