import moment from "moment";
import { create } from "zustand";
import TmsContactUsService from "../services/contact.service";
import catchErrorFunction from "../utils/tms-catch-error/tms-catch-error";

const useContactUsStore = create((set, get) => {
  const initialState = {
    loading: false,
    data: [],
    datesArray: [""],
    arrayStates: ["data"],
  };
  const generateSetterFunctions = (stateObject) => {
    const setters = {};
    for (const key in stateObject) {
      setters[`set${key.charAt(0).toUpperCase()}${key.slice(1)}`] = (value) =>
        set({ [key]: value });
    }
    return setters;
  };

  return {
    ...initialState,
    ...generateSetterFunctions(initialState),
    checkForEmptyStates(array) {
      array.forEach((element) => {
        const value = get()[element];
        if (value === "") {
          throw new Error(
            `${element
              .split(/(?=[A-Z])/)
              .join(" ")} should not be empty. Please fill all the fields`
          );
        }
      });
    },
    clearStates(array, dataArrays) {
      const setters = {};
      const data = [...array, ...dataArrays];
      data.forEach((item) => {
        if (get().datesArray.includes(item)) {
          setters[item] = moment();
        } else if (get().arrayStates.includes(item)) {
          setters[item] = [];
        } else setters[item] = "";
      });
      set({ ...setters });
    },
    async fetchSupportRequests(tableCurrentPage, tablePageSize, router) {
      try {
        set({ loading: true });
        let res = await TmsContactUsService.getContactUsData(
          tableCurrentPage,
          tablePageSize,
          router
        );
        set({ data: res, loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
  };
});
export default useContactUsStore;
