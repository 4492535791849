import { Button, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import useRentsAndLandStore from "../../../../../zustand/rents-and-leases.store";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import { createLandStates } from "../../../../../constants/states-data.data";
import TmsSelect from "../../../../../components/tms-customs/tms-select";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";

const TmsRalLandReports = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  // const [sortType, setSortType] = useState("ASC");
  // const [sortBy, setSortBy] = useState("createdAt");
  const {
    selectedInstitution,
    setSelectedInstitution,
    loading,
    institutionData,
    fetchLandReports,
    fetchInstitutionList,
    landReports,
    clearStates,
    fetchTotalReports,
  } = useRentsAndLandStore((state) => {
    return {
      selectedInstitution: state.selectedInstitution,
      loading: state.loading,
      setSelectedInstitution: state.setSelectedInstitution,
      institutionData: state.institutionData,
      landReports: state.landReports,
      fetchLandReports: state.fetchLandReports,
      fetchInstitutionList: state.fetchInstitutionList,
      clearStates: state.clearStates,
      fetchTotalReports: state.fetchTotalReports,
    };
  }, shallow);

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    if (
      router.location.pathname === tmsRouteMap.reports_ral_lands_lands ||
      router.location.pathname === tmsRouteMap.reports_ral_lands
    )
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createLandStates, ["landReports"]);
    };
  }, [router.location.pathname]);

  useEffect(() => {
    if (
      router.location.pathname.includes(tmsRouteMap.reports_ral_lands_lands) ||
      router.location.pathname === "/reports/rents-and-leases/lands"
    )
      if (selectedInstitution !== "" && selectedInstitution) {
        fetchLandReports(tableCurrentPage, tablePageSize, router);
      } else {
        fetchTotalReports("LAND", tableCurrentPage, tablePageSize, router);
      }
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedInstitution,
    router?.location?.pathname,
  ]);
  const innerLandColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Name of Temple",
      dataIndex: "institutionName",
      key: "institutionName",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Nature of Land",
      dataIndex: "natureOfLand",
      key: "natureOfLand",
    },
    {
      title: "Survey No.",
      dataIndex: "surveyNumber",
      key: "surveyNumber",
    },
    {
      title: "Extent(Acres)",
      dataIndex: "extentInAcres",
      key: "extentInAcres",
    },
    {
      title: "Extent(Cents)",
      dataIndex: "extentInCents",
      key: "extentInCents",
    },
  ];
  const totalLandColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Institution",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Village",
      dataIndex: "village",
      key: "village",
    },
    {
      title: "Agricultural",
      children: [
        {
          title: "Acres",
          dataIndex: "totalAgricultureLandAcres",
          key: "totalAgricultureLandAcres",
          align: "center",
        },
        {
          title: "Cents",
          dataIndex: "totalAgricultureLandCents",
          key: "totalAgricultureLandCents",
          align: "center",
        },
      ],
    },
    {
      title: "Non-Agricultural",
      children: [
        {
          title: "Acres",
          dataIndex: "totalNonAgricultureLandAcres",
          key: "totalNonAgricultureLandAcres",
          align: "center",
        },
        {
          title: "Cents",
          dataIndex: "totalNonAgricultureLandCents",
          key: "totalNonAgricultureLandCents",
          align: "center",
        },
      ],
    },

    {
      title: "Total Land (In Acres)",
      dataIndex: "totalLandAcres",
      key: "totalLandAcres",
    },
  ];
  return (
    <>
      <Row justify="flex-start">
        <TmsSelect
          selectClass="w-100 "
          classname="mr-20 align-center"
          labelClass="mr-10 mb-0"
          type="col"
          value={selectedInstitution}
          setValue={setSelectedInstitution}
          label="Select Institution"
          data={institutionData?.items?.map((item) => {
            return { name: item.name, id: item.id };
          })}
          span={5}
          optional
        />
        {selectedInstitution !== "" && (
          <Button
            style={{ marginTop: "27px" }}
            onClick={() => {
              setSelectedInstitution("");
            }}
          >
            Clear
          </Button>
        )}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        loading={loading}
        style={{
          width: "100%",
          textTransform: "capitalize",
        }}
        columns={
          selectedInstitution !== "" && selectedInstitution
            ? innerLandColumns.map((item) => {
                return { ...item, align: "center" };
              })
            : totalLandColumns.map((item) => {
                return { ...item, align: "center" };
              })
        }
        dataSource={landReports?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: landReports?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsRalLandReports);
