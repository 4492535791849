import { Col, notification, Row, Button } from "antd";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
// import { FormatNumberINRCurrency } from "../../../../utils/tms-currency-formatter/tms-curreny-formatter";
import "../../../../assets/css/tms-reports/tms-reports.scss";
import { BaseContext } from "../../../../contexts/BaseContext";
import TmsAccommodationService from "../../../../services/tms-accommodation.service";
import { FormatNumberINRCurrency } from "../../../../utils/tms-currency-formatter/tms-curreny-formatter";

const TmsReportAccommodationRenewal = ({ router }) => {
  const [loading, setLoading] = useState();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [dateType, setDateType] = useState("booking");
  const [sortType, setSortType] = useState("DESC");
  //   const [bookingType, setBookingType] = useState("pos");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [roomType, setRoomType] = useState("");
  const [blocksList, setblocksList] = useState([]);
  const [blockId, setBlockId] = useState("");
  const { roomMapData } = useContext(BaseContext);

  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);
  const [sortBy, setSortBy] = useState("bookingDate");
  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [roomList, setRoomList] = useState([]);

  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await TmsAccommodationService.renewalReport(
        "json",
        Number(tableCurrentPage),
        Number(tablePageSize),
        bookingFromDate,
        bookingToDate,
        roomType,
        Number(blockId),
        selectedRoom,
        Number(counter),
        shift,
        dateType,
        sortType,
        sortBy
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.renewalId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      let data = await TmsAccommodationService.renewalReport(
        "json",
        Number(tableCurrentPage),
        Number(50000),
        bookingFromDate,
        bookingToDate,
        roomType,
        Number(blockId),
        selectedRoom,
        Number(counter),
        shift,
        dateType,
        sortType,
        sortBy,
        "success"
      );

      // data = data?.items?.reverse();
      let compiledDataOffile = [];

      compiledDataOffile = data.items.map((item, i) => {
        return {
          "S.no": i + 1,
          "POS Name": item?.counterName,
          "Shift ID": item?.counterShiftTimingId,
          "Renewal Id": item?.renewalId,
          "Transaction Id": `${
            item?.categoryTransactionSequence?.split("-")[3]
          }-${item?.categoryTransactionSequence?.split("-")[4]}`,
          "Room Type ": item?.roomType,
          "Block Name": item?.blockName,
          "Room Number": item?.roomNumber,
          "Devotee Name": item?.devoteeName,
          "Devotee DOB": moment(item?.dob)?.format("DD-MM-YYYY"),
          "Aadhar No": item?.documentId,
          Contact: item?.contact ?? "-",
          Email: item?.email ?? "-",
          Address: `${item?.address ?? ""} ${item?.address2 ?? ""} ${
            item?.city ?? ""
          } ${item?.state ?? ""} ${item?.country ?? ""} ${item?.pinCode ?? ""}`,
          "Booking Date": item?.bookingDate
            ? moment(item?.bookingDate)?.format("DD-MM-YYYY hh:mm A")
            : "-",
          "Renewal On": item?.renewalAt
            ? moment(item?.renewalAt)?.format("DD-MM-YYYY hh:mm A")
            : "-",
          "Unpaid Days": item?.unpaidDays,
          "Room Price": item?.roomPrice,
          GST: item?.roomGst,
          "Total Amount": Number(
            item?.totalRoomPriceWithGst * item?.unpaidDays
          ),
        };
      });

      exportExcel(
        compiledDataOffile,
        `renewal-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };
  const fetchblocksList = async () => {
    try {
      let data = [];
      setblocksList([]);
      setBlockId("");
      // setSelectedRoom("");
      // setRoomList([]);
      {
        data = await TmsAccommodationService.getAllBlocks(1, 200);
      }

      setTabelTotalSize(data?.meta?.totalItems);
      setblocksList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchRoomList = async () => {
    try {
      setSelectedRoom("");
      setRoomList([]);
      const res = await TmsAccommodationService.getRoomsByBlockId(blockId);
      // console.log(res);
      setRoomList(
        res?.map((item) => {
          return {
            key: item.id,
            name: `${item.description} - ${item.roomNumber}`,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  useEffect(() => {
    fetchCounterList();
    fetchShiftList();
  }, []);

  useEffect(() => {
    if (blockId !== "") {
      fetchRoomList();
    }
  }, [blockId]);

  useEffect(() => {
    // if (roomType !== "") {
    fetchblocksList();
    // }
  }, [roomType]);

  useEffect(() => {
    fetchBookingDetails();
  }, [
    tableCurrentPage,
    tablePageSize,

    // bookingType,
  ]);

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },

    {
      title: "Devotee Name",
      render: (record) => {
        return `${record?.devoteeName}`;
      },
    },

    {
      title: "Room Type",
      dataIndex: "roomType",
      key: "roomType",
      render: (record) => {
        return `${record?.replace("_", " ")}`;
      },
    },
    {
      title: "Block Name",
      dataIndex: "blockName",
      key: "blockName",
    },
    {
      title: "Room Number",
      dataIndex: "roomNumber",
      key: "roomNumber",
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Renewal On",
      dataIndex: "renewalAt",
      key: "renewalAt",
      render: (text) => {
        return text ? moment(text).format("DD-MM-YYYY") : "-";
      },
    },
    { title: "Unpaid Days", dataIndex: "unpaidDays", key: "unpaidDays" },
    {
      title: "Room Price",
      dataIndex: "roomPrice",
      render: FormatNumberINRCurrency,
    },
    {
      title: "GST",
      dataIndex: "roomGst",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Total Amount",
      dataIndex: "totalRoomPriceWithGst",
      render: (text, record) => {
        return FormatNumberINRCurrency(text * record?.unpaidDays);
      },
    },
  ].filter((item) => !item.hidden);

  return (
    <div>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Room Type"
          setValue={setRoomType}
          value={roomType}
          data={roomMapData}
        />

        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Block Name"
          setValue={(e) => {
            setBlockId(e);
            if (e === "") {
              setSelectedRoom("");
              return;
            }
          }}
          value={blockId}
          data={blocksList}
        />

        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Room Number"
          setValue={setSelectedRoom}
          value={selectedRoom}
          data={roomList}
        />

        <TmsSelect
          type="col"
          span={3}
          label="POS"
          setValue={setCounter}
          value={counter}
          data={counterList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shift"
          setValue={setShift}
          value={shift}
          data={shiftList}
        />
        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <Col>
          <Row justify="start" style={{ marginBottom: "5px" }}>
            Update
          </Row>
          <Button type="primary" onClick={fetchBookingDetails}>
            Fetch
          </Button>
        </Col>
      </Row>
      <br />
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Sort By"
          setValue={setSortBy}
          value={sortBy}
          data={[
            { id: "bookingDate", name: "Booking Date" },
            { id: "checkInDate", name: "Check In" },
            { id: "checkOutDate", name: "Check Out" },
          ]}
          optional={true}
        />
        <TmsSelect
          type="col"
          label="Date Type"
          span={3}
          setValue={setDateType}
          value={dateType}
          data={[
            { id: "booking", name: "Performance" },
            { id: "payment", name: "Booking" },
          ]}
        />
        <Col style={{ padding: "0" }}>
          <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
          <TmsExcelButton
            loading={excelLoading}
            handleExcelExport={handleExcelExport}
            top={false}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          fontSize={true}
          expandable={{
            expandedRowRender: (record) => (
              <Row justify="space-evenly">
                <Col className="mr-10">
                  <b>Renewal ID:</b> {record.key}
                </Col>
                |
                <Col className="mr-10">
                  <b> Devotee Contact</b>:{" "}
                  {record.contact !== null ? record.contact : "-"}
                </Col>
                |
                <Col className="mr-10">
                  <b> Counter</b>: {record.counterName} | {record?.userName}
                </Col>
              </Row>
            ),
          }}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </div>
  );
};

export default withRouter(TmsReportAccommodationRenewal);
