import { Button, Modal, Statistic } from "antd";
import React from "react";
const { Countdown } = Statistic;

const TmsPosDevicePopup = ({
  setPreviewFlag,
  value,
  intervalId,
  setValue,
  clearFunction,
  message,
}) => {
  const deadline = Date.now() + 1000 * 60 * 4; // Moment is also OK
  return (
    <Modal
      visible={value}
      width={700}
      footer={
        <>
          <Button
            type="danger"
            onClick={() => {
              setPreviewFlag(false);
              setValue(false);
              clearInterval(intervalId);
              clearFunction();
            }}
          >
            Cancel
          </Button>
        </>
      }
      closable={false}
      centered
      maskClosable={false}
      onCancel={() => {
        setPreviewFlag(false);
        setValue(false);
        clearInterval(intervalId);
        clearFunction();
      }}
    >
      <div style={{ textAlign: "center" }}>
        {message ??
          `Request Has been sent to the respective POS paymnet device. Please check
        your device for payment notification and finish the payment.`}
      </div>
      <br />
      <p style={{ textAlign: "center" }}>
        Do not reload, It will make the txrn invalid. If you want to cancel the
        txrn click cancel in the device.{" "}
      </p>
      <br />
      <center>
        {" "}
        <Countdown
          title=" Txn Expires in"
          value={deadline}
          textAlign="center"
        />
      </center>{" "}
    </Modal>
  );
};

export default TmsPosDevicePopup;
