import React from "react";
import TmsReportRoutes from "../../routes/tms-report-routes";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
const TmsReports = () => {
  return (
    <div>
      <TmsReportRoutes />
    </div>
  );
};
export default withRouter(TmsReports);
