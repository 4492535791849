import React, { useEffect, useState } from "react";
import TmsAccommodationService from "../../../services/tms-accommodation.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsPosTicketDetails from "./tms-pos-ticket-details";
import TmsModal from "../../../components/tms-customs/tms-modal";
import { Button, notification, Row, Space } from "antd";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import moment from "moment-timezone";
import {
  captureBiometric,
  checkConnection,
  verifyBiometric,
} from "../../../utils/tms-biometric/tms-biometric";

const TmsPosAccommodationScan = ({ router }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ticketVisible, setTicketVisible] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState();
  const [externalPrint, setExternalPrint] = useState(false);
  const [array, setArray] = useState([]);
  const [ticketId, setTicketId] = useState();
  const [printFlag, setPrintFlag] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [fingerloading, setFingerLoading] = useState(false);
  const [match, setMatch] = useState(null);
  const [captured, setCaptured] = useState(null);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  const bookingData = (item) => {
    let schemaBody = [];
    setTicketId(item?.newCounterCartItem?.ticketId);
    schemaBody = [
      ...schemaBody,
      [
        "header",
        item?.newCounterCartItem?.categoryTransactionSequence
          ?.split("-")
          ?.pop(),
        {},
        item?.newCounterCartItem?.dailyCategoryTransactionSequence
          ?.split("-")
          ?.pop(),
        item?.newCounterCartItem?.ticketId,
      ],
      ["Ticket Id", item?.newCounterCartItem?.ticketId, { margin: "0" }],
      [
        "Devotee Name",
        item?.accRoomBookingStats?.devoteeName,
        { margin: "0", fontWeight: "bold" },
      ],
      [
        "Room Details",
        `${item?.accRoomBookingStats?.roomType}-${item?.accRoomBookingStats?.blockName}-${item?.accRoomBookingStats?.roomNumber}`,
        { margin: "0" },
      ],
      [
        "Checkin Time",
        `${moment(
          item?.accRoomBookingStats?.checkInTime,
          "YYYY-MM-DD HH:mm:ss Z"
        ).format("DD-MM-YYYY hh:mm A")}`,
        { margin: "0" },
      ],
      [
        "Checkout Time",
        flag === "checkOut"
          ? `${moment().format("DD-MM-YYYY hh:mm A")}`
          : `${moment(
              item?.accRoomBookingStats?.newCheckOutTime,
              "YYYY-MM-DD HH:mm:ss Z"
            ).format("DD-MM-YYYY hh:mm A")}`,
        { margin: "0" },
      ],
      [
        "Room Price",
        `Rs. ${item?.accRoomBookingStats?.roomPrice}.00`,
        { margin: "0" },
      ],
      // [
      //   "Service Amount",
      //   item?.accRoomBookingStats?.serviceAmount > 0
      //     ? `Rs. ${item?.accRoomBookingStats?.serviceAmount}.00`
      //     : "null",
      //   { margin: "0" },
      // ],
      [
        "GST Amount",
        item?.accRoomBookingStats?.gstAmount > 0
          ? `Rs. ${item?.accRoomBookingStats?.gstAmount}.00`
          : "null",
        { margin: "0" },
      ],
      [
        "Deposit Amount",
        `Rs. ${item?.accRoomBookingStats?.depositAmount}.00`,
        { margin: "0" },
      ],
      [
        item?.accRoomBookingStats?.outstandingAmount >= 0
          ? "Amount Paid"
          : "Amount Received",
        `Rs. ${
          item?.accRoomBookingStats?.outstandingAmount > 0
            ? item?.accRoomBookingStats?.outstandingAmount
            : item?.accRoomBookingStats?.outstandingAmount * -1
        }.00 `,
        { margin: "0", fontSize: "22px", fontWeight: "800" },
      ],
      [
        "",
        `(${numberToWords(
          Number(
            item?.accRoomBookingStats?.outstandingAmount > 0
              ? item?.accRoomBookingStats?.outstandingAmount
              : item?.accRoomBookingStats?.outstandingAmount * -1
          )
        )} Rupees)`,
        { margin: "0", fontWeight: "bold" },
      ],
      [
        "footer",
        item?.newCounterCartItem?.ticketId,
        {},
        {},
        {},
        null,
        item?.newCounterCartItem?.createdAt,
      ],
    ];

    setArray([...schemaBody]);
  };

  const submit = (details) => {
    setFlag(details?.func);
    let body = { roomBookingId: Number(details?.roomBookingId) };
    if (details?.func === "checkOut") {
      if (details?.outstandingAmount > 0) {
        body.outStandingBalance = Number(details?.outstandingAmount);
      } else {
        body.depositReturned = Number(details?.outstandingAmount * -1);
      }
      if (templeDetails?.configuration?.enableAccommodationFingerScan) {
        body.fingerPrintTemplate = captured?.IsoTemplate;
      }
    } else if (details?.func === "renew") {
      body.serviceAmount = details?.outstandingAmount;
      setPrintFlag(true);
    }
    setData(body);
    setIsModalVisible(true);
  };

  const roomUpdate = async () => {
    setLoading(false);
    try {
      const resp = await TmsAccommodationService.roomBookingUpdate(data, flag);
      bookingData(resp);
      setTicketVisible(true);
      if (flag === "renew") {
        setExternalPrint(true);
      }

      setLoading(false);
      setIsModalVisible(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const checkIfDeviceIsReady = async () => {
    try {
      const res = await checkConnection();
      if (res.data.ErrorCode === "0") {
        setDeviceInfo(res);
      } else {
        setDeviceInfo(null);
        notification.error({ message: res.data.ErrorDescription });
      }
    } catch (E) {
      catchErrorFunction(E, "Something went wrong with Device.", router);
    }
  };

  const scanFingerPrint = async (oldTemplate, flag) => {
    setFingerLoading(true);
    try {
      const res = await captureBiometric();
      if (res.data.ErrorCode !== "0") {
        setCaptured(res.data);
        setMatch(null);
        notification.error({ message: res.data.ErrorDescription });
      } else {
        setCaptured(res.data);
        if (flag === "verify") {
          setMatch(null);
          matchFingerPrint(oldTemplate, res?.data?.IsoTemplate);
        } else if (flag === "onlyScan") {
          setMatch(true);
          notification.success({ message: "Captured Successfully" });
        }
      }
      setFingerLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Something went wrong with the device", router);
      setMatch(null);
      setFingerLoading(false);
    }
  };

  const matchFingerPrint = async (oldTemplate, newTemplate) => {
    setFingerLoading(true);
    try {
      const res = await verifyBiometric(oldTemplate, newTemplate);
      if (res.data.ErrorCode !== "0") {
        notification.error({ message: res.data.ErrorDescription });
        setMatch(null);
        setFingerLoading(false);
      } else {
        if (res.data.Status) {
          notification.success({ message: "Finger print matched" });
          setMatch(true);
          setFingerLoading(false);
        } else {
          notification.error({ message: "Finger print mismatch" });
          setMatch(false);
          setFingerLoading(false);
        }
      }
    } catch (error) {
      catchErrorFunction(error, "Something went wrong with the device", router);
    }
  };

  useEffect(() => {
    if (templeDetails?.configuration?.enableAccommodationFingerScan) {
      setTimeout(() => {
        checkIfDeviceIsReady();
      }, 1000);
    }
  }, []);

  return (
    <>
      {/* {deviceInfo && (
        <>
          {" "}
          <Button
            onClick={() => {
              if (captured === null) {
                scanFingerPrint();
              } else {
                setCaptured(null);
              }
            }}
          >
            {captured !== null ? "Clear Captures" : "Capture"}
          </Button>
          <Button
            onClick={() => {
              matchFingerPrint();
            }}
          >
            Match Prev
          </Button>
        </>
      )} */}

      <TmsPosTicketDetails
        from="accoScan"
        bookingUpdate={submit}
        scanFingerPrint={scanFingerPrint}
        checkIfDeviceIsReady={checkIfDeviceIsReady}
        fingerloading={fingerloading}
        match={match}
        setMatch={setMatch}
        deviceInfo={deviceInfo}
        captured={captured}
        setCaptured={setCaptured}
      />

      <TmsModal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
          Are you sure ?
        </Row>
        <Row style={{ justifyContent: "center", margin: "auto" }}>
          <Space>
            <Button type="primary" onClick={roomUpdate} loading={loading}>
              Yes
            </Button>
            <Button
              onClick={() => {
                setIsModalVisible(false);
                setTicketVisible(false);
              }}
              loading={loading}
            >
              No
            </Button>
          </Space>
        </Row>
      </TmsModal>
      <TmsModal
        visible={ticketVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        {flag === "checkOut" ? (
          !printFlag ? (
            <>
              <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                Do you want to print ticket ?
              </Row>
              <Row style={{ justifyContent: "center", margin: "auto" }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setPrintFlag(true);
                      setExternalPrint(true);
                    }}
                    loading={loading}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setIsModalVisible(false);
                      setTicketVisible(false);
                      setPrintFlag(false);
                      window.location.reload();
                    }}
                    loading={loading}
                  >
                    No
                  </Button>
                </Space>
              </Row>
            </>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {printFlag && (
          <TmsPrintTicket
            type={flag === "checkOut" ? "Checkout" : "Renew"}
            from="Counter"
            setData={setArray}
            data={array}
            externalPrint={externalPrint}
            setExternalPrint={setExternalPrint}
            setButtonLoading={setLoading}
            setPrintTicketModal={setTicketVisible}
            ticketId={ticketId}
            printType="a4"
          />
        )}
      </TmsModal>
    </>
  );
};

export default withRouter(TmsPosAccommodationScan);
