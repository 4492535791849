import React, { useEffect, useState } from "react";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsThulabharamInnerPagesAll from "./tms-sm-thulabharam-inner-pages/tms-thulabharam-inner-pages-all";
import TmsThulabharamInnerPagesAnd from "./tms-sm-thulabharam-inner-pages/tms-thulabharam-inner-pages-and";
// import TmsThulabharamInnerPagesDbd from "./tms-sm-thulabharam-inner-pages/tms-thulabharam-inner-pages-dbd";

const TmsSmThulabharam = ({ router }) => {
  const [active, setActive] = useState("all");

  useEffect(() => {
    let location = router.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_thulabharam) {
      setActive("all");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_thulabharam_add_new_thulabharam)
    ) {
      setActive("Add New Thulabharam");
    }
    // else if (location.includes(tmsRouteMap.sm_thulabharam_booking_details)) {
    //   setActive("Thulabharam Booking Details");
    // }
    else if (location.includes(tmsRouteMap.sm_thulabharam_manage_counter)) {
      setActive("all");
      return;
    }
  }, [router.location?.pathname]);
  const location = router.location?.pathname;
  const components = [
    {
      name: "All",
      Element: location.includes(tmsRouteMap.sm_thulabharam_manage_counter) ? (
        <TmsCmsSlots type="thulabharam-counters" />
      ) : (
        <TmsThulabharamInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_thulabharam,
    },

    {
      name: "Add New Thulabharam",
      Element: <TmsThulabharamInnerPagesAnd />,
      key: "Add New Thulabharam",
      route: tmsRouteMap.sm_thulabharam_add_new_thulabharam,
    },
    // {
    //   name: "Thulabharam Booking Details",
    //   Element: <TmsThulabharamInnerPagesDbd />,
    //   key: "Thulabharam Booking Details",
    //   route: tmsRouteMap.sm_thulabharam_booking_details,
    // },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmThulabharam);
