import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import "../../../assets/css/tms-pos-products/tms-pos-products.scss";
import moment from "moment";
import { RightOutlined } from "@ant-design/icons";
import TmsTable from "../../../components/tms-customs/tms-table";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import TmsPublicationService from "../../../services/tms-publications.service";
import TmsSelect from "../../../components/tms-customs/tms-select";
const TmsPosPublication = ({ router }) => {
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [type, setType] = useState("PURCHASE");
  const [loading, setLoading] = useState("false");
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  // const [paymentType, setPaymentType] = useState("CASH");
  const [country, setCountry] = useState("India");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [devoteeName, setDevoteeName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [array, setArray] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [filteredData, setFilteredData] = useState(products);

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  let cartArray = [];

  const ProductData = (data) => {
    let schemaBody = [];
    data?.cartItems?.publication?.map((item) => {
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
        ],
        ["Ticket Id", item?.counterCartItem?.ticketId, { margin: "0" }],
        [
          "Product",
          type === "PUBLICATION"
            ? `${item?.productDetails?.productName} - ${item?.publicationBooking?.language}`
            : item?.productDetails?.productName,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Qty",
          item?.publicationBooking?.noOfItems,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Devotee Name",
          item?.publicationBooking?.name
            ? item?.publicationBooking?.contact
              ? `${item?.publicationBooking?.name?.split(",")[0].trim()} | ${
                  item?.publicationBooking?.contact
                }`
              : item?.publicationBooking?.name?.split(",")[0].trim()
            : item?.publicationBooking?.contact ?? "null",
          { margin: "0" },
        ],
        [
          "Address",
          type === "PUBLICATION"
            ? `${item?.publicationBooking?.address}, ${item?.publicationBooking?.city}, ${item?.publicationBooking?.state}-${item?.publicationBooking?.pinCode}`
            : "null",
          { margin: "0" },
        ],
        ["Purchase Date", moment().format("DD-MM-YYYY"), { margin: "0" }],
        [
          "Price",
          `Rs. ${item?.productDetails?.productActualPrice}.00`,
          { margin: "0" },
        ],
        [
          "Delivery Amount",
          item?.publicationBooking?.deliveryAmount > 0
            ? `Rs. ${item?.publicationBooking?.deliveryAmount}.00`
            : "null",
          { margin: "0" },
        ],
        [
          "GST",
          item?.publicationBooking?.gst > 0
            ? `Rs. ${item?.publicationBooking?.gst}.00`
            : "null",
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${Number(
            item?.publicationBooking?.price +
              item?.publicationBooking?.deliveryAmount +
              item?.publicationBooking?.gst
          )}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(
            Number(
              item?.publicationBooking?.price +
                item?.publicationBooking?.deliveryAmount +
                item?.publicationBooking?.gst
            )
          )} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
    });
    setArray([...schemaBody]);
  };

  const addCart = (record, item) => {
    // cartArray = [...cart, item]; //multiple selection
    const filteredItem = record.subscriptions.find((e) => e.id === item.bookID);
    cartArray = [
      {
        id: record.id,
        name: record.name,
        bookName: filteredItem.name,
        bookID: item.bookID,
        quantity: item.quantity,
        month: filteredItem.month,
        year: filteredItem.year,
        gstAmount: filteredItem.gstAmount,
        deliveryPrice: filteredItem.deliveryPrice,
        language: filteredItem.language[0],
        price: filteredItem.price,
        type: filteredItem.type,
      },
    ]; //single selection
    setCart(cartArray);
    removeFromData(record);
  };

  const columns = [
    {
      title: "type",
      dataIndex: "type",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Language",
      dataIndex: "language",
    },
    {
      title: "Year - Month",
      dataIndex: "year",
      render: (text, record) => {
        return (
          <>
            {record?.year} - {record?.month}
          </>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      render: (text) => text,
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (text) => {
        return <>Rs. {Number(text)}.00</>;
      },
    },
    {
      title: "GST",
      dataIndex: "gstAmount",
      render: (text) => {
        return <>Rs. {Number(text) ?? 0}.00</>;
      },
    },
    {
      title: "Delivery Charges",
      render: (record) => {
        return (
          <>
            Rs.{" "}
            {Number(
              record.type === "subscription" ? record?.deliveryPrice : 0
            ) ?? 0}
            .00
          </>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "price",
      render: (text, record) => {
        return (
          <>
            Rs.{" "}
            {Number(
              (record?.price +
                record?.gstAmount +
                (record.type === "subscription" ? record?.deliveryPrice : 0)) *
                record?.quantity
            ) ?? 0}
            {/* {Number(record?.price + record?.gst + record?.deliveryPrice) ?? 0} */}
            .00
          </>
        );
      },
    },
    {
      title: "Action",
      render: (text, record, i) => {
        return (
          <Button
            onClick={() => {
              let a = [...cart];
              delete a[i];
              setCart(a?.filter((item) => item));
            }}
          >
            Remove
          </Button>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const fetchProducts = async () => {
    setLoading(true);
    let array = [];
    try {
      let resp = await TmsPublicationService.fetchValidProducts(type);
      array = resp?.map((item) => {
        return item.product;
      });
      setProducts(array);
      const filterData = {};
      array?.forEach((item) => {
        filterData[item.id] = {
          publicationID: item.id,
          bookID: "",
          quantity: "",
        };
      });
      setFilteredData({ ...filterData });
      let languages = getUniqueLanguages(array);
      setLanguageList(languages);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    setCart([]);
    fetchProducts();
  }, [type]);

  const sum = () => {
    let total = 0;
    cart?.map(
      (item) =>
        (total += Number(
          (item?.price +
            item?.gstAmount +
            (item.type === "subscription" ? item?.deliveryPrice : 0)) *
            item?.quantity
        ))
    );
    return total;
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (type === "SUBSCRIPTION") {
      if (
        phone === "" ||
        devoteeName === "" ||
        zip === "" ||
        address === "" ||
        city === "" ||
        state === "" ||
        country === ""
      ) {
        notification.error({ message: "Please fill all mandatory fields" });
        setLoading(false);
        return;
      }
    }

    let body = [];

    cart?.map((item) => {
      let bodyObject = {
        type: item.type,
        noOfItems: Number(item?.quantity),
        publicationId: item?.id,
        publicationSubscriptionTypeId: item?.bookID,
      };
      if (type === "SUBSCRIPTION") {
        bodyObject.publicationSubscriptionDetailsDTO = {
          name: devoteeName,
          contact: `+91${phone}`,
          pinCode: Number(zip),
          address: address,
          address2: "",
          city: city,
          state: state,
          country: country,
          language: item?.language,
        };
      }
      body.push(bodyObject);
    });

    try {
      const resp = await TmsPublicationService.publicationBooking(
        body,
        "CASH",
        "",
        ""
      );
      notification.success({ message: "Success" });
      ProductData(resp);
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const removeFromData = (item) => {
    console.log(item);
    let copy = { ...filteredData };
    copy[item.id] = {
      publicationID: item.id,
      bookID: "",
      quantity: "",
    };
    setFilteredData({ ...copy });
  };

  const getUniqueLanguages = (publications) => {
    const uniqueLanguagesSet = new Set(
      publications.flatMap((publication) =>
        (publication.subscriptions || []).flatMap(
          (subscription) => subscription.language || []
        )
      )
    );

    return Array.from(uniqueLanguagesSet);
  };

  const columns2 = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Book Name",
      render: (_, record) => (
        <Select
          className="w-100"
          style={{ minWidth: "350px" }}
          placeholder="Select Year"
          value={filteredData[record.id].bookID ?? ""}
          placehHolder="Select a book"
          onChange={(value) => {
            let copy = { ...filteredData };
            copy[record.id].bookID = value;
            setFilteredData({ ...copy });
          }}
          defaultValue={filteredData[record.id].bookID ?? ""}
          filterOption={true}
        >
          {record.subscriptions
            .filter((pub) => pub.language.includes(selectedLanguage))
            .map((item) => {
              return (
                <Select.Option key={item.id} value={item.id} label={item.name}>
                  Book: {item.name} | Year:
                  {item.year} | Month: {months[item.month - 1]}
                </Select.Option>
              );
            })}
        </Select>
      ),
    },
    {
      title: "Quantity",
      width: 100,
      render: (_, record) => (
        <Input
          maxLength={2}
          value={filteredData[record.id].quantity ?? ""}
          onKeyDown={(e) =>
            ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
          }
          onChange={(e) => {
            if (!/^\d*$/.test(e.target.value)) {
              return;
            }
            let copy = { ...filteredData };
            copy[record.id].quantity = e.target.value;
            setFilteredData({ ...copy });
          }}
        ></Input>
      ),
    },
    {
      title: "",
      render: (_, record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              addCart(record, filteredData[record.id]);
            }}
          >
            Add to Cart
          </Button>
        );
      },
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
          textAlign: "left",
        }}
      >
        <Row gutter={[20, 20]}>
          <Col style={{ width: "25%", justifyContent: "start" }}>
            <label style={labelStyles}>
              Booking Date
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />
            <DatePicker
              disabled
              value={startDate}
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              disabledDate={(d) =>
                !d || d.isBefore(TmsStringUtils.addDays(new Date(), -1))
              }
              onChange={(date) => {
                // getSlots(selectedSevaId, moment(date).format("YYYY-MM-DD"));
                setStartDate(date);
              }}
            />
          </Col>
          <Col style={{ width: "25%", justifyContent: "start" }}>
            <label style={labelStyles}>Publication Type</label>
            <br />
            <Select
              style={{ width: "100%" }}
              onChange={(e) => {
                setType(e);
              }}
              value={type}
              defaultValue={type}
            >
              <Select.Option value="SUBSCRIPTION">Subscription</Select.Option>
              <Select.Option value="PURCHASE">Purchase</Select.Option>
            </Select>
          </Col>

          <TmsSelect
            type="col"
            span={6}
            label="Language"
            setValue={(item) => {
              console.log(item);
              setSelectedLanguage(item);
              // getYearsAndMonths(item);
            }}
            labelClass="select-heading"
            value={selectedLanguage}
            data={languageList?.map((item) => {
              return { id: item, name: item };
            })}
            optional={true}
          />
        </Row>
        <br />
        <div style={{ maxHeight: "55vh" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "400",
              paddingBottom: "20px",
              margin: "0",
            }}
          >
            Publications
          </p>
          {selectedLanguage ? (
            <div>
              <TmsTable dataSource={products} columns={columns2} />
            </div>
          ) : (
            <p style={{ color: "red" }}>Select a language</p>
          )}
        </div>
        <br />
        {type === "SUBSCRIPTION" && (
          <Row>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "400",
                paddingBottom: "20px",
                margin: "0",
              }}
            >
              Devotee Details
            </p>

            <Row
              style={{ width: "100%", paddingBottom: "20px" }}
              gutter={[20, 20]}
            >
              <Col style={{ width: "50%" }}>
                <label style={labelStyles}>
                  Devotee Name{" "}
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />{" "}
                <Input
                  onChange={(e) => {
                    if (
                      /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                      e.target.value === ""
                    ) {
                      setDevoteeName(e.target.value);
                    }
                  }}
                  type="text"
                  placeholder="Enter Name"
                  maxLength="30"
                  value={devoteeName}
                />
              </Col>
              <Col style={{ width: "50%" }}>
                <label style={labelStyles}>
                  Mobile Number{" "}
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />{" "}
                <Input
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onChange={(e) => {
                    if (e.target.value.length > 10) {
                      return;
                    }
                    setPhone(e.target.value);
                  }}
                  type="number"
                  min="0"
                  prefix="+91"
                  onWheel={(event) => event.currentTarget.blur()}
                  maxLength={10}
                  value={phone}
                />
              </Col>
            </Row>

            <Row
              style={{ width: "100%", paddingBottom: "20px" }}
              gutter={[20, 20]}
            >
              <Col style={{ width: "50%" }}>
                <label style={labelStyles}>
                  Address{" "}
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />{" "}
                <Input
                  type="text"
                  placeholder="Enter Address"
                  maxLength="50"
                  value={address}
                  onKeyDown={(e) =>
                    ["#", "+"].includes(e.key) && e.preventDefault()
                  }
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </Col>
              <Col style={{ width: "50%" }}>
                <label style={labelStyles}>
                  City{" "}
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />{" "}
                <Input
                  type="text"
                  value={city}
                  placeholder="Enter City"
                  maxLength="30"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  onKeyDown={(e) =>
                    ["-", "+", "$", "^", "%"].includes(e.key) &&
                    e.preventDefault()
                  }
                />
              </Col>
            </Row>

            <Row
              style={{ width: "100%", paddingBottom: "20px" }}
              gutter={[20, 20]}
            >
              <Col style={{ width: "50%" }}>
                <label style={labelStyles}>
                  State
                  <span style={{ color: "red", marginLeft: "3px" }}>
                    *
                  </span>{" "}
                </label>
                <br />
                <RegionDropdown
                  className="CountryRegionDropDown"
                  country={country}
                  value={state}
                  showDefaultOption={true}
                  blankOptionLabel="Select State"
                  onChange={(val) => {
                    setState(val);
                  }}
                  placeholder="Select State"
                />
              </Col>
              <Col style={{ width: "50%" }}>
                <label style={labelStyles}>
                  Country{" "}
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />{" "}
                <CountryDropdown
                  className="CountryRegionDropDown"
                  value={country}
                  onChange={(val) => {
                    setCountry(val);
                  }}
                />
              </Col>
            </Row>

            <Row
              style={{ width: "100%", paddingBottom: "20px" }}
              gutter={[20, 20]}
            >
              <Col style={{ width: "50%" }}>
                <label style={labelStyles}>
                  Pincode
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />{" "}
                <Input
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Enter Pincode"
                  value={zip}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      return;
                    }
                    setZip(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </Row>
        )}

        <br />
        <Row style={{ width: "100%" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "400",
              paddingBottom: "20px",
              margin: "0",
            }}
          >
            Cart
          </p>
          <TmsTable columns={columns} dataSource={cart} pagination={false} />
        </Row>
      </div>
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          padding: "10px 50px",
          background: "#FFFCDC",
        }}
      >
        <Row
          style={{
            height: "50px",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "500",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              textAlign: "left",
              margin: "0",
            }}
          >
            Total Amount :
          </p>
          <p
            style={{
              fontSize: "24px",
              margin: "0",
              textAlign: "right",
            }}
          >
            Rs. {sum() ?? 0}.00
          </p>
        </Row>
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              type="primary"
              onClick={() => {
                handleSubmit();
              }}
              loading={loading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="product"
          from="Counter"
          setData={setArray}
          data={array}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setLoading}
          setPrintTicketModal={setIsModalVisible}
          printType="a4"
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosPublication);
