import Axios from "../../utils/tms-axios/tms-axios";

const TmsAuthService = {
  login: async (phone, password) => {
    phone = "+91" + phone;
    const resp = await Axios.post(`v1/user/login`, {
      phone: phone,
      password: password,
    });
    return resp.data;
  },

  checkToken: async () => {
    const resp = await Axios.get(`v1/user/check-token`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  logout: async () => {
    const resp = await Axios.post(
      "v1/user/logout",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  sendOtp: async (phone, captcha) => {
    const resp = await Axios.post(
      "v1/sms/send-otp",
      {
        phone: `+91${phone}`,
        type: "forgotPassword",
        captchaHumanKey: captcha,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
          "X-Temple-Id": 1,
        },
      }
    );
    return resp.data;
  },
};

export default TmsAuthService;
