import { Button, Card, Checkbox, Col, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsMiscService from "../../../../../services/tms-misc.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsImageUpload from "../../../../../components/tms-customs/tms-image-upload";

const TmsMiscInnerPagesAND = ({
  viewMiscData,
  editFlag,
  productId,
  setEditFlag,
  settmsModalVisible,
  router,
}) => {
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setdescription] = useState("");
  const [counterEnabled, setcounterEnabled] = useState(false);
  const [onlineEnabled, setonlineEnabled] = useState(false);
  const [price, setprice] = useState();
  const [fileList, setFileList] = useState([]);
  // const [duration, setDuration] = useState("");
  // const [durationFormat, setDurationFormat] = useState("min");
  const [changeRequestFile, setChangeRequestFile] = useState([]);
  const [changeRequestDesc, setChangeRequestDesc] = useState("");

  const fetchData = async () => {
    setLoading(true);
    if (
      name === "" ||
      description === "" ||
      counterEnabled === "" ||
      onlineEnabled === "" ||
      fileList?.length === 0 ||
      price === ""
      // duration === ""
    ) {
      notification.error({ message: "Please enter all the details" });
      setLoading(false);
      return;
    }
    if (editFlag) {
      if (changeRequestFile?.length === 0 || changeRequestDesc === "") {
        notification.error({ message: "Fill all the fields" });
        setLoading(false);

        return;
      }
    }
    try {
      let body = {
        name: name,
        description: description,
        counterEnabled: counterEnabled,
        onlineEnabled: onlineEnabled,
        price: price,
        imageUrl: fileList[0]?.url,
        // duration:
        //   durationFormat === "min"
        //     ? Number(duration)
        //     : durationFormat === "hour"
        //     ? Number(duration * 60)
        //     : durationFormat === "day" && Number(duration * 1440),
      };
      if (editFlag) {
        body.changeRequestPdfUrl = changeRequestFile[0].url;
        body.changeDescription = changeRequestDesc;
      }
      if (editFlag) {
        await TmsMiscService.editMisc(productId, body);
        // setDarshanamData(darData);
        notification.success({
          message: "Miscellaneous Product Edited Successful",
        });
        setEditFlag(false);
      } else {
        await TmsMiscService.createMisc(body);
        // setDarshanamData(data?.items);
        notification.success({
          message: "Miscellaneous Product Created Successful",
        });
        handleFuction();
      }
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    setName("");
    setdescription("");
    setcounterEnabled(false);
    setonlineEnabled(false);
    setprice("");
    setFileList([]);
    // setDuration("");
    setChangeRequestFile([]);
    setChangeRequestDesc("");
  };

  useEffect(() => {
    if (editFlag) {
      setName(viewMiscData?.name);
      setdescription(viewMiscData?.description);
      setcounterEnabled(viewMiscData?.counterEnabled);
      setonlineEnabled(viewMiscData?.onlineEnabled);
      setprice(viewMiscData?.price);
      setFileList([{ url: viewMiscData?.imageUrl, status: "done" }]);
      // if (viewMiscData?.duration % 1440 === 0) {
      //   setDuration(viewMiscData?.duration / 1440);
      //   setDurationFormat("day");
      // } else if (viewMiscData?.duration % 60 === 0) {
      //   setDuration(viewMiscData?.duration / 60);
      //   setDurationFormat("hour");
      // } else {
      //   setDuration(viewMiscData?.duration);
      //   setDurationFormat("min");
      // }
    }
  }, [viewMiscData]);

  return (
    <Row className="formRow" style={{ width: "100%" }}>
      <Card style={{ width: "100%" }}>
        <Row className="formRow">
          <label className="formLabel">Product Type</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Row>
        {/* <Row style={{ marginBottom: "10px" }}>
          <label className="formLabel">Duration Format</label>

          <Select
            className="formInput"
            onChange={(e) => {
              setDurationFormat(e);
            }}
            value={durationFormat}
            defaultValue={durationFormat}
          >
            <Select.Option value="min">Minutes</Select.Option>
            <Select.Option value="hour">Hours</Select.Option>
            <Select.Option value="day">Days</Select.Option>
          </Select>
        </Row>
        <Row className="formRow">
          <label className="formLabel">Duration</label>
          <InputNumber
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            defaultValue={duration}
            min={0}
            className="formInput"
            type="text"
            value={duration}
            placeholder="Enter Duration of pass"
            onChange={(e) => {
              setDuration(e);
            }}
          />
        </Row> */}
        <Row className="formRow">
          <label className="formLabel">Description</label>
          <TextArea
            maxLength={2000}
            className="formInput"
            rows={4}
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        </Row>
        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Price</label>
          <Col className="formCols-2">
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={price}
              onChange={(e) => setprice(Number(e.target.value))}
            />
          </Col>
          <Col className="formCols-2 contantCenter">
            <label style={{ paddingRight: "10px" }}>Enable POS</label>
            <Checkbox
              style={{ paddingRight: "10px" }}
              checked={counterEnabled}
              onChange={(e) => setcounterEnabled(e.target.checked)}
            />
            <label style={{ paddingRight: "10px" }}>Enable Online</label>
            <Checkbox
              checked={onlineEnabled}
              onChange={(e) => setonlineEnabled(e.target.checked)}
            />
          </Col>
        </Row>

        <Row className="formRow">
          <label className="formLabel">Image</label>
          <TmsImageUpload
            fileList={fileList}
            setFileList={setFileList}
            router={router}
          />
        </Row>
        {editFlag && (
          <>
            <Row gutter={[20, 20]} className="formRow">
              <label className="formLabel">Request Copy</label>
              <Col className="formInput ">
                <TmsImageUpload
                  fileList={changeRequestFile}
                  setFileList={setChangeRequestFile}
                  router={router}
                  type="private"
                  accept={true}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="formRow">
              <label className="formLabel">Edit Description</label>
              <Input
                maxLength={220}
                className="formInput"
                value={changeRequestDesc}
                onChange={(e) => setChangeRequestDesc(e.target.value)}
              />
            </Row>
          </>
        )}
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                fetchData();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsMiscInnerPagesAND);
