import { getParamByParam } from "iso-country-currency";

export const FormatNumberINRCurrency = (number) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);
};
export const FormatNumberINRCurrencyDecimalSeperator = (number) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(number);
};
export const FormatNumberAllCurrency = (number, country) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: getParamByParam("countryName", country, "currency"),
  }).format(number);
};
