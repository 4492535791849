import React, { useState, useEffect, useRef } from "react";
import {
  notification,
  Row,
  Col,
  Table,
  Space,
  Button,
  InputNumber,
  Input,
  Statistic,
  Select,
} from "antd";
import moment from "moment";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsShiftService from "../../../../services/tms-shift.service";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsSRShiftDetailsCard from "../../../../components/tms-reports/tms-shift-reports/tms-sr-view-transactions-card";
import TmsModal from "../../../../components/tms-customs/tms-modal";
import { useReactToPrint } from "react-to-print";
import "../../../../assets/css/tms-reports/tms-reports.scss";
import TmsCartService from "../../../../services/tms-cart.service";
import TmsPrintTicket from "../../../../components/tms-print-ticket/tms-print-ticket";
import postBookingDataFunction from "../../../../utils/tms-post-booking-data/tms-post-booking-data";
import { useContext } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { BaseContext } from "../../../../contexts/BaseContext";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import getColumnSearchProps from "../../../../utils/tms-table-search/tms-search";
import TmsDatepickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsCounterService from "../../../../services/tms-counter.service";

const TmsSRShiftTransactions = ({ router }) => {
  const { Countdown } = Statistic;
  const { userDetails } = useContext(BaseContext);
  const refContainer = useRef(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [userShiftData, setUserShiftData] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [modal, setModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState();
  const [reprintData, setReprintData] = useState();
  const [printLoading, setPrintLoading] = useState(false);
  const [ShiftID, setShiftID] = useState("");
  const [shiftIDData, setShiftIDData] = useState([]);
  const [featchloading, setFetchLoading] = useState(false);
  const [shiftData, setShiftData] = useState("");
  const [counterCartItemId, setCounterCartItemId] = useState();
  const [countDown, setCountDown] = useState();
  const [excelLoading, setExcelLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [counterId, setCounterId] = useState("");
  const [counters, setCounters] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [shifts, setShifts] = useState([]);
  const id = new URLSearchParams(router?.location?.search).get("shiftID");

  const getTableData = async () => {
    setDataLoading(true);
    try {
      const respData = await TmsShiftService.allShiftReports(
        Number(selectedShift ?? 0),
        Number(counterId ?? 0),
        fromDate,
        toDate,
        1,
        50000
      );
      setShiftIDData(respData.items);
      setDataLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! login again.", router);
      setDataLoading(false);
    }
  };

  const getShiftCounters = async () => {
    setDataLoading(true);
    try {
      const respDataCounters = await TmsCounterService.getAllCounter();
      setCounters(respDataCounters.items);
      const respDataShifts = await TmsShiftService.getAllShifts();
      setShifts(respDataShifts.items);
      setDataLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! login again.", router);
      setDataLoading(false);
    }
  };

  const dataFormat = (data) => {
    let res = [];
    data.items?.map((item) => {
      res = [
        ...res,
        {
          productName: item?.productName,
          categoryTransactionSequence: item?.categoryTransactionSequence
            ?.split("-")
            ?.pop(),
          service: item?.service
            ?.replaceAll("_", " ")
            ?.replaceAll("counter", ""),
          price: Number(item?.price),
          paymentType: item?.paymentType,
          paymentId: item?.paymentId,
          createdAt: item?.createdAt
            ? moment(item?.createdAt).format("DD-MM-YYYY hh:mm A")
            : "-",
          counterCartItemId: item?.counterCartItemId,
          deposit: Number(item?.deposit),
          gst: Number(item?.gst),
          delivery: Number(item?.deliveryAmount),
          totalAmount:
            Number(item?.gst ?? 0) +
            Number(item?.deposit ?? 0) +
            Number(item?.price ?? 0) +
            Number(item?.deliveryAmount ?? 0),
          // item?.cartAmount,
        },
      ];
    });
    return res;
  };

  const fetchAllTransactions = async (TranId) => {
    if (ShiftID === "") {
      if (!TranId) {
        notification.error({ message: "Please enter a shift ID" });
        return;
      }
    }
    setDataLoading(true);
    try {
      setReportData([]);
      setShiftData("");
      const data = await TmsShiftService.allShiftTransactions(
        id ? Number(TranId) : Number(ShiftID),
        tableCurrentPage,
        tablePageSize
      );
      setUserShiftData(data?.meta?.shiftLoginLog);

      setReportData(dataFormat(data));
      setShiftData(data.shiftLog);
      setTabelTotalSize(data?.meta?.totalItems);
      setDataLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token Expired!! Please login.", router);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setShiftID(Number(id));
      fetchAllTransactions(id);
    } else {
      getShiftCounters();
      getTableData();
      if (ShiftID !== "") {
        fetchAllTransactions();
      }
    }
  }, [tableCurrentPage, tablePageSize]);

  useEffect(() => {
    if (!id) {
      getTableData();
      setShiftID("");
      setReportData([]);
      setShiftData("");
    }
  }, [fromDate, toDate, counterId, selectedShift]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const fetchOtp = async (id) => {
    setFetchLoading(true);
    try {
      if (userDetails?.permissions?.includes("SUPER_ADMIN")) {
        setFetchLoading(false);
        setOtpModal(true);
        setCountDown(Date?.now() + 60 * 2000);
      } else {
        await TmsCartService.sendreprintOTP(id);
        setFetchLoading(false);
        setOtpModal(true);
        setCountDown(Date?.now() + 60 * 2000);
        notification.success({ message: `OTP Sent` });
      }
    } catch (error) {
      catchErrorFunction(error, "Token expired! Please login again", router);
      setFetchLoading(false);
    }
  };

  const reprint = async () => {
    setFetchLoading(true);
    try {
      let resp = await TmsCartService.reprint(counterCartItemId, Number(otp));
      setCountDown(0);
      setOtpModal(false);
      setIsModalVisible(true);
      setReprintData(postBookingDataFunction(resp));
    } catch (error) {
      catchErrorFunction(error, "Token expired! Please login again", router);
      setFetchLoading(false);
    }
  };

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Service Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Txn No.",
      dataIndex: "categoryTransactionSequence",
      key: "categoryTransactionSequence",
      ...getColumnSearchProps("categoryTransactionSequence"),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Payment type",
      dataIndex: "paymentType",
      key: "paymentType",
    },

    {
      title: "Payment ID",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdDate > b.createdDate,
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Deposit Amount",
      dataIndex: "deposit",
      key: "deposit",
    },
    {
      title: "GST Amount",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Delivery Amount",
      dataIndex: "delivery",
      key: "delivery",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },

    {
      title: "Action",
      render: (record) => {
        if (
          userDetails?.permissions.includes("SUPER_ADMIN") ||
          userDetails?.permissions.includes("ADMIN")
        ) {
          return (
            <Space>
              <Button
                loading={featchloading}
                onClick={() => {
                  fetchOtp(record.counterCartItemId);
                  setCounterCartItemId(record.counterCartItemId);
                }}
              >
                Reprint
              </Button>
            </Space>
          );
        } else {
          return "-";
        }
      },
    },
  ];
  const printReport = async () => {
    setPrintLoading(true);
    try {
      const data = await TmsShiftService.allShiftTransactions(
        Number(ShiftID),
        1,
        50000
      );
      setModalData(data?.items);
      setModal(true);
      setPrintLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setModalData(false);
      setPrintLoading(false);
      setModal(false);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "tms-report-container",
    onAfterPrint: () => {
      setModal(false);
      setModalData([]);
    },
  });

  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const data = await TmsShiftService.allShiftTransactions(
        Number(ShiftID),
        1,
        50000
      );
      if (data?.items?.length === 0) {
        notification.info({ message: "No records found to Export" });
        setExcelLoading(false);
        return;
      }
      console.log(dataFormat(data));
      const compiledData = dataFormat(data)?.map((item, i) => {
        return {
          "S.no": i + 1,
          "Service Name": item?.productName,
          "Txn No.": item?.categoryTransactionSequence,
          Service: item?.service,
          "Payment type": item?.paymentType,
          "Payment ID": item?.paymentId,
          "Created At": item?.createdAt,
          "Service Amount": item?.price,
          "Deposit Amount": item?.deposit,
          "GST Amount": item?.gst,
          "Delivery Amount": item?.delivery,
          "Total Amount": item?.totalAmount,
        };
      });

      exportExcel(
        compiledData,
        `shift-transaction-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  return (
    <>
      <Row style={{ marginBottom: "20px", display: "flex" }} gutter={[10, 10]}>
        {new URLSearchParams(router?.location?.search).get("shiftID") ? (
          <Col>
            <Row
              justify="start"
              style={{
                marginBottom: "5px",
              }}
            >
              {" "}
              Shift Login Log ID
            </Row>
            <Row justify="start" style={{ flexDirection: "row" }}>
              <InputNumber
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                style={{ width: "300px", maxWidth: "300px" }}
                value={ShiftID}
                defaultValue={ShiftID}
                placeholder="Please Enter a Shift ID"
                onChange={(e) => {
                  setShiftID(e);
                }}
                maxLength={220}
              />
            </Row>
          </Col>
        ) : (
          <>
            <Col>
              <TmsDatepickerRange
                label="Select Duration"
                to={toDate}
                from={fromDate}
                setFrom={setFromDate}
                setTo={setToDate}
              />
            </Col>
            <TmsSelect
              selectClass="w-100"
              type="col"
              value={counterId}
              setValue={setCounterId}
              label="POS"
              data={counters}
              span={4}
            />
            <TmsSelect
              selectClass="w-100"
              type="col"
              value={selectedShift}
              setValue={setSelectedShift}
              label="Shift"
              data={shifts}
              span={4}
            />
            <Col span={4}>
              <Row
                style={{
                  marginBottom: "5px",
                }}
              >
                <label
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  Shift Login Log ID
                </label>
              </Row>
              <Select
                style={{ width: "100%", textAlign: "left" }}
                placeholder="Shift ID"
                value={ShiftID}
                onChange={(e) => {
                  setShiftID(e);
                }}
              >
                {shiftIDData?.map((item, i) => {
                  return (
                    <Select.Option key={i} value={item?.id}>
                      {`${item?.user?.displayName} (${item?.id})`}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
          </>
        )}
        <Col style={{ display: "flex", alignItems: "end" }}>
          <Button
            type="primary"
            style={{ maxWidth: "100px" }}
            onClick={() => fetchAllTransactions()}
          >
            Fetch
          </Button>
        </Col>
        {reportData === "" || reportData.length === 0 || !reportData ? (
          ""
        ) : (
          <>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <Button
                type="primary"
                onClick={() => printReport()}
                loading={printLoading}
              >
                Print Report
              </Button>
            </Col>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <Button
                style={{ maxWidth: "100px" }}
                loading={excelLoading}
                type="primary"
                onClick={handleExcelExport}
              >
                <DownloadOutlined /> Excel
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Shift Transactions</span>
        </Space>
      </Row>
      <Table
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={reportData}
        loading={dataLoading}
        size="small"
        scroll={{ x: true }}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
      {shiftData !== "" && reportData !== "" && (
        <TmsSRShiftDetailsCard shiftData={userShiftData} />
      )}
      <br />
      <TmsModal
        visible={modal}
        width={700}
        closable={false}
        centered
        onCancel={() => {
          setModal(false);
          setPrintLoading(false);
        }}
      >
        <div className="tms-modal-div">
          <Button
            type="primary"
            className="print-button"
            onClick={handlePrint}
            loading={printLoading}
          >
            Print
          </Button>
        </div>
        <br />
        <div className="tms-report-container">
          <table
            className="tms-report-table"
            ref={refContainer}
            style={{ fontSize: "14px" }}
          >
            <thead>
              <th>Service name</th>
              <th>Transaction No</th>
              <th>Payment ID</th>
              <th>Payment Type</th>
              <th>Deposit Amount</th>
              <th>Gst Amount</th>
              <th>Price</th>
              <th>Created at</th>
            </thead>
            <tbody>
              {modalData?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="al">{item?.productName}</td>
                    <td>
                      {item?.categoryTransactionSequence?.split("-")?.pop()}
                    </td>
                    <td>{item?.paymentId}</td>
                    <td>{item?.paymentType}</td>
                    <td>{item?.deposit}</td>
                    <td>{item?.gst}</td>

                    <td>{item?.price}</td>
                    <td>
                      {moment(new Date(item?.createdAt)).format(
                        "DD-MM-YYYY - hh:mm A"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </TmsModal>
      <TmsModal
        visible={otpModal}
        width={400}
        closable={true}
        centered
        onCancel={() => {
          setOtpModal(false);
          setCountDown(0);
        }}
        footer={
          <>
            <Button
              disabled={countDown > 0 ? true : false}
              onClick={() => {
                fetchOtp(counterCartItemId);
              }}
            >
              Resend OTP
            </Button>
            <Button loading={featchloading} type="primary" onClick={reprint}>
              Submit
            </Button>
          </>
        }
      >
        <div>
          <p>Enter OTP</p>
          <Input
            maxLength={6}
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
          ></Input>
          <br />
          <Countdown
            className="countDownOTP"
            value={countDown}
            format={"mm:ss"}
            style={{
              paddingTop: "10px",
              display: `${countDown > 0 ? "block" : "none"}`,
            }}
            suffix={"to resend OTP"}
            onFinish={() => {
              setCountDown(0);
            }}
          />
        </div>
      </TmsModal>
      <TmsModal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type={reprintData?.type}
          from="Counter"
          data={reprintData?.array}
          setData={setReprintData}
          externalPrint={reprintData?.externalPrint}
          setExternalPrint={setReprintData}
          setButtonLoading={setFetchLoading}
          setPrintTicketModal={setIsModalVisible}
          ticketId={reprintData?.ticketID}
          duplicate={true}
        />
      </TmsModal>
    </>
  );
};

export default withRouter(TmsSRShiftTransactions);
