import { Button, Col, Input, Row, Table } from "antd";
import { getParamByParam } from "iso-country-currency";
import React from "react";
import { CountryDropdown } from "react-country-region-selector";
import TmsImageUpload from "../../tms-customs/tms-image-upload";
import { InboxOutlined } from "@ant-design/icons";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";

const TmsSmParakamaniHundi = ({
  hundiCurrencyArray,
  SetHundiCurrencyArray,
  hundiPicList,
  setHundiPicList,
}) => {
  const HandleBills = (countryId, type, key, index, value, from) => {
    console.log(countryId, type, key, index, value, from);
    let temp = [...hundiCurrencyArray];
    let item = temp.find((country) => country.id === countryId);
    item[from].find((currency) => currency.type === type).bills[index][key] =
      value;
    SetHundiCurrencyArray([...temp]);
  };

  const addRemoveBills = (countryId, type, from, flag) => {
    let temp = [...hundiCurrencyArray].map((topItem) => {
      if (topItem.id === countryId) {
        topItem[from].map((item) => {
          if (item.type === type) {
            if (flag === "add") {
              item.bills = [...item.bills, { denomination: 0, quantity: 0 }];
            } else {
              item?.bills?.splice(-1, 1);
            }
          }
          return item;
        });
      }
      return topItem;
    });
    SetHundiCurrencyArray([...temp]);
  };

  const HandleCountry = (val, index) => {
    let temp = [...hundiCurrencyArray];
    temp[index].countryName = val;
    temp[index].currency = getParamByParam("countryName", val, "currency");
    temp[index].symbol = getParamByParam("countryName", val, "symbol");
    SetHundiCurrencyArray([...temp]);
  };

  const addCurrency = () => {
    let temp = [...hundiCurrencyArray];

    SetHundiCurrencyArray([
      ...temp,
      {
        id: Number(new Date().getTime()),
        countryName: "India",
        currency: getParamByParam("countryName", "India", "currency"),
        symbol: getParamByParam("countryName", "India", "symbol"),
        validBills: [
          {
            countryId: Number(new Date().getTime()),
            type: "notes",
            bills: [{ denomination: 0, quantity: 0 }],
          },
          {
            countryId: Number(new Date().getTime()),
            type: "coins",
            bills: [{ denomination: 0, quantity: 0 }],
          },
        ],
        invalidBills: [
          {
            countryId: Number(new Date().getTime()),
            type: "notes",
            bills: [{ denomination: 0, quantity: 0 }],
          },
          {
            countryId: Number(new Date().getTime()),
            type: "coins",
            bills: [{ denomination: 0, quantity: 0 }],
          },
        ],
      },
    ]);
  };

  const removeCurrency = (index) => {
    let temp = [...hundiCurrencyArray];
    temp?.splice(index, 1);
    SetHundiCurrencyArray([...temp]);
  };

  const TotalAmount = (record) => {
    let amount = 0;
    record?.map((item) =>
      item?.bills?.map((bill) => {
        amount = Number(
          Number(amount) + Number(bill?.denomination * bill?.quantity)
        );
      })
    );
    return amount;
  };

  const columnsFunc = (from) => {
    return [
      {
        title: "",
        render: (text, record) => {
          return (
            <Row style={{ flexDirection: "column" }}>
              <Button
                className="buttons"
                onClick={() => {
                  addRemoveBills(record?.countryId, record?.type, from, "add");
                }}
              >
                +
              </Button>
              <br />
              <Button
                className="buttons"
                onClick={() => {
                  addRemoveBills(
                    record?.countryId,
                    record?.type,
                    from,
                    "remove"
                  );
                }}
              >
                -
              </Button>
            </Row>
          );
        },
      },
      {
        title: "Currency Type",
        dataIndex: "type",
        key: "type",
        render: (text) => {
          return <p style={{ textTransform: "capitalize" }}>{text}</p>;
        },
      },
      {
        title: "Denomination",
        dataIndex: "denomination",
        key: "denomination",
        render: (text, record) => {
          return (
            <Row gutter={[10, 10]}>
              {record?.bills?.map((item, i) => {
                return (
                  <Col key={i}>
                    <Input
                      maxLength={220}
                      type="number"
                      prefix={
                        hundiCurrencyArray?.find(
                          (item) => item?.id === record?.countryId
                        )?.symbol
                      }
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          return;
                        }
                        if (e.target.value.length >= 5) {
                          return;
                        }
                        HandleBills(
                          record?.countryId,
                          record?.type,
                          "denomination",
                          i,
                          e.target.value,
                          from
                        );
                      }}
                      value={Number(item?.denomination)}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        },
      },
      {
        title: "Quantity",
        dataIndex: "qunatity",
        key: "qunatity",
        render: (text, record) => {
          return (
            <Row gutter={[10, 10]}>
              {record?.bills?.map((item, i) => {
                return (
                  <Col key={i}>
                    <Input
                      maxLength={220}
                      type="number"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          return;
                        }
                        HandleBills(
                          record?.countryId,
                          record?.type,
                          "quantity",
                          i,
                          e.target.value,
                          from
                        );
                      }}
                      value={Number(item?.quantity)}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        },
      },
      {
        title: "Amount",
        key: "denomination",
        render: (text, record) => {
          return (
            <Row gutter={[20, 20]}>
              {record?.bills?.map((item, i) => {
                return (
                  <Col key={i} span={24}>
                    {
                      hundiCurrencyArray?.find(
                        (item) => item?.id === record?.countryId
                      )?.symbol
                    }
                    {Number(item?.denomination * item?.quantity)}
                  </Col>
                );
              })}
            </Row>
          );
        },
      },
    ];
  };

  return (
    <div>
      <Row gutter={[20, 20]}>
        {hundiCurrencyArray?.map((item, i) => {
          return (
            <Col key={i} span={24}>
              <Row gutter={[20, 20]}>
                <Col span={4}>
                  <label>
                    Country <span className="mandatory">*</span>
                  </label>
                  <br />{" "}
                  <CountryDropdown
                    className="CountryRegionDropDown"
                    value={item?.countryName}
                    onChange={(val) => {
                      HandleCountry(val, i);
                    }}
                  />
                </Col>
                <Col span={3}>
                  <label>Currency </label>
                  <br />{" "}
                  <Input
                    disabled
                    value={`${item?.currency}-(${item?.symbol})`}
                  />
                </Col>
                <Col>
                  <label></label>
                  <br />{" "}
                  <Button
                    type="primary"
                    onClick={() => {
                      i + 1 === hundiCurrencyArray?.length
                        ? addCurrency()
                        : removeCurrency(i);
                    }}
                  >
                    {i + 1 === hundiCurrencyArray?.length ? "Add" : "Remove"}
                  </Button>
                </Col>
              </Row>
              <br />
              <Row gutter={[20, 20]}>
                <Col span={12}>
                  <label
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Valid Currency
                  </label>
                  <Table
                    bordered={true}
                    columns={columnsFunc("validBills")}
                    dataSource={item?.validBills}
                    pagination={false}
                  />
                  <Row className="totalAmount">
                    <label>Total : </label>
                    <span>
                      {item?.symbol}
                      {TotalAmount(item?.validBills)}
                    </span>
                  </Row>
                </Col>
                <Col span={12}>
                  <label
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Invalid Currency
                  </label>
                  <Table
                    bordered={true}
                    columns={columnsFunc("invalidBills")}
                    dataSource={item?.invalidBills}
                    pagination={false}
                  />
                  <Row className="totalAmount">
                    <label>Total : </label>
                    <span>
                      {item?.symbol}
                      {TotalAmount(item?.invalidBills)}
                    </span>
                  </Row>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
      <br />
      <Row className="uploadBox">
        <TmsImageUpload
          dragger={true}
          // flag="private"
          className="w-100 pictureCard"
          listType="text"
          accept={false}
          fileList={hundiPicList}
          setFileList={setHundiPicList}
        />
        <Row
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            visibility: hundiPicList?.length < 4 ? "visible" : "hidden",
            position: "absolute",
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Hundi Counting Images</p>
        </Row>
      </Row>
    </div>
  );
};

export default withRouter(TmsSmParakamaniHundi);
