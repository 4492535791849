import { Col, Input, Row } from "antd";
import React, { memo } from "react";

const TmsLandAcreCentsInput = ({
  colspace,
  valueOne,
  setValueOne,
  valueTwo,
  setValueTwo,
  label,
  disabled,
  maxValueOne,
  maxValueTwo,
}) => {
  return (
    <Col span={colspace}>
      <Row className={"t-left"}>{label}</Row>
      <Row>
        {" "}
        <Col span={12}>
          <Input
            maxLength={220}
            value={valueOne ?? 0}
            type="number"
            min="0"
            step="1"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) => {
              if (disabled) {
                return;
              }
              if (maxValueOne === 0) {
                setValueOne(0);
              }
              if (maxValueOne !== "" && maxValueOne !== undefined) {
                maxValueOne >= e.target.value &&
                  setValueOne(Number(e.target.value));
              } else {
                setValueOne(Number(e.target.value));
              }
            }}
            suffix={<span className="suffix-border">Acres</span>}
            disabled={disabled}
          />
        </Col>
        <Col span={12}>
          <Input
            maxLength={220}
            value={valueTwo ?? 0}
            type="number"
            min="0"
            step="1"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) => {
              if (disabled) {
                return;
              }
              if (maxValueTwo) {
                maxValueTwo >= e.target.value &&
                  setValueTwo(Number(e.target.value));
              } else {
                e.target.value <= 99 && setValueTwo(Number(e.target.value));
              }
            }}
            suffix={<span className="suffix-border">Cents</span>}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default memo(TmsLandAcreCentsInput);
