import { Button, Row } from "antd";
import React, { useState } from "react";
import TmsLandCreation from "../creation-inner-pages/tms-land-creation";
import TmsCreatedLandList from "../created-list-inner-pages/tms-sm-ral-created-land-list";
import TmsModal from "../../../../../../components/tms-customs/tms-modal";
const LandCreationIndex = () => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <Row justify="start">
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
          }}
        >
          New Land Creation
        </Button>
      </Row>
      <br />
      {!visible && <TmsCreatedLandList />}
      <TmsModal
        centered={true}
        width="80%"
        closable={false}
        visible={visible}
        title={"Land Creation "}
        onClose={() => {
          setVisible(false);
        }}
      >
        {visible && <TmsLandCreation setVisible={setVisible} />}
      </TmsModal>
    </div>
  );
};

export default LandCreationIndex;
