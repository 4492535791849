import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportProductReport from "./tms-report-products-report";

const TmsReportProduct = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.report_products) {
      setActive("Product Report");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Product Report",
      Element: <TmsReportProductReport />,
      key: "Product Report",
      route: tmsRouteMap.report_products,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportProduct);
