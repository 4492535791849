import React, { useContext, useState } from "react";
import { Col, Row, Card, Tag, Divider, Button, Modal } from "antd";
import { FormatNumberINRCurrency } from "../../utils/tms-currency-formatter/tms-curreny-formatter";
import moment from "moment";
import "../../assets/css/tms-pos/tms-scan-ticket.scss";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsAccommodationService from "../../services/tms-accommodation.service";
import { useEffect } from "react";
import TmsPosOnlineRooms from "../tms-pos/tms-pos-accommodation/tms-pos-online-rooms";
import { BaseContext } from "../../contexts/BaseContext";

const TmsRenderDetailsRenderer = ({
  router,
  data,
  bookingUpdate,
  from,
  match,
  captured,
  type,
}) => {
  const [renew, setRenew] = useState();
  const { roomsModal, setRoomsModal } = useContext(BaseContext);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  const fetchRenewStats = async (roomId) => {
    try {
      const resp = await TmsAccommodationService.renewStats(roomId);
      setRenew(resp);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
  };

  useEffect(() => {
    if (
      data[0]?.productType === "counter_accommodation" ||
      data[0]?.online_accommodation?.roomNumber
    ) {
      fetchRenewStats(
        data[0]?.counter_accommodation?.roomBookingId ??
          data[0]?.online_accommodation?.roomBookingId
      );
    }
  }, []);

  const renderDarshanamTicket = () => {
    let datas = [
      {
        title: "Ticket Type ",
        value: "Darshanam",
      },
    ];
    data.forEach((item) => {
      datas = [
        ...datas,
        {
          title: "Darshanam Name",
          value:
            item?.counter_darshanam?.darshanamName ??
            item?.online_darshanam?.darshanamName ??
            item?.seva_name,
        },
        {
          title: "Booking date",
          value: moment(
            item?.counter_darshanam?.bookingDate ??
              item?.online_darshanam?.bookingDate ??
              item?.booking_date,
            "YYYY-MM-DD"
          ).format("DD-MM-YYYY"),
        },
        {
          title: "Slot Time",
          value: `${moment(
            item?.counter_darshanam?.slotStartTime ??
              item?.online_darshanam?.slotStartTime ??
              item?.slot_start_start,
            "HH:mm:ssZ"
          ).format("hh:mm A")}`,
        },
        {
          title: "No of Tickets",
          value:
            item?.counter_darshanam?.noOfTickets ??
            item?.online_darshanam?.noOfTickets ??
            item?.no_of_tickets,
        },
        {
          title: "Price",
          value: FormatNumberINRCurrency(
            item?.counter_darshanam?.price ??
              item?.online_darshanam?.price ??
              item?.amount_paid
          ),
        },
      ];
    });
    return datas.map((item, i) => {
      return (
        <Row style={{ marginBottom: "5px" }} key={"ticketdetails" + i}>
          <Col className="ticket_label">{item.title}:</Col>
          <Col className="ticket_data">{item?.value}</Col>
        </Row>
      );
    });
  };

  const renderSevaTicket = () => {
    let datas = [
      {
        title: "Ticket Type ",
        value: "Seva",
      },
    ];
    data.forEach((item) => {
      datas = [
        ...datas,
        {
          title: "Seva Name",
          value: `${
            item?.counter_seva?.sevaType ??
            item?.online_seva?.sevaType ??
            item?.type
          } - ${
            item?.counter_seva?.sevaName ??
            item?.online_seva?.sevaName ??
            item?.seva_name
          }`,
        },
        {
          title: "Booking date",
          value: moment(
            item?.counter_seva?.bookingDate ??
              item?.online_seva?.bookingDate ??
              item?.booking_date,
            "YYYY-MM-DD"
          ).format("DD-MM-YYYY"),
        },
        {
          title: "Slot Time",
          value:
            item?.counter_seva?.slotStartTime ||
            item?.online_seva?.slotStartTime
              ? `${moment(
                  item?.counter_seva?.slotStartTime ??
                    item?.online_seva?.slotStartTime,
                  "HH:mm:ssZ"
                ).format("hh:mm A")}`
              : item?.time_slot,
        },
        {
          title: "Devotee Name",
          value:
            item?.counter_seva?.devoteeName ??
            item?.online_seva?.personDetails[0]?.personName ??
            item?.devotee_names,
        },
        {
          title: "Price",
          value: FormatNumberINRCurrency(
            item?.counter_seva?.price ??
              item?.online_seva?.price ??
              item?.amount_paid
          ),
        },
        {
          title: "Address",
          value: item?.counter_seva?.address ?? "-",
        },
        {
          title: "Contact",
          value:
            item?.counter_seva?.contact ??
            item?.online_seva?.bookingPhoneNumber ??
            item?.mobile_no,
        },
        {
          title: "ID proof Number",
          value: item?.counter_seva?.idProofNumber
            ? `${item?.counter_seva?.idProofType}-${item?.counter_seva?.idProofNumber}`
            : item?.online_seva?.personDetails[0]?.documentId
            ? `${item?.online_seva?.personDetails[0]?.documentType}-${item?.online_seva?.personDetails[0]?.documentId}`
            : item?.primary_id_proof_number
            ? `${item?.primary_id_proof_type} - ${item?.primary_id_proof_number}`
            : "-",
        },
        {
          title: "Gothram",
          value:
            item?.counter_seva?.gothram ??
            item?.online_seva?.gothram ??
            item?.gothram ??
            "-",
        },
      ];
    });
    // data?.counter_seva?.gothram &&
    //   datas.push();
    return datas.map((item, i) => {
      return (
        <Row style={{ marginBottom: "5px" }} key={"ticketdetails" + i}>
          <Col className="ticket_label">{item.title}:</Col>
          <Col className="ticket_data">{item?.value}</Col>
        </Row>
      );
    });
  };

  const renderDonationTicket = () => {
    let datas = [
      {
        title: "Ticket Type ",
        value: "Donation",
      },
    ];
    data.forEach((item) => {
      datas = [
        ...datas,
        {
          title: "Donation Name",
          value: item?.counter_donation?.donationName,
        },
        {
          title: "Donation Amount",
          value: FormatNumberINRCurrency(item?.counter_donation?.amount),
        },
        {
          title: "Booking date",
          value: moment(item?.counter_donation?.createdAt).format("DD-MM-YYYY"),
        },

        {
          title: "In the Name of",
          value: item?.counter_donation?.inTheNameOf ?? "-",
        },
        {
          title: "Calender Type",
          value:
            item?.counter_donation?.calendarType === "english"
              ? `${item?.counter_donation?.calendarType} - Day/Month: ${moment(
                  item?.counter_donation?.bookingDate
                ).format("DD")}/${moment(
                  item?.counter_donation?.bookingDate
                ).format("MM")}`
              : `${item?.counter_donation?.calendarType} - ${item?.counter_donation?.tithi} -${item?.counter_donation?.masam} - ${item?.counter_donation?.paksham}`,
        },
        {
          title: "Address",
          value: item?.counter_donation?.address
            ? `${item?.counter_donation?.address} ${
                item?.counter_donation?.address2 ?? ""
              }
            ${item?.counter_donation?.city ?? ""}
            ${item?.counter_donation?.state ?? ""}
            ${item?.counter_donation?.country ?? ""}
            
            `
            : "-",
        },
        { title: "Contact", value: item?.counter_donation?.contact ?? "-" },

        {
          title: "ID proof",
          value: item?.counter_donation?.panCardId
            ? `PAN-${item?.counter_donation?.panCardId}`
            : item?.counter_donation?.documentId
            ? item?.counter_donation?.documentId
            : "-",
        },

        { title: "Email", value: item?.counter_donation?.email ?? "-" },
        { title: "Ocassion", value: item?.counter_donation?.occasion ?? "-" },
      ];
    });
    return datas.map((item, i) => {
      return (
        <Row style={{ marginBottom: "5px" }} key={"ticketdetails" + i}>
          <Col className="ticket_label">{item.title}:</Col>
          <Col className="ticket_data">{item?.value}</Col>
        </Row>
      );
    });
  };

  const renderPrasadamTicket = () => {
    let datas = [
      {
        title: "Ticket Type ",
        value: "Prasadam",
      },
    ];
    let totalAmount = 0;
    data?.forEach((item) => {
      totalAmount = Number(totalAmount + item?.counter_prasadam?.price);
      datas = [
        ...datas,
        { title: "Item Name", value: item?.counter_prasadam?.prasadamName },
        { title: "Quantity", value: item?.counter_prasadam?.noOfItems },
        { title: "Price", value: `Rs. ${item?.counter_prasadam?.price}.00` },
      ];
    });
    datas = [
      ...datas,
      { title: "Total Amount", value: `Rs. ${totalAmount}.00` },
    ];
    return datas.map((item, i) => {
      return (
        <Row style={{ marginBottom: "5px" }} key={"ticketdetails" + i}>
          <Col className="ticket_label">{item.title}:</Col>
          <Col
            className="ticket_data"
            style={{
              fontWeight: item.title === "Total Amount" ? "800" : "400",
            }}
          >
            {item?.value}
          </Col>
        </Row>
      );
    });
  };

  const renderThulabharamTicket = () => {
    let datas = [
      {
        title: "Ticket Type ",
        value: "Thulabharam",
      },
    ];
    let totalAmount = 0;
    data?.forEach((item) => {
      totalAmount = Number(totalAmount + item?.counter_thulabharam?.price);
      datas = [
        ...datas,
        {
          title: "Item Name",
          value: item?.counter_thulabharam?.thulabharamName,
        },
        { title: "Quantity", value: item?.counter_thulabharam?.noOfItems },
        { title: "Price", value: `Rs. ${item?.counter_thulabharam?.price}.00` },
      ];
    });
    datas = [
      ...datas,
      { title: "Total Amount", value: `Rs. ${totalAmount}.00` },
    ];
    return datas.map((item, i) => {
      return (
        <Row style={{ marginBottom: "5px" }} key={"ticketdetails" + i}>
          <Col className="ticket_label">{item?.title}:</Col>
          <Col
            className="ticket_data"
            style={{
              fontWeight: item?.title === "Total Amount" ? "800" : "400",
            }}
          >
            {item?.value}
          </Col>
        </Row>
      );
    });
  };

  const renderAccommodationTicket = () => {
    let datas = [
      {
        title: "Ticket Type ",
        value: "Accommodation",
      },
    ];
    let checkOutDatas = [];
    let renewDatas = [];
    data.forEach((item) => {
      datas = [
        ...datas,
        {
          title: "Status",
          value:
            item?.counter_accommodation?.currentStatus ??
            item?.online_accommodation?.currentStatus,
        },
        {
          title: "Devotee Name",
          value:
            item?.counter_accommodation?.devoteeName ??
            item?.online_accommodation?.devoteeName,
        },
        {
          title: "Room Details",
          value: `${
            item?.counter_accommodation?.roomType ??
            item?.online_accommodation?.roomType
          } - ${
            item?.counter_accommodation?.blockName ??
            item?.online_accommodation?.blockName
          } - ${
            item?.counter_accommodation?.roomNumber ??
            item?.online_accommodation?.roomNumber
          }`,
        },
        {
          title: "Room Price",
          value: `Rs. ${
            item?.counter_accommodation?.roomPrice ??
            item?.online_accommodation?.roomPrice
          }.00`,
        },
        {
          title: "Checkin Time",
          value: moment(
            item?.counter_accommodation?.checkInTime ??
              item?.online_accommodation?.checkInTime
          ).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "Actual Checkout Time",
          value: moment(
            item?.counter_accommodation?.currentCheckOutTime ??
              item?.online_accommodation?.currentCheckOutTime
          ).format("DD-MM-YYYY hh:mm A"),
        },
      ];
    });
    data.forEach((item) => {
      checkOutDatas = [
        ...checkOutDatas,
        {
          title: "New Checkout Time",
          value: moment(
            item?.counter_accommodation?.newCheckOutTime ??
              item?.online_accommodation?.newCheckOutTime
          ).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "Unpaid Days",
          value:
            item?.counter_accommodation?.unPaidDays ??
            item?.online_accommodation?.unPaidDays,
        },
        {
          title: "Deposit Amount",
          value: `Rs. ${
            item?.counter_accommodation?.depositAmount ??
            item?.online_accommodation?.depositAmount
          }.00`,
        },
        {
          title: "Service Amount",
          value: `Rs. ${
            item?.counter_accommodation?.serviceAmount ??
            item?.online_accommodation?.serviceAmount
          }.00`,
        },
        {
          title: "GST Amount",
          value: `Rs. ${
            item?.counter_accommodation?.gstAmount ??
            item?.online_accommodation?.gstAmount
          }.00`,
        },
        {
          title: "Outstanding Amount",
          value: `Rs. ${
            item?.counter_accommodation?.outstandingAmount ??
            item?.online_accommodation?.outstandingAmount
          }.00`,
        },
      ];
    });
    renewDatas = [
      {
        title: "New Checkout Time",
        value: moment(renew?.newCheckOutTime).format("DD-MM-YYYY hh:mm A"),
      },
      {
        title: "Previous Renewal Count",
        value: renew?.previousRenewalCount,
      },
      {
        title: "Unpaid Days",
        value: renew?.unPaidDays,
      },
      {
        title: "Deposit Amount",
        value: `Rs. ${renew?.depositAmount}.00`,
      },
      {
        title: "Service Amount",
        value: `Rs. ${renew?.serviceAmount}.00`,
      },
      {
        title: "GST Amount",
        value: `Rs. ${renew?.gstAmount}.00`,
      },
      {
        title: "Outstanding Amount",
        value: `Rs. ${renew?.outstandingAmount}.00`,
      },
    ];
    return (
      <>
        {datas.map((item, i) => {
          return (
            <Row style={{ marginBottom: "5px" }} key={"ticketdetails" + i}>
              <Col className="ticket_label">{item?.title}:</Col>
              <Col className="ticket_data">{item?.value}</Col>
            </Row>
          );
        })}
        {renew && (
          <>
            <Divider />
            <Row>
              <Col
                style={{
                  width: "50%",
                  borderRight: "1px solid #f0f0f0",
                  paddingLeft: "20px",
                }}
              >
                <b
                  style={{
                    justifyContent: "center",
                    margin: "auto",
                    display: "flex",
                  }}
                >
                  Checkout Details
                </b>
                <br />
                {checkOutDatas.map((item, i) => {
                  return (
                    <Row
                      style={{ marginBottom: "5px" }}
                      key={"ticketdetails" + i}
                    >
                      <Col className="ticket_label">{item?.title}:</Col>
                      <Col className="ticket_data">{item?.value}</Col>
                    </Row>
                  );
                })}
                <br />
              </Col>
              {renew?.roomType !== "KALYANA_MANDAPAM" && (
                <Col style={{ width: "50%", paddingLeft: "30px" }}>
                  <b
                    style={{
                      justifyContent: "center",
                      margin: "auto",
                      display: "flex",
                    }}
                  >
                    Renew Details
                  </b>
                  <br />
                  {renewDatas.map((item, i) => {
                    return (
                      <Row
                        style={{ marginBottom: "5px" }}
                        key={"ticketdetails" + i}
                      >
                        <Col className="ticket_label">{item?.title}:</Col>
                        <Col className="ticket_data">{item?.value}</Col>
                      </Row>
                    );
                  })}
                  <br />
                </Col>
              )}
            </Row>
            {from === "accoScan" && (
              <Row style={{ textAlign: "center" }}>
                <Col
                  style={{
                    width: "50%",
                    borderRight: "1px solid #f0f0f0",
                  }}
                >
                  <Button
                    type="primary"
                    disabled={
                      templeDetails?.configuration
                        ?.enableAccommodationFingerScan
                        ? data[0]?.counter_accommodation
                            ?.checkInFingerPrintTemplate != null
                          ? match === true
                            ? false
                            : true
                          : data[0]?.online_accommodation
                              ?.checkInFingerPrintTemplate != null
                          ? match === true
                            ? false
                            : true
                          : false
                        : false
                    }
                    onClick={() =>
                      bookingUpdate({
                        func: "checkOut",
                        roomBookingId:
                          data[0]?.counter_accommodation?.roomBookingId ??
                          data[0]?.online_accommodation?.roomBookingId,
                        outstandingAmount:
                          data[0]?.counter_accommodation?.outstandingAmount ??
                          data[0]?.online_accommodation?.outstandingAmount,
                      })
                    }
                  >
                    Check Out
                  </Button>
                </Col>
                {renew?.roomType !== "KALYANA_MANDAPAM" && (
                  <Col
                    style={{
                      width: "50%",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() =>
                        bookingUpdate({
                          func: "renew",
                          roomBookingId: renew?.roomBookingId,
                          outstandingAmount: renew?.outstandingAmount,
                        })
                      }
                    >
                      Renew
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
      </>
    );
  };

  const renderOnlineAccommodationTicket = () => {
    let datas = [
      {
        title: "Ticket Type ",
        value: "Accommodation",
      },
    ];
    console.log(data);
    data.forEach((item) => {
      datas = [
        ...datas,
        {
          title: "Devotee Name",
          value:
            item?.counter_accommodation_advance?.devoteeName ??
            `${item?.online_accommodation?.firstName} ${item?.online_accommodation?.lastName}`,
        },
        {
          title: "No. of Persons",
          value:
            item?.online_accommodation?.noOfPeople ??
            item?.counter_accommodation_advance?.noOfPeople,
        },
        {
          title: "Booking Date",
          value: item?.online_accommodation?.bookingDate
            ? moment(item?.online_accommodation?.bookingDate).format(
                "DD-MM-YYYY"
              )
            : moment(item?.counter_accommodation_advance?.bookingDate).format(
                "DD-MM-YYYY"
              ),
        },
        {
          title: "Room Details",
          value: item?.counter_accommodation_advance?.accBlockCategory?.roomType
            ? `${
                item?.counter_accommodation_advance?.accBlockCategory?.roomType
              } - ${
                item?.counter_accommodation_advance?.accBlockCategory?.accBlock
                  ?.name
              } - ${
                item?.counter_accommodation_advance?.accPreAssignedRoom
                  ?.roomNumber ?? ""
              }`
            : `${item?.online_accommodation?.accBlockCategory?.roomType} - ${item?.online_accommodation?.accBlockCategory?.accBlock?.name}`,
        },
        {
          title: "Paid Room Price",
          value: `Rs. ${
            item?.counter_accommodation_advance?.price ??
            item?.online_accommodation?.accBlockCategory?.price
          }.00`,
        },
        {
          title: "Paid GST",
          value: `Rs. ${
            item?.counter_accommodation_advance?.gst ??
            item?.online_accommodation?.gst
          }.00`,
        },
        {
          title: "Address",
          value: item?.counter_accommodation_advance?.address
            ? `${item?.counter_accommodation_advance?.address} ${
                item?.counter_accommodation_advance?.address2 ?? ""
              }
            ${item?.counter_accommodation_advance?.city ?? ""}
            ${item?.counter_accommodation_advance?.state ?? ""}
            ${item?.counter_accommodation_advance?.country ?? ""}
            
            `
            : item?.online_accommodation?.address
            ? `${item?.online_accommodation?.address} ${
                item?.online_accommodation?.address2 ?? ""
              }
            ${item?.online_accommodation?.city ?? ""}
            ${item?.online_accommodation?.state ?? ""}
            ${item?.online_accommodation?.country ?? ""}
            
            `
            : "-",
        },
      ];
    });
    return (
      <>
        {datas.map((item, i) => {
          return (
            <Row style={{ marginBottom: "5px" }} key={"ticketdetails" + i}>
              <Col className="ticket_label">{item?.title}:</Col>
              <Col className="ticket_data">{item?.value}</Col>
            </Row>
          );
        })}
      </>
    );
  };

  const renderTollgateTicket = () => {
    let datas = [
      {
        title: "Ticket Type ",
        value: "Toll Gate",
      },
    ];
    data.forEach((item) => {
      datas = [
        ...datas,
        {
          title: "TI.No",
          value: item?.categoryTransactionSequence,
        },
        {
          title: "SI.No",
          value: item?.dailyCategoryTransactionSequence,
        },

        {
          title: "Vehicle Type",
          value: item?.counter_tollgate?.tollgateName,
        },
        {
          title: "numberPlate",
          value: item?.counter_tollgate?.numberPlate ?? "-",
        },

        {
          title: "Created At",
          value: moment(item?.counter_tollgate?.createdAt).format("DD-MM-YYYY"),
        },
        {
          title: "Price",
          value: FormatNumberINRCurrency(item?.counter_tollgate?.amount),
        },
      ];
    });
    return datas.map((item, i) => {
      return (
        <Row style={{ marginBottom: "5px" }} key={"ticketdetails" + i}>
          <Col className="ticket_label">{item?.title}:</Col>
          <Col className="ticket_data">{item?.value}</Col>
        </Row>
      );
    });
  };

  return (
    // <div style={{ width: "100%" }}>
    <Card
      hoverable={"true"}
      style={{ width: "100%", textAlign: "left" }}
      title="Ticket Details"
    >
      <Row style={{ marginBottom: "5px" }}>
        <Col className="ticket_label">Ticket Scanned :</Col>
        <Col className="ticket_data">
          {(() => {
            if (data[0]?.isScanned === true || data[0]?.ticket_used === true) {
              return (
                <Tag color="error">
                  Scanned -{" "}
                  {data[0]?.scannedDate
                    ? moment(data[0]?.scannedDate).format(
                        "DD-MM-YYYY - (hh:mm:ss A)"
                      )
                    : data[0]?.scanned_on
                    ? moment(data[0]?.scanned_on).format(
                        "DD-MM-YYYY - (hh:mm:ss A)"
                      )
                    : "-"}
                </Tag>
              );
            } else if (
              data[0]?.isScanned === false ||
              data[0]?.ticket_used === false
            ) {
              return <Tag color="success">New Ticket</Tag>;
            }
          })()}
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ alignItems: "center" }}>
        <Col
          style={{
            width: "70%",
            display:
              from === "accoScan"
                ? data[0]?.counter_accommodation?.roomNumber ||
                  data[0]?.online_accommodation?.roomNumber ||
                  data[0]?.counter_accommodation_advance?.accPreAssignedRoom
                    ?.roomNumber
                  ? "none"
                  : "block"
                : "none",
          }}
        >
          <TmsPosOnlineRooms data={data} captured={captured} type={type} />
        </Col>
        <Col
          style={{
            width:
              from === "accoScan"
                ? data[0]?.counter_accommodation?.roomNumber ||
                  data[0]?.online_accommodation?.roomNumber ||
                  data[0]?.counter_accommodation_advance?.accPreAssignedRoom
                    ?.roomNumber
                  ? "100%"
                  : "30%"
                : "100%",
          }}
        >
          {data[0]?.ticketId && (
            <Row style={{ marginBottom: "5px" }}>
              <Col className="ticket_label">Ticket ID :</Col>
              <Col className="ticket_data">{data[0]?.ticketId}</Col>
            </Row>
          )}
          {console.log(data)}
          {data[0]?.productType === "counter_seva" ||
          data[0]?.productType === "online_seva" ||
          data[0]?.type?.includes("Seva")
            ? renderSevaTicket()
            : data[0]?.productType === "counter_darshanam" ||
              data[0]?.productType === "online_darshanam" ||
              data[0]?.type?.includes("darshanam")
            ? renderDarshanamTicket()
            : data[0]?.productType === "counter_donation"
            ? renderDonationTicket()
            : data[0]?.productType === "counter_prasadam"
            ? renderPrasadamTicket()
            : data[0]?.productType === "counter_thulabharam"
            ? renderThulabharamTicket()
            : data[0]?.productType === "counter_accommodation" ||
              data[0]?.online_accommodation?.roomNumber
            ? renderAccommodationTicket()
            : data[0]?.productType === "counter_accommodation_advance" ||
              data[0]?.productType === "online_accommodation"
            ? renderOnlineAccommodationTicket()
            : data[0]?.productType === "counter_tollgate"
            ? renderTollgateTicket()
            : ""}
        </Col>
      </Row>
      {data[0]?.counter_accommodation_advance?.accBlockCategory?.roomType ===
        "KALYANA_MANDAPAM" && (
        <>
          <br />
          <Row style={{ justifyContent: "center" }}>
            <Button type="primary" onClick={() => setRoomsModal(true)}>
              Check In
            </Button>
          </Row>
        </>
      )}
      <Modal
        visible={roomsModal}
        centered
        maskClosable={false}
        closable
        onCancel={() => setRoomsModal(false)}
        footer={false}
      >
        <TmsPosOnlineRooms
          data={data}
          from={"accoScan"}
          type={type}
          captured={captured}
        />
      </Modal>
    </Card>
  );
};

export default TmsRenderDetailsRenderer;
