import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../../../utils/tms-catch-error/tms-catch-error";
import { Row, Space, Switch } from "antd";
import TmsModal from "../../../../../../../components/tms-customs/tms-modal";
import "../../../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmsAccommodationService from "../../../../../../../services/tms-accommodation.service";
import TmsAccIpMcrManageRoomAddNew from "./tms-mcr-mr-add-new";
import { EditOutlined } from "@ant-design/icons";
const TmsAccIPMcrManageRoomAll = ({ active, router }) => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accommodationData, setAccommodationData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewAccommodationData, setViewAccommodationData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [roomId, setRoomId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  const switchTrigger = async (key, status) => {
    setLoading(true);
    try {
      const accData = await TmsAccommodationService.getBlockCategoryRoomById(
        key
      );
      let body = {
        roomNumber: accData.roomNumber,
        description: accData.description,
        accBlockCategoryId: accData.accBlockCategoryId,
        enabled: status,
      };
      await TmsAccommodationService.editBlockCategoryRoom(key, body);
      setUpdateFlag(Math.random() + 1);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const getAccommadation = async () => {
    setLoading(true);
    try {
      setAccommodationData([]);
      const data = await TmsAccommodationService.getAllBlockCategoryRoom(
        new URLSearchParams(router?.location.search).get("categoryid"),
        tableCurrentPage,
        tablePageSize
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setAccommodationData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Room Number",
      dataIndex: "roomNumber",
      key: "roomNumber",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Description ",
      dataIndex: "description",
      key: "description",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Status",
      dataIndex: "enabled",
      key: "enabled",
      render: (text, record) => {
        return (
          <Switch
            size={"small"}
            checked={text}
            style={{
              background: text ? "#4CBB17 " : "EFEFEF ",
            }}
            onClick={() => {
              switchTrigger(record?.key, !text);
            }}
          />
        );
      },
    },

    {
      title: "Action",
      render: (record) => {
        return (
          <Space size="middle">
            <a
              onClick={async () => {
                setLoading(true);
                try {
                  const accData =
                    await TmsAccommodationService.getBlockCategoryRoomById(
                      record?.key
                    );
                  setViewAccommodationData(accData);
                  settmsModalVisible(true);
                  setEditFlag(true);
                  setRoomId(record?.key);
                } catch (error) {
                  catchErrorFunction(
                    error,
                    "Token Expired, redirecting to login page in 5 seconds",
                    router
                  );
                }
                setLoading(false);
              }}
            >
              <EditOutlined />
            </a>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      router.location?.pathname.includes(
        "service-management/accommodation/manage-room-categories/manage-room"
      ) &&
      active === "Manage Room"
    ) {
      getAccommadation();
      if (!editFlag) {
        settmsModalVisible(false);
      }
    }
  }, [
    active,
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <label>
          {new URLSearchParams(router.location.search).get("name")}
          {new URLSearchParams(router.location.search).get("flag") &&
            ` - ${new URLSearchParams(router.location.search).get("flag")}`}
        </label>
      </Row>
      <br />
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={accommodationData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <TmsModal
        className="DetailsModal"
        centered={true}
        width={"800px"}
        closable={true}
        visible={tmsModalVisible}
        onCancel={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewAccommodationData(null);
        }}
        title={editFlag ? "Edit Room Category" : "Room Category Details"}
      >
        {editFlag ? (
          <TmsAccIpMcrManageRoomAddNew
            viewAccommodationData={viewAccommodationData}
            editFlag={editFlag}
            roomId={roomId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
          />
        ) : (
          ""
        )}
      </TmsModal>
    </>
  );
};

export default withRouter(TmsAccIPMcrManageRoomAll);
