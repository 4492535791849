import {
  busLogo,
  parakamani,
  rentsLogo,
  tms_d_products,
  tms_icon_accommodation,
  tms_icon_check_in_scan,
  tms_icon_coupon_management,
  tms_icon_darshanam,
  tms_icon_donation,
  tms_icon_kalyanakatta,
  tms_icon_prasadam,
  tms_icon_pratyaksha_Seva,
  tms_icon_protocol,
  tms_icon_publication,
  tms_icon_shift_managament,
  tms_icon_thulabharam,
  tms_icon_ticket_details,
  tms_icon_tollgate,
} from "../assets/images/tms-image-list";
import tmsRouteMap from "./tms-route.map";

const TmsReportsMap = [
  {
    title: "Shift Report",
    route: tmsRouteMap.reports_shift_report,
    accessedByKey: [
      "SHIFT_LOGIN",
      "ADMIN",
      "SUPER_ADMIN",
      "ACCOMMODATION_REPORT",
      "SEVA_REPORT",
      "DARSHANAM_REPORT",
      "PRASADAM_REPORT",
      "KALYANAKATTA_REPORT",
      "DONATION_REPORT",
      "PUBLICATION_REPORT",
      "TOLLGATE_REPORT",
      "SHIFT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "THULABHARAM_REPORT",
      "SHOP_PRODUCT_REPORT",
      "MISC_PRODUCT_REPORT",
    ],
    color: "#FEF6D7",
    hidden: "",

    logo: tms_icon_shift_managament,
    textColor: "#EF7843",
  },
  {
    title: "Shift Transactions",
    route: tmsRouteMap.reports_view_shift_transactions,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "SHIFT_LOGIN",
      "ACCOMMODATION_REPORT",
      "SEVA_REPORT",
      "DARSHANAM_REPORT",
      "PRASADAM_REPORT",
      "KALYANAKATTA_REPORT",
      "DONATION_REPORT",
      "PUBLICATION_REPORT",
      "TOLLGATE_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "THULABHARAM_REPORT",
      "SHOP_PRODUCT_REPORT",
      "MISC_PRODUCT_REPORT",
    ],
    color: "#EEEBFF",
    logo: tms_icon_check_in_scan,
    textColor: "#573BFF",
    hidden: "",
  },
  {
    title: "Scan Logs",
    route: tmsRouteMap.reports_scan_logs,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "ACCOMMODATION_REPORT",
      "SEVA_REPORT",
      "DARSHANAM_REPORT",
      "PRASADAM_REPORT",
      "KALYANAKATTA_REPORT",
      "DONATION_REPORT",
      "PUBLICATION_REPORT",
      "SHOP_PRODUCT_REPORT",
      "MISC_PRODUCT_REPORT",
      "TOLLGATE_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "THULABHARAM_REPORT",
    ],
    color: "#F0F9FF",
    logo: tms_icon_darshanam,
    textColor: "#3399CC",
    hidden: "",
  },
  {
    title: "Accommodation Reports",
    route: tmsRouteMap.reports_accommodation,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "ACCOMMODATION_REPORT"],
    color: "#E6F8FD",
    logo: tms_icon_accommodation,
    textColor: "#0EB5E4",
    hidden: "accommodationEnabled",
  },
  {
    title: "Darshanam Reports",
    route: tmsRouteMap.reports_darshanam,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "DARSHANAM_REPORT",
    ],
    color: "#F0F9FF",
    logo: tms_icon_darshanam,
    textColor: "#3399CC",
    hidden: "darshanamEnabled",
  },
  {
    title: "Seva Reports",
    route: tmsRouteMap.reports_seva,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "SEVA_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "SEVA_REPORT",
    ],
    color: "#EEF7EE",
    logo: tms_icon_pratyaksha_Seva,
    textColor: "#53B04A",
    hidden: "sevaEnabled",
  },
  {
    title: "Donation Report",
    route: tmsRouteMap.reports_donation,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "DONATION_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "DONATION_REPORT",
    ],
    color: "#FFF6FB",
    logo: tms_icon_donation,
    textColor: "#CC3399",
    hidden: "donationEnabled",
  },
  {
    title: "Prasadam Report",
    route: tmsRouteMap.reports_prasadam,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "PRASADAM_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "PRASADAM_REPORT",
    ],
    color: "#F0F7D8",
    logo: tms_icon_prasadam,
    textColor: "#999966",
    hidden: "prasadamEnabled",
  },
  {
    title: "Thulabharam Report",
    route: tmsRouteMap.reports_thulabharam,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "THULABHARAM_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
    ],
    color: "#D6F8FF",
    logo: tms_icon_thulabharam,
    textColor: "#669999",
    hidden: "thulabaramEnabled",
  },
  {
    title: "Toll Gate Report",
    route: tmsRouteMap.reports_tollgate,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "TOLLGATE_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
    ],
    color: "#F9F8FD",
    logo: tms_icon_tollgate,
    textColor: "#666699",
    hidden: "tollgateEnabled",
  },
  {
    title: "Products Reports",
    route: tmsRouteMap.report_products,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "SHOP_PRODUCT_REPORT"],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
    hidden: "productEnabled",
  },
  {
    title: "Kalyanakatta Report",
    route: tmsRouteMap.reports_kalyanakatta,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "KALYANAKATTA_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
    ],
    color: "#D9F7E0",
    logo: tms_icon_kalyanakatta,
    textColor: "#669966",
    hidden: "kalyanakattaEnabled",
  },
  {
    title: "Consolidated Report",
    route: tmsRouteMap.reports_consolidated_reports,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
    ],
    color: "#FFE2E5",
    logo: tms_icon_coupon_management,
    textColor: "#848355",
    hidden: "",
  },
  {
    title: "Manual Entry Report",
    route: tmsRouteMap.report_manual_entry,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
    hidden: "",
  },
  {
    title: "Miscellaneous Report",
    route: tmsRouteMap.reports_misc,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "MISC_PRODUCT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
    ],
    color: "#D9F7E0",
    logo: tms_d_products,
    textColor: "#669966",
    hidden: "miscellaneousEnabled",
  },
  {
    title: "All Transaction Report",
    route: tmsRouteMap.reports_all_transactions_reports,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],

    color: "#EEF7EE",
    logo: tms_icon_publication,
    textColor: "#53B04A",
    hidden: "",
  },
  {
    title: "Entry Scan Report",
    route: tmsRouteMap.reports_entryScan,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "ENTRY_SCANNING_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
    ],
    color: "#F0F9FF",
    logo: tms_icon_check_in_scan,
    textColor: "#3399CC",
    hidden: "",
  },
  {
    title: "Print Logs",
    route: tmsRouteMap.reports_print_logs,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#ECECF2",
    logo: tms_icon_ticket_details,
    textColor: "#7D4FB2",
    hidden: "",
  },

  {
    title: "Rents and Leases Report",
    route: tmsRouteMap.reports_rents_and_leases,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "SHIFT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "RENT_AND_LEASE_READ",
      "RENT_AND_LEASE_COUNTER",
    ],
    color: "#EEEBFF",
    logo: rentsLogo,
    textColor: "#573BFF",
    hidden: "rentsAndLeasesEnabled",
  },

  {
    title: "Protocol Report",
    route: tmsRouteMap.reports_protocol,
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
    color: "#FEFBEC",
    logo: tms_icon_protocol,
    textColor: "#CC9933",
    hidden: "protocolEnable",
  },
  {
    title: "Parakamani Report",
    hidden: "hundiEnabled",
    route: tmsRouteMap.reports_parakamani,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "HUNDI_REPORT"],
    color: "#FEF6D7",
    logo: parakamani,
    textColor: "#EF7843",
  },
  {
    title: "Bus Report",
    hidden: "busEnabled",
    route: tmsRouteMap.reports_bus,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "BUS_TICKET_REPORT"],
    color: "#EEF7EE",
    logo: busLogo,
    active: true,
    textColor: "#53B04A",
  },
  {
    title: "Publication Report",
    hidden: "publicationEnabled",
    route: tmsRouteMap.report_publication,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "PUBLICATION_REPORT"],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
    active: true,
  },
  // {
  //   title: "Report Requests",
  //   route: tmsRouteMap.reports_requests,
  //   accessedByKey: [
  //     "ADMIN",
  //     "SUPER_ADMIN",
  //     "ACCOMMODATION_REPORT",
  //     "SEVA_REPORT",
  //     "DARSHANAM_REPORT",
  //     "PRASADAM_REPORT",
  //     "KALYANAKATTA_REPORT",
  //     "DONATION_REPORT",
  //     "PUBLICATION_REPORT",
  //     "SHOP_PRODUCT_REPORT",
  //     "MISC_PRODUCT_REPORT",
  //     "TOLLGATE_REPORT",
  //     "GST_REPORT",
  //     "SCAN_LOG_REPORT",
  //     "TRANSACTIONS_REPORT",
  //     "THULABHARAM_REPORT",
  //   ],
  //   color: "#FFF6FB",
  //   logo: quoteRequest,
  //   textColor: "#CC3399",
  //   hidden: "",
  // },
]; // ADD ROLES IN Sider.map.js
export default TmsReportsMap;
