import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Switch,
  Tabs,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import TmsImageUpload from "../../components/tms-customs/tms-image-upload";
import TmsPhoneNumberBasic from "../../components/tms-customs/tms-phone-number-basic";
import TmsTempleService from "../../services/tms-temple.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import { RegionDropdown } from "react-country-region-selector";
import moment from "moment";
import TmsCheckBoxList from "../../components/tms-customs/tms-checkbox-list";
import TmsInstitutionCreation from "../tms-service-management/tms-service-management-pages/tms-sm-rents-and-leases/tms-sm-rents-and-leases-inner-pages/creation-inner-pages/tms-institution-creation";
import TmsInstitutionList from "../../components/tms-service-management/tms-rents-and-leases/tms-institution-list";
import { BaseContext } from "../../contexts/BaseContext";
import TmsTable from "../../components/tms-customs/tms-table";
import { services } from "../../utils/tms-services/tms-services";
import tmsRouteMap from "../../constants/tms-route.map";
import TmsBookingLimiterModal from "../../components/tms-settings/booking-limiter-popup";
import TmsBookingLimiterTable from "../../components/tms-settings/booking-limiter-table";
const { TabPane } = Tabs;

const TmsTempleSettings = ({ router }) => {
  const { userDetails } = useContext(BaseContext);
  const [feeType, setFeeType] = useState("percentage");
  const [feeValue, setFeeValue] = useState("");
  const [conditionOne, setConditionOne] = useState("");
  const [conditionOneValue, setConditionOneValue] = useState("");
  const [liveURL, setLiveURL] = useState();
  const [conditionTwo, setConditionTwo] = useState("");
  const [conditionTwoValue, setConditionTwoValue] = useState("");
  const [payementGateway, setPaymentGateway] = useState("");
  const [subAccountID, setSubAccountID] = useState("");
  const [limitVisible, setLimitVisible] = useState("");
  const [updateTable, setUpdateTable] = useState("");
  const [editDetails, setEditDetails] = useState("");
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  const [darTicketCount, setDarTicketCount] = useState(
    templeDetails?.configuration?.darshanam_max_tickets_per_booking ?? 0
  );
  const [darshanamType, setDarshanamType] = useState(
    templeDetails?.configuration?.darshanamMultiTickets
  );

  const [loading, setLoading] = useState(false);
  const [sevaReprintOtp, setSevaReprintOtp] = useState(
    templeDetails?.configuration?.counterSevaReprintOTPPhone?.replace(
      "+91",
      ""
    ) ?? ""
  );
  const [accommodationForceOTP, setAccommodationForceOTP] = useState(
    templeDetails?.configuration?.accommodationForceCheckoutOTPPhone?.replace(
      "+91",
      ""
    ) ?? ""
  );
  const [darshnamReprintOtp, setDarshanamReprintOtp] = useState(
    templeDetails?.configuration?.counterDarshanamReprintOTPPhone?.replace(
      "+91",
      ""
    ) ?? ""
  );
  const [donationReprintOtp, setDonationReprintOtp] = useState(
    templeDetails?.configuration?.counterDonationReprintOTPPhone?.replace(
      "+91",
      ""
    ) ?? ""
  );
  const [tollgateReprintOtp, setTollgateReprintOtp] = useState(
    templeDetails?.configuration?.counterTollgateReprintOTPPhone?.replace(
      "+91",
      ""
    ) ?? ""
  );
  const [prasadamReprintOtp, setPrasadamReprintOtp] = useState(
    templeDetails?.configuration?.counterPrasadamReprintOTPPhone?.replace(
      "+91",
      ""
    ) ?? ""
  );
  const [thulabharamReprintOtp, setThulabharamReprintOTP] = useState(
    templeDetails?.configuration?.counterThulabharamReprintOTPPhone?.replace(
      "+91",
      ""
    ) ?? ""
  );
  const [kalyanakattaReprintOtp, setKalyanakattaReprintOTP] = useState(
    templeDetails?.configuration?.counterKalyanakattaReprintOTPPhone?.replace(
      "+91",
      ""
    ) ?? ""
  );
  const [accommodationReprintOTP, setAccommodationReprintOTP] = useState(
    templeDetails?.configuration?.counterAccommodationReprintOTPPhone?.replace(
      "+91",
      ""
    ) ?? ""
  );
  const [visible, setVisible] = useState(false);
  const [prasadamInventory, setprasadamInventory] = useState(
    templeDetails?.configuration?.prasadamInventory ?? ""
  );
  // const [protocol, setProtocol] = useState(
  //   templeDetails?.configuration?.protocolEnabled ?? false
  // );
  // const [rentsAndLeasesEnabled, setRentsAndLeasesEnabled] = useState(
  //   templeDetails?.configuration?.rentsAndLeasesEnabled ?? false
  // );
  // const [donorPrivilegeEnabled, setdonorPrivilegeEnabled] = useState(
  //   templeDetails?.configuration?.donorPrivilegeEnabled ?? false
  // );

  const [serviceEnable, setSerivceEnable] = useState({
    protocolEnabled: templeDetails?.configuration?.protocolEnabled ?? false,
    donorPrivilegeEnabled:
      templeDetails?.configuration?.donorPrivilegeEnabled ?? false,
    rentsAndLeasesEnabled:
      templeDetails?.configuration?.rentsAndLeasesEnabled ?? false,
    sevaEnabled: templeDetails?.configuration?.sevaEnabled ?? false,
    darshanamEnabled: templeDetails?.configuration?.darshanamEnabled ?? false,
    accommodationEnabled:
      templeDetails?.configuration?.accommodationEnabled ?? false,
    donationEnabled: templeDetails?.configuration?.donationEnabled ?? false,
    tollgateEnabled: templeDetails?.configuration?.tollgateEnabled ?? false,
    productEnabled: templeDetails?.configuration?.productEnabled ?? false,
    prasadamEnabled: templeDetails?.configuration?.prasadamEnabled ?? false,
    kalyanakattaEnabled:
      templeDetails?.configuration?.kalyanakattaEnabled ?? false,
    thulabaramEnabled: templeDetails?.configuration?.thulabaramEnabled ?? false,
    hundiEnabled: templeDetails?.configuration?.hundiEnabled ?? false,
    busEnabled: templeDetails?.configuration?.busEnabled ?? false,
    publicationEnabled:
      templeDetails?.configuration?.publicationEnabled ?? false,
    miscellaneousEnabled:
      templeDetails?.configuration?.miscellaneousEnabled ?? false,
    addOnEnabled: templeDetails?.configuration?.addOnEnabled ?? false,
    footfallEnabled: templeDetails?.configuration?.footfallEnabled ?? false,
    posDevicePaymentEnabled:
      templeDetails?.configuration?.posDevicePaymentEnabled ?? false,
  });
  const [sevaSplitTicket, setSevaSplitTicket] = useState(
    templeDetails?.configuration?.sevaSplitTickets ?? ""
  );
  const [enableDonationBond, setEnableDonationBond] = useState(
    templeDetails?.configuration?.enableDonationBond ?? ""
  );
  const [kalyanakattaPerformer, setKalyanakattaPerformer] = useState(
    templeDetails?.configuration?.kalyanakattaPerformer ?? false
  );
  const [accPerAadharLimit, setAccPerAadharLimit] = useState();
  const [accPerAadharDays, setAccPerAadharDays] = useState();

  const [active, setActive] = useState("counter-settings");
  const [razorpayKey, setRazorPayKey] = useState("");
  const [razorpaySecret, setRazorPaySecret] = useState("");
  const [razorpayMerchant, setRazorpayMerchant] = useState("");
  const [onlineData, setOnlineData] = useState("");
  const [fileList, setFileList] = useState([]);
  const [headerImg, setHeaderImg] = useState([]);
  const [footerImg, setFooterImg] = useState([]);
  const [banner, setBanner] = useState([]);
  const [time, setTime] = useState("");
  const [AccommodationFingerScan, setAccommodationFingerScan] = useState(
    templeDetails?.configuration?.enableAccommodationFingerScan
      ? templeDetails?.configuration?.enableAccommodationFingerScan
      : false
  );
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState();
  const [email, setEmail] = useState("");
  const [village, setVillage] = useState("");
  const [mandal, setMandal] = useState("");
  const [district, setDistrict] = useState("");
  const [domain, setDomain] = useState("");
  const [userProductsData, setUserProductData] = useState(
    templeDetails?.configuration?.performanceScanEnabledProducts?.length > 0
      ? templeDetails?.configuration?.performanceScanEnabledProducts
      : []
  );

  const serviceCol = [
    { title: "Service", dataIndex: "name", key: "name" },
    {
      title: "Status",
      dataIndex: "key",
      key: "key",
      render: (record) => {
        return (
          <Switch
            size="small"
            checked={serviceEnable[record]}
            onChange={() => {
              handleChangeServiceStatus(record, !serviceEnable[record]);
            }}
          />
        );
      },
    },
  ];

  const productsData = [
    "counter_darshanam",
    "online_darshanam",
    "counter_seva",
    "online_seva",
    "counter_donation",
    "online_donation",
    "counter_prasadam",
    "counter_tollgate",
    "counter_thulabharam",
    "counter_accommodation",
    "online_accommodation",
    "counter_accommodation_update",
    "counter_accommodation_advance",
    "counter_kalyanakatta",
    "counter_manual_entry",
    "counter_shop_product",
    "online_shop_product ",
    "counter_misc_product",
  ];

  const updateSelectedProducts = (product) => {
    let newProducts = [];
    if (userProductsData?.includes(product)) {
      newProducts = userProductsData.filter((e) => e !== product);
    } else {
      newProducts = [...userProductsData, product];
    }
    setUserProductData(newProducts);
  };

  const handleChangeServiceStatus = (key, value) => {
    console.log(key, value);
    let temp = serviceEnable;
    temp[key] = value;
    setSerivceEnable({ ...temp });
    console.log(temp);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (darshanamType === null) {
      notification.error({ message: "Select a valid darshanam print value" });
      setLoading(false);
      return;
    }
    if (darshnamReprintOtp !== "" && darshnamReprintOtp.length !== 10) {
      notification.error({ message: "Enter a valid darshanam otp number" });
      setLoading(false);
      return;
    }
    if (
      accommodationReprintOTP !== "" &&
      accommodationReprintOTP.length !== 10
    ) {
      notification.error({ message: "Enter a valid Accommodation otp number" });
      setLoading(false);
      return;
    }
    if (donationReprintOtp !== "" && donationReprintOtp.length !== 10) {
      notification.error({ message: "Enter a valid donation otp number" });
      setLoading(false);
      return;
    }
    if (prasadamReprintOtp !== "" && prasadamReprintOtp.length !== 10) {
      notification.error({ message: "Enter a valid prasadam otp number" });
      setLoading(false);
      return;
    }
    if (tollgateReprintOtp !== "" && tollgateReprintOtp.length !== 10) {
      notification.error({ message: "Enter a valid tollgate otp number" });
      setLoading(false);
      return;
    }
    if (sevaReprintOtp !== "" && sevaReprintOtp.length !== 10) {
      notification.error({ message: "Enter a valid seva otp number" });
      setLoading(false);
      return;
    }
    if (thulabharamReprintOtp !== "" && thulabharamReprintOtp.length !== 10) {
      notification.error({ message: "Enter a valid thulabharam otp number" });
      setLoading(false);
      return;
    }
    if (kalyanakattaReprintOtp !== "" && kalyanakattaReprintOtp.length !== 10) {
      notification.error({ message: "Enter a valid kalyanakatta otp number" });
      setLoading(false);
      return;
    }
    if (accommodationForceOTP !== "" && accommodationForceOTP.length !== 10) {
      notification.error({
        message: "Enter a valid acc force checkout otp number",
      });
      setLoading(false);
      return;
    }
    if (darTicketCount !== "" && Number(darTicketCount) > 19) {
      notification.error({
        message: "darshanam max tickets per booking must be less than 20",
      });
      setLoading(false);
      return;
    }

    try {
      const response = await TmsTempleService.updateTempleSettings(
        darshanamType,
        "A4",
        darshnamReprintOtp,
        prasadamReprintOtp,
        donationReprintOtp,
        tollgateReprintOtp,
        sevaReprintOtp,
        thulabharamReprintOtp,
        kalyanakattaReprintOtp,
        accommodationReprintOTP,
        kalyanakattaPerformer,
        prasadamInventory,
        sevaSplitTicket,
        enableDonationBond,
        AccommodationFingerScan,
        accommodationForceOTP,
        userProductsData,
        serviceEnable,
        darTicketCount
      );
      localStorage.setItem("templeDetails", JSON.stringify(response));
      notification.success({ message: "Updated temple configuration" });
      window.location.reload();
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token has been expired!. Please login again.",
        router
      );
      setLoading(false);
    }
  };

  const handleOnlineSubmit = async () => {
    setLoading(true);

    if (conditionTwo !== "" || conditionTwo) {
      if (conditionTwoValue === "") {
        notification.error({ message: "Please provide condition two value" });
        setLoading(false);
        return;
      }
    }
    if (payementGateway === "") {
      notification.error({ message: "Please select a payment gateway" });
      setLoading(false);
      return;
    }
    if (razorpayKey === "" || !razorpayKey) {
      notification.error({
        message: `Please provide valid ${
          payementGateway === "razorpay key" ? "CC avnue Access Code" : ""
        }`,
      });
      setLoading(false);
      return;
    } else {
      if (razorpaySecret === "" || !razorpaySecret) {
        notification.error({
          message: `Please provide valid ${
            payementGateway === "razorpay"
              ? "razorpay key"
              : "CC Avenue Working key"
          } `,
        });
        setLoading(false);
        return;
      }
      if (razorpayMerchant === "" || !razorpayMerchant) {
        notification.error({
          message: "Please provide valid Merchant ID",
        });
        setLoading(false);
        return;
      }
      // if (payementGateway !== "razorpay" && subAccountID === "") {
      //   console.log(subAccountID);
      //   notification.error({
      //     message:
      //       "Please provide valid Sub Account ID if you want to add a sub Account",
      //   });
      //   setLoading(false);
      //   return;
      // }
      // }
    }

    try {
      let body = {
        feeType,
        feeValue,
        conditionOne,
        conditionOneValue,
        conditionTwo,
        conditionTwoValue,
        payementGateway,
        subAccountID,
      };

      body.razorpayKey = razorpayKey;
      body.razorpaySecret = razorpaySecret;
      body.merchantId = razorpayMerchant;

      if (!onlineData || onlineData === "") {
        await TmsTempleService.modifyTempleOnlineSettings(body);
      } else {
        await TmsTempleService.updateTempleOnlineSettings(body);
      }
      await handleOnlineTempleData();

      notification.success({ message: "Updated temple online configuration" });
      setLoading(false);
      window.location.reload();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token has been expired!. Please login again.",
        router
      );
      setLoading(false);
    }
  };

  const handleOnlineTempleData = async () => {
    try {
      let res = await TmsTempleService.getOnlineTempleSettings();
      setOnlineData(res);
      if (res.configuration) {
        res.configuration.razorpay
          ? setPaymentGateway("razorpay")
          : setPaymentGateway("ccavenue");
        res.configuration.razorpay
          ? resetValues("razorpay", res.configuration.razorpay)
          : resetValues("ccavenue", res.configuration.ccavenue);

        let fee = res?.configuration?.fee[0];
        if (fee) {
          setFeeType(fee.type ?? "");
          fee.type
            ? fee.type === "fixed" && setFeeType(fee?.fixedValue)
            : setFeeType(fee?.percentageValue);
          setConditionOne(fee.condition ?? "");
          setConditionTwo(fee.condition2 ?? "");
          setConditionOneValue(fee.conditionValue ?? "");
          setConditionTwoValue(fee.condition2Value ?? "");
        }
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token has been expired!. Please login again.",
        router
      );
    }
  };

  const stateVariables = (data) => {
    if (data?.idolImage === "" || !data?.idolImage) {
      setFileList([]);
    } else {
      setFileList([
        {
          url: data?.idolImage,
          status: "done",
          name: "Idol Image",
        },
      ]);
    }
    if (data?.templeBannerImage === "" || !data?.templeBannerImage) {
      setBanner([]);
    } else {
      setBanner([
        { url: data?.templeBannerImage, status: "done", name: "Temple Banner" },
      ]);
    }
    if (data?.ticketHeaderImage === "" || !data?.ticketHeaderImage) {
      setHeaderImg([]);
    } else {
      setHeaderImg([
        { url: data?.ticketHeaderImage, status: "done", name: "Ticket Header" },
      ]);
    }
    if (data?.ticketFooterImage === "" || !data?.ticketFooterImage) {
      setFooterImg([]);
    } else {
      setFooterImg([
        { url: data?.ticketFooterImage, status: "done", name: "Ticket Footer" },
      ]);
    }
    setMandal(data?.mandal ?? "");
    setVillage(data?.village ?? "");
    setDistrict(data?.district ?? "");
    setLiveURL(data?.parokshaLiveStreamUrl ?? "");
    setTime(data?.templeTime);
    setAddress(data?.address);
    setCity(data?.city);
    setState(data?.state);
    setCountry(data?.country);
    setZip(data?.pinCode);
    setEmail(data?.email);
    setPhone(data?.contact?.replaceAll("+91", ""));
    setDomain(data?.devoteePortalDomain);
    setAccPerAadharDays(
      data?.bookingConfig?.accommodationPerAadharLimitDays ?? ""
    );
    setAccPerAadharLimit(
      data?.bookingConfig?.accommodationPerAadharLimit ?? ""
    );
  };

  const getTempleDetails = async () => {
    setLoading(true);
    try {
      let res = await TmsTempleService.getTempleDetails(
        localStorage.getItem("templeId")
      );
      stateVariables(res?.devoteePortalConfiguration);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token has been expired!. Please login again.",
        router
      );
      setLoading(false);
    }
  };

  const handleTempleProfile = async () => {
    setLoading(true);

    if (fileList?.length === 0) {
      notification.error({
        message: "Upload Idol Image",
      });
      setLoading(false);
      return;
    }
    if (banner?.length === 0) {
      notification.error({
        message: "Upload Temple Banner Image",
      });
      setLoading(false);
      return;
    }
    if (time === undefined || time?.length === 0) {
      notification.error({
        message: "Enter Temple Timings",
      });
      setLoading(false);
      return;
    }
    if (accPerAadharDays < 1 && accPerAadharDays) {
      notification.error({
        message:
          "Enter Accommodation Limit (Days) and should be greather than 1",
      });
      setLoading(false);
      return;
    }
    if (accPerAadharLimit < 1 && accPerAadharLimit) {
      notification.error({
        message:
          "Enter Accommodation Per day limit(Count) and it should be greather than 1",
      });
      setLoading(false);
      return;
    }
    if (phone === "") {
      notification.error({ message: "Enter Phone No." });
      setLoading(false);
      return;
    }
    if (address === "") {
      notification.error({ message: "Enter Address" });
      setLoading(false);
      return;
    }
    if (zip === "") {
      notification.error({ message: "Enter Pincode" });
      setLoading(false);
      return;
    }
    if (city === "") {
      notification.error({ message: "Enter City" });
      setLoading(false);
      return;
    }
    if (village === "") {
      notification.error({ message: "Enter Village" });
      setLoading(false);
      return;
    }
    if (mandal === "") {
      notification.error({ message: "Enter Mandal" });
      setLoading(false);
      return;
    }
    if (district === "") {
      notification.error({ message: "Enter District" });
      setLoading(false);
      return;
    }
    if (!state) {
      notification.error({ message: "Select State" });
      setLoading(false);
      return;
    }
    if (phone?.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      // clearLoadings();
      setLoading(false);
      return;
    }

    // if (!country) {
    //   notification.error({ message: "Select Country" });
    //   setLoading(false);
    //   return;
    // }
    if (email !== "") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        notification.error({ message: "Invalid Email." });
        setLoading(false);
        // clearLoadings();
        return;
      }
    }
    let body = {
      idolImage: fileList[0]?.url ?? "",
      templeBannerImage: banner[0]?.url ?? "",
      templeTime: `${moment(time[0]).format("hh:mm A")} - ${moment(
        time[1]
      ).format("hh:mm A")}`,
      address: address,
      address2: "string",
      city: city,
      state: state,
      country: country ?? "India",
      district: district,
      mandal: mandal,
      village: village,
      contact: `+91${phone}`,
      email: email,
      pinCode: Number(zip),
      bookingConfig: {
        accommodationPerAadharLimit: accPerAadharLimit,
        accommodationPerAadharLimitDays: accPerAadharDays,
      },
    };
    if (domain !== "") {
      body.devoteePortalDomain = domain;
    }
    if (liveURL !== "") {
      body.parokshaLiveStreamUrl = liveURL;
    }
    if (headerImg?.length > 0 && headerImg !== "") {
      body.ticketHeaderImage = headerImg[0].url ?? "";
    }
    if (footerImg?.length > 0 && footerImg !== "") {
      body.ticketFooterImage = footerImg[0].url ?? "";
    }
    try {
      await TmsTempleService.templeProfile(body);
      notification.success({ message: "Updated temple profile" });
      setLoading(false);
      window.location.reload();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token has been expired!. Please login again.",
        router
      );
      setLoading(false);
    }
  };

  const resetValues = (e, values) => {
    if (e === "razorpay") {
      setRazorPayKey(
        values ? values?.key : onlineData?.configuration?.razorpay?.key ?? ""
      );
      setRazorpayMerchant(
        values
          ? values?.merchantId
          : onlineData?.configuration?.razorpay?.merchantId ?? ""
      );
      setRazorPaySecret("");
    } else if (e === "ccavenue") {
      setRazorPayKey(
        values
          ? values?.accessCode
          : onlineData?.configuration?.ccavenue?.accessCode ?? ""
      );
      setRazorpayMerchant(
        values
          ? values?.merchantId
          : onlineData?.configuration?.ccavenue?.merchantId ?? ""
      );
      setRazorPaySecret("");

      setSubAccountID(
        values
          ? values?.subAccountId
          : onlineData?.configuration?.ccavenue?.subAccountId ?? ""
      );
    } else {
      setRazorPayKey("");
      setRazorpayMerchant("");
      setRazorPaySecret("");
    }
  };

  useEffect(() => {
    active === "payment-settings" && handleOnlineTempleData();
    active === "temple-profile" && getTempleDetails();
    if (new URLSearchParams(router?.location?.search).get("tab")) {
      setActive(new URLSearchParams(router?.location?.search).get("tab"));
    }
  }, [active]);

  return (
    <div className="card-container">
      <Tabs
        type="card"
        activeKey={active}
        onChange={(e) => {
          setActive(e);
          router.navigate(`${tmsRouteMap.temple_settings}?tab=${e}`);
        }}
      >
        <TabPane tab={"Counter settings"} key={"counter-settings"}>
          <div style={{ background: "white" }}>
            <Row justify="start" gutter={[20, 20]}>
              {userDetails?.permissions.includes("SUPER_ADMIN") && (
                <Col span={6}>
                  <Row justify="start">Darshanam Print</Row>
                  <Row>
                    <Select
                      onChange={(e) => {
                        setDarshanamType(e);
                      }}
                      value={darshanamType}
                      style={{ width: "100%", textDecoration: "capitalise" }}
                    >
                      <Select.Option value={false}> Single </Select.Option>
                      <Select.Option value={true}> Multiple </Select.Option>
                    </Select>
                  </Row>
                </Col>
              )}
              <Col span={6}>
                <Row justify="start">Prasadam Inventory</Row>
                <Row>
                  <Select
                    onChange={(e) => {
                      setprasadamInventory(e);
                    }}
                    value={prasadamInventory}
                    style={{ width: "100%", textDecoration: "capitalise" }}
                  >
                    <Select.Option value={true}> Enabled </Select.Option>
                    <Select.Option value={false}> Disabled </Select.Option>
                  </Select>
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Accommodation Biometric</Row>
                <Row>
                  <Select
                    onChange={(e) => {
                      setAccommodationFingerScan(e);
                    }}
                    value={AccommodationFingerScan}
                    style={{ width: "100%", textDecoration: "capitalise" }}
                  >
                    <Select.Option value={true}> Enabled </Select.Option>
                    <Select.Option value={false}> Disabled </Select.Option>
                  </Select>
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Kalyanakatta Performer </Row>
                <Row>
                  <Select
                    onChange={(e) => {
                      setKalyanakattaPerformer(e);
                    }}
                    value={kalyanakattaPerformer}
                    style={{ width: "100%", textDecoration: "capitalise" }}
                  >
                    <Select.Option value={true}> Enabled </Select.Option>
                    <Select.Option value={false}> Disabled </Select.Option>
                  </Select>
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Seva Split Ticket</Row>
                <Row>
                  <Select
                    onChange={(e) => {
                      setSevaSplitTicket(e);
                    }}
                    value={sevaSplitTicket}
                    style={{ width: "100%", textDecoration: "capitalise" }}
                  >
                    <Select.Option value={true}> Enabled </Select.Option>
                    <Select.Option value={false}> Disabled </Select.Option>
                  </Select>
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Donation Bond</Row>
                <Row>
                  <Select
                    onChange={(e) => {
                      setEnableDonationBond(e);
                    }}
                    value={enableDonationBond}
                    style={{ width: "100%", textDecoration: "capitalise" }}
                  >
                    <Select.Option value={true}> Enabled </Select.Option>
                    <Select.Option value={false}> Disabled </Select.Option>
                  </Select>
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Darshanam Reprint Number</Row>
                <Row>
                  <TmsPhoneNumberBasic
                    value={darshnamReprintOtp}
                    setValue={setDarshanamReprintOtp}
                    placeholder="Please Enter Seva OTP Number"
                    disable={
                      templeDetails?.configuration
                        ?.counterDarshanamReprintOTPPhone
                        ? userDetails?.permissions?.includes("SUPER_ADMIN")
                          ? false
                          : true
                        : false
                    }
                  />{" "}
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Accommodation Reprint Number</Row>
                <Row>
                  <TmsPhoneNumberBasic
                    value={accommodationReprintOTP}
                    setValue={setAccommodationReprintOTP}
                    placeholder="Please Enter Seva OTP Number"
                    disable={
                      templeDetails?.configuration
                        ?.counterAccommodationReprintOTPPhone
                        ? userDetails?.permissions?.includes("SUPER_ADMIN")
                          ? false
                          : true
                        : false
                    }
                  />{" "}
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Acc Force Checkout Number</Row>
                <Row>
                  <TmsPhoneNumberBasic
                    value={accommodationForceOTP}
                    setValue={setAccommodationForceOTP}
                    placeholder="Please Enter Accommodation Force OTP Number"
                    disable={
                      templeDetails?.configuration
                        ?.counterAccommodationReprintOTPPhone
                        ? userDetails?.permissions?.includes("SUPER_ADMIN")
                          ? false
                          : true
                        : false
                    }
                  />{" "}
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Seva Reprint Number</Row>
                <Row>
                  <TmsPhoneNumberBasic
                    value={sevaReprintOtp}
                    setValue={setSevaReprintOtp}
                    placeholder="Please Enter Seva OTP Number"
                    disable={
                      templeDetails?.configuration?.counterSevaReprintOTPPhone
                        ? userDetails?.permissions?.includes("SUPER_ADMIN")
                          ? false
                          : true
                        : false
                    }
                  />{" "}
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Prasadam Reprint Number</Row>
                <Row>
                  <TmsPhoneNumberBasic
                    value={prasadamReprintOtp}
                    setValue={setPrasadamReprintOtp}
                    placeholder="Please Enter Prasadam OTP Number"
                    disable={
                      templeDetails?.configuration
                        ?.counterPrasadamReprintOTPPhone
                        ? userDetails?.permissions?.includes("SUPER_ADMIN")
                          ? false
                          : true
                        : false
                    }
                  />{" "}
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Donation Reprint Number</Row>
                <Row>
                  <TmsPhoneNumberBasic
                    value={donationReprintOtp}
                    setValue={setDonationReprintOtp}
                    placeholder="Please Enter Donation OTP Number"
                    disable={
                      templeDetails?.configuration
                        ?.counterDonationReprintOTPPhone
                        ? userDetails?.permissions?.includes("SUPER_ADMIN")
                          ? false
                          : true
                        : false
                    }
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Tollgate Reprint Number</Row>
                <Row>
                  <TmsPhoneNumberBasic
                    value={tollgateReprintOtp}
                    setValue={setTollgateReprintOtp}
                    placeholder="Please Enter Tollgate OTP Number"
                    disable={
                      templeDetails?.configuration
                        ?.counterTollgateReprintOTPPhone
                        ? userDetails?.permissions?.includes("SUPER_ADMIN")
                          ? false
                          : true
                        : false
                    }
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Thulabharam Reprint Number</Row>
                <Row>
                  <TmsPhoneNumberBasic
                    value={thulabharamReprintOtp}
                    setValue={setThulabharamReprintOTP}
                    placeholder="Please Enter Thulabharam OTP Number"
                    disable={
                      templeDetails?.configuration
                        ?.counterThulabharamReprintOTPPhone
                        ? userDetails?.permissions?.includes("SUPER_ADMIN")
                          ? false
                          : true
                        : false
                    }
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Kalyanakatta Reprint Number</Row>
                <Row>
                  <TmsPhoneNumberBasic
                    value={kalyanakattaReprintOtp}
                    setValue={setKalyanakattaReprintOTP}
                    placeholder="Please Enter Kalyanakatta OTP Number"
                    disable={
                      templeDetails?.configuration
                        ?.counterKalyanakattaReprintOTPPhone
                        ? userDetails?.permissions?.includes("SUPER_ADMIN")
                          ? false
                          : true
                        : false
                    }
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">Darshanam Max Tickets Per Booking</Row>
                <Row>
                  <Input
                    maxLength={220}
                    type="number"
                    value={darTicketCount}
                    onChange={(e) => {
                      if (e.target.value > 19) {
                        return;
                      }
                      setDarTicketCount(e.target.value);
                    }}
                  />
                </Row>
              </Col>
            </Row>
            <br />
            <br />
            <TmsCheckBoxList
              data={productsData}
              selectedData={userProductsData}
              name="Performance Scan"
              updateItems={updateSelectedProducts}
              gutter={[20, 20]}
              style={{ width: "100%", padding: "10px 0px", textAlign: "left" }}
            />
            <Row justify="center" gutter={[20, 20]}>
              <Col span={4}>
                <Button
                  loading={loading}
                  type="primary"
                  style={{ width: "100%", marginTop: "30px" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </TabPane>
        {userDetails?.permissions.includes("SUPER_ADMIN") && (
          <TabPane tab={"Service settings"} key={"service-settings"}>
            <Row style={{ background: "white", width: "100%" }}>
              <div style={{ width: "50%" }}>
                <TmsTable
                  dataSource={services?.slice(0, 12)}
                  columns={serviceCol}
                  pagination={false}
                />
              </div>
              <div style={{ width: "50%" }}>
                <TmsTable
                  dataSource={services?.slice(12, services?.length)}
                  columns={serviceCol}
                  pagination={false}
                />
              </div>
            </Row>
            <Row justify="center" gutter={[20, 20]}>
              <Col span={4}>
                <Button
                  loading={loading}
                  type="primary"
                  style={{ width: "100%", marginTop: "30px" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </TabPane>
        )}
        <TabPane tab={"Payment settings"} key={"payment-settings"}>
          <h3>Payment settings</h3>
          <Row justify="start" gutter={[20, 20]}>
            <Col span={6}>
              <Row justify="start">Payment Gateway</Row>
              <Row>
                <Select
                  className="formInput"
                  onChange={(e) => {
                    setPaymentGateway(e);
                    resetValues(e);
                  }}
                  value={payementGateway}
                  defaultValue={payementGateway}
                >
                  <Select.Option value="ccavenue">CC Avenue</Select.Option>
                  <Select.Option value="razorpay">Razorpay</Select.Option>
                </Select>
              </Row>
            </Col>
          </Row>
          <br />
          {payementGateway === "" ? (
            ""
          ) : (
            <Row justify="start" gutter={[20, 20]}>
              <Col span={6}>
                <Row justify="start">
                  {payementGateway === "razorpay"
                    ? "Razorpay Key"
                    : "CC Avenue Access Code"}{" "}
                </Row>
                <Row>
                  <Input
                    maxLength={220}
                    placeholder={
                      payementGateway === "razorpay"
                        ? "Razorpay Key"
                        : "CC Avenue Access Code"
                    }
                    value={razorpayKey}
                    onChange={(e) => {
                      setRazorPayKey(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row justify="start">
                  {payementGateway === "razorpay"
                    ? "Razorpay Merchant ID"
                    : "CC Avenue Merchant ID"}{" "}
                </Row>
                <Row>
                  <Input
                    maxLength={220}
                    placeholder={
                      payementGateway === "razorpay"
                        ? "Razorpay Merchant ID"
                        : "CC Avenue Merchant ID"
                    }
                    value={razorpayMerchant}
                    onChange={(e) => {
                      setRazorpayMerchant(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>

              <Col span={6}>
                <Row justify="start">
                  {payementGateway === "razorpay"
                    ? "Razorpay Secret"
                    : "CC avenue Working Key"}
                </Row>
                <Row>
                  <Input
                    maxLength={220}
                    placeholder={
                      payementGateway === "razorpay"
                        ? "Razorpay Secret"
                        : "CC avenue Working Key"
                    }
                    value={razorpaySecret}
                    onChange={(e) => {
                      setRazorPaySecret(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>
              {payementGateway !== "razorpay" && (
                <Col span={6}>
                  <Row justify="start">Sub Account ID (Optional)</Row>{" "}
                  <Row>
                    <Input
                      maxLength={220}
                      placeholder={"Sub Account ID"}
                      value={subAccountID}
                      onChange={(e) => {
                        setSubAccountID(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          )}
          <Divider />
          <h3>Online ticket/Handling Fee</h3>
          <Row>
            <Col span={6}>
              <Row>Handling Fee Type</Row>
              <Row>
                <Select
                  className="formInput"
                  onChange={(e) => {
                    setFeeType(e);
                  }}
                  value={feeType}
                  defaultValue={feeType}
                  // disabled={editFlag ? true : false}
                >
                  <Select.Option value="percentage">Percentage</Select.Option>
                  <Select.Option value="fixed">Fixed</Select.Option>
                </Select>
              </Row>
            </Col>
            {feeType && (
              <Col span={6}>
                <Row justify="start" style={{ textTransform: "capitalize" }}>
                  {feeType} Value
                </Row>
                <Row>
                  <Input
                    maxLength={220}
                    placeholder={`Enter ${feeType} Value`}
                    value={feeValue}
                    onChange={(e) => {
                      setFeeValue(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Row>
              </Col>
            )}
          </Row>
          {feeType && (
            <>
              <Divider />
              <h3>Fee conditions/limits</h3>
              <Row>
                <>
                  <Col span={6}>
                    <Row>Condition-1 Type</Row>
                    <Row>
                      <Select
                        className="formInput"
                        onChange={(e) => {
                          setConditionOne(e);
                        }}
                        value={conditionOne}
                        defaultValue={conditionOne}
                        // disabled={editFlag ? true : false}
                      >
                        <Select.Option value="greaterThan">
                          greater Than{`(>)`}
                        </Select.Option>
                        <Select.Option value="lessThan">
                          Less Than {`(<)`}
                        </Select.Option>
                        <Select.Option value="greaterThanOrEqual">
                          GreaterThan Or Equal{`(>=)`}
                        </Select.Option>
                        <Select.Option value="lessThanOrEqual ">
                          LessThan Or Equal {`(<=)`}{" "}
                        </Select.Option>
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row justify="start"> Condition One value</Row>
                    <Row>
                      <Input
                        maxLength={220}
                        placeholder={`Condition Value`}
                        value={conditionOneValue}
                        onChange={(e) => {
                          setConditionOneValue(e.target.value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Row>
                  </Col>
                </>
              </Row>
              <br />
              <Row>
                <>
                  <Col span={6}>
                    <Row>Condition-2 Type</Row>
                    <Row>
                      <Select
                        className="formInput"
                        onChange={(e) => {
                          setConditionTwo(e);
                        }}
                        value={conditionTwo}
                        defaultValue={conditionTwo}
                        // disabled={editFlag ? true : false}
                      >
                        <Select.Option value="greaterThan">
                          Greater Than{`(>)`}
                        </Select.Option>
                        <Select.Option value="lessThan">
                          Less Than {`(<)`}
                        </Select.Option>
                        <Select.Option value="greaterThanOrEqual">
                          GreaterThan Or Equal{`(>=)`}
                        </Select.Option>
                        <Select.Option value="lessThanOrEqual ">
                          LessThan Or Equal {`(<=)`}{" "}
                        </Select.Option>
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row justify="start">Condition One value</Row>
                    <Row>
                      <Input
                        maxLength={220}
                        placeholder={`Condition Value`}
                        value={conditionTwoValue}
                        onChange={(e) => {
                          setConditionTwoValue(e.target.value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Row>
                  </Col>
                </>
              </Row>{" "}
            </>
          )}
          <Row>
            {" "}
            <Col span={4}>
              <Button
                loading={loading}
                type="primary"
                style={{ width: "100%", marginTop: "22px" }}
                onClick={handleOnlineSubmit}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={"Temple Profile"} key={"temple-profile"}>
          <Row gutter={[20, 20]}>
            <Col style={{ textAlign: "left" }} span={6}>
              <lable>Idol Image</lable>
              <br />
              <TmsImageUpload
                className="w-100"
                fileList={fileList}
                setFileList={setFileList}
                router={router}
              />
            </Col>
            <Col style={{ textAlign: "left" }} span={6}>
              <lable>Temple Banner</lable>
              <br />
              <TmsImageUpload
                className="w-100"
                fileList={banner}
                setFileList={setBanner}
              />
            </Col>
            <Col style={{ textAlign: "left" }} span={6}>
              <lable>Ticket Header Image</lable>
              <br />
              <TmsImageUpload
                className="w-100"
                fileList={headerImg}
                setFileList={setHeaderImg}
              />
            </Col>
            <Col style={{ textAlign: "left" }} span={6}>
              <lable>Ticket Footer Image</lable>
              <br />
              <TmsImageUpload
                className="w-100"
                fileList={footerImg}
                setFileList={setFooterImg}
              />
            </Col>

            <Col span={6} style={{ textAlign: "left" }}>
              <lable>Temple Time</lable>
              <br />

              <Input
                maxLength={220}
                type="text"
                placeholder="Start Time - End TIme"
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
              />
            </Col>
            <Col span={6} style={{ textAlign: "left" }}>
              <lable>Devotee Domain Portal</lable>
              <br />
              <Input
                maxLength={220}
                type="text"
                placeholder="Domain"
                value={domain}
                onChange={(e) => {
                  setDomain(e.target.value);
                }}
              />
            </Col>
            <Col span={6} style={{ textAlign: "left" }}>
              <lable>Live Seva URL</lable>
              <br />
              <Input
                maxLength={220}
                type="text"
                placeholder="Live Seva URL"
                value={liveURL}
                onChange={(e) => {
                  setLiveURL(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <h3>Accommodation Restrictions</h3>
          <Divider />
          <Row gutter={[20, 20]} style={{ textAlign: "left" }}>
            <Col span={6}>
              Accommodation Per Aadhar Limit (Count)
              <InputNumber
                maxLength={220}
                defaultValue={accPerAadharLimit}
                value={accPerAadharLimit}
                min={0}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                style={{ width: "100%" }}
                placeholder={`Accommodation Per Aadhar Limit`}
                onChange={(e) => {
                  setAccPerAadharLimit(e);
                }}
              ></InputNumber>
            </Col>
            <Col span={6}>
              Accommodation Aadhar Validation (Days)
              <InputNumber
                maxLength={220}
                defaultValue={accPerAadharDays}
                value={accPerAadharDays}
                min={0}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                style={{ width: "100%" }}
                placeholder={`Accommodation Aadhar Validation`}
                onChange={(e) => {
                  setAccPerAadharDays(e);
                }}
              ></InputNumber>
            </Col>
          </Row>
          <br />
          <h3>Temple Address</h3>
          <Divider />
          <Row gutter={[20, 20]} style={{ textAlign: "left" }}>
            <Col span={6}>
              <label>Address</label>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter address"
                value={address}
                onKeyDown={(e) =>
                  ["#", "+"].includes(e.key) && e.preventDefault()
                }
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>City</label>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Village</label>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Village"
                value={village}
                onChange={(e) => {
                  setVillage(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Mandal</label>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Mandal"
                value={mandal}
                onChange={(e) => {
                  setMandal(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>District</label>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter District"
                value={district}
                onChange={(e) => {
                  setDistrict(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>State</label>
              <RegionDropdown
                className="CountryRegionDropDown"
                style={{
                  border: "1px solid #d9d9d9",
                  height: "32px",
                  fontSize: "14px",
                  padding: "0px 8px",
                  width: "100%",
                }}
                country={"India"}
                value={state}
                showDefaultOption={true}
                blankOptionLabel="Select a State"
                onChange={(val) => {
                  setState(val);
                }}
              />
            </Col>
            {/* <Col span={6}>
              <label>Country</label>
              {country}
              <CountryDropdown
                className="CountryRegionDropDown"
                style={{
                  border: "1px solid #d9d9d9",
                  height: "32px",
                  fontSize: "14px",
                  padding: "0px 8px",
                  width: "100%",
                }}
                value={country}
                onChange={(val) => {
                  setCountry(val);
                }}
              />
            </Col> */}
            <Col span={6}>
              <label>Pincode</label>
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                placeholder="Enter Pincode"
                value={zip}
                onChange={(e) => {
                  if (e.target.value.length > 6) {
                    return;
                  }
                  setZip(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Email</label>
              <Input
                type="email"
                placeholder="Enter Email ID"
                maxLength="30"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Col>
            <Col span={6}>
              <label>Contact</label>
              <Input
                size="medium"
                type="number"
                min="0"
                onWheel={(event) => event.currentTarget.blur()}
                maxLength={10}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                addonBefore={"+91"}
                placeholder="Please Enter Mobile Number"
                style={{ width: "100%" }}
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  }
                  setPhone(`${e.target.value}`);
                }}
              />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Button
              type="primary"
              style={{ width: "100%" }}
              loading={loading}
              onClick={() => handleTempleProfile()}
            >
              Submit
            </Button>
          </Row>
        </TabPane>
        <TabPane tab={"Institution creation"} key="Institution creation">
          <Row justify="space-between">
            <h2>Institution Creation</h2>
            <Button
              onClick={() => {
                setVisible(!visible);
              }}
            >
              Institution List
            </Button>
          </Row>
          <Divider style={{ marginTop: "5px" }} />
          <TmsInstitutionCreation />
          <TmsInstitutionList visible={visible} setVisible={setVisible} />
        </TabPane>
        <TabPane tab={"Booking Limiters"} key="Booking Limiters">
          <Row justify="space-between">
            <h2>Booking Limiters</h2>
            <Button
              onClick={() => {
                setLimitVisible(true);
              }}
            >
              Add New Limiter
            </Button>
          </Row>
          <Divider style={{ marginTop: "5px" }} />
          <TmsBookingLimiterModal
            visible={limitVisible}
            setVisible={setLimitVisible}
            setUpdateTable={setUpdateTable}
            setEditDetails={setEditDetails}
            editDetails={editDetails}
          />
          <TmsBookingLimiterTable
            updateTable={updateTable}
            setEditDetails={setEditDetails}
            setVisible={setLimitVisible}
          />
        </TabPane>
      </Tabs>{" "}
    </div>
  );
};
export default withRouter(TmsTempleSettings);
