import React, { useEffect, useState } from "react";
import TmsBatchSlots from "../../../../components/tms-customs/tms-batch-slots";
import TmsCalender from "../../../../components/tms-customs/tms-calender";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsDInnerPagesAll from "./tms-sm-darshanam-inner-pages/tms-d-inner-pages-all";
import TmsDInnerPagesAnd from "./tms-sm-darshanam-inner-pages/tms-d-inner-pages-and";
import TmsDInnerPagesDisableDarshanam from "./tms-sm-darshanam-inner-pages/tms-d-inner-pages-disabledarshanam";
import TmsDipPartners from "./tms-sm-darshanam-inner-pages/tms-dip-partners";
const TmsSmDarshanam = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_darshanam) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.smd_darshanam_add_new_darshanam)) {
      setActive("Add New Darshanam");
    } else if (location.includes(tmsRouteMap.smd_darshanam_manage_slots)) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.smd_darshanam_disable_dates)) {
      setActive("Darshanam Disable Dates");
      return;
    } else if (location.includes(tmsRouteMap.smd_darshanam_manage_counters)) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sms_darshanam_manage_partners)) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.smd_darshanam_manage_quota)) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sms_darshanam_quota_management)) {
      setActive("Batch Quota");
      return;
    }
  }, [router?.location?.pathname]);
  const location = router?.location?.pathname;
  const components = [
    {
      name: location.includes(tmsRouteMap.smd_darshanam_manage_counters)
        ? "(Slots)-Manage Counter"
        : location.includes(tmsRouteMap.smd_darshanam_manage_slots)
        ? "Manage Slots"
        : location.includes(tmsRouteMap.smd_darshanam_manage_quota)
        ? "Manage Online Quota"
        : location.includes(tmsRouteMap.sms_darshanam_manage_partners)
        ? "Darshanam - Manage Partners"
        : "All",
      Element: location?.includes(tmsRouteMap.smd_darshanam_manage_quota) ? (
        <TmsCalender />
      ) : location?.includes(tmsRouteMap.smd_darshanam_manage_counters) ? (
        <TmsCmsSlots type="darshanam-counters" />
      ) : location?.includes(tmsRouteMap.smd_darshanam_manage_slots) ? (
        <TmsCmsSlots type="darshanam-slots" />
      ) : location?.includes(tmsRouteMap.sms_darshanam_manage_partners) ? (
        <TmsDipPartners />
      ) : (
        <TmsDInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_darshanam,
    },

    {
      name: "Add New Darshanam",
      Element: <TmsDInnerPagesAnd />,
      key: "Add New Darshanam",
      route: tmsRouteMap.smd_darshanam_add_new_darshanam,
    },

    {
      name: "Darshanam Disable Dates",
      Element: <TmsDInnerPagesDisableDarshanam />,
      key: "Darshanam Disable Dates",
      route: tmsRouteMap.smd_darshanam_disable_dates,
    },
    {
      name: "Batch Quota",
      Element: <TmsBatchSlots type="Darshanam" />,
      key: "Batch Quota",
      route: tmsRouteMap.sms_darshanam_quota_management,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmDarshanam);
