import { Row } from "antd";
import React, { useEffect } from "react";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import "../../../../../../assets/css/tms-common/tms-common.scss";
import { FdrInstitutionSelect } from "../../../../../../components/tms-customs/rents-and-leases/index";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import { createAccountCatStates } from "../../../../../../constants/states-data.data";
import useFdrStore from "../../../../../../zustand/fdr.store";
import TmsInput from "../../../../../../components/tms-customs/tms-input";
const TmsFdrAccountCreation = ({ router, setVisible }) => {
  const {
    accountCategoryName,
    setAccountCategoryName,
    setSubmitLoading,
    submitLoading,
    fetchInstitutionList,
    handleCreateAccountCategory,
    clearStates,
  } = useFdrStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createAccountCatStates, ["institutionData"]);
    };
  }, []);

  return (
    <div>
      <Row gutter={[20, 30]} justify="center" style={{ marginBottom: "15px" }}>
        <FdrInstitutionSelect span={8} />
        <TmsInput
          type="text"
          label={"Enter Account Category Name"}
          value={accountCategoryName}
          setValue={setAccountCategoryName}
          span={8}
          placeholder={"Enter Account Category Name"}
        />
      </Row>

      <TmsCancelSubmitButtons
        loading={submitLoading}
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(createAccountCatStates, ["institutionData"]);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleCreateAccountCategory(router);
        }}
      />
    </div>
  );
};

export default withRouter(TmsFdrAccountCreation);
