import TmsAccommodationService from "../services/tms-accommodation.service";
import TmsCounterService from "../services/tms-counter.service";
import TmsDarshanamService from "../services/tms-darshanam/tms-darshanam.service";
import TmsDonationService from "../services/tms-donation.service";
import TmsKalayanakattaService from "../services/tms-kalayanakatta.service";
import TmsMiscService from "../services/tms-misc.service";
import TmsPrasadamService from "../services/tms-prasadam.service";
import TmsProductsService from "../services/tms-products.service";
import TmsSevasService from "../services/tms-sevas.service";
import TmsThulabharamService from "../services/tms-thulabharam.service";
import TmsTollGateService from "../services/tms-tollgate.service";
import TmsEntryScanService from "../services/tms-entry-scan.service";

import tmsRouteMap from "./tms-route.map";
import TmsBusService from "../services/tms-bus-service";
import TmsPublicationService from "../services/tms-publications.service";

const CSData = {
  ["darshanam-slots"]: {
    service: TmsDarshanamService,
    fecthService: TmsDarshanamService.getDarshanamSlots,
    prevRoute: tmsRouteMap.serviceManagement_darshanam,
    buttonLabel: "Add New Slot",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit Slot Details - Darshanam ID: ${editData?.productId}`
        : "Create Slot";
    },
    editService: TmsDarshanamService.editDarshanamSlots,
    createService: TmsDarshanamService.createDarshanamSlots,
    deleteService: TmsDarshanamService.deleteDarshanamSlots,
    editNotificationMessage: "Slot Updated Succesfully",
    createNotificationMessage: "Slot created Succesfully",
    elements: ["description", "fromTo", "maxBooking", "checkbox"],
  },
  ["darshanam-counters"]: {
    service: TmsDarshanamService,
    fecthService: TmsDarshanamService.getDarshanamSlotCounter,
    prevRoute: tmsRouteMap.serviceManagement_darshanam,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Slot ID: ${editData?.productSlotId}`
        : "Create Shift";
    },
    editService: TmsDarshanamService.editDarshanamSlotCounter,
    createService: TmsDarshanamService.createDarshanamSlotCounter,
    deleteService: TmsDarshanamService.deleteDarshanamSlotCounter,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["maxBooking", "select-counter"],
  },
  ["seva-slots"]: {
    service: TmsSevasService,
    fecthService: TmsSevasService.getSevaSlots,
    prevRoute: tmsRouteMap.serviceManagement_sevas,
    buttonLabel: "Add New Slot",
    modalLabel: (editFlag, editData) => {
      // console.log(editData);
      return editFlag
        ? `Edit Slot Details - Seva ID: ${editData?.productId}`
        : "Create Slot";
    },
    editService: TmsSevasService.editSevaSlots,
    createService: TmsSevasService.createSevaSlots,
    deleteService: TmsSevasService.deleteSevaSlots,
    editNotificationMessage: "Slot Updated Succesfully",
    createNotificationMessage: "Slot created Succesfully",
    elements: ["description", "fromTo", "maxBooking", "checkbox"],
  },
  ["seva-counters"]: {
    service: TmsSevasService,
    fecthService: TmsSevasService.getSevaSlotCounter,
    prevRoute: tmsRouteMap.serviceManagement_sevas,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Slot ID: ${editData?.productSlotId}`
        : "Create Shift";
    },
    editService: TmsSevasService.editSevaSlotCounter,
    createService: TmsSevasService.createSevaSlotCounter,
    deleteService: TmsSevasService.deleteSevaSlotCounter,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["maxBooking", "select-counter"],
  },
  ["donation-counters"]: {
    service: TmsDonationService,
    fecthService: TmsDonationService.getDonationCounters,
    prevRoute: tmsRouteMap.serviceManagement_donation,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create Shift";
    },
    editService: TmsDonationService.editDonationCounters,
    createService: TmsDonationService.createDonationCounters,
    deleteService: TmsDonationService.deleteDonationCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["prasadam-counters"]: {
    service: TmsPrasadamService,
    fecthService: TmsPrasadamService.getPrasadamCounters,
    prevRoute: tmsRouteMap.serviceManagement_prasadam,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create Shift";
    },
    editService: TmsPrasadamService.editPrasadamCounters,
    createService: TmsPrasadamService.createPrasadamCounters,
    deleteService: TmsPrasadamService.deletePrasadamCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["thulabharam-counters"]: {
    service: TmsThulabharamService,
    fecthService: TmsThulabharamService.getThulabharamCounters,
    prevRoute: tmsRouteMap.serviceManagement_thulabharam,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create Shift";
    },
    editService: TmsThulabharamService.editThulabharamCounters,
    createService: TmsThulabharamService.createThulabharamCounters,
    deleteService: TmsThulabharamService.deleteThulabharamCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["tollgate-counters"]: {
    service: TmsTollGateService,
    fecthService: TmsTollGateService.getTollGateCounters,
    prevRoute: tmsRouteMap.serviceManagement_tollgate,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create Shift";
    },
    editService: TmsTollGateService.editTollGateCounters,
    createService: TmsTollGateService.createTollGateCounters,
    deleteService: TmsTollGateService.deleteTollGateCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["entry-scan-counters"]: {
    service: TmsEntryScanService,
    fecthService: TmsEntryScanService.getEntryScanCounters,
    prevRoute: tmsRouteMap.serviceManagement_entryScan,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create Shift";
    },
    editService: TmsEntryScanService.editEntryScanCounters,
    createService: TmsEntryScanService.createEntryScanCounters,
    deleteService: TmsEntryScanService.deleteEntryScanCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["manage-acc-pos"]: {
    service: TmsAccommodationService,
    fecthService: TmsAccommodationService.getBlockCounters,
    prevRoute: tmsRouteMap.serviceManagement_accommodation,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Assign POS";
    },
    editService: TmsAccommodationService.editBlockCounters,
    createService: TmsAccommodationService.createBlockCounters,
    deleteService: TmsAccommodationService.deleteBlockCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["kalyankatta-tonsure"]: {
    service: TmsKalayanakattaService,
    fecthService: TmsKalayanakattaService.getKalayanakattaCounters,
    prevRoute: tmsRouteMap.serviceManagement_prasadam,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create Tonsure (POS)";
    },
    editService: TmsKalayanakattaService.editKalyanakattaCounters,
    createService: TmsKalayanakattaService.createKalyanakattaCounters,
    deleteService: TmsKalayanakattaService.deleteKalyanakattaCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["products-counters"]: {
    service: TmsProductsService,
    fecthService: TmsProductsService.getProductCounters,
    prevRoute: tmsRouteMap.serviceManagement_products,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create Shift";
    },
    editService: TmsProductsService.editProductCounters,
    createService: TmsProductsService.createProductCounters,
    deleteService: TmsProductsService.deleteProductCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["publication-counters"]: {
    service: TmsPublicationService,
    fecthService: TmsPublicationService.getPublicationCounters,
    prevRoute: tmsRouteMap.serviceManagement_publications,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create Shift";
    },
    editService: TmsPublicationService.editPublicationCounters,
    createService: TmsPublicationService.createPublicationCounters,
    deleteService: TmsPublicationService.deletePublicationCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["misc-counters"]: {
    service: TmsMiscService,
    fecthService: TmsMiscService.getMiscCounters,
    prevRoute: tmsRouteMap.serviceManagement_misc,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create Shift";
    },
    editService: TmsMiscService.editMiscCounters,
    createService: TmsMiscService.createMiscCounters,
    deleteService: TmsMiscService.deleteMiscCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
  ["bus-counters"]: {
    service: TmsBusService,
    fecthService: TmsBusService.getBusCounter,
    prevRoute: tmsRouteMap.sm_bus,
    buttonLabel: "Add New POS",
    modalLabel: (editFlag, editData) => {
      return editFlag
        ? `Edit POS Details - Product: (${editData?.product?.name})`
        : "Create POS";
    },
    editService: TmsBusService.editBusCounters,
    createService: TmsBusService.createBusCounters,
    deleteService: TmsBusService.deleteBusCounters,
    editNotificationMessage: "POS Updated Succesfully",
    createNotificationMessage: "POS created Succesfully",
    fetchModalService: TmsCounterService.getAllCounter,
    elements: ["select-counter"],
  },
};
export default CSData;
