import moment from "moment";
import Axios from "../utils/tms-axios/tms-axios";

const TmsFootfallService = {
  getFootfall: async (data) => {
    const resp = await Axios.get(
      `v1/temple-visitor-count/visitors-count/paginated/${data?.type}?fromDate=${data?.fromDate}&toDate=${data?.toDate}&page=${data?.current}&limit=${data?.limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  setFootfallOffline: async (currentDate, count) => {
    const resp = await Axios.post(
      `v1/temple-visitor-count/visitors-count/offline`,
      {
        date: moment(currentDate).format("YYYY-MM-DD"),
        count: Number(count),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getFootfallCurrentDate: async (type) => {
    const resp = await Axios.get(
      `v1/temple-visitor-count/visitors-count/${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
};

export default TmsFootfallService;
