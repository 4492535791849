import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import BuildingArrear from "./tms-sm-ral-buildings-inner-pages/index-pages/building-arrear";
import BuildingEncroachment from "./tms-sm-ral-buildings-inner-pages/index-pages/building-encroachment";
import BuildingShop from "./tms-sm-ral-buildings-inner-pages/index-pages/building-shop";
import BuildingBlock from "./tms-sm-ral-buildings-inner-pages/index-pages/building-block";
import BuildingIndex from "./tms-sm-ral-buildings-inner-pages/index-pages/building-index";
import BuildingLease from "./tms-sm-ral-buildings-inner-pages/index-pages/building-lease";

const TmsSmRalBuildings = ({ router }) => {
  const [active, setActive] = useState("Building Creation");
  useEffect(() => {
    let location = router?.location?.pathname;
    if (
      location === tmsRouteMap.sm_ral_buildings_creation ||
      location === tmsRouteMap.sm_ral_buildings
    ) {
      setActive("Building Creation");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_ral_buildings_blocks_creation)
    ) {
      setActive("Block Creation");
      return;
    } else if (location.includes(tmsRouteMap.sm_ral_buildings_shops_creation)) {
      setActive("Shop Creation");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_ral_buildings_buildings_lease_creation)
    ) {
      setActive("Lease Creation");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_ral_buildings_arears_creation)
    ) {
      setActive("Arears Creation");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_ral_buildings_enchroament_creation)
    ) {
      setActive("Encroachment");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Building Creation",
      Element: <BuildingIndex />,
      key: "Building Creation",
      route: tmsRouteMap.sm_ral_buildings_creation,
    },
    {
      name: "Block Creation",
      Element: <BuildingBlock />,
      key: "Block Creation",
      route: tmsRouteMap.sm_ral_buildings_blocks_creation,
    },
    {
      name: "Shop Creation",
      Element: <BuildingShop />,
      key: "Shop Creation",
      route: tmsRouteMap.sm_ral_buildings_shops_creation,
    },
    {
      name: "Lease Creation",
      Element: <BuildingLease />,
      key: "Lease Creation",
      route: tmsRouteMap.sm_ral_buildings_buildings_lease_creation,
    },
    {
      name: "Arears Creation",
      Element: <BuildingArrear />,
      key: "Arears Creation",
      route: tmsRouteMap.sm_ral_buildings_arears_creation,
    },
    {
      name: "Encroachment",
      Element: <BuildingEncroachment />,
      key: "Encroachment",
      route: tmsRouteMap.sm_ral_buildings_enchroament_creation,
    },
  ];

  return (
    <TmsTabsLayout
      components={components}
      active={active}
      setActive={setActive}
    />
  );
};
export default withRouter(TmsSmRalBuildings);
