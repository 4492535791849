import React, { useEffect, useState } from "react";
import TmsTempleService from "../../services/tms-temple.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../tms-customs/tms-table";
import { Badge } from "antd";

const TmsBookingLimiterTable = ({
  setVisible,
  setEditDetails,
  updateTable,
  router,
}) => {
  const [loading, setLoading] = useState(false);

  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(20);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [data, setData] = useState([]);

  const fetchLimitersData = async () => {
    setLoading(true);
    try {
      const data = await TmsTempleService.getCreatedBookingLimits(
        tableCurrentPage,
        tablePageSize
      );
      setData(data.items);
      setTabelTotalSize(data.meta.totalItems);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };
  useEffect(() => {
    fetchLimitersData();
  }, [tablePageSize, tableCurrentPage, updateTable]);

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },

    {
      title: "Service",
      dataIndex: "productType",
      key: "productType",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },

    {
      title: "Limit",
      dataIndex: "limit",
      key: "limit",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      render: (text) => {
        return !text ? <Badge color="red" /> : <Badge color="green" />;
      },
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <a
            onClick={() => {
              setEditDetails(record);
              setVisible(true);
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];
  return (
    <TmsTable
      columns={columns}
      dataSource={data}
      loading={loading}
      handleTableChange={handleTableChange}
      pagination={{
        total: tableTotalSize,
        current: tableCurrentPage,
        pageSize: tablePageSize,
      }}
    />
  );
};

export default TmsBookingLimiterTable;
