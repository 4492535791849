import { Col, Input, Row } from "antd";
import React, { memo } from "react";

const TmsInput = ({
  type,
  label,
  value,
  setValue,
  span,
  placeholder,
  disabled,
}) => {
  return (
    <>
      <Col span={span}>
        <Row className={"t-left"}>{label}</Row>
        <Input
          maxLength={220}
          type={type === "number" ? "number" : "text"}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            if (e.target.value === "") {
              setValue("");
              return;
            }
            if (type === "text" && /[a-zA-Z ]+$/.test(e.target.value)) {
              setValue(e.target.value);
            } else if (type === "number" && /^\d*$/.test(e.target.value)) {
              setValue(e.target.value);
            } else if (
              type === "text-number" &&
              /[a-zA-Z0-9]$/.test(e.target.value)
            ) {
              setValue(e.target.value);
            } else if (type === "all" || !type) {
              setValue(e.target.value);
            }
          }}
          disabled={disabled ? true : false}
        />{" "}
      </Col>
    </>
  );
};

export default memo(TmsInput);
