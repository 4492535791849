import React, { useState, useEffect } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import TmsAccInnerPagesManageBlocksAll from "./tms-mrc-all";
import TmsAccInnerPagesManageBlockADD from "./tms-mrc-add-new";
import TmsSmAccIpMcrManageRoom from "./tms-inner-pages/tms-mcr-mr-room";
import { Route, Routes } from "react-router";
import tmsRouteMap from "../../../../../../constants/tms-route.map";

import { Tabs } from "antd";
import TmsCalender from "../../../../../../components/tms-customs/tms-calender";
const TmsSmAccManageBlocks = ({ router }) => {
  const [active, setActive] = useState("Manage Room Category");
  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.sm_acc_mrc_manage_quota) {
      setActive("Manage Quota");
      return;
    } else {
      setActive("Manage Room Category");
    }
  }, [router?.location?.pathname]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="card-container">
        <Routes>
          <Route
            exact
            path={tmsRouteMap.sm_acc_mrc_manage_room?.replace(
              "/service-management/accommodation/manage-room-categories",
              ""
            )}
            element={<TmsSmAccIpMcrManageRoom />}
          />
          <Route
            path="*"
            element={
              <Tabs
                type="card"
                activeKey={active}
                onChange={(e) => {
                  setActive(e);
                  if (e !== "Manage Quota") {
                    router.navigate(
                      `${
                        tmsRouteMap.sm_acc_manage_room_categories
                      }?id=${new URLSearchParams(router.location.search).get(
                        "id"
                      )}&name=${new URLSearchParams(router.location.search).get(
                        "name"
                      )}`
                    );
                  }
                }}
              >
                <Tabs.TabPane
                  tab={"Manage Room Category"}
                  key={"Manage Room Category"}
                >
                  <TmsAccInnerPagesManageBlocksAll active={active} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Add New Category"} key={"Add New Category"}>
                  <TmsAccInnerPagesManageBlockADD active={active} />
                </Tabs.TabPane>

                {router.location?.pathname.includes(
                  tmsRouteMap.sm_acc_mrc_manage_quota
                ) && (
                  <Tabs.TabPane tab={"Manage Quota"} key={"Manage Quota"}>
                    <TmsCalender active={active} />
                  </Tabs.TabPane>
                )}
              </Tabs>
            }
          />
        </Routes>
      </div>
    </div>
  );
};
export default withRouter(TmsSmAccManageBlocks);
