import Axios from "../utils/tms-axios/tms-axios";
const TmsContactUsService = {
  getContactUsData: async (page, limit) => {
    const resp = await Axios.get(`contact-us/all?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};
export default TmsContactUsService;
