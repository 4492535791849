import { Button, Col, Row, Tag } from "antd";
import React from "react";
import TmsTable from "../../tms-customs/tms-table";
import { FormatNumberAllCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
import { EyeOutlined } from "@ant-design/icons";
import TmsModal from "../../tms-customs/tms-modal";
import { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import TmsParakamaniTable from "./tms-parakamani-table";

const TmsParakamaniModal = ({ recordData, loading }) => {
  const personsColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Designation", dataIndex: "designation", key: "designation" },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => {
        return <>{text ? text : "-"}</>;
      },
    },
  ];
  // const [image, setImage] = useState([]);
  // const [modalVisible, setModalVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [gallery, setGallery] = useState(false);
  const [selectImg, setSelectImg] = useState("");
  console.log(recordData);
  const currecyColumns = (country) => {
    return [
      {
        title: "Currency Type",
        dataIndex: "currencyType",
        key: "currencyType",
      },
      {
        title: "",
        dataIndex: "validCurrency",
        key: "validCurrency",
        render: (text) => {
          return text ? (
            <Tag color="green">Valid</Tag>
          ) : (
            <Tag color="red">Invalid</Tag>
          );
        },
      },
      {
        title: "Denomination",
        dataIndex: "denomination",
        key: "denomination",
        render: (text) => {
          return <>{FormatNumberAllCurrency(Number(text), country)}</>;
        },
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text) => {
          return <>{FormatNumberAllCurrency(Number(text), country)}</>;
        },
      },
    ];
  };

  const jewelleryColumns = [
    {
      title: "Type",
      dataIndex: "jewelleryType",
      key: "jewelleryType",
    },
    // {
    //   title: "Name",
    //   dataIndex: "itemName",
    //   key: "itemName",
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Weight (kg.gm.mg)",
      dataIndex: "weight",
      key: "weight",
      render: (text) => {
        return <>{text}</>;
      },
    },

    {
      title: "Images",
      render: (text, record) => {
        return (
          <>
            {record?.jewelleryImage?.length > 0 ? (
              <a
                onClick={() => {
                  setImages(record?.jewelleryImage);
                  setSelectImg(record?.jewelleryImage[0]);
                  setGallery(true);
                }}
              >
                {" "}
                <EyeOutlined />
              </a>
            ) : (
              <>No photos</>
            )}
          </>
        );
      },
    },
  ];
  const downLoadPDF = () => {
    const input = document.getElementById("pdf");
    const pdf = new jsPDF({ orientation: "potrait" });

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Calculate the number of pages needed
      const totalPages = Math.ceil(
        pdfHeight / pdf.internal.pageSize.getHeight()
      );
      console.log(totalPages, pdfHeight / pdf.internal.pageSize.getHeight());
      // Add each page to the PDF
      for (let i = 0; i < totalPages; i++) {
        const startY = i * pdf.internal.pageSize.getHeight();
        if (i > 0) {
          pdf.addPage();
        }
        pdf.addImage(
          imgData,
          "PNG",
          0,
          -startY,
          pdfWidth,
          pdfHeight,
          undefined,
          "FAST"
        );
      }

      pdf.save(
        `Hundi ${moment(recordData?.parakamaniDate).format(
          "DD-MM-YYYY"
        )} report.pdf`
      );
    });
  };

  // const downloadImages = () => {
  //   images?.forEach((item, index) => {
  //     fetch(item)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const url = window.URL.createObjectURL(blob);
  //         const anchor = document.createElement("a");
  //         anchor.href = url;
  //         anchor.download = `image${index + 1}.jpg`;
  //         anchor.target = "_blank";

  //         document.body.appendChild(anchor);
  //         anchor.click();
  //         document.body.removeChild(anchor);

  //         window.URL.revokeObjectURL(url);
  //       })
  //       .catch((error) => console.error("Error downloading image:", error));
  //   });
  // };

  return (
    <Row>
      <Row
        style={{
          display: "flex",
          marginBottom: "20px",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          style={{ marginRight: "20px" }}
          onClick={() => {
            downLoadPDF();
          }}
          type="primary"
        >
          Download
        </Button>

        <Button
          disabled={
            !recordData?.hundiCountingImages ||
            recordData?.hundiCountingImages?.length === 0
          }
          onClick={() => {
            if (
              !recordData?.hundiCountingImages ||
              recordData?.hundiCountingImages?.length === 0
            ) {
              return;
            } else {
              setImages(recordData?.hundiCountingImages);
              setSelectImg(recordData?.hundiCountingImages[0]);
              setGallery(true);
            }
          }}
        >
          {!recordData?.hundiCountingImages ||
          recordData?.hundiCountingImages?.length === 0
            ? "No Counting Images"
            : "View Counting Images"}
        </Button>
      </Row>
      <Row id="pdf" className="printPdf">
        <Row style={{ width: "100%" }}>
          <TmsParakamaniTable
            dataSource={[recordData]}
            flag={false}
            pagination={false}
            scroll={false}
          />
        </Row>
        <Row style={{ width: "100%" }}>
          <TmsTable
            style={{ width: "100%" }}
            columns={personsColumns}
            dataSource={recordData?.personsPresent}
            loading={loading}
            pagination={false}
          ></TmsTable>
        </Row>

        <Row gutter={[20, 20]} style={{ width: "100%", marginTop: "10px" }}>
          {recordData?.hundiCurrencyDetails.map((item, i) => {
            return (
              <Col
                key={i}
                style={{
                  width:
                    recordData?.hundiCurrencyDetails?.length === 1
                      ? "100%"
                      : "50%",
                }}
              >
                <Row style={{ marginBottom: "10px" }}>
                  <b style={{ marginLeft: "8px" }}>{item?.country}</b>
                </Row>
                <TmsTable
                  columns={currecyColumns(item?.country)}
                  dataSource={item?.hundiCurrency}
                  pagination={false}
                />
              </Col>
            );
          })}
        </Row>
        <Row style={{ width: "100%" }}>
          <Row style={{ margin: "10px 0px" }}>
            <b style={{ marginLeft: "8px" }}>Jewellery</b>
          </Row>
          <TmsTable
            columns={jewelleryColumns}
            dataSource={recordData?.hundiJewellery}
            pagination={false}
          />
        </Row>
      </Row>
      {/* <TmsModal
        centered={true}
        visible={modalVisible}
        closable={true}
        onCancel={() => {
          setImage([]);
          setModalVisible(false);
        }}
      >
        <br />
        <img style={{ width: "350px" }} src={image[0]} />
      </TmsModal> */}
      <TmsModal
        centered={true}
        visible={gallery}
        closable={true}
        width={"1200px"}
        onCancel={() => {
          setImages([]);
          setSelectImg("");
          setGallery(false);
        }}
        className={"gallery"}
      >
        <Row style={{ width: "100%", height: "600px" }}>
          <Col
            style={{
              width: "60%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRight: "1px solid #eaeaea",
            }}
          >
            <img style={{ width: "95%" }} src={selectImg} />
          </Col>
          <Col
            style={{
              width: "40%",
              height: "100%",
              overflow: "auto",
            }}
          >
            {/* <Row
              style={{
                height: "10%",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                style={{ borderRadius: "20px" }}
                onClick={downloadImages}
              >
                Download Images
              </Button>
            </Row> */}
            <Row
              style={{
                width: "100%",
                alignContent: "flex-start",
                overflow: "auto",
                height: "90%",
                paddingLeft: "10px",
                paddingTop: "10px",
              }}
            >
              {images?.map((item, i) => {
                return (
                  <>
                    <Col
                      key={i}
                      className={`galleryCol ${
                        selectImg === item ? "activeBorder" : ""
                      }`}
                      onClick={() => {
                        setSelectImg(item);
                      }}
                      style={{ backgroundImage: `url(${item})` }}
                    >
                      {/* <img style={{ width: "100%" }} src={item} /> */}
                    </Col>
                  </>
                );
              })}
            </Row>
          </Col>
        </Row>
      </TmsModal>
    </Row>
  );
};

export default TmsParakamaniModal;
