import React, { useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportsParakamaniReports from "./tms-reports-parakamani-reports";

const TmsReportParakamani = () => {
  const [active, setActive] = useState("Parakamani Report");

  const components = [
    {
      name: "Parakamani Report",
      Element: <TmsReportsParakamaniReports />,
      key: "Parakamani Report",
      route: tmsRouteMap.reports_misc,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportParakamani);
