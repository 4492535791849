import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsKalayanakattaService = {
  getKalayanakatta: async (page, limit) => {
    const resp = await Axios.get(
      `v1/kalyanakatta/all?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createKalayanakatta: async (body) => {
    const resp = await Axios.post(`v1/kalyanakatta/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteKalyanakatta: async (productId) => {
    const resp = await Axios.delete(`v1/kalyanakatta/delete/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewKalyanakatta: async (productId) => {
    const resp = await Axios.get(`v1/kalyanakatta/by-id/${productId}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  editKalyanakatta: async (productId, body) => {
    const resp = await Axios.put(`v1/kalyanakatta/update/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  updateTerms: async (productId, termsData, onlineTerms, ticketType) => {
    const resp = await Axios.patch(
      `v1/kalyanakatta/metadata/${productId}`,
      {
        termsAndConditions: termsData,
        onlineTermsAndConditions: onlineTerms,
        ticketType: ticketType ? "mini_ticket" : "full_ticket",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //prasadam-counters
  getKalayanakattaCounters: async (id) => {
    const resp = await Axios.get(`v1/kalyanakatta-counter/by-product/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  createKalyanakattaCounters: async (body) => {
    const resp = await Axios.post(`v1/kalyanakatta-counter/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editKalyanakattaCounters: async (body, slotId) => {
    const resp = await Axios.put(
      `v1/kalyanakatta-counter/update/${slotId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteKalyanakattaCounters: async (slotId) => {
    const resp = await Axios.delete(
      `v1/kalyanakatta-counter/delete/${slotId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //Kalyanakatta booking

  fetchKalyanakattaBookingSlots: async () => {
    const resp = await Axios.get(
      `v1/kalyanakatta-counter-booking/valid-counter-slots`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  kalayanakattaBooking: async (body) => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        kalyanakattaBookings: body,
        multiTicketsLinked: false,
        paymentType: "CASH",
        cashRemarks: "string",
        cashReference: "string",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  kalayanakattaBookingDetails: async (
    format,
    page,
    limit,
    fromDate,
    toDate,
    selectedKalyanakattaId,
    performerId
  ) => {
    let url = `v1//kalyanakatta-counter-report/reports?format=${format}&page=${page}&limit=${limit}&bookingFromDate=${fromDate}&bookingToDate=${toDate}`;
    if (selectedKalyanakattaId && selectedKalyanakattaId !== "") {
      url += `&productId=${Number(selectedKalyanakattaId)}`;
    }
    if (performerId && performerId !== "") {
      url += `&kalyanakattaPerformerId=${Number(performerId)}`;
    }
    const resp = await ReportsAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  kalayanakattaReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    counter,
    shift,
    kalyankattaType,
    performerId,
  }) => {
    let query = `v1/kalyanakatta-counter-report/booking?format=${format}&page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&bookingToDate=${toDate}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }

    if (kalyankattaType && kalyankattaType !== "") {
      query += `&productId=${kalyankattaType}`;
    }
    if (performerId && performerId !== "") {
      query += `&kalyanakattaPerformerId=${Number(performerId)}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/kalyanakatta-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  getOnlineConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    groupBy,
  }) => {
    let query = `v1/kalyanakatta-online-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  //-------------------------------------performer api's--------------------------//
  getKalayanakattaPerformers: async (page, limit, counterId) => {
    let query = `v1/kalyanakatta-performer/all?page=${page}&limit=${limit}`;

    if (counterId) {
      query += `&counterId=${counterId}`;
    }

    const resp = await Axios.get(query, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  createKalayanakattaPerformer: async (body) => {
    const resp = await Axios.post(`v1/kalyanakatta-performer/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteKalyanakattaPerformer: async (productId) => {
    const resp = await Axios.delete(
      `v1/kalyanakatta-performer/delete/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  viewKalyanakattaPerformer: async (productId) => {
    const resp = await Axios.get(
      `v1/kalyanakatta-performer/by-id/${productId}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  editKalyanakattaPerformer: async (productId, body) => {
    const resp = await Axios.put(
      `v1/kalyanakatta-performer/update/${productId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  editPerformerStatus: async (performerId, status) => {
    const resp = await Axios.patch(
      `v1/kalyanakatta-performer/change-status/${performerId}?status=${status}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp?.data;
  },
  editPerformerBulkStatus: async (counterId, status) => {
    const resp = await Axios.patch(
      `/v1/kalyanakatta-performer/change-counter-bulk-status/${counterId}?status=${status}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp?.data;
  },
  performerReport: async (
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    counter,
    shift,
    kalyankattaType,
    performerId
  ) => {
    let query = `v1/kalyanakatta-counter-report/consolidate-performer-wise-ticket-collection-for-product?page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&bookingToDate=${toDate}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }

    if (kalyankattaType && kalyankattaType !== "") {
      query += `&productId=${kalyankattaType}`;
    }
    if (performerId && performerId !== "") {
      query += `&kalyanakattaPerformerId=${Number(performerId)}`;
    }
    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};

export default TmsKalayanakattaService;
