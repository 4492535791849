import { Button, Select, Card, Col, Input, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import "../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import usePartnersStore from "../../zustand/partners.store";
import TmsTempleService from "../../services/tms-temple.service";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsCheckboxList from "../../components/tms-customs/tms-checkbox-list";
const TmsAddNewPartner = ({ router }) => {
  const {
    partnerName,
    setPartnerName,
    partnerCode,
    setPartnerCode,
    editData,
    setVisible,
    submitLoading,
    clearStates,
    handlePartnerCreation,
    handlePartnerPermissions,
    setEditFlag,
    editFlag,
    loading,
    setLoading,
    selectedTemplesData,
    setSelectedTemplesData,

    fetchPartnerPermissions,
  } = usePartnersStore();

  const [templesData, SetTemplesData] = useState([]);
  const [selectedTemple, setSelectedTemple] = useState("");

  const fetchTemples = async () => {
    try {
      setLoading(true);
      const data = await TmsTempleService.getAllTempleDetails();
      SetTemplesData(data);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login", router);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editFlag && editData) {
      handeEditData();
    }
  }, [editData]);

  useEffect(() => {
    fetchTemples();
  }, []);

  const handeEditData = async () => {
    setPartnerName(editData.partnerName);
    setPartnerCode(editData.partnerCode);
    const res = await fetchPartnerPermissions(editData.id, router, true);

    setSelectedTemplesData(
      res.map((item) => {
        return { templeId: item.temple.id, permissions: item.permissions };
      })
    );
  };

  const columns = [
    {
      title: "Temple",
      dataIndex: "templeId",
      key: "temple",
      render: (templeId) => {
        const temple = templesData?.find((t) => t.id === templeId);
        return temple?.displayName;
      },
    },
    {
      title: "Permission",
      dataIndex: "permissions",
      key: "permission",
      render: (permissions, record) => (
        <TmsCheckboxList
          data={["SEVA_ONLINE_BOOKING", "DARSHANAM_ONLINE_BOOKING"]}
          selectedData={permissions}
          updateItems={(event) => {
            const newData = selectedTemplesData.map((item) => {
              if (item?.templeId === record?.templeId) {
                if (item.permissions?.includes(event)) {
                  item.permissions = item.permissions.filter(
                    (e) => e !== event
                  );
                } else {
                  item.permissions = [...item.permissions, event];
                }
              }
              return item;
            });
            setSelectedTemplesData(newData);
          }}
          auto={true}
          gutter={[20, 20]}
          style={{ padding: "10px 0px" }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button type="danger" onClick={() => handleRemove(record)}>
          Remove
        </Button>
      ),
    },
  ];

  const handleRemove = (record) => {
    const newData = selectedTemplesData.filter(
      (item) => item.templeId !== record.templeId
    );
    setSelectedTemplesData(newData ?? []);
  };

  return (
    <Row className="formRow" style={{ width: "100%" }}>
      <Card style={{ width: "100%" }}>
        <Row className="formRow">
          <label className="formLabel">Partner Name</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={partnerName}
            onChange={(e) => setPartnerName(e.target.value)}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Partner Code </label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={partnerCode}
            onChange={(e) => {
              if (/[a-zA-Z0-9]$/.test(e.target.value)) {
                setPartnerCode(e.target.value);
              }
            }}
          />
        </Row>

        <Row justify="center" style={{ width: "100%", paddingBottom: "20px" }}>
          <label className="formLabel">Select Temples</label>

          <Select
            showSearch
            width="100%"
            className="formInput"
            value={selectedTemple}
            placeholder="select temples"
            defaultValue={selectedTemple}
            autoClearSearchValue
            onChange={(e) => {
              let temp = templesData?.find((item) => item.name === e);
              if (
                !selectedTemplesData.find((item) => item.templeId === temp.id)
              ) {
                let obj = [...selectedTemplesData];
                setSelectedTemplesData([
                  ...obj,
                  { templeId: temp.id, permissions: [] },
                ]);
              }
              setSelectedTemple(null);
            }}
          >
            {templesData?.map((item, i) => {
              return (
                <Select.Option
                  key={item.name + "pos-list" + i}
                  value={item.name}
                >
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
        <Table
          dataSource={selectedTemplesData ?? []}
          columns={columns}
          bordered
          pagination={false}
        />
        <br />
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2  pl-0">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                if (editFlag) {
                  setEditFlag(false);
                }
                setVisible(false);
                setSelectedTemple("");
                setSelectedTemplesData([]);
                clearStates(["partnerName", "partnerCode", "permissions"], []);
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={submitLoading}
              onClick={() => {
                editData ? handlePartnerPermissions() : handlePartnerCreation();
              }}
            >
              {submitLoading ? "Loading.." : "Submit"}
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsAddNewPartner);
``;
