import {
  Button,
  Col,
  Input,
  notification,
  Modal,
  Row,
  Space,
  Select,
  Card,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";

import TmsRenderDetailsRenderer from "../../../components/tms-ticket-details-renderer/tms-ticket-details-renderer";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsCartService from "../../../services/tms-cart.service";
import { BaseContext } from "../../../contexts/BaseContext";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import postBookingDataFunction from "../../../utils/tms-post-booking-data/tms-post-booking-data";
import {
  biometric,
  biometricStart,
  cross,
  tick,
} from "../../../assets/images/tms-image-list";

const { Option } = Select;
const TmsPosTicketDetails = ({
  router,
  from,
  bookingUpdate,
  scanFingerPrint,
  checkIfDeviceIsReady,
  fingerloading,
  match,
  setMatch,
  deviceInfo,
  captured,
  setCaptured,
}) => {
  const inputEl = useRef();
  const [printType, setPrintType] = useState("a4");
  const { userDetails } = useContext(BaseContext);
  const [ticketId, setTicketId] = useState("");
  const [data, setData] = useState();
  // const [array, setArray] = useState();
  const [postBookingData, setPostBookingData] = useState();

  const setInputFocus = () => {
    inputEl?.current?.input?.focus();
  };
  const [externalPrint, setExternalPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  const submit = async () => {
    setData(undefined);
    setPostBookingData(undefined);
    if (from === "accoScan") {
      setCaptured(null);
      setMatch(null);
    }
    if (!ticketId) {
      notification.error({ message: "Enter Ticket Number!" });
    }
    try {
      const data = await TmsCartService.getAdminTicketDetails(ticketId);
      if (data) {
        setData(data);
        setTicketId("");
      }
      setInputFocus();
    } catch (error) {
      catchErrorFunction(error, "Token expired!! please login again.", router);
      setPostBookingData("");
      console.log(externalPrint);
    }
  };

  useEffect(() => {
    setInputFocus();
  }, []);

  const handlePrint = () => {
    setPostBookingData(postBookingDataFunction(data));
    setIsModalVisible(true);
  };

  return (
    <>
      <Row
        style={{
          justifyContent: from === "accoScan" ? "space-between" : "center",
          alignItems: "center",
        }}
      >
        <Col style={{ flexDirection: "row" }}>
          <Row justify={`${from === "accoScan" ? "left" : "center"}`}>
            <p
              style={{
                fontSize: "30px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              {from !== "accoScan" ? "Ticket Details" : "Accommodation Scan"}
            </p>
          </Row>
          <Row justify={`${from === "accoScan" ? "left" : "center"}`}>
            <Space size="middle">
              <Col> Ticket No: </Col>
              <Col>
                <Input
                  maxLength={220}
                  ref={inputEl}
                  value={ticketId}
                  onChange={(e) => {
                    if (e.target.value?.includes("PRB")) {
                      setType("protocol");
                    } else {
                      setType("normal");
                    }
                    setTicketId(e.target.value.trim());
                  }}
                  onKeyDown={onKeyDown}
                />
              </Col>
              <Col>
                <Button type="primary" onClick={submit}>
                  GO!
                </Button>
              </Col>
            </Space>
          </Row>
          {templeDetails?.configuration?.enableAccommodationFingerScan &&
            from === "accoScan" && (
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "18px",
                  marginTop: "10px",
                }}
              >
                Biometric Device :
                <p
                  style={{
                    color: `${
                      deviceInfo?.data?.ErrorCode === "0" ? "green" : "red"
                    }`,
                    marginLeft: "10px",
                    fontWeight: "600",
                  }}
                >
                  {deviceInfo?.data?.ErrorDescription === "Success"
                    ? "Connected"
                    : "Not Connected"}
                </p>
                {deviceInfo?.data?.ErrorCode !== "0" && (
                  <p
                    style={{
                      color: "skyblue",
                      textDecoration: "underline",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      checkIfDeviceIsReady();
                    }}
                  >
                    Refresh
                  </p>
                )}
              </Row>
            )}
        </Col>
        {templeDetails?.configuration?.enableAccommodationFingerScan ? (
          from === "accoScan" ? (
            data ? (
              data[0]?.counter_accommodation?.checkInFingerPrintTemplate !=
              null ? (
                <Col>
                  <Card
                    hoverable
                    onClick={() => {
                      scanFingerPrint(
                        data[0]?.counter_accommodation
                          ?.checkInFingerPrintTemplate,
                        "verify"
                      );
                    }}
                  >
                    <img
                      src={
                        fingerloading
                          ? biometric
                          : match === null
                          ? biometricStart
                          : match
                          ? tick
                          : cross
                      }
                    />
                  </Card>
                </Col>
              ) : data[0]?.productType === "counter_accommodation_advance" ||
                data[0]?.productType === "online_accommodation" ? (
                <Col>
                  <Card
                    hoverable
                    onClick={() => {
                      scanFingerPrint(
                        data[0]?.counter_accommodation
                          ?.checkInFingerPrintTemplate,
                        "onlyScan"
                      );
                    }}
                  >
                    <img
                      src={
                        fingerloading
                          ? biometric
                          : match === null
                          ? biometricStart
                          : match
                          ? tick
                          : cross
                      }
                    />
                  </Card>
                </Col>
              ) : (
                <></>
              )
            ) : (
              <></>
            )
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Row>
      <Row justify="center">
        <Col
          style={{
            marginTop: "20px",
            width: from === "accoScan" ? "100%" : "60%",
          }}
        >
          {data && (
            <TmsRenderDetailsRenderer
              data={data}
              bookingUpdate={bookingUpdate}
              from={from}
              match={match}
              captured={captured}
              type={type}
            />
          )}
        </Col>
      </Row>
      {userDetails?.permissions.includes("SUPER_ADMIN") ||
      userDetails?.permissions.includes("ADMIN") ? (
        <>
          {data && from !== "accoScan" && (
            <Row justify="center">
              <Col
                style={{
                  marginTop: "10px",
                  marginRight: "10px",
                  width: "20%",
                  justifyContent: "start",
                }}
              >
                <Select
                  size="medium"
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select Print Type"
                  value={printType}
                  onChange={(e) => {
                    setPrintType(e);
                  }}
                >
                  <Option key={"thermal"} value={"thermal"}>
                    Thermal Print
                  </Option>
                  <Option key={"a4"} value={"a4"}>
                    A4 Print
                  </Option>
                </Select>
              </Col>
              <Button
                loading={loading}
                style={{
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: "4px",
                  marginTop: "10px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handlePrint();
                }}
              >
                Print
              </Button>
              <Modal
                visible={isModalVisible}
                width={850}
                footer={null}
                closable={false}
                centered
                maskClosable={false}
              >
                <TmsPrintTicket
                  type={postBookingData?.type}
                  from="Counter"
                  data={postBookingData?.array}
                  setData={setPostBookingData}
                  externalPrint={postBookingData?.externalPrint}
                  setExternalPrint={setExternalPrint}
                  setButtonLoading={setLoading}
                  setPrintTicketModal={setIsModalVisible}
                  printType={printType}
                  duplicate={true}
                />
              </Modal>
            </Row>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(TmsPosTicketDetails);
