import Axios from "../utils/tms-axios/tms-axios";

const TmsHundiService = {
  createHundi: async (body) => {
    const resp = await Axios.post(`v1/hundi/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  listHundi: async (page, limit, fromDate, toDate) => {
    const resp = await Axios.get(
      `v1/hundi/list?page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&sortOrder=DESC&sortBy=createdAt`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
};

export default TmsHundiService;
