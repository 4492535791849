import moment from "moment";

export const formatRentsData = (key, data) => {
  const spitInstitution = (data) => {
    return [["Institution Name", `${data.institution.name}`]];
  };
  const spitAddress = (data) => {
    return [
      ["Mandal", data.mandal],
      ["Village", data.village],
      ["Pincode", data.pincode],
      ["Country", data.country],
      ["State", data.state],
      ["District", data.district],
    ];
  };
  const spitLandDetails = (data) => {
    return [
      ["Agriculture Land (Acres)", data.agricultureLandAcres],
      ["AgricultureLand (Cents)", data.agricultureLandCents],
      ["NonAgriculture Land (Acres)", data.nonAgricultureLandAcres],
      ["NonAgriculture Land (Cents)", data.nonAgricultureLandCents],
      ["Total Land (Acres)", data.totalLandAcres],
      ["Total Land (Cents)", data.totalLandCents],
    ];
  };
  const spitCRUDDates = (data) => {
    return [
      [
        "Created At",
        data.createdAt
          ? moment(data.createdAt).format("DD-MM-YYYY HH:mm A")
          : "-",
      ],
      [
        "Last updated",
        data.updatedAt
          ? moment(data.updatedAt).format("DD-MM-YYYY HH:mm A")
          : "-",
      ],
      [
        "Deleted At",
        data.deletedAt
          ? moment(data.deletedAt).format("DD-MM-YYYY HH:mm A")
          : "-",
      ],
    ];
  };
  switch (key) {
    case "Lands":
      return [
        ["id", 2],
        ["Temple ID", data.templeId],
        ...spitInstitution(data),
        ...spitLandDetails(data),
        ["Landmark", data.landmark],

        ...spitAddress(data),
        ...spitCRUDDates(data),
        ["By User", data.userId],
      ];
  }
};
