import { Col, Modal, Row, Space } from "antd";
import moment from "moment";
import React from "react";
import { tmsLogo } from "../../assets/images/tms-image-list";
const SpecialSevaModal = ({
  handlePrint,
  downloadFlag,
  setDownloadFlag,
  downloadData,
  setDownloadData,
  refContainer,
}) => {
  const udayaasthamaList = [
    "Gopooja - 03:15 a.m.",
    "Suprabhatham - 03:30 a.m.",
    "Maha Mangala Harathi - 04:30 a.m.",
    "Panchamrutha Abhishekam -05:00 am",
    "Sri Vruddha Mallikarjuna Swamy vari Abhishekam - 05:30am",
    "Kumkumarchana",
    "Vedaseervachanam",
    "Rudra Homam - 08:00 am to 09:00 am.",
    "Sri Uma Maheswara Swamy Vratham - 09:30 am.",
    " Sri Valli Devasena Subrahmanya Swamy vari Kalyanam -10:00 am to 10:45 am",
    "Chandi Homam - 11:00 am.",
    "Sarpa Dosha Nivarana Pooja - 05:30 pm.",
    "Leela Kalyanotsavam - 07:30 pm.",
    "Ekantha Seva - 10:30 pm",
  ];
  const pradokshakalaList = [
    "Pradoshakala Maha Mangala Harathi - 05:30 p.m",
    "Panchamrutha Abhishekam - 06:00 p.m",
    "Kumkumarchana - 06:30 p.m.",
    "Vedaseervachanam",
  ];
  return (
    <Modal
      title="Devotee Data Form"
      visible={downloadFlag}
      onOk={() => {
        console.log(downloadData);
        handlePrint();
      }}
      onCancel={() => {
        setDownloadData(null);
        setDownloadFlag(false);
      }}
    >
      <div ref={refContainer} style={{ padding: "5px " }} className="sevaform">
        <Row justify="center">
          <img src={tmsLogo} alt="" height={"60px"} />
        </Row>{" "}
        <Row justify="center">
          <h2 style={{ textAlign: "center" }}>
            Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam - Srisailam{" "}
          </h2>
        </Row>{" "}
        <Row justify="center">
          {" "}
          <h3>Devotee Seva Form</h3>
        </Row>
        <br />
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col span={8} className="t-l">
            <b>Seva name</b>
          </Col>{" "}
          <Col span={16} className="t-l">
            : <b>{downloadData?.sevaName}</b>
          </Col>
        </Row>
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col span={8} className="t-l">
            <b>Ticket ID</b>
          </Col>{" "}
          <Col span={16} className="t-l">
            : {downloadData?.ticketId}
          </Col>
        </Row>
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col span={8} className="t-l">
            <b>Devotee Names</b>
          </Col>
          <Col span={16} className="t-l">
            {downloadData?.personDetails.map((item, i) => {
              return (
                <p key={i + "serial"} style={{ marginBottom: "3px" }}>
                  {i + 1}. {item.personName} - {item.documentId}
                </p>
              );
            })}
          </Col>
        </Row>
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col span={8} className="t-l">
            <b>Seva Date</b>{" "}
          </Col>
          <Col span={16} className="t-l">
            {" "}
            : {moment(downloadData?.bookingDate).format("DD-MM-YYYY")}
          </Col>
        </Row>
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col span={8} className="t-l">
            {" "}
            <b>Gothram</b>
          </Col>{" "}
          <Col span={16} className="t-l">
            : {downloadData?.gothram ?? "-"}
          </Col>
        </Row>
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col span={8} className="t-l">
            {" "}
            <b>Address</b>{" "}
          </Col>
          <Col span={16} className="t-l">
            : {downloadData?.address ?? "-"}
          </Col>
        </Row>
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col span={8} className="t-l">
            {" "}
            <b>Attender Name</b>{" "}
          </Col>
          <Col span={16} className="t-l">
            : {downloadData?.address ?? "-"}
          </Col>
        </Row>
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col span={8} className="t-l">
            {" "}
            <b>Accommodation (Building)</b>{" "}
          </Col>
          <Col span={16} className="t-l">
            : {downloadData?.address ?? "-"}
          </Col>
        </Row>
        <br />
        <Row
          justify="space-between"
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            borderBottom: "none",
          }}
        >
          {" "}
          <Col span={16} style={{ padding: "3px" }}>
            Seva Name
          </Col>
          <Col
            span={4}
            style={{
              textAlign: "center",
              borderLeft: "1px solid black",
              padding: "3px",
            }}
          >
            Clerk
          </Col>
          <Col
            span={4}
            style={{
              textAlign: "center",
              borderLeft: "1px solid black",
              padding: "3px",
            }}
          >
            SUPT
          </Col>
        </Row>
        {downloadData?.sevaName === "Udayasthamana Seva"
          ? udayaasthamaList.map((item, i) => {
              console.log(udayaasthamaList?.length);

              return (
                <Row
                  key={i + "udaya"}
                  justify="space-between"
                  style={{
                    textAlign: "left",
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    borderBottom:
                      i + 1 === udayaasthamaList?.length
                        ? "1px solid black"
                        : "none",
                  }}
                >
                  <Col span={16} style={{ padding: "3px" }}>
                    {i + 1}. {item}
                  </Col>
                  <Col
                    span={4}
                    style={{
                      textAlign: "end",
                      borderLeft: "1px solid black",
                      padding: "3px",
                    }}
                  ></Col>
                  <Col
                    span={4}
                    style={{
                      textAlign: "end",
                      borderLeft: "1px solid black",
                      padding: "3px",
                    }}
                  ></Col>
                </Row>
              );
            })
          : pradokshakalaList.map((item, i) => {
              return (
                <Row
                  key={i + "udayasthana"}
                  justify="space-between"
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    borderBottom:
                      i + 1 === pradokshakalaList?.length
                        ? "1px solid black"
                        : "none",
                    textAlign: "left",
                  }}
                >
                  <Col span={16} style={{ padding: "3px" }}>
                    {i + 1}. {item}
                  </Col>
                  <Col
                    span={4}
                    style={{
                      textAlign: "end",
                      borderLeft: "1px solid black",
                      padding: "3px",
                    }}
                  ></Col>
                  <Col
                    span={4}
                    style={{
                      textAlign: "end",
                      borderLeft: "1px solid black",
                      padding: "3px",
                    }}
                  ></Col>
                </Row>
              );
            })}
        <br />
        <Row gutter={[10, 10]}>
          {" "}
          <Col>
            <Space>
              <p className="boldText">AEO :</p>
              <p className="boldText">__________</p>
            </Space>
          </Col>{" "}
          <Col>
            <Space>
              <p className="boldText">Devotee :</p>
              <p className="boldText">__________</p>
            </Space>
          </Col>
        </Row>
        <Row>Devotee Feedback: </Row>
      </div>
    </Modal>
  );
};

export default SpecialSevaModal;
