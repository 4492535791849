import { Grid, Layout, Select } from "antd";
import { useContext, useState } from "react";
import React, { useEffect } from "react";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsAvatardropdown from "./tms-header-dropdown/tms-avatardropdown";
import TmsShiftinButton from "./tms-header-dropdown/tms-shiftin-button";
import { BaseContext } from "../../../contexts/BaseContext";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsShiftService from "../../../services/tms-shift.service";
import TmsTempleService from "../../../services/tms-temple.service";
import "../../../assets/css/tms-header/tms-header.scss";

const { Header } = Layout;

const TmsHomeHeader = ({ toggleSider, siderCollapsed, router }) => {
  const { useBreakpoint } = Grid;
  const { shiftData, setShiftData, userDetails } = useContext(BaseContext);
  const [reportPopUp, setReportPopUp] = useState(false);
  const screens = useBreakpoint();
  const [counterPop, setCounterPop] = useState(false);
  const [siderHidden, setSiderHidden] = useState(false);
  const [shiftChangeLoading, setShiftChangeLoading] = useState(false);
  const [reportVisible, setReportVisible] = useState(false);
  const [shiftId, setShiftId] = useState();
  const [temples, setTemples] = useState([]);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  useEffect(() => {
    // console.log(`#${templeDetails.colorCode}`);
    isShiftLoggedIn();
  }, []);

  useEffect(() => {
    if (
      userDetails?.permissions.includes("SUPER_ADMIN") ||
      userDetails?.permissions.includes("SUPER_DASHBOARD_READ")
    ) {
      getAllTemples();
    }
  }, [userDetails]);

  useEffect(() => {
    if (screens?.xl || screens?.xxl || screens?.lg) {
      setSiderHidden(false);
    } else {
      setSiderHidden(true);
    }
  }, [screens]);

  const isShiftLoggedIn = async () => {
    try {
      const shiftData = await TmsShiftService.IsShiftLoggedIn();
      if (!shiftData) {
        return;
      }
      setShiftData(shiftData);
    } catch (error) {
      catchErrorFunction(error, "Token Expired!! please login", router);
    }
  };

  const getAllTemples = async () => {
    try {
      const temples = await TmsTempleService.getAllTempleDetails();
      setTemples(temples);
    } catch (e) {
      catchErrorFunction(e, "Token Expired! Login again.", router);
    }
  };

  return (
    <div>
      <Header
        style={{
          padding: 0,
          backgroundColor: templeDetails?.colorCode
            ? `#${templeDetails?.colorCode}`
            : "#001529",
          color: "white",
        }}
      >
        {React.createElement(
          siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger tms_trigger_class",
            onClick: toggleSider,
            style: {
              left: siderCollapsed ? (siderHidden ? 20 : 100) : 220,
            },
          }
        )}
        {userDetails?.permissions?.includes("SUPER_ADMIN") ||
        userDetails?.permissions.includes("SUPER_DASHBOARD_READ") ? (
          ""
        ) : (
          <p style={{ color: "white", fontWeight: "600" }}>
            {" "}
            {templeDetails?.name
              ? templeDetails?.name
              : "Endowments Portal Andhra Pradesh"}{" "}
          </p>
        )}
        <div className="tms-shift-in-wrapper">
          {(userDetails?.permissions?.includes("SUPER_ADMIN") ||
            userDetails?.permissions.includes("SUPER_DASHBOARD_READ")) && (
            <div
              style={{
                margin: "0px 20px",
                fontWeight: "bold",
              }}
            >
              <Select
                style={{ maxWidth: "400px", width: "400px" }}
                defaultValue={templeDetails?.name}
                showSearch
                value={templeDetails?.name}
                onChange={(e) => {
                  let findTemple = temples.find((item) => item.name === e);
                  localStorage.setItem(
                    "templeDetails",
                    JSON.stringify(findTemple)
                  );
                  localStorage.setItem("templeId", findTemple.id);
                  window.location.reload();
                }}
              >
                {temples?.map((item) => {
                  return (
                    <Select.Option key={item?.name} value={item?.name}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          )}
          <TmsShiftinButton
            shiftId={shiftId}
            setShiftId={setShiftId}
            isShiftLoggedIn={isShiftLoggedIn}
            shiftData={shiftData}
            setShiftData={setShiftData}
            reportPopUp={reportPopUp}
            setReportPopUp={setReportPopUp}
            counterPop={counterPop}
            setCounterPop={setCounterPop}
            shiftChangeLoading={shiftChangeLoading}
            setShiftChangeLoading={setShiftChangeLoading}
            reportVisible={reportVisible}
            setReportVisible={setReportVisible}
          />
          <div style={{ marginLeft: "20px" }}>
            <TmsAvatardropdown />
          </div>
        </div>
      </Header>
    </div>
  );
};

export default withRouter(TmsHomeHeader);
