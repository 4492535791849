import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsManualEntryService = {
  getAll: async (page, limit) => {
    const resp = await Axios.get(
      `/v1/manual-entry/all?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  addBook: async (body) => {
    const resp = await Axios.post(`/v1/manual-entry/new`, body, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editBook: async (body) => {
    const resp = await Axios.put(`v1/manual-entry/edit`, body, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  addCategory: async (bookId, body) => {
    const resp = await Axios.post(
      `/v1/manual-entry/add-category/${bookId}`,
      body,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  editCategory: async (body) => {
    const resp = await Axios.put(`/v1/manual-entry/edit-category`, body, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  deleteBook: async (id) => {
    const resp = await Axios.delete(`v1/manual-entry/delete/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  deleteCategory: async (id) => {
    const resp = await Axios.delete(`v1/manual-entry/delete-category/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  validProducts: async () => {
    const resp = await Axios.get(
      `v1/manual-entry-counter-booking/valid-counter-products`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  manualEntryBooking: async (body) => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        manualEntryBookings: body,
        multiTicketsLinked: false,
        paymentType: "CASH",
        cashRemarks: "string",
        cashReference: "string",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  manualEntryReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    sortType,
    counter,
    shift,
    Book,
  }) => {
    let url = `v1/manual-entry-report/booking?format=${format}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&order=${sortType}`;
    if (Book && Book !== "") {
      url += `&productId=${Number(Book)}`;
    }
    if (counter && counter !== "") {
      url += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      url += `&counterShiftTimingId=${shift}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/manual-entry-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
};

export default TmsManualEntryService;
