//State Validation Arrays

//land states
export const createLandStates = [
  "landmark",
  "nonAgriculturalAcres",
  "agriculturalAcres",
  "agriculturalCents",
  "nonAgriculturalCents",
  "zip",
  "state",
  "country",
  "district",
  "institutionData",
  "village",
  "mandal",
  "selectedInstitution",
];
export const createBuildingStates = [
  "landmark",
  "buildingName",
  "propertyType",
  "builtAreaInSqFeet",
  "zip",
  "state",
  "country",
  "district",
  "village",
  "mandal",
  "selectedInstitution",
  "numberOfShops",
];
export const createLicenseStates = [
  "selectedInstitution",
  "licenseName",
  "status",
];
export const createAccountCatStates = [
  "accountCategoryName",
  "selectedInstitution",
];
//survey creation
export const createSurveyNoStates = [
  "typeOfLand",
  "surveyNumber",
  "pattadarPassbook",
  "natureOfLand",
  "totalLandValue",
  "agriculturalAcres",
  "nonAgriculturalAcres",
  "agriculturalCents",
  "nonAgriculturalCents",
  "eastBoundary",
  "westBoundary",
  "southBoundary",
  "northBoundary",
  "country",
  "typeOfLand",
  "state",
  "district",
  "mandal",
  "village",
  "zip",
  "geoTagging",
  "waterSource",
  "donorName",
  "DonorRelationName",
  "donorPhone",
  "donorAddress",
  "donorVillage",
  "donorCity",
  "donorDistrict",
  "donorPincode",
  "donorState",
  "donorCountry",
  "donorDocType",
  "donorDocNo",
  "donorDoc",
  "selectedLand",
  "selectedInstitution",
];

export const additionalSurveyStates = [
  "status",
  "remarks",
  "pattadarPassbookDocument",
  "pattadarPassbookReason",
  "institutionData",
  "createdLandData",
];

export const createBlockStates = [
  "numberOfShops",
  "blockNumber",
  "selectedInstitution",
  "selectedBuilding",
];
//breakup no
export const breakupSurveyNoStates = [
  "blockNumber",
  "extent",
  "agriculturalAcres",
  "nonAgriculturalAcres",
  "agriculturalCents",
  "nonAgriculturalCents",
  "holdingStatus",
  "eastBoundary",
  "westBoundary",
  "southBoundary",
  "northBoundary",
  "geoTagging",
];

export const additionalBreakup = [
  "createdLandData",
  "surveyNoDataByLandId",
  "institutionData",
  "remarks",
  "selectedSurveyNumber",
  "selectedInstitution",
  "selectedLand",
  "selectedSNBreakup",
];

export const createShopStates = [
  "extentLength",
  "extentBreadth",
  "selectedBuilding",
  "selectedInstitution",
  "shopStatus",
  "status",
  "shopNumber",
  "selectedBlock",
];

export const createBankAccountStates = [
  "bankName",
  "branchName",
  "accountNumber",
  "accountType",
  "ifsccode",
  "location",
  "selectedInstitution",
  "selectedAccountCategory",
];

//Lease
export const leaseCreationStates = [
  "extent",
  "leasePeriodStartDate",
  "leasePeriodEndDate",
  "dueDate",
  "paymentTerm",
  "paymentType",
  "lesseType",
  "lesseName",
  "mobileNumber",
  "address",
  "state",
  "district",
  "mandal",
  "village",
  "zip",
  "idProofType",
  "idProofNumber",
  "uploadIdProof",
  "eoProceedingUploadDocument",
  "status",
  "selectedSurveyNumber",
  "selectedInstitution",
];

export const leaseAddtionalStates = [
  "gstAmount",
  "leaseRate",
  "depositAmount",
  "mrNo",
  "mrDate",
  "kindItem",
  "kindItemUnitsInKg",
  "agriculturalAcres",
  "agriculturalCents",
  "nonAgriculturalAcres",
  "nonAgriculturalCents",
  "gstNo",
  "incorporationOrPanNo",
  "contact_person_name",
  "gender",
  "reason",
  "institutionData",
  "createdSNBreakupData",
  "createdSurveryNumberData",
  "surveyNumberBreakupId",
  "remarks",
  "uploadApprovedDocument",
  "uploadApprovedDocumentOrderNo",
  "uploadApprovedDocumentOrderDate",
  "createdLandLeaseData",
];

export const shopLeaseCreationStates = [
  "leasePeriodStartDate",
  "leasePeriodEndDate",
  "dueDate",
  "paymentTerm",
  "paymentType",
  "lesseType",
  "lesseName",
  "mobileNumber",
  "address",
  "state",
  "district",
  "mandal",
  "village",
  "zip",
  "idProofType",
  "idProofNumber",
  "uploadIdProof",
  "selectedBuilding",
  "selectedBlock",
  "selectedShop",
  "selectedInstitution",
  "goodWillAmount",
  "gstAmount",
  "eoProceedingUploadDocument",
  "status",
  "natureOfBusiness",
  "leaseApprovedByCompetentAuthority",
];

export const shopleaseAddtionalStates = [
  "leaseRate",
  "depositAmount",
  "mrNo",
  "mrDate",
  "gstNo",
  "incorporationOrPanNo",
  "contactPersonName",
  "gender",
  "reason",
  "uploadContemptoryCopy",
  "uploadApprovedDocumentOrderNo",
  "uploadApprovedDocumentOrderDate",
];

export const licenseLeaseCreationStates = [
  "natureOfBusiness",
  "licensePeriodStartDate",
  "licensePeriodEndDate",
  "dueDate",
  "gstAmount",
  "paymentTerm",
  "paymentType",
  "depositAmount",
  "mrNo",
  "mrDate",
  "licenseFee",
  "licenseeType",
  "licenseeName",
  "mobileNumber",
  "address",
  "state",
  "district",
  "mandal",
  "village",
  "zip",
  "idProofType",
  "idProofNumber",
  "uploadIdProof",
  "eoProceedingUploadDocument",
  "status",
  "selectedInstitution",
];

export const licenseLeaseAddtionalStates = [
  "enhancementEndPeriod",
  "enhancementStartPeriod",
  "enhancementPercentage",
  "gstNo",
  "incorporationOrPanNo",
  "reason",
  "institutionData",
  "remarks",
  "uploadCEDOrder",
  "uploadApprovedDocumentOrderNo",
  "uploadApprovedDocumentOrderDate",
  "createdLicenseData",
];

export const createInvestmentStates = ["investmentType", "selectedInstitution"];

//encroachment
export const encroachmentStates = [
  "encroacherName",
  "encroacherAddress",
  "enchrochmentDuration",
  "encroachmentDurationInMonths",
  "legalAction",
  "remarks",
  "status",
  "selectedSurveyNumber",
  "encroachedAcres",
  "encroachedCents",
];

export const buildingEncroachmentStates = [
  "encroacherName",
  "encroacherAddress",
  "encroachmentDurationInMonths",
  "legalAction",
  "remarks",
  "status",
  "selectedBuilding",
  "selectedInstitution",
  "selectedBuilding",
  "selectedShop",
  "encroacherNatureOfBusiness",
];
export const additionalEncroahmentStates = [
  "caseNo",
  "legalReason",
  "file",
  "selectedSNBreakup",
  "institutionData",
  "surveyNoDataByLandId",
  "createdSNBreakupData",
  "createdLandData",
  "selectedInstitution",
  "selectedLand",
  "remarks",
];

export const additionalBuildingEncroahmentStates = [
  "caseNo",
  "legalReason",
  "file",
  "institutionData",
  "selectedBlock",
  "createdBlocksData",
  "createdBuildingsData",
  "selectedBlock",
  "selectedShop",
  "createdShopData",
  "createdEncroachmentsData",
];
export const createFdrStates = [
  "chequeOrDDNumber",
  "amountDepositedFromBank",
  "principleAmountInvested",
  "fdrAccountNumber",
  "fdrFolioNumber",
  "depositDate",
  "numberOfDays",
  "rateOfInterest",
  "interestRemittanceTerm",
  "interestAmount",
  "maturityDate",
  "interestRemittanceDate",
  "maturityAmount",
  "remarks",
  "selectedInstitution",
  "selectedBankAccount",
  "selectedInvestmentType",
];
//arrear
export const arrearStates = [
  "arrearAmount",
  "interestAmount",
  "leasePeriodStartDate",
  "leasePeriodEndDate",
  "arrearAmount",
  "legalAction",
  "status",
  "selectedLandLease",
  "selectedSurveyNumber",
  "arrearPeriodInMonths",
];
export const arrearShopStates = [
  "arrearAmount",
  "interestAmount",
  "leasePeriodStartDate",
  "leasePeriodEndDate",
  "arrearAmount",
  "legalAction",
  "status",
  "selectedShop",
  "selectedBlock",
  "selectedShop",
  "selectedBuilding",
  "selectedBuildingLease",
  "arrearPeriodInMonths",
];
export const arrearLicenseStates = [
  "arrearAmount",
  "interestAmount",
  "legalAction",
  "status",
  "selectedLicenseLease",
  "selectedInstitution",
  "arrearPeriodInMonths",
];
export const additionalArrearStates = [
  "caseNo",
  "file",
  "legalReason",
  "remarks",
  "institutionData",
  "createdSNBreakupData",
  "createdSurveryNumberData",
  "createdLandLeaseData",
  "createdBuildingLeaseData",
  "createdArrearsData",
  "selectedLicenseLease",
  "selectedLicense",
];
export const additionalShopArrearStates = [
  "caseNo",
  "casePDF",
  "legalReason",
  "remarks",
  "institutionData",
  "createdBlocksData",
  "createdShopData",
  "createdBuildingData",
  "createdArrearsData",
  "selectedInstitution",
];
export const additionalLicenseArrearStates = [
  "caseNo",
  "casePDF",
  "legalReason",
  "remarks",
  "institutionData",
  "createdLicenseData",
  "createdLicenseLeaseData",
  "createdArrearsData",
  "arrearReport",
];
//Data Arrays
export const paymentTerms = [
  {
    name: "Monthly",
    id: "MONTHLY",
  },
  {
    name: "Half Yearly",
    id: "HALF_YEARLY",
  },
  {
    name: "Quaterly",
    id: "QUARTERLY",
  },
  {
    name: "Yearly",
    id: "YEARLY",
  },
];
export const sortByData = [
  {
    name: "Created At",
    id: "createdAt",
  },
  {
    name: "Updated At",
    id: "updatedAt",
  },
  {
    name: "Deleted At",
    id: "deletedAt",
  },
];
export const yesOrNotData = [
  {
    id: false,
    name: "NO",
  },
  {
    id: true,
    name: "YES",
  },
];
export const paymentTypes = [
  { id: "CASH", name: "CASH" },
  { id: "UPI", name: "UPI" },
  { id: "CARD", name: "CARD" },
  { id: "CHEQUE", name: "CHEQUE" },
  { id: "DD", name: "DD" },
  { id: "NEFT", name: "NEFT" },
  { id: "RTGS", name: "RTGS" },
  { id: "IMPS", name: "IMPS" },
];
export const holdingStatusData = [
  "LEASE",
  "UNDER_TENANT_HOLDING_OVER",
  "ENCROACHMENT",
  "UTILITY_UNDER_INSTITUTION",
];
export const ShopStatusData = [
  { name: "Leased out", id: "LEASED_OUT" },
  { name: "To be Leased out", id: "TO_BE_LEASED_OUT" },
  { name: "Maintenance", id: "MAINTENANCE" },
  { name: "Encroachment", id: "ENCROACHMENT" },
];
export const investmentTypesData = [
  { name: "GENERAL_FUND", id: "GENERAL_FUND" },
  { name: "RESERVE_FUND", id: "RESERVE_FUND" },
  { name: "SECURITY_DEPOSIT", id: "SECURITY_DEPOSIT" },
];
export const accountTypeData = [
  { name: "SAVINGS", id: "SAVINGS" },
  { name: "CURRENT", id: "CURRENT" },
];

export const natureOfLandData = [
  { id: "AGRI_DRY", name: "AGRI_DRY" },
  { id: "AGRI_TOPES", name: "AGRI_TOPES" },
  { id: "AGRI_WET", name: "AGRI_WET" },
  { id: "FOREST", name: "FOREST" },
  { id: "FISH_TANK", name: "FISH_TANK" },
  { id: "RIVER_BED", name: "RIVER_BED" },
  { id: "VACANT", name: "VACANT" },
  { id: "HILLS", name: "HILLS" },
];
export const waterSourceData = [
  { id: "GROUND_WATER", name: "GROUND WATER" },
  { id: "SURFACE_WATER", name: "SURFACE WATER" },
  { id: "RAIN_WATER", name: "RAIN WATER" },
  { id: "OTHER", name: "OTHER" },
];
export const documentTypesData = [
  { id: "IRF", name: "IRF" },
  { id: "TD", name: "TD" },
  { id: "RYTHWARI_PATTA", name: "RYTHWARI PATTA" },
  { id: "GO", name: "GOVERNMENT ORDER" },
  { id: "COURT_ORDER", name: "COURT ORDER" },
  { id: "PPP", name: "PPP" },
  { id: "OTHER", name: "OTHER" },
];
export const surveyLandtypes = [
  { name: "Temple Inam", id: "TEMPLE_INAM" },
  { name: "Service Inam", id: "SERVICE_INAM" },
  { name: "Ryotwari", id: "RYOTWARI" },
  { name: "Gifted", id: "GIFTED" },
  { name: "Acquired", id: "ACQUIRED" },
];
export const extentTypes = [
  { name: "Agricultural", id: "AGRICULTURE" },
  { name: "Non Agricultural", id: "NON_AGRICULTURE" },
];
export const IdProofData = [
  { name: "Aadhar", id: "aadhar" },
  { name: "Pan card", id: "pan" },
  { name: "Driving License", id: "driving" },
  { name: "Passport", id: "passport" },
  { name: "Ration", id: "ration" },
  { name: "Voter", id: "voter" },
];
export const LeeseType = [
  {
    label: "Individual",
    value: "individual",
  },
  {
    label: "Institution",
    value: "institution",
  },
];
export const propertyTypes = [
  { name: "VACANT_SITE", id: "VACANT_SITE" },
  { name: "SHEDS", id: "SHEDS" },
  { name: "BUILDINGS", id: "BUILDINGS" },
  { name: "SHOPPING COMPLEX", id: "SHOPPING_COMPLEX" },
];
