import React, { useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportMiscReport from "./tms-report-misc-report";

const TmsReportMisc = () => {
  const [active, setActive] = useState("Misc Report");

  const components = [
    {
      name: "Miscellaneous Report",
      Element: <TmsReportMiscReport />,
      key: "Misc Report",
      route: tmsRouteMap.reports_misc,
    },

    // {
    //   name: "Summary Report",
    //   Element: <TmsReportTollgateSummaryReport />,
    //   key: "Summary Report",
    //   route: tmsRouteMap.reports_misc_summary_report,
    // },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportMisc);
