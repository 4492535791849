import React from "react";
import { useNavigate } from "react-router-dom";
import { tmsLogo } from "../../assets/images/tms-image-list";

// import {SD_Logo_circle} from "../../Assets/imageList";
const TmsReload = () => {
  let navigate = useNavigate();

  return (
    <div
      style={{
        textAlignLast: "center",
        padding: "5vw",
      }}
    >
      <img
        src={tmsLogo}
        alt="logo"
        height={window.innerWidth < 650 ? "70px" : "100px"}
        style={{
          border: "2px solid orange",
          borderRadius: "50%",
        }}
      />
      <br />

      <p style={{ marginTop: "10px" }}>
        {" "}
        Oops!! Looks like something went wrong
      </p>

      <p style={{ color: "#181818" }}>
        Click here to{" "}
        <a
          href="/"
          style={{ color: "#2f3193", fontWeight: "600" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Reload
        </a>
      </p>
    </div>
  );
};
export default TmsReload;
