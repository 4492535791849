import { Button, Col, Input, Modal, notification, Row, Statistic } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import { BaseContext } from "../../../contexts/BaseContext";
import TmsAccommodationService from "../../../services/tms-accommodation.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
// import TmsPosAccommodationScan from "../../../views/tms-pos/tms-pos-pages/tms-pos-accommodation-scan";
// import TmsModal from "../../tms-customs/tms-modal";
import TmsPrintTicket from "../../tms-print-ticket/tms-print-ticket";

const TmsAccDashboardCheckOutRenew = ({
  router,
  flag,
  roomId,
  setCheckOutRenewModal,
  // checkOutRenewModal,
  page,
  setPage,
  checkOutRenewDatas,
  setCheckOutRenewDatas,
}) => {
  const { Countdown } = Statistic;
  const [loading, setLoading] = useState(false);
  const [roomUpdateData, setRoomUpdateData] = useState();
  const [otp, setOtp] = useState("");
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  const [ticketId, setTicketId] = useState();
  const [array, setArray] = useState([]);
  const [countDown, setCountDown] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);

  const bookingData = (item) => {
    let schemaBody = [];
    setTicketId(item?.newCounterCartItem?.ticketId);
    schemaBody = [
      ...schemaBody,
      [
        "header",
        item?.newCounterCartItem?.categoryTransactionSequence
          ?.split("-")
          ?.pop(),
        {},
        item?.newCounterCartItem?.dailyCategoryTransactionSequence
          ?.split("-")
          ?.pop(),
        item?.newCounterCartItem?.ticketId,
      ],
      ["Ticket Id", item?.newCounterCartItem?.ticketId, { margin: "0" }],
      [
        "Devotee Name",
        item?.accRoomBookingStats?.devoteeName,
        { margin: "0", fontWeight: "bold" },
      ],
      [
        "Room Details",
        `${item?.accRoomBookingStats?.roomType}-${item?.accRoomBookingStats?.blockName}-${item?.accRoomBookingStats?.roomNumber}`,
        { margin: "0" },
      ],
      [
        "Checkin Time",
        `${moment(
          item?.accRoomBookingStats?.checkInTime,
          "YYYY-MM-DD HH:mm:ss Z"
        ).format("DD-MM-YYYY hh:mm A")}`,
        { margin: "0" },
      ],
      [
        "Checkout Time",
        flag === "checkOut"
          ? `${moment().format("DD-MM-YYYY hh:mm A")}`
          : `${moment(
              item?.accRoomBookingStats?.newCheckOutTime,
              "YYYY-MM-DD HH:mm:ss Z"
            ).format("DD-MM-YYYY hh:mm A")}`,
        { margin: "0" },
      ],
      [
        "Room Price",
        `Rs. ${item?.accRoomBookingStats?.roomPrice}.00`,
        { margin: "0" },
      ],
      // [
      //   "Service Amount",
      //   item?.accRoomBookingStats?.serviceAmount > 0
      //     ? `Rs. ${item?.accRoomBookingStats?.serviceAmount}.00`
      //     : "null",
      //   { margin: "0" },
      // ],
      [
        "GST Amount",
        item?.accRoomBookingStats?.gstAmount > 0
          ? `Rs. ${item?.accRoomBookingStats?.gstAmount}.00`
          : "null",
        { margin: "0" },
      ],
      [
        "Deposit Amount",
        `Rs. ${item?.accRoomBookingStats?.depositAmount}.00`,
        { margin: "0" },
      ],
      [
        item?.accRoomBookingStats?.outstandingAmount >= 0
          ? "Amount Paid"
          : "Amount Received",
        `Rs. ${
          item?.accRoomBookingStats?.outstandingAmount > 0
            ? item?.accRoomBookingStats?.outstandingAmount
            : item?.accRoomBookingStats?.outstandingAmount * -1
        }.00 `,
        { margin: "0", fontSize: "22px", fontWeight: "800" },
      ],
      [
        "",
        `(${numberToWords(
          Number(
            item?.accRoomBookingStats?.outstandingAmount > 0
              ? item?.accRoomBookingStats?.outstandingAmount
              : item?.accRoomBookingStats?.outstandingAmount * -1
          )
        )} Rupees)`,
        { margin: "0", fontWeight: "bold" },
      ],
      [
        "footer",
        item?.newCounterCartItem?.ticketId,
        {},
        {},
        {},
        null,
        item?.newCounterCartItem?.createdAt,
      ],
    ];

    setArray([...schemaBody]);
  };

  useEffect(() => {
    if (flag === "checkOut") {
      fetchCheckOut();
    } else if (flag === "renew") {
      fetchRenew();
    }
  }, [flag, roomId]);

  const fetchCheckOut = async () => {
    setLoading(true);
    try {
      const resp = await TmsAccommodationService.checkOutStats(roomId);
      setRoomUpdateData({
        func: "checkOut",
        roomBookingId: resp?.roomBookingId,
        outstandingAmount: resp?.outstandingAmount,
        checkInFingerPrintTemplate: resp?.checkInFingerPrintTemplate,
      });
      setCheckOutRenewDatas([
        {
          title: "Room Details",
          value: `Rs. ${resp?.roomPrice}.00 - ${resp?.roomType} - ${resp?.blockName} - ${resp?.roomNumber}`,
        },
        {
          title: "Checkin Time",
          value: moment(resp?.checkInTime).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "Actual Checkout Time",
          value: moment(resp?.currentCheckOutTime).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "New Checkout Time",
          value: moment(resp?.newCheckOutTime).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "Unpaid Days",
          value: resp?.unPaidDays,
        },

        {
          title: "Service Amount",
          value: `Rs. ${resp?.serviceAmount ?? 0}.00`,
        },
        {
          title: "Deposit Amount",
          value: `Rs. ${resp?.depositAmount ?? 0}.00`,
        },

        {
          title: "GST Amount",
          value: `Rs. ${resp?.gstAmount ?? 0}.00`,
        },
        {
          title: "Outstanding Amount",
          value: `Rs. ${resp?.outstandingAmount ?? 0}.00`,
        },
      ]);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const fetchRenew = async () => {
    setLoading(true);
    try {
      const resp = await TmsAccommodationService.renewStats(roomId);
      setRoomUpdateData({
        func: "renew",
        roomBookingId: resp?.roomBookingId,
        outstandingAmount: resp?.outstandingAmount,
      });
      setCheckOutRenewDatas([
        {
          title: "New Checkout Time",
          value: moment(resp?.newCheckOutTime).format("DD-MM-YYYY hh:mm A"),
        },
        {
          title: "Previous Renewal Count",
          value: resp?.previousRenewalCount,
        },
        {
          title: "Unpaid Days",
          value: resp?.unPaidDays,
        },
        {
          title: "Deposit Amount",
          value: `Rs. ${resp?.depositAmount}.00`,
        },
        {
          title: "Service Amount",
          value: `Rs. ${resp?.serviceAmount}.00`,
        },
        {
          title: "GST Amount",
          value: `Rs. ${resp?.gstAmount ?? 0}.00`,
        },
        {
          title: "Outstanding Amount",
          value: `Rs. ${resp?.outstandingAmount ?? 0}.00`,
        },
      ]);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const accOTP = async () => {
    setLoading(true);
    try {
      await TmsAccommodationService.checkOutOTP();
      notification.success({ message: `OTP Sent` });
      setPage(1);
      setCountDown(Date?.now() + 60 * 2000);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const submit = (details) => {
    let body = { roomBookingId: Number(details?.roomBookingId) };
    if (details?.func === "checkOut") {
      if (otp === "") {
        notification.error({ message: "Please enter OTP" });
        return;
      } else {
        body.forceCheckoutOTP = Number(otp);
      }
      if (details?.outstandingAmount > 0) {
        body.outStandingBalance = Number(details?.outstandingAmount);
      } else {
        body.depositReturned = Number(details?.outstandingAmount * -1);
      }

      body.fingerPrintTemplate = details?.checkInFingerPrintTemplate;

      roomCheckout(body);
    } else if (details?.func === "renew") {
      body.serviceAmount = details?.outstandingAmount;
      roomRenew(body);
    }
  };

  const roomCheckout = async (data) => {
    setLoading(false);
    try {
      const resp = await TmsAccommodationService.forceCheckOutandRenew(
        data,
        flag
      );
      bookingData(resp);
      setPage(2);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const roomRenew = async (data) => {
    setLoading(false);
    try {
      const resp = await TmsAccommodationService.roomBookingUpdate(data, flag);
      bookingData(resp);
      setIsModalVisible(true);
      setExternalPrint(true);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  return (
    <>
      <b
        style={{
          justifyContent: "left",
          margin: "auto",
          display: "flex",
        }}
      >
        <p style={{ borderBottom: `2px solid ${templeDetails?.colorCode}` }}>
          {flag === "checkOut" ? "Checkout" : "Renew"} Details
        </p>
      </b>
      {(() => {
        if (page === 0) {
          return (
            <Row>
              {checkOutRenewDatas?.map((item, i) => {
                return (
                  <Row
                    style={{ marginBottom: "5px", width: "100%" }}
                    key={"ticketdetails" + i}
                  >
                    <Col className="ticket_label">{item.title}:</Col>
                    <Col className="ticket_data">{item?.value}</Col>
                  </Row>
                );
              })}
            </Row>
          );
        } else if (page === 1) {
          if (flag === "renew") {
            return (
              <>
                <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                  Are you sure ?
                </Row>
                <Row gutter={[10, 10]} style={{ justifyContent: "center" }}>
                  <Col>
                    <Button
                      onClick={() => {
                        setCheckOutRenewModal(false);
                        setPage(0);
                      }}
                    >
                      No
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => submit(roomUpdateData)}
                    >
                      Yes
                    </Button>
                  </Col>
                </Row>
              </>
            );
          } else if (flag === "checkOut") {
            return (
              <Row style={{ marginBottom: "10px" }}>
                <Row style={{ width: "100%", marginBottom: "10px" }}>
                  <Col
                    style={{
                      width: "80%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginBottom: "0" }}>
                      One-Time-Password has been sent to{" "}
                      <b>
                        {
                          templeDetails?.configuration
                            ?.accommodationForceCheckoutOTPPhone
                        }
                      </b>
                      . <br />
                      valid for 5 mins.
                    </p>
                  </Col>
                  <Col
                    style={{
                      width: "20%",
                      display: "flex",
                      justifyContent: "right",
                      marginBottom: countDown > 0 ? "10px" : "0",
                      alignItems: "center",
                    }}
                  >
                    {countDown > 0 ? (
                      <Countdown
                        className="countDownOTP"
                        value={countDown}
                        format={"mm:ss"}
                        onFinish={() => {
                          setCountDown(0);
                        }}
                      />
                    ) : (
                      <p
                        style={{
                          margin: "0",
                          color: "#1890ff",
                          cursor: "pointer",
                        }}
                        onClick={() => accOTP()}
                      >
                        Resend OTP
                      </p>
                    )}
                  </Col>
                </Row>
                <Input
                  type="number"
                  min="0"
                  style={{
                    border: "0",
                    borderBottom: "2px solid #1890ff",
                    width: "20%",
                    padding: "0",
                  }}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                />
              </Row>
            );
          }
        } else if (page === 2) {
          return (
            <>
              <Row style={{ justifyContent: "center" }}>
                <p>Do you want to print recipt ?</p>
              </Row>
              <Row style={{ justifyContent: "center" }} gutter={[10, 10]}>
                <Col>
                  <Button
                    onClick={() => {
                      setCheckOutRenewModal(false);
                      window?.location?.reload();
                    }}
                  >
                    No
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setExternalPrint(true);
                      setIsModalVisible(true);
                    }}
                  >
                    Yes
                  </Button>
                </Col>
              </Row>
            </>
          );
        }
      })()}
      <Row
        style={{
          paddingTop: "10px",
          justifyContent: "end",
          display:
            page === 0
              ? "flex"
              : page === 1
              ? flag === "checkOut"
                ? "flex"
                : "none"
              : "flex",
        }}
      >
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            page === 0
              ? flag === "renew"
                ? setPage(1)
                : accOTP()
              : submit(roomUpdateData);
          }}
        >
          {page === 0 ? (flag === "renew" ? "Renew" : "Next") : "Check Out"}
        </Button>
      </Row>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type={flag === "checkOut" ? "Checkout" : "Renew"}
          from="Counter"
          setData={setArray}
          data={array}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setLoading}
          setPrintTicketModal={setIsModalVisible}
          ticketId={ticketId}
          printType="a4"
        />
      </Modal>
    </>
  );
};

export default TmsAccDashboardCheckOutRenew;
