import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import {
  Row,
  Col,
  DatePicker,
  Select,
  Button,
  Input,
  notification,
  Space,
  Divider,
  Table,
  Card,
} from "antd";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import { RightOutlined } from "@ant-design/icons";
import moment from "moment";
import "../../../assets/css/tms-accommodation/tms-pos-accommodation.scss";
import TmsAccommodationService from "../../../services/tms-accommodation.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsModal from "../../../components/tms-customs/tms-modal";
import tmsRouteMap from "../../../constants/tms-route.map";
import { BaseContext } from "../../../contexts/BaseContext";
import TmsPosUserForm from "../../../components/tms-pos/tms-pos-accommodation/tms-pos-user-form";
import TmsPosKalyanaForm from "../../../components/tms-pos/tms-pos-accommodation/tms-pos-kalyana-form";
import {
  captureBiometric,
  checkConnection,
} from "../../../utils/tms-biometric/tms-biometric";
import { biometric } from "../../../assets/images/tms-image-list";
import TmsPosCoupon from "../../../components/tms-pos/tms-pos-coupon/tms-pos-coupon";

const TmsPosAccommodation = ({ router }) => {
  const {
    setDetails,
    details,
    roomMapData,
    couponCode,
    setCouponCode,
    verifiedCouponData,
    setVerifiedCouponData,
    usedBy,
    setUsedBy,
  } = useContext(BaseContext);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [bookingType, setBookingType] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [roomType, setRoomType] = useState("");
  const [roomTypeList, setRoomTypeList] = useState([]);
  const [persons, setPersons] = useState(2);
  const [printType, setPrintType] = useState("thermal");
  const [country, setCountry] = useState("India");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  // const [contextLoading, setContextLoading] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [captured, setCaptured] = useState(null);
  const [loading, setLoading] = useState(false);
  //pMZ0woOcw9
  const [kalayanaMandapam, setkalayanaMandapam] = useState({
    brideDetails: {
      name: "",
      dob: moment().subtract(6570, "days"),
      aadharNumber: "",
    },
    brideFatherDetails: {
      name: "",
      dob: "",
      aadharNumber: "",
    },
    brideMotherDetails: {
      name: "",
      dob: "",
      aadharNumber: "",
    },

    groomDetails: {
      name: "",
      dob: moment().subtract(7665, "days"),
      aadharNumber: "",
    },
    groomFatherDetails: {
      name: "",
      dob: "",
      aadharNumber: "",
    },
    groomMotherDetails: {
      name: "",
      dob: "",
      aadharNumber: "",
    },
  });
  const [idProofType, setIdProofType] = useState("aadhar");
  const [idProofNumber, setIdProofNumber] = useState("");
  const [payment, setPayment] = useState("CASH");
  const [cashReference, setCashReference] = useState("");
  const [cashRemarks, setCashRemarks] = useState("");
  const [blockCategoryData, setBlockCategoryData] = useState([]);
  const [devoteeName, setDevoteeName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [muhurthamDate, setMuhurthamDate] = useState("");
  const [muhurthamTime, setMuhurthamTime] = useState("");
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  const columns = [
    {
      title: "Block Name",
      render: (record) => {
        return (
          <div
            style={{
              fontWeight: `${
                record?.blockCategoryId === selectedRoom?.blockCategoryId ||
                record?.quota?.accBlockCategoryId ===
                  selectedRoom?.blockCategoryId
                  ? "bold"
                  : ""
              }`,
            }}
          >
            {bookingType === "same-day"
              ? record?.blockName
              : record?.accBlock?.name}
            <br />({record?.roomType})
          </div>
        );
      },
    },
    {
      title: "Total Rooms",
      dataIndex: bookingType === "same-day" ? "totalRooms" : "quota",
      render: (record) =>
        bookingType === "same-day" ? record : record?.quantity,
    },

    { title: "Booking Cost", dataIndex: "price" },
    {
      title: "Maintenance Fee",
      dataIndex: "maintenanceCharge",
      key: "maintenanceCharge",
    },
    {
      title:
        bookingType === "same-day" ? "Pending Check Out" : "Maintenance Fee",
      render: (record) => {
        return bookingType === "same-day"
          ? record.pendingCheckout
          : record.maintenanceCharge;
      },
      hidden: bookingType !== "same-day",
    },
    {
      title: "Total Available",
      render: (record) => {
        return bookingType === "same-day"
          ? record.totalAvailable
          : record?.quota?.availableQuantity;
      },
    },
    {
      render: (record) => {
        let type =
          bookingType === "advance"
            ? record?.quota?.accBlockCategoryId
            : record.blockCategoryId;
        return (
          <Button
            type={type === selectedRoom?.blockCategoryId ? "primary" : ""}
            onClick={() => {
              if (type !== selectedRoom?.blockCategoryId) {
                setCouponCode(null);
                setVerifiedCouponData(null);
              }
              handleRoomSelect(record);
              setDetails(null);
            }}
          >
            {type === selectedRoom?.blockCategoryId ? "Selected" : "Book Now"}
          </Button>
        );
      },
    },
  ]?.filter((item) => !item?.hidden);

  //--------------------useeffects-----------------//

  useEffect(() => {
    if (details !== null && details) {
      handleContextData();
    } else {
      setVerifiedCouponData(null);
      setCouponCode();
    }
  }, []);

  useEffect(() => {
    bookingType !== "" && fetchRoomCategory();
  }, [persons, bookingType, startDate]);

  useEffect(() => {
    if (templeDetails?.configuration?.enableAccommodationFingerScan) {
      setTimeout(() => {
        checkIfDeviceIsReady();
      }, 1000);
    }
  }, []);

  //--------------------useeffects-----------------//
  //--------------------biometric-----------------//
  const checkIfDeviceIsReady = async () => {
    try {
      const res = await checkConnection();
      if (res.data.ErrorCode === "0") {
        setDeviceInfo(res);
      } else {
        setDeviceInfo(null);
        notification.error({ message: res.data.ErrorDescription });
      }
    } catch (E) {
      catchErrorFunction(E, "Something went wrong with Device.", router);
    }
  };

  const scanFingerPrint = async () => {
    setLoading(true);
    try {
      const res = await captureBiometric();
      if (res.data.ErrorCode !== "0") {
        setCaptured(res.data);
        console.log(res);
        notification.error({ message: res.data.ErrorDescription });
      } else {
        setCaptured(res.data);
        console.log(res);
        notification.success({ message: "Captured Successfully" });
      }
      setLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Something went wrong with the device", router);
      setLoading(false);
    }
  };
  //--------------------biometric-----------------//

  const handleRoomSelect = (record) => {
    if (bookingType === "same-day") {
      setSelectedRoom(record);
    } else {
      setSelectedRoom({
        blockCategoryId: record?.quota?.accBlockCategoryId,
        price: record?.price,
        depositAmount: record?.depositAmount,
        blockName: record?.accBlock?.name,
        gst: record.gstAmount,
        maintenanceCharge: record?.maintenanceCharge,
        advanceBookingCharge: record?.advanceBookingCharge,
        roomType: record.roomType,
      });
    }
  };

  const fetchRoomCategory = async () => {
    setTableLoading(true);
    if (!persons || persons === "") {
      setTableLoading(false);
      return;
    }
    try {
      let resp = null;
      if (bookingType === "same-day") {
        resp = await TmsAccommodationService.fetchValidCounterProducts(
          roomType,
          persons
        );
      } else {
        resp = await TmsAccommodationService.fetchValidAdvanceCounterProducts(
          roomType,
          persons,
          moment(startDate).format("YYYY-MM-DD")
        );
      }
      let data = [];
      resp.forEach((element) => {
        if (data.includes(element.roomType)) {
          return;
        } else {
          data.push(element.roomType);
        }
      });
      setRoomTypeList(data);
      setBlockCategoryData(resp);

      if (details?.selectedRoom) {
        let filteredElement = null;
        if (bookingType === "same-day") {
          filteredElement = resp.find(
            (item) =>
              item.blockCategoryId === details?.selectedRoom?.blockCategoryId
          );
        } else {
          filteredElement = resp.find(
            (item) =>
              item.quota?.accBlockCategoryId ===
              details?.selectedRoom?.blockCategoryId
          );
        }

        if (filteredElement) {
          handleRoomSelect(filteredElement);
        }
      } else {
        setSelectedRoom(null);
      }
      setTableLoading(false);
      setDetails(null);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setTableLoading(false);
    }
  };

  const handleSubmit = () => {
    if (!selectedRoom) {
      notification.error({ message: "Select Block" });
      return;
    }

    if (devoteeName === "") {
      notification.error({ message: "Enter Devotee Name" });
      return;
    }
    if (age === "") {
      notification.error({ message: "Enter Age" });
      return;
    }
    if (age <= 17) {
      notification.error({ message: "Age must be greater than 17" });
      // clearLoadings();
      return;
    }
    if (!gender) {
      notification.error({ message: "Select Gender" });
      return;
    }
    if (phone === "") {
      notification.error({ message: "Enter Phone No." });
      return;
    }
    if (phone.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      // clearLoadings();
      return;
    }
    if (address === "") {
      notification.error({ message: "Enter Address" });
      return;
    }
    if (zip === "") {
      notification.error({ message: "Enter Pincode" });
      return;
    }
    if (city === "") {
      notification.error({ message: "Enter City" });
      return;
    }
    if (!state) {
      notification.error({ message: "Select State" });
      return;
    }
    if (!country) {
      notification.error({ message: "Select Country" });
      return;
    }
    if (!idProofType) {
      notification.error({ message: "Select ID Proof" });
      return;
    }
    if (idProofNumber === "") {
      notification.error({ message: "Enter Valid ID Proof Number" });
      return;
    }
    if (idProofNumber !== "" && idProofType !== "") {
      if (idProofType === "pan") {
        let txt = idProofNumber.toUpperCase();
        let regex = /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
        let pan = {
          C: "Company",
          P: "Personal",
          H: "Hindu Undivided Family (HUF)",
          F: "Firm",
          A: "Association of Persons (AOP)",
          T: "AOP (Trust)",
          B: "Body of Individuals (BOI)",
          L: "Local Authority",
          J: "Artificial Juridical Person",
          G: "Govt",
        };
        pan = pan[txt[3]];
        if (regex.test(txt)) {
          if (pan !== "Personal") {
            notification.error({ message: "Invalid Pan Card Number." });
            // clearLoadings();
            return;
          }
        } else {
          notification.error({ message: "Invalid Pan Card Number." });
          // clearLoadings();
          return;
        }
      } else if (idProofType === "voter") {
        let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({ message: "Invalid Voter Card Number." });
          // clearLoadings();
          return;
        }
      } else if (idProofType === "aadhar") {
        let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        if (!regex.test(idProofNumber.toUpperCase())) {
          notification.error({ message: "Invalid Aadhar Card Number." });
          // clearLoadings();
          return;
        }
      }
    }
    if (email !== "") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        notification.error({ message: "Invalid Email." });
        // clearLoadings();
        return;
      }
    }
    if (roomType === "KALYANA_MANDAPAM") {
      //
      let array = [];
      for (const property in kalayanaMandapam) {
        array.push(kalayanaMandapam[property].aadharNumber);
        if (
          kalayanaMandapam[property].name === "" ||
          kalayanaMandapam[property].dob === "" ||
          kalayanaMandapam[property].aadharNumber === ""
        ) {
          notification.error({
            message:
              "Enter all valid bride and groom including family details.",
          });
          return;
        }
      }
      if (muhurthamDate === "" || muhurthamTime === "") {
        notification.error({ message: "Enter muhurtham details." });
        return;
      }
      let isDuplicate = array.some((item, idx) => {
        return array.indexOf(item) != idx;
      });
      if (isDuplicate) {
        notification.error({ message: "All Aadhar cards must be unique." });
        return;
      }
    }
    if (templeDetails?.configuration?.enableAccommodationFingerScan) {
      if (captured?.ErrorCode != "0") {
        notification.error({ message: "Re-Capture Fingerprint" });
        return;
      }
    }

    setIsModalVisible(true);
    setDetails({
      selectedRoom,
      devoteeName,
      age,
      gender,
      phone,
      email,
      address,
      zip,
      city,
      state,
      country,
      idProofType,
      idProofNumber,
      persons,
      payment,
      cashReference,
      cashRemarks,
      roomType,
      printType,
      bookingType,
      startDate,
      kalayanaMandapam,
      muhurthamDate,
      muhurthamTime,
      verifiedCouponData,
      couponCode,
      usedBy,
      captured,
    });
  };

  const handleContextData = () => {
    setAddress(details?.address);
    setAge(details?.age);
    setDevoteeName(details?.devoteeName);
    setGender(details?.gender);
    setPhone(details?.phone);
    setEmail(details?.email);
    setZip(details?.zip);
    setCashReference(details?.cashReference);
    setCashRemarks(details?.cashReference);
    setState(details?.state);
    setCity(details?.city);
    setCountry(details?.country);
    setIdProofNumber(details?.idProofNumber);
    setIdProofType(details?.idProofType);
    setPayment(details?.payment);
    setPrintType(details?.printType ?? "a4");
    setBookingType(details?.bookingType);
    setStartDate(details?.startDate);
    setSelectedRoom(details?.selectedRoom);
    setVerifiedCouponData(details?.verifiedCouponData);
    setCouponCode(details?.couponCode);
    setUsedBy(details?.usedBy);
    setCaptured(details?.captured);

    if (details?.roomType === "KALYANA_MANDAPAM") {
      setMuhurthamDate(details?.muhurthamDate);
      setMuhurthamTime(details?.muhurthamTime);
      setkalayanaMandapam(details?.kalayanaMandapam);
    }
    setPersons(details?.persons);
    setRoomType(details?.roomType);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
          textAlign: "left",
        }}
      >
        <Row style={{ justifyContent: "start", alignItems: "end" }}>
          <Col style={{ width: "17.9%", marginRight: "2.6%" }}>
            <label style={labelStyles}>Booking Type </label>
            <br />{" "}
            <Select
              style={{ width: "100%" }}
              value={bookingType}
              onChange={(e) => {
                setRoomType("");
                setBookingType(e);
                if (e === "same-day") {
                  setStartDate(moment());
                } else if (e === "advance") {
                  setStartDate(moment().add(1, "d"));
                }
              }}
              placeholder="Select Booking Type"
            >
              <Select.Option value="same-day">SAME DAY</Select.Option>
              <Select.Option value="advance">ADVANCE</Select.Option>
              <Select.Option value="" style={{ display: "none" }}>
                Booking Type
              </Select.Option>
            </Select>
          </Col>
          <Col style={{ width: "17.9%" }}>
            <label style={labelStyles}>
              Booking Date{" "}
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />{" "}
            <DatePicker
              disabled={bookingType === "same-day" ? true : false}
              value={startDate}
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              disabledDate={(d) =>
                !d || d.isBefore(TmsStringUtils.addDays(new Date(), 0))
              }
              onChange={(date) => {
                setStartDate(date).format("YYYY-MM-DD");
              }}
              size="medium"
            />
          </Col>
          <Col style={{ width: "17.9%", marginLeft: "2.6%" }}>
            <label style={labelStyles}>
              No of Persons{" "}
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />{" "}
            <Input
              maxLength={220}
              style={{ width: "100%" }}
              onWheel={(event) => event.currentTarget.blur()}
              type="number"
              min="0"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
              }}
              key="edit"
              placeholder="0"
              value={persons}
              controls={false}
              step="false"
              onChange={(event) => {
                setPersons(event.target.value);
                setRoomTypeList([]);
                setRoomType("");
                setSelectedRoom(null);
              }}
            />
          </Col>

          <Col style={{ width: "17.9%", marginLeft: "2.6%" }}>
            <label style={labelStyles}>
              Room Type{" "}
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />{" "}
            <Select
              placeholder="Select Room Type"
              style={{ width: "100%" }}
              value={roomType}
              onChange={(e) => {
                setRoomType(e);
                setSelectedRoom(null);
              }}
            >
              <Select.Option value={""}>ALL</Select.Option>
              {roomTypeList?.map((item) => {
                return (
                  <Select.Option key={item} value={item}>
                    {roomMapData[item]}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
          <Col style={{ width: "17.9%", marginLeft: "2.6%" }}>
            <label style={labelStyles}>Print Type </label>
            <br />{" "}
            <Select
              style={{ width: "100%" }}
              value={printType}
              onChange={(e) => {
                setPrintType(e);
              }}
            >
              <Select.Option value="thermal">Thermal</Select.Option>
              <Select.Option value="a4">A4</Select.Option>
            </Select>
          </Col>
        </Row>
        <br />
        <Row style={{ width: "100%" }} className="roomsTable">
          <Table
            columns={columns}
            bordered={true}
            style={{ width: "100%" }}
            dataSource={
              roomType === ""
                ? blockCategoryData
                : blockCategoryData.filter((item) => item.roomType === roomType)
            }
            loading={tableLoading}
          />
        </Row>
        {selectedRoom?.roomType === "KALYANA_MANDAPAM" && (
          <>
            <br />
            <TmsPosKalyanaForm
              kalayanaMandapam={kalayanaMandapam}
              setkalayanaMandapam={setkalayanaMandapam}
              muhurthamDate={muhurthamDate}
              setMuhurthamDate={setMuhurthamDate}
              muhurthamTime={muhurthamTime}
              setMuhurthamTime={setMuhurthamTime}
            />
          </>
        )}

        <br />
        <TmsPosUserForm
          setDevoteeName={setDevoteeName}
          devoteeName={devoteeName}
          setGender={setGender}
          gender={gender}
          setAge={setAge}
          age={age}
          setPhone={setPhone}
          phone={phone}
          setEmail={setEmail}
          email={email}
          setAddress={setAddress}
          address={address}
          setZip={setZip}
          zip={zip}
          setCity={setCity}
          city={city}
          setState={setState}
          state={state}
          setCountry={setCountry}
          country={country}
          deviceInfo={deviceInfo}
          checkIfDeviceIsReady={checkIfDeviceIsReady}
        />
        <br />
        <Row
          style={{
            fontWeight: "400",
            fontSize: "20px",
          }}
        >
          ID Proof Details
        </Row>
        <br />
        <Row style={{ width: "100%", justifyContent: "space-between" }}>
          <Col style={{ width: "49%" }}>
            <label style={labelStyles}>
              ID Proof{" "}
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />{" "}
            <Select
              placeholder="Select ID Proof"
              style={{ width: "100%" }}
              value={idProofType}
              onChange={(e) => {
                setIdProofType(e);
              }}
            >
              {/* <Select.Option key="pan" value="pan">
                PAN
              </Select.Option> */}
              <Select.Option key="aadhar" value="aadhar">
                AADHAR
              </Select.Option>
              {/* <Select.Option key="voter" value="voter">
                Voter ID
              </Select.Option> */}
            </Select>
          </Col>
          <Col style={{ width: "48%" }}>
            <label style={labelStyles}>ID Proof Number</label>
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            <br />
            <Input
              maxLength={220}
              size="medium"
              type="text"
              placeholder="Enter ID Proof No"
              style={{ width: "100%" }}
              value={idProofNumber}
              onChange={(e) => {
                if (e.target.value?.length > 12) {
                  return;
                }
                setIdProofNumber(`${e.target.value?.toUpperCase()}`);
              }}
            />
          </Col>
        </Row>
        <br />
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Col style={{ width: "48%" }}>
            <Row
              style={{
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              Coupon Details
            </Row>
            <br />
            <Row style={{ justifyContent: "center", width: "100%" }}>
              <TmsPosCoupon
                width="100%"
                Id={selectedRoom?.blockCategoryId}
                price={selectedRoom?.price}
                gst={selectedRoom?.gst}
                type="DONAR_ACCOMMODATION"
              />
            </Row>
          </Col>
          {templeDetails?.configuration?.enableAccommodationFingerScan && (
            <Col style={{ width: "48%" }}>
              <Row
                style={{
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                Biometric
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </Row>
              <br />
              <Card
                className="fingerprint"
                hoverable
                onClick={() => {
                  scanFingerPrint();
                }}
              >
                {loading ? (
                  <img src={biometric} />
                ) : (
                  <p
                    style={{
                      margin: "0",
                      color: `${
                        captured === null
                          ? "Black"
                          : captured?.ErrorCode === "0"
                          ? "Green"
                          : "Red"
                      }`,
                    }}
                  >
                    {captured === null
                      ? "Start Capture"
                      : captured?.ErrorCode === "0"
                      ? "Captured"
                      : "Re-Capture"}
                  </p>
                )}
              </Card>
            </Col>
          )}
        </Row>

        <br />
        <Row
          style={{
            fontWeight: "400",
            fontSize: "20px",
          }}
        >
          Payment Details
        </Row>
        <br />
        <Row justify="space-between">
          <Col style={{ width: "48%", justifyContent: "start" }}>
            <label style={labelStyles}>
              Payment Type
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />
            <Select
              size="medium"
              style={{ width: "100%" }}
              placeholder="Select payment type"
              optionFilterProp="children"
              value={payment}
              onChange={(e) => {
                setPayment(e);
              }}
            >
              <Select.Option key="CASH" value="CASH">
                Cash
              </Select.Option>
              <Select.Option key="UPI" value="UPI">
                UPI
              </Select.Option>
              <Select.Option key="CARD" value="CARD">
                Card
              </Select.Option>
              <Select.Option key="CHEQUE" value="CHEQUE">
                Cheque
              </Select.Option>
              <Select.Option key="DD" value="DD">
                DD
              </Select.Option>
            </Select>
          </Col>
          <Col style={{ width: "48%" }}>
            <label style={labelStyles}>Payment Reference ID </label>
            <br />
            <Input
              size="medium"
              type="text"
              maxLength={220}
              placeholder="Enter Payment ID"
              style={{ width: "100%" }}
              value={cashReference}
              onChange={(e) => {
                setCashReference(`${e.target.value}`);
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col style={{ width: "48%" }}>
            <label style={labelStyles}>Payment Remarks </label>
            <br />
            <Input
              maxLength={220}
              size="medium"
              type="text"
              placeholder="Enter Payment Remarks"
              style={{ width: "100%" }}
              value={cashRemarks}
              onChange={(e) => {
                setCashRemarks(`${e.target.value}`);
              }}
            />
          </Col>
        </Row>
        <TmsModal
          visible={isModalVisible}
          width={850}
          footer={null}
          closable={false}
          centered
        >
          <>
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              {details?.bookingType === "same-day" ? (
                "Deposit Amount Received ?"
              ) : (
                <>
                  Are you sure ?<br />
                  Deposit Amount will be collected at the time of checkin
                </>
              )}
            </Row>
            <Row style={{ justifyContent: "center", margin: "auto" }}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    router.navigate(tmsRouteMap.pos_accommodation_rooms);
                  }}
                >
                  Yes
                </Button>
                <Button onClick={() => setIsModalVisible(false)}>No</Button>
              </Space>
            </Row>
          </>
        </TmsModal>
      </div>
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "#FFFCDC",
        }}
      >
        <Row
          style={{
            alignItems: "center",
            textAlign: "left",
            padding: "10px 50px",
            justifyContent: "space-between",
          }}
          gutter={[20, 20]}
        >
          <Col
            className="amountLabelStyles"
            style={{
              fontSize: "22px",
            }}
          >
            {" "}
            Room Price : Rs. {Number(selectedRoom?.price ?? 0)}.00
          </Col>
          <Col
            className="amountLabelStyles"
            style={{
              fontSize: "22px",
            }}
          >
            GST Amount : Rs. {Number(selectedRoom?.gst ?? 0)}.00
          </Col>
          <Col
            className="amountLabelStyles"
            style={{
              fontSize: "22px",
            }}
          >
            Deposit Amount : Rs. {Number(selectedRoom?.depositAmount ?? 0)}.00
          </Col>
          <Col
            className="amountLabelStyles"
            style={{
              fontSize: "22px",
            }}
          >
            Maintenance Amount : Rs.{" "}
            {Number(selectedRoom?.maintenanceCharge ?? 0)}.00
          </Col>
          {verifiedCouponData && (
            <Col
              className="amountLabelStyles"
              style={{
                fontSize: "22px",
              }}
            >
              Discount Amount : Rs.{" "}
              {Number(
                verifiedCouponData?.gstDiscountAmount +
                  verifiedCouponData?.priceDiscountAmount
              )}
            </Col>
          )}
        </Row>
        {selectedRoom?.price > 0 && (
          <>
            <Divider style={{ margin: "0" }} />
            <Row
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "500",
                padding: "10px 50px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  textAlign: "left",
                  margin: "0",
                }}
              >
                Total Amount :
              </p>
              <p
                style={{
                  fontSize: "22px",
                  margin: "0",
                  textAlign: "right",
                }}
              >
                Rs.{" "}
                {Number(
                  selectedRoom?.price +
                    selectedRoom?.depositAmount +
                    selectedRoom?.gst +
                    selectedRoom?.maintenanceCharge -
                    (verifiedCouponData?.gstDiscountAmount ?? 0) -
                    (verifiedCouponData?.priceDiscountAmount ?? 0)
                )}
              </p>
            </Row>
          </>
        )}
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                tableLoading === false && handleSubmit();
              }}
              loading={tableLoading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default withRouter(TmsPosAccommodation);
