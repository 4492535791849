import { Button, Card, Col, Input, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import catchErrorFunction from "../../../../../../utils/tms-catch-error/tms-catch-error";
import TmsImageUpload from "../../../../../../components/tms-customs/tms-image-upload";
import TmsAccommodationService from "../../../../../../services/tms-accommodation.service";
import { useContext } from "react";
import { BaseContext } from "../../../../../../contexts/BaseContext";

const TmsAccInnerPagesManageBlockADD = ({
  router,
  editFlag,
  roomCategorytId,
  setEditFlag,
  viewAccommodationData,
  settmsModalVisible,
}) => {
  const { roomMapData } = useContext(BaseContext);
  const [loading, setLoading] = useState(false);
  const [onlineAvailableRooms, setOnlineAvailableRooms] = useState("");
  const [protocolAvailableRooms, setProtocolAvailableRooms] = useState("");
  const [counterAvailableRooms, setCounterAvailableRooms] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const advBookingprice = 0;
  const [maintenanceAmount, setMaintanenceAmount] = useState(0);

  const [roomType, setRoomType] = useState("AC");
  const [noOfPeople, setNoOfPeople] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [price, setprice] = useState("");
  const [fileList, setFileList] = useState([]);
  const [changeRequestFile, setChangeRequestFile] = useState([]);
  const [changeRequestDesc, setChangeRequestDesc] = useState("");

  const handleSumit = async () => {
    setLoading(true);
    if (
      onlineAvailableRooms === null ||
      onlineAvailableRooms === "" ||
      counterAvailableRooms === null ||
      counterAvailableRooms === "" ||
      protocolAvailableRooms === null ||
      protocolAvailableRooms === "" ||
      gstAmount === null ||
      gstAmount === "" ||
      depositAmount === "" ||
      roomType === "" ||
      price === "" ||
      fileList.length === 0 ||
      noOfPeople === "" ||
      noOfPeople === 0
    ) {
      notification.error({ message: "Fill all the fields" });
      setLoading(false);
      return;
    }
    if (editFlag) {
      if (changeRequestFile?.length === 0 || changeRequestDesc === "") {
        notification.error({ message: "Fill all the fields" });
        setLoading(false);

        return;
      }
    }
    let body = {
      roomType: roomType,
      imageUrl: fileList[0].url,
      onlineAvailableRooms: onlineAvailableRooms,
      counterAvailableRooms: counterAvailableRooms,
      protocolAvailableRooms: protocolAvailableRooms,
      noOfPersons: noOfPeople,
      depositAmount: depositAmount,
      price: price,
      gstAmount: gstAmount,
      accBlockId: Number(
        new URLSearchParams(router?.location.search).get("id")
      ),
      maintenanceCharge: maintenanceAmount,
      advanceBookingCharge: advBookingprice,
    };
    if (editFlag) {
      body.changeRequestPdfUrl = changeRequestFile[0].url;
      body.changeDescription = changeRequestDesc;
    }
    try {
      if (editFlag) {
        await TmsAccommodationService.editBlockCategory(roomCategorytId, body);
        notification.success({
          message: "Accommodation Block Category Edited Successful",
        });
        setEditFlag(false);
      } else {
        await TmsAccommodationService.createNewBlockCategory(body);
        notification.success({
          message: "Accommodation Block Category Successful",
        });
        handleFuction();
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const handleFuction = () => {
    setOnlineAvailableRooms("");
    setCounterAvailableRooms("");
    setProtocolAvailableRooms("");
    setGstAmount("");
    setRoomType("");
    setDepositAmount("");
    setNoOfPeople("");
    setprice("");
    setFileList([]);
    setMaintanenceAmount(0);
    setChangeRequestFile([]);
    setChangeRequestDesc("");
  };
  useEffect(() => {
    if (editFlag) {
      setOnlineAvailableRooms(
        Number(viewAccommodationData.onlineAvailableRooms)
      );
      setCounterAvailableRooms(
        Number(viewAccommodationData.counterAvailableRooms)
      );
      setProtocolAvailableRooms(
        Number(viewAccommodationData.protocolAvailableRooms)
      );
      setGstAmount(viewAccommodationData.gstAmount);
      setRoomType(viewAccommodationData.roomType);
      setNoOfPeople(viewAccommodationData.noOfPersons);
      setDepositAmount(viewAccommodationData.depositAmount);
      setprice(viewAccommodationData.price);
      setFileList([{ url: viewAccommodationData.imageUrl, status: "done" }]);
      setMaintanenceAmount(viewAccommodationData.maintenanceCharge);
    }
  }, [viewAccommodationData]);
  return (
    <Row>
      <Card style={{ minWidth: "700px" }}>
        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Room Type</label>
          <Select
            className="formInput"
            onChange={(e) => {
              setRoomType(e);
            }}
            defaultValue={roomType}
            value={roomType}
          >
            {roomMapData?.map((item) => {
              return (
                <Select.Option key={item?.id} value={item?.id}>
                  {item?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel" style={{ marginTop: "20px" }}>
            Available Rooms
          </label>
          <Col className="formCols-3 pl-0">
            <label>Online </label>
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              style={{ width: "100%" }}
              placeholder="Online"
              value={onlineAvailableRooms}
              onChange={(e) => setOnlineAvailableRooms(Number(e.target.value))}
            />
          </Col>

          <Col className="formCols-3 pl-0 pr-0">
            <label>POS </label>
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              style={{ width: "100%" }}
              placeholder="Offline"
              value={counterAvailableRooms}
              onChange={(e) => setCounterAvailableRooms(Number(e.target.value))}
            />
          </Col>

          <Col className="formCols-3 pr-0">
            <label>Protocol </label>
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              style={{ width: "100%" }}
              placeholder="Online"
              value={protocolAvailableRooms}
              onChange={(e) =>
                setProtocolAvailableRooms(Number(e.target.value))
              }
            />
          </Col>
        </Row>

        <Row className="formRow">
          <label className="formLabel">No of People</label>
          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={noOfPeople}
            onChange={(e) => setNoOfPeople(Number(e.target.value))}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Room Price</label>
          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={price}
            onChange={(e) => setprice(Number(e.target.value))}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Maintanence Fee</label>
          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={maintenanceAmount}
            onChange={(e) => setMaintanenceAmount(Number(e.target.value))}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">GST Price</label>
          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={gstAmount}
            onChange={(e) => setGstAmount(Number(e.target.value))}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Deposit Price</label>
          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={depositAmount}
            onChange={(e) => setDepositAmount(Number(e.target.value))}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Image</label>
          <TmsImageUpload
            fileList={fileList}
            setFileList={setFileList}
            router={router}
            accept={false}
          />
        </Row>
        {editFlag && (
          <>
            <Row gutter={[20, 20]} className="formRow">
              <label className="formLabel">Request Copy</label>
              <Col className="formInput ">
                <TmsImageUpload
                  fileList={changeRequestFile}
                  setFileList={setChangeRequestFile}
                  router={router}
                  type="private"
                  accept={true}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="formRow">
              <label className="formLabel">Edit Description</label>
              <Input
                maxLength={220}
                className="formInput"
                value={changeRequestDesc}
                onChange={(e) => setChangeRequestDesc(e.target.value)}
              />
            </Row>
          </>
        )}
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              Reset
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                handleSumit();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsAccInnerPagesManageBlockADD);
