import {
  Button,
  Col,
  Divider,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  leaseAddtionalStates,
  leaseCreationStates,
} from "../../../../../../constants/states-data.data";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import {
  BillAdjustment,
  InstitutionSelect,
  LeaseInvoice,
  SortTypeAndBy,
  SurveyNoSelect,
} from "../../../../../../components/tms-customs/rents-and-leases/index";
import TmsModal from "../../../../../../components/tms-customs/tms-modal";
import { useReactToPrint } from "react-to-print";
import { DeleteOutlined } from "@ant-design/icons";
import "../../../../../../assets/css/tms-common/tms-rents.scss";
import PastInvoices from "../../../../../../components/tms-customs/rents-and-leases/past-invoices";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";
const TmsSmRalLandLeaseList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState({});
  const [view, setView] = useState(false);
  const [adjustedAmount, setAdjustedAmount] = useState("");
  const [adjustedAmountRemarks, setAdjustedAmountRemarks] = useState("");
  const refContainer = useRef(null);
  const [pastTxnsFlag, setPastTxnFlag] = useState(false);

  const {
    selectedInstitution,
    loading,
    deleteLoading,
    clearStates,
    fetchInstitutionList,
    fetchSurveyNumbers,
    selectedSurveyNumber,
    fetchLandLeases,
    createdLandLeaseData,
    handleChangeStatus,
    handleArrearBillUpdate,
    deleteCreatedLease,
    fetchLeaseInvoices,
    excelLoading,
    handleExcelExport,
  } = useRentsAndLandStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  useEffect(() => {
    return () => {
      clearStates(leaseCreationStates, leaseAddtionalStates);
    };
  }, []);
  useEffect(() => {
    router.location.pathname === tmsRouteMap.sm_ral_lands_land_lease_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution) {
      fetchSurveyNumbers(1, 200, "ASC", "createdAt", router);
    }
  }, [sortBy, tableCurrentPage, tablePageSize, sortType, selectedInstitution]);

  useEffect(() => {
    if (selectedSurveyNumber !== "" && selectedSurveyNumber) {
      fetchLandLeases(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
    }
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedSurveyNumber,
    update,
  ]);

  const switchTrigger = async (record, status) => {
    await handleChangeStatus(record, status, router);
    setUpdate(Math.random() * 100000 + 21);
  };
  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "tms-lease-container",
    onAfterPrint: () => {
      setView(false);
    },
  });

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <InstitutionSelect span={5} />
        <SurveyNoSelect span={5} />
        <SortTypeAndBy
          span={5}
          sortType={sortType}
          setSortType={setSortType}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        {selectedSurveyNumber !== "" && selectedSurveyNumber && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() => handleExcelExport("lease", router)}
              top={false}
            />
          </Col>
        )}
      </Row>
      <br />
      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          { title: "Lesse Name", dataIndex: "lesseName" },
          { title: "Mobile Number", dataIndex: "mobileNumber" },

          {
            title: "Survey No",
            dataIndex: "surveyNumber",
            render: (record) => record?.surveyNo,
          },
          {
            title: "Breakup No",
            dataIndex: "surveyNumberBreakup",
            render: (record) => record?.blockNumber,
          },
          {
            title: "Extent",
            dataIndex: "extent",
          },
          { title: "Location", dataIndex: "state" },
          {
            title: "Village/Mandal",
            render: (record) => `${record?.village}/${record?.mandal}`,
          },
          {
            title: "Lease Period From & To",
            render: (record) =>
              `${moment(record?.leasePeriodStartDate).format(
                "DD-MM-YYYY"
              )} to ${moment(record?.leasePeriodEndDate).format("DD-MM-YYYY")}`,
          },
          {
            title: "Due Date",
            dataIndex: "dueDate",
          },
          {
            title: "Lease Rate",
            dataIndex: "leaseRate",
          },
          {
            title: "Arrear Amount",
            dataIndex: "arrear",
            render: (record) => record?.arrearAmount ?? 0,
          },
          {
            title: "Advance Amount",
            dataIndex: "depositAmount",
          },
          {
            title: "Payment type",
            dataIndex: "paymentType",
          },

          {
            title: "Approval Status",
            dataIndex: "leaseApprovedByCompetentAuthority",
            render: (record) => (record ? `Approved` : `Un-Approved`),
          },

          {
            title: "Status",
            key: "status",
            render: (record) => {
              return (
                <Switch
                  size={"small"}
                  checked={record.status}
                  onClick={() => {
                    switchTrigger(record?.id, !record.status);
                  }}
                />
              );
            },
          },
          {
            title: "Actions",
            key: "status",
            render: (record) => {
              return (
                <Space>
                  {/* {record.lastInvoiceGeneratedOn === null ? (
                    ""
                  ) : ( */}
                  <a
                    onClick={async () => {
                      try {
                        let records = await fetchLeaseInvoices(
                          record.id,
                          true,
                          1,
                          200,
                          "ASC",
                          "createdAt",
                          router
                        );
                        if (!records?.id) {
                          notification.error({
                            message: "No latest invoices are generated",
                          });
                          return;
                        } else {
                          setView(true);
                          setEditData(records);
                        }
                      } catch (e) {
                        console.log(e);
                        setView(false);
                        notification.error({
                          message: "No latest invoices",
                        });
                        setEditData({});
                      }
                    }}
                  >
                    Invoice
                  </a>{" "}
                  <Divider type="vertical" />
                  <a
                    onClick={() => {
                      setEditData(record);
                      setPastTxnFlag(true);
                    }}
                  >
                    Invoice History
                  </a>
                  {/* )} */}
                  <Divider type="vertical" />
                  <a
                    onClick={() => {
                      setEditFlag(true);
                      setEditData(record);
                    }}
                  >
                    Bill Adjustment
                  </a>{" "}
                  <Divider type="vertical" />
                  <Space size="middle">
                    <Popconfirm
                      title="Are you sure delete this lease?"
                      onConfirm={async () => {
                        await deleteCreatedLease(record.id, router);
                        setUpdate(Math.random() * 100000 + 21);
                      }}
                      okText="Yes"
                      cancelText="No"
                      loading={deleteLoading}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Space>{" "}
                </Space>
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={createdLandLeaseData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdLandLeaseData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={record?.eoProceedingUploadDocument}
                alt=""
              >
                Uploaded EO Doc
              </a>
            );
          },
        }}
      ></Table>
      <TmsModal
        visible={view}
        width={"50%"}
        onOk={() => {
          setView(false);
          setEditData({});
        }}
        closable={true}
        centered
        onCancel={() => {
          setView(false);
          setEditData({});
        }}
      >
        <Row justify={"center"}>
          <Button onClick={handlePrint} type="primary">
            Print
          </Button>
        </Row>
        <br />
        <LeaseInvoice
          data={editData}
          type="land-lease"
          refContainer={refContainer}
          scantype={"payment"}
        />
      </TmsModal>
      <BillAdjustment
        type="land-lease"
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        loading={loading}
        editData={{
          surveyNumber: editData?.surveyNumber?.surveyNo,
          surveyBreakup: editData?.surveyNumberBreakup?.blockNumber,
          lesseName: editData?.lesseName,
          lesseType: editData?.lesseType,
          leaseAmount: editData?.leaseRate,
          arrearAmount: editData?.arrear?.arrearAmount,
          arrearId: editData?.arrear?.id,
          landLeaseId: editData?.id,
        }}
        setEditData={setEditData}
        adjustedAmount={adjustedAmount}
        setAdjustedAmount={setAdjustedAmount}
        adjustedAmountRemarks={adjustedAmountRemarks}
        setAdjustedAmountRemarks={setAdjustedAmountRemarks}
        handleArrearBillUpdate={handleArrearBillUpdate}
        setUpdate={setUpdate}
        router={router}
      />
      {pastTxnsFlag && (
        <PastInvoices
          type="land-lease"
          pastTxnFlag={pastTxnsFlag}
          fetchLeaseInvoices={fetchLeaseInvoices}
          setPastTxnFlag={setPastTxnFlag}
          editData={editData}
          setEditData={setEditData}
          router={router}
        />
      )}
    </>
  );
};

export default withRouter(TmsSmRalLandLeaseList);
