import { Button, Card, Checkbox, Col, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsEntryScanService from "../../../../../services/tms-entry-scan.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";

const TmsESInnerPagesAND = ({
  router,
  editFlag,
  productId,
  setEditFlag,
  viewEntryScanData,
  settmsModalVisible,
}) => {
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [entryScanName, setEntryScanName] = useState("");
  const [description, setdescription] = useState("");
  const [counterEnabled, setcounterEnabled] = useState(false);
  // const [changeRequestFile, setChangeRequestFile] = useState([]);
  // const [changeRequestDesc, setChangeRequestDesc] = useState("");
  const [fingerPrintEnabled, setFingerPrintEnabled] = useState(false);

  const entryScanApi = async () => {
    setLoading(true);

    if (
      entryScanName === "" ||
      description === "" ||
      fingerPrintEnabled === ""
    ) {
      notification.error({ message: "Fill all the fields" });
      setLoading(false);
      return;
    }
    // if (editFlag) {
    //   if (changeRequestFile?.length === 0 || changeRequestDesc === "") {
    //     notification.error({ message: "Fill all the fields" });
    //     setLoading(false);

    //     return;
    //   }
    // }
    let body = {
      name: entryScanName,
      description: description,
      counterEnabled: counterEnabled,
      isFingerPrintRequired: fingerPrintEnabled,
    };
    // if (editFlag) {
    //   body.changeRequestPdfUrl = changeRequestFile[0].url;
    //   body.changeDescription = changeRequestDesc;
    // }
    try {
      if (editFlag) {
        await TmsEntryScanService.editEntryScan(productId, body);
        notification.success({ message: "Entry Scan Edited Successful" });
        setEditFlag(false);
      } else {
        await TmsEntryScanService.createEntryScan(body);
        notification.success({ message: "Entry Scan Created Successful" });
        handleFuction();
      }
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    setEntryScanName("");
    setdescription("");
    setcounterEnabled(false);
    // setChangeRequestFile([]);
    // setChangeRequestDesc("");
  };
  useEffect(() => {
    if (editFlag) {
      setEntryScanName(viewEntryScanData?.name);
      setdescription(viewEntryScanData?.description);
      setcounterEnabled(viewEntryScanData?.counterEnabled);
      setFingerPrintEnabled(viewEntryScanData?.isFingerPrintRequired);
    }
  }, [viewEntryScanData]);
  return (
    <Row style={{ width: "100%", minWidth: "500px" }}>
      <Card style={{ width: "80%" }}>
        <Row className="formRow">
          <label className="formLabel">Entry Scan Name</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={entryScanName}
            onChange={(e) => setEntryScanName(e.target.value)}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">Description</label>
          <TextArea
            maxLength={2000}
            className="formInput"
            rows={4}
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel" style={{ color: "white" }}>
            checkBox
          </label>
          <Col className="formCols-2 contantCenter">
            <label style={{ paddingRight: "10px" }}>Enable POS</label>
            <Checkbox
              style={{ paddingRight: "10px" }}
              checked={counterEnabled}
              onChange={(e) => setcounterEnabled(e.target.checked)}
            />
          </Col>
          <Col className="formCols-2 contantCenter">
            <label style={{ paddingRight: "10px" }}>Enable Fingerprint</label>
            <Checkbox
              checked={fingerPrintEnabled}
              onChange={(e) => setFingerPrintEnabled(e.target.checked)}
            />
          </Col>
        </Row>
        {/* {editFlag && (
          <>
            <Row gutter={[20, 20]} className="formRow">
              <label className="formLabel">Request Copy</label>
              <Col className="formInput ">
                <TmsImageUpload
                  fileList={changeRequestFile}
                  setFileList={setChangeRequestFile}
                  router={router}
                  type="private"
                  accept={true}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="formRow">
              <label className="formLabel">Edit Description</label>
              <Input
                className="formInput"
                value={changeRequestDesc}
                onChange={(e) => setChangeRequestDesc(e.target.value)}
              />
            </Row>
          </>
        )} */}
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                entryScanApi();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsESInnerPagesAND);
