import { Button, Col, Divider, Input, Select, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
// import tmsRouteMap from "../../constants/tms-route.map";
import { BaseContext } from "../../contexts/BaseContext";
import TmsUserService from "../../services/tms-user/tms-user.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import TmsCheckBoxList from "../tms-customs/tms-checkbox-list";
import TmsModal from "../tms-customs/tms-modal";
import TmsRowVertical from "../tms-customs/tms-row-vertical";
import TmsTempleService from "../../services/tms-temple.service";
import TmsImageUpload from "../tms-customs/tms-image-upload";
import TextArea from "antd/lib/input/TextArea";
const TmsUMAssignModal = ({
  setUpdateFlag,
  showAssignModal,
  setShowAssignModal,
  assignPermissionsFlag,
  editPermissionsFlag,
  setEditPermissionsFlag,
  setAssignPermissionsFlag,
  editData,
  router,
  transfer,
  setTransfer,
}) => {
  const [temples, setTemples] = useState([]);
  const [userPhone, setUserPhone] = useState();
  const [loading, setLoading] = useState(false);
  const [permissionsData, setPermissionsData] = useState([]);
  const [userPermissionData, setUserPermissionData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { userDetails } = useContext(BaseContext);
  const [changeRequestFile, setChangeRequestFile] = useState([]);
  const [selectedTemple, setSelectedTemple] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  useEffect(() => {
    if (assignPermissionsFlag === true || editPermissionsFlag === true) {
      fetchAllPermissions();
    }
  }, [assignPermissionsFlag, editPermissionsFlag]);

  useEffect(() => {
    if (editPermissionsFlag) {
      setUserPhone(editData.phone);
      fetchUsersPermissionsByID(editData?.userId);
    }
  }, [editPermissionsFlag]);

  useEffect(() => {
    if (userDetails?.permissions.includes("SUPER_ADMIN")) {
      getAllTemples();
    }
  }, [userDetails]);

  const getAllTemples = async () => {
    try {
      const temples = await TmsTempleService.getAllTempleDetails();
      setTemples(temples);
    } catch (e) {
      catchErrorFunction(e, "Token Expired! Login again.", router);
    }
  };

  const clearStates = () => {
    setEditPermissionsFlag(false);
    setUserPhone("");
    setPermissionsData([]);
    setUserPermissionData([]);
    setButtonLoading(false);
    setAssignPermissionsFlag(false);
    setUpdateFlag(Math.random() * 100 + 8);
    setShowAssignModal(false);
    setTransfer(false);
  };

  const handleAssignUser = async () => {
    setButtonLoading(true);
    if (userPhone === "") {
      notification.error({ message: "Please Enter Number" });
      setButtonLoading(false);
      return;
    }
    if (userPhone.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      setButtonLoading(false);
      return;
    }
    try {
      await TmsUserService.updatePermissions(
        userPermissionData,
        assignPermissionsFlag ? `+91${userPhone}` : editData?.userId,
        assignPermissionsFlag
      );
      clearStates();
    } catch (error) {
      setButtonLoading(false);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
  };

  const transferUser = async () => {
    setButtonLoading(true);
    try {
      await TmsUserService.transferUser(
        selectedTemple?.id,
        editData?.userId,
        userPermissionData
      );
      clearStates();
    } catch (error) {
      setButtonLoading(false);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
  };

  const fetchAllPermissions = async () => {
    setLoading(true);
    try {
      const response = await TmsUserService.userPermissions();
      setPermissionsData(response);
    } catch (error) {
      // console.log(error.response);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const updateSelectedRoles = (role) => {
    let newRoles = [];
    let levelCount = 0;
    if (userPermissionData?.includes(role)) {
      newRoles = userPermissionData.filter((e) => e !== role);
    } else {
      newRoles = [...userPermissionData];
      if (role?.includes("LEVEL")) {
        userPermissionData?.map((item) => {
          if (item?.includes("LEVEL")) {
            levelCount++;
            notification?.error({
              message: "Please select only one of the protocol level",
            });
            return;
          }
        });
      }
      if (levelCount < 1) {
        newRoles?.push(role);
      }
    }

    setUserPermissionData(newRoles);
  };
  const handleClose = () => {
    clearStates();
  };
  const fetchUsersPermissionsByID = async (userId) => {
    setLoading(true);
    try {
      const response = await TmsUserService.getUserPermissionsById(userId);
      setUserPermissionData(response?.permissions);
    } catch (error) {
      // console.log(error.response);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  return (
    <TmsModal
      width={transfer ? "500px" : "1000px"}
      visible={showAssignModal}
      title={
        editPermissionsFlag
          ? transfer
            ? "Transfer User To Another Temple"
            : "Edit User Permissions"
          : "Assign User Permissions"
      }
      onOk={transfer ? transferUser : handleAssignUser}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={buttonLoading}
          onClick={() => {
            transfer ? transferUser() : handleAssignUser();
          }}
        >
          Submit
        </Button>,
      ]}
    >
      {loading ? (
        "loading"
      ) : (
        <>
          {transfer ? (
            <>
              <TmsRowVertical
                data={{
                  one: "From",
                  two: (
                    <Input
                      value={
                        JSON.parse(localStorage.getItem("templeDetails"))?.name
                      }
                      disabled={true}
                    />
                  ),
                  justify: "start",
                  style: { width: "100%", padding: "10px 0px" },
                }}
              />

              <TmsRowVertical
                data={{
                  one: "To",
                  two: (
                    <Select
                      style={{ width: "100%" }}
                      defaultValue={selectedTemple?.name}
                      showSearch
                      value={selectedTemple?.name}
                      onChange={(e) => {
                        let findTemple = temples.find(
                          (item) => item.name === e
                        );
                        setSelectedTemple(findTemple);
                      }}
                    >
                      {temples?.map((item) => {
                        return (
                          <Select.Option key={item?.name} value={item?.name}>
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  ),
                  justify: "start",
                  style: { width: "100%", padding: "10px 0px" },
                }}
              />
              <TmsRowVertical
                data={{
                  one: "Order Copy",
                  two: (
                    <Col style={{ width: "100%" }}>
                      <TmsImageUpload
                        fileList={changeRequestFile}
                        setFileList={setChangeRequestFile}
                        router={router}
                        type="private"
                        accept={true}
                        className="w-100"
                      />
                    </Col>
                  ),
                  justify: "start",
                  style: { width: "100%", padding: "10px 0px" },
                }}
              />
              <TmsRowVertical
                data={{
                  one: "Remark",
                  two: (
                    <Col style={{ width: "100%" }}>
                      <TextArea />
                    </Col>
                  ),
                  justify: "start",
                  style: { width: "100%", padding: "10px 0px" },
                }}
              />
            </>
          ) : (
            <>
              <TmsRowVertical
                data={{
                  one: "Phone",
                  two: (
                    <Input
                      type="number"
                      min="0"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      onWheel={(event) => event.currentTarget.blur()}
                      maxLength={10}
                      addonBefore={"+91"}
                      placeholder="Please Enter Mobile Number"
                      style={{ width: "100%" }}
                      value={userPhone}
                      onChange={(e) => {
                        if (e.target.value.length > 10) {
                          return;
                        }
                        setUserPhone(`${e.target.value}`);
                      }}
                      disabled={editPermissionsFlag ? true : false}
                    />
                  ),
                  justify: "start",
                  style: { width: "100%", padding: "10px 0px" },
                }}
              />
              <Divider style={{ margin: "10px 0px" }} />
              <TmsCheckBoxList
                data={permissionsData}
                userDetails={userDetails}
                selectedData={userPermissionData}
                name="Permissions"
                updateItems={updateSelectedRoles}
                gutter={[20, 20]}
                style={{ width: "100%", padding: "10px 0px" }}
              />
            </>
          )}
        </>
      )}
    </TmsModal>
  );
};
export default withRouter(TmsUMAssignModal);
