import { notification } from "antd";
import moment from "moment";
import { create } from "zustand";
import {
  additionalArrearStates,
  additionalBreakup,
  additionalEncroahmentStates,
  additionalSurveyStates,
  arrearStates,
  breakupSurveyNoStates,
  createLandStates,
  createSurveyNoStates,
  encroachmentStates,
  leaseAddtionalStates,
  leaseCreationStates,
} from "../constants/states-data.data";
import TmsRentsService from "../services/tms-rents.service";
import catchErrorFunction from "../utils/tms-catch-error/tms-catch-error";
import { exportExcel } from "../utils/tms-excel/tms-excel";
const regex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[0-9A-Z]{1}/;
const useRentsAndLandStore = create((set, get) => {
  const initialState = {
    loading: false,
    submitLoading: false,
    excelLoading: false,
    village: "",
    status: false,
    mandal: "",
    district: "",
    country: "India",
    state: "",
    zip: "",
    totalLandAcres: "",
    totalLandCents: "",
    agriculturalAcres: "",
    agriculturalCents: "",
    nonAgriculturalAcres: "",
    nonAgriculturalCents: "",
    holdingStatus: false,
    landmark: "",
    category: "",
    institutionName: "",
    typeOfLand: "",
    surveyNumber: "",
    pattadarPassbook: "",
    totalLandValue: "",
    pattadarPassbookDocument: [],
    pattadarPassbookReason: "",
    natureOfLand: "",
    landArea: "",
    landValue: "",
    blockNumber: "",
    geoTagging: "",
    waterSource: "",
    donorName: "",
    donorPhone: "",
    donorAddress: "",
    DonorRelationName: "",
    donorVillage: "",
    donorDistrict: "",
    donorCity: "",
    donorCountry: "",
    donorState: "",
    donorPincode: "",
    donorDocType: "",
    donorDocNo: "",
    donorDoc: [],
    remarks: "",
    extent: "",
    reason: "",
    file: "",
    legalAction: false,
    encroachmentDurationInDays: "",
    encroacherAddress: "",
    encroacherName: "",
    deleteLoading: "",
    westBoundary: "",
    eastBoundary: "",
    northBoundary: "",
    southBoundary: "",
    //Data from API's & selected field
    institutionData: [],
    selectedInstitution: "",
    createdLandData: [],
    selectedLand: "",
    createdSurveryNumberData: [],
    surveyNoDataByLandId: [],
    selectedSurveyNumber: "",
    createdSNBreakupData: [],
    selectedSNBreakup: "",
    createdEncroachmentsData: [],
    selectedEncroachment: "",
    createdArrearsData: [],
    selectedArrear: "",
    createdLandLeaseData: [],
    selectedLandLease: "",
    encroachedAcres: "",
    encroachedCents: "",
    address: "",
    enchrochmentDuration: "",
    encroachmentDurationInMonths: "",
    casePDF: [],
    caseNo: "",
    legalReason: "",
    mobileNumber: "",
    //lease creation
    leasePeriodStartDate: moment(),
    leasePeriodEndDate: moment(),
    dueDate: "",
    paymentTerm: "",
    gstAmount: "",
    paymentType: "",
    kindItem: "",
    kindItemUnitsInKg: "",
    leaseRate: "",
    depositAmount: "",
    mrNo: "",
    mrDate: moment(),
    lesseType: "",
    lesseName: "",
    gstNo: "",
    incorporationOrPanNo: "",
    contact_person_name: "",
    gender: "",
    idProofType: "",
    idProofNumber: "",

    uploadIdProof: [],
    leaseApprovedByCompetentAuthority: "",
    uploadApprovedDocument: [],
    uploadApprovedDocumentOrderNo: "",
    uploadApprovedDocumentOrderDate: "",
    eoProceedingUploadDocument: [],
    //arrear
    arrearAmount: "",
    interestAmount: "",
    arrearPeriodInMonths: "",
    //reports-data
    leaseReports: [],
    landReports: [],
    encroachmentReport: [],
    arrearReport: [],
    arrayStates: [
      "eoProceedingUploadDocument",
      "uploadApprovedDocument",
      "uploadIdProof",
      "pattadarPassbookDocument",
      "donorDoc",
      "casePDF",
      "institutionData",
      "createdLandData",
      "createdSurveryNumberData",
      "createdLandLeaseData",
      "createdEncroachmentsData",
      "createdSNBreakupData",
      "surveyNoDataByLandId",
    ],
    datesArray: [
      "uploadApprovedDocumentOrderDate",
      "mrDate",
      "leasePeriodStartDate",
      "leasePeriodEndDate",
    ],
  };

  const services = {
    "survey-no-list": "surveyChangeStatus",
    "survey-breakup-list": "surveyBreakupChangeStatus",
    "land-lease-list": "landLeaseChangeStatus",
    "encroachment-list": "encroachmentChangeStatus",
  };

  const generateSetterFunctions = (stateObject) => {
    const setters = {};
    for (const key in stateObject) {
      setters[`set${key.charAt(0).toUpperCase()}${key.slice(1)}`] = (value) =>
        set({ [key]: value });
    }
    return setters;
  };

  return {
    ...initialState,
    ...generateSetterFunctions(initialState),
    //Actions
    checkForEmptyStates(array) {
      array.forEach((element) => {
        const value = get()[element];
        if (value === "") {
          throw new Error(
            `${element
              .split(/(?=[A-Z])/)
              .join(" ")} should not be empty. Please fill all the fields`
          );
        }
      });
    },
    clearStates(array, dataArrays) {
      const setters = {};
      const data = [...array, ...dataArrays];
      data.forEach((item) => {
        if (get().datesArray.includes(item)) {
          setters[item] = moment();
        } else if (get().arrayStates.includes(item)) {
          setters[item] = [];
        } else setters[item] = "";
      });
      set({ ...setters });
    },
    async fetchInstitutionList(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedInstitutions(
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          institutionData: res,
          loading: false,
          selectedInstitution: "",
          selectedLand: "",
          selectedSNBreakup: "",
          selectedSurveyNumber: "",
          createdSNBreakupData: [],
          createdSurveryNumberData: [],
          createdLandData: [],
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token Expired!!,Login Again", router);
        set({ loading: false });
        return;
      }
    },
    async deleteInstitution(id, router) {
      try {
        set({ loading: true });

        await TmsRentsService.deleteInstitution(id);
        notification.success({
          message: "Institution Deleted Successful",
        });
        set({ loading: false });

        // setUpdateFlag(Math.Random + 1);
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ loading: false });
      }
    },
    async fetchCreatedLandList(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedLands(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });

          return res.items;
        } else {
          set({
            createdLandData: res,
            loading: false,
            selectedSNBreakup: "",
            createdSNBreakupData: [],
            selectedSurveyNumber: "",
            createdSurveryNumberData: [],
          });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleCreateLand(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createLandStates);
        const body = {
          agricultureLandAcres: get().agriculturalAcres,
          agricultureLandCents: get().agriculturalCents,
          nonAgricultureLandAcres: get().nonAgriculturalAcres ?? 0,
          nonAgricultureLandCents: get().nonAgriculturalCents ?? 0,
          country: get().country,
          state: get().state,
          district: get().district,
          mandal: get().mandal,
          village: get().village,
          pincode: get().zip,
          landmark: get().landmark,
          // totalLandAcres:
          //   (Number(get().agriculturalAcres) ?? 0) +
          //   (Number(get().nonAgriculturalAcres) ?? 0),
          // totalLandCents:
          //   (Number(get().agriculturalCents) ?? 0) +
          //   (Number(get().nonAgriculturalCents) ?? 0),
          institutionId: get().selectedInstitution,
          status: get().status,
        };
        await TmsRentsService.createLands(body);
        await get().clearStates(createLandStates, []);
        notification.success({ message: "Land created successfully" });
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false, status: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedLand(landId, router) {
      try {
        set({ deleteLoading: true });
        await TmsRentsService.deleteCreatedLand(landId);
        notification.success({
          message: `Land with ID:${landId} Deleted Successful`,
        });
        set({ deleteLoading: false });
        get().fetchCreatedLandList(1, 1000, "ASC", "createdAt");
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchSurveyNumbers(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getSurveyNosByIstitution(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });

          return res.items;
        } else {
          set({
            createdSurveryNumberData: res,
            loading: false,
            selectedSNBreakup: "",
            createdSNBreakupData: [],
          });

          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchSurveyNumbersByLandID(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedSurveyNumbers(
          get().selectedLand.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          surveyNoDataByLandId: res,
          loading: false,
          selectedSNBreakup: "",
          createdSNBreakupData: [],
        });

        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleCreateSurveyNumber(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createSurveyNoStates);
        if (
          get().agriculturalAcres === 0 &&
          get().agriculturalCents === 0 &&
          get().nonAgriculturalAcres === 0 &&
          get().nonAgriculturalCents === 0
        ) {
          throw new Error(
            `All Agricultural and Non agricultural values should not be empty. Please fill all the fields`
          );
        }
        const body = {
          surveyNo: get().surveyNumber,
          typeOfLand: get().typeOfLand,
          pattadarPassbook: get().pattadarPassbook,
          natureOfLand: get().natureOfLand,
          totalLandValue: Number(get().totalLandValue) ?? 0,
          agricultureLandAcres: Number(get().agriculturalAcres) ?? 0,
          agricultureLandCents: Number(get().agriculturalCents) ?? 0,
          nonAgricultureLandAcres: Number(get().nonAgriculturalAcres) ?? 0,
          nonAgricultureLandCents: Number(get().nonAgriculturalCents) ?? 0,
          eastBoundary: get().eastBoundary,
          westBoundary: get().westBoundary,
          southBoundary: get().southBoundary,
          northBoundary: get().northBoundary,
          country: get().country,
          state: get().state,
          district: get().district,
          mandal: get().mandal,
          village: get().village,
          pincode: get().zip,
          geoTagging: get().geoTagging,
          waterSource: get().waterSource,
          donorName: get().donorName,
          fatherOrMotherOrSonOrWifeOf: get().DonorRelationName,
          contactNo: get().donorPhone,
          donorAddress: get().donorAddress,
          donorVillage: get().donorVillage,
          donorCity: get().donorCity,
          donorDistrict: get().donorDistrict,
          donorPincode: get().donorPincode,
          donorState: get().donorState,
          donorCountry: get().donorCountry,
          donorDocumentType: get().donorDocType,
          donorDocumentNumber: get().donorDocNo,
          uploadDocument: get().donorDoc[0]?.url,
          landId: get().selectedLand.id,
        };
        if (get().pattadarPassbook) {
          if (
            get().pattadarPassbookDocument.length === 0 ||
            get().pattadarPassbookDocument === ""
          ) {
            notification.error({
              message: "Patta Document must be Uploaded.Please Upload",
            });
            set({ submitLoading: false });
            return;
          } else {
            body.pattadarPassbookDocument =
              get().pattadarPassbookDocument[0]?.url;
          }
        } else {
          if (
            get().pattadarPassbookReason === "" ||
            !get().pattadarPassbookReason
          ) {
            notification.error({
              message:
                "Reason must be provided for not having a Patta Document",
            });
            set({ submitLoading: false });
            return;
          } else {
            body.pattadarPassbookReason = get().pattadarPassbookReason;
          }
        }
        if (get().remarks !== "") {
          body.remarks = get().remarks;
        }
        await TmsRentsService.createSurveyNumber(body);
        await get().clearStates(createSurveyNoStates, additionalSurveyStates);
        notification.success({ message: "Survey Number created successfully" });
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async handleChangeStatus(id, status, router) {
      set({ submitLoading: true });
      try {
        await TmsRentsService[
          services[router?.location?.pathname?.split("/")?.pop()]
        ](id, status);
        notification.success({ message: "Updated successfully" });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async handleCreateSurveyUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          surveyNo: record.surveyNo,
          typeOfLand: record.typeOfLand,
          pattadarPassbook: record.pattadarPassbook,
          natureOfLand: record.natureOfLand,
          totalLandValue: Number(record.totalLandValue) ?? 0,
          agricultureLandAcres: Number(record.agricultureLandAcres) ?? 0,
          agricultureLandCents: Number(record.agricultureLandCents) ?? 0,
          nonAgricultureLandAcres: Number(record.nonAgricultureLandAcres) ?? 0,
          nonAgricultureLandCents: Number(record.nonAgricultureLandCents) ?? 0,
          eastBoundary: record.eastBoundary,
          westBoundary: record.westBoundary,
          southBoundary: record.southBoundary,
          northBoundary: record.northBoundary,
          country: record.country,
          state: record.state,
          district: record.district,
          mandal: record.mandal,
          village: record.village,
          pincode: record.pincode,
          geoTagging: record.geoTagging,
          waterSource: record.waterSource,
          donorName: record.donorName,
          fatherOrMotherOrSonOrWifeOf: record.fatherOrMotherOrSonOrWifeOf,
          contactNo: record.contactNo,
          donorAddress: record.donorAddress,
          donorVillage: record.donorVillage,
          donorCity: record.donorCity,
          donorDistrict: record.donorDistrict,
          donorPincode: record.donorPincode,
          donorState: record.donorState,
          donorCountry: record.donorCountry,
          donorDocumentType: record.donorDocumentType,
          donorDocumentNumber: record.donorDocumentNumber,
          uploadDocument: record.uploadDocument,
          landId: record.landId,
          status: record.status,
        };
        if (record.pattadarPassbook) {
          if (
            record.pattadarPassbookDocument.length === 0 ||
            record.pattadarPassbookDocument === ""
          ) {
            notification.error({
              message: "Patta Document must be Uploaded.Please Upload",
            });
            set({ submitLoading: false });
            return;
          } else {
            body.pattadarPassbookDocument = record.pattadarPassbookDocument;
          }
        } else {
          if (
            record.pattadarPassbookReason === "" ||
            !record.pattadarPassbookReason
          ) {
            notification.error({
              message:
                "Reason must be provided for not having a Patta Document",
            });
            set({ submitLoading: false });
            return;
          } else {
            body.pattadarPassbookReason = record.pattadarPassbookReason;
          }
        }
        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }
        await TmsRentsService.updateSurveyNumber(body, record.id);
        notification.success({ message: "Survey Number updated successfully" });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedSurvey(surveyNo, router) {
      try {
        set({ deleteLoading: true });
        await TmsRentsService.deleteSurveyNumber(surveyNo);
        notification.success({
          message: `Survey with ID:${surveyNo} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchSurveyNumbersBreakUp(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedSurveyBreakUps(
          get().selectedSurveyNumber.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({ createdSNBreakupData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleBreakupSurveyNumber(router) {
      set({ submitLoading: true });
      try {
        const checkStates = [
          "blockNumber",
          "extent",
          "holdingStatus",
          "eastBoundary",
          "westBoundary",
          "southBoundary",
          "northBoundary",
          "geoTagging",
        ];
        get().checkForEmptyStates(checkStates);
        if (get().extent === "AGRICULTURE") {
          if (
            get().agriculturalAcres === "" ||
            get().agriculturalCents === ""
          ) {
            throw new Error(
              `Agricultural Values should not be empty. Please fill all the fields`
            );
          } else if (
            get().agriculturalAcres === 0 &&
            get().agriculturalCents === 0
          ) {
            throw new Error(
              `Both Agricultural Values should cannot be zero. Please fill all the fields`
            );
          }
        } else {
          if (
            get().nonAgriculturalAcres === "" ||
            get().nonAgriculturalCents === ""
          ) {
            throw new Error(
              `Non Agricultural Values should not be empty. Please fill all the fields`
            );
          } else if (
            get().nonAgriculturalAcres === 0 &&
            get().nonAgriculturalCents === 0
          ) {
            throw new Error(
              `Both Non Agricultural Values should cannot be zero. Please fill all the fields`
            );
          }
        }

        const body = {
          blockNumber: get().blockNumber,
          extent: get().extent,
          extentAcres:
            get().extent === "AGRICULTURE"
              ? get().agriculturalAcres
              : get().nonAgriculturalAcres,
          extentCents:
            get().extent === "AGRICULTURE"
              ? get().agriculturalCents
              : get().nonAgriculturalCents,

          holdingStatus: get().holdingStatus,
          eastBoundary: get().eastBoundary,
          westBoundary: get().westBoundary,
          southBoundary: get().southBoundary,
          northBoundary: get().northBoundary,
          geoTagging: get().geoTagging,
          status: get().status,
          surveyNumberId: get().selectedSurveyNumber.id,
        };

        if (get().remarks !== "") {
          body.remarks = get().remarks;
        }

        await TmsRentsService.createSurveyNumberBreakUp(body);
        await get().clearStates(breakupSurveyNoStates, additionalBreakup);
        notification.success({
          message: "Survey Breakup Number created successfully",
        });
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async handleBreakupSurveyUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          blockNumber: record?.blockNumber,
          extent: record?.extent,
          extentAcres: record.extentAcres,
          extentCents: record?.extentCents,
          holdingStatus: record?.holdingStatus,
          eastBoundary: record?.eastBoundary,
          westBoundary: record?.westBoundary,
          southBoundary: record?.southBoundary,
          northBoundary: record?.northBoundary,
          geoTagging: record?.geoTagging,
          status: record?.status,
          surveyNumberId: record?.surveyNumberId,
        };

        if (record?.remarks !== "") {
          body.remarks = record?.remarks;
        }

        await TmsRentsService.updateSurveyNumberBreakUp(body, record.id);
        notification.success({
          message: "Survey Breakup Number updated successfully",
        });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedSurveyBreakUp(surveyBKNo, router) {
      try {
        set({ deleteLoading: true });
        await TmsRentsService.deleteSurveyNumberBreakUp(surveyBKNo);
        notification.success({
          message: `Survey Breakup with ID:${surveyBKNo} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchEncroachments(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedEncroachment(
          get().selectedSurveyNumber?.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy,
          router
        );
        if (dataType === "return") {
          set({ loading: false });

          return res.items;
        } else {
          set({ createdEncroachmentsData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLandLeases(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedLandLeases(
          get().selectedSurveyNumber?.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });

          return res.items;
        } else {
          set({ createdLandLeaseData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLeaseInvoices(
      landleaseId,
      latest,
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getLandLeaseInvoices(
          landleaseId,
          latest,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({ loading: false });
        return res;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLeaseInvoiceByID(invoiceID, router) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getInvoiceByInvoiceID(invoiceID);
        set({ loading: false });
        return res;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleLeaseCreation(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(leaseCreationStates);
        if (get().mobileNumber.length !== 10) {
          throw new Error(`Mobile number must be 10 digits`);
        }
        if (!get().leaseApprovedByCompetentAuthority) {
          if (get().reason === "") {
            throw new Error(
              "Please enter the lease approval reason in reason field"
            );
          }
        }
        if (get().extent === "AGRICULTURE") {
          if (
            get().agriculturalAcres === "" ||
            get().agriculturalCents === ""
          ) {
            throw new Error(
              `Agricultural Values should not be empty. Please fill all the fields`
            );
          } else if (
            get().agriculturalAcres === 0 &&
            get().agriculturalCents === 0
          ) {
            throw new Error(
              `Both  Agricultural Values should cannot be zero. Please fill all the fields`
            );
          }
        } else {
          if (
            get().nonAgriculturalAcres === "" ||
            get().nonAgriculturalCents === ""
          ) {
            throw new Error(
              `Non Agricultural Values should not be empty. Please fill all the fields`
            );
          } else if (
            get().nonAgriculturalAcres === 0 &&
            get().nonAgriculturalCents === 0
          ) {
            throw new Error(
              `Both Non Agricultural Values should cannot be zero. Please fill all the fields`
            );
          }
        }

        const body = {
          extent: get().selectedSNBreakup?.id
            ? get().selectedSNBreakup?.extent
            : get().extent,
          leasedExtentAcres:
            get().extent === "AGRICULTURE"
              ? get().agriculturalAcres
              : get().nonAgriculturalAcres,
          leasedExtentCents:
            get().extent === "AGRICULTURE"
              ? get().agriculturalCents
              : get().nonAgriculturalCents,
          leasePeriodStartDate: moment(get().leasePeriodStartDate).format(
            "YYYY-MM-DD"
          ),
          leasePeriodEndDate: moment(get().leasePeriodEndDate).format(
            "YYYY-MM-DD"
          ),
          dueDate: Number(get().dueDate),
          paymentTerm: get().paymentTerm,
          paymentType: get().paymentType,
          lesseType: get().lesseType,
          lesseName: get().lesseName,
          mobileNumber: `+91${get().mobileNumber}`,
          address: get().address,
          village: get().village,
          mandal: get().mandal,
          district: get().district,
          state: get().state,
          pincode: get().zip,
          idProofType: get().idProofType,
          idProofNumber: get().idProofNumber,
          uploadIdProof: get().uploadIdProof[0]?.url,
          leaseApprovedByCompetentAuthority:
            get().leaseApprovedByCompetentAuthority,
          eoProceedingUploadDocument: get().eoProceedingUploadDocument[0]?.url,
          status: get().status,
          surveyNumberId: get().selectedSurveyNumber?.id,
        };
        if (get().paymentType === "KIND") {
          get().checkForEmptyStates(["kindItem", "kindItemUnitsInKg"]);
          body.kindItem = get().kindItem;
          body.kindItemUnitsInKg = Number(get().kindItemUnitsInKg);
        } else {
          get().checkForEmptyStates([
            "leaseRate",
            "depositAmount",
            "mrNo",
            "mrDate",
          ]);
          body.gstPercentage = get().gstAmount;
          body.leaseRate = Number(get().leaseRate);
          body.depositAmount = Number(get().depositAmount);
          body.mrNo = get().mrNo;
          body.mrDate = moment(get().mrDate).format("YYYY-MM-DD");
        }

        if (get().lesseType === "individual") {
          body.gender = get().gender;
        } else {
          if (get().gstNo !== "") {
            if (!regex.test(get().gstNo)) {
              throw new Error(`Invalid GST Number`);
            }
          }
          body.gstNo = get().gstNo;
          body.contact_person_name = get().contact_person_name;
          body.incorporationOrPanNo = get().incorporationOrPanNo;
        }
        if (get().leaseApprovedByCompetentAuthority) {
          get().checkForEmptyStates(
            [
              "uploadApprovedDocument",
              "uploadApprovedDocumentOrderNo",
              "uploadApprovedDocumentOrderDate",
            ],
            []
          );
          body.uploadApprovedDocument = get().uploadApprovedDocument[0]?.url;
          body.uploadApprovedDocumentOrderNo =
            get().uploadApprovedDocumentOrderNo;
          body.uploadApprovedDocumentOrderDate = moment(
            get().uploadApprovedDocumentOrderDate
          ).format("YYYY-MM-DD");
        } else {
          body.reason = get().reason;
        }
        if (get().selectedSNBreakup !== "" && get().selectedSNBreakup?.id) {
          body.surveyNumberBreakupId = get().selectedSNBreakup?.id;
        }
        if (get().remarks !== "") {
          body.remarks = get().remarks;
        }

        await TmsRentsService.createLandLeases(body);
        notification.success({ message: "Lease created successfully" });
        await get().clearStates(leaseCreationStates, [
          ...leaseAddtionalStates,
          ...[
            "institutionData",
            "createdSNBreakupData",
            "createdSurveryNumberData",
            "surveyNumberBreakupId",
          ],
        ]);
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleLeaseUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          extent: record.extent,
          leasedExtentAcres: record.leasedExtentAcres,
          leasedExtentCents: record.leasedExtentCents,
          leasePeriodStartDate: record.leasePeriodStartDate,
          leasePeriodEndDate: record.leasePeriodEndDate,
          gstPercentage: record.gstPercentage,
          dueDate: record.dueDate,
          paymentTerm: record.paymentTerm,
          paymentType: record.paymentType,
          kindItem: record.kindItem,
          kindItemUnitsInKg: record.kindItemUnitsInKg,
          leaseRate: record.leaseRate,
          depositAmount: record.depositAmount,
          mrNo: record.mrNo,
          mrDate: moment(record.mrDate).format("YYYY-MM-DD"),
          lesseType: record.lesseType,
          lesseName: record.lesseName,
          mobileNumber: record.mobileNumber,
          address: record.address,
          village: record.village,
          mandal: record.mandal,
          district: record.district,
          state: record.state,
          pincode: record.pincode,
          idProofType: record.idProofType,
          idProofNumber: record.idProofNumber,
          uploadIdProof: record.uploadIdProof,
          leaseApprovedByCompetentAuthority:
            record.leaseApprovedByCompetentAuthority,
          eoProceedingUploadDocument: record.eoProceedingUploadDocument,
          status: record.status,
          surveyNumberId: record.surveyNumberId,
        };
        if (record.lesseType === "individual") {
          body.gender = record.gender;
        } else {
          body.gstNo = record.gstNo;
          body.contact_person_name = record.contact_person_name;
          body.incorporationOrPanNo = record.incorporationOrPanNo;
        }
        if (record.leaseApprovedByCompetentAuthority) {
          body.uploadApprovedDocument = record.uploadApprovedDocument;
          body.uploadApprovedDocumentOrderNo =
            record.uploadApprovedDocumentOrderNo;
          body.uploadApprovedDocumentOrderDate =
            record.uploadApprovedDocumentOrderDate;
        } else {
          body.reason = record.reason;
        }
        if (record.surveyNumberBreakupId) {
          body.surveyNumberBreakupId = record.surveyNumberBreakupId;
        }
        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }

        await TmsRentsService.updateCreatedLandLease(body, record.id);
        notification.success({ message: "Land Lease Updated succesfully" });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedLease(landLeaseId, router) {
      try {
        set({ deleteLoading: true });
        await TmsRentsService.deleteCreatedLandLease(landLeaseId);
        notification.success({
          message: `Land Lease with ID:${landLeaseId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async handleEncroachment(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(encroachmentStates);

        const survey = get().selectedSurveyNumber;
        const surveyBreak = get().selectedSNBreakup;
        const body = {
          extent: get().extent,
          encroacherName: get().encroacherName,
          encroacherAddress: get().encroacherAddress,
          encroachedAcres: get().encroachedAcres,
          encroachedCents: get().encroachedCents,
          encroachmentDurationInDays: Number(get().enchrochmentDuration),
          encroachmentDurationInMonths: get().encroachmentDurationInMonths,
          eastBoundary: surveyBreak.id
            ? surveyBreak.eastBoundary
            : survey?.eastBoundary,
          westBoundary: surveyBreak.id
            ? surveyBreak.westBoundary
            : survey?.westBoundary,
          southBoundary: surveyBreak.id
            ? surveyBreak.southBoundary
            : survey?.southBoundary,
          northBoundary: surveyBreak.id
            ? surveyBreak.northBoundary
            : survey?.northBoundary,
          geoTagging: surveyBreak.id
            ? surveyBreak.geoTagging
            : survey.geoTagging,
          legalAction: get().legalAction,
          status: get().status,
          surveyNumberId: survey.id,
        };
        if (get().remarks !== "") {
          body.remarks = get().remarks;
        }
        if (get().legalAction) {
          body.caseNumber = get().caseNo;
          body.file = get().casePDF[0]?.url;
        } else {
          body.reason = get().legalReason;
        }
        if (get().selectedSNBreakup?.id) {
          body.surveyNumberBreakupId = get().selectedSNBreakup?.id;
        }
        await TmsRentsService.createEncroachment(body);
        notification.success({
          message: "Successfully created encroahment record",
        });
        await get().clearStates(
          encroachmentStates,
          additionalEncroahmentStates
        );

        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({
          submitLoading: false,
        });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleEncroachmentUpdate(record, router) {
      try {
        set({ loading: true });

        const body = {
          extent: record.extent,
          encroacherName: record.encroacherName,
          encroacherAddress: record.encroacherAddress,
          encroachedAcres: record.encroachedAcres,
          encroachedCents: record.encroachedCents,
          encroachmentDurationInDays: Number(record.encroachmentDurationInDays),
          encroachmentDurationInMonths: record.encroachmentDurationInMonths,
          eastBoundary: record.eastBoundary,
          westBoundary: record.westBoundary,
          southBoundary: record.southBoundary,
          northBoundary: record.northBoundary,
          geoTagging: record.geoTagging,
          legalAction: record.legalAction,
          status: record.status,
          surveyNumberId: record.surveyNumberId,
        };
        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }
        if (record.legalAction) {
          body.caseNumber = record.caseNo;
          body.file = record.file;
        } else {
          body.reason = record.reason;
        }
        if (record.selectedSNBreakup?.id) {
          body.surveyNumberBreakupId = record.surveyNumberBreakupId;
        }
        await TmsRentsService.updateEncroachment(body, record.id);
        notification.success({
          message: "Successfully updated encroahment record",
        });
        set({
          loading: false,
        });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ loading: false });
      }
    },

    async fetchArrears(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedArrears(
          get().selectedSurveyNumber?.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy,
          router
        );
        if (dataType === "return") {
          set({ loading: false });

          return res.items;
        } else {
          set({ createdArrearsData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleArrearCreation(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(arrearStates);

        if (!get().legalAction && get().legalReason === "") {
          throw new Error(
            "Please enter the lease approval reason in reason field"
          );
        }

        const body = {
          arrearAmount: Number(get().arrearAmount),
          interestAmount: Number(get().interestAmount),
          legalAction: get().legalAction,
          status: true,
          surveyNumberId: get().selectedSurveyNumber?.id,
          landLeaseId: get().selectedLandLease?.id,
          arrearPeriodInMonths: Number(get().arrearPeriodInMonths),
        };
        if (get().selectedSNBreakup.id) {
          body.surveyNumberBreakupId = get().selectedSNBreakup?.id;
        }
        if (!get().legalAction) {
          body.reason = get().legalReason;
        } else {
          body.caseNumber = get().caseNo;
          body.file = get().casePDF[0]?.url;
        }
        if (get().remarks !== "") {
          body.remarks = get().remarks;
        }

        await TmsRentsService.createArrear(body);
        notification.success({
          message: "Arrear has been successfully creation.",
        });
        await get().clearStates(arrearStates, additionalArrearStates);
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleArrearUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          leasePeriodStartDate: record.leasePeriodStartDate,
          leasePeriodEndDate: record.leasePeriodEndDate,
          arrearAmount: record.arrearAmount,
          interestAmount: record.interestAmount,
          legalAction: record.legalAction,
          status: record.status,
          surveyNumberId: record.surveyNumberId,
          landLeaseId: record.landLeaseId,
          arrearPeriodInMonths: Number(record.arrearPeriodInMonths),
        };
        if (record.surveyNumberBreakupId) {
          body.surveyNumberBreakupId = record.surveyNumberBreakupId;
        }
        if (!record.legalAction) {
          body.reason = record.reason;
        } else {
          body.caseNumber = record.caseNumber;
          body.file = record.file;
        }
        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }

        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }

        await TmsRentsService.updateCreatedArrears(body, record.id);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleArrearBillUpdate(id, body, router) {
      set({ submitLoading: true });
      try {
        await TmsRentsService.updateArrearBill(body, id);
        notification.success({
          message: "Arrear Bill has been successfully updated.",
        });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedArrear(arrearId, router) {
      try {
        set({ deleteLoading: true });
        await TmsRentsService.deleteCreatedArrear(arrearId);
        notification.success({
          message: `Arrear with ID:${arrearId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },

    //reports

    async fetchTotalReports(
      type,
      tableCurrentPage,
      tablePageSize,
      // sortType,
      // sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedReportsByType(
          type,
          tableCurrentPage,
          tablePageSize
          // sortType,
          // sortBy
        );
        if (type === "LAND") {
          set({
            landReports: res,
          });
        } else if (type === "ENCROACHMENT") {
          set({
            encroachmentReport: res,
          });
        } else if (type === "LEASE") {
          set({
            leaseReports: res,
          });
        } else if (type === "ARREAR") {
          set({
            arrearReport: res,
          });
        }
        set({ loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLandReports(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedLandReports(
          get().selectedInstitution ?? "",
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          landReports: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLeaseReports(
      tableCurrentPage,
      tablePageSize,
      leaseStartDate,
      leaseEndDate,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getLeaseReports(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          moment(leaseStartDate).format("YYYY-MM-DD"),
          moment(leaseEndDate).format("YYYY-MM-DD")
        );
        set({
          leaseReports: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchArrearReports(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getArrearsReports(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          arrearReport: res,
          loading: false,
          selectedSNBreakup: "",
          createdSNBreakupData: [],
          selectedSurveyNumber: "",
          createdSurveryNumberData: [],
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchEncroachmentReports(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getEncroahmentReports(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          encroachmentReport: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    // Function to add agricultural and non-agricultural land
    calculateAcresAndCents(agrAcres, agrCents, nonAgrAcres, nonAgrCents) {
      // Convert cents to acres if cents exceed 100;
      let finalAcres = agrAcres + nonAgrAcres,
        finalCents = 0;

      if (agrCents + nonAgrCents > 99) {
        let additionalAcres = 1;
        const excessCents = agrCents + nonAgrCents - 100;
        if (excessCents > 100) {
          additionalAcres += Math.floor(excessCents / 100);
        }

        // Update acres and cents
        finalAcres += additionalAcres;
        finalCents = excessCents % 100;
      } else {
        finalCents = agrCents + nonAgrCents;
      }

      return { totalAcres: finalAcres, totalCents: finalCents };
    },
    async handleExcelExport(type, router) {
      set({ excelLoading: true });
      const funtionType = {
        land: get().fetchCreatedLandList,
        survey: get().fetchSurveyNumbers,
        surveyNoBreakup: get().fetchSurveyNumbersBreakUp,
        lease: get().fetchLandLeases,
        arrear: get().fetchArrears,
        encroachment: get().fetchEncroachments,
      };
      try {
        const data = await funtionType[type](
          1,
          1000,
          "ASC",
          "createdAt",
          router,
          "return"
        );
        if (data.length === 0) {
          notification.info({ message: "No records found to Export" });
          set({ excelLoading: false });
          return;
        }
        exportExcel(data, `${type}-${moment().format("DD-MM-YYYY")}`, () => {
          set({ excelLoading: false });
        });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token expired!! Please login again.",
          router
        );
        set({ excelLoading: false });
      }
    },
  };
});

export default useRentsAndLandStore;
