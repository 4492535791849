import React, { memo } from "react";
import useBuildingsStore from "../../../../zustand/buildings-and-shops.store";
import TmsSelect from "../../tms-select";

const BuildingSelect = ({ span }) => {
  const {
    createdBuildingsData,
    selectedBuilding,
    clearStates,
    setSelectedBuilding,
  } = useBuildingsStore((state) => {
    return {
      createdBuildingsData: state.createdBuildingsData,
      clearStates: state.clearStates,
      setSelectedBuilding: state.setSelectedBuilding,
      selectedBuilding: state.selectedBuilding,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      setValue={(e) => {
        console.log(createdBuildingsData?.items?.find((item) => item.id === e));
        setSelectedBuilding(
          createdBuildingsData?.items?.find((item) => item.id === e)
        );
        clearStates(
          ["selectedBlock", "selectedShop", "selectedBuildingLease"],
          []
        );
      }}
      label="Select Building"
      data={
        createdBuildingsData?.items?.map((item) => {
          return {
            name: `ID: ${item?.buildingName} - ${item?.landmark ?? "-"} ${
              item?.village ?? ""
            } `,
            id: item?.id,
          };
        }) ?? []
      }
      value={selectedBuilding?.id}
      optional={true}
    />
  );
};

export default memo(BuildingSelect);
