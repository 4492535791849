import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsAccommodationService = {
  getAllBlocks: async (page, limit) => {
    const resp = await Axios.get(
      `v1/accommodation/block/all?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getCounterSpecificBlocks: async (counterId) => {
    const resp = await Axios.get(
      `v1/accommodation/block-counter/by-counter/${counterId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getBlockById: async (block_id) => {
    const resp = await Axios.get(`v1/accommodation/block/by-id/${block_id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  createNewBlock: async (body) => {
    const resp = await Axios.post(`v1/accommodation/block/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  editBlock: async (blockId, body) => {
    const resp = await Axios.put(
      `v1/accommodation/block/update/${blockId}`,

      body,

      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteBlock: async (blockId) => {
    const resp = await Axios.delete(
      `v1/accommodation/block/delete/${blockId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  //-------------------------------ManageRoom category-----------------------------//
  getAllBlockCategorys: async (block_id, page, limit) => {
    const resp = await Axios.get(
      `v1/accommodation/block/category/all/${block_id}?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getBlockCategoryById: async (block_id) => {
    const resp = await Axios.get(
      `v1/accommodation/block/category/by-id/${block_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createNewBlockCategory: async (body) => {
    const resp = await Axios.post(
      `v1/accommodation/block/category/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  editBlockCategory: async (blockId, body) => {
    const resp = await Axios.put(
      `v1/accommodation/block/category/update/${blockId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteBlockCategory: async (blockId) => {
    const resp = await Axios.delete(
      `v1/accommodation/block/delete/${blockId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //-----------------------------Manage Room---------------------------------//
  getAllBlockCategoryRoom: async (block_category_id, page, limit) => {
    const resp = await Axios.get(
      `v1/accommodation/block/category/room/all/${block_category_id}?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getBlockCategoryRoomById: async (block_id) => {
    const resp = await Axios.get(
      `v1/accommodation/block/category/room/by-id/${block_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getRoomsByBlockId: async (block_id) => {
    const resp = await Axios.get(
      `v1/accommodation/block/category/room/get-by-block-id/${block_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createNewBlockCategoryRoom: async (body) => {
    const resp = await Axios.post(
      `v1/accommodation/block/category/room/create`,
      {
        roomNumber: body.roomNumber,
        description: body.description,
        accBlockCategoryId: body.accBlockCategoryId,
        enabled: body.enabled,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  editBlockCategoryRoom: async (roomId, body) => {
    const resp = await Axios.put(
      `v1/accommodation/block/category/room/update/${roomId}`,
      {
        roomNumber: body.roomNumber,
        description: body.description,
        accBlockCategoryId: body.accBlockCategoryId,
        enabled: body.enabled,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteBlockCategoryRoom: async (blockId) => {
    const resp = await Axios.delete(
      `v1/accommodation/block/category/room/delete/${blockId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  updateTerms: async (productId, termsData, onlineTerms) => {
    const resp = await Axios.patch(
      `v1/accommodation/block/metadata/${productId}`,
      {
        termsAndConditions: termsData,
        onlineTermsAndConditions: onlineTerms,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  //-----------------------------Manage Room---------------------------------//

  //-----------------------------Manage pos---------------------------------//
  getBlockCounters: async (id) => {
    const resp = await Axios.get(
      `v1/accommodation/block-counter/by-product/${id}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  createBlockCounters: async (body) => {
    const resp = await Axios.post(
      `v1/accommodation/block-counter/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  editBlockCounters: async (body, slotId) => {
    const resp = await Axios.put(
      `v1/accommodation/block-counter/update/${slotId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteBlockCounters: async (slotId) => {
    const resp = await Axios.delete(
      `v1/accommodation/block-counter/delete/${slotId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  //-----------------------------Manage pos---------------------------------//
  //-----------------------------Booking------------------------------------//
  fetchValidCounterProducts: async (roomType, persons) => {
    let url = `v1/accommodation-counter-booking/valid-counter-products?noOfPersons=${persons}`;
    if (roomType !== "") {
      url += `&roomType=${roomType}`;
    }
    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  fetchValidAdvanceCounterProducts: async (roomType, persons, date) => {
    let url = `v1/accommodation-counter-booking/valid-advance-block-category-rooms?noOfPersons=${persons}&date=${date}`;
    if (roomType !== "") {
      url += `&roomType=${roomType}`;
    }
    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  fetchValidRooms: async (blockCategoryId, date) => {
    let query = `v1/accommodation-counter-booking/valid-block-category-rooms/${blockCategoryId}`;
    if (date !== null && date) {
      query += `?bookingDate=${date}`;
    }
    const resp = await Axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  fetchRoomsByBlockCategoryId: async (blockCategoryId) => {
    const resp = await Axios.get(
      `v1/accommodation/block/category/block-category-enabled-rooms-count/${blockCategoryId}?blockCategoryId=${blockCategoryId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  accommodationBooking: async (
    body,
    payment,
    cashReference,
    cashRemarks,
    flag
  ) => {
    let data = {
      multiTicketsLinked: true,
      paymentType: payment,
      cashRemarks: cashReference,
      cashReference: cashRemarks,
    };
    if (flag) {
      data.accCounterBookings = [body];
    } else {
      data.accAdvanceCounterBookings = [body];
    }
    const resp = await Axios.post(`v1/counter-cart/new`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  advanceAccommodationBooking: async (body) => {
    const resp = await Axios.post(
      `v1/counter-cart/counter-advance-room-checkin`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  onlineAccommodationBooking: async (body) => {
    const resp = await Axios.post(`v1/counter-cart/online-room-checkin`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  renewStats: async (roomId) => {
    const resp = await Axios.get(
      `v1/accommodation-counter-booking/renew-stats/${roomId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  checkOutStats: async (roomId) => {
    const resp = await Axios.get(
      `v1/accommodation-counter-booking/checkout-stats/${roomId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  roomBookingUpdate: async (data, flag) => {
    let body = {
      paymentType: "CASH",
      cashRemarks: "string",
      cashReference: "string",
    };
    if (flag === "checkOut") {
      body.checkOutData = data;
    } else if (flag === "renew") {
      body.renewData = data;
    }
    const resp = await Axios.post(`v1/counter-cart/room-booking-update`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  checkOutOTP: async () => {
    const resp = await Axios.post(
      `v1/accommodation/force-checkout-otp`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  forceCheckOutandRenew: async (data, flag) => {
    let body = {
      paymentType: "CASH",
      cashRemarks: "string",
      cashReference: "string",
    };
    if (flag === "checkOut") {
      body.checkOutData = data;
    } else if (flag === "renew") {
      body.renewData = data;
    }
    const resp = await Axios.post(`v1/counter-cart/room-force-checkout`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getCheckoutReadyRooms: async (counterId) => {
    let url = `v1/accommodation-counter-reports/checkout-ready-rooms`;
    if (counterId !== "all" && counterId && counterId !== "") {
      url = url + `?blockId=${counterId}`;
    }
    const resp = await Axios.get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getRoomsStats: async (fromDate, toDate, blockId) => {
    let url = `v1/accommodation-counter-reports/room-type-booking-stats?fromDate=${fromDate}&toDate=${toDate}`;
    if (blockId !== "all" && blockId && blockId !== "") {
      url = url + `&blockId=${blockId}`;
    }
    const resp = await Axios.get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getLiveRoomsData: async (blockId, id, type) => {
    let url = `v1/accommodation-counter-reports/room-type-booking-rooms?type=${type}&roomType=${id}&page=1&limit=1000`;
    if (blockId !== "all" && blockId && blockId !== "") {
      url = url + `&blockId=${blockId}`;
    }

    const resp = await Axios.get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  accommodationReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    roomType,
    blockId,
    selectedRoom,
    counter,
    shift,
    dateType,
    sortType,
    sortBy,
  }) => {
    console.log(format);
    let query = `v1/accommodation-counter-reports/booking?format=${format}&bookingFromDate=${fromDate}&sortBy=${
      sortBy ?? "createdAt"
    }&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&dateType=${dateType}&order=${sortType}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (roomType && roomType !== "") {
      query += `&roomType=${roomType}`;
    }
    if (blockId && blockId !== "") {
      query += `&productId=${blockId}`;
    }
    if (selectedRoom && selectedRoom !== "") {
      query += `&roomId=${selectedRoom}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  accommodationReportOnline: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    blockId,
    counter,
    shift,
    dateType,
    sortType,
    paymentStatus,
  }) => {
    let query = `v1/accommodation-online-report/booking?format=${format}&bookingFromDate=${fromDate}&paymentStatus=${paymentStatus}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&dateType=${dateType}&order=${sortType}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }

    if (blockId && blockId !== "") {
      query += `&productId=${blockId}`;
    }

    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  accommodationReportAdvance: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    roomType,
    blockId,
    selectedRoom,
    counter,
    shift,
    dateType,
    sortType,
    sortBy,
  }) => {
    let query = `v1/accommodation-counter-reports/advance-booking?bookingFromDate=${fromDate}&sortBy=${sortBy}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&dateType=${dateType}&order=${sortType}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (roomType && roomType !== "") {
      query += `&roomType=${roomType}`;
    }
    if (blockId && blockId !== "") {
      query += `&productId=${blockId}`;
    }
    if (selectedRoom && selectedRoom !== "") {
      query += `&roomId=${selectedRoom}`;
    }

    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  renewalReport: async (
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    roomType,
    blockId,
    selectedRoom,
    counterId,
    shiftId,
    dateType,
    sortType,
    sortBy
  ) => {
    let query = `v1/accommodation-counter-reports/renewal/${format}?page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&sortBy=${sortBy}&bookingToDate=${toDate}&dateType=${dateType}&sortOrder=${sortType}`;

    if (counterId && counterId !== "") {
      query += `&counterId=${counterId}`;
    }
    if (shiftId && shiftId !== "") {
      query += `&counterShiftTimingId=${shiftId}`;
    }
    if (roomType && roomType !== "") {
      query += `&roomType=${roomType}`;
    }
    if (blockId && blockId !== "") {
      query += `&productId=${blockId}`;
    }
    if (selectedRoom && selectedRoom !== "") {
      query += `&roomId=${selectedRoom}`;
    }
    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  occupancyReport: async (
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    blockId,
    selectedRoom
  ) => {
    let query = `v1/accommodation-counter-reports/room/occupancy/report?bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}`;
    if (blockId && blockId !== "") {
      query += `&productId=${blockId}`;
    }
    if (selectedRoom && selectedRoom !== "") {
      query += `&roomId=${selectedRoom}`;
    }
    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    accType,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/accommodation-counter-reports/consolidated-ticket-collection-for-product/${format}/${accType}?bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  getOnlineConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/accommodation-online-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  getQuotaData: async ({
    from,
    to,
    accBlockId,
    accBlockCategoryId,
    channel,
  }) => {
    let query = `v1/acc-block-category-daily-quota?fromDate=${from}&toDate=${to}&accBlockId=${accBlockId}&accBlockCategoryId=${accBlockCategoryId}`;
    if (channel) {
      query += `&channel=${channel}`;
    }

    const resp = await Axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getProtocolQuotaData: async (from, to, roomType, noOfPersons) => {
    let query = `v1/acc-block-category-daily-quota?fromDate=${from}&toDate=${to}&onlyProtocolEnabled=true&channel=protocol`;
    if (roomType) {
      query += `&roomType=${roomType}`;
    }
    if (noOfPersons) {
      query += `&noOfPersons=${noOfPersons}`;
    }

    const resp = await Axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateQuotaData: async (body) => {
    const res = await Axios.post(`v1/acc-block-category-daily-quota`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
};
export default TmsAccommodationService;
