import { Col, Row, Input } from "antd";
import React, { useEffect } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import TmsDatepickerRange from "../../../../../../components/tms-customs/tms-datepicker-range";
import TmsAddress from "../../../../../../components/tms-customs/tms-address";
import {
  additionalLicenseArrearStates,
  arrearLicenseStates,
  yesOrNotData,
} from "../../../../../../constants/states-data.data";
import TmsImageUpload from "../../../../../../components/tms-customs/tms-image-upload";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import {
  LicenseInstitutionSelect,
  LicenseLeaseSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";
import moment from "moment";
import useLicensesStore from "../../../../../../zustand/licenses.store";

const TmsLicenseArearsCreation = ({ router, setVisible }) => {
  const {
    selectedInstitution,
    arrearPeriodInMonths,
    setArrearPeriodInMonths,
    setRemarks,
    setLesseName,
    setSubmitLoading,
    submitLoading,
    legalAction,
    setLegalAction,
    setLegalReason,
    legalReason,
    caseNo,
    setCaseNo,
    casePDF,
    setCasePDF,
    fetchInstitutionList,
    clearStates,
    fetchLicenseLeases,
    setArrearAmount,
    arrearAmount,
    interestAmount,
    setInterestAmount,
    setStatus,
    status,
    handleArrearCreation,
    remarks,
    selectedLicenseLease,
  } = useLicensesStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(arrearLicenseStates, additionalLicenseArrearStates);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchLicenseLeases(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <LicenseInstitutionSelect span={8} />
        <LicenseLeaseSelect span={8} />
      </Row>
      <br />
      <Row
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#333333",
          width: "100%",
        }}
      >
        Land Details
      </Row>{" "}
      <Row justify="flex-start" gutter={[20, 20]}>
        <Col span={8}>
          <TmsDatepickerRange
            label="Select Lease Period"
            to={moment(selectedLicenseLease?.leasePeriodEndDate)}
            from={moment(selectedLicenseLease?.leasePeriodStartDate)}
            // setFrom={setLeasePeriodStartDate}
            // setTo={setLeasePeriodEndDate}
            disabled={true}
          />{" "}
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Arrear Amount</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter Arrear Amount"
            value={arrearAmount}
            onChange={(e) => {
              setArrearAmount(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>

        <Col span={8}>
          <Row className={"t-left"}>Interest Amount</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter Interest Amount"
            value={interestAmount}
            onChange={(e) => {
              setInterestAmount(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Arrear Period In Months</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter Arrear period in months"
            value={arrearPeriodInMonths}
            onChange={(e) => {
              e.target.value < 360 && setArrearPeriodInMonths(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
      </Row>{" "}
      <br />
      <Row
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#333333",
          width: "100%",
        }}
      >
        Lessee Details
      </Row>
      <Row justify="flex-start" gutter={[20, 20]}>
        <Col span={12}>
          <Row className={"t-left"}>Lessee Name</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Lessee Name"
            value={selectedLicenseLease?.lesseName}
            onChange={(e) => {
              setLesseName(e.target.value);
            }}
            disabled={true}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Mobile Number</Row>
          {console.log(selectedLicenseLease)}
          <Input
            type="number"
            min="0"
            prefix="+91"
            onWheel={(event) => event.currentTarget.blur()}
            maxLength={10}
            value={selectedLicenseLease?.mobileNumber?.replace("+91", "")}
            disabled={true}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>

        <TmsAddress
          span={12}
          village={selectedLicenseLease?.village}
          mandal={selectedLicenseLease?.mandal}
          district={selectedLicenseLease?.district}
          country={selectedLicenseLease?.country}
          state={selectedLicenseLease?.state}
          zip={selectedLicenseLease?.pincode}
          type="general"
          disabled={true}
        />
      </Row>
      <br />
      <Row
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#333333",
          width: "100%",
        }}
      >
        Legal Case Details
      </Row>
      <Row justify="flex-start" gutter={[20, 20]}>
        <TmsSelect
          selectClass="w-100 "
          classname="mr-0 "
          type="col"
          value={legalAction}
          setValue={setLegalAction}
          label="Legal Action"
          data={yesOrNotData}
          span={12}
          optional={true}
        />
        {legalAction ? (
          <>
            <Col span={12}>
              <Row className={"t-left"}>If yes Case Number</Row>
              <Input
                maxLength={220}
                type="text"
                onWheel={(event) => event.currentTarget.blur()}
                onKeyDown={(e) =>
                  ["+", "-", "."].includes(e.key) && e.preventDefault()
                }
                placeholder="Enter Case No"
                value={caseNo}
                onChange={(e) => setCaseNo(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Upload File(PDF)</Row>
              <TmsImageUpload
                fileList={casePDF}
                setFileList={setCasePDF}
                router={router}
                type="private"
                accept={true}
                className="w-100"
              />
            </Col>
          </>
        ) : (
          <Col span={12}>
            {" "}
            <Row className={"t-left"}>Reason</Row>
            <Input
              maxLength={220}
              type="text"
              placeholder="Enter Reason"
              value={legalReason}
              onChange={(e) => setLegalReason(e.target.value)}
            />
          </Col>
        )}
        <Col span={12}>
          {" "}
          <Row className={"t-left"}>Remarks</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Col>
      </Row>{" "}
      <TmsCheckbox
        label={"Arrear Status"}
        value={status}
        setValue={setStatus}
      />
      <TmsCancelSubmitButtons
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(arrearLicenseStates, additionalLicenseArrearStates);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => !submitLoading && handleArrearCreation(router)}
        loading={submitLoading}
      />
    </>
  );
};

export default TmsLicenseArearsCreation;
