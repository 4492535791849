import React, { useEffect } from "react";
import { Col, Divider, Input, Row } from "antd";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import TmsImageUpload from "../../../../../../components/tms-customs/tms-image-upload";
import TmsBoundayElement from "../../../../../../components/tms-customs/tms-bounday-element";
import TmsLandAcreCentsInput from "../../../../../../components/tms-customs/tms-land-acre-cents-input";
import {
  yesOrNotData,
  encroachmentStates,
  additionalEncroahmentStates,
  extentTypes,
} from "../../../../../../constants/states-data.data";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import {
  InstitutionSelect,
  LandCreationSelect,
  SurveyBreakUpNoSelect,
} from "../../../../../../components/tms-customs/rents-and-leases/index";

const TmsEncroachment = ({ router, setVisible }) => {
  const {
    selectedInstitution,
    selectedLand,
    encroacherAddress,
    setEncroacherAddress,
    enchrochmentDuration,
    setEnchrochmentDuration,
    submitLoading,
    setSubmitLoading,
    legalReason,
    setLegalReason,
    encroacherName,
    setEncroacherName,
    setLegalAction,
    fetchInstitutionList,
    fetchSurveyNumbersByLandID,
    legalAction,
    selectedSurveyNumber,
    surveyNoDataByLandId,
    remarks,
    setEncroachmentDurationInMonths,
    encroachmentDurationInMonths,
    setRemarks,
    caseNo,
    setCaseNo,
    casePDF,
    setCasePDF,
    fetchCreatedLandList,
    handleEncroachment,
    clearStates,
    selectedSNBreakup,
    fetchSurveyNumbersBreakUp,
    setSelectedSurveyNumber,
    extent,
    setStatus,
    status,
    setEncroachedAcres,
    setEncroachedCents,
    encroachedAcres,
    setExtent,
    encroachedCents,
  } = useRentsAndLandStore();
  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(encroachmentStates, additionalEncroahmentStates);
    };
  }, []);
  useEffect(() => {
    selectedInstitution !== "" &&
      selectedInstitution &&
      fetchCreatedLandList(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);
  useEffect(() => {
    if (selectedLand !== "" && selectedLand && selectedInstitution !== "")
      fetchSurveyNumbersByLandID(1, 200, "ASC", "createdAt", router);
  }, [selectedLand, selectedInstitution]);

  useEffect(() => {
    if (
      selectedLand !== "" &&
      selectedInstitution !== "" &&
      selectedSurveyNumber !== ""
    )
      fetchSurveyNumbersBreakUp(1, 200, "ASC", "createdAt", router);
  }, [selectedSurveyNumber, selectedInstitution, selectedLand]);
  return (
    <div>
      {" "}
      <Row gutter={[20, 20]} style={{ marginBottom: "15px" }}>
        {/* <Col span={12}> */}
        <InstitutionSelect span={12} />
        <LandCreationSelect span={12} />
        <TmsSelect
          type="col"
          span={12}
          label="Select Survey No"
          setValue={(e) => {
            setSelectedSurveyNumber(
              surveyNoDataByLandId?.items.find((item) => item.id === e)
            );
          }}
          selectClass="w-100"
          value={selectedSurveyNumber?.id}
          data={surveyNoDataByLandId?.items?.map((item) => {
            return { name: `Survey.no: ${item.surveyNo}`, id: item.id };
          })}
          optional={true}
        />{" "}
        <SurveyBreakUpNoSelect span={12} />
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={selectedSNBreakup?.id ? selectedSNBreakup.extent : extent}
          setValue={setExtent}
          label="Enter Extent"
          data={extentTypes}
          span={12}
          optional={true}
          disabled={selectedSNBreakup?.id ? true : false}
        />
        <TmsLandAcreCentsInput
          valueOne={
            selectedSNBreakup?.id
              ? selectedSNBreakup.extentAcres
              : extent === "AGRICULTURE"
              ? selectedSurveyNumber?.agricultureLandAcres
              : selectedSurveyNumber?.nonAgricultureLandAcres
          }
          setValueOne={() => {}}
          valueTwo={
            selectedSNBreakup?.id
              ? selectedSNBreakup.extentCents
              : extent === "AGRICULTURE"
              ? selectedSurveyNumber?.agricultureLandCents
              : selectedSurveyNumber?.nonAgricultureLandCents
          }
          setValueTwo={() => {}}
          colspace={12}
          label={`Available ${extent !== "" ? extent : ""} Land`}
          disabled={true}
        />{" "}
        <TmsLandAcreCentsInput
          valueOne={encroachedAcres}
          setValueOne={setEncroachedAcres}
          valueTwo={encroachedCents}
          setValueTwo={setEncroachedCents}
          colspace={12}
          label={`Encroached ${extent !== "" ? extent : ""} Land`}
          disabled={false}
          maxValueOne={
            selectedSNBreakup?.id
              ? selectedSNBreakup.extentAcres
              : extent === "AGRICULTURE"
              ? selectedSurveyNumber?.agricultureLandAcres
              : selectedSurveyNumber?.nonAgricultureLandAcres
          }
          maxValueTwo={
            selectedSNBreakup?.id
              ? selectedSNBreakup.extentCents
              : extent === "AGRICULTURE"
              ? selectedSurveyNumber?.agricultureLandCents
              : selectedSurveyNumber?.nonAgricultureLandCents
          }
        />{" "}
        {/* <Col span={12}>
          <Row className={"t-left"}>Nature of Land</Row>
          <Input
            type="col"
            value={selectedLand?.natureOfLand}
            label="Nature of Land"
            span={12}
            disabled={true}
          />
        </Col>{" "} */}
        <Col span={12}>
          <Row className={"t-left"}>Enchrochure Name</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Enchrochure Name"
            value={encroacherName}
            onChange={(e) => setEncroacherName(e.target.value)}
          />
        </Col>{" "}
        <Col span={12}>
          <Row className={"t-left"}>Enchrochure Address</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Enchrochure Address"
            value={encroacherAddress}
            onChange={(e) => setEncroacherAddress(e.target.value)}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Enchrochment Duration(Months)</Row>
          <Input
            maxLength={220}
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            placeholder="Enter Enchrochment Duration in Months"
            value={encroachmentDurationInMonths}
            onChange={(e) => {
              if (e.target.value <= 360)
                setEncroachmentDurationInMonths(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Enchrochment Duration(Remaining Days)</Row>
          <Input
            maxLength={220}
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            placeholder="Enter Enchrochment Duration in Days"
            value={enchrochmentDuration}
            onChange={(e) => {
              if (e.target.value <= 31) setEnchrochmentDuration(e.target.value);
            }}
          />
        </Col>{" "}
        <TmsBoundayElement
          disabled={true}
          data={selectedSNBreakup ? selectedSNBreakup : selectedSurveyNumber}
        />
        <Col span={12}>
          <Row className={"t-left"}>Geo tagging </Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Boundary Value"
            value={
              selectedSNBreakup
                ? selectedSNBreakup.geoTagging
                : selectedSurveyNumber.geoTagging
            }
            disabled={true}
          />
        </Col>
      </Row>
      <Divider />
      <h2 className="t-left">Land Donated By</h2>
      <Row className="mt-20" gutter={[20, 20]}>
        <TmsSelect
          selectClass="w-100 "
          classname="mr-0 "
          type="col"
          value={legalAction}
          setValue={setLegalAction}
          label="Legal Action"
          data={yesOrNotData}
          span={12}
          optional={true}
        />
        {legalAction ? (
          <>
            <Col span={12}>
              <Row className={"t-left"}>If yes Case Number</Row>
              <Input
                maxLength={220}
                type="text"
                onWheel={(event) => event.currentTarget.blur()}
                onKeyDown={(e) =>
                  ["+", "-", "."].includes(e.key) && e.preventDefault()
                }
                placeholder="Enter Case No"
                value={caseNo}
                onChange={(e) => setCaseNo(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Upload File(PDF)</Row>
              <TmsImageUpload
                fileList={casePDF}
                setFileList={setCasePDF}
                router={router}
                type="private"
                accept={true}
                className="w-100"
              />
            </Col>
          </>
        ) : (
          <Col span={12}>
            {" "}
            <Row className={"t-left"}>Reason</Row>
            <Input
              maxLength={220}
              type="text"
              placeholder="Enter Remarks"
              value={legalReason}
              onChange={(e) => setLegalReason(e.target.value)}
            />
          </Col>
        )}

        <Col span={12}>
          <Row className={"t-left"}>Remarks</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Col>
      </Row>
      <TmsCheckbox
        label={"Encroachment Status"}
        value={status}
        setValue={setStatus}
      />
      <TmsCancelSubmitButtons
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(encroachmentStates, additionalEncroahmentStates);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => !submitLoading && handleEncroachment(router)}
        loading={submitLoading}
      />
    </div>
  );
};

export default withRouter(TmsEncroachment);
