import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { Badge, Button, Col, Row, Space } from "antd";
import moment from "moment";
import { BaseContext } from "../../../../contexts/BaseContext";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../../../components/tms-customs/tms-table";
// import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsSCCreateShiftModal from "../../../../components/tms-service-management/tms-shift-management/tms-shift-management-create-modal";
import TmsShiftService from "../../../../services/tms-shift.service";
const TmsSmShiftManagement = ({ router }) => {
  const [loading, setLoading] = useState();
  const [showShiftsModal, setShowShiftsModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState(null);
  const [updateFlag, setUpdateFlag] = useState();
  const [data, setData] = useState([]);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const { userDetails } = useContext(BaseContext);

  const handleModify = async (record) => {
    setEditData(record);
    setEditFlag(true);
    setShowShiftsModal(true);
  };

  useEffect(() => {
    getAllShifts();
  }, [updateFlag]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const columns = [
    { title: "id", dataIndex: "key", key: "key" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (record) => {
        return moment(record, "HH:mm:ss Z").format("hh:mm A");
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (record) => {
        return moment(record, "HH:mm:ss Z").format("hh:mm A");
      },
    },
    {
      title: "Status",
      dataIndex: "enabled",
      key: "enabled",
      render: (text) => {
        return !text ? <Badge color="red" /> : <Badge color="green" />;
      },
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            {/* <Popconfirm
              title="Are you sure delete this shift?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await TmsShiftService.deleteShiftById(record.key);
                  notification.success({
                    message: `Shift has been deleted`,
                  });
                  getAllShifts();
                } catch (error) {
                  catchErrorFunction(
                    error,
                    "Token Expired!! Please Login",
                    router
                  );
                }

                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>{" "} */}
            <a
              onClick={(e) => {
                e.preventDefault();
                handleModify(record);
              }}
            >
              Edit
            </a>
          </Space>
        );
      },
    },
  ];

  const getAllShifts = async () => {
    try {
      setLoading(true);
      const shifts = await TmsShiftService.getAllShifts();
      setData(
        shifts?.items?.map((item) => {
          return {
            key: item.id,
            id: item.id,
            name: item?.name,
            startTime: item?.startTime,
            endTime: item?.endTime,
            enabled: item?.enabled,
          };
        })
      );
      setTabelTotalSize(shifts?.meta?.totalItems);

      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token is expired. You wll be navigated to login page",
        router
      );
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Row style={{ width: "100%", marginBottom: "15px" }} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setShowShiftsModal(true);
            }}
            disabled={
              userDetails?.permissions?.includes("SUPER_ADMIN") ||
              userDetails?.permissions?.includes("ADMIN")
                ? false
                : true
            }
          >
            Add New Shift
          </Button>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <TmsTable
          dataSource={data}
          loading={loading}
          columns={columns}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        />
      </Row>
      <TmsSCCreateShiftModal
        showShiftsModal={showShiftsModal}
        setShowShiftsModal={setShowShiftsModal}
        setUpdateFlag={setUpdateFlag}
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        editData={editData}
        setEditData={setEditData}
      />
    </div>
  );
};

export default withRouter(TmsSmShiftManagement);
