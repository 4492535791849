import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportPublicationReport from "./tms-report-publication-report";

const TmsReportPublication = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.report_publication) {
      setActive("Publication Report");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Publication Report",
      Element: <TmsReportPublicationReport />,
      key: "Publication Report",
      route: tmsRouteMap.report_publication,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportPublication);
