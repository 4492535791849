import { Button, Col, Divider, notification, Row } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import TmsDatepickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsSevasService from "../../../../services/tms-sevas.service";
import TmsDarshanamService from "../../../../services/tms-darshanam/tms-darshanam.service";
import TmsDonationService from "../../../../services/tms-donation.service";
import TmsPrasadamService from "../../../../services/tms-prasadam.service";
import TmsThulabharamService from "../../../../services/tms-thulabharam.service";
import TmsTollgateService from "../../../../services/tms-tollgate.service";
import TmsKalayanakattaService from "../../../../services/tms-kalayanakatta.service";
import TmsAccommodationService from "../../../../services/tms-accommodation.service";
import TmsManualEntryService from "../../../../services/tms-manual-entry.service";
import TmsMiscService from "../../../../services/tms-misc.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsBusService from "../../../../services/tms-bus-service";
const TmsReportsRequestNew = ({ router }) => {
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [dateType, setDateType] = useState("payment");
  // const [sortType, setSortType] = useState("DESC");
  const [bookingType, setBookingType] = useState("pos");
  const [paymentStatus, setPaymentStatus] = useState("success");
  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);
  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);
  const [serviceType, setServiceType] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("bookingDate");

  const [sevaType, setSevaType] = useState("");

  const [serviceDataList, setServiceDataList] = useState([]);
  const [selectedServiceDataList, setSelectedServiceDataList] = useState("");

  const [serviceDataSlotList, setServiceDataSlotList] = useState([]);
  const [selectedServiceSlot, setSelectedServiceSlot] = useState("");

  const menuService = {
    Sevas: {
      online: TmsSevasService.sevaReportOnline,
      pos: TmsSevasService.sevaReport,
    },
    Darshanam: {
      online: TmsDarshanamService.darshanamReportOnline,
      pos: TmsDarshanamService.darshanamReport,
    },
    Donation: {
      online: TmsDonationService.donationOnlineReport,
      pos: TmsDonationService.donationReport,
    },
    Prasadam: {
      pos: TmsPrasadamService.prasadamReport,
    },
    Thulabharam: {
      pos: TmsThulabharamService.thulabharamReport,
    },
    Tollgate: {
      pos: TmsTollgateService.tollGateReport,
    },
    Kalyanakatta: {
      pos: TmsKalayanakattaService.kalayanakattaReport,
    },
    Accommodation: {
      online: TmsAccommodationService.accommodationReportOnline,
      pos: TmsAccommodationService.accommodationReport,
      advance: TmsAccommodationService.accommodationReportAdvance,
    },
    "Manual Entry": {
      pos: TmsManualEntryService.manualEntryReport,
    },
    Miscellaneous: { pos: TmsMiscService.miscReport },
    Bus: { pos: TmsBusService.busReport },
  };
  const serviceAPIList = {
    Sevas: {
      listAPI: TmsSevasService.getSevas,
      slotAPI: TmsSevasService.getSevaSlots,
    },
    Darshanam: {
      listAPI: TmsDarshanamService.getDarshanam,
      slotAPI: TmsDarshanamService.getDarshanamSlots,
    },
    Accommodation: {
      listAPI: TmsAccommodationService.getAllBlocks,
      slotAPI: TmsAccommodationService.getRoomsByBlockId,
    },
    Donation: {
      listAPI: TmsDonationService.getDonations,
    },
    Prasadam: {
      listAPI: TmsPrasadamService.getPrasadam,
    },
    Thulabharam: {
      listAPI: TmsThulabharamService.getThulabharam,
    },
    Tollgate: {
      listAPI: TmsTollgateService.getTollGate,
    },
    Kalyanakatta: {
      listAPI: TmsKalayanakattaService.getKalayanakatta,
    },
    "Manual Entry": {
      listAPI: TmsManualEntryService.getAll,
    },
    Miscellaneous: { listAPI: TmsMiscService.getMisc },
    Bus: { listAPI: TmsBusService.fetchAllBus },
  };
  const serviceData = [
    { id: "Sevas", name: "Seva", type: ["pos", "online"] },
    { id: "Darshanam", name: "Darshanam", type: ["pos", "online"] },
    { id: "Tollgate", name: "Tollgate", type: ["pos"] },
    { id: "Donation", name: "Donation", type: ["pos", "online"] },
    { id: "Prasadam", name: "Prasadam", type: ["pos"] },
    { id: "Thulabharam", name: "Thulabharam", type: ["pos"] },
    {
      id: "Accommodation",
      name: "Accommodation",
      type: ["pos", "online", "advance"],
    },
    { id: "Kalyanakatta", name: "Kalyanakatta", type: ["pos"] },
    { id: "Manual Entry", name: "Manual Entry", type: ["pos"] },
    { id: "Miscellaneous", name: "Miscellaneous", type: ["pos"] },
    { id: "Bus", name: "Bus", type: ["pos"] },
  ];

  useEffect(() => {
    if (bookingType === "pos") {
      fetchCounterList();
      fetchShiftList();
    }
    return () => {
      clearFields();
    };
  }, []);

  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  const handleReportRequest = async () => {
    const serviceBasedKeys = {
      Donation: "donationId",
      Thulabharam: "thulabharamType",
      Prasadam: "prasadamType",
      Tollgate: "tollGateType",
      Kalyanakatta: "kalyankattaType",
      "Manual Entry": "Book",
      Miscellaneous: "miscType",
      Bus: "route",
    };
    try {
      setLoading(true);
      let body = {
        format: "email",
        tableCurrentPage: Number(1),
        tablePageSize: 100000,
        fromDate: bookingFromDate,
        toDate: bookingToDate,
        dateType: dateType,
        sortType: "DESC",
        counter,
        shift,
        sortBy,
      };
      if (["Sevas", "Darshanam", "Accommodation"].includes(serviceType)) {
        if (serviceType === "Sevas") {
          body.sevaType = sevaType;
          body.sevaId = Number(selectedServiceDataList);
          body.slotId = selectedServiceSlot;
        } else if (serviceType === "Accomdation") {
          body.blockId = Number(selectedServiceDataList);
          if (selectedServiceSlot) body.selectedRoom = selectedServiceSlot;
        } else if (serviceType === "Darshanam") {
          body.darshanamType = Number(selectedServiceDataList);
          if (selectedServiceSlot) body.slot = selectedServiceSlot;
        }
      } else {
        body[serviceBasedKeys[serviceType]] = selectedServiceDataList;
      }
      console.log(body);
      await menuService[serviceType][bookingType](body);
      notification.success({ message: "Request raised successfully." });
      setLoading(false);
      clearFields();
    } catch (e) {
      catchErrorFunction(e, "Token expired. Login again", router);
      setLoading(false);
    }
  };
  const clearFields = () => {
    setBookingFromDate(moment().format("YYYY-MM-DD"));
    setBookingToDate(moment().format("YYYY-MM-DD"));
    setCounter("");
    setShift("");
    setDateType("booking");
  };

  //-----------------service related functions-------------//

  const fetchServiceList = async () => {
    try {
      let data = [];
      //clearing service List states
      setSelectedServiceDataList("");
      setServiceDataList([]);

      //clearing service List states
      setServiceDataSlotList([]);
      setSelectedServiceSlot("");

      if (serviceType === "Sevas") {
        if (
          ["parokshaSeva", "pratyakshaSeva", "saswataSeva"]?.includes(sevaType)
        ) {
          data = await TmsSevasService.getSevasByType(sevaType, 1, 1000);
        } else {
          data = await TmsSevasService.getSevas(1, 1000);
        }
      } else {
        data = await serviceAPIList[serviceType].listAPI(1, 1000);
      }

      setServiceDataList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (e) {
      catchErrorFunction(e, "Token expired. Login again.", router);
    }
  };

  const fetchServiceSlotList = async () => {
    try {
      setSelectedServiceSlot("");
      setServiceDataSlotList([]);
      const res = await serviceAPIList[serviceType].slotAPI(
        selectedServiceDataList
      );
      // console.log(res);
      setServiceDataSlotList(
        res?.map((item) => {
          return {
            key: item.productSlotId,
            ...item,
          };
        })
      );
    } catch (e) {
      catchErrorFunction(e, "Token expired. Login again.", router);
    }
  };

  useEffect(() => {
    if (
      serviceType !== "" &&
      selectedServiceDataList !== "" &&
      ["Sevas", "Darshanam", "Accommodation"].includes(serviceType)
    ) {
      fetchServiceSlotList();
    }
  }, [selectedServiceDataList]);

  useEffect(() => {
    if (serviceType !== "") {
      fetchServiceList();
    }
  }, [serviceType, sevaType]);

  //-----------------service related functions-------------//

  return (
    <div>
      <Row
        gutter={[20, 20]}
        style={{ paddingTop: "15px", position: "relative" }}
      >
        <Col span={8}>
          <TmsDatepickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
            disabledDate={false}
          />
        </Col>
        <TmsSelect
          type="col"
          span={4}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
          optional={true}
        />
        {bookingType === "online" && (
          <>
            {" "}
            <TmsSelect
              type="col"
              span={4}
              selectClass="w-100"
              label="Payment Status"
              setValue={setPaymentStatus}
              value={paymentStatus}
              data={[
                { id: "created", name: "created" },
                { id: "success", name: "success" },
                { id: "failure", name: "failure" },
                { id: "refund", name: "refund" },
                { id: "canceled", name: "canceled" },
              ]}
              optional={true}
            />
          </>
        )}
        {bookingType === "pos" && (
          <>
            {" "}
            <TmsSelect
              type="col"
              span={4}
              label="POS"
              setValue={setCounter}
              value={counter}
              data={counterList}
            />
            <TmsSelect
              type="col"
              span={4}
              label="Shift"
              setValue={setShift}
              value={shift}
              data={shiftList}
            />
          </>
        )}
        <TmsSelect
          type="col"
          span={4}
          label="Date Type"
          setValue={setDateType}
          value={dateType}
          data={[
            { id: "booking", name: "Performance" },
            { id: "payment", name: "Booking" },
          ]}
          optional={true}
        />
        <Divider />
        <TmsSelect
          type="col"
          span={6}
          label="Service Type"
          setValue={(e) => {
            setServiceType(e);
            setSevaType("");
          }}
          value={serviceType}
          data={serviceData.filter((item) => {
            if (item?.type.includes(bookingType)) {
              return { id: item.id, name: item.name };
            }
          })}
          optional={true}
        />
        {serviceType === "Sevas" && (
          <TmsSelect
            type="col"
            span={6}
            selectClass="w-100"
            label="Seva Type"
            setValue={setSevaType}
            value={sevaType}
            data={[
              { id: "parokshaSeva", name: "Paroksha Seva" },
              { id: "pratyakshaSeva", name: "Pratyaksha Seva" },
              { id: "saswataSeva", name: "Saswata Seva" },
            ]}
          />
        )}
        {serviceType !== "" && (
          <>
            <TmsSelect
              type="col"
              span={6}
              selectClass="w-100"
              label={`${serviceType} Name`}
              setValue={(e) => {
                setSelectedServiceDataList(e);
                if (e === "") {
                  setSelectedServiceSlot("");
                  return;
                }
              }}
              value={selectedServiceDataList}
              data={serviceDataList}
            />
            {selectedServiceDataList !== "" &&
              ["Sevas", "Darshanam", "Accommodation"].includes(serviceType) && (
                <TmsSelect
                  type="col"
                  span={6}
                  label={`${
                    serviceType === "Accommodation" ? "Block" : "Slot"
                  }`}
                  setValue={setSelectedServiceSlot}
                  value={selectedServiceSlot}
                  data={serviceDataSlotList}
                />
              )}
            {serviceType === "Accommodation" && (
              <>
                <TmsSelect
                  type="col"
                  span={6}
                  label="Sort By"
                  setValue={setSortBy}
                  value={sortBy}
                  data={[
                    { id: "bookingDate", name: "Booking Date" },
                    { id: "checkInDate", name: "Check In" },
                    { id: "checkOutDate", name: "Check Out" },
                  ]}
                  optional={true}
                />
              </>
            )}
          </>
        )}
      </Row>
      <br />
      <Row
        justify="center"
        onClick={() => {
          handleReportRequest();
        }}
      >
        <Button loading={loading} type="primary">
          Request Report
        </Button>
      </Row>
    </div>
  );
};

export default withRouter(TmsReportsRequestNew);
