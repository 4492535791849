import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsSevasService = {
  getSevas: async (page, limit) => {
    const resp = await Axios.get(`v1/seva/all?page=${page}&limit=${limit}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getSevasByType: async (sevaType, page, limit) => {
    const resp = await Axios.get(
      `v1/seva/all/by-type/${sevaType}?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getSevasByCounter: async (id) => {
    const resp = await Axios.get(
      `v1/seva-counter-slot/products/by-counter-id/${id}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createSeva: async (body) => {
    const resp = await Axios.post(`v1/seva/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteSeva: async (productId) => {
    const resp = await Axios.delete(`v1/seva/delete/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewSeva: async (productId) => {
    const resp = await Axios.get(`v1/seva/by-id/${productId}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  editSeva: async (productId, body) => {
    const resp = await Axios.put(`v1/seva/update/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  //seva-slots
  getSevaSlots: async (id) => {
    const resp = await Axios.get(`v1/seva-slot/product-slots/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  fetchProtocolSlots: async (fromDate, toDate, multipleProductIds) => {
    let url = `v1/seva-slot-daily-quota?fromDate=${fromDate}&toDate=${toDate}&multipleProductIds=${multipleProductIds}&type=protocol`;
    // &onlyOnlineEnabled=false&type=protocol${multipleProductIds
    //   .map((item) => `&multipleProductIds=${Number(item)}`)
    //   .join("")}
    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  createSevaSlots: async (body) => {
    const resp = await Axios.post(`v1/seva-slot/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  editSevaSlots: async (body, slotId) => {
    const resp = await Axios.put(`v1/seva-slot/update/${slotId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteSevaSlots: async (slotId) => {
    const resp = await Axios.delete(`v1/seva-slot/delete/${slotId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  sevaTerms: async (productId, termsData, onlineTerms, ticketType) => {
    const resp = await Axios.patch(
      `v1/seva/metadata/${productId}`,
      {
        termsAndConditions: termsData,
        onlineTermsAndConditions: onlineTerms,
        ticketType: ticketType ? "mini_ticket" : "full_ticket",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  //seva-slots-counters

  //seva-slots
  getSevaSlotCounter: async (id) => {
    const resp = await Axios.get(
      `v1/seva-counter-slot/by-product-slot-id/${id}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createSevaSlotCounter: async (body) => {
    const resp = await Axios.post(`v1/seva-counter-slot/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  editSevaSlotCounter: async (body, slotId) => {
    const resp = await Axios.put(
      `v1/seva-counter-slot/update/${slotId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteSevaSlotCounter: async (slotId) => {
    const resp = await Axios.delete(`v1/seva-counter-slot/delete/${slotId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  //Seva booking
  fetchSevaBookingSlots: async (id, date) => {
    const resp = await Axios.get(
      `v1/seva-counter-booking/valid-counter-slots/${id}?date=${date}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  sevasBooking: async (body) => {
    const resp = await Axios.post(`v1/counter-cart/new`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getPartnersForSeva: async (productId) => {
    const resp = await Axios.get(`v1/seva/partners/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  sevPartnerAccess: async (body) => {
    const resp = await Axios.patch(`v1/seva/partner-access`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  sevasBookingDetails: async ({
    format,
    fromDate,
    sevaType,
    toDate,
    dateType,
    sortType,
  }) => {
    let url = `v1/seva-counter-booking/reports?format=${format}&page=1&limit=100&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;
    if (sevaType !== "All") {
      url += `&sevaType=${sevaType}`;
    }
    const resp = await ReportsAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  sevaReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    sevaType,
    sevaId,
    slotId,
    counterId,
    shiftId,
    dateType,
  }) => {
    let query = `v1/seva-counter-report/booking?format=${format}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&dateType=${dateType}`;
    if (sevaType) {
      query += `&sevaType=${sevaType}`;
    }
    if (counterId && counterId !== "") {
      query += `&counterId=${counterId}`;
    }
    if (shiftId && shiftId !== "") {
      query += `&counterShiftTimingId=${shiftId}`;
    }
    if (slotId && slotId !== "") {
      query += `&sevaSlotId=${slotId}`;
    }
    if (sevaId && sevaId !== "") {
      query += `&productId=${sevaId}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  sevaReportOnline: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    sevaType,
    sevaId,
    slotId,
    paymentStatus,
    dateType,
    selectedPartner,
  }) => {
    let query = `v1/seva-online-report/booking?format=${format}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&dateType=${dateType}`;

    if (paymentStatus && paymentStatus !== "") {
      query += `&paymentStatus=${paymentStatus}`;
    }
    if (sevaType) {
      query += `&sevaType=${sevaType}`;
    }
    if (slotId && slotId !== "") {
      query += `&sevaSlotId=${slotId}`;
    }
    if (sevaId && sevaId !== "") {
      query += `&productId=${sevaId}`;
    }
    if (selectedPartner !== "" && selectedPartner) {
      query += `&partnerCode=${selectedPartner}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  //disable-dates
  fetchdisabledates: async (productId, page, limit) => {
    const resp = await Axios.get(
      `v1/seva/disable-date/by-product-id/${productId}?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createDisableDates: async (
    productIds,
    startDate,
    endDate,
    description,
    onlineDisabled,
    counterDisabled
  ) => {
    const resp = await Axios.post(
      `v1/seva/disable-date/create`,
      {
        productIds: productIds,
        startDate: startDate,
        endDate: endDate,
        description: description,
        onlineDisabled: onlineDisabled,
        counterDisabled: counterDisabled,
        protocolDisabled: false,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteDisableDate: async (id) => {
    const resp = await Axios.delete(`v1/seva/disable-date/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/seva-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  getOnlineConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/seva-online-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  getQuotaData: async ({ from, to, productIds, type }) => {
    const resp = await Axios.get(
      `v1/seva-slot-daily-quota?fromDate=${from}&toDate=${to}&productSlotId=${productIds}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateQuotaData: async (body) => {
    const res = await Axios.post(`v1/seva-slot-daily-quota`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
};

export default TmsSevasService;
