import { Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsAddress from "../../../../../../components/tms-customs/tms-address";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import "../../../../../../assets/css/tms-common/tms-common.scss";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import TmsLandAcreCentsInput from "../../../../../../components/tms-customs/tms-land-acre-cents-input";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import { createLandStates } from "../../../../../../constants/states-data.data";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";

const TmsLandCreation = ({ router, setVisible }) => {
  const {
    calculateAcresAndCents,
    landmark,
    setLandmark,
    nonAgriculturalAcres,
    setNonAgriculturalAcres,
    agriculturalAcres,
    setAgriculturalAcres,
    agriculturalCents,
    setAgriculturalCents,
    nonAgriculturalCents,
    setNonAgriculturalCents,
    zip,
    setZip,
    setSubmitLoading,
    state,
    setState,
    country,
    setCountry,
    district,
    setDistrict,
    institutionData,
    village,
    setVillage,
    mandal,
    setMandal,
    submitLoading,
    fetchInstitutionList,
    selectedInstitution,
    setSelectedInstitution,
    handleCreateLand,
    clearStates,
    status,
    setStatus,
  } = useRentsAndLandStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createLandStates, ["institutionData"]);
    };
  }, []);

  return (
    <div>
      <Row gutter={[20, 30]} style={{ marginBottom: "15px" }}>
        <TmsSelect
          type="col"
          span={12}
          label="Select Institution"
          setValue={setSelectedInstitution}
          selectClass="w-100"
          value={selectedInstitution}
          data={institutionData?.items?.map((item) => {
            return { name: item.name, id: item.id };
          })}
          optional={true}
        />
        <Col span={12}>
          <Row gutter="20">
            <Col span={12}>
              <Row className={"t-left"}>Land Mark</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Landmark"
                value={landmark}
                onChange={(e) => {
                  setLandmark(e.target.value);
                }}
              />
            </Col>

            <TmsLandAcreCentsInput
              valueOne={
                calculateAcresAndCents(
                  agriculturalAcres,
                  agriculturalCents,
                  nonAgriculturalAcres,
                  nonAgriculturalCents
                )?.totalAcres
              }
              setValueOne={() => {}}
              valueTwo={
                calculateAcresAndCents(
                  agriculturalAcres,
                  agriculturalCents,
                  nonAgriculturalAcres,
                  nonAgriculturalCents
                )?.totalCents
              }
              setValueTwo={() => {}}
              colspace={12}
              label="Total Land"
              disabled={true}
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={[20, 30]} style={{ marginBottom: "15px" }}>
        <TmsLandAcreCentsInput
          valueOne={agriculturalAcres}
          setValueOne={setAgriculturalAcres}
          valueTwo={agriculturalCents}
          setValueTwo={setAgriculturalCents}
          colspace={12}
          label="Agriculture Land"
        />
        <TmsLandAcreCentsInput
          valueOne={nonAgriculturalAcres}
          setValueOne={setNonAgriculturalAcres}
          valueTwo={nonAgriculturalCents}
          setValueTwo={setNonAgriculturalCents}
          colspace={12}
          label="Non Agricultural Land"
        />
        <TmsAddress
          span={12}
          village={village}
          setVillage={setVillage}
          mandal={mandal}
          setMandal={setMandal}
          district={district}
          setDistrict={setDistrict}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          type="general"
        />{" "}
      </Row>

      <TmsCheckbox label={"Land Status"} value={status} setValue={setStatus} />
      <TmsCancelSubmitButtons
        loading={submitLoading}
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(createLandStates, ["institutionData"]);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleCreateLand(router);
        }}
      />
    </div>
  );
};

export default withRouter(TmsLandCreation);
