import { Button, Checkbox, Input, notification, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import TmsShiftService from "../../../services/tms-shift.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsModal from "../../tms-customs/tms-modal";
import moment from "moment";
import TmsDatePicker from "../../tms-customs/tms-datepicker";
const TmsSCCreateShiftModal = ({
  showShiftsModal,
  setShowShiftsModal,
  setUpdateFlag,
  editFlag,
  setEditFlag,
  editData,
  setEditData,
  router,
}) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    if (editFlag) {
      // console.log(editData);
      setDetails(
        editData.name,
        moment(editData.startTime, "HH:mm:ss Z"),
        moment(editData.endTime, "HH:mm:ss Z"),
        editData.enabled
      );
    }
  }, [editFlag]);

  const handleCreateShift = async () => {
    if (!description || description === "") {
      return notification.error({ message: `description is required` });
    }
    if (!to || !from || to === "" || from === "") {
      return notification.error({
        message: `Make sure start and end times are valid`,
      });
    }

    setLoading(true);
    try {
      if (editFlag) {
        await TmsShiftService.updateShift(
          {
            name: description,
            startTime: moment(from).toDate(),
            endTime: moment(to).toDate(),
            enabled: enabled,
          },
          editData?.key
        );
        notification.success({
          message: `Shift updated successfully`,
        });
        setDetails("", "", "", "");
        setUpdateFlag(Math.random() * 100 + 9);
      } else {
        await TmsShiftService.addShift({
          name: description,
          startTime: moment(from).toDate(),
          endTime: moment(to).toDate(),
          enabled: enabled,
        });
        notification.success({ message: `Shift  created successfully` });
        setUpdateFlag(Math.random() * 1000 + 9);
      }
      onClose();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const setDetails = (shitName, from, to, isEnabled) => {
    setDescription(shitName);
    setTo(to);
    setFrom(from);
    setEnabled(isEnabled);
  };
  const onClose = () => {
    setShowShiftsModal(false);
    setEditData(null);
    setEditFlag(false);
  };

  return (
    <TmsModal
      centered={true}
      width="40%"
      visible={showShiftsModal}
      title={
        editFlag ? `Edit Shift Details - ${editData?.name}` : "Create Shift"
      }
      onOk={handleCreateShift}
      onCancel={() => {
        setDetails("", "", "", false);
        onClose();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setDetails("", "", "", false);
            onClose();
          }}
        >
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            handleCreateShift();
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        Name :{" "}
        <Input
          maxLength={220}
          style={{ marginTop: "10px" }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter Name "
        ></Input>
      </Row>
      <Row style={{ width: "100%" }}>
        <TmsDatePicker value={from} setValue={setFrom} label="Start Time" />
        <TmsDatePicker value={to} setValue={setTo} label="End Time" />
      </Row>
      <Row justify="center" style={{ width: "100%", padding: "10px 0px" }}>
        <Space size="middle">
          Enable Shift
          <Checkbox
            checked={enabled}
            onChange={(e) => {
              setEnabled(e.target.checked);
            }}
          />
        </Space>
      </Row>{" "}
    </TmsModal>
  );
};
export default withRouter(TmsSCCreateShiftModal);
