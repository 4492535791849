import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsPrasdamService from "../../../../../services/tms-prasadam.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsPrasadamService from "../../../../../services/tms-prasadam.service";
import moment from "moment";
const TmsPrasadamInventoryAddNew = ({ router }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [remarks] = useState("");
  const [posList, setPosList] = useState([]);
  const [prasadamList, setPrasadamList] = useState([]);
  const [selectedPrasadam, setSelectedPrasadam] = useState("");
  const [posListStates, setPosListStates] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    if (type === "" || quantity === "" || selectedPrasadam === "") {
      notification.error({ message: "Please enter all the details" });
      setLoading(false);
      return;
    }
    const percentage = Object.values(posListStates).reduce(
      (t, value) => t + Number(value),
      0
    );
    if (percentage !== 100) {
      setLoading(false);

      notification.error({
        message:
          "Please make sure the counter percentages equal to 100 percent",
      });
      return;
    }
    let inventryData = Object.keys(posListStates).map((key) => {
      return { counterId: Number(key), percentage: Number(posListStates[key]) };
    });
    let body = {
      prasadamId: selectedPrasadam,
      counterInventories: Object.values(inventryData),
      inwardQuantity: type === "inward" ? quantity : 0,
      outwardQuantity: type === "outward" ? quantity : 0,
    };

    if (type === "outward") {
      body.outwardRemarks = remarks;
    }
    try {
      await TmsPrasdamService.createPrasadamInventory(body);
      notification.success({
        message: "Prasadam Inverntory Created Successful",
      });
      cleanUpFunction();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const getPrasadams = async () => {
    setLoading(true);
    try {
      let data = [];
      setPrasadamList([]);
      data = await TmsPrasadamService.getPrasadam(1, 1000);

      setPrasadamList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const getPrasadamCounters = async (id) => {
    setLoading(true);
    try {
      let data = [];
      setPosList([]);
      data = await TmsPrasadamService.getPrasadamCounters(id);
      data = data.filter((item) => item.counter.enabled);

      setPosList(
        data?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      let posDataStates = {};
      data?.forEach((item) => {
        posDataStates[item.counterId] = "";
      });
      setPosListStates(posDataStates);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const cleanUpFunction = () => {
    setType("");
    setQuantity("");
    setPosList([]);
    setPosListStates({});
    setSelectedPrasadam("");
  };

  useEffect(() => {
    getPrasadams();
  }, []);

  return (
    <Row className="formRow" style={{ width: "100%" }}>
      <Card style={{ width: "100%" }}>
        <Row gutter={[20, 20]} className="formRow">
          <Col span={12}>
            <Row>
              <Col span={3}>
                <label style={{ paddingRight: "10px" }}>Date</label>
              </Col>
              <Col span={19}>
                <DatePicker
                  style={{ width: "100%" }}
                  defaultValue={moment()}
                  disabled={true}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={3}>
                <label style={{ paddingRight: "10px" }}>Item</label>
              </Col>
              <Col span={19}>
                <Select
                  style={{ width: "100%" }}
                  className={"sd-select-r"}
                  dropdownClassName="sd-select-dropdown-r"
                  onChange={(e) => {
                    setSelectedPrasadam(e);
                    getPrasadamCounters(e);
                  }}
                  value={selectedPrasadam}
                  defaultValue={selectedPrasadam}
                  placeholder={`Select a Prasadam`}
                >
                  {prasadamList?.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name ?? item.description}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[20, 20]} className="formRow">
          <Col span={12}>
            <Row>
              <Col span={3}>
                <label style={{ paddingRight: "10px" }}>Type</label>
              </Col>
              <Col span={19}>
                <Select
                  style={{ width: "100%" }}
                  className={"sd-select-r"}
                  dropdownClassName="sd-select-dropdown-r"
                  onChange={setType}
                  value={type}
                  defaultValue={type}
                  placeholder={`Select a Type`}
                >
                  <Select.Option key={"outward"} value="outward">
                    Outward
                  </Select.Option>{" "}
                  <Select.Option key={"inward"} value="inward">
                    Inward
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={3}>
                <label style={{ paddingRight: "10px" }}>Quantity</label>
              </Col>
              <Col span={19}>
                <Input
                  maxLength={220}
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        {selectedPrasadam !== "" && (
          <>
            <Row gutter={[20, 20]} justify="center">
              <h3>
                POS List -{" "}
                {prasadamList?.find((e) => e.id === selectedPrasadam)?.name}
              </h3>
            </Row>

            <br />

            <div className="tms-prasadam-inventory">
              <Row gutter={[20, 20]} className="formRow max-80">
                {posList?.map((item, i) => {
                  return (
                    <Col
                      key={i + "poslist"}
                      span={posList.length === 1 ? 24 : 12}
                    >
                      <Row style={{ alignItems: "center" }}>
                        <Col span={6}>
                          <label style={{ paddingRight: "10px" }}>
                            {item.counter?.name}
                          </label>
                        </Col>
                        <Col span={18}>
                          <Input
                            maxLength={220}
                            type="number"
                            min="0"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-", "."].includes(e.key) &&
                              e.preventDefault()
                            }
                            placeholder="Enter Percentage"
                            onWheel={(event) => event.currentTarget.blur()}
                            value={posListStates[item?.counter?.id]}
                            onChange={(e) => {
                              let data = { ...posListStates };
                              data[item.counter?.id] = e.target.value;
                              setPosListStates(data);
                            }}
                            prefix={
                              <span>
                                %
                                <Divider type="vertical" />
                              </span>
                            }
                            suffix={
                              <span>
                                <Divider type="vertical" />
                                {quantity *
                                  (posListStates[item?.counter?.id] / 100)}
                                / {quantity}
                              </span>
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>

              <Row gutter={[20, 20]} justify="center" className="max-80">
                <Col className="">
                  <Button
                    className="formButton"
                    type="primary"
                    ghost
                    onClick={cleanUpFunction}
                  >
                    Clear
                  </Button>
                </Col>
                <Col className="">
                  <Button
                    className="formButton"
                    type="primary"
                    loading={loading}
                    onClick={() => {
                      fetchData();
                    }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Card>
    </Row>
  );
};

export default withRouter(TmsPrasadamInventoryAddNew);
