import { Button, Col, Input, Row } from "antd";
import React from "react";

const TmsSmParakamaniPersons = ({ personsArray, setPersonsArray }) => {
  const addCurrency = () => {
    let temp = [...personsArray];
    setPersonsArray([...temp, { name: "", designation: "", address: "" }]);
  };

  const removeCurrency = (index) => {
    let temp = [...personsArray];
    temp?.splice(index, 1);
    setPersonsArray([...temp]);
  };

  const handlePersons = (val, index, key) => {
    let temp = [...personsArray];
    temp[index][key] = val;
    setPersonsArray([...temp]);
  };

  return (
    <div>
      <label style={{ fontWeight: "bold" }}>Persons Present</label>
      <br />
      {personsArray?.map((item, i) => {
        return (
          <Row key={i} gutter={[20, 20]} style={{ marginBottom: "10px" }}>
            <Col span={6}>
              <label>
                Name <span className="mandatory">*</span>
              </label>
              <br />{" "}
              <Input
                maxLength={220}
                placeholder="Enter name"
                value={item?.name}
                onChange={(e) => {
                  const input = e.target.value;
                  const pattern = /^[A-Za-z\s]+$/;
                  if (pattern.test(input) || input === "") {
                    handlePersons(input, i, "name");
                  }
                }}
              />
            </Col>
            <Col span={6}>
              <label>
                Designation <span className="mandatory">*</span>
              </label>
              <br />{" "}
              <Input
                maxLength={220}
                placeholder="Enter designation"
                value={item?.designation}
                onChange={(e) => {
                  const input = e.target.value;
                  const pattern = /^[A-Za-z\s]+$/;
                  if (pattern.test(input) || input === "") {
                    handlePersons(input, i, "designation");
                  }
                }}
              />
            </Col>
            <Col span={6}>
              <label>Address</label>
              <br />{" "}
              <Input
                maxLength={220}
                placeholder="Enter address"
                value={item?.address}
                onChange={(e) => {
                  handlePersons(e.target.value, i, "address");
                }}
              />
            </Col>
            <Col>
              <label></label>
              <br />{" "}
              <Button
                type="primary"
                onClick={() => {
                  i + 1 === personsArray?.length
                    ? addCurrency()
                    : removeCurrency(i);
                }}
              >
                {i + 1 === personsArray?.length ? "Add" : "Remove"}
              </Button>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default TmsSmParakamaniPersons;
