import React, { memo } from "react";
import { sortByData } from "../../../constants/states-data.data";
import TmsSelect from "../tms-select";

const SortTypeAndBy = ({ span, sortType, setSortType, sortBy, setSortBy }) => {
  console.log(sortType);
  return (
    <>
      {" "}
      <TmsSelect
        selectClass="w-100 "
        classname=" align-center"
        labelClass="mr-10 mb-0"
        type="col"
        value={sortType}
        setValue={setSortType}
        label="Sort type:"
        data={[
          { name: "Ascending", id: "ASC" },
          {
            name: "Descending",
            id: "DESC",
          },
        ]}
        span={span}
        optional={true}
      />
      <TmsSelect
        selectClass="w-100 "
        classname=" align-center"
        labelClass="mr-10 mb-0"
        type="col"
        value={sortBy}
        setValue={setSortBy}
        label="Sort By:"
        data={sortByData}
        span={span}
        optional={true}
      />
    </>
  );
};

export default memo(SortTypeAndBy);
