import { Col, Row, Input, DatePicker, Radio } from "antd";
import React, { useEffect } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  extentTypes,
  IdProofData,
  leaseAddtionalStates,
  leaseCreationStates,
  LeeseType,
  paymentTerms,
  paymentTypes,
  yesOrNotData,
} from "../../../../../../constants/states-data.data";
import TmsDatePickerRange from "../../../../../../components/tms-customs/tms-datepicker-range";
import TmsLandAcreCentsInput from "../../../../../../components/tms-customs/tms-land-acre-cents-input";
import TmsAddress from "../../../../../../components/tms-customs/tms-address";
import TmsImageUpload from "../../../../../../components/tms-customs/tms-image-upload";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsPhoneNumberBasic from "../../../../../../components/tms-customs/tms-phone-number-basic";
import {
  InstitutionSelect,
  SurveyBreakUpNoSelect,
  SurveyNoSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";
import TmsInput from "../../../../../../components/tms-customs/tms-input";
const TmsLandLeaseCreation = ({ router, setVisible }) => {
  const {
    submitLoading,
    selectedInstitution,
    setStatus,
    gstAmount,
    status,
    selectedSurveyNumber,
    selectedSNBreakup,
    address,
    setAddress,
    fetchInstitutionList,
    extent,
    setExtent,
    nonAgriculturalAcres,
    setNonAgriculturalAcres,
    agriculturalAcres,
    setAgriculturalAcres,
    agriculturalCents,
    setAgriculturalCents,
    nonAgriculturalCents,
    setNonAgriculturalCents,
    fetchSurveyNumbers,
    lesseType,
    idProofNumber,
    setIdProofNumber,
    setLesseType,
    fetchSurveyNumbersBreakUp,
    createdSNBreakupData,
    clearStates,
    setIdProofType,
    idProofType,
    setUploadIdProof,
    uploadIdProof,
    setLeasePeriodEndDate,
    leasePeriodEndDate,
    leasePeriodStartDate,
    setLeasePeriodStartDate,
    handleLeaseCreation,
    paymentTerm,
    setPaymentTerm,
    paymentType,
    setPaymentType,
    setKindItem,
    kindItem,
    setKindItemUnitsInKg,
    kindItemUnitsInKg,
    leaseRate,
    setLeaseRate,
    depositAmount,
    setDepositAmount,
    gender,
    setGender,
    mrNo,
    setMrNo,
    mrDate,
    setMrDate,
    setLesseName,
    lesseName,
    setMobileNumber,
    gstNo,
    setGstNo,
    mobileNumber,
    setIncorporationOrPanNo,
    incorporationOrPanNo,
    contact_person_name,
    setContact_person_name,
    zip,
    setZip,
    setSubmitLoading,
    state,
    setState,
    country,
    setCountry,
    district,
    setDistrict,
    village,
    setVillage,
    mandal,
    setMandal,
    reason,
    setReason,
    setLeaseApprovedByCompetentAuthority,
    leaseApprovedByCompetentAuthority,
    uploadApprovedDocument,
    setUploadApprovedDocument,
    uploadApprovedDocumentOrderNo,
    setUploadApprovedDocumentOrderNo,
    uploadApprovedDocumentOrderDate,
    setUploadApprovedDocumentOrderDate,
    eoProceedingUploadDocument,
    setEoProceedingUploadDocument,
    remarks,
    setRemarks,
    dueDate,
    setDueDate,
    setGstAmount,
  } = useRentsAndLandStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(leaseCreationStates, leaseAddtionalStates);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchSurveyNumbers(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  useEffect(() => {
    if (selectedSurveyNumber !== "" && selectedSurveyNumber)
      fetchSurveyNumbersBreakUp(1, 200, "ASC", "createdAt", router);
  }, [selectedSurveyNumber]);

  return (
    <div>
      <Row gutter={[20, 20]} style={{ marginBottom: "15px" }}>
        <InstitutionSelect span={8} />
        <SurveyNoSelect span={8} />
        {createdSNBreakupData?.items?.length > 0 && (
          <SurveyBreakUpNoSelect span={8} />
        )}
        <Row
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333333",
            padding: "0px 20px",
            width: "100%",
          }}
        >
          Land Details
        </Row>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={selectedSNBreakup?.id ? selectedSNBreakup.extent : extent}
          setValue={setExtent}
          label="Enter Extent"
          data={extentTypes}
          span={12}
          optional={true}
          disabled={selectedSNBreakup?.id ? true : false}
        />
        <TmsLandAcreCentsInput
          valueOne={
            selectedSNBreakup?.id
              ? selectedSNBreakup.extentAcres
              : extent === "AGRICULTURE"
              ? selectedSurveyNumber?.agricultureLandAcres
              : selectedSurveyNumber?.nonAgricultureLandAcres
          }
          setValueOne={() => {}}
          valueTwo={
            selectedSNBreakup?.id
              ? selectedSNBreakup.extentCents
              : extent === "AGRICULTURE"
              ? selectedSurveyNumber?.agricultureLandCents
              : selectedSurveyNumber?.nonAgricultureLandCents
          }
          setValueTwo={() => {}}
          colspace={12}
          label={`${extent} Land`}
          disabled={true}
        />
        {/* <TmsLandAcreCentsInput
          valueOne={
            selectedSNBreakup?.id
              ? selectedSNBreakup.extentAcres
              : extent === "AGRICULTURE"
              ? selectedSurveyNumber?.balanceAgriLandAcres
              : selectedSurveyNumber?.balanceNonAgriLandAcres
          }
          setValueOne={() => {}}
          valueTwo={
            selectedSNBreakup?.id
              ? selectedSNBreakup.extentCents
              : extent === "AGRICULTURE"
              ? selectedSurveyNumber?.balanceAgriLandCents
              : selectedSurveyNumber?.balanceNonAgriLandCents
          }
          setValueTwo={() => {}}
          colspace={6}
          label={`Available ${extent} Land`}
          disabled={true}
        />{" "} */}
        <TmsLandAcreCentsInput
          valueOne={
            extent === "AGRICULTURE" ? agriculturalAcres : nonAgriculturalAcres
          }
          setValueOne={
            extent === "AGRICULTURE"
              ? setAgriculturalAcres
              : setNonAgriculturalAcres
          }
          valueTwo={
            extent === "AGRICULTURE" ? agriculturalCents : nonAgriculturalCents
          }
          setValueTwo={
            extent === "AGRICULTURE"
              ? setAgriculturalCents
              : setNonAgriculturalCents
          }
          colspace={12}
          label="Lease Extent"
          maxValueOne={
            extent === "AGRICULTURE"
              ? selectedSNBreakup?.id
                ? selectedSNBreakup.extentAcres
                : selectedSurveyNumber?.agricultureLandAcres
              : selectedSNBreakup?.id
              ? selectedSNBreakup.extentAcres
              : selectedSurveyNumber?.nonAgricultureLandAcres
          }
          maxValueTwo={
            extent === "AGRICULTURE"
              ? selectedSNBreakup?.id
                ? selectedSNBreakup.extentCents
                : selectedSurveyNumber?.agricultureLandCents
              : selectedSNBreakup?.id
              ? selectedSNBreakup.extentCents
              : selectedSurveyNumber?.nonAgricultureLandCents
          }
        />
        {/* <Col span={12}>
          <Row className={"t-left"}>Lease Period Start Date</Row>
          <DatePicker style={{ width: "100%" }} format={"DD-MM-YYYY"} />
        </Col> */}
        <Col span={12}>
          <TmsDatePickerRange
            label="Select Lease Period"
            to={leasePeriodEndDate}
            from={leasePeriodStartDate}
            setFrom={setLeasePeriodStartDate}
            setTo={setLeasePeriodEndDate}
            disabledDate={false}
          />{" "}
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Due Date (Ex. 15)</Row>
          <Input
            maxLength={220}
            min="1"
            type="number"
            max={31}
            placeholder="Due Date"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            value={dueDate}
            onChange={(e) => {
              if (e.target.value > 31) {
                return;
              }
              setDueDate(e.target.value);
            }}
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={paymentTerm}
          setValue={setPaymentTerm}
          label="Payment Term"
          span={12}
          optional={true}
          data={paymentTerms}
        />
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={paymentType}
          setValue={setPaymentType}
          label="Payment Type"
          span={12}
          optional={true}
          data={[...paymentTypes, { id: "KIND", name: "KIND" }]}
        />
        {paymentType === "KIND" ? (
          <>
            {" "}
            <Col span={12}>
              <Row className={"t-left"}>If Payment Type is Kind</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Kind item"
                value={kindItem}
                onChange={(e) => {
                  setKindItem(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Kind Item Units in KGs</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Kind Item in Units"
                value={kindItemUnitsInKg}
                onChange={(e) => {
                  setKindItemUnitsInKg(e.target.value);
                }}
              />
            </Col>
          </>
        ) : (
          <>
            <Col span={12}>
              <Row className={"t-left"}>Lease Rate</Row>
              <Input
                maxLength={220}
                type="number"
                min="0"
                placeholder="Lease rate"
                value={leaseRate}
                onChange={(e) => {
                  setLeaseRate(e.target.value);
                }}
                onWheel={(event) => event.currentTarget.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Deposit Amount</Row>
              <Input
                maxLength={220}
                type="number"
                min="0"
                placeholder=""
                value={depositAmount}
                onChange={(e) => {
                  setDepositAmount(e.target.value);
                }}
                onWheel={(event) => event.currentTarget.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>MR No</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder=""
                value={mrNo}
                onChange={(e) => {
                  setMrNo(e.target.value);
                }}
              />
            </Col>{" "}
            <Col span={12}>
              <Row className={"t-left"}>MR Date</Row>
              <DatePicker
                disabled={false}
                value={mrDate}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                onChange={(date) => {
                  setMrDate(date);
                }}
                size="medium"
              />{" "}
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>GST Amount(%)</Row>

              <Input
                maxLength={220}
                type="number"
                value={gstAmount}
                min={0}
                onWheel={(event) => event.currentTarget.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                max={101}
                style={{ width: "100%" }}
                placeholder="Enter Gst Percentage"
                onChange={(e) => {
                  if (Number(e.target.value) < 101) {
                    setGstAmount(Number(e.target.value));
                  }
                }}
                suffix={`Rs.${gstAmount ? (leaseRate / 100) * gstAmount : 0}`}
              ></Input>
            </Col>
          </>
        )}
        <Row
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333333",
            padding: "0px 20px 0px 10px",
            width: "100%",
          }}
        >
          Lessee Details
        </Row>
        <Row style={{ width: "100%", padding: "0px 20px" }}>
          <Radio.Group
            options={LeeseType}
            value={lesseType}
            checked={lesseType}
            id={lesseType}
            onChange={(e) => {
              setLesseType(e.target.value);
            }}
          />
        </Row>
        <TmsInput
          type="text"
          label="Lessee Name"
          span={12}
          value={lesseName}
          setValue={setLesseName}
          placeholder={"Enter Lessee Name"}
        />
        {lesseType === "individual" ? (
          <TmsSelect
            selectClass="w-100 "
            classname="ml-0 "
            type="col"
            value={gender}
            setValue={setGender}
            label="Gender"
            span={12}
            optional={true}
            data={[
              { name: "male", id: "male" },
              { name: "female", id: "female" },
            ]}
          />
        ) : (
          <>
            <Col span={12}>
              <Row className={"t-left"}>Incorporation Number/PAN No</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Incorporation/PAN No"
                value={incorporationOrPanNo}
                onChange={(e) => {
                  setIncorporationOrPanNo(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>GST NO</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Gst No"
                value={gstNo}
                onChange={(e) => {
                  setGstNo(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Contact Person Name</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Name"
                value={contact_person_name}
                onChange={(e) => {
                  setContact_person_name(e.target.value);
                }}
              />
            </Col>
          </>
        )}
        <Col span={12}>
          <Row className={"t-left"}>Mobile Number</Row>
          <TmsPhoneNumberBasic
            value={mobileNumber}
            setValue={setMobileNumber}
            placeHolder={"Enter mobile number"}
            disable={false}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Address</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Col>
        <TmsAddress
          span={12}
          village={village}
          setVillage={setVillage}
          mandal={mandal}
          setMandal={setMandal}
          district={district}
          setDistrict={setDistrict}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          type="general"
        />
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={idProofType}
          setValue={(e) => {
            setIdProofType(e);
            setIdProofNumber("");
          }}
          label="Select Id Proof"
          data={IdProofData}
          span={12}
          optional={true}
        />
        <TmsInput
          type="text-number"
          label="ID Proof Number"
          span={6}
          value={idProofNumber}
          setValue={(e) => {
            if (e?.length > 12) {
              return;
            }
            setIdProofNumber(`${e?.toUpperCase()}`);
          }}
          placeholder={"Enter ID Proof No"}
        />
        <Col span={6}>
          <Row className={"t-left"} style={{ marginBottom: "5px" }}>
            Upload ID Proof
          </Row>
          <TmsImageUpload
            fileList={uploadIdProof}
            setFileList={setUploadIdProof}
            router={router}
            type="private"
            accept={true}
            className="w-100"
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={leaseApprovedByCompetentAuthority}
          setValue={setLeaseApprovedByCompetentAuthority}
          label="Lease Approved by Competent Authority"
          data={yesOrNotData}
          span={12}
          optional={true}
        />
        {leaseApprovedByCompetentAuthority ? (
          <>
            {" "}
            <Col span={12}>
              <Row className={"t-left"} style={{ marginBottom: "5px" }}>
                Upload Approved Documents
              </Row>
              <TmsImageUpload
                fileList={uploadApprovedDocument}
                setFileList={setUploadApprovedDocument}
                router={router}
                type="private"
                accept={true}
                className="w-100"
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>If Yes, Enter Orders No </Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Order no"
                value={uploadApprovedDocumentOrderNo}
                onChange={(e) => {
                  setUploadApprovedDocumentOrderNo(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Approved Document Order Date</Row>
              <DatePicker
                disabled={false}
                value={uploadApprovedDocumentOrderDate}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                onChange={(date) => {
                  setUploadApprovedDocumentOrderDate(date);
                }}
                size="medium"
              />{" "}
            </Col>
          </>
        ) : (
          <Col span={12}>
            <Row className={"t-left"}>If No, Enter Reason</Row>
            <Input
              maxLength={220}
              type="text"
              placeholder="Enter Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Col>
        )}
        <Col span={12}>
          <Row className={"t-left"} style={{ marginBottom: "5px" }}>
            Eo proceedings Upload Documents
          </Row>
          <TmsImageUpload
            fileList={eoProceedingUploadDocument}
            setFileList={setEoProceedingUploadDocument}
            router={router}
            type="private"
            accept={true}
            className="w-100"
          />
        </Col>
        <Col span={12}>
          {" "}
          <Row className={"t-left"}>Remarks</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Col>
      </Row>
      <TmsCheckbox label={"Lease Status"} value={status} setValue={setStatus} />
      <TmsCancelSubmitButtons
        loading={submitLoading}
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(leaseCreationStates, leaseAddtionalStates);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleLeaseCreation(router);
        }}
      />
    </div>
  );
};

export default withRouter(TmsLandLeaseCreation);
