import { Row } from "antd";
import React, { memo } from "react";
const TmsRowVertical = ({ data }) => {
  return (
    <Row style={data.style}>
      <Row justify={data.justify} style={{ width: "100%" }}>
        {data.one}
      </Row>
      <Row justify={data.justify} style={{ width: "100%" }}>
        {data.two}
      </Row>
    </Row>
  );
};
export default memo(TmsRowVertical);
