import { Button, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import TmsCounterService from "../../../services/tms-counter.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsModal from "../../tms-customs/tms-modal";
import TmsCheckbox from "../../tms-customs/tms-checkbox";
import TmsRowVertical from "../../tms-customs/tms-row-vertical";

const TmsCMPosDeviceModal = ({
  posdeviceModal,
  setPosDeviceModal,
  setUpdateFlag,
  editFlag,
  setEditFlag,
  editData,
  setEditData,
  router,
}) => {
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [ApiKey, setApiKey] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [userName, setUserName] = useState("");
  useEffect(() => {
    if (editFlag) {
      setDetails(
        editData.configuration?.ezeetap.deviceId,
        editData.name,
        editData.configuration?.ezeetap.userName,
        "",
        editData.isActive
      );
    }
  }, [editFlag]);

  const handleAddPosDevice = async () => {
    setLoading(true);
    try {
      let body = {
        isActive: enabled,
        configuration: {
          type: "ezeetap",
          ezeetap: {
            deviceId: deviceId,
            userName,
            apiKey: ApiKey,
          },
        },
        name: deviceName,
      };
      if (editFlag) {
        await TmsCounterService.editPosDeviceById(body, editData.id);
        notification.success({
          message: `Device has been registered`,
        });
      } else {
        await TmsCounterService.addPosDevice(body);
        notification.success({
          message: `Device has been updated`,
        });
      }
      setUpdateFlag(Math.random() * 100 + 55);
      handleCancel();
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please Login", router);
    }
    setLoading(false);
  };

  const setDetails = (id, name, username, api, enabled) => {
    setDeviceId(id);
    setDeviceName(name);
    setApiKey(api);
    setEnabled(enabled);
    setUserName(username);
  };

  const onClose = () => {
    setEditData(null);
    setEditFlag(false);
    setPosDeviceModal(false);
  };

  const handleCancel = () => {
    setDetails("", "", "", "", false);
    onClose();
  };

  return (
    <TmsModal
      width="40%"
      visible={posdeviceModal}
      title={editFlag ? `Edit Device - ${editData?.id}` : "Create Device"}
      onOk={handleAddPosDevice}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            handleAddPosDevice();
          }}
        >
          Submit
        </Button>,
      ]}
    >
      {loading ? (
        ""
      ) : (
        <>
          <TmsRowVertical
            data={{
              one: "Name",
              two: (
                <Input
                  maxLength={220}
                  placeholder="POS Device Name"
                  style={{ maxWidth: "300px" }}
                  value={deviceName}
                  onChange={(e) => setDeviceName(e.target.value)}
                />
              ),
              justify: "center",
              style: { width: "100%", padding: "10px 0px" },
            }}
          />
          <TmsRowVertical
            data={{
              one: "Device provider",
              two: (
                <Input
                  maxLength={220}
                  style={{ maxWidth: "300px" }}
                  value={"Eezepay"}
                  disabled={true}
                />
              ),
              justify: "center",
              style: { width: "100%", padding: "10px 0px" },
            }}
          />
          <TmsRowVertical
            data={{
              one: "Device ID",
              two: (
                <Input
                  maxLength={220}
                  placeholder="Device ID"
                  style={{ maxWidth: "300px" }}
                  value={deviceId}
                  onChange={(e) => setDeviceId(e.target.value)}
                />
              ),
              justify: "center",
              style: { width: "100%", padding: "10px 0px" },
            }}
          />
          <TmsRowVertical
            data={{
              one: "Username(Device)",
              two: (
                <Input
                  maxLength={220}
                  placeholder="Display Name"
                  style={{ maxWidth: "300px" }}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              ),
              justify: "center",
              style: { width: "100%", padding: "10px 0px" },
            }}
          />
          <TmsRowVertical
            data={{
              one: "API Key",
              two: (
                <Input
                  maxLength={220}
                  placeholder="API key"
                  style={{ maxWidth: "300px" }}
                  value={ApiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
              ),
              justify: "center",
              style: { width: "100%", padding: "10px 0px" },
            }}
          />
          <TmsCheckbox
            label="Enable Pos Device"
            setValue={setEnabled}
            value={enabled}
          />
        </>
      )}
    </TmsModal>
  );
};
export default withRouter(TmsCMPosDeviceModal);
