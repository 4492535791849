import { Button, Input, notification, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import TmsModal from "../tms-customs/tms-modal";
import TmsSelect from "../tms-customs/tms-select";
import TmsCheckbox from "../tms-customs/tms-checkbox";
import TmsTempleService from "../../services/tms-temple.service";
const TmsBookingLimiterModal = ({
  visible,
  setVisible,
  editDetails,
  setEditDetails,
  setUpdateTable,
  router,
}) => {
  const [productType, setProductType] = useState();
  const [duration, setDuration] = useState("");
  const [isActive, setIsActive] = useState("");
  const [limit, setLimit] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreateLimiter = async () => {
    setLoading(true);
    try {
      if (editDetails !== "") {
        await TmsTempleService.updateCreatedBookingLimits(
          {
            duration,
            isActive,
            limit,
          },
          editDetails.id
        );
      } else {
        await TmsTempleService.createBookingLimit(
          productType,
          duration,
          isActive,
          limit
        );
      }
      setLoading(false);
      setVisible(false);
      setProductType("");
      setDuration("");
      setIsActive("");
      notification.success({ message: "Limiter created Successfully" });
      setUpdateTable(Math.random());
      setEditDetails("");
    } catch (error) {
      setLoading(false);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
  };
  useEffect(() => {
    if (editDetails && editDetails !== "") {
      setIsActive(editDetails.isActive);
      setDuration(editDetails.duration);
      setLimit(editDetails.limit);
      setProductType(editDetails.productType);
    }
  }, [editDetails]);
  return (
    <TmsModal
      width="45%"
      visible={visible}
      title="Create Booking Limit"
      onOk={handleCreateLimiter}
      onCancel={() => {
        setVisible(false);
      }}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            handleCreateLimiter();
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <TmsSelect
        type="col"
        span={24}
        label={`Select Type`}
        selectClass="w-100"
        value={productType}
        data={[
          { name: "Accommodation", id: "online_accommodation" },
          { name: "Seva", id: "online_seva" },
          { name: "Darshanam", id: "online_darshanam" },
        ]}
        setValue={setProductType}
        optional={true}
      />
      <TmsSelect
        type="col"
        span={24}
        label={`Select Duration`}
        selectClass="w-100"
        value={duration}
        data={[
          { name: "Day", id: "day" },
          { name: "Week", id: "week" },
          { name: "Month", id: "month" },
        ]}
        setValue={setDuration}
        optional={true}
      />

      <Col span={24}>
        <Row className="cal-select">Limit</Row>
        <Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            defaultValue={limit}
            value={limit}
            style={{ width: "100%" }}
            placeholder="Enter Quota"
            onChange={(e) => {
              setLimit(Number(e.target.value));
            }}
          ></Input>
        </Row>
      </Col>

      <TmsCheckbox label={"Active "} value={isActive} setValue={setIsActive} />
    </TmsModal>
  );
};
export default withRouter(TmsBookingLimiterModal);
