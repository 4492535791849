import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsDashboardService = {
  getPaymentStatusFromDevice: async (counterCartPaymentID) => {
    const resp = await Axios.post(
      `v1/counter-cart-payment/pos-payment/status/${counterCartPaymentID}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );

    return resp.data;
  },
  getCollectionData: async (counter, fromDate, toDate, dateType, order) => {
    const resp = await ReportsAxios.get(
      `v1/dashboard/tickets-and-collection-count/${counter}?page=1&limit=100&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${order}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getIndividualCollectionData: async ({
    counter,
    fromDate,
    toDate,
    dateType,
    order,
  }) => {
    const resp = await ReportsAxios.get(
      `v1/dashboard/consolidated-report/${counter}?page=1&limit=100&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${order}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getAllTemplesIndividualCollectionData: async ({
    counter,
    fromDate,
    toDate,
    dateType,
    order,
  }) => {
    const resp = await ReportsAxios.get(
      `v1/dashboard/temple-consolidated-report/${counter}?page=1&limit=100&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${order}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,

          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getAllTempleTransactions: async (templeID, page, limit, paymentStatus) => {
    let query = `v1/dashboard/user/all-temples?templeId=${templeID}&page=${page}&limit=${limit}&filter=all`;
    if (paymentStatus) {
      query = `${query}&paymentStatus=${paymentStatus}`;
    }
    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getReportRequests: async (page, limit) => {
    const resp = await Axios.get(
      `v1/email-reports/user?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );

    return resp.data;
  },
  downloadReport: async (fileUploadId) => {
    const resp = await Axios.get(
      `/v1/file-upload/download-user-file/${fileUploadId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
        responseType: "blob",
      }
    );

    return resp.data;
  },
};
export default TmsDashboardService;
