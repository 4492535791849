import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import LandEncroachmentIndex from "./tms-sm-rents-and-leases-inner-pages/creation-index-pages/land-encroachment";
import LandleaseIndex from "./tms-sm-rents-and-leases-inner-pages/creation-index-pages/land-lease";
import LandSurveyBreakupIndex from "./tms-sm-rents-and-leases-inner-pages/creation-index-pages/land-survey-breakup";
import LandSurveyNoIndex from "./tms-sm-rents-and-leases-inner-pages/creation-index-pages/land-survey-no";
import LandCreationIndex from "./tms-sm-rents-and-leases-inner-pages/creation-index-pages/land-creation";
import LandArrearIndex from "./tms-sm-rents-and-leases-inner-pages/creation-index-pages/land-arrear";

const TmsSmLandsandLeases = ({ router }) => {
  const [active, setActive] = useState("Land Creation");
  useEffect(() => {
    let location = router?.location?.pathname;
    if (
      location.includes(tmsRouteMap.sm_ral_lands_land_creation) ||
      location === tmsRouteMap.sm_ral_lands
    ) {
      setActive("Land Creation");
      return;
    } else if (location.includes(tmsRouteMap.sm_ral_lands_survey_no_creation)) {
      setActive("Survey Number");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_ral_lands_survey_breakup_no_creation)
    ) {
      setActive("Survey No Breakup");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_ral_lands_land_lease_creation)
    ) {
      setActive("Land Lease");
      return;
    } else if (location.includes(tmsRouteMap.sm_ral_lands_arears_creation)) {
      setActive("Arears");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_ral_lands_enchroament_creation)
    ) {
      setActive("Encroachment");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Land Creation",
      key: "Land Creation",
      route: tmsRouteMap.sm_ral_lands_land_creation,
      Element: <LandCreationIndex />,
    },
    {
      name: "Survey Number",
      key: "Survey Number",
      route: tmsRouteMap.sm_ral_lands_survey_no_creation,
      Element: <LandSurveyNoIndex />,
    },
    {
      name: "Survey No Breakup",
      key: "Survey No Breakup",
      route: tmsRouteMap.sm_ral_lands_survey_breakup_no_creation,
      Element: <LandSurveyBreakupIndex />,
    },
    {
      name: "Land Lease",
      key: "Land Lease",
      route: tmsRouteMap.sm_ral_lands_land_lease_creation,
      Element: <LandleaseIndex />,
    },
    {
      name: "Arears",
      key: "Arears",
      route: tmsRouteMap.sm_ral_lands_arears_creation,
      Element: <LandArrearIndex />,
    },
    {
      name: "Encroachment",
      key: "Encroachment",
      route: tmsRouteMap.sm_ral_lands_enchroament_creation,
      Element: <LandEncroachmentIndex />,
    },
  ];

  return (
    <TmsTabsLayout
      components={components}
      active={active}
      setActive={setActive}
    />
  );
};
export default withRouter(TmsSmLandsandLeases);
