import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import LicenseLease from "./tms-sm-ral-licenses-inner-pages/index-pages/license-lease";
import LicenseArrears from "./tms-sm-ral-licenses-inner-pages/index-pages/license-arrears";
import LicenseCreation from "./tms-sm-ral-licenses-inner-pages/index-pages/license-creation";

const TmsRalLicenses = ({ router }) => {
  const [active, setActive] = useState("Creation");
  useEffect(() => {
    let location = router?.location?.pathname;
    if (
      location === tmsRouteMap.sm_ral_license_creation ||
      location === tmsRouteMap.sm_ral_licenses
    ) {
      setActive("License Creation");
      return;
    } else if (location.includes(tmsRouteMap.sm_ral_license_lease_creation)) {
      setActive("License Lease");
      return;
    } else if (location.includes(tmsRouteMap.sm_ral_license_arears_creation)) {
      setActive("License Arrear");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "License Creation",
      Element: <LicenseCreation />,
      key: "License Creation",
      route: tmsRouteMap.sm_ral_license_creation,
    },
    {
      name: "License Lease",
      Element: <LicenseLease />,
      key: "License Lease",
      route: tmsRouteMap.sm_ral_license_lease_creation,
    },
    {
      name: "License Arrear",
      Element: <LicenseArrears />,
      key: "License Arrear",
      route: tmsRouteMap.sm_ral_license_arears_creation,
    },
  ];

  return (
    <TmsTabsLayout
      components={components}
      active={active}
      setActive={setActive}
      //
    />
  );
};
export default withRouter(TmsRalLicenses);
