import { Col, Row, Input, DatePicker, Radio } from "antd";
import React, { useEffect } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  IdProofData,
  leaseAddtionalStates,
  LeeseType,
  licenseLeaseAddtionalStates,
  licenseLeaseCreationStates,
  paymentTerms,
  paymentTypes,
  yesOrNotData,
} from "../../../../../../constants/states-data.data";
import TmsDatePickerRange from "../../../../../../components/tms-customs/tms-datepicker-range";
import TmsAddress from "../../../../../../components/tms-customs/tms-address";
import TmsImageUpload from "../../../../../../components/tms-customs/tms-image-upload";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsPhoneNumberBasic from "../../../../../../components/tms-customs/tms-phone-number-basic";
import {
  LicenseInstitutionSelect,
  LicenseSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";
import useLicensesStore from "../../../../../../zustand/licenses.store";
const TmsLicenseLeaseCreation = ({ router, setVisible }) => {
  const {
    setEnhancementPercentage,
    enhancementPercentage,
    submitLoading,
    selectedInstitution,
    setStatus,
    gstAmount,
    status,
    setLicensePeriodEndDate,
    setLicensePeriodStartDate,
    licensePeriodStartDate,
    licensePeriodEndDate,
    address,
    setAddress,
    fetchInstitutionList,
    idProofNumber,
    setIdProofNumber,
    setLicenseeType,
    clearStates,
    setIdProofType,
    idProofType,
    setUploadIdProof,
    uploadIdProof,
    handleLeaseCreation,
    paymentTerm,
    setPaymentTerm,
    paymentType,
    setPaymentType,
    setEnhancementEndPeriod,
    setEnhancementStartPeriod,
    enhancementStartPeriod,
    enhancementEndPeriod,
    depositAmount,
    setDepositAmount,
    gender,
    setGender,
    mrNo,
    setMrNo,
    mrDate,
    setMrDate,
    setLicenseeName,
    licenseeName,
    setMobileNumber,
    gstNo,
    setGstNo,
    mobileNumber,
    setIncorporationOrPanNo,
    incorporationOrPanNo,
    contact_person_name,
    setContact_person_name,
    zip,
    setZip,
    setSubmitLoading,
    state,
    setState,
    country,
    setCountry,
    district,
    setDistrict,
    village,
    setVillage,
    mandal,
    setMandal,
    reason,
    setReason,
    setLeaseApprovedByCED,
    leaseApprovedByCED,
    uploadCEDOrder,
    setUploadCEDOrder,
    uploadApprovedDocumentOrderNo,
    setUploadApprovedDocumentOrderNo,
    uploadApprovedDocumentOrderDate,
    setUploadApprovedDocumentOrderDate,
    eoProceedingUploadDocument,
    setEoProceedingUploadDocument,

    dueDate,
    setDueDate,
    setGstAmount,
    fetchCreatedLicenses,
    setLicenseFee,
    licenseFee,
    natureOfBusiness,
    setNatureOfBusiness,
    setLicenseEnhancement,
    licenseEnhancement,
    licenseeType,
  } = useLicensesStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(licenseLeaseCreationStates, licenseLeaseAddtionalStates);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedLicenses(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  return (
    <div>
      <Row gutter={[20, 20]} style={{ marginBottom: "15px" }}>
        <LicenseInstitutionSelect span={8} />
        <LicenseSelect span={8} />
        <Row
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333333",
            padding: "0px 10px",
            width: "100%",
          }}
        >
          License Details
        </Row>

        {/* <Col span={12}>
          <Row className={"t-left"}>Lease Period Start Date</Row>
          <DatePicker style={{ width: "100%" }} format={"DD-MM-YYYY"} />
        </Col> */}

        <Col span={12}>
          <Row className={"t-left"}>License Fee</Row>
          <Input
            min="0"
            type="number"
            placeholder=""
            value={licenseFee}
            onChange={(e) => {
              setLicenseFee(e.target.value);
            }}
            maxLength={220}
          />
        </Col>

        <Col span={12}>
          <TmsDatePickerRange
            label="Select Lease Period"
            to={licensePeriodEndDate}
            from={licensePeriodStartDate}
            setFrom={setLicensePeriodStartDate}
            setTo={setLicensePeriodEndDate}
            disabledDate={false}
          />{" "}
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Due Date (Ex. 15)</Row>
          <Input
            min="1"
            type="number"
            max={31}
            placeholder="Due Date"
            value={dueDate}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onChange={(e) => {
              if (e.target.value > 31) {
                return;
              }
              setDueDate(e.target.value);
            }}
            maxLength={220}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Nature of Business</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Nature of Business"
            value={natureOfBusiness}
            onChange={(e) => {
              setNatureOfBusiness(e.target.value);
            }}
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={paymentTerm}
          setValue={setPaymentTerm}
          label="Payment Term"
          span={12}
          optional={true}
          data={paymentTerms}
        />
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={paymentType}
          setValue={setPaymentType}
          label="Payment Type"
          span={12}
          optional={true}
          data={[...paymentTypes]}
        />

        <>
          <Col span={12}>
            <Row className={"t-left"}>Deposit Amount</Row>
            <Input
              maxLength={220}
              type="number"
              min="0"
              placeholder=""
              value={depositAmount}
              onChange={(e) => {
                setDepositAmount(e.target.value);
              }}
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
            />
          </Col>
          <Col span={12}>
            <Row className={"t-left"}>MR No</Row>
            <Input
              maxLength={220}
              type="text"
              placeholder=""
              value={mrNo}
              onChange={(e) => {
                setMrNo(e.target.value);
              }}
            />
          </Col>{" "}
          <Col span={12}>
            <Row className={"t-left"}>MR Date</Row>
            <DatePicker
              disabled={false}
              value={mrDate}
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              onChange={(date) => {
                setMrDate(date);
              }}
              size="medium"
            />{" "}
          </Col>
          <Col span={12}>
            <Row className={"t-left"}>GST Amount(%)</Row>

            <Input
              maxLength={220}
              type="number"
              value={gstAmount}
              min={0}
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              max={101}
              style={{ width: "100%" }}
              placeholder="Enter Gst Percentage"
              onChange={(e) => {
                if (Number(e.target.value) < 101) {
                  setGstAmount(Number(e.target.value));
                }
              }}
              suffix={`Rs.${gstAmount ? (licenseFee / 100) * gstAmount : 0}`}
            ></Input>
          </Col>
          <TmsSelect
            selectClass="w-100 "
            classname="ml-0 "
            type="col"
            value={licenseEnhancement}
            setValue={setLicenseEnhancement}
            label="License Enchancement"
            data={yesOrNotData}
            span={12}
            optional={true}
          />
          {licenseEnhancement && (
            <>
              {" "}
              <Col span={12}>
                <TmsDatePickerRange
                  label="Select Enhancement Period"
                  to={enhancementEndPeriod}
                  from={enhancementStartPeriod}
                  setFrom={setEnhancementStartPeriod}
                  setTo={setEnhancementEndPeriod}
                  disabledDate={false}
                />{" "}
              </Col>
              <Col span={12}>
                <Row className={"t-left"}>
                  Percentage of Enhancement Value(%)
                </Row>

                <Input
                  maxLength={220}
                  type="number"
                  value={enhancementPercentage}
                  min={0}
                  onWheel={(event) => event.currentTarget.blur()}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  max={101}
                  style={{ width: "100%" }}
                  placeholder="Enter Gst Percentage"
                  onChange={(e) => {
                    if (Number(e.target.value) < 101) {
                      setEnhancementPercentage(Number(e.target.value));
                    }
                  }}
                  suffix={`Rs.${
                    enhancementPercentage
                      ? (licenseFee / 100) * enhancementPercentage
                      : 0
                  }`}
                ></Input>
              </Col>
            </>
          )}
        </>

        <Row
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333333",
            padding: "0px 20px 0px 10px",
            width: "100%",
          }}
        >
          License Holder Details
        </Row>
        <Row style={{ width: "100%", padding: "0px 20px" }}>
          <Radio.Group
            options={LeeseType}
            value={licenseeType}
            checked={licenseeType}
            id={licenseeType}
            onChange={(e) => {
              setLicenseeType(e.target.value);
            }}
          />
        </Row>
        <Col span={12}>
          <Row className={"t-left"}>Lessee Name</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Lessee Name"
            value={licenseeName}
            onChange={(e) => {
              setLicenseeName(e.target.value);
            }}
          />
        </Col>
        {licenseeType === "individual" ? (
          <TmsSelect
            selectClass="w-100 "
            classname="ml-0 "
            type="col"
            value={gender}
            setValue={setGender}
            label="Gender"
            span={12}
            optional={true}
            data={[
              { name: "male", id: "male" },
              { name: "female", id: "female" },
            ]}
          />
        ) : (
          <>
            <Col span={12}>
              <Row className={"t-left"}>Incorporation Number/PAN No</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Incorporation/PAN No"
                value={incorporationOrPanNo}
                onChange={(e) => {
                  setIncorporationOrPanNo(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>GST NO</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Gst No"
                value={gstNo}
                onChange={(e) => {
                  setGstNo(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Contact Person Name</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Name"
                value={contact_person_name}
                onChange={(e) => {
                  setContact_person_name(e.target.value);
                }}
              />
            </Col>
          </>
        )}
        <Col span={12}>
          <Row className={"t-left"}>Mobile Number</Row>
          <TmsPhoneNumberBasic
            value={mobileNumber}
            setValue={setMobileNumber}
            placeHolder={"Enter mobile number"}
            disable={false}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Address</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Col>
        <TmsAddress
          span={12}
          village={village}
          setVillage={setVillage}
          mandal={mandal}
          setMandal={setMandal}
          district={district}
          setDistrict={setDistrict}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          type="general"
        />
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={idProofType}
          setValue={(e) => {
            setIdProofType(e);
            setIdProofNumber("");
          }}
          label="Select Id Proof"
          data={IdProofData}
          span={12}
          optional={true}
        />
        <Col span={6}>
          <Row className={"t-left"} style={{ marginBottom: "5px" }}>
            ID Proof Number
          </Row>
          <Input
            maxLength={220}
            size="medium"
            type="text"
            placeholder="Enter ID Proof No"
            style={{ width: "100%" }}
            value={idProofNumber}
            onChange={(e) => {
              if (e.target.value?.length > 12) {
                return;
              }
              setIdProofNumber(`${e.target.value?.toUpperCase()}`);
            }}
          />
        </Col>
        <Col span={6}>
          <Row className={"t-left"} style={{ marginBottom: "5px" }}>
            Upload ID Proof
          </Row>
          <TmsImageUpload
            fileList={uploadIdProof}
            setFileList={setUploadIdProof}
            router={router}
            type="private"
            accept={true}
            className="w-100"
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={leaseApprovedByCED}
          setValue={setLeaseApprovedByCED}
          label="Lease Approved by CED"
          data={yesOrNotData}
          span={12}
          optional={true}
        />
        {leaseApprovedByCED ? (
          <>
            {" "}
            <Col span={12}>
              <Row className={"t-left"} style={{ marginBottom: "5px" }}>
                Upload Approved Documents
              </Row>
              <TmsImageUpload
                fileList={uploadCEDOrder}
                setFileList={setUploadCEDOrder}
                router={router}
                type="private"
                accept={true}
                className="w-100"
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>If Yes, Enter Orders No </Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Order no"
                value={uploadApprovedDocumentOrderNo}
                onChange={(e) => {
                  setUploadApprovedDocumentOrderNo(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Approved Document Order Date</Row>
              <DatePicker
                disabled={false}
                value={uploadApprovedDocumentOrderDate}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                onChange={(date) => {
                  setUploadApprovedDocumentOrderDate(date);
                }}
                size="medium"
              />{" "}
            </Col>
          </>
        ) : (
          <Col span={12}>
            <Row className={"t-left"}>If No, Enter Reason</Row>
            <Input
              maxLength={220}
              type="text"
              placeholder="Enter Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Col>
        )}
        <Col span={12}>
          <Row className={"t-left"} style={{ marginBottom: "5px" }}>
            Eo proceedings Upload Documents
          </Row>
          <TmsImageUpload
            fileList={eoProceedingUploadDocument}
            setFileList={setEoProceedingUploadDocument}
            router={router}
            type="private"
            accept={true}
            className="w-100"
          />
        </Col>
      </Row>
      <TmsCheckbox label={"Lease Status"} value={status} setValue={setStatus} />
      <TmsCancelSubmitButtons
        loading={submitLoading}
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(licenseLeaseCreationStates, leaseAddtionalStates);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleLeaseCreation(router);
        }}
      />
    </div>
  );
};

export default withRouter(TmsLicenseLeaseCreation);
