import { Button, Row } from "antd";
import React, { memo } from "react";
import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";
const CustomButton = ({ icon, size, type, loading, onClickFunction }) => {
  return (
    <Row
      className="d-flex-c"
      justify="start"
      style={{ margin: "20px 0px 5px 0px" }}
    >
      <Button
        type={type}
        className="tms-login-button"
        onClick={onClickFunction}
        loading={loading}
        style={{ width: "100%" }}
        size={size}
      >
        Continue {!loading && icon}
      </Button>
    </Row>
  );
};
export default memo(CustomButton);
