import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportKalyanakattaReport from "./tms-report-kalyanakatta-report";
import TmsReportKalyanakattaSummaryReport from "./tms-report-kalyanakatta-summary-report";
import TmsKalyanakattaPerformerReport from "./tms-kalyanakatta-performer-report";

const TmsReportKalyanakatta = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.reports_kalyanakatta) {
      setActive("Kalyanakatta Report");
      return;
    } else if (
      location.includes(tmsRouteMap.reports_kalyanakatta_performer_report)
    ) {
      setActive("Performer Report");
    } else if (
      location.includes(tmsRouteMap.reports_kalyanakatta_summary_report)
    ) {
      setActive("Summary Report");
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Kalyanakatta Report",
      Element: <TmsReportKalyanakattaReport />,
      key: "Kalyanakatta Report",
      route: tmsRouteMap.reports_kalyanakatta,
    },
    {
      name: "Performer Report",
      Element: <TmsKalyanakattaPerformerReport />,
      key: "Performer Report",
      route: tmsRouteMap.reports_kalyanakatta_performer_report,
    },
    {
      name: "Summary Report",
      Element: <TmsReportKalyanakattaSummaryReport />,
      key: "Summary Report",
      route: tmsRouteMap.reports_kalyanakatta_summary_report,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportKalyanakatta);
