import { Button, Popconfirm, Row, Space, Select, Col } from "antd";
import React, { useState, useEffect } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import TmsModal from "../../../../../components/tms-customs/tms-modal";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
// import { SearchOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsSevasInnerPagesDisableDate from "../../../../../components/tms-service-management/tms-sm-sevas/tms-sevas-inner-pages-disableDate";
import TmsSevasService from "../../../../../services/tms-sevas.service";

const TmsSevasInnerPagesDisableSevas = ({ router }) => {
  // const [searchText, setSearchText] = useState("");
  // const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState();
  const [disableDateData, SetDisableDateData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [sevaData, setSevaData] = useState([]);
  const [productId, setProductId] = useState();

  useEffect(() => {
    if (productId) fetchDisableDates();
  }, [productId, modalVisible]);

  useEffect(() => {
    getSeva();
  }, []);

  const getSeva = async () => {
    setLoading(true);
    try {
      const data = await TmsSevasService.getSevas(1, 100);
      // console.log(userDetails?.user?.templeId);
      setSevaData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const fetchDisableDates = async () => {
    setLoading(true);
    try {
      const data = await TmsSevasService.fetchdisabledates(
        Number(productId),
        1,
        200
      );
      SetDisableDateData(data?.items);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      render: (text) => {
        return (
          text && (
            <div style={{ width: "300px", whiteSpace: "initial" }}>{text}</div>
          )
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      render: (record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await TmsSevasService.deleteDisableDate(record?.id);
                } catch (error) {
                  catchErrorFunction(
                    error,
                    "Token Expired, redirecting to login page in 5 seconds",
                    router
                  );
                  setLoading(false);
                }
                fetchDisableDates();
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col style={{ width: "20%", textAlign: "left" }}>
          <Select
            style={{ width: "100% " }}
            value={productId}
            onChange={(e) => {
              setProductId(e);
            }}
            placeholder={"Select Seva"}
          >
            {sevaData?.map((item) => {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col>
          <Button type="primary" onClick={() => setModalVisible(true)}>
            Disable Date
          </Button>
        </Col>
      </Row>

      <br />
      <TmsTable
        columns={columns}
        dataSource={disableDateData}
        loading={loading}
      />
      <TmsModal
        visible={modalVisible}
        centered
        width="650px"
        onCancel={() => setModalVisible(false)}
        closable={true}
      >
        <TmsSevasInnerPagesDisableDate sevaData={sevaData} loading={loading} />
      </TmsModal>
    </>
  );
};

export default withRouter(TmsSevasInnerPagesDisableSevas);
