import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsBusService = {
  createBus: async (body) => {
    const resp = await Axios.post(`v1/bus/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  fetchAllBus: async (page, limit) => {
    const resp = await Axios.get(`v1/bus/get-all?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  fetchABusbyId: async (id) => {
    const resp = await Axios.get(`v1/bus/by-id/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateBusbyId: async (id, body) => {
    const resp = await Axios.put(`v1/bus/update/${id}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getBusCounter: async (id) => {
    const resp = await Axios.get(`v1/bus-ticket-counter/by-product/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  createBusCounters: async (body) => {
    const resp = await Axios.post(`v1/bus-ticket-counter/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editBusCounters: async (body, id) => {
    const resp = await Axios.put(`v1/bus-ticket-counter/update/${id}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  deleteBusCounters: async (id) => {
    const resp = await Axios.delete(`v1/bus-ticket-counter/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  fetchBusByCounters: async () => {
    const resp = await Axios.get(
      `v1/bus-counter-booking/valid-counter-products`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  busBooking: async (body) => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        busTicketBooking: body?.busItems,
        multiTicketsLinked: true,
        paymentType: "CASH",
        cashRemarks: "string",
        cashReference: "string",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  busReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    counter,
    shift,
    route,
  }) => {
    let query = `v1/bus-counter-report/booking?format=${format}&page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&bookingToDate=${toDate}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }

    if (route && route !== "") {
      query += `&productId=${route}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/bus-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&busId=${productId}`;
    }
    if (counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
};

export default TmsBusService;
