import { Col, DatePicker, Row, Table } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import { createFdrStates } from "../../../../../../constants/states-data.data";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import {
  AccountCategorySelect,
  BankAccountSelect,
  FdrInstitutionSelect,
  InvestementSelect,
} from "../../../../../../components/tms-customs/rents-and-leases/index";
import "../../../../../../assets/css/tms-common/tms-rents.scss";
import useFdrStore from "../../../../../../zustand/fdr.store";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";

const TmsSmFdrList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const labelStyles = { float: "left", marginBottom: "5px" };

  // const [update, setUpdate] = useState("");
  const [maturityDate, setMaturityDate] = useState("");
  const {
    selectedInstitution,
    loading,
    clearStates,
    fetchInstitutionList,
    fetchCreatedAccountCategory,
    createdFdrData,
    fetchBankAccounts,
    fetchInvestmentTypes,
    fetchFdrReports,
    selectedAccountCategory,
    selectedBankAccount,
    selectedInvestmentType,
    excelLoading,
    handleExcelExport,
  } = useFdrStore();

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };
  useEffect(() => {
    return () => {
      clearStates(createFdrStates, [
        "accountCategoryData",
        "bankAccountData",
        "investmentTypeData",
        "createdFdrData",
      ]);
    };
  }, []);
  useEffect(() => {
    router.location.pathname === tmsRouteMap.sm_ral_fdr_fdr_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution) {
      fetchCreatedAccountCategory(1, 200, "ASC", "createdAt", router);
      fetchInvestmentTypes(1, 200, "ASC", "createdAt", router);
      fetchBankAccounts(1, 200, "ASC", "createdAt", router);
    }
  }, [selectedInstitution]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution) {
      fetchFdrReports(maturityDate, tableCurrentPage, tablePageSize, router);
    }
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedInstitution,
    maturityDate,
    selectedAccountCategory,
    selectedBankAccount,
    selectedInvestmentType,
  ]);

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <FdrInstitutionSelect span={5} />
        <AccountCategorySelect span={5} />
        <BankAccountSelect span={5} />
        <InvestementSelect span={5} />
        <Col span={4}>
          <label style={labelStyles}>
            Maturity Date{" "}
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <DatePicker
            value={maturityDate}
            style={{ width: "100%" }}
            format="DD-MM-YYYY"
            onChange={(date) => {
              setMaturityDate(date);
            }}
            size="medium"
          />
        </Col>
        {selectedInstitution !== "" && selectedInstitution && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() =>
                handleExcelExport("encroachment", router)
              }
              top={false}
            />
          </Col>
        )}
      </Row>
      <br />
      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          {
            title: "Account Category",
            dataIndex: "accountCategoryName",
          },

          { title: "Investment Type", dataIndex: "investmentType" },

          {
            title: "Name of Bank",
            dataIndex: "bankName",
          },

          {
            title: "Branch",
            dataIndex: "branchName",
          },

          {
            title: "FDR A/C no",
            dataIndex: "fdrAccountNumber",
          },

          {
            title: "Principal Amount",
            dataIndex: "principleAmountInvested",
          },
          {
            title: "Rate of Interest(%)",
            dataIndex: "rateOfInterest",
          },
          {
            title: "FDR Duration",
            dataIndex: "numberOfDays",
          },

          {
            title: "Maturity Date",
            dataIndex: "maturityDate",
            render: (record) => moment(record).format("DD-MM-YYYY"),
          },
          {
            title: "Remittance Date",
            dataIndex: "interestRemittanceDate",
            render: (record) => moment(record).format("DD-MM-YYYY"),
          },
          {
            title: "Maturity Amount",
            dataIndex: "maturityAmount",
          },
          {
            title: "Interest Amount",
            dataIndex: "interestAmount",
          },

          // {
          //   title: "Actions",
          //   key: "status",
          //   render: (record) => {
          //     return (
          //       <Space>
          //         <Space size="middle">
          //           <Popconfirm
          //             title="Are you sure delete this lease?"
          //             onConfirm={async () => {
          //               await deleteCreatedFdr(record.id, router);
          //               setUpdate(Math.random() * 100000 + 21);
          //             }}
          //             okText="Yes"
          //             cancelText="No"
          //             loading={deleteLoading}
          //           >
          //             <DeleteOutlined />
          //           </Popconfirm>
          //         </Space>{" "}
          //       </Space>
          //     );
          //   },
          // },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={createdFdrData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdFdrData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsSmFdrList);
