import React, { useState } from "react";
import { tmsLogo } from "../../../assets/images/tms-image-list";
import QRCode from "qrcode.react";
import moment from "moment/moment";
import { FormatNumberINRCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
import { Divider } from "antd";
const LeaseInvoice = ({
  refContainer,
  data,
  type,
  display,
  scantype,
  invoiceId,
}) => {
  console.log(type);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  const dataKeysForPayment = {
    "shop-lease": {
      "Invoice Date": moment(data?.shopLease?.lastInvoiceGeneratedOn).format(
        "DD-MM-YYYY"
      ),
      "Invoice No": data?.invoiceNumber,
      "Category ": "Lease of Building",
      Location: `${data?.shopLease?.address} | ${data?.shopLease?.village} | ${data?.shopLease?.mandal}`,
      "Building Name":
        data?.shopLease?.shop?.blockOrFloor?.building?.buildingName,
      "Block Name": data?.shopLease?.shop?.blockOrFloor?.blockOrFloorName,
      "Shop No": data?.shopLease?.shop?.shopNumber ?? "-",
      Extent: `${data?.shopLease?.shopExtentBreadth}(L) * ${data?.shopLease?.shopExtentBreadth}(B) (sqft)`,
      "Lease Term": data?.shopLease?.paymentTerm,
      // "Lease Amount":data?.paymentType !=="KIND"? data?.leaseRate : `${}-${data?.kindItemUnitsInKg}`,
      "Lease Amount": FormatNumberINRCurrency(data?.shopLease?.leaseRate ?? 0),
      "Arrear Amount": FormatNumberINRCurrency(
        data?.shopLease?.arrear?.arrearAmount ?? 0
      )
        ? FormatNumberINRCurrency(data?.shopLease?.arrear?.arrearAmount)
        : 0,
      GST: `${data?.shopLease?.gstPercentage ?? 0} %`,
      "Total Amount":
        data?.lastInvoiceGeneratedOn === null
          ? "No invoice generated till date"
          : FormatNumberINRCurrency(
              (Number(data?.shopLease?.arrear?.arrearAmount) ?? 0) +
                (Number(data?.lastMonthLeaseAmount) ?? 0)
            ),
      // "Penality Amount": data?.,
      "Due Date": data?.shopLease?.dueDate,
      "GST No":
        data?.shopLease?.shop?.blockOrFloor?.building?.institution?.gstNumber ??
        "-",
      "HSN Code":
        data?.shopLease?.shop?.blockOrFloor?.building?.institution?.hsnNumber ??
        "-",
      "lesse Name": data?.shopLease?.lesseName,
      "Mobile Number": data?.shopLease?.mobileNumber,
      "Customer GST No":
        data?.shopLease?.lesseType === "individual"
          ? "-"
          : data?.shopLease?.gstNo,
    },
    "land-lease": {
      "Invoice No": data?.invoiceNumber,
      "Category ": "Lease of Lands",
      "Invoice Date": moment(data?.landLease?.lastInvoiceGeneratedOn).format(
        "DD-MM-YYYY"
      ),
      "lesse Name": data?.landLease?.lesseName,
      "Mobile Number": data?.landLease?.mobileNumber,
      Location: `${data?.landLease?.address} | ${data?.landLease?.village} | ${data?.landLease?.mandal}`,
      "Survey No": data?.landLease?.surveyNumberBreakup?.surveyNumber?.surveyNo,
      "Survey Breakup ": data?.landLease?.surveyNumberBreakup?.blockNumber,
      Extent: `${data?.landLease?.leasedExtentAcres}(Acres), ${data?.landLease?.leasedExtentCents}(Cents)`,
      "Lease Term": `${data?.landLease?.paymentTerm} - ${data?.landLease?.paymentType}`,
      // "Lease Amount":data?.paymentType !=="KIND"? data?.leaseRate : `${}-${data?.kindItemUnitsInKg}`,
      "Lease Amount":
        data?.landLease?.paymentType !== "KIND"
          ? FormatNumberINRCurrency(data?.landLease?.leaseRate)
          : `${data?.landLease?.kindItem} - ${data?.kindItemUnitsInKg}`,
      "Arrear Amount": FormatNumberINRCurrency(
        data?.landLease?.arrear?.arrearAmount ?? 0
      ),
      "Total Amount":
        data?.lastInvoiceGeneratedOn === null
          ? "No invoice generated till date"
          : FormatNumberINRCurrency(
              (Number(data?.landLease?.arrear?.arrearAmount) ?? 0) +
                (Number(data?.lastMonthLeaseAmount) ?? 0)
            ),
      GST: `${data?.landLease?.gstPercentage ?? 0} %`,
      Payment: data?.isPaymentCompleted ? "Completed" : "Pending",
      "Due Date": data?.landLease?.dueDate,
      "Temple GST No":
        data?.landLease?.surveyNumberBreakup?.surveyNumber?.land?.institution
          ?.gstNumber ?? "-",
      "Temple HSN Code":
        data?.landLease?.surveyNumberBreakup?.surveyNumber?.land?.institution
          ?.hsnNumber ?? "-",
      "Customer GST No":
        data?.landLease?.lesseType === "individual"
          ? "-"
          : data?.landLease?.gstNo,
    },
    "license-lease": {
      "Invoice Date": moment(data?.licenseLease?.lastInvoiceGeneratedOn).format(
        "DD-MM-YYYY"
      ),
      "Invoice No": data?.invoiceNumber,
      "Category ": "Lease of License",
      "License/Item Name": data?.licenseLease?.license?.licenseName,
      "License Term": data?.licenseLease?.paymentTerm,
      "License Amount": data?.licenseLease?.licenseFee ?? 0,
      "Arrear Amount": FormatNumberINRCurrency(
        data?.licenseLease?.licenseArrear?.arrearAmount ?? 0
      ),
      "GST Amount": `${data?.licenseLease?.gstPercentage ?? 0} %`,
      "Total Amount":
        data?.lastInvoiceGeneratedOn === null
          ? "No invoice generated till date"
          : FormatNumberINRCurrency(
              (Number(data?.licenseLease?.licenseArrear?.arrearAmount) ?? 0) +
                (Number(data?.lastMonthLeaseAmount) ?? 0)
            ),
      // "Penality Amount": data?.,
      "Due Date": data?.licenseLease?.dueDate,
      "GST No": data?.licenseLease?.license?.institution?.gstNumber ?? "-",
      "HSN Code": data?.licenseLease?.license?.institution?.hsnNumber ?? "-",
      "lesse Name": data?.licenseLease?.licenseeName,
      "Mobile Number": data?.licenseLease?.mobileNumber,
      "Customer GST No":
        data?.licenseLease?.licenseeType === "individual"
          ? "-"
          : data?.licenseLease?.gstNo,
    },
  };
  const dataKeysForAckowledgment = {
    "land-lease": {
      "Acknowledgement No": invoiceId,
      "Invoice No": data?.landLeaseInvoice?.invoiceNumber,
      "Category ": "Lease of Lands",
      "Invoice Date": moment(data?.landLeaseInvoice?.createdAt).format(
        "DD-MM-YYYY"
      ),
      Location: `${data?.landLease?.village}`,
      "Survey No": data?.landLease?.surveyNumber?.surveyNo,
      "Survey Breakup ": data?.landLease?.surveyNumberBreakup?.blockNumber,
      Extent: `${data?.landLease?.leasedExtentAcres}(Acres), ${data?.landLease?.leasedExtentCents}(Cents)`,
      "Mode of Payment": `${data?.landLease?.paymentType}`,
      // "Lease Amount":data?.paymentType !=="KIND"? data?.leaseRate : `${}-${data?.kindItemUnitsInKg}`,
      "lease Amount": FormatNumberINRCurrency(
        data?.landLeaseInvoice?.lastMonthLeaseAmount ?? 0
      ),
      "Arreat Amount": data?.landLease?.arrear?.arrearAmount
        ? FormatNumberINRCurrency(data?.landLease?.arrear?.arrearAmount)
        : 0,
      "Total Amount": FormatNumberINRCurrency(
        Number(data?.landLeaseInvoice?.lastMonthLeaseAmount ?? 0) +
          Number(data?.landLease?.arrear?.arrearAmount ?? 0)
      ),
      "Amount Paid": FormatNumberINRCurrency(
        data?.counterCartItem?.counterCart?.counterCartPayment.amount +
          data?.counterCartItem?.counterCart?.counterCartPayment.gst
      ),
      "Payment ID": data?.counterCartItem?.counterCart?.counterCartPayment.id,
      "Payment Date": moment(
        data?.counterCartItem?.counterCart?.counterCartPayment.createdAt
      ).format("DD-MM-YYYY"),
      "lesse Name": data?.landLease?.lesseName,
      "Mobile Number": data?.landLease?.mobileNumber,
    },
    "shop-lease": {
      "Acknowledgement No": invoiceId,
      "Invoice No": data?.shopLeaseInvoice?.invoiceNumber,
      "Category ": "Lease of Shops",
      "Invoice Date": moment(
        data?.shopLeaseInvoice?.lastInvoiceGeneratedOn
      ).format("DD-MM-YYYY"),
      Location: `${data?.shopLease?.address} | ${data?.shopLease?.village} | ${data?.shopLease?.mandal}`,
      "Building Name":
        data?.shopLease?.shop?.blockOrFloor?.building?.buildingName,
      "Block/Floor No": data?.shopLease?.shop?.blockOrFloor?.blockOrFloorName,
      "Shop Number": data?.shopLease?.shop?.shopNumber,
      Extent: `${data?.shopLease?.shopExtentLength}(L) * ${data?.shopLease?.shopExtentBreadth}(B) sqft`,
      "Mode of Payment": `${data?.shopLease?.paymentType}`,
      // "Lease Amount":data?.paymentType !=="KIND"? data?.leaseRate : `${}-${data?.kindItemUnitsInKg}`,
      "lease Amount": FormatNumberINRCurrency(
        data?.shopLeaseInvoice?.lastMonthLeaseAmount ?? 0
      ),
      "Arreat Amount": data?.shopLease?.arrear?.arrearAmount
        ? FormatNumberINRCurrency(data?.shopLease?.arrear?.arrearAmount)
        : 0,
      "Total Amount": FormatNumberINRCurrency(
        Number(data?.shopLeaseInvoice?.lastMonthLeaseAmount ?? 0) +
          Number(data?.shopLease?.arrear?.arrearAmount ?? 0)
      ),
      "Amount Paid": FormatNumberINRCurrency(
        data?.counterCartItem?.counterCart?.counterCartPayment.amount +
          data?.counterCartItem?.counterCart?.counterCartPayment.gst
      ),
      "Payment ID": data?.counterCartItem?.counterCart?.counterCartPayment.id,
      "Payment Date": moment(
        data?.counterCartItem?.counterCart?.counterCartPayment.createdAt
      ).format("DD-MM-YYYY"),
      "lesse Name": data?.shopLease?.lesseName,
      "Mobile Number": data?.shopLease?.mobileNumber,
    },
    "license-lease": {
      "Acknowledgement No": invoiceId,
      "Invoice No": data?.licenseLeaseInvoice?.invoiceNumber,
      "Category ": "Lease of License",
      "Invoice Date": moment(
        data?.licenseLeaseInvoice?.lastInvoiceGeneratedOn
      ).format("DD-MM-YYYY"),
      Location: `${data?.licenseLease?.village} `,
      "license Name": data?.licenseLease?.license?.licenseName,
      "Mode of Payment": `${data?.licenseLease?.paymentType}`,
      // "Lease Amount":data?.paymentType !=="KIND"? data?.leaseRate : `${}-${data?.kindItemUnitsInKg}`,
      "lease Amount": FormatNumberINRCurrency(
        data?.licenseLeaseInvoice?.lastMonthLeaseAmount ?? 0
      ),
      "Arreat Amount": data?.licenseLease?.licenseArrear?.arrearAmount
        ? FormatNumberINRCurrency(
            data?.licenseLease?.licenseArrear?.arrearAmount ?? 0
          )
        : 0,
      "Total Amount": FormatNumberINRCurrency(
        Number(data?.licenseLeaseInvoice?.lastMonthLeaseAmount) +
          Number(data?.licenseLease?.licenseArrear?.arrearAmount)
      ),
      "Amount Paid": FormatNumberINRCurrency(
        data?.counterCartItem?.counterCart?.counterCartPayment.amount +
          data?.counterCartItem?.counterCart?.counterCartPayment.gst
      ),
      "Payment ID": data?.counterCartItem?.counterCart?.id,
      "Payment Date": moment(
        data?.counterCartItem?.counterCart?.counterCartPayment.createdAt
      ).format("DD-MM-YYYY"),
      "lesse Name": data?.licenseLease?.licenseeName,
      "Mobile Number": data?.licenseLease?.mobileNumber,
    },
  };

  return (
    <div
      className={
        display ? "tms-lease-container bg-invoice" : "tms-lease-container"
      }
      ref={refContainer}
    >
      <div className="top">
        {!display && (
          <>
            {" "}
            <h2 className="title">INVOICE</h2>
            <div className="header">
              <div className="logo">
                <img
                  src={tmsLogo}
                  // src={templeDetails.logo !== "" ? templeDetails?.logo : tmsLogo}
                />
              </div>{" "}
              <QRCode
                className="qrcode"
                size="50"
                fontSize="14px"
                value={data?.invoiceNumber}
              />
            </div>
          </>
        )}
        <h2 className={display ? "" : "dotted-line"}>{templeDetails?.name}</h2>
        {display && <Divider className="divider" />}

        <table className="data-table">
          <tbody>
            {Object?.entries(
              scantype !== "payment"
                ? dataKeysForAckowledgment[type]
                : dataKeysForPayment[type]
            )?.map((item, i) => {
              return (
                <tr key={`tableCOl${i}`}>
                  <td className="col-1">{item?.[0] ?? ""}</td>
                  {!display && <td className="col-2">:</td>}
                  <td className="col-3">{item?.[1] ?? ""}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!display && (
          <>
            {" "}
            <div className="dotted-line"></div>
            <p>
              Please make crossed Cheque/DD/Pay order for Amount Payable in
              favour of Executive officer, XXXX
            </p>
          </>
        )}
      </div>
      {!display && (
        <div className="footer">
          This is a System generated Bill and doesn't require any Signature
        </div>
      )}
    </div>
  );
};

export default LeaseInvoice;
