import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import TmsEntryScanService from "../../../../../services/tms-entry-scan.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Col, Drawer, Switch } from "antd";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmsESInnerPagesAND from "./tms-es-inner-pages-and";
import TmsESinnerActions from "../../../../../components/tms-service-management/tms-sm-entry-scan/tms-es-inner-items";

const TmsESInnerPagesAll = ({ router }) => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [entryScanData, setEntryScanData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewEntryScanData, setViewEntryScanData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  const tableColums = [
    ["Name", viewEntryScanData?.name],
    ["Description", viewEntryScanData?.description],
    [
      "Fingerprint",
      viewEntryScanData?.isFingerPrintRequired ? "enabled" : "disabled",
    ],
    [
      "POS-(Status)",
      viewEntryScanData?.counterEnabled ? "enabled" : "disabled",
    ],
  ];

  const switchTrigger = async (key) => {
    setLoading(true);
    try {
      const entryData = await TmsEntryScanService.viewEntryScan(key);
      setViewEntryScanData(entryData);
      settmsModalVisible(true);
      setEditFlag(true);
      setProductId(key);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    if (router.location?.pathname === "/service-management/entry-scan") {
      getEntryScan();
      if (!editFlag) {
        settmsModalVisible(false);
      }
    }
  }, [
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  const getEntryScan = async () => {
    setLoading(true);
    try {
      setEntryScanData([]);
      const data = await TmsEntryScanService.getEntryScan(
        tableCurrentPage,
        tablePageSize
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setEntryScanData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Entry Scan Name",
      dataIndex: "name",
      key: "name",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Finger Print Enabled",
      dataIndex: "isFingerPrintRequired",
      render: (record) => (record ? "Enabled" : "Disabled"),
      //   ...getColumnSearchProps("darshanam_name"),
    },

    {
      title: "Status",
      dataIndex: "counterEnabled",
      key: "counterEnabled",
      render: (text, record) => {
        return (
          <Switch
            size={"small"}
            checked={text}
            style={{ background: text ? "#4CBB17" : "EFEFEF" }}
            onClick={() => {
              switchTrigger(record?.key, !text, "counter");
            }}
          />
        );
      },
    },

    {
      title: "Action",
      render: (record) => {
        return (
          <TmsESinnerActions
            record={record}
            setLoading={setLoading}
            setViewEntryScanData={setViewEntryScanData}
            settmsModalVisible={settmsModalVisible}
            setUpdateFlag={setUpdateFlag}
            setEditFlag={setEditFlag}
            setProductId={setProductId}
          />
        );
      },
    },
  ];

  return (
    <>
      {" "}
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={entryScanData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <Drawer
        className="DetailsModal"
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewEntryScanData(null);
        }}
        title={editFlag ? "Edit Darshanam" : "Darshanam Details"}
      >
        {editFlag ? (
          <TmsESInnerPagesAND
            viewEntryScanData={viewEntryScanData}
            editFlag={editFlag}
            productId={productId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
          />
        ) : (
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
            <br />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default withRouter(TmsESInnerPagesAll);
