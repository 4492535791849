import { Button, Col, Input, Modal, notification, Row } from "antd";
import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import Highlighter from "react-highlight-words";
import TmsSevasService from "../../../../services/tms-sevas.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { SearchOutlined } from "@ant-design/icons";

import "../../../../assets/css/tms-reports/tms-reports.scss";
import SpecialSevaModal from "../../../../components/tms-reports/special-seva-modal";
import { useReactToPrint } from "react-to-print";
import TmsPrintTicket from "../../../../components/tms-print-ticket/tms-print-ticket";
import TmsPartnerService from "../../../../services/partners.service";
import ExcelJs from "exceljs";
import { BaseContext } from "../../../../contexts/BaseContext";
const TmsReportSevaReport = ({ router }) => {
  const { userDetails } = useContext(BaseContext);
  const [loading, setLoading] = useState();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [allPartnerList, setAllPartnerList] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [externalPrint, setExternalPrint] = useState(false);
  const [dateType, setDateType] = useState("payment");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [setSortType] = useState("DESC");
  const [bookingType, setBookingType] = useState("pos");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [sevaType, setSevaType] = useState("pratyakshaSeva");
  const [sevaList, setSevaList] = useState([]);
  const [sevaId, setSevaId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("success");
  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);
  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [labelLoading, setLabelLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [slot, setSLot] = useState("");
  const [slotList, setSlotList] = useState([]);
  const [downloadData, setDownloadData] = useState(null);
  const [downloadFlag, setDownloadFlag] = useState(null);
  const [tableData, setTableData] = useState("");
  const refContainer = useRef(null);

  const API = {
    online: TmsSevasService.sevaReportOnline,
    pos: TmsSevasService.sevaReport,
  };
  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sevaform",

    onAfterPrint: () => {
      setDownloadData(null);
      setDownloadFlag(false);
    },
  });

  const fetchBookingDetails = async () => {
    setLoading(true);
    let body = handleBody("fetch");
    try {
      const data = await API[bookingType](body);
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
    setLabelLoading(false);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          maxLength={220}
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  const handleExcelExport = async (type, returnState) => {
    type ? setLabelLoading(true) : setExcelLoading(true);
    try {
      const body = handleBody("excel");
      let data = await API[bookingType](body);
      if (data.items.length === 0) {
        notification.info({ message: "No Data" });
        type ? setLabelLoading(true) : setExcelLoading(true);
      }
      data = data?.items?.reverse();
      let compiledDataOffile = [];
      let compiledDataOnline = [];

      if (bookingType === "online") {
        compiledDataOnline = data.map((item, i) => {
          const commonProperties = {
            "S.no": i + 1,
            Seva: item.sevaName,
            Devotee:
              type === "label"
                ? item?.personDetails[0].personName ?? "-"
                : item.personDetails
                    .map(
                      (items, j) =>
                        `${j + 1}. ${items?.personName} - ${items?.documentId}`
                    )
                    ?.join(", "),
            Phone: item.bookingPhoneNumber,
            Address: `${item.address ?? "-"} ${item.address2 ?? ""} ${
              item?.city ?? ""
            } ${item?.state ?? ""} ${item?.country ?? ""} ${item?.pinCode}`,
          };
          if (type === "label") {
            commonProperties["P Date"] = item.bookingDate
              ? moment(item.bookingDate).format("DD-MM-YYYY")
              : "-";
          } else {
            commonProperties["Performance Date"] = item.bookingDate
              ? moment(item.bookingDate).format("DD-MM-YYYY")
              : "-";
          }
          return type === "label"
            ? commonProperties
            : {
                ...commonProperties,
                Partner: item.partnerCode,
                "Order ID": item?.partnerOrderId
                  ? item.partnerOrderId
                  : item?.onlinePaymentType === "razorpay"
                  ? item?.razorPayOrderId
                  : item?.ccavenueOrderId,
                "Booked on": moment(item.createdDate).format(
                  "DD-MM-YYYY hh:mm A"
                ),
                "No of persons": item.noOfTickets,
                "Total Amount": item?.price,
                Slot: `${moment(item.slotStartTime, "HH:mm:ss").format(
                  "hh:mm A"
                )} - ${moment(item.slotEndTime, "HH:mm:ss").format("hh:mm A")}`,
                Gothram: item.gothram,
                "Payment ID": item?.onlinePaymentId,
                "Payment gateway": item?.onlinePaymentType,
                "Payment Status": item?.onlinePaymentStatus,
              };
        });
      } else {
        compiledDataOffile = data.map((item, i) => {
          const commonProperties = {
            "S.no": i + 1,
            "Devotee Name": item?.devoteeName ?? "-",
            "Phone number": item?.contact ?? "-",
            Address: item?.address ?? "-",
            "Seva Name ": item.sevaName,
          };
          if (type === "label") {
            commonProperties["P Date"] = item.bookingDate
              ? moment(item.bookingDate).format("DD-MM-YYYY")
              : "-";
          } else {
            commonProperties["Performance Date"] = item.bookingDate
              ? moment(item.bookingDate).format("DD-MM-YYYY")
              : "-";
          }
          if (type !== "label") {
            return {
              ...commonProperties,
              "Booking Id": item.bookingId,
              "Transaction Id": item?.categoryTransactionSequence
                ?.split("-")
                ?.pop(),
              "Booked on": item.createdDate
                ? moment(item.createdDate).format("DD-MM-YYYY HH:mm:ss A")
                : "-",
              "POS Name": item.counterName,
              "Shift ID": item.counterShiftTimingId,
              Amount: item.price,
              "Ticket Count": item?.noOfPeople,
              Slot: `${moment(item.slotStartTime, "HH:mm:ss").format(
                "hh:mm A"
              )} - ${moment(item.slotEndTime, "HH:mm:ss").format("hh:mm A")}`,
              Gothram: item?.gothram ?? "-",
              "Date of Birth": item?.dob
                ? moment(item?.dob).format("DD-MM-YYYY")
                : "-",
            };
          }

          return commonProperties;
        });
      }
      if (type === "label" && !returnState) {
        setLabelLoading(false);
        return bookingType === "online"
          ? compiledDataOnline
          : compiledDataOffile;
      }
      if (type === "label") {
        exportLabelExcel(
          data,
          `label-report-${bookingType}-${moment().format("DD-MM-YYYY")}`,
          type ? setLabelLoading : setExcelLoading
        );
      } else
        exportExcel(
          bookingType === "online" ? compiledDataOnline : compiledDataOffile,
          `${
            type === "label" ? "label-report" : `seva-report-${bookingType}`
          }-${moment().format("DD-MM-YYYY")}`,
          type ? setLabelLoading : setExcelLoading
        );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      type ? setLabelLoading(false) : setExcelLoading(false);
    }
  };
  const fetchAllPartners = async () => {
    setLoading(true);
    try {
      const data = await TmsPartnerService.getPartnersByTemple();
      setAllPartnerList(data);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };
  const fetchSevaList = async () => {
    try {
      let data = [];
      setSevaList([]);
      setSevaId("");
      setSLot("");
      setSlotList([]);
      if (
        ["parokshaSeva", "pratyakshaSeva", "saswataSeva"]?.includes(sevaType)
      ) {
        data = await TmsSevasService.getSevasByType(sevaType, 1, 300);
      } else {
        data = await TmsSevasService.getSevas(1, 300);
      }

      setTabelTotalSize(data?.meta?.totalItems);
      setSevaList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchSevaSlotList = async () => {
    try {
      setSLot("");
      setSlotList([]);
      const res = await TmsSevasService.getSevaSlots(sevaId);
      // console.log(res);
      setSlotList(
        res?.map((item) => {
          return {
            key: item.productSlotId,
            ...item,
          };
        })
      );
      if (res?.length > 0) {
        setSLot("");
        res[0]?.productSlotId;
      }
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  useEffect(() => {
    if (bookingType === "pos") {
      fetchCounterList();
      fetchShiftList();
    }
    bookingType === "online" &&
      (userDetails?.permissions.includes("SUPER_ADMIN") ||
        userDetails?.permissions.includes("ADMIN")) &&
      fetchAllPartners();
  }, [bookingType]);

  useEffect(() => {
    if (sevaId !== "") {
      fetchSevaSlotList();
    }
  }, [sevaId]);

  useEffect(() => {
    if (sevaType !== "") {
      fetchSevaList();
    }
  }, [sevaType]);

  useEffect(() => {
    fetchBookingDetails();
  }, [tableCurrentPage, tablePageSize, bookingType]);

  const handleBody = (type) => {
    let body = {
      format: "json",
      tableCurrentPage: Number(tableCurrentPage),
      tablePageSize: type === "excel" ? 70000 : Number(tablePageSize),
      fromDate: bookingFromDate,
      toDate: bookingToDate,
      sevaType,
      sevaId: Number(sevaId),
      slotId: slot,
      dateType: dateType,
    };
    if (bookingType === "pos") {
      body.counterId = Number(counter);
      body.shiftId = shift;
      return body;
    } else {
      body.paymentStatus = paymentStatus;
      if (selectedPartner) {
        body.partnerCode = selectedPartner;
      }
      return body;
    }
  };

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "POS Name",
      dataIndex: "counterName",
      key: "counterName",
    },
    {
      title: "Shift Id",
      dataIndex: "counterShiftTimingId",
      key: "counterShiftTimingId",
    },
    {
      title: "Booking Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Txn Id",
      dataIndex: "categoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "S no",
      dataIndex: "dailyCategoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "Seva Type",
      dataIndex: "sevaName",
      key: "sevaName",
      ...getColumnSearchProps("sevaName"),
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `Rs. ${text}`;
      },
      sorter: (a, b) => a.price > b.price,
    },
    {
      title: "User Phone",
      dataIndex: "contact",
      key: "contact",
      render: (record) => {
        return record && record !== null ? record : "-";
      },
    },
    {
      title: "Slot",
      dataIndex: "slotStartTime",
      key: "slotStartTime",
      render: (text, record) => {
        return `${moment(record.slotStartTime, "HH:mm:ss").format("hh:mm A")} - 
            ${moment(record.slotEndTime, "HH:mm:ss").format("hh:mm A")}`;
      },
    },
    {
      title: "Performance Date",
      dataIndex: "bookingDate",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY");
      },
      sorter: (a, b) => a.bookingDate > b.bookingDate,
    },
    {
      title: "Booked Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm:ss");
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdDate > b.createdDate,
    },
  ];
  const onlineColumns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Seva Type",
      dataIndex: "sevaName",
      key: "sevaName",
    },
    {
      title: "Devotee Details",
      dataIndex: "personDetails",
      render: (record) => {
        return record
          ?.map((item) => {
            return `${item?.personName}${
              item?.documentId !== null ? `- ${item?.documentId}` : ""
            }`;
          })
          ?.join(",");
      },
      width: "120px",
    },
    {
      title: "No of Persons",
      dataIndex: "noOfTickets",
      key: "noOfTickets",
    },
    // {
    //   title: "Price",
    //   dataIndex: "productPrice",
    //   key: "productPrice",
    //   render: (text) => {
    //     return `Rs. ${text}`;
    //   },
    //   sorter: (a, b) => a.price > b.price,
    // },
    {
      title: "Total",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `Rs. ${text}`;
      },
      // sorter: (a, b) => a.price > b.price,
    },
    {
      title: "Order ID",
      key: "bookingId",
      render: (text, record) => {
        return record?.onlinePaymentType === "razorpay"
          ? record?.razorPayOrderId
          : record?.ccavenueOrderId;
      },
    },
    {
      title: "Slot",
      dataIndex: "slotStartTime",
      key: "slotStartTime",
      render: (text, record) => {
        return `${moment(record.slotStartTime, "HH:mm:ss").format("hh:mm A")} - 
            ${moment(record.slotEndTime, "HH:mm:ss").format("hh:mm A")}`;
      },
    },
    {
      title: "Performance Date",
      dataIndex: "bookingDate",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY");
      },
      sorter: (a, b) => a.bookingDate > b.bookingDate,
    },
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
    },
    {
      title: "Booking Phone",
      dataIndex: "bookingPhoneNumber",
      key: "bookingPhoneNumber",
    },
    {
      title: "Booked on",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY hh:mm A");
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdDate > b.createdDate,
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => {
        return record.sevaName === "Udayasthamana Seva" ||
          record.sevaName === "Pradoshakala Seva" ? (
          <span
            style={{ cursor: "pointer", fontWeight: "600" }}
            onClick={() => {
              setDownloadFlag(true);
              setDownloadData(record);
            }}
          >
            Download Form
          </span>
        ) : (
          ""
        );
      },
    },
  ];
  const exportLabelExcel = (data, fileName, setLoading) => {
    const workbook = new ExcelJs.Workbook();
    const sheet = workbook.addWorksheet("My sheet");
    sheet.properties.defaultColWidth = 50;
    sheet.columns = [
      { header: "Heading", key: "label", width: "20" },
      {
        header: "Value",
        key: "value",
        width: "40",
      },
    ];
    sheet.getColumn(2).alignment = { wrapText: true };

    data.forEach((item) => {
      sheet.addRow({ label: "Name", value: item?.userName ?? "-" });
      sheet.addRow({ label: "Phone", value: item?.userPhone ?? "-" });

      let address = "-";
      if (
        item.address !== null &&
        item.city !== null &&
        item.state !== null &&
        item.pinCode !== null &&
        item.country !== null
      ) {
        address = `${item.address ?? ""} ${item.address2 ?? ""}, ${
          item.city ?? ""
        }, ${item.state ?? ""}, ${item.pinCode ?? ""}, ${item.country ?? ""}`;
      }

      sheet.addRow({ label: "Address", value: address });

      sheet.addRow({ label: "Seva Name", value: item.sevaName });
      sheet.addRow({
        label: "P Date",
        value: item.bookingDate
          ? moment(item.bookingDate).format("DD-MM-YYYY")
          : "-",
      });

      sheet.addRow("");
      sheet.addRow("");
    });

    sheet.spliceColumns(3, 3);
    sheet.spliceRows(1, 1);
    sheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      if (rowNumber % 7 === 0) row.border = { top: { style: "thick" } };
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument,spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${fileName}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
      setLoading(false);
    });
  };

  const renderTable = async () => {
    const data = await handleExcelExport("label", false);

    const generalBody = data.map((item) => {
      return Object.entries(item)
        .filter(([key]) => key !== "S.no")
        .map(([key, value]) => {
          return [key, value, { margin: "0" }];
        });
    });
    setTableData(...generalBody);
  };

  return (
    <div>
      <Row
        gutter={[20, 20]}
        style={{ paddingTop: "15px", position: "relative" }}
      >
        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
          optional={true}
        />
        {bookingType === "online" &&
          (userDetails?.permissions.includes("SUPER_ADMIN") ||
            userDetails?.permissions.includes("ADMIN")) && (
            <TmsSelect
              type="col"
              span={3}
              selectClass="w-100"
              label="Partner Type"
              setValue={setSelectedPartner}
              value={selectedPartner}
              data={allPartnerList.map((item) => {
                return {
                  id: item.partnerCode,
                  name: `${item?.partnerName} - ${item?.partnerCode}`,
                };
              })}
            />
          )}
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Seva Type"
          setValue={setSevaType}
          value={sevaType}
          data={[
            { id: "parokshaSeva", name: "Paroksha Seva" },
            { id: "pratyakshaSeva", name: "Pratyaksha Seva" },
            { id: "saswataSeva", name: "Saswata Seva" },
          ]}
        />
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Seva Name"
          setValue={(e) => {
            setSevaId(e);
            if (e === "") {
              setSLot("");
              return;
            }
          }}
          value={sevaId}
          data={sevaList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Slot"
          setValue={setSLot}
          value={slot}
          data={slotList}
        />
        {bookingType === "online" && (
          <>
            {" "}
            <TmsSelect
              type="col"
              span={3}
              selectClass="w-100"
              label="Payment Status"
              setValue={setPaymentStatus}
              value={paymentStatus}
              data={[
                { id: "created", name: "created" },
                { id: "success", name: "success" },
                { id: "failure", name: "failure" },
                { id: "refund", name: "refund" },
                { id: "canceled", name: "canceled" },
              ]}
              optional={true}
            />
          </>
        )}
        {bookingType === "pos" && (
          <>
            {" "}
            <TmsSelect
              type="col"
              span={3}
              label="POS"
              setValue={setCounter}
              value={counter}
              data={counterList}
            />
            <TmsSelect
              type="col"
              span={3}
              label="Shift"
              setValue={setShift}
              value={shift}
              data={shiftList}
            />
          </>
        )}
        <TmsSelect
          type="col"
          span={3}
          label="Date Type"
          setValue={setDateType}
          value={dateType}
          data={[
            { id: "booking", name: "Performance" },
            { id: "payment", name: "Booking" },
          ]}
          optional={true}
        />{" "}
        <Col>
          <Row justify="start" style={{ marginBottom: "5px" }}>
            Update
          </Row>
          <Button type="primary" onClick={fetchBookingDetails}>
            Fetch
          </Button>
        </Col>
        <TmsExcelButton
          span={24}
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={true}
        />
        <TmsExcelButton
          span={24}
          label="Label Print"
          loading={labelLoading}
          handleExcelExport={async () => {
            setLabelLoading(true);
            await renderTable();
            setModal(true);
            setExternalPrint(true);
            console.log("external");
          }}
          top={true}
        />
        <TmsExcelButton
          span={24}
          label="Label Excel"
          loading={labelLoading}
          handleExcelExport={async () => {
            handleExcelExport("label", true);
          }}
          top={true}
        />
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={bookingType === "online" ? onlineColumns : columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>

      <SpecialSevaModal
        handlePrint={handlePrint}
        downloadFlag={downloadFlag}
        setDownloadFlag={setDownloadFlag}
        downloadData={downloadData}
        setDownloadData={setDownloadData}
        refContainer={refContainer}
      />
      <Modal
        visible={modal}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="label-print"
          from="Counter"
          data={tableData}
          setData={setTableData}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setLabelLoading}
          setPrintTicketModal={setModal}
          ticketType={"label-print"}
        />
      </Modal>
    </div>
  );
};

export default withRouter(TmsReportSevaReport);
