import React from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { useEffect, useState } from "react";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsHundiService from "../../../../services/tms-hundi.service";
import { Col, Row } from "antd";
import TmsDatepickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import moment from "moment";

import "../../../../assets/css/tms-parakamani/tms-parakamani-reports.scss";
import { FormatNumberAllCurrency } from "../../../../utils/tms-currency-formatter/tms-curreny-formatter";
import TmsModal from "../../../../components/tms-customs/tms-modal";
import TmsParakamaniModal from "../../../../components/tms-reports/tms-reports-parakamani/tms-parakamani-modal";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import TmsParakamaniTable from "../../../../components/tms-reports/tms-reports-parakamani/tms-parakamani-table";

const TmsReportsParakamaniReports = ({ router }) => {
  const [reports, setReports] = useState([]);
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [modalVisible, setModalVisible] = useState(false);

  const [excelLoading, setExcelLoading] = useState(false);

  const fetchReports = async () => {
    setLoading(true);
    try {
      const res = await TmsHundiService.listHundi(
        Number(tableCurrentPage),
        Number(tablePageSize),
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      );
      setReports(res?.items);
      setTabelTotalSize(res?.meta?.totalItems);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    fetchReports();
  }, [fromDate, toDate, tableCurrentPage, tablePageSize]);

  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const data = await TmsHundiService.listHundi(
        Number(1),
        Number(50000),
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      );

      const compiledData = data.items.map((item, i) => {
        return {
          "S.no": i + 1,
          "Parakamani Date": moment(item.parakamaniDate).format("DD-MM-YYYY"),
          "Installed Date": moment(item.installedDate).format("DD-MM-YYYY"),
          "Opened Date": moment(item.openedDate).format("DD-MM-YYYY"),
          Period: item.hundiPeriod,
          Hundis: item.totalInstalledHundis,
          Country: Object.keys(item?.totalAmount)
            .map((key) => {
              return item?.totalAmount[key]?.countryName;
            })
            .join(", \n"),
          "Valid Amount": Object.keys(item?.totalAmount)
            .map((key) => {
              return FormatNumberAllCurrency(
                Number(
                  item?.totalAmount[key].totalValidNotesValue +
                    item?.totalAmount[key].totalValidCoinsValue
                ),
                item?.totalAmount[key]?.countryName
              );
            })
            .join(", \n"),
          "Invalid Amount": Object.keys(item?.totalAmount)
            .map((key) => {
              return FormatNumberAllCurrency(
                Number(
                  item?.totalAmount[key].totalInvalidNotesValue +
                    item?.totalAmount[key].totalInvalidCoinsValue
                ),
                item?.totalAmount[key]?.countryName
              );
            })
            .join(", \n"),
          "Total Amount": Object.keys(item?.totalAmount)
            .map((key) => {
              return FormatNumberAllCurrency(
                Number(
                  item?.totalAmount[key].totalInvalidNotesValue +
                    item?.totalAmount[key].totalInvalidCoinsValue +
                    item?.totalAmount[key].totalValidNotesValue +
                    item?.totalAmount[key].totalValidCoinsValue
                ),
                item?.totalAmount[key]?.countryName
              );
            })
            .join(", \n"),
          "MR Date": moment(item.mrDate).format("DD-MM-YYYY"),
          "MR Number": item.mrNumber,
        };
      });
      console.log(compiledData);
      exportExcel(
        compiledData,
        `Hundi-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <TmsDatepickerRange
            className={"m-0"}
            label="Time period"
            to={toDate}
            from={fromDate}
            setFrom={setFromDate}
            setTo={setToDate}
          />
        </Col>
        <TmsExcelButton
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={true}
        />
      </Row>
      <br />
      <Row>
        <TmsParakamaniTable
          dataSource={reports}
          loading={loading}
          handleTableChange={handleTableChange}
          setRecord={setRecord}
          setModalVisible={setModalVisible}
          // setImages={setImages}
          // setSelectImg={setSelectImg}
          // setGallery={setGallery}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
          flag={true}
          scroll={{ x: "160%" }}
        />
      </Row>
      <TmsModal
        className="hundiReport"
        centered={true}
        visible={modalVisible}
        closable={true}
        width={"1200px"}
        onCancel={() => {
          setRecord([]);
          setModalVisible(false);
        }}
      >
        <TmsParakamaniModal recordData={record} loading={loading} />
      </TmsModal>
    </>
  );
};

export default withRouter(TmsReportsParakamaniReports);
