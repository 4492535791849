import { Select, Row, Col } from "antd";
import React, { memo } from "react";
import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";
const TmsSelect = ({
  type,
  label,
  setValue,
  value,
  onChange,
  data,
  selectClass,
  classname,
  labelClass,
  span,
  optional,
  mandatory,
  error,
  disabled,
  placeholder,
  allowClear,
}) => {
  const item = (
    <>
      {" "}
      <Row
        justify="start"
        style={{ marginBottom: "5px", width: type === "row" ? "20%" : "100%" }}
        className={labelClass}
      >
        {label}
        {mandatory && (
          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
        )}
      </Row>
      <Row justify="start" style={{ width: type === "row" ? "80%" : "100%" }}>
        <Select
          allowClear={allowClear && true}
          style={{ width: "100%", textAlign: "left" }}
          showSearch
          className={selectClass ? selectClass : "sd-select-r"}
          dropdownClassName="sd-select-dropdown-r"
          onChange={setValue ? setValue : onChange}
          value={value}
          defaultValue={value}
          placeholder={placeholder ? placeholder : `Select a ${label}`}
          filterOption={true}
          disabled={disabled ?? false}
        >
          {data?.map((item) => {
            return (
              <Select.Option
                key={item.id}
                value={item.id}
                label={item.name ?? item.description}
              >
                {item.name ?? item.description}
              </Select.Option>
            );
          })}
          {!optional && (
            <Select.Option key={""} value="">
              All
            </Select.Option>
          )}
        </Select>
      </Row>
    </>
  );
  return (
    <>
      {type === "row" ? (
        <Row className={classname ? classname : ""}>{item}</Row>
      ) : type === "col" ? (
        <Col span={span} className={classname ? classname : ""}>
          {item}
          {error?.length > 0 && (
            <p className="sd-EM mr-15">
              {
                error[0]?.err?.find(
                  (e) => e.type === `${error[0]?.i + 1}-idType`
                )?.msg
              }
            </p>
          )}
        </Col>
      ) : (
        ""
      )}
    </>
  );
};
export default memo(TmsSelect);
