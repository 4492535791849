import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportEntryScanReport from "./tms-report-entryscan-report";

const TmsReportEntryScan = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.reports_entryScan) {
      setActive("Entry Scan");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Entry Scan",
      Element: <TmsReportEntryScanReport />,
      key: "Entry Scan",
      route: tmsRouteMap.reports_entryScan,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportEntryScan);
