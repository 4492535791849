import { Col, Row, Input, DatePicker, Divider } from "antd";
import React, { useEffect } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import {
  additionalLicenseArrearStates,
  arrearLicenseStates,
  createFdrStates,
  paymentTerms,
} from "../../../../../../constants/states-data.data";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import {
  AccountCategorySelect,
  BankAccountSelect,
  FdrInstitutionSelect,
  InvestementSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";

import useFdrStore from "../../../../../../zustand/fdr.store";
import TmsInput from "../../../../../../components/tms-customs/tms-input";

const TmsFdrCreation = ({ router, setVisible }) => {
  const {
    selectedInstitution,
    setSubmitLoading,
    submitLoading,
    fetchInstitutionList,
    clearStates,
    chequeOrDDNumber,
    setChequeOrDDNumber,
    amountDepositedFromBank,
    setAmountDepositedFromBank,
    principleAmountInvested,
    setPrincipleAmountInvested,
    fdrAccountNumber,
    setFdrAccountNumber,
    fdrFolioNumber,
    setFdrFolioNumber,
    depositDate,
    setDepositDate,
    numberOfDays,
    setNumberOfDays,
    rateOfInterest,
    setRateOfInterest,
    interestRemittanceTerm,
    setInterestRemittanceTerm,
    maturityDate,
    setMaturityDate,
    interestRemittanceDate,
    setInterestRemittanceDate,
    maturityAmount,
    setMaturityAmount,
    setRemarks,
    interestAmount,
    setInterestAmount,
    remarks,
    handleFdrCreation,
    fetchInvestmentTypes,
    fetchCreatedAccountCategory,
    fetchBankAccounts,
  } = useFdrStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(arrearLicenseStates, additionalLicenseArrearStates);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution) {
      fetchCreatedAccountCategory(1, 200, "ASC", "createdAt", router);
      fetchInvestmentTypes(1, 200, "ASC", "createdAt", router);
      fetchBankAccounts(1, 200, "ASC", "createdAt", router);
    }
  }, [selectedInstitution]);

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <FdrInstitutionSelect span={8} />
        <AccountCategorySelect span={8} />
        <InvestementSelect span={8} />
        <BankAccountSelect span={8} />
      </Row>
      <Divider />
      <Row
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#333333",
          width: "100%",
          marginBottom: "15px",
        }}
      >
        Bank Details
      </Row>{" "}
      <Row justify="flex-start" gutter={[20, 20]}>
        <TmsInput
          type="text-number"
          label={"Cheq/DD no"}
          value={chequeOrDDNumber}
          setValue={setChequeOrDDNumber}
          span={8}
          placeholder={"Enter Cheq/DD no"}
        />

        <Col span={8}>
          <Row className={"t-left"}>Amount Deposited from Bank</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter Deposited Amount"
            value={amountDepositedFromBank}
            onChange={(e) => {
              setAmountDepositedFromBank(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Principal Amount invested Rs.</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter Principal Amount"
            value={principleAmountInvested}
            onChange={(e) => {
              setPrincipleAmountInvested(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>FDR Account Number</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter FDR Account Number"
            value={fdrAccountNumber}
            onChange={(e) => {
              setFdrAccountNumber(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>FDR Folio Number</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter folio number"
            value={fdrFolioNumber}
            onChange={(e) => {
              setFdrFolioNumber(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Deposit Date</Row>
          <DatePicker
            disabled={false}
            value={depositDate}
            style={{ width: "100%" }}
            format="DD-MM-YYYY"
            onChange={(date) => {
              setDepositDate(date);
            }}
            size="medium"
          />{" "}
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>No of Days</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter number of days"
            value={numberOfDays}
            onChange={(e) => {
              setNumberOfDays(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Rate of Interest(%)</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter Percentage"
            value={rateOfInterest}
            onChange={(e) => {
              setRateOfInterest(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="mr-0 "
          type="col"
          value={interestRemittanceTerm}
          setValue={setInterestRemittanceTerm}
          label="Interest remittance term"
          data={paymentTerms}
          span={8}
          optional={true}
        />
        <Col span={8}>
          <Row className={"t-left"}>Interest Amount</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter Interest Amount"
            value={interestAmount}
            onChange={(e) => {
              setInterestAmount(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Maturity Date</Row>
          <DatePicker
            disabled={false}
            value={maturityDate}
            style={{ width: "100%" }}
            format="DD-MM-YYYY"
            onChange={(date) => {
              setMaturityDate(date);
            }}
            size="medium"
          />{" "}
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Interest Remittance Date</Row>
          <DatePicker
            disabled={false}
            value={interestRemittanceDate}
            style={{ width: "100%" }}
            format="DD-MM-YYYY"
            onChange={(date) => {
              setInterestRemittanceDate(date);
            }}
            size="medium"
          />{" "}
        </Col>

        <Col span={8}>
          <Row className={"t-left"}>Maturity Amount</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter Maturity Amount"
            value={maturityAmount}
            onChange={(e) => {
              setMaturityAmount(e.target.value);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>

        <Col span={8}>
          {" "}
          <Row className={"t-left"}>Remarks</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Col>
      </Row>{" "}
      <br />
      <TmsCancelSubmitButtons
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(createFdrStates, additionalLicenseArrearStates);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => !submitLoading && handleFdrCreation(router)}
        loading={submitLoading}
      />
    </>
  );
};

export default TmsFdrCreation;
