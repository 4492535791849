import { Button, Row } from "antd";
import React, { useState } from "react";
import TmsModal from "../../../../../../components/tms-customs/tms-modal";
import TmsBasLeaseCreation from "../creation-inner-pages/tms-bas-land-lease-creation";
import TmsSmBasLeaseList from "../created-list-inner-pages/tms-sm-bas-lease-list";

const BuildingLease = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Row justify="start">
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
          }}
        >
          New Shop Lease Creation
        </Button>
      </Row>
      <br />
      {!visible && <TmsSmBasLeaseList />}
      <TmsModal
        centered={true}
        width="80%"
        closable={false}
        visible={visible}
        title={"Shop Lease Creation "}
        onClose={() => {
          setVisible(false);
        }}
      >
        {visible && <TmsBasLeaseCreation setVisible={setVisible} />}
      </TmsModal>
    </div>
  );
};

export default BuildingLease;
