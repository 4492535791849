import React, { useRef, useContext, useState, useEffect } from "react";
import TmsDarshanamTicket from "./tms-darshanam-print-ticket/tms-darshanam-ticket";
import { useReactToPrint } from "react-to-print";
import "../../assets/css/tms-print-ticket/tms-print-ticket.scss";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import { BaseContext } from "../../contexts/BaseContext";
import { Button, Modal, Row, Space } from "antd";
import TmsPosDonationBond from "../tms-pos/tms-pos-donation/tms-pos-dontion-bond.jsx";

const TmsPrintTicket = ({
  data,
  bondData,
  printType,
  setBondData,
  setData,
  setExternalPrint,
  type,
  externalPrint,
  from,
  setPrintTicketModal,
  setButtonLoading,
}) => {
  let refContainer = useRef(null);

  const { setTicketDetailsModal, setReBookingConfi } = useContext(BaseContext);
  const [bondPrintTicket, setBondPrintTicket] = useState(false);
  const [bondPrintConfirmation, setBondPrintConfirmation] = useState(false);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sd-ticket-container",

    onAfterPrint: async () => {
      setData([]);
      setExternalPrint(false);
      setPrintTicketModal(false);
      setButtonLoading(false);
      if (type === "accommodation" || type === "advance accommodation") {
        setReBookingConfi(true);
      } else if (type === "renew" || type === "checkOut") {
        setTicketDetailsModal(false);
        window?.location?.reload();
      } else if (type === "donation" && printType !== "thermal") {
        if (templeDetails?.configuration?.enableDonationBond) {
          setBondPrintConfirmation(true);
          setExternalPrint(false);
        } else {
          window.location.reload();
        }
      } else {
        window.location.reload();
      }
    },
  });

  useEffect(() => {
    if (externalPrint === true) {
      handleFunction();
    }
  }, [externalPrint]);

  const handleFunction = () => {
    setTimeout(() => {
      handlePrint();
    }, 500);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <div
          ref={refContainer}
          id="ticketContainer"
          className="sd-ticket-container"
          style={{ marginTop: "0", width: "100%", textAlign: "center" }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              textAlign: "left",
              paddingRight: "30px",
              border: "1px solid #f2f2f2",
            }}
            className="sd-ticket-card"
          >
            <TmsDarshanamTicket data={data} from={from} type={type} />
          </div>
        </div>
      </div>
      <Modal
        visible={bondPrintConfirmation}
        width={850}
        footer={null}
        closable={false}
        centered
      >
        <>
          <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
            {`Do You Want To Print Bond ?`}
          </Row>
          <Row style={{ justifyContent: "center", margin: "auto" }}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setBondPrintConfirmation(false);
                  setBondPrintTicket(true);
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setData([]);
                  setBondData([]);
                  window.location.reload();
                }}
              >
                No
              </Button>
            </Space>
          </Row>
        </>
      </Modal>
      <Modal
        visible={bondPrintTicket}
        width={850}
        footer={null}
        closable={false}
        centered
      >
        <TmsPosDonationBond
          data={bondData}
          setBondPrintTicket={setBondPrintTicket}
        />
      </Modal>
    </>
  );
};

export default withRouter(TmsPrintTicket);
