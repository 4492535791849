import { Upload, Button, message, notification, Modal } from "antd";
import React, { memo, useState } from "react";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsCommonService from "../../services/tms-common.service";
const TmsImageUpload = ({
  fileList,
  setFileList,
  className,
  selectedClass,
  accept,
  router,
  type,
  listType,
  flag,
  overrideFunc,
  index,
  dragger,
}) => {
  const [uploading, setUploading] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [fileStore, setFileStore] = useState();
  const handleChange = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    const fileTypes = ["application/pdf", "image/jpeg", "image/png"];
    const ext = file?.name?.match(/\.(.+)$/)[1];

    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
      overrideFunc
        ? overrideFunc([], index, "fileURl")
        : setFileList(dragger ? [...fileList] : []);
      return false;
    }
    if (!accept) {
      if (file?.type !== "image/png" && file?.type !== "image/jpeg") {
        message.error("Please Upload PNG, JPEG file");
        overrideFunc
          ? overrideFunc([], index, "fileURl")
          : setFileList(dragger ? [...fileList] : []);
        return false;
      }
    } else if (!fileTypes?.includes(file?.type)) {
      message.error("Please Upload only PDF, JPEG, PNG file");
      overrideFunc
        ? overrideFunc([], index, "fileURl")
        : setFileList(dragger ? [...fileList] : []);
      return false;
    }
    if (ext.indexOf(".") !== -1) {
      message.error(
        "Filename with two extensions is not supported due to security restrictions"
      );
      overrideFunc
        ? overrideFunc([], index, "fileURl")
        : setFileList(dragger ? [...fileList] : []);
      return false;
    }

    return true;
  };

  const props = {
    customRequest: async ({ file, onSuccess, onError }) => {
      setUploading(true);
      let formData = new FormData();
      formData.append("file", file);
      try {
        let res =
          flag === "private"
            ? await TmsCommonService.uploadPrivateFile(formData, "welcome")
            : await TmsCommonService.uploadFile(
                formData,
                type ? type : "public",
                "welcome"
              );
        let temp = dragger
          ? [
              ...fileList,
              {
                url: res.fileUrl,
                status: "done",
                name: "file",
              },
            ]
          : [
              {
                url: res.fileUrl,
                status: "done",
                name: "file",
              },
            ];
        overrideFunc ? overrideFunc(temp, index, "fileURl") : setFileList(temp);
        setUploading(false);
        onSuccess("done");
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please login again.", router);
        setUploading(false);
        onError("err");
        notification.error({ message: e?.message });
        overrideFunc
          ? overrideFunc([], index, "fileURl")
          : setFileList(dragger ? [...fileList] : []);
      }
    },
    progress: {
      type: "line",
      // strokeColor: {
      //   "0%": "#108ee9",
      //   "100%": "#87d068",
      // },
      strokeWidth: 3,
      // format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    beforeUpload: handleChange,
    multiple: false,
    listType: listType ? listType : "picture",
    accept: accept ? "image/*,.pdf" : "image/png,image/jpeg",
    onRemove: (file) => {
      let temp = fileList;
      temp?.splice(
        temp?.indexOf(temp?.find((item) => item?.uid === file?.uid)),
        1
      );
      overrideFunc
        ? overrideFunc([...temp], index, "fileURl")
        : setFileList([...temp]);
    },
    className: "w-100",
    style: `width:100%`,
  };

  return (
    <>
      {" "}
      <Upload
        {...props}
        listType={dragger ? "picture-card" : "picture"}
        maxCount={1}
        fileList={fileList}
        className={selectedClass ?? ""}
        onPreview={(file) => {
          setFileStore(file);
          setViewModal(true);
        }}
        style={{ width: className === "w-100" ? "100%" : "250px" }}
      >
        {dragger ? (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        ) : (
          <Button
            loading={uploading}
            disabled={uploading}
            icon={<UploadOutlined />}
            style={{ width: className === "w-100" ? "100%" : "250px" }}
          >
            Upload {accept ? "" : "PNG, JPEG only"}
          </Button>
        )}
      </Upload>
      <Modal
        centered
        visible={viewModal}
        footer={null}
        onCancel={() => {
          setViewModal(false);
        }}
      >
        <br />
        <img alt="example" style={{ width: "100%" }} src={fileStore?.url} />
      </Modal>
    </>
  );
};

export default memo(TmsImageUpload);
