import React, { useEffect, useState } from "react";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsMiscInnerPagesAll from "./tms-sm-misc-inner-pages/tms-misc-inner-pages-all";
import TmsMiscInnerPagesAnd from "./tms-sm-misc-inner-pages/tms-misc-inner-pages-and";

const TmsSmMisc = ({ router }) => {
  const [active, setActive] = useState("all");

  useEffect(() => {
    let location = router.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_misc) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sm_misc_add_new_misc)) {
      setActive("Add New Miscellaneous");
    } else if (location.includes(tmsRouteMap.sm_misc_manage_counter)) {
      setActive("all");
      return;
    }
  }, [router.location?.pathname]);
  const location = router.location?.pathname;
  const components = [
    {
      name: "All",
      Element: location.includes(tmsRouteMap.sm_misc_manage_counter) ? (
        <TmsCmsSlots type="misc-counters" />
      ) : (
        <TmsMiscInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_misc,
    },

    {
      name: "Add New Miscellaneous",
      Element: <TmsMiscInnerPagesAnd />,
      key: "Add New Miscellaneous",
      route: tmsRouteMap.sm_misc_add_new_misc,
    },
    // {
    //   name: "Tollgate Booking Details",
    //   Element: <TmsTollgateInnerPagesDbd />,
    //   key: "Tollgate Booking Details",
    //   route: tmsRouteMap.sm_tollgate_booking_details,
    // },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmMisc);
