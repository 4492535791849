import { notification } from "antd";
import moment from "moment";
import { create } from "zustand";
import {
  createAccountCatStates,
  createBankAccountStates,
  createFdrStates,
  createInvestmentStates,
} from "../constants/states-data.data";
import TmsFdrService from "../services/tms-fdr.service";
import catchErrorFunction from "../utils/tms-catch-error/tms-catch-error";
import { exportExcel } from "../utils/tms-excel/tms-excel";

const useFdrStore = create((set, get) => {
  const initialState = {
    loading: false,
    submitLoading: false,
    //account-cat
    selectedInstitution: "",
    institutionData: [],
    accountCategoryName: "",
    selectedAccountCategory: "",
    accountCategoryData: [],
    //bank-acc
    bankName: "",
    branchName: "",
    accountNumber: "",
    accountType: "",
    ifsccode: "",
    location: "",
    selectedBankAccount: "",
    bankAccountData: [],
    //investment
    investmentType: "",
    selectedInvestmentType: "",
    investmentTypeData: [],
    //FDR
    chequeOrDDNumber: "",
    amountDepositedFromBank: "",
    principleAmountInvested: "",
    fdrAccountNumber: "",
    fdrFolioNumber: "",
    depositDate: "",
    numberOfDays: "",
    rateOfInterest: "",
    interestRemittanceTerm: "",
    interestAmount: "",
    maturityDate: "",
    interestRemittanceDate: "",
    maturityAmount: "",
    remarks: "",
    createdFdrData: [],
    selectedFdr: "",
    fdrReports: [],

    arrayStates: [
      "accountCategoryData",
      "institutionData",
      "bankAccountData",
      "investmentTypeData",
      "createdFdrData",
      "fdrReports",
    ],
    datesArray: ["interestRemittanceDate", "maturityDate", "depositDate"],
  };
  const generateSetterFunctions = (stateObject) => {
    const setters = {};
    for (const key in stateObject) {
      setters[`set${key.charAt(0).toUpperCase()}${key.slice(1)}`] = (value) =>
        set({ [key]: value });
    }
    return setters;
  };
  return {
    ...initialState,
    ...generateSetterFunctions(initialState),
    //Actions
    checkForEmptyStates(array) {
      array.forEach((element) => {
        const value = get()[element];
        if (value === "") {
          throw new Error(
            `${element
              .split(/(?=[A-Z])/)
              .join(" ")} should not be empty. Please fill all the fields`
          );
        }
      });
    },
    clearStates(array, dataArrays) {
      const setters = {};
      const data = [...array, ...dataArrays];
      data.forEach((item) => {
        if (get().datesArray.includes(item)) {
          setters[item] = moment();
        } else if (get().arrayStates.includes(item)) {
          setters[item] = [];
        } else setters[item] = "";
      });
      set({ ...setters });
    },
    async fetchInstitutionList(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsFdrService.getCreatedInstitutions(
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          institutionData: res,
          loading: false,
          selectedInstitution: "",
          selectedAccountCategory: "",
          selectedBankAccount: "",
          selectedInvestmentType: "",
          accountCategoryData: [],
          bankAccountData: [],
          investmentTypeData: [],
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token Expired!!,Login Again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchCreatedAccountCategory(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsFdrService.getCreatedAccountCategory(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          accountCategoryData: res,
          loading: false,
          selectedAccountCategory: "",
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleCreateAccountCategory(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createAccountCatStates);
        const body = {
          accountCategoryName: get().accountCategoryName,
          institutionId: get().selectedInstitution,
        };
        await TmsFdrService.createAccountCategory(body);
        await get().clearStates(createAccountCatStates, []);
        notification.success({
          message: "Account category created successfully",
        });
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false, status: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedAccountCategory(catId, router) {
      try {
        set({ deleteLoading: true });
        await TmsFdrService.deleteCreatedAcountCategory(catId);
        notification.success({
          message: `Account Category with ID:${catId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchBankAccounts(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsFdrService.getCreatedBankAccounts(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({ bankAccountData: res, loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },

    async handleBankAccountCreation(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createBankAccountStates);
        const body = {
          bankName: get().bankName,
          branchName: get().branchName,
          accountNumber: get().accountNumber,
          accountType: get().accountType,
          ifsccode: get().ifsccode,
          location: get().location,
          accountCategoryId: get().selectedAccountCategory?.id,
          institutionId: get().selectedInstitution,
        };
        await TmsFdrService.createBankAccounts(body);
        notification.success({ message: "Bank Account created successfully" });
        await get().clearStates(createBankAccountStates, [
          "institutionData",
          "accountCategoryData",
        ]);
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleBankAccountUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          bankName: record.bankName,
          branchName: record.branchName,
          accountNumber: record.accountNumber,
          accountType: record.accountType,
          ifsccode: record.ifsccode,
          location: record.location,
          accountCategoryId: record.selectedAccountCategory,
          institutionId: record.selectedInstitution,
        };

        await TmsFdrService.updateCreatedBankAccount(body, record.id);
        notification.success({ message: "Bank Account Updated succesfully" });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedBankAccount(bankAccId, router) {
      try {
        set({ deleteLoading: true });
        await TmsFdrService.deleteCreatedBankAccount(bankAccId);
        notification.success({
          message: `Bank Account with ID:${bankAccId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },

    //investment types
    async fetchInvestmentTypes(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsFdrService.getCreatedInvestment(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy,
          router
        );
        set({ investmentTypeData: res, loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleInvestmentCreation(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createInvestmentStates);

        const body = {
          investmentType: get().investmentType,
          institutionId: get().selectedInstitution,
        };

        await TmsFdrService.createInvestment(body);
        notification.success({
          message: "Investment has been successfully creation.",
        });
        await get().clearStates(createInvestmentStates, []);
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleInvestmentUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          investmentType: record.investmentType,
          institutionId: record.institutionId,
        };

        await TmsFdrService.updateCreatedInvestment(body, record.id);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedInvestmentType(investmentId, router) {
      try {
        set({ deleteLoading: true });
        await TmsFdrService.deleteCreatedInvestment(investmentId);
        notification.success({
          message: `Investment type with ID:${investmentId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },

    //fdr
    async fetchCreatedFdr(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsFdrService.getCreatedFdr(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy,
          router
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({ createdFdrData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleFdrCreation(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createFdrStates);

        const body = {
          chequeOrDDNumber: get().chequeOrDDNumber,
          amountDepositedFromBank: Number(get().amountDepositedFromBank),
          principleAmountInvested: Number(get().principleAmountInvested),
          fdrAccountNumber: get().fdrAccountNumber,
          fdrFolioNumber: get().fdrFolioNumber,
          depositDate: moment(get().depositDate).format("YYYY-MM-DD"),
          numberOfDays: Number(get().numberOfDays),
          rateOfInterest: Number(get().rateOfInterest),
          interestRemittanceTerm: get().interestRemittanceTerm,
          interestAmount: Number(get().interestAmount),
          maturityDate: moment(get().maturityDate).format("YYYY-MM-DD"),
          interestRemittanceDate: moment(get().interestRemittanceDate).format(
            "YYYY-MM-DD"
          ),
          maturityAmount: Number(get().maturityAmount),
          remarks: get().remarks,
          institutionId: get().selectedInstitution,
          bankAccountId: get().selectedBankAccount?.id,
          investmentTypeId: get().selectedInvestmentType?.id,
        };

        await TmsFdrService.createFdr(body);
        notification.success({
          message: "FDR has been successfully created.",
        });
        await get().clearStates(createFdrStates, []);
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleFdrUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          chequeOrDDNumber: record.chequeOrDDNumber,
          amountDepositedFromBank: record.amountDepositedFromBank,
          principleAmountInvested: record.principleAmountInvested,
          fdrAccountNumber: record.fdrAccountNumber,
          fdrFolioNumber: record.fdrFolioNumber,
          depositDate: moment(record.depositDate).format("YYYY-MM-DD"),
          numberOfDays: record.numberOfDays,
          rateOfInterest: record.rateOfInterest,
          interestRemittanceTerm: record.interestRemittanceTerm,
          interestAmount: record.interestAmount,
          maturityDate: moment(record.maturityDate).format("YYYY-MM-DD"),
          interestRemittanceDate: moment(record.interestRemittanceDate).format(
            "YYYY-MM-DD"
          ),
          maturityAmount: record.maturityAmount,
          remarks: record.remarks,
          institutionId: record.institutionId,
          bankAccountId: record.bankAccountId,
          investmentTypeId: record.investmentTypeId,
        };

        await TmsFdrService.updateCreatedFdr(body, record.id);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedFdr(fdrId, router) {
      try {
        set({ deleteLoading: true });
        await TmsFdrService.deleteCreatedFdr(fdrId);
        notification.success({
          message: `Fdr with ID:${fdrId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },

    async fetchFdrReports(
      maturityDate,
      tableCurrentPage,
      tablePageSize,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsFdrService.getFdrReports(
          get().selectedInstitution,
          get().selectedAccountCategory?.id,
          get().selectedBankAccount?.id,
          get().selectedInvestmentType?.id,
          maturityDate ? moment(maturityDate).format("YYYY-MM-DD") : "",
          tableCurrentPage,
          tablePageSize
        );
        set({
          createdFdrData: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleExcelExport(type, router) {
      set({ excelLoading: true });
      const funtionType = {
        fdr: get().fetchCreatedFdr,
      };
      try {
        const data = await funtionType[type](
          1,
          1000,
          "ASC",
          "createdAt",
          router,
          "return"
        );
        if (data.length === 0) {
          notification.info({ message: "No records found to Export" });
          set({ excelLoading: false });
          return;
        }
        exportExcel(data, `${type}-${moment().format("DD-MM-YYYY")}`, () => {
          set({ excelLoading: false });
        });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token expired!! Please login again.",
          router
        );
        set({ excelLoading: false });
      }
    },
  };
});
export default useFdrStore;
