import React, { useEffect, useState } from "react";
import "../../../../assets/css/tms-pos-donor/tms-pos-donor-p.scss";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { Button, Checkbox, Col, Input, Row, notification } from "antd";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsAccommodationService from "../../../../services/tms-accommodation.service";
import TmsDarshanamService from "../../../../services/tms-darshanam/tms-darshanam.service";
import TmsSevasService from "../../../../services/tms-sevas.service";
import TmsProductsService from "../../../../services/tms-products.service";
import TmsPrivilegesService from "../../../../services/tms-privileges.service";
import { RightOutlined } from "@ant-design/icons";
import TmsImageUpload from "../../../../components/tms-customs/tms-image-upload";
import tmsRouteMap from "../../../../constants/tms-route.map";

const TmsSmDonorPCreate = ({ router }) => {
  const [loading, setLoading] = useState(false);
  // const [donationList, setDonationList] = useState([]);
  // const [selectedDonationType, setSelectedDonationType] = useState();
  const [blockData, setBlockData] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState();
  const [darshanamList, setDarshanamList] = useState([]);
  const [sevaList, setSevaList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [privilegeName, setPrivilegeName] = useState("");
  const [minAmount, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [accoBooking, setAccoBooking] = useState([]);
  const [darshanamBooking, setDarshanamBooking] = useState([
    { id: null, days: "", ppl: "", tenure: null, discount: "" },
  ]);
  const [sevaBooking, setSevaBooking] = useState([
    { id: null, days: "", tenure: null, discount: "" },
  ]);
  const [productBooking, setProductBooking] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [publicationBooking, setPublicationBooking] = useState([]);
  const [bahumanamBooking, setBahumanamBooking] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [fileList, setFileList] = useState([]);

  // const getDonationList = async () => {
  //   setLoading(true);
  //   try {
  //     let res = await TmsDonationService.getDonations(1, 100);
  //     let arr = res?.items?.filter((item) => item?.donationType === "general");

  //     setDonationList(arr);

  //     setLoading(false);
  //   } catch (error) {
  //     catchErrorFunction(
  //       error,
  //       "Token Expired, redirecting to login page in 5 seconds",
  //       router
  //     );
  //     setLoading(false);
  //   }
  // };

  const getBlocks = async () => {
    try {
      let res = await TmsAccommodationService.getAllBlocks(1, 100);
      setBlockData(res?.items);
    } catch (error) {
      catchErrorFunction(error, "Token expired!", router);
    }
  };

  const getCategories = async () => {
    try {
      let res = await TmsAccommodationService.getAllBlockCategorys(
        selectedBlock,
        1,
        100
      );
      setcategoryData(
        res?.items.map((item) => {
          return { id: item.id, name: item.roomType };
        })
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!", router);
    }
  };

  const fetchDarshanamList = async () => {
    try {
      let res = await TmsDarshanamService.getDarshanam(1, 100);
      setDarshanamList(res.items);
    } catch (error) {
      catchErrorFunction(error, "Token expired!", router);
    }
  };

  const fetchSevaList = async () => {
    try {
      let data = await TmsSevasService.getSevasByType("pratyakshaSeva", 1, 100);
      setSevaList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  const getproducts = async () => {
    setLoading(true);
    try {
      const data = await TmsProductsService.getProducts(1, 100);
      setProductList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const privilegeById = async (id) => {
    setLoading(true);
    try {
      let data = await TmsPrivilegesService.getAllPrivilegesId(id);
      // setSelectedDonationType(data?.donationType);
      setSelectedBlock(
        data?.accomodationPrivilege[0]?.accBlockCategory?.accBlockId
      );
      setPrivilegeName(data?.donationName);
      setMinAmount(data?.donationMinValue);
      setMaxAmount(data?.donationMaxValue);

      if (data?.accomodationPrivilege?.length > 0) {
        setAccoBooking(
          data?.accomodationPrivilege?.map((item) => {
            return {
              block: item?.accBlockCategory?.accBlockId,
              category: item?.accBlockCategoryId,
              days: item?.noOfCoupons,
              tenure: item?.tenureInMonths,
              discount: Number(item?.discount),
            };
          })
        );
      }

      if (data?.darshanamPrivilege?.length > 0) {
        setDarshanamBooking(
          data?.darshanamPrivilege?.map((item) => {
            return {
              id: item?.darshanamId,
              days: item?.noOfCoupons,
              ppl: item?.noOfPeople,
              tenure: item?.tenureInMonths,
              discount: Number(item?.discount),
            };
          })
        );
      }

      if (data?.sevaPrivilege?.length > 0) {
        setSevaBooking(
          data?.sevaPrivilege?.map((item) => {
            return {
              id: item?.sevaId,
              days: item?.noOfCoupons,
              tenure: item?.tenureInMonths,
              discount: Number(item?.discount),
            };
          })
        );
      }

      if (data?.productPrivilege?.length > 0) {
        let products = [];
        let publication = [];
        let bahumanam = [];

        data?.productPrivilege?.map((item) => {
          if (item?.shopProduct?.type === "GENERAL") {
            products?.push(item);
          } else if (item?.shopProduct?.type === "PUBLICATION") {
            publication?.push(item);
          } else if (item?.shopProduct?.type === "BAHUMANAM") {
            bahumanam?.push(item);
          }
        });

        if (products?.length > 0) {
          let pbody = [{ product: [], tenure: null, discount: "" }];
          products?.map((item) => {
            pbody[0]?.product?.push({
              id: item?.shopProductId,
              qty: item?.quantity,
            });
            pbody[0].tenure = item?.tenureInMonths;
            pbody[0].discount = Number(item?.discount);
          });

          selectedProductsFunc(pbody);
          setProductBooking(pbody);
        }

        if (publication?.length > 0) {
          let publicBody = [{ product: [], tenure: null, discount: "" }];
          publication?.map((item) => {
            publicBody[0]?.product?.push(item?.shopProductId);
            publicBody[0].tenure = item?.tenureInMonths;
            publicBody[0].discount = Number(item?.discount);
          });
          setPublicationBooking(publicBody);
        }

        if (bahumanam?.length > 0) {
          let bahuBody = [{ product: [], tenure: null, discount: "" }];
          bahumanam?.map((item) => {
            bahuBody[0]?.product?.push(item?.shopProductId);
            bahuBody[0].tenure = item?.tenureInMonths;
            bahuBody[0].discount = Number(item?.discount);
          });
          setBahumanamBooking(bahuBody);
        }
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    // getDonationList();
    getBlocks();
    fetchDarshanamList();
    fetchSevaList();
    getproducts();
    if (new URLSearchParams(router?.location?.search).get("id")) {
      setEditId(new URLSearchParams(router?.location?.search).get("id"));
      privilegeById(new URLSearchParams(router?.location?.search).get("id"));
      setEdit(true);
    }
  }, []);

  useEffect(() => {
    if (selectedBlock !== undefined) {
      getCategories();
    }
  }, [selectedBlock]);

  const addBooking = (type) => {
    let body = type === "darshanam" ? [...darshanamBooking] : [...sevaBooking];
    body?.push(
      type === "darshanam"
        ? { id: null, days: "", ppl: "", tenure: null, discount: "" }
        : { id: null, days: "", tenure: null, discount: "" }
    );
    if (type === "darshanam") {
      setDarshanamBooking(body);
    } else {
      setSevaBooking(body);
    }
  };

  const removeBooking = (type, i) => {
    let body = type === "darshanam" ? [...darshanamBooking] : [...sevaBooking];
    body?.splice(i, 1);
    if (type === "darshanam") {
      setDarshanamBooking(body);
    } else {
      setSevaBooking(body);
    }
  };

  const bookingHandle = (type, key, value, index) => {
    if (type === "pquota") {
      let pbody =
        productBooking?.length > 0
          ? [...productBooking]
          : [{ product: [], tenure: null, discount: "" }];
      if (key === "product") {
        if (selectedProducts?.includes(value)) {
          let i = selectedProducts?.indexOf(value);
          selectedProducts?.splice(i, 1);
          pbody[0]?.product?.splice(i, 1);
        } else {
          pbody[0][key]?.push({ id: value, qty: 1 });
        }
      } else {
        pbody[0][key] = value;
      }
      selectedProductsFunc(pbody);
      setProductBooking(pbody);
    }

    if (type === "acco") {
      let accoBody =
        accoBooking?.length > 0
          ? [...accoBooking]
          : [
              {
                block: null,
                category: null,
                days: "",
                tenure: null,
                discount: "",
              },
            ];
      accoBody[0][key] = value;
      setAccoBooking(accoBody);
    }

    if (type === "darshanam") {
      let darBody =
        darshanamBooking?.length > 0
          ? [...darshanamBooking]
          : [{ id: null, days: "", ppl: "", tenure: null, discount: "" }];
      darBody[index][key] = value;
      setDarshanamBooking(darBody);
    }

    if (type === "seva") {
      let sevaBody =
        sevaBooking?.length > 0
          ? [...sevaBooking]
          : [{ id: null, days: "", tenure: null, discount: "" }];
      sevaBody[index][key] = value;
      setSevaBooking(sevaBody);
    }

    if (type === "publication") {
      let publicBody =
        publicationBooking?.length > 0
          ? [...publicationBooking]
          : [{ product: [], tenure: null, discount: "" }];
      if (key === "product") {
        if (publicBody[0]?.product?.includes(value)) {
          publicBody[0]?.product?.splice(
            publicBody[0]?.product?.indexOf(value),
            1
          );
        } else {
          publicBody[0][key]?.push(value);
        }
      } else {
        publicBody[0][key] = value;
      }
      setPublicationBooking(publicBody);
    } else if (type === "bahumanam") {
      let bahuBody =
        bahumanamBooking?.length > 0
          ? [...bahumanamBooking]
          : [{ product: [], tenure: null, discount: "" }];
      if (key === "product") {
        if (bahuBody[0]?.product?.includes(value)) {
          bahuBody[0]?.product?.splice(bahuBody[0]?.product?.indexOf(value), 1);
        } else {
          bahuBody[0][key]?.push(value);
        }
      } else {
        bahuBody[0][key] = value;
      }
      setBahumanamBooking(bahuBody);
    }
  };

  const qtyManagement = (id, value) => {
    let body = [...productBooking];
    if (selectedProducts?.includes(id)) {
      body[0].product[selectedProducts?.indexOf(id)].qty = value;
    } else {
      body[0]?.product?.push({ id: id, qty: value });
      selectedProductsFunc(body);
    }
    setProductBooking(body);
  };

  const selectedProductsFunc = (body) => {
    setSelectedProducts(
      body[0]?.product?.map((item) => {
        return item?.id;
      })
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    let productsArr = [];

    productBooking?.map((item) => {
      item?.product?.map((pro) => {
        return productsArr?.push({
          shopProductId: Number(pro?.id),
          quantity: Number(pro?.qty),
          tenureInMonths: Number(item?.tenure),
          discount: Number(item?.discount),
        });
      });
    });

    publicationBooking?.map((item) => {
      item?.product?.map((pro) => {
        return productsArr?.push({
          shopProductId: Number(pro),
          quantity: 1,
          tenureInMonths: Number(item?.tenure),
          discount: Number(item?.discount),
        });
      });
    });

    bahumanamBooking?.map((item) => {
      item?.product?.map((pro) => {
        return productsArr?.push({
          shopProductId: Number(pro),
          quantity: 1,
          tenureInMonths: Number(item?.tenure),
          discount: Number(item?.discount),
        });
      });
    });

    let body = {
      donationName: privilegeName,
      // donationType: selectedDonationType,
      donationMinValue: Number(minAmount),
      donationMaxValue: Number(maxAmount),
      accommodationPrivilege: [],
      sevaPrivilege: [],
      darshanamPrivilege: [],
      productPrivilege: [],
    };

    if (accoBooking?.length > 0) {
      body.accommodationPrivilege = [
        {
          accBlockCategoryId: accoBooking[0]?.category,
          noOfCoupons: Number(accoBooking[0]?.days),
          tenureInMonths: Number(accoBooking[0]?.tenure),
          discount: Number(accoBooking[0]?.discount),
        },
      ];
    }

    if (sevaBooking?.filter((flag) => flag?.id !== null)?.length > 0) {
      body.sevaPrivilege = sevaBooking?.map((item) => {
        return {
          sevaId: Number(item?.id),
          noOfCoupons: Number(item?.days),
          tenureInMonths: Number(item?.tenure),
          discount: Number(item?.discount),
        };
      });
    }

    if (darshanamBooking?.filter((flag) => flag?.id !== null)?.length > 0) {
      body.darshanamPrivilege = darshanamBooking?.map((item) => {
        return {
          darshanamId: Number(item?.id),
          noOfCoupons: Number(item?.days),
          noOfPeople: Number(item?.ppl),
          tenureInMonths: Number(item?.tenure),
          discount: Number(item?.discount),
        };
      });
    }

    if (productsArr?.length > 0) {
      body.productPrivilege = productsArr;
    }

    if (edit) {
      body.changeRequestDocument = fileList[0]?.url;
    }

    try {
      if (edit) {
        await TmsPrivilegesService.updatePrivilege(editId, body);
        notification?.success({ message: "Edited Successfully" });
        router.navigate(tmsRouteMap?.sm_donor_p_list);
      } else {
        await TmsPrivilegesService.createPrivilege(body);
        notification?.success({ message: "Created Successfully" });
      }

      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  return (
    <Row className="parent">
      <Row className="max-width w-full background-white pxy">
        <Row className="w-full" gutter={[20, 20]}>
          <Col className="w-4">
            <label className="labelStyles">
              Privilege Name
              <span className="mandatory">*</span>
            </label>
            <br />
            <Input
              maxLength={220}
              className="w-full"
              placeholder="Enter Name"
              value={privilegeName}
              onChange={(e) => {
                setPrivilegeName(e.target.value);
              }}
            />
          </Col>
          {/* <TmsSelect
            type="col"
            label="Donation Type"
            mandatory={true}
            classname="w-4"
            optional={true}
            value={selectedDonationType}
            setValue={setSelectedDonationType}
            data={[
              { name: "Cash", id: "CASH" },
              { name: "Kind", id: "KIND" },
            ]}
          /> */}
          <Col className="w-4">
            <label className="labelStyles">
              Donation Value
              <span className="mandatory">*</span>
            </label>
            <br />
            <Input.Group>
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "50%" }}
                placeholder="Min"
                value={minAmount}
                onChange={(e) => {
                  setMinAmount(e.target.value);
                }}
              />
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "50%" }}
                placeholder="Max"
                value={maxAmount}
                onChange={(e) => {
                  setMaxAmount(e.target.value);
                }}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row className="headings w-full" justify="space-between">
          <Col>Accommodation</Col>{" "}
          <Col>
            <Button
              onClick={() => {
                setAccoBooking([]);
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
        <Row className="w-full" gutter={[20, 20]}>
          <TmsSelect
            type="col"
            label="Block"
            mandatory={true}
            classname="w-4"
            optional={true}
            value={accoBooking[0]?.block}
            onChange={(e) => {
              bookingHandle("acco", "block", e);
              setSelectedBlock(e);
            }}
            data={blockData?.map((item) => {
              return {
                name: item?.name,
                id: item?.id,
              };
            })}
          />
          <TmsSelect
            type="col"
            label="Room Category Type"
            mandatory={true}
            classname="w-4"
            optional={true}
            value={accoBooking[0]?.category}
            onChange={(e) => {
              bookingHandle("acco", "category", e);
            }}
            data={categoryData}
          />
          <Col className="w-4">
            <label className="labelStyles">
              No of Days
              <span className="mandatory">*</span>
            </label>
            <br />
            <Input
              maxLength={220}
              className="w-full"
              placeholder="Enter No of Days (0-9)"
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={accoBooking[0]?.days}
              onChange={(e) => {
                if (e.target.value > 9) {
                  return;
                }
                bookingHandle("acco", "days", e.target.value);
              }}
            />
          </Col>
          <Col className="w-4">
            <label className="labelStyles">
              Tenure
              <span className="mandatory">*</span>
            </label>
            <br />
            <Input
              maxLength={220}
              className="w-full"
              placeholder="Enter tenure in months"
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={accoBooking[0]?.tenure}
              onChange={(e) => {
                if (e.target.value > 120) {
                  return;
                }
                bookingHandle("acco", "tenure", e.target.value);
              }}
            />
          </Col>
          <Col className="w-4">
            <label className="labelStyles">
              Discount
              <span className="mandatory">*</span>
            </label>
            <br />
            <Input
              maxLength={220}
              className="w-full"
              placeholder="Enter Discount %"
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={accoBooking[0]?.discount}
              onChange={(e) => {
                if (e.target.value > 100) {
                  return;
                }
                bookingHandle("acco", "discount", e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row className="headings w-full" justify="space-between">
          <Col>Darshanam</Col>
          <Col>
            <Button
              onClick={() => {
                setDarshanamBooking([
                  { id: null, days: "", ppl: "", tenure: null, discount: "" },
                ]);
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
        {darshanamBooking?.map((item, i) => {
          return (
            <Row className="w-full mb-20" gutter={[20, 20]} key={i}>
              <TmsSelect
                type="col"
                label="Select Darshanam"
                mandatory={true}
                classname="w-4"
                optional={true}
                value={darshanamBooking[i]?.id}
                onChange={(e) => {
                  bookingHandle("darshanam", "id", e, i);
                }}
                data={darshanamList?.map((dar) => {
                  return { name: dar?.name, id: dar?.id };
                })}
              />
              <Col className="w-4">
                <label className="labelStyles">
                  No of Days
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter No of Days (0-9)"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={darshanamBooking[i]?.days}
                  onChange={(e) => {
                    if (e.target.value > 9) {
                      return;
                    }
                    bookingHandle("darshanam", "days", e.target.value, i);
                  }}
                />
              </Col>
              <Col className="w-4">
                <label className="labelStyles">
                  No of People
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter No of People"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={darshanamBooking[i]?.ppl}
                  onChange={(e) => {
                    if (e.target.value > 9) {
                      return;
                    }
                    bookingHandle("darshanam", "ppl", e.target.value, i);
                  }}
                />
              </Col>
              <Col className="w-4">
                <label className="labelStyles">
                  Tenure
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter tenure in months"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={darshanamBooking[i]?.tenure}
                  onChange={(e) => {
                    if (e.target.value > 120) {
                      return;
                    }
                    bookingHandle("darshanam", "tenure", e.target.value, i);
                  }}
                />
              </Col>
              <Col className="w-4">
                <label className="labelStyles">
                  Discount
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter Discount %"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={darshanamBooking[i]?.discount}
                  onChange={(e) => {
                    if (e.target.value > 100) {
                      return;
                    }
                    bookingHandle("darshanam", "discount", e.target.value, i);
                  }}
                />
              </Col>
              <Col className="buttonDiv w-4">
                <label className="labelStyles"> </label>
                <br />
                <Button
                  className="add"
                  onClick={() =>
                    darshanamBooking?.length === i + 1
                      ? addBooking("darshanam")
                      : removeBooking("darshanam", i)
                  }
                >
                  {darshanamBooking?.length === i + 1 ? "+" : "-"}
                </Button>
              </Col>
            </Row>
          );
        })}

        <Row className="headings w-full" justify="space-between">
          <Col>Seva</Col>
          <Col>
            <Button
              onClick={() => {
                setSevaBooking([
                  { id: null, days: "", tenure: null, discount: "" },
                ]);
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
        {sevaBooking?.map((item, i) => {
          return (
            <Row className="w-full mb-20" gutter={[20, 20]} key={i}>
              <TmsSelect
                type="col"
                label="Select Seva"
                mandatory={true}
                classname="w-4"
                optional={true}
                data={sevaList?.map((seva) => {
                  return {
                    name: seva?.name,
                    id: seva?.id,
                  };
                })}
                value={sevaBooking[i]?.id}
                onChange={(e) => {
                  bookingHandle("seva", "id", e, i);
                }}
              />
              <Col className="w-4">
                <label className="labelStyles">
                  No of Days
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter No of Days (0-9)"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={sevaBooking[i]?.days}
                  onChange={(e) => {
                    if (e.target.value > 9) {
                      return;
                    }
                    bookingHandle("seva", "days", e.target.value, i);
                  }}
                />
              </Col>
              <Col className="w-4">
                <label className="labelStyles">
                  Tenure
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter tenure in months"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={sevaBooking[i]?.tenure}
                  onChange={(e) => {
                    if (e.target.value > 120) {
                      return;
                    }
                    bookingHandle("seva", "tenure", e.target.value, i);
                  }}
                />
              </Col>
              <Col className="w-4">
                <label className="labelStyles">
                  Discount
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter Discount %"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={sevaBooking[i]?.discount}
                  onChange={(e) => {
                    if (e.target.value > 100) {
                      return;
                    }
                    bookingHandle("seva", "discount", e.target.value, i);
                  }}
                />
              </Col>
              <Col className="buttonDiv w-4">
                <label className="labelStyles"> </label>
                <br />
                <Button
                  className="add"
                  onClick={() =>
                    sevaBooking?.length === i + 1
                      ? addBooking("seva")
                      : removeBooking("seva", i)
                  }
                >
                  {sevaBooking?.length === i + 1 ? "+" : "-"}
                </Button>
              </Col>
            </Row>
          );
        })}

        <Row className="headings w-full" justify="space-between">
          <Col>Products</Col>
          <Col>
            <Button
              onClick={() => {
                setProductBooking([]);
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
        {productList?.filter((item) => item?.type === "GENERAL")?.length ===
        0 ? (
          <p style={{ color: "red", paddingRight: "5px" }}>
            No Products Available
          </p>
        ) : (
          <Row className="w-full" gutter={[20, 20]}>
            <Row
              className="w-full"
              gutter={[20, 20]}
              style={{ padding: "0px 15px" }}
            >
              {productList
                ?.filter((item) => item?.type === "GENERAL")
                ?.map((item, i) => {
                  return (
                    <Col key={i}>
                      <Row gutter={[10, 10]} className="w-full align-center">
                        <Col className="w-100 tmsCheckboxCss">
                          <Checkbox
                            checked={selectedProducts?.includes(item?.id)}
                            onChange={() => {
                              bookingHandle("pquota", "product", item?.id);
                            }}
                          />
                          <span
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          >
                            {item?.name}
                          </span>
                          <Input
                            maxLength={220}
                            className="w-3"
                            placeholder="Qty"
                            type="number"
                            min="0"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-", "."].includes(e.key) &&
                              e.preventDefault()
                            }
                            onWheel={(event) => event.currentTarget.blur()}
                            value={
                              productBooking[0]?.product[
                                selectedProducts?.indexOf(item?.id)
                              ]?.qty
                            }
                            onChange={(e) =>
                              qtyManagement(item?.id, e.target.value)
                            }
                            addonBefore={
                              <div
                                className="pointer"
                                onClick={() => {
                                  qtyManagement(
                                    item?.id,
                                    Number(
                                      productBooking[0]?.product[
                                        selectedProducts?.indexOf(item?.id)
                                      ]?.qty - 1
                                    )
                                  );
                                }}
                              >
                                -
                              </div>
                            }
                            addonAfter={
                              <div
                                className="pointer"
                                onClick={() =>
                                  qtyManagement(
                                    item?.id,
                                    Number(
                                      productBooking[0]?.product[
                                        selectedProducts?.indexOf(item?.id)
                                      ]?.qty + 1
                                    )
                                  )
                                }
                              >
                                +
                              </div>
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
            </Row>
            <Col className="w-4">
              <label className="labelStyles">
                Tenure
                <span className="mandatory">*</span>
              </label>
              <br />
              <Input
                maxLength={220}
                className="w-full"
                placeholder="Enter tenure in months"
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                value={productBooking[0]?.tenure}
                onChange={(e) => {
                  if (e.target.value > 120) {
                    return;
                  }
                  bookingHandle("pquota", "tenure", e.target.value);
                }}
              />
            </Col>
            <Col className="w-4">
              <label className="labelStyles">
                Discount
                <span className="mandatory">*</span>
              </label>
              <br />
              <Input
                maxLength={220}
                className="w-full"
                placeholder="Enter Discount %"
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                value={productBooking[0]?.discount}
                onChange={(e) => {
                  if (e.target.value > 100) {
                    return;
                  }
                  bookingHandle("pquota", "discount", e.target.value);
                }}
              />
            </Col>
          </Row>
        )}

        <Row className="headings w-full" justify="space-between">
          <Col>Publications</Col>
          <Col>
            <Button
              onClick={() => {
                setPublicationBooking([]);
              }}
            >
              Clear
            </Button>
          </Col>{" "}
        </Row>
        {productList?.filter((item) => item?.type === "PUBLICATION")?.length ===
        0 ? (
          <p style={{ color: "red", paddingRight: "5px" }}>
            No Publication Available
          </p>
        ) : (
          <>
            <Row
              gutter={[20, 20]}
              className="w-full align-center "
              style={{
                marginBottom: "20px",
                padding: "0px 5px",
                textAlign: "left",
              }}
            >
              {productList
                ?.filter((item) => item?.type === "PUBLICATION")
                ?.map((item, i) => {
                  return (
                    <Col key={i} className="w-4 tmsCheckboxCss">
                      <Checkbox
                        checked={publicationBooking[0]?.product?.includes(
                          item?.id
                        )}
                        onChange={() => {
                          bookingHandle("publication", "product", item?.id);
                        }}
                      />
                      <span style={{ fontSize: "20px", marginRight: "5px" }}>
                        {item?.name}
                      </span>
                    </Col>
                  );
                })}
            </Row>
            <Row className="w-full" gutter={[20, 20]}>
              <Col className="w-4">
                <label className="labelStyles">
                  Tenure
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter tenure in months"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={publicationBooking[0]?.tenure}
                  onChange={(e) => {
                    if (e.target.value > 120) {
                      return;
                    }
                    bookingHandle("publication", "tenure", e.target.value);
                  }}
                />
              </Col>
              <Col className="w-4">
                <label className="labelStyles">
                  Discount
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter Discount %"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={publicationBooking[0]?.discount}
                  onChange={(e) => {
                    if (e.target.value > 100) {
                      return;
                    }
                    bookingHandle("publication", "discount", e.target.value);
                  }}
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="headings w-full" justify="space-between">
          <Col>Bahumanam</Col>
          <Col>
            <Button
              onClick={() => {
                setBahumanamBooking([]);
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
        {productList?.filter((item) => item?.type === "BAHUMANAM")?.length ===
        0 ? (
          <p style={{ color: "red", paddingRight: "5px" }}>
            No Bahumanam Available
          </p>
        ) : (
          <>
            <Row
              gutter={[20, 20]}
              className="w-full align-center "
              style={{
                marginBottom: "20px",
                padding: "0px 5px",
                textAlign: "left",
              }}
            >
              {productList
                ?.filter((item) => item?.type === "BAHUMANAM")
                ?.map((item, i) => {
                  return (
                    <Col key={i} className="w-4 tmsCheckboxCss">
                      <Checkbox
                        checked={bahumanamBooking[0]?.product?.includes(
                          item?.id
                        )}
                        onChange={() => {
                          bookingHandle("bahumanam", "product", item?.id);
                        }}
                      />
                      <span style={{ fontSize: "20px", marginRight: "5px" }}>
                        {item?.name}
                      </span>
                    </Col>
                  );
                })}
            </Row>
            <Row className="w-full" gutter={[20, 20]}>
              <Col className="w-4">
                <label className="labelStyles">
                  Tenure
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter tenure in months"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={bahumanamBooking[0]?.tenure}
                  onChange={(e) => {
                    if (e.target.value > 120) {
                      return;
                    }
                    bookingHandle("bahumanam", "tenure", e.target.value);
                  }}
                />
              </Col>
              <Col className="w-4">
                <label className="labelStyles">
                  Discount
                  <span className="mandatory">*</span>
                </label>
                <br />
                <Input
                  maxLength={220}
                  className="w-full"
                  placeholder="Enter Discount %"
                  type="number"
                  min="0"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  value={bahumanamBooking[0]?.discount}
                  onChange={(e) => {
                    if (e.target.value > 100) {
                      return;
                    }
                    bookingHandle("bahumanam", "discount", e.target.value);
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        {edit && (
          <Row className="w-full" style={{ margin: "20px 0px" }}>
            <Col className="w-4">
              <Row className="mb-5">
                <label>
                  Change Request Document<span className="mandatory">*</span>
                </label>{" "}
              </Row>
              <TmsImageUpload
                className="w-100"
                listType="text"
                accept={true}
                fileList={fileList}
                setFileList={setFileList}
              />
            </Col>
          </Row>
        )}
      </Row>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                loading === false && handleSubmit();
              }}
              loading={loading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

export default withRouter(TmsSmDonorPCreate);
