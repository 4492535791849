import Axios from "../utils/tms-axios/tms-axios";

const TmsPrivilegesService = {
  getAllPrivileges: async (page, limit) => {
    const resp = await Axios.get(
      `v1/donor-privilege/all?page=${page}&limit=${limit}&sortOrder=ASC&sortBy=updatedAt`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getAllPrivilegesId: async (id) => {
    const resp = await Axios.get(`v1/donor-privilege/by-id/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  createPrivilege: async (body) => {
    const resp = await Axios.post(`v1/donor-privilege/create`, body, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  updatePrivilege: async (id, body) => {
    const resp = await Axios.put(`v1/donor-privilege/update/${id}`, body, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  deletePrivilege: async (id) => {
    const resp = await Axios.delete(`v1/donor-privilege/delete/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
};

export default TmsPrivilegesService;
