import Axios from "../utils/tms-axios/tms-axios";
// import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsFdrService = {
  //Institutions
  getCreatedInstitutions: async (page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/institution/get-all?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  //Account category
  getCreatedAccountCategory: async (
    institution,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/account-category/get-by-institution/${institution}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createAccountCategory: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/account-category/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateCreatedAcountCategory: async (body, landID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/account-category/update/${landID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedAcountCategory: async (landID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/account-category/delete/${landID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //Bank Accounts
  getCreatedBankAccounts: async (
    institution,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/bank-account/get-by-institution/${institution}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createBankAccounts: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/bank-account/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateCreatedBankAccount: async (body, bankAccId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/bank-account/update/${bankAccId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedBankAccount: async (bankAccId) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/bank-account/delete/${bankAccId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //Investment
  getCreatedInvestment: async (institution, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/investment-type/get-by-institution/${institution}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createInvestment: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/investment-type/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateCreatedInvestment: async (body, investmentId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/investment-type/update/${investmentId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedInvestment: async (investmentId) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/investment-type/delete/${investmentId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getFdrReports: async (
    institution,
    accountCategory,
    bankAccount,
    InvestmentType,
    maturityDate,
    page,
    limit
    // leaseStartDate,
    // leaseEndDate
  ) => {
    let url = `v1/rent-and-lease/fdr/list?page=${page}&limit=${limit}&institutionId=${institution}`;
    if (accountCategory !== "" && accountCategory) {
      url += `&accountCategoryId=${accountCategory}`;
    } else if (bankAccount !== "" && bankAccount) {
      url += `&bankAccountId=${bankAccount}`;
    } else if (InvestmentType !== "" && InvestmentType) {
      url += `&investmentTypeId=${InvestmentType}`;
    } else if (maturityDate !== "" && maturityDate) {
      url += `&maturityDate=${maturityDate}`;
    }
    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  //Arrears
  getCreatedFdr: async (institution, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/fdr/get-by-institution/${institution}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createFdr: async (body) => {
    const resp = await Axios.post(`v1/rent-and-lease/fdr/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateCreatedFdr: async (body, fdrId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/fdr/update/${fdrId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedFdr: async (fdrId) => {
    const resp = await Axios.delete(`v1/rent-and-lease/fdr/delete/${fdrId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};
export default TmsFdrService;
