import { Button, Input, notification } from "antd";
import React, { useState } from "react";
import TmsUserService from "../../services/tms-user/tms-user.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import TmsModal from "../tms-customs/tms-modal";
import TmsRowVertical from "../tms-customs/tms-row-vertical";
const TmsUMCreateUserModal = ({
  showCreateUserModal,
  setShowCreateUserModal,
  userDetails,
  router,
}) => {
  const [UserPhone, setUserPhone] = useState();
  const [UserDisplayName, setUserDisplayName] = useState();
  const [UserPass, setUserPass] = useState();
  const [loading, setLoading] = useState(false);
  const handleCreatUser = async () => {
    setLoading(true);
    try {
      await TmsUserService.adminCreateUser(
        UserPhone,
        UserDisplayName,
        UserPass,
        userDetails.user.templeId
      );
      setLoading(false);
      setShowCreateUserModal(false);
      setUserDisplayName("");
      setUserPass("");
      setUserPhone("");
      notification.success({ message: "User created Successfully" });
      // setUpdateTable(Math.random());
    } catch (error) {
      setLoading(false);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
  };
  return (
    <TmsModal
      width="45%"
      visible={showCreateUserModal}
      title="Create User"
      onOk={handleCreatUser}
      onCancel={() => {
        setShowCreateUserModal(false);
      }}
      footer={[
        <Button key="back" onClick={() => setShowCreateUserModal(false)}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            handleCreatUser();
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <TmsRowVertical
        data={{
          one: "Phone",
          two: (
            <Input
              placeholder="Phone"
              addonBefore="+91"
              style={{ width: "250px" }}
              value={UserPhone}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  return;
                }
                setUserPhone(e.target.value);
              }}
            />
          ),
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />

      <TmsRowVertical
        data={{
          one: "Display Name",
          two: (
            <Input
              maxLength={220}
              placeholder="Display Name"
              style={{ width: "250px" }}
              value={UserDisplayName}
              onChange={(e) => setUserDisplayName(e.target.value)}
            />
          ),
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />

      <TmsRowVertical
        data={{
          one: "Password",
          two: (
            <Input
              maxLength={220}
              placeholder="password"
              style={{ width: "250px" }}
              value={UserPass}
              onChange={(e) => setUserPass(e.target.value)}
            />
          ),
          justify: "center",
          style: { width: "100%", padding: "10px 0px" },
        }}
      />
    </TmsModal>
  );
};
export default withRouter(TmsUMCreateUserModal);
