import React from "react";
import useBuildingsStore from "../../../../zustand/buildings-and-shops.store";
import TmsSelect from "../../tms-select";

const BuildingBlockNoSelect = ({ span, disabled }) => {
  const { setSelectedBlock, selectedBlock, createdBlocksData, clearStates } =
    useBuildingsStore((state) => {
      return {
        setSelectedBlock: state.setSelectedBlock,
        selectedBlock: state.selectedBlock,
        createdBlocksData: state.createdBlocksData,
        clearStates: state.clearStates,
      };
    });

  return (
    <TmsSelect
      disabled={disabled ? true : false}
      type="col"
      span={span}
      label="Select Block"
      setValue={(e) => {
        setSelectedBlock(
          createdBlocksData?.items?.find((item) => item.id === e)
        );
        clearStates(
          [
            "createdShopData",
            "selectedShop",
            "selectedBuildingLease",
            "createdBuildingLeaseData",
            "numberOfShops",
          ],
          []
        );
      }}
      selectClass="w-100"
      value={selectedBlock?.id}
      data={createdBlocksData?.items?.map((item) => {
        return { name: `Block.Name:${item.blockOrFloorName} `, id: item.id };
      })}
      optional={true}
    />
  );
};

export default BuildingBlockNoSelect;
