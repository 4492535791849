import Axios from "../../utils/tms-axios/tms-axios";
import ReportsAxios from "../../utils/tms-axios/tms-reports-axios";

const TmsUserService = {
  getUsers: async () => {
    const resp = await Axios.get(`v1/user-permission/get-users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getUsersStats: async (userId) => {
    const resp = await ReportsAxios.get(
      `v1/dashboard/user/booking-payment-status-count/admin/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getUsersPaymentStats: async (userId) => {
    const resp = await ReportsAxios.get(
      `v1/dashboard/user/booking-service-count/admin/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  manageUserRole: async (role, phone) => {
    phone = "+91" + phone;
    const resp = await Axios.post(
      `v1/user/managment/user/role`,
      {
        role,
        phone,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getUserPermissionsById: async (userId) => {
    const resp = await Axios.get(`v1/user-permission/by-admin/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  updatePermissions: async (userRolesData, userIdorPhone, isPhone) => {
    const body = { permissions: userRolesData };
    if (isPhone) {
      body.phone = userIdorPhone;
    } else {
      body.userId = userIdorPhone;
    }
    const resp = await Axios.post(`v1/user-permission/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  transferUser: async (templeID, userId, permissions) => {
    const resp = await Axios.patch(
      `v1/user-permission/transfer?transferToTempleId=${templeID}&permissions=${permissions}&userId=${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  userDetails: async (queryBy, value) => {
    const params = {};
    params[queryBy] = value;
    const resp = await Axios.get(`v1/user/admin/user/details?`, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  adminCreateUser: async (phone, displayName, password, templeId) => {
    phone = "+91" + phone;
    const resp = await Axios.post(
      `v1/user/admin/create`,
      {
        phone,
        displayName,
        password,
        templeId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  userOrderStats: async (
    userId,
    page,
    limit,
    paymentStatus,
    upcoming,
    templeID
  ) => {
    let url = `v1/dashboard/user/admin/${userId}?page=${page}&limit=${limit}&paymentStatus=${paymentStatus}&filter=${upcoming}`;
    if (templeID && templeID !== "") {
      url += `&templeId=${templeID}`;
    }
    const resp = await ReportsAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  userPaymentStats: async (userId) => {
    const resp = await Axios.get(`v1/user-report/payments?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  userAuthLogs: async (phone, page, pageSize) => {
    const resp = await ReportsAxios.get(`v1/user-report/logs/auth/${phone}`, {
      params: {
        page,
        limit: pageSize,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  userActionLogs: async (userId, page, pageSize) => {
    const resp = await ReportsAxios.get(
      `v1/user-report/user-actions/${userId}?page=${page}&limit=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  userPermissions: async () => {
    const resp = await Axios.get(
      `v1/user-permission/get-available-permissions`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteUser: async (userId) => {
    const resp = await Axios.delete(`v1/user-permission/delete/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  forgotPassword: async (phone, otp, password) => {
    phone = "+91" + phone;
    const resp = await Axios.post(
      `v1/user/password/forgot`,
      {
        otp: Number(otp),
        phone: phone,
        password: password,
        templeId: 1,
      },
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  checkUserRegistered: async (phone) => {
    const res = Axios.get(`v1/user/is-registered?phone=%2B91${phone}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId") ?? 1,
      },
    });
    return res;
  },
  verifyEmail: async (email) => {
    const resp = await Axios.post(
      `v1/mail/send-verification-mail?email=${email}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": process.env.REACT_APP_TEMPLE ?? "1",
        },
      }
    );
    return resp.data;
  },
  getEmailStatus: async () => {
    const resp = await Axios.get(`v1/mail/last-verify-log`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": process.env.REACT_APP_TEMPLE ?? "1",
      },
    });
    return resp.data;
  },
};

export default TmsUserService;
