import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
// import { BaseContext } from "../../../../../contexts/BaseContext";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Col, Row, Select, Switch, Drawer, Button, Checkbox } from "antd";
import TmsSevasService from "../../../../../services/tms-sevas.service";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmsSevasInnerPagesANS from "./tms-sevas-inner-pages-and";
import TmSevasinnerActions from "../../../../../components/tms-service-management/tms-sm-sevas/tms-sevas-inner-pages-actions";
import TextArea from "antd/lib/input/TextArea";

const TmsSevasInnerPagesAll = ({ router, sevaType, setSevaType }) => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [miniTicket, setMiniTicket] = useState(false);
  const [sevasData, setSevasData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewSevaData, setViewSevaData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [termsData, setTermsData] = useState([]);
  const [onlinetermsData, setOnlineTermsData] = useState([]);

  const tableColums = [
    ["Name", viewSevaData?.name],
    ["Description", viewSevaData?.description],
    ["No of People allowed", viewSevaData?.noOfPeople],
    ["Max POS Bookings", viewSevaData?.maxCounterBookings],
    ["Max Online Bookings", viewSevaData?.maxOnlineBookings],
    ["Min Online (Hours)", Number(viewSevaData?.minOnlineHours)],
    ["Max Online (Hours)", Number(viewSevaData?.maxOnlineHours)],
    ["Min POS (Hours)", viewSevaData?.minCounterHours],
    ["Max POS (Hours)", viewSevaData?.maxCounterHours],
    ["POS-(Status)", viewSevaData?.counterEnabled ? "enabled" : "disabled"],
    ["Online-(Status)", viewSevaData?.onlineEnabled ? "enabled" : "disabled"],
    ["Price", viewSevaData?.price],
  ];

  const switchTrigger = async (key) => {
    setLoading(true);
    try {
      const darData = await TmsSevasService.viewSeva(key);
      setViewSevaData(darData);
      settmsModalVisible(true);
      setEditFlag(true);
      setProductId(key);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    // try {
    //   const darData = await TmsSevasService.viewSeva(key);
    //   let body = {
    //     sevaType: darData.sevaType,
    //     maxCounterBookings: darData?.maxCounterBookings,
    //     maxOnlineBookings: darData?.maxOnlineBookings,
    //     minOnlineHours: darData?.minOnlineHours / 24,
    //     maxOnlineHours: darData?.maxOnlineHours / 24,
    //     minCounterHours: darData?.minCounterHours,
    //     maxCounterHours: darData?.maxCounterHours,
    //     sevasName: darData?.name,
    //     description: darData?.description,
    //     counterEnabled: from === "counter" ? status : darData?.counterEnabled,
    //     onlineEnabled: from === "online" ? status : darData?.onlineEnabled,
    //     price: darData?.price,
    //     imageUrl: darData?.imageUrl,
    //     noOfPeople: darData?.noOfPeople,
    //   };
    //   await TmsSevasService.editSeva(key, body);
    //   setUpdateFlag(Math.random() + 1);
    // } catch (error) {
    //   catchErrorFunction(
    //     error,
    //     "Token Expired, redirecting to login page in 5 seconds",
    //     router
    //   );
    // }
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    router.location?.pathname === "/service-management/sevas" && getSevas();
    if (!editFlag) {
      settmsModalVisible(false);
    }
  }, [
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    sevaType,
    router.location?.pathname,
  ]);

  const getSevas = async () => {
    setLoading(true);
    try {
      let data = [];
      setSevasData([]);
      if (
        ["parokshaSeva", "pratyakshaSeva", "saswataSeva"]?.includes(sevaType)
      ) {
        data = await TmsSevasService.getSevasByType(
          sevaType,
          tableCurrentPage,
          tablePageSize
        );
      } else {
        data = await TmsSevasService.getSevas(tableCurrentPage, tablePageSize);
      }
      setTabelTotalSize(data?.meta?.totalItems);
      setSevasData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Seva Name",
      dataIndex: "name",
      key: "name",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price > b.price,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },
    {
      title: "People Allowed",
      dataIndex: "noOfPeople",
      key: "noOfPeople",
    },
    {
      title: "POS",
      children: [
        {
          title: "Booking",
          dataIndex: "maxCounterBookings",
          key: "maxCounterBookings",
          sorter: (a, b) => a.maxCounterBookings > b.maxCounterBookings,
        },
        {
          title: "Status",
          dataIndex: "counterEnabled",
          key: "counterEnabled",
          render: (text, record) => {
            return (
              <Switch
                size={"small"}
                checked={text}
                style={{ background: text ? "#4CBB17" : "EFEFEF" }}
                onClick={() => {
                  switchTrigger(record?.key, !text, "counter");
                }}
              />
            );
          },
        },
      ],
    },
    {
      title: "Online",
      children: [
        {
          title: "Booking",
          dataIndex: "maxOnlineBookings",
          key: "maxOnlineBookings",
        },
        {
          title: "Min (Hours)",
          dataIndex: "minOnlineHours",
          key: "minOnlineHours",
        },
        {
          title: "Max (Hours)",
          dataIndex: "maxOnlineHours",
          key: "maxOnlineHours",
        },
        {
          title: "Status",
          dataIndex: "onlineEnabled",
          key: "onlineEnabled",
          render: (text, record) => {
            return (
              <Switch
                size={"small"}
                checked={text}
                style={{
                  background: text ? "#4CBB17 " : "EFEFEF ",
                }}
                onClick={() => {
                  switchTrigger(record?.key, !text, "online");
                }}
              />
            );
          },
        },
      ],
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <TmSevasinnerActions
            record={record}
            setLoading={setLoading}
            setViewSevaData={setViewSevaData}
            settmsModalVisible={settmsModalVisible}
            setUpdateFlag={setUpdateFlag}
            setEditFlag={setEditFlag}
            setProductId={setProductId}
            setShowDrawer={setShowDrawer}
            setTermsData={setTermsData}
            setOnlineTermsData={setOnlineTermsData}
            setMiniTicket={setMiniTicket}
          />
        );
      },
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: "10px" }}>
        <Select
          style={{
            minWidth: "150px",
            margin: "10px 10px 0px 0px",
            maxHeight: "32px",
          }}
          onChange={(e) => {
            setSevaType(e);
          }}
          defaultValue={sevaType}
          value={sevaType}
        >
          <Select.Option value="parokshaSeva">Paroksha Seva</Select.Option>
          <Select.Option value="pratyakshaSeva">Pratyaksha Seva</Select.Option>
          <Select.Option value="saswataSeva">Saswata Seva</Select.Option>{" "}
          <Select.Option value="All">All</Select.Option>
        </Select>
      </Row>
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={sevasData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <Drawer
        className="DetailsModal"
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewSevaData(null);
        }}
        title={editFlag ? "Edit Seva" : "Seva Details"}
      >
        {editFlag ? (
          <TmsSevasInnerPagesANS
            viewSevasData={viewSevaData}
            editFlag={editFlag}
            productId={productId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
          />
        ) : (
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
            <br />
            <Col className="ImgCol">
              <img src={viewSevaData?.imageUrl} alt="Image" />
            </Col>
          </div>
        )}
      </Drawer>
      <Drawer
        className="TermsDrawer"
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setTermsData([]);
          setOnlineTermsData([]);
        }}
        title="Terms & Conditions"
        extra={
          <Button
            type="primary"
            onClick={async () => {
              setLoading(true);
              try {
                await TmsSevasService.sevaTerms(
                  productId,
                  termsData?.toString()?.replaceAll(",", "\n")?.split("\n"),
                  onlinetermsData
                    ?.toString()
                    ?.replaceAll(",", "\n")
                    ?.split("\n"),
                  miniTicket
                );
                setUpdateFlag(Math.random() + 1);
                setShowDrawer(false);
                setTermsData([]);
                setOnlineTermsData([]);
                setMiniTicket(false);
              } catch (error) {
                catchErrorFunction(
                  error,
                  "Token Expired, redirecting to login page in 5 seconds",
                  router
                );
              }
              setLoading(false);
            }}
          >
            Submit
          </Button>
        }
      >
        POS{" "}
        <TextArea
          maxLength={2200}
          rows={5}
          value={termsData?.toString()?.replaceAll(",", "\n")}
          onChange={(e) => {
            setTermsData(e.target.value);
          }}
        />
        <br />
        <br />
        Online
        <TextArea
          maxLength={2200}
          rows={5}
          value={onlinetermsData?.toString()?.replaceAll(",", "\n")}
          onChange={(e) => {
            setOnlineTermsData(e.target.value);
          }}
        />
        <Row className="formRow" style={{ width: "100%", paddingTop: "10px" }}>
          <Checkbox
            style={{ paddingRight: "10px" }}
            checked={miniTicket}
            onChange={(e) => setMiniTicket(e.target.checked)}
          />
          <label>Mini ticket</label>
        </Row>
      </Drawer>
    </>
  );
};

export default withRouter(TmsSevasInnerPagesAll);
