import { Col, Popconfirm, Row, Space, Switch, Table } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import { createShopStates } from "../../../../../../constants/states-data.data";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import useBuildingsStore from "../../../../../../zustand/buildings-and-shops.store";
import {
  BuildingInstitutionSelect,
  BuildingSelect,
  SortTypeAndBy,
} from "../../../../../../components/tms-customs/rents-and-leases";
import { DeleteOutlined } from "@ant-design/icons";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";

const TmsBasCreatedShopsList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");

  const {
    deleteLoading,
    deleteShop,
    selectedInstitution,
    loading,
    createdShopData,
    fetchCreatedBuildingsList,
    fetchInstitutionList,
    fetchShopsByBuilding,
    selectedBuilding,
    clearStates,
    handleChangeStatus,
    excelLoading,
    handleExcelExport,
  } = useBuildingsStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  useEffect(() => {
    return () => {
      clearStates(createShopStates, [
        "institutionData",
        "createdBuildingsData",
        "selectedBuilding",
        "createdBlocksData",
        "selectedBlock",
        "createdShopData",
      ]);
    };
  }, []);
  useEffect(() => {
    router.location.pathname === tmsRouteMap.sm_ral_buildings_shops_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedBuildingsList(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  useEffect(() => {
    if (selectedBuilding && selectedBuilding !== "")
      fetchShopsByBuilding(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedBuilding,
    update,
  ]);

  const switchTrigger = async (record, status) => {
    await handleChangeStatus(record, status, router);
    setUpdate(Math.random() * 100000 + 21);
  };
  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <BuildingInstitutionSelect span={5} />
        <BuildingSelect span={5} />
        <SortTypeAndBy
          span={5}
          sortBy={sortBy}
          sortType={sortType}
          setSortBy={setSortBy}
          setSortType={setSortType}
        />{" "}
        {selectedBuilding !== "" && selectedBuilding && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() => handleExcelExport("shop", router)}
              top={false}
            />
          </Col>
        )}
      </Row>

      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        loading={loading}
        style={{
          width: "auto",
          textTransform: "capitalize",
          textAlign: "center !important",
        }}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          { title: "Shop Number", dataIndex: "shopNumber" },
          {
            title: "Building Name",
            dataIndex: "blockOrFloor",
            render: (record) => record?.building?.buildingName,
          },
          { title: "Property Type", dataIndex: "propertyType" },
          {
            title: "Block/Floor",
            dataIndex: "blockOrFloor",
            render: (record) => record?.blockOrFloorName,
          },
          {
            title: "Extent Area",
            children: [
              {
                title: "Length",
                dataIndex: "shopExtentLength",
                key: "shopExtentLength",
                align: "center",
              },
              {
                title: "Breadth",
                dataIndex: "shopExtentBreadth",
                key: "shopExtentBreadth",
                align: "center",
              },
            ],
          },

          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (record) => moment(record).format("DD-MM-YYYY hh:mm A"),
          },
          {
            title: "Status",
            key: "status",
            render: (record) => {
              return (
                <Switch
                  size={"small"}
                  checked={record.status}
                  onClick={() => {
                    switchTrigger(record?.id, !record.status);
                  }}
                />
              );
            },
          },
          {
            title: "Action",
            render: (record) => {
              return (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure delete this institution?"
                    onConfirm={async () => {
                      await deleteShop(record.id, router);
                      setUpdate(Math.random() * 100000 + 21);
                    }}
                    okText="Yes"
                    cancelText="No"
                    loading={deleteLoading}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Space>
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={createdShopData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdShopData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsBasCreatedShopsList);
