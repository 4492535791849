import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportDarshanamReport from "./tms-report-darshanam-report";
import TmsReportDarshanamSummaryReport from "./tms-report-darshanam-summary-report";
const TmsReportDarshanam = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.reports_darshanam) {
      setActive("Darshanam Report");
      return;
    } else if (
      location.includes(tmsRouteMap.reports_darshanam_summary_report)
    ) {
      setActive("Summary Report");
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Darshanam Report",
      Element: <TmsReportDarshanamReport />,
      key: "Darshanam Report",
      route: tmsRouteMap.reports_darshanam,
    },

    {
      name: "Summary Report",
      Element: <TmsReportDarshanamSummaryReport />,
      key: "Summary Report",
      route: tmsRouteMap.reports_darshanam_summary_report,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportDarshanam);
