import { Checkbox, Col, Row } from "antd";
import React, { memo } from "react";
const TmsCheckBoxList = ({
  data,
  selectedData,
  name,
  updateItems,
  gutter,
  style,
  auto,
  userDetails,
}) => {
  return (
    <>
      <Row justify="start">{name}</Row>
      {data ? (
        <Row gutter={gutter} justify="start" style={style}>
          {data?.map((item, i) => {
            if (
              item === "SUPER_DASHBOARD_READ" &&
              !userDetails?.permissions.includes("SUPER_ADMIN")
            ) {
              return;
            }
            return (
              <Col
                key={"role-dataa" + i}
                style={{
                  width: auto ? "auto" : "25%",
                  textTransform: "lowercase",
                  whiteSpace: "nowrap",
                }}
              >
                <Checkbox
                  checked={selectedData?.includes(item)}
                  onChange={() => {
                    if (
                      item === "ADMIN" &&
                      !userDetails?.permissions.includes("SUPER_ADMIN")
                    ) {
                      return;
                    }
                    updateItems(item);
                  }}
                >
                  {item?.replaceAll("_", " ")}
                </Checkbox>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Row>fetching Checkbox data ....</Row>
      )}
    </>
  );
};
export default memo(TmsCheckBoxList);
