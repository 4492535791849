import Axios from "../utils/tms-axios/tms-axios";
// import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsLicenseService = {
  //Institutions
  getCreatedInstitutions: async (page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/institution/get-all?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  //Created
  getCreatedLicenses: async (institution, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/license/get-by-institution/${institution}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createLicense: async (body) => {
    const resp = await Axios.post(`v1/rent-and-lease/license/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateCreatedLicense: async (body, landID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/license/update/${landID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedLicense: async (landID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/license/delete/${landID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getCreatedLicenseReports: async (institution, page, limit) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/reports/${institution}/license?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getCreatedReportsByType: async (type, page, limit) => {
    let url = `v1/rent-and-lease/license/reports/totalReports?reportType=${type}&page=${page}&limit=${limit}`;
    type;
    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  licenseLeaseChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/license-lease/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getCreatedLicenseLeases: async (
    institution,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/license-lease/get-by-institution/${institution}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createLicenseLeases: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/license-lease/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateCreatedLicenseLease: async (body, licenseLeaseID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/license-lease/update/${licenseLeaseID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedLicenseLease: async (landLeaseID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/license-lease/delete/${landLeaseID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  getLicenseLeaseInvoices: async (
    licenseLeaseId,
    latest,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/license-lease/invoice/${licenseLeaseId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}&latestRecord=${latest}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getInvoiceByInvoiceID: async (invoiceID) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/license-lease/get-by-invoice/${invoiceID}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getInvoiceAcknowledgement: async (ackID) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/license-lease-transaction/get-by-acknowledgement/${ackID}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  handleInvoicePayment: async (invoiceID, body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/license-lease-transaction/${invoiceID}/payment`,
      body,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  handleInvoiceAcknowledgement: async (ackID, body) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/license-lease-transaction/${ackID}/updatePayment`,
      body,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  getLeaseReports: async (
    institution,
    page,
    limit,
    leaseStartDate,
    leaseEndDate
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/license/reports/${institution}/licenseLeaseList?page=${page}&limit=${limit}&institutionId=${institution}&leaseStartDate=${leaseStartDate}&leaseEndDate=${leaseEndDate}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  //Arrears

  getCreatedArrears: async (institution, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/licenseArrear/get-by-institution/${institution}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getCreatedArrearsByLease: async (leaseId, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/licenseArrear/get-by-licenseLease/${leaseId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createArrear: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/licenseArrear/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateCreatedArrears: async (body, arreaId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/licenseArrear/update/${arreaId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateArrearBill: async (body, arreaId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/licenseArrear/billAdjustment/${arreaId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedArrear: async (arrearID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/license/delete/${arrearID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getArrearsReports: async (
    institution,
    page,
    limit
    // leaseStartDate,
    // leaseEndDate
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/license/reports/${institution}/arrearList?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
};
export default TmsLicenseService;
