import { Card, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  MailOutlined,
  PhoneOutlined,
  CalendarOutlined,
  HomeOutlined,
  LoginOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import moment from "moment";
import { tms_profile_pic_holder } from "../../assets/images/tms-image-list";

const TmsUserCard = ({ userLoading, data }) => {
  const [userAddress, setUserAddress] = useState("");

  useEffect(() => {
    const addressArr = [];
    data?.address1 && addressArr.push(data?.address1);
    data?.address2 && addressArr.push(data?.address2);
    data?.city && addressArr.push(data?.city);
    data?.state && addressArr.push(data?.state);
    data?.country && addressArr.push(data?.country);
    data?.pincode && addressArr.push(data?.pincode);
    setUserAddress(addressArr.join(", "));
  }, [data]);

  return (
    <Row>
      <Col span={24}>
        <Card style={{ marginBottom: 24, width: "100%" }} loading={userLoading}>
          {data ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: "fit-content",
                  float: "left",
                  marginRight: "30px",
                }}
              >
                <img
                  src={data?.profilepic ?? tms_profile_pic_holder}
                  alt="profile"
                  style={{
                    height: "180px",
                    width: "180px",
                  }}
                ></img>
              </div>
              <div>
                <div style={{ textAlign: "left" }}>
                  <p>
                    Display Name: <b>{data?.displayName}</b>
                    {data?.registered ? (
                      <CheckCircleTwoTone
                        twoToneColor="#52c41a"
                        className="ml-10"
                      />
                    ) : (
                      <CloseCircleTwoTone
                        twoToneColor="#eb2f96"
                        className="ml-10"
                      />
                    )}
                  </p>
                </div>
                <div style={{ width: "fit-content", marginRight: "30px" }}>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <PhoneOutlined />
                    </Col>
                    <Col>
                      <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        copyable={data?.phone ? true : false}
                      >
                        {data?.phone ? data?.phone : "-"}
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <MailOutlined />
                    </Col>
                    <Col>
                      <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        copyable={data.email ? true : false}
                      >
                        {data?.email ? data.email : "-"}
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <CalendarOutlined />
                    </Col>
                    <Col>{data?.dateOfBirth ? data?.dateOfBirth : "-"}</Col>
                  </Row>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <HomeOutlined />
                    </Col>
                    <Col>{userAddress ? userAddress : "-"}</Col>
                  </Row>
                </div>
                <div>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <LoginOutlined />
                    </Col>
                    <Col>
                      {data?.lastLogoutAt &&
                        moment(data?.lastLogoutAt)
                          .utcOffset("+5:30")
                          .format("YYYY-MM-DD hh:mm A")}
                    </Col>
                  </Row>
                  <Row gutter={[10, 10]}>
                    <Col>Created On:</Col>
                    <Col>
                      {data?.createdAt &&
                        moment(data?.createdAt)
                          .utcOffset("+5:30")
                          .format("YYYY-MM-DD hh:mm A")}
                    </Col>
                  </Row>
                  <Row gutter={[10, 10]}>
                    <Col>Updated On:</Col>
                    <Col>
                      {data?.updatedAt &&
                        moment(data?.updatedAt)
                          .utcOffset("+5:30")
                          .format("YYYY-MM-DD hh:mm A")}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          ) : (
            "loading..."
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default TmsUserCard;
