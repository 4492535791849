import { Col, Row, Space, Table } from "antd";
import { React } from "react";
import moment from "moment";
import "../../../assets/css/tms-accommodation/tms-accommodation-dashboard.scss";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import { FormatNumberINRCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
// import { BaseContext } from "../../../contexts/BaseContext";
const TmsAccommodationCheckoutTable = ({
  list,
  loading,
  tableCurrentPage,
  tablePageSize,
  handleTableChange,
  checkOutRenewSubmit,
}) => {
  // const { userDetails } = useContext(BaseContext);
  // const [searchText, setSearchText] = useState("");
  // const [searchedColumn, setSearchedColumn] = useState("");
  // const [data, setData] = useState([]);

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };

  // const handleReset = (clearFilters) => {
  //   clearFilters();
  //   setSearchText("");
  // };

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         // ref={node => {
  //         //   this.searchInput = node;
  //         // }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ width: 188, marginBottom: 8, display: "block" }}
  //       />
  //       <Button
  //         type="primary"
  //         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         size="small"
  //         style={{ width: 90, marginRight: 8 }}
  //       >
  //         Search
  //       </Button>
  //       <Button
  //         onClick={() => handleReset(clearFilters)}
  //         size="small"
  //         style={{ width: 90 }}
  //       >
  //         Reset
  //       </Button>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       // setTimeout(() => this.searchInput.select());
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text.toString()}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  const columns = [
    {
      title: "Block Name",
      dataIndex: "blockName",
      key: "blockName",
    },
    {
      title: "Room No",
      dataIndex: "roomNumber",
      key: "roomNumber",
    },
    {
      title: "Room Price",
      dataIndex: "blockCategoryPrice",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Room Type",
      dataIndex: "roomType",
      key: "roomType",
      style: "width:100px",
    },

    {
      title: "Name",
      dataIndex: "devoteeName",
      key: "devoteeName",
    },
    {
      title: "Contact",
      dataIndex: "devoteePhone",
      key: "devoteePhone",
    },

    {
      title: "Check In Time",
      dataIndex: "checkinDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY - ( hh:mm A )");
      },
    },
    {
      title: "Checkout Time",
      dataIndex: "checkoutDate",
      render: (text) => {
        return text
          ? moment(text).format("DD-MM-YYYY - ( hh:mm A )")
          : " Not yet";
      },
    },
    {
      title: "Extra Hours",
      dataIndex: "extraHours",
      key: "extraHours",
    },
    {
      title: "Options",
      dataIndex: "checkoutDate",
      key: "checkoutDate",
      render: (text, record) => {
        if (text) {
          return moment(text).format(`DD-MM-YYYY - hh:mm A `);
        } else
          return (
            <>
              {record.extraHours <= 0 ? (
                <Space size="middle">
                  <a
                    className="optioncss"
                    onClick={() =>
                      checkOutRenewSubmit(record?.roomBookingId, "checkOut")
                    }
                  >
                    Force Check Out
                  </a>
                  {record?.roomType !== "KALYANA_MANDAPAM" && (
                    <a
                      className="optioncss"
                      onClick={() =>
                        checkOutRenewSubmit(record?.roomBookingId, "renew")
                      }
                    >
                      Renew
                    </a>
                  )}
                </Space>
              ) : record.extraHours > 0 ? (
                <Space size="middle">
                  <a
                    className="optioncss"
                    onClick={() =>
                      checkOutRenewSubmit(record?.roomBookingId, "checkOut")
                    }
                  >
                    Check Out
                  </a>
                  {record?.roomType !== "KALYANA_MANDAPAM" && (
                    <a
                      className="optioncss"
                      onClick={() =>
                        checkOutRenewSubmit(record?.roomBookingId, "renew")
                      }
                    >
                      Renew
                    </a>
                  )}
                </Space>
              ) : (
                ""
              )}
            </>
          );
      },
    },
  ];
  return (
    <Table
      tableLayout="fixed"
      scroll={{ x: true }}
      loading={loading}
      style={{
        width: "100%",
        textTransform: "capitalize",
        border: "1px solid #cbcbcb",
        borderCollapse: "collapse",
      }}
      className="checkout-table"
      size="medium"
      rowClassName={(record) =>
        record.extraHours > 0 && record.extraHours <= 1
          ? "sd-light-orange"
          : record.extraHours > 1 && record.extraHours <= 2
          ? "sd-color-orange"
          : record.extraHours > 2 && record.extraHours < 24
          ? "sd-color-red"
          : record.extraHours >= 24
          ? "sd-dark-red"
          : ""
      }
      columns={columns}
      dataSource={list}
      pagination={{
        current: tableCurrentPage,
        pageSize: tablePageSize,
      }}
      onChange={handleTableChange}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Row style={{ paddingLeft: "60px" }}>
              <Col span={6}>
                Check Out :
                <p style={{ fontWeight: "bolder", marginRight: "20px" }}>
                  {record?.checkoutDate
                    ? moment(record?.checkoutDate).format(
                        "DD-MM-YYYY - hh:mm A"
                      )
                    : "Not Yet"}
                </p>
              </Col>

              <Col span={6}>
                No of Persons:
                <p style={{ fontWeight: "bolder", marginRight: "20px" }}>
                  {record?.noOfPeople}
                </p>
              </Col>
            </Row>
          </>
        ),
      }}
    />
  );
};

export default withRouter(TmsAccommodationCheckoutTable);
