import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
// import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsPublicationService = {
  getPublications: async (page, limit) => {
    const resp = await Axios.get(
      `v1/publication/get-all?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  createPublication: async (body) => {
    const resp = await Axios.post(`v1/publication/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deletePublication: async (productId) => {
    const resp = await Axios.delete(`v1/publication/delete/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewPublication: async (productId) => {
    const resp = await Axios.get(`v1/publication/by-id/${productId}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  editPublication: async (productId, body) => {
    const resp = await Axios.put(`v1/publication/update/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  //product-counters
  getPublicationCounters: async (id) => {
    const resp = await Axios.get(`v1/publication-counter/by-product/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  createPublicationCounters: async (body) => {
    const resp = await Axios.post(`v1/publication-counter/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editPublicationCounters: async (body, slotId) => {
    const resp = await Axios.put(
      `v1/publication-counter/update/${slotId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deletePublicationCounters: async (slotId) => {
    const resp = await Axios.delete(`v1/publication-counter/delete/${slotId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  updateTerms: async (productId, termsData, onlineTerms) => {
    const resp = await Axios.patch(
      `v1/shop-product/metadata/${productId}`,
      {
        termsAndConditions: termsData,
        onlineTermsAndConditions: onlineTerms,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //products booking

  fetchValidProducts: async (type) => {
    let url = `v1/publication-counter-booking/valid-products?type=${type}`;

    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  publicationBooking: async (body, payment, cashReference, cashRemarks) => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        publicationBooking: body,
        paymentType: payment,
        cashRemarks: cashReference,
        cashReference: cashRemarks,
        multiTicketsLinked: true,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //product - publication API's
  getPublicationsFiles: async (productId, page, limit) => {
    const resp = await Axios.get(
      `v1/publication-subscription-type/all/${productId}?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  createPublicationFile: async (body) => {
    const resp = await Axios.post(
      `v1/publication-subscription-type/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deletePublicationFile: async (productId) => {
    const resp = await Axios.delete(
      `v1/publication-subscription-type/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  viewPublicationFile: async (productId) => {
    const resp = await Axios.get(
      `v1/publication-subscription-type/by-id/${productId}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },

  editPublicationFile: async (productId, body) => {
    const resp = await Axios.put(
      `v1/publication-subscription-type/edit/${productId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  publicationReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    sortType,
    counter,
    shift,
    publicationId,
  }) => {
    let url = `v1/publication-counter-report/booking?format=${format}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&order=${sortType}`;
    if (publicationId && publicationId !== "") {
      url += `&productId=${Number(publicationId)}`;
    }
    if (counter && counter !== "") {
      url += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      url += `&counterShiftTimingId=${shift}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  publicationOnlineReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    sortType,
    selectedDonationId,
    paymentStatus,
  }) => {
    let url = `v1/publication-online-report/booking?format=${format}&paymentStatus=${paymentStatus}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&order=${sortType}`;
    if (selectedDonationId && selectedDonationId !== "") {
      url += `&publicationId=${Number(selectedDonationId)}`;
    }

    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/publication-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&publicationId=${productId}`;
    }
    if (counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  getOnlineConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    groupBy,
  }) => {
    let query = `v1/publication-online-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&publicationId=${productId}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  //   donationBookingDetails: async (
  //     format,
  //     fromDate,
  //     toDate,
  //     dateType,
  //     sortType,
  //     page,
  //     limit,
  //     selectedDonationId
  //   ) => {
  //     let url = `v1/donation-counter-booking/reports?format=${format}&page=${page}&limit=${limit}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;
  //     if (selectedDonationId && selectedDonationId !== "") {
  //       url += `&productId=${Number(selectedDonationId)}`;
  //     }
  //     const resp = await Axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     });
  //     return resp.data;
  //   },

  //   donationReport: async (
  //     format,
  //     page,
  //     limit,
  //     fromDate,
  //     toDate,
  //     sortType,
  //     counter,
  //     shift,
  //     selectedDonationId
  //   ) => {
  //     let url = `v1/donation-counter-report/booking?format=${format}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${page}&limit=${limit}&order=${sortType}`;
  //     if (selectedDonationId && selectedDonationId !== "") {
  //       url += `&productId=${Number(selectedDonationId)}`;
  //     }
  //     if (counter && counter !== "") {
  //       url += `&counterId=${counter}`;
  //     }
  //     if (shift && shift !== "") {
  //       url += `&counterShiftTimingId=${shift}`;
  //     }
  //     const resp = await Axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     });
  //     return resp.data;
  //   },
  //   donationOnlineReport: async (
  //     format,
  //     page,
  //     limit,
  //     fromDate,
  //     toDate,
  //     sortType,
  //     counter,
  //     shift,
  //     selectedDonationId
  //   ) => {
  //     let url = `v1/donation-online-report/booking?format=${format}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${page}&limit=${limit}&order=${sortType}`;
  //     if (selectedDonationId && selectedDonationId !== "") {
  //       url += `&productId=${Number(selectedDonationId)}`;
  //     }
  //     // if (counter && counter !== "") {
  //     //   url += `&counterId=${counter}`;
  //     // }
  //     // if (shift && shift !== "") {
  //     //   url += `&counterShiftTimingId=${shift}`;
  //     // }
  //     const resp = await Axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     });
  //     return resp.data;
  //   },

  //   getConsolidateReport: async (
  //     format,
  //     productId,
  //     from,
  //     to,

  //     counter,
  //     shift
  //   ) => {
  //     let query = `v1/donation-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

  //     if (productId !== "") {
  //       query += `&productId=${productId}`;
  //     }
  //     if (counter !== "") {
  //       query += `&counterId=${counter}`;
  //     }
  //     if (shift !== "") {
  //       query += `&counterShiftTimingId=${shift}`;
  //     }
  //     let res = await Axios.get(query, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     });
  //     return res.data;
  //   },
  //   getOnlineConsolidateReport: async (format, productId, from, to) => {
  //     let query = `v1/donation-online-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

  //     if (productId !== "") {
  //       query += `&productId=${productId}`;
  //     }
  //     let res = await Axios.get(query, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     });
  //     return res.data;
  //   },
};

export default TmsPublicationService;
