import tmsRouteMap from "./tms-route.map";
import {
  busLogo,
  rentsLogo,
  tms_d_products,
  tms_icon_accommodation,
  tms_icon_check_in_scan,
  tms_icon_darshanam,
  tms_icon_donation,
  tms_icon_kalyanakatta,
  tms_icon_performance_scan,
  tms_icon_prasadam,
  tms_icon_prasadam_scanner,
  tms_icon_pratyaksha_Seva,
  tms_icon_protocol,
  tms_icon_protocol_dashboard,
  tms_icon_protocol_print,
  tms_icon_publication,
  tms_icon_thulabharam,
  tms_icon_ticket_details,
  tms_icon_tollgate,
} from "../assets/images/tms-image-list";

const TmsPosMap = [
  {
    title: "Check In Scan",
    hidden: "",
    route: tmsRouteMap.pos_check_in_scan,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "KALYANAKATTA_TICKET_SCAN",
      "TOLLGATE_TICKET_SCAN",
      "DARSHANAM_TICKET_SCAN",
      "SEVA_TICKET_PERFORMANCE_SCAN",
      "SEVA_TICKET_SCAN",
      "SHOP_PRODUCT_TICKET_SCAN",
    ],
    color: "#EEEBFF",
    logo: tms_icon_check_in_scan,
    textColor: "#573BFF",
  },
  {
    title: "Pre Assigned Scan",
    hidden: "",
    route: tmsRouteMap.pos_pre_assigned_check_in_scan,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "KALYANAKATTA_TICKET_SCAN",
      "TOLLGATE_TICKET_SCAN",
      "DARSHANAM_TICKET_SCAN",
      "SEVA_TICKET_PERFORMANCE_SCAN",
      "SEVA_TICKET_SCAN",
      "SHOP_PRODUCT_TICKET_SCAN",
    ],
    color: "#EEEBFF",
    logo: tms_icon_check_in_scan,
    textColor: "#573BFF",
  },
  {
    title: "Accommodation Scan",
    hidden: "accommodationEnabled",
    route: tmsRouteMap.pos_acc_scan,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "ACCOMMODATION_TICKET_SCAN",
      "ACCOMMODATION_COUNTER",
    ],
    color: "#EEEBFF",
    logo: tms_icon_check_in_scan,
    textColor: "#573BFF",
  },
  {
    title: "Performance Scan",
    hidden: "",
    route: tmsRouteMap.pos_performance_sccanner,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "SEVA_TICKET_PERFORMANCE_SCAN"],
    color: "#FFF3E6",
    logo: tms_icon_performance_scan,
    textColor: "#FF7805",
  },
  {
    title: "Accommodation",
    hidden: "accommodationEnabled",
    route: tmsRouteMap.pos_accommodation,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "ACCOMMODATION_COUNTER"],
    color: "#E6F8FD",
    logo: tms_icon_accommodation,
    textColor: "#0EB5E4",
  },
  {
    title: "Accommodation Dashboard",
    hidden: "accommodationEnabled",
    route: tmsRouteMap.pos_accommodation_dashboard,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "ACCOMMODATION_COUNTER"],
    color: "#dff2ec",
    logo: tms_icon_accommodation,
    textColor: "#0EB5E4",
  },
  {
    title: "Sevas",
    hidden: "sevaEnabled",
    route: tmsRouteMap.pos_sevas,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "SEVA_COUNTER"],
    color: "#EEF7EE",
    logo: tms_icon_pratyaksha_Seva,
    active: true,
    textColor: "#53B04A",
  },
  {
    title: "Darshanam",
    hidden: "darshanamEnabled",
    route: tmsRouteMap.pos_darshanam,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "DARSHANAM_COUNTER"],
    color: "#F0F9FF",
    logo: tms_icon_darshanam,
    active: true,
    textColor: "#3399CC",
  },
  {
    title: "Donation",
    hidden: "donationEnabled",
    route: tmsRouteMap.pos_donation,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "DONATION_COUNTER"],
    color: "#FFF6FB",
    logo: tms_icon_donation,
    textColor: "#CC3399",
  },
  {
    title: "Petty Donation",
    hidden: "donationEnabled",
    route: tmsRouteMap.pos_petty_donation,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "DONATION_PETTY_COUNTER"],
    color: "#FFF6FB",
    logo: tms_icon_donation,
    textColor: "#CC3399",
  },
  {
    title: "Products",
    hidden: "productEnabled",
    route: tmsRouteMap.pos_products,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "PUBLICATION_COUNTER",
      "SHOP_PRODUCT_COUNTER",
    ],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
  },
  {
    title: "Toll Gate",
    hidden: "tollgateEnabled",
    route: tmsRouteMap.pos_tollgate,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "TOLLGATE_COUNTER"],
    color: "#ECECF2",
    logo: tms_icon_tollgate,
    textColor: "#666699",
  },
  {
    title: "Prasadam",
    hidden: "prasadamEnabled",
    route: tmsRouteMap.pos_prasadam,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "PRASADAM_COUNTER"],
    color: "#F0F7D8",
    logo: tms_icon_prasadam,
    textColor: "#999966",
  },
  {
    title: "Kalyanakatta",
    hidden: "kalyanakattaEnabled",
    route: tmsRouteMap.pos_kalayanakatta,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "KALYANAKATTA_COUNTER"],
    color: "#D9F7E0",
    logo: tms_icon_kalyanakatta,
    textColor: "#669966",
  },
  {
    title: "Thulabharam",
    hidden: "thulabaramEnabled",
    route: tmsRouteMap.pos_thulabharam,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "THULABHARAM_COUNTER"],
    color: "#D6F8FF",
    logo: tms_icon_thulabharam,
    textColor: "#669999",
  },
  {
    title: "Prasadam Scanner",
    hidden: "prasadamEnabled",
    route: tmsRouteMap.pos_prasadm_scanner,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "PRASADAM_TICKET_SCAN"],
    color: "#D6F8FF",
    logo: tms_icon_prasadam_scanner,
    textColor: "#339999",
  },
  {
    title: "Ticket Details",
    hidden: "",
    route: tmsRouteMap.pos_ticket_details,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "TICKET_DETAILS_SCAN"],
    color: "#ECECF2",
    textColor: "#7D4FB2",
    logo: tms_icon_ticket_details,
  },
  {
    title: "Manual Entry",
    hidden: "",
    route: tmsRouteMap.pos_manual_entry,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "ACCOMMODATION_COUNTER",
      "SEVA_COUNTER",
      "DARSHANAM_COUNTER",
      "PRASADAM_COUNTER",
      "KALYANAKATTA_COUNTER",
      "DONATION_COUNTER",
      "DONATION_PETTY_COUNTER",
      "PUBLICATION_COUNTER",
      "TOLLGATE_COUNTER",
      "TICKET_DETAILS_SCAN",
      "TOLLGATE_TICKET_SCAN",
      "PRASADAM_TICKET_SCAN",
      "DARSHANAM_TICKET_SCAN",
      "SEVA_TICKET_PERFORMANCE_SCAN",
      "ACCOMMODATION_TICKET_SCAN",
      "THULABHARAM_COUNTER",
      "MISC_PRODUCT_COUNTER",
      "SHOP_PRODUCT_COUNTER",
    ],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
  },
  {
    title: "Miscellaneous",
    hidden: "miscellaneousEnabled",
    route: tmsRouteMap.pos_misc,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "MISC_PRODUCT_COUNTER"],
    color: "#D9F7E0",
    logo: tms_d_products,
    textColor: "#669966",
  },
  {
    title: "Entry Scan",
    hidden: "",
    route: tmsRouteMap.pos_entryScan,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "ENTRY_SCANNING_COUNTER"],
    color: "#EEEBFF",
    logo: tms_icon_check_in_scan,
    textColor: "#573BFF",
  },
  {
    title: "Protocol Request",
    hidden: "protocolEnabled",
    route: tmsRouteMap.pos_protocol,
    accessedByKey: ["SUPER_ADMIN", "PROTOCOL_LEVEL_4"],
    color: "#FEFBEC",
    logo: tms_icon_protocol,
    textColor: "#CC9933",
  },
  {
    title: "Protocol Dashboard",
    hidden: "protocolEnabled",
    route: tmsRouteMap.pos_protocol_dashboard,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "PROTOCOL_LEVEL_1",
      "PROTOCOL_LEVEL_2",
      "PROTOCOL_LEVEL_3",
      "PROTOCOL_LEVEL_4",
    ],
    color: "#F0F9FF",
    logo: tms_icon_protocol_dashboard,
    textColor: "#3399CC",
  },
  {
    title: "Protocol Booking",
    hidden: "protocolEnabled",
    route: tmsRouteMap.pos_protocol_print,
    accessedByKey: ["SUPER_ADMIN", "PROTOCOL_BOOKING"],
    color: "#FFF6FB",
    logo: tms_icon_protocol_print,
    textColor: "#CC3399",
  },
  {
    title: "Rents and Leases",
    hidden: "rentsAndLeasesEnabled",
    route: tmsRouteMap.pos_rents_and_leases,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "RENT_AND_LEASE_COUNTER"],
    color: "#EEEBFF",
    logo: rentsLogo,
    textColor: "#573BFF",
  },
  {
    title: "Bus",
    hidden: "busEnabled",
    route: tmsRouteMap.pos_bus,
    accessedByKey: ["ADMIN", "SUPER_ADMIN", "BUS_TICKET_COUNTER"],
    color: "#EEF7EE",
    logo: busLogo,
    active: true,
    textColor: "#53B04A",
  },
  {
    title: "Publications",
    hidden: "publicationEnabled",
    route: tmsRouteMap.pos_publication,
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "PUBLICATION_COUNTER",
      "TICKET_DETAILS_SCAN",
    ],
    color: "#FEFBEC",
    logo: tms_icon_publication,
    textColor: "#CC9933",
  },
]; // ADD ROLES IN Sider.map.js

export default TmsPosMap;
