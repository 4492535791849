import { Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import "../../../../../../assets/css/tms-common/tms-common.scss";
import { LicenseInstitutionSelect } from "../../../../../../components/tms-customs/rents-and-leases/index";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  createLandStates,
  createLicenseStates,
} from "../../../../../../constants/states-data.data";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import useLicensesStore from "../../../../../../zustand/licenses.store";
const TmsLicenseCreation = ({ router, setVisible }) => {
  const {
    licenseName,
    setLicenseName,
    setSubmitLoading,
    submitLoading,
    fetchInstitutionList,
    handleCreateLicense,
    clearStates,
    status,
    setStatus,
  } = useLicensesStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createLandStates, ["institutionData"]);
    };
  }, []);

  return (
    <div>
      <Row gutter={[20, 30]} justify="center" style={{ marginBottom: "15px" }}>
        <LicenseInstitutionSelect span={8} />
        <Col span={8}>
          <Row className={"t-left"}>Name of License</Row>
          <Input
            type="text"
            placeholder="Enter License Name"
            value={licenseName}
            onChange={(e) => {
              setLicenseName(e.target.value);
            }}
            maxLength={220}
          />
        </Col>
      </Row>
      <TmsCheckbox
        label={"License Status"}
        value={status}
        setValue={setStatus}
      />
      <TmsCancelSubmitButtons
        loading={submitLoading}
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(createLicenseStates, ["institutionData"]);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleCreateLicense(router);
        }}
      />
    </div>
  );
};

export default withRouter(TmsLicenseCreation);
