import React, { useEffect, useState, useRef, useContext } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Modal,
  notification,
  Row,
  Switch,
} from "antd";
import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import "../../../assets/css/tms-prasadam/tms-pos-prasadam.scss";
import TmsModal from "../../../components/tms-customs/tms-modal";
import TmsKalayanakattaService from "../../../services/tms-kalayanakatta.service";
import { BaseContext } from "../../../contexts/BaseContext";

const TmsPosKalayanakatta = ({ router }) => {
  const ref = useRef(null);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  const { shiftData } = useContext(BaseContext);
  const [kalyanaKattaList, setKalyanaKattaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [amountpaid, setAmountPaid] = useState(0);
  const [externalPrint, setExternalPrint] = useState(false);
  const [array, setArray] = useState([]);
  const [tonsureModale, setTonsureModal] = useState(false);
  const [ticketsCount, setTicketsCount] = useState({
    id: "",
    price: "",
    count: "",
  });
  const [performerList, setPerformerList] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState();
  const [performerModalLoading, setPerformerModalLoading] = useState(false);

  const kalyanaKattaData = (data) => {
    let schemaBody = [];
    data?.cartItems?.kalyanakatta?.forEach((item) => {
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
          item?.productDetails?.kalyanakattaPerformerSittingNumber,
        ],
        ["Ticket Id", item?.counterCartItem?.ticketId, { margin: "0" }],
        [
          "Type",
          item?.productDetails?.productName,
          {
            margin: "0",
            fontWeight: "bold",
          },
        ],
        [
          "Performer",
          templeDetails?.configuration?.kalyanakattaPerformer === true
            ? item?.productDetails?.kalyanakattaPerformerName
            : "null",
          {
            margin: "0",
          },
        ],
        [
          "Ticket Count",
          item?.kalyanakattaBooking?.noOfItems,
          {
            margin: "0",
          },
        ],
        [
          `Price`,
          `Rs. ${item?.kalyanakattaBooking?.price}.00`,
          { margin: "0" },
        ],
        [
          "",
          `(${numberToWords(item?.kalyanakattaBooking?.price)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i + "p-tag"}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
    });
    setArray([...schemaBody]);
  };

  useEffect(() => {
    getKalyanaKatta();
  }, []);

  useEffect(() => {
    templeDetails?.configuration?.kalyanakattaPerformer
      ? shiftData
        ? getPerformers()
        : ""
      : "";
  }, [refreshFlag, shiftData]);

  const getKalyanaKatta = async () => {
    setLoading(true);
    try {
      let res = await TmsKalayanakattaService.fetchKalyanakattaBookingSlots();
      setKalyanaKattaList(res);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const getPerformers = async () => {
    setLoading(true);
    setPerformerModalLoading(true);
    try {
      let data = await TmsKalayanakattaService.getKalayanakattaPerformers(
        1,
        1000,
        shiftData?.counterId
      );
      setPerformerList(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
      setPerformerModalLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
      setPerformerModalLoading(false);
    }
  };

  const switchTrigger = async (type, id, status) => {
    setLoading(true);
    try {
      if (type === "single") {
        await TmsKalayanakattaService.editPerformerStatus(id, status);
      } else {
        await TmsKalayanakattaService.editPerformerBulkStatus(id, status);
      }
      setRefreshFlag(Math.random() + 1);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!ticketsCount || ticketsCount <= 0 || ticketsCount === "") {
      notification.error({ message: "Please enter no. of tickets" });
      setLoading(false);
      return;
    }

    try {
      let body = [];

      // if (templeDetails?.configuration?.kalyanakattaPerformer === true) {
      for (var i = 1; i <= Number(ticketsCount?.count); i++) {
        body.push({
          kalyanakattaId: Number(ticketsCount?.id),
          noOfItems: Number(1),
        });
      }
      // } else {
      //   body.push({
      //     kalyanakattaId: productId,
      //     noOfItems: Number(ticketsCount),
      //   });
      // }

      let res = await TmsKalayanakattaService.kalayanakattaBooking(body);
      notification.success({ message: "Success" });
      kalyanaKattaData(res);
      clearFields();
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const getAmount = () => {
    let amount = 0;

    amount =
      Number(ticketsCount?.price) > 0
        ? Number(ticketsCount?.price) * Number(ticketsCount?.count)
        : 0;

    return amount;
  };

  const clearFields = () => {
    setStartDate("");
    setKalyanaKattaList([]);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
        }}
      >
        <Row gutter={[20, 20]} style={{ textAlign: "left" }}>
          <Col style={{ width: "33%", justifyContent: "start" }}>
            <label className="labelStyles">
              Booking Date{" "}
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />{" "}
            <DatePicker
              disabled={true}
              value={startDate}
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              disabledDate={(d) =>
                !d || d.isBefore(TmsStringUtils.addDays(new Date(), -1))
              }
              onChange={(date) => {
                setStartDate(date);
              }}
              size="medium"
            />
          </Col>
          {/* <Col style={{ width: "34%", justifyContent: "start" }}>
            <label className="labelStyles">No. of Tickets</label>
            <br />
            <Input
              onWheel={(event) => event.currentTarget.blur()}
              style={{ width: "100%", textAlign: "left" }}
              type="number"
              min="0"
              max="20"
              maxLength="2"
              key="edit"
              placeholder="0"
              controls={false}
              step="false"
              value={ticketsCount}
              onChange={(e) => {
                if (e.target.value > 20) {
                  return;
                } else {
                  setTicketsCount(e.target.value);
                }
              }}
            />
          </Col> */}
          {templeDetails?.configuration?.kalyanakattaPerformer && (
            <Col style={{ width: "33%", justifyContent: "start" }}>
              <label className="labelStyles">Performer Management</label>
              <br />
              <Button
                style={{ width: "100%", textAlign: "left" }}
                onClick={() => setTonsureModal(true)}
              >
                Performer Status
              </Button>
            </Col>
          )}
        </Row>
        <br />
        <Row gutter={[20, 0]}>
          {kalyanaKattaList.map((item, i) => {
            return (
              <Col key={i + "col"} style={{ width: "100%" }}>
                <Card
                  key={i + "card"}
                  hoverable
                  style={{
                    width: "100%",
                  }}
                  // onClick={() => {
                  //   selectSlot(item?.product?.price, item?.product?.id);
                  //   ref.current.focus();
                  // }}
                >
                  <Row justify="space-between">
                    <Col
                      style={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontWeight: "600",
                      }}
                    >
                      {item?.product.name}
                      <Divider type="vertical" />
                    </Col>

                    <Col
                      style={{
                        width: "20%",
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontWeight: "600",
                      }}
                    >
                      Rs. {item?.product?.price}
                    </Col>
                    <Col
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        id={`input-navigation-${i}`}
                        onWheel={(event) => event.currentTarget.blur()}
                        style={{ width: "100%", textAlign: "left" }}
                        type="number"
                        min="0"
                        max="10"
                        maxLength="2"
                        key={i}
                        placeholder="0"
                        controls={false}
                        step="false"
                        value={
                          item?.product?.id === Number(ticketsCount?.id)
                            ? ticketsCount?.count
                            : ""
                        }
                        onChange={(e) => {
                          if (e.target.value > 10) {
                            return;
                          } else {
                            setTicketsCount({
                              id: item?.product?.id,
                              price: item?.product?.price,
                              count: e.target.value,
                            });
                          }
                        }}
                        onKeyDown={(E) => {
                          if (E.key === "Enter") {
                            E.preventDefault();
                            loading === false && handleSubmit();
                          }
                          if (E.key === "ArrowUp") {
                            E.preventDefault();
                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i - 1}`)
                                ?.focus();
                            }, 0);
                          }
                          if (E.key === "ArrowDown") {
                            E.preventDefault();

                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i + 1}`)
                                ?.focus();
                            }, 0);
                          }
                        }}
                      />
                    </Col>
                    {/* <Col style={{ width: "10%" }}>
                      <Radio
                        autoFocus={i === 0 ? true : false}
                        onKeyDown={(E) => {
                          if (E.key === "Enter") {
                            E.preventDefault();
                            selectSlot(item?.product?.price, item?.product?.id);
                            ref.current.focus();
                          }
                          if (E.key === "ArrowUp") {
                            E.preventDefault();
                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i - 1}`)
                                ?.focus();
                            }, 0);
                          }
                          if (E.key === "ArrowDown") {
                            E.preventDefault();

                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i + 1}`)
                                ?.focus();
                            }, 0);
                          }
                        }}
                        id={`input-navigation-${i}`}
                        defaultChecked={false}
                        value={item?.product?.id}
                        checked={item?.product?.id === productId ? true : false}
                        onClick={() => {
                          setProductId(item?.product?.id);
                          setPrice(item?.product?.price);
                        }}
                      />
                    </Col> */}
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "#FFFCDC",
        }}
      >
        <Row
          style={{
            alignItems: "center",
            textAlign: "left",
            padding: "10px 50px",
          }}
        >
          <Col
            className="amountLabelStyles"
            style={{
              width: "260px",
              fontSize: "24px",
              marginRight: "20px",
            }}
          >
            Amount Paid: Rs.
            <Input
              maxLength={220}
              className="amountPaidStyle"
              type="number"
              min=""
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) => {
                setAmountPaid(e.target.value);
              }}
            />
          </Col>
          <Col className="amountLabelStyles">
            Amount to be Returned: Rs.{" "}
            {amountpaid - getAmount() > 0 ? amountpaid - getAmount() : 0}
          </Col>
        </Row>
        <Divider style={{ margin: "0" }} />
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "500",
            padding: "10px 50px",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              textAlign: "left",
              margin: "0",
            }}
          >
            Total Amount :
          </p>
          <p
            style={{
              fontSize: "24px",
              margin: "0",
              textAlign: "right",
            }}
          >
            Rs. {Number(getAmount() ?? 0)}.00
          </p>
        </Row>
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              ref={ref}
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                loading === false && handleSubmit();
              }}
              loading={loading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      <TmsModal
        visible={tonsureModale}
        width={600}
        footer={null}
        closable={false}
        centered
        maskClosable={true}
        onCancel={() => setTonsureModal(false)}
        className="performerModal"
        title={
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <p style={{ margin: "0" }}>Performer Status</p>
            <Button
              onClick={() => {
                switchTrigger("multiple", shiftData?.counter?.id, false);
              }}
            >
              Master Off
            </Button>
          </Row>
        }
      >
        {performerModalLoading ? (
          <LoadingOutlined />
        ) : (
          <Row gutter={[20, 20]}>
            {performerList
              ?.sort((a, b) => a.sittingNumber - b.sittingNumber)
              ?.map((item, i) => {
                return (
                  <Col
                    key={i + "list"}
                    style={{
                      width: "50%",
                    }}
                  >
                    <Card
                      onClick={() => {
                        switchTrigger("single", item?.id, !item?.isActive);
                      }}
                      hoverable
                    >
                      <Row
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <b
                          style={{
                            border: "1px solid black",
                            padding: "0px 5px",
                          }}
                        >
                          {item?.sittingNumber}
                        </b>{" "}
                        {item?.name}
                        <Switch
                          size={"small"}
                          checked={item?.isActive}
                          style={{
                            background: item?.isActive ? "#4CBB17" : "EFEFEF",
                          }}
                          onClick={() => {
                            switchTrigger("single", item?.id, !item?.isActive);
                          }}
                        />
                      </Row>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        )}
      </TmsModal>
      <Modal
        visible={isModalVisible}
        width={400}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="kalyanakatta"
          from="Counter"
          data={array}
          setData={setArray}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setLoading}
          setPrintTicketModal={setIsModalVisible}
          ticketType={"mini_ticket"}
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosKalayanakatta);
