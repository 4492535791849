import { Col, Popconfirm, Row, Space, Table } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import { createLicenseStates } from "../../../../../../constants/states-data.data";
import { shallow } from "zustand/shallow";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import { DeleteOutlined } from "@ant-design/icons";
import useLicensesStore from "../../../../../../zustand/licenses.store";
import {
  LicenseInstitutionSelect,
  SortTypeAndBy,
} from "../../../../../../components/tms-customs/rents-and-leases";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";
const TmsCreatedLicenseList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const {
    selectedInstitution,
    loading,
    createdLicenseData,
    fetchCreatedLicenses,
    fetchInstitutionList,
    deleteCreatedLicense,
    deleteLoading,
    clearStates,
    excelLoading,
    handleExcelExport,
  } = useLicensesStore((state) => {
    return {
      selectedInstitution: state.selectedInstitution,
      loading: state.loading,
      deleteLoading: state.deleteLoading,
      setSelectedInstitution: state.setSelectedInstitution,
      createdLicenseData: state.createdLicenseData,
      fetchInstitutionList: state.fetchInstitutionList,
      fetchCreatedLicenses: state.fetchCreatedLicenses,
      deleteCreatedLicense: state.deleteCreatedLicense,
      clearStates: state.clearStates,
      handleExcelExport: state.handleExcelExport,
      excelLoading: state.excelLoading,
    };
  }, shallow);
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  useEffect(() => {
    return () => {
      clearStates(createLicenseStates, ["selectedInstitution"]);
    };
  }, []);
  useEffect(() => {
    (router.location.pathname === tmsRouteMap.sm_ral_license_arears_creation ||
      router.location.pathname === tmsRouteMap.sm_ral_licenses) &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedLicenses(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
  }, [sortBy, tableCurrentPage, tablePageSize, sortType, selectedInstitution]);

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <LicenseInstitutionSelect span={6} />
        <SortTypeAndBy
          span={6}
          sortType={sortType}
          setSortType={setSortType}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        {selectedInstitution !== "" && selectedInstitution && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() => handleExcelExport("license", router)}
              top={false}
            />
          </Col>
        )}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        scroll={{
          x: "max-content",
        }}
        loading={loading}
        style={{
          textTransform: "capitalize",
        }}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 80,
            render: (text, object, index) => index + 1,
            align: "center",
          },
          {
            title: "License Name",
            dataIndex: "licenseName",
            key: "licenseName",
            align: "center",
          },

          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (record) => moment(record).format("DD-MM-YYYY hh:mm A"),
            align: "center",
          },

          {
            title: "Action",
            render: (record) => {
              return (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure delete this license?"
                    onConfirm={async () =>
                      deleteCreatedLicense(record.id, router)
                    }
                    okText="Yes"
                    cancelText="No"
                    loading={deleteLoading}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Space>
              );
            },
            align: "center",
          },
        ]}
        dataSource={createdLicenseData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdLicenseData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsCreatedLicenseList);
