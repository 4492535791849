import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Modal,
  notification,
  Row,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import "../../../assets/css/tms-prasadam/tms-pos-prasadam.scss";
import {
  dragEnter,
  dragStart,
  drop,
  updateData,
} from "../../../utils/tms-card-dragger/tms-card-dragger";
import TmsMiscService from "../../../services/tms-misc.service";

const TmsPosMisc = ({ router }) => {
  const [miscProductsList, setMiscProductsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [bookingData, setBookingData] = useState([]);
  const [productsCount, setProductsCount] = useState([]);
  const [amountpaid, setAmountPaid] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [array, setArray] = useState([]);
  const [updateFlag, setUpdateFlag] = useState();
  const [ticketType, setTicketType] = useState();
  const ref = useRef(null);
  const dragItem = useRef();
  const dragOverItem = useRef();

  const miscProductData = (data) => {
    let schemaBody = [];
    data?.cartItems?.misc_product?.forEach((item) => {
      setTicketType(item?.productDetails?.productMetadata?.ticketType);
      const basicBody = [
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
        ],
        [
          "Ticket Id",
          item?.productDetails?.productMetadata?.ticketType === "mini_ticket"
            ? item?.counterCartItem?.ticketId?.split("-")?.pop()
            : item?.counterCartItem?.ticketId,
          { margin: "0" },
        ],
      ];
      const generalBody = [
        [
          "Booking Date & Time",
          moment().format("DD-MM-YYYY hh:mm:ss A"),
          { margin: "0" },
        ],
        ["Product", item?.productDetails?.productName, { margin: "0" }],
        ["Quantity", item?.productDetails?.noOfItems, { margin: "0" }],
        [
          "Ticket Price",
          `Rs. ${item?.productDetails?.productActualPrice}.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.miscProductBooking?.amount}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.miscProductBooking?.amount)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
      const miniTicketBody = [
        ["Product", item?.productDetails?.productName, { margin: "0" }],
        ["Quantity", item?.productDetails?.noOfItems, { margin: "0" }],
        [
          "Amount",
          `Rs. ${item?.tollgateBooking?.amount}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          null,
          item?.counterCartItem?.createdAt,
        ],
      ];
      if (item?.productDetails?.productMetadata?.ticketType === "mini_ticket") {
        schemaBody = [...schemaBody, ...basicBody, ...miniTicketBody];
      } else {
        schemaBody = [...schemaBody, ...basicBody, ...generalBody];
      }
    });
    setArray([...schemaBody]);
  };

  const getProducts = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      let res = await TmsMiscService.fetchMiscPro();
      let ids = [];
      setProductsCount(
        res.map((item) => {
          ids = [...ids, item?.id];
          return {
            id: item?.product?.id,
            price: item?.product?.price,
            count: "",
          };
        })
      );
      setSelectedProductIds(ids);
      updateData(res, setMiscProductsList, "miscProductsList");
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getProducts();
  }, [updateFlag]);

  const handleSubmit = async () => {
    setButtonLoading(true);
    setLoading(true);
    let a = false;
    productsCount?.forEach((e) => {
      if (e.count !== 0 && e.count > 0) {
        a = true;
      }
    });
    if (!a) {
      notification.error({ message: "Please insert quantity" });
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    try {
      let body = {
        miscProducts: [],
      };
      productsCount.forEach((item) => {
        if (item.count > 0 && item?.count !== 0) {
          body.miscProducts.push({
            miscProductId: Number(item?.id),
            noOfItems: Number(item?.count),
          });
        }
      });
      let res = await TmsMiscService.miscProductBooking(body?.miscProducts);
      notification.success({ message: "Success" });
      miscProductData(res);
      clearFields();
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
      setButtonLoading(false);
    }
    // setButtonLoading(false);
  };

  const getAmount = () => {
    let amount = 0;
    productsCount.forEach((e) => {
      if (e?.count === 0) {
        return;
      } else {
        amount += e?.count * e?.price;
      }
    });
    return amount;
  };

  const clearFields = () => {
    setStartDate("");
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
        }}
      >
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col style={{ width: "48%", justifyContent: "start" }}>
            <label className="labelStyles">
              Booking Date{" "}
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />{" "}
            <DatePicker
              disabled={true}
              value={startDate}
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              disabledDate={(d) =>
                !d || d.isBefore(TmsStringUtils.addDays(new Date(), -1))
              }
              onChange={(date) => {
                setStartDate(date);
              }}
              size="medium"
            />
          </Col>
          {/* <Col style={{ width: "31%", justifyContent: "start" }}>
              <label className="labelStyles">Manual Entry {`(If any)`} </label>
              <br />
              <Button
                style={{ width: "100%", textAlign: "left" }}
                type="ghost"
                // onClick={() => setTicketsDetailsVisisble(true)}
              >
                Ticket Details
              </Button>
            </Col> */}
        </Row>
        <br />
        <Row
          style={{
            backgroundColor: "#ECF0F5",
            borderRadius: "2px 2px 0px 0px",
            paddingRight: "18px",
          }}
        >
          <Col
            style={{
              width: "25%",
              padding: "15px 10px",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            Item Name
          </Col>

          <Col
            style={{
              width: "25%",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Unit Price
          </Col>
          <Col
            style={{
              width: "25%",
              display: "flex",
              alignItems: "center",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Quantity
          </Col>
          <Col
            style={{
              width: "25%",
              display: "flex",
              alignItems: "center",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Net Amount
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{ overflow: "hidden" }}
          className="posItemsList"
        >
          {miscProductsList
            ?.filter((item) => selectedProductIds?.includes(item?.id))
            ?.map((item, i) => {
              return (
                <Card
                  key={i}
                  hoverable
                  style={{ width: "100%" }}
                  className="itemCard"
                  onDragStart={(e) => dragStart(e, i, dragItem)}
                  onDragEnter={(e) => dragEnter(e, i, dragOverItem)}
                  onDragEnd={(e) =>
                    drop(
                      e,
                      setUpdateFlag,
                      dragItem,
                      dragOverItem,
                      "miscProductsList",
                      selectedProductIds
                    )
                  }
                  draggable
                >
                  <Row style={{ padding: "0" }}>
                    <Col
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontWeight: "600",
                        padding: "10px",
                      }}
                    >
                      {item?.product.name}{" "}
                      {item?.availableBookingCount
                        ? ` - (Stock: ${item?.availableBookingCount})`
                        : ""}
                      <Divider type="vertical" />
                    </Col>

                    <Col
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontWeight: "600",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      Rs. {item?.product?.price}
                    </Col>
                    <Col
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      <Input
                        id={`input-navigation-${i}`}
                        tabIndex={i}
                        onWheel={(event) => event.currentTarget.blur()}
                        style={{ width: "70px", textAlign: "center" }}
                        type="number"
                        min="0"
                        max={item?.product.maxItemsPerOrder ?? "30"}
                        maxLength="2"
                        key="edit"
                        placeholder="0"
                        autoFocus={i === 0 ? true : false}
                        value={
                          productsCount?.find((e) => e.id === item?.product?.id)
                            ?.count
                        }
                        controls={false}
                        step="false"
                        onKeyDown={(E) => {
                          if (E.key === "Enter") {
                            E.preventDefault();
                            buttonLoading === false && handleSubmit();
                          }
                          if (E.key === "ArrowUp") {
                            E.preventDefault();
                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i - 1}`)
                                ?.focus();
                            }, 0);
                          }
                          if (E.key === "ArrowDown") {
                            E.preventDefault();

                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i + 1}`)
                                ?.focus();
                            }, 0);
                          }
                        }}
                        onChange={(event) => {
                          let limit = item?.product?.maxItemsPerOrder ?? 10;
                          if (event.target.value > limit) {
                            return;
                          } else {
                            let data = [...productsCount];
                            data.forEach((e) => {
                              if (e?.id === item?.product?.id) {
                                e.count = event.target.value;
                              }
                            });
                            setProductsCount(data);
                          }
                        }}
                      />
                    </Col>
                    <Col
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      <div key={i}>
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Rs.{" "}
                          {productsCount?.find(
                            (e) => e.id === item?.product?.id
                          )?.price *
                            productsCount?.find(
                              (e) => e.id === item?.product?.id
                            )?.count}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              );
            })}
        </Row>
      </div>
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "#FFFCDC",
        }}
      >
        <Row
          style={{
            alignItems: "center",
            textAlign: "left",
            padding: "10px 50px",
          }}
        >
          <Col
            className="amountLabelStyles"
            style={{
              width: "260px",
              fontSize: "24px",
              marginRight: "20px",
            }}
          >
            Amount Paid: Rs.
            <Input
              maxLength={220}
              className="amountPaidStyle"
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) => {
                setAmountPaid(e.target.value);
              }}
            />
          </Col>
          <Col className="amountLabelStyles">
            Amount to be Returned: Rs.{" "}
            {amountpaid - getAmount() > 0 ? amountpaid - getAmount() : 0}
          </Col>
        </Row>
        <Divider style={{ margin: "0" }} />
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "500",
            padding: "10px 50px",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              textAlign: "left",
              margin: "0",
            }}
          >
            Total Amount :
          </p>
          <p
            style={{
              fontSize: "24px",
              margin: "0",
              textAlign: "right",
            }}
          >
            Rs. {Number(getAmount() ?? 0)}.00
          </p>
        </Row>
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              ref={ref}
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                buttonLoading === false && handleSubmit();
              }}
              loading={buttonLoading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      {/* <Modal
        visible={ticketDetailsVisible}
        okText={"Submit"}
        cancelText={"Return"}
        width="600px"
      ></Modal> */}
      <Modal
        visible={isModalVisible}
        width={400}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="miscellaneous"
          from="Counter"
          data={array}
          setData={setArray}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setButtonLoading}
          setPrintTicketModal={setIsModalVisible}
          ticketType={ticketType}
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosMisc);
