import React, { useEffect } from "react";
import { Col, Divider, Input, Row } from "antd";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import TmsImageUpload from "../../../../../../components/tms-customs/tms-image-upload";
import {
  yesOrNotData,
  encroachmentStates,
  additionalEncroahmentStates,
  buildingEncroachmentStates,
  additionalBuildingEncroahmentStates,
} from "../../../../../../constants/states-data.data";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import useBuildingsStore from "../../../../../../zustand/buildings-and-shops.store";
import {
  BuildingBlockNoSelect,
  BuildingInstitutionSelect,
  BuildingSelect,
  ShopNoSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";
const TmsSmBasEncroachment = ({ router, setVisible }) => {
  const {
    setEncroacherNatureOfBusiness,
    encroacherNatureOfBusiness,
    setEncroacherAddress,
    encroacherAddress,
    selectedInstitution,
    encroacherName,
    setEncroacherName,
    submitLoading,
    setSubmitLoading,
    legalReason,
    setLegalReason,
    setLegalAction,
    fetchInstitutionList,
    legalAction,
    selectedBuilding,
    setEncroachmentDurationInMonths,
    encroachmentDurationInMonths,
    remarks,
    setRemarks,
    caseNo,
    setCaseNo,
    casePDF,
    setCasePDF,
    handleEncroachment,
    clearStates,
    setStatus,
    status,
    selectedShop,
    fetchCreatedBuildingsList,
    fetchBlocks,
    selectedBlock,
    fetchShopsByBlock,
  } = useBuildingsStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(
        buildingEncroachmentStates,
        additionalBuildingEncroahmentStates
      );
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution && selectedInstitution !== "")
      fetchCreatedBuildingsList(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  useEffect(() => {
    if (
      selectedInstitution !== "" &&
      selectedInstitution &&
      selectedBuilding?.id &&
      selectedBlock?.id
    ) {
      fetchShopsByBlock(1, 200, "ASC", "createdAt", router);
    }
  }, [selectedInstitution, selectedBuilding, selectedBlock]);

  useEffect(() => {
    if (
      selectedInstitution !== "" &&
      selectedInstitution &&
      selectedBuilding &&
      selectedBuilding !== ""
    ) {
      fetchBlocks(1, 200, "ASC", "createdAt", router);
    }
  }, [selectedInstitution, selectedBuilding]);

  return (
    <div>
      {" "}
      <Row gutter={[20, 20]} style={{ marginBottom: "15px" }}>
        {/* <Col span={6}> */}
        <BuildingInstitutionSelect span={6} />
        <BuildingSelect span={6} />
        <BuildingBlockNoSelect span={6} />
        <ShopNoSelect span={6} />
        <Divider />
        <Col span={6}>
          <Row className={"t-left"}>Property Type</Row>
          <Input
            maxLength={220}
            type="col"
            value={selectedBuilding?.propertyType}
            label="Property Type"
            span={6}
            disabled={true}
          />
        </Col>{" "}
        <Col span={6}>
          <Row className={"t-left"}>Shop Status</Row>
          <Input
            maxLength={220}
            type="col"
            value={selectedShop?.shopStatus}
            label="Shop Status"
            span={6}
            disabled={true}
          />
        </Col>{" "}
        <Col span={6}>
          <Row className={"t-left"}>Enchrochure Name</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Enchrochure Name"
            value={encroacherName}
            onChange={(e) => setEncroacherName(e.target.value)}
          />
        </Col>{" "}
        <Col span={6}>
          <Row className={"t-left"}>Enchrochure Address</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Address"
            value={encroacherAddress}
            onChange={(e) => setEncroacherAddress(e.target.value)}
          />
        </Col>{" "}
        <Col span={6}>
          <Row className={"t-left"}>Encroacher Nature Of Business</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Encroacher Nature Of Business"
            value={encroacherNatureOfBusiness}
            onChange={(e) => setEncroacherNatureOfBusiness(e.target.value)}
          />
        </Col>{" "}
        <Col span={6}>
          <Row className={"t-left"}>Enchrochment Duration(Months)</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            placeholder="Enter Enchrochment Duration in Months"
            value={encroachmentDurationInMonths}
            onChange={(e) => {
              if (e.target.value <= 360)
                setEncroachmentDurationInMonths(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Divider />
      <h2 className="t-left">Land Donated By</h2>
      <Row className="mt-20" gutter={[20, 20]}>
        <TmsSelect
          selectClass="w-100 "
          classname="mr-0 "
          type="col"
          value={legalAction}
          setValue={setLegalAction}
          label="Legal Action"
          data={yesOrNotData}
          span={6}
          optional={true}
        />
        {legalAction ? (
          <>
            <Col span={6}>
              <Row className={"t-left"}>If yes Case Number</Row>
              <Input
                maxLength={220}
                type="text"
                onWheel={(event) => event.currentTarget.blur()}
                onKeyDown={(e) =>
                  ["+", "-", "."].includes(e.key) && e.preventDefault()
                }
                placeholder="Enter Case No"
                value={caseNo}
                onChange={(e) => setCaseNo(e.target.value)}
              />
            </Col>
            <Col span={6}>
              <Row className={"t-left"}>Upload File(PDF)</Row>
              <TmsImageUpload
                fileList={casePDF}
                setFileList={setCasePDF}
                router={router}
                type="private"
                accept={true}
                className="w-100"
              />
            </Col>
          </>
        ) : (
          <Col span={6}>
            {" "}
            <Row className={"t-left"}>Reason</Row>
            <Input
              maxLength={220}
              type="text"
              placeholder="Enter Remarks"
              value={legalReason}
              onChange={(e) => setLegalReason(e.target.value)}
            />
          </Col>
        )}

        <Col span={6}>
          <Row className={"t-left"}>Remarks</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Col>
      </Row>
      <TmsCheckbox
        label={"Encroachment Status"}
        value={status}
        setValue={setStatus}
      />
      <TmsCancelSubmitButtons
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(encroachmentStates, additionalEncroahmentStates);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => !submitLoading && handleEncroachment(router)}
        loading={submitLoading}
      />
    </div>
  );
};

export default withRouter(TmsSmBasEncroachment);
