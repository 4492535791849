/* eslint-disable no-undef */
import "./App.scss";
import { Route, Routes as SwitchRouter } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import TmsErrorBoundary from "./components/tms-error-boundary/tms-error-boundary";
import TmsLayout from "./components/tms-layout/tms-layout";
import TmsLogin from "./views/tms-login/tms-login";
import TmsErrorPage from "./views/tms-error-page/tms-error-page";
import tmsRouteMap from "./constants/tms-route.map";
import TmsAuthService from "./services/tms-user/tms-auth.service";
import { BaseContext } from "./contexts/BaseContext";
import { useNavigate, useLocation } from "react-router-dom";
import TmsTempleService from "./services/tms-temple.service";
import { notification } from "antd";
// import useUniqueId from "./utils/tms-unique-id/tms-unique-id";

const App = () => {
  const { setUserDetails, clearLocalStorage } = useContext(BaseContext);
  let navigate = useNavigate();
  let location = useLocation();
  // const [tabRestriction, setTabRestriction] = useState(false);
  // const uniqueId = useUniqueId(setTabRestriction);

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    try {
      if (process.env.REACT_APP_ELECTRON) {
        window?.ipcRenderer.on("update_available", () => {
          window?.ipcRenderer.removeAllListeners("update_available");
          const message = "A new update is available. Downloading now...";
          openNotification(message);
        });
        window?.ipcRenderer.on("update_downloaded", () => {
          window?.ipcRenderer.removeAllListeners("update_downloaded");
          const message = "Update Downloaded. Please restart the Application.";
          openNotification(message);
        });
      } else {
        //
      }
    } catch (e) {
      //
    }
  }, []);

  // useEffect(() => {
  //   console.log("adding listerners");
  //   // Listen for changes in localStorage to detect if the user opens the app in a new tab
  //   const storageEventListener = (e) => {
  //     console.log(e);
  //     if (e.key === "uniqueId" && e.newValue !== uniqueId) {
  //       // You may want to redirect or handle the situation in a way that makes sense for your application
  //       setTabRestriction(true);
  //     }
  //   };

  //   window.addEventListener("storage", storageEventListener);

  //   // Add a listener for the beforeunload event to clear the localStorage when the tab is closed
  //   const beforeUnloadListener = () => {
  //     console.log("removed");
  //     localStorage.removeItem("uniqueId");
  //   };

  //   window.addEventListener("beforeunload", beforeUnloadListener);

  //   return () => {
  //     window.removeEventListener("storage", storageEventListener);
  //     window.removeEventListener("beforeunload", beforeUnloadListener);
  //   };
  // }, [uniqueId]);

  const checkToken = async () => {
    if (!localStorage.getItem("accessToken")) {
      clearLocalStorage();
      navigate(tmsRouteMap.login);
    } else {
      try {
        const user = await TmsAuthService.checkToken();
        if (user.userPermissions?.length === 0 || !user.userPermissions) {
          setLoading(false);
          notification.error({ message: "Forbidden Account" });
          clearLocalStorage();
          navigate(tmsRouteMap.login);
          return;
        }

        //-----------comment this below code for developement and Electron Application-----------

        // if (!user.userPermissions.includes("SUPER_ADMIN") || !user.userPermissions.includes("DASHBOARD_READ")) {
        //   notification.error({
        //     message: "Please login with a Super Admin Account",
        //   });
        //   clearLocalStorage();
        //   navigate(tmsRouteMap.login);
        //   return;
        // }

        //----------------------------------------------------------------------------------
        setUserDetails({ user: user, permissions: user.userPermissions });
        let res = await TmsTempleService.getTempleDetails(
          localStorage.getItem("templeId")
        );
        if (res !== null && res !== undefined && Object.keys(res).length > 0) {
          if (
            res.configuration === null ||
            Object.keys(res.configuration).length === 0
          ) {
            await TmsTempleService.updateTempleSettings(false, "A4");
            let res2 = await TmsTempleService.getTempleDetails(user.templeId);
            localStorage.setItem("templeDetails", JSON.stringify(res2));
          } else {
            localStorage.setItem("templeDetails", JSON.stringify(res));
          }
        }

        if (location?.pathname === "/") {
          navigate(tmsRouteMap.welcome);
          return;
        } else {
          navigate(`${location?.pathname}${location.search}`);
        }
      } catch (error) {
        console.error(error);
        clearLocalStorage();
        navigate(tmsRouteMap.login);
      }
    }
  };
  const openNotification = (message) => {
    notification.info({
      message: `Software Update`,
      description: message,
      placement: "bottomRight",
    });
  };

  return (
    <div className="App">
      <TmsErrorBoundary>
        <SwitchRouter>
          <Route
            exact
            path={"/login"}
            element={<TmsLogin navigate={navigate} location={location} />}
          />
          <Route
            path={"/*"}
            element={<TmsLayout navigate={navigate} location={location} />}
          />
          <Route
            exact
            path="/error/:error"
            element={<TmsErrorPage navigate={navigate} />}
          />
          {/* {tabRestriction && (
            <div
              tabIndex="-1"
              ref={ref}
              autoFocus
              className={"sd-pop-up"}
              // onKeyDown={(e) => onKeyEvent(e)}
              // onMouseDown={(e) => {
              //   handleKey(e);
              // }}
            >
              <div className="inside-pop">
                <span>
                  SORRY FOR THE DISRUPTION
                  <br></br>
                  <br />
                  Looks like you're using Applicatione from more than one window
                  or tab! <br />
                  Please close any extra windows or tabs, then reload the papge.
                  <br />
                </span>
              </div>
            </div>
          )} */}
        </SwitchRouter>
      </TmsErrorBoundary>
    </div>
  );
};

export default App;
