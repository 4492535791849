import { Button, Col, Row, Space, Switch } from "antd";
import React, { useContext, useEffect, useState } from "react";
import TmsCounterService from "../../../../services/tms-counter.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import { BaseContext } from "../../../../contexts/BaseContext";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsPosDeviceModal from "../../../../components/tms-service-management/tms-counter-management/tms-pos-device-modal";
const TmsSmManageDeives = ({ router }) => {
  const [loading, setLoading] = useState();
  const { userDetails } = useContext(BaseContext);

  const [allDeviceList, setAllDeviceList] = useState([]);
  const [updateTable, setUpdateTable] = useState("none");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  const [posdeviceModal, setPosDeviceModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const handleModify = async (record) => {
    setEditData(record);
    setEditFlag(true);
    setPosDeviceModal(true);
  };

  useEffect(() => {
    getDevices();
  }, [updateTable]);

  const getDevices = async () => {
    setLoading(true);
    try {
      const resp = await TmsCounterService.getAllDevices(
        tableCurrentPage,
        tablePageSize
      );
      setAllDeviceList(resp.items);
      setTabelTotalSize(resp?.meta?.totalItems);

      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    { title: "id", dataIndex: "id", key: "id" },
    {
      title: "Device ID",
      dataIndex: "configuration",
      render: (record) =>
        record.type === "ezeetap" ? record.ezeetap.deviceId : "-",
    },
    {
      title: "Device Config ",
      dataIndex: "configuration",
      render: (record) => record.type,
    },

    {
      title: "Username",
      dataIndex: "configuration",
      render: (record) =>
        record.type === "ezeetap" ? record.ezeetap.userName : "-",
    },

    {
      title: "Status",
      dataIndex: "isActive",
      render: (text, record) => {
        return (
          <Switch
            size={"small"}
            checked={text}
            style={{
              background: text ? "#4CBB17 " : "EFEFEF ",
            }}
            onClick={() => {
              switchTrigger(record?.id, !text);
            }}
          />
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            <a
              onClick={(e) => {
                e.preventDefault();
                handleModify(record);
              }}
            >
              Edit
            </a>
          </Space>
        );
      },
    },
  ];

  const switchTrigger = async (id, status) => {
    console.log(id, status);
    setLoading(true);
    try {
      await TmsCounterService.togglePosStatus(id, status);
      setUpdateTable(Math.random() * 100 + 55);

      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Row style={{ width: "100%", marginBottom: "15px" }} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              // setUpdateTable(Math.random(100));
              setPosDeviceModal(true);
            }}
            disabled={
              userDetails?.permissions?.includes("SUPER_ADMIN") ||
              userDetails?.permissions?.includes("ADMIN")
                ? false
                : true
            }
          >
            Add New Pos Device
          </Button>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <TmsTable
          dataSource={allDeviceList}
          loading={loading}
          searchable={["display_name"]}
          columns={columns}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        />
      </Row>

      {posdeviceModal && (
        <TmsPosDeviceModal
          posdeviceModal={posdeviceModal}
          setPosDeviceModal={setPosDeviceModal}
          setUpdateFlag={setUpdateTable}
          editFlag={editFlag}
          setEditFlag={setEditFlag}
          editData={editData}
          setEditData={setEditData}
        />
      )}
    </div>
  );
};
export default withRouter(TmsSmManageDeives);
