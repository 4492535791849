import { writeFile, utils } from "xlsx";

export const exportExcel = (data, fileName, setLoading) => {
  let binaryWS = utils.json_to_sheet(data);
  // Create a new Workbook
  var wb = utils.book_new();
  // Name your sheet
  utils.book_append_sheet(wb, binaryWS, "Binary values");
  // export your excel
  writeFile(wb, `${fileName}.xlsx`);
  setLoading(false);
};
