import React from "react";
import useBuildingsStore from "../../../../zustand/buildings-and-shops.store";
import TmsSelect from "../../tms-select";

const BuildingInstitutionSelect = ({ span }) => {
  const {
    setSelectedInstitution,
    selectedInstitution,
    institutionData,
    clearStates,
  } = useBuildingsStore((state) => {
    return {
      setSelectedInstitution: state.setSelectedInstitution,
      selectedInstitution: state.selectedInstitution,
      institutionData: state.institutionData,
      clearStates: state.clearStates,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select a Institution"
      setValue={(e) => {
        setSelectedInstitution(e);
        clearStates(
          [
            "createdBlocksData",
            "createdShopData",
            "selectedShop",
            "selectedBlock",
            "selectedBuilding",
            "selectedBuildingLease",
            "createdBuildingLeaseData",
            "createdBuildingsData",
            "createdArrearsData",
          ],
          []
        );
      }}
      selectClass="w-100"
      value={selectedInstitution}
      data={institutionData?.items?.map((item) => {
        return { name: item.name, id: item.id };
      })}
      optional={true}
    />
  );
};

export default BuildingInstitutionSelect;
