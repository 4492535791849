import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsProductsInnerPagesAll from "./tms-sm-products-inner-pages/tms-products-inner-pages-all";
import TmsProductsInnerPagesAnP from "./tms-sm-products-inner-pages/tms-products-inner-pages-anp";
import TmsSmProductsFileManagement from "./tms-sm-products-inner-pages/tms-products-file-management/tms-products-file-management";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { Route, Routes } from "react-router";

const TmsSmProducts = ({ router }) => {
  const [active, setActive] = useState("all");
  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_products) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sm_products_and)) {
      setActive("Add New Product");
    } else if (location.includes(tmsRouteMap.sm_products_manage_pos)) {
      setActive("all");
      return;
    }
  }, [router?.location?.pathname]);

  const location = router?.location?.pathname;
  const components = [
    {
      name: location.includes(tmsRouteMap.sm_products_manage_pos)
        ? "Manage POS"
        : "All",
      Element: location.includes(tmsRouteMap.sm_products_manage_pos) ? (
        <TmsCmsSlots type="products-counters" />
      ) : (
        <TmsProductsInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_products,
    },
    {
      name: "Add New Product",
      Element: <TmsProductsInnerPagesAnP />,
      key: "Add New Product",
      route: tmsRouteMap.sm_products_and,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Routes>
        <Route
          exact
          path={
            tmsRouteMap.sm_products_manage_files?.replace(
              "/service-management/products",
              ""
            ) + "/*"
          }
          element={<TmsSmProductsFileManagement />}
        />
        <Route
          path="*"
          element={
            <TmsTabsLayout
              components={components}
              active={active}
              setActive={setActive}
            />
          }
        />
      </Routes>
    </div>
  );
};
export default withRouter(TmsSmProducts);
