import React, { memo } from "react";
import useBuildingsStore from "../../../../zustand/buildings-and-shops.store";
import TmsSelect from "../../tms-select";

const ShopNoSelect = ({ span }) => {
  const {
    setSelectedShop,
    selectedShop,
    createdShopData,
    setExtentLength,
    setExtentBreadth,
  } = useBuildingsStore((state) => {
    return {
      setSelectedShop: state.setSelectedShop,
      selectedShop: state.selectedShop,
      createdShopData: state.createdShopData,
      clearStates: state.clearStates,
      setExtentBreadth: state.setExtentBreadth,
      setExtentLength: state.setExtentLength,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select Shop No"
      setValue={(e) => {
        const item = createdShopData?.items?.find((item) => item.id === e);
        setSelectedShop(item);
        setExtentLength(item.shopExtentLength);
        setExtentBreadth(item.shopExtentBreadth);
      }}
      selectClass="w-100"
      value={selectedShop?.id}
      data={createdShopData?.items?.map((item) => {
        return { name: `Shop.no: ${item.shopNumber}`, id: item.id };
      })}
      optional={true}
    />
  );
};

export default memo(ShopNoSelect);
