import { Col, Input, Row } from "antd";
import React from "react";
import useBuildingsStore from "../../../../zustand/buildings-and-shops.store";

const ShopExtentInput = ({ disabled }) => {
  const { extentLength, setExtentLength, extentBreadth, setExtentBreadth } =
    useBuildingsStore((state) => {
      return {
        extentLength: state.extentLength,
        setExtentLength: state.setExtentLength,
        extentBreadth: state.extentBreadth,
        setExtentBreadth: state.setExtentBreadth,
      };
    });
  return (
    <Col span={12}>
      <Row className={"t-left"}>Extent Area</Row>
      <Row>
        <Col span={12}>
          <Input
            maxLength={220}
            value={extentLength ?? 0}
            type="number"
            min="0"
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) => {
              setExtentLength(Number(e.target.value));
            }}
            suffix={<span className="suffix-border">Length</span>}
            disabled={disabled}
          />
        </Col>
        <Col span={12}>
          <Input
            maxLength={220}
            value={extentBreadth ?? 0}
            type="number"
            min="0"
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) => {
              setExtentBreadth(Number(e.target.value));
            }}
            suffix={<span className="suffix-border">Breadth</span>}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default ShopExtentInput;
