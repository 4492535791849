import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsPrasadamService from "../../../../../services/tms-prasadam.service";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import { Col, Row, Table } from "antd";
// import TmsPrasadamInnerPagesAND from "./tms-prasadam-inner-pages-and";

const TmsSmPrasadamInventoryStockLevel = ({ router }) => {
  const [loading, setLoading] = useState(false);
  const [prasadamInventoryData, setPrasadamInventoryData] = useState([]);
  useEffect(() => {
    router.location?.pathname ===
      "/service-management/prasadam-inventory/stock" && getPrasadamInventory();
  }, [router.location?.pathname]);

  const getPrasadamInventory = async () => {
    setLoading(true);
    try {
      let data = [];
      setPrasadamInventoryData([]);

      data = await TmsPrasadamService.getPrasadamInventoryStock();

      setPrasadamInventoryData(
        data?.map((item, i) => {
          return {
            key: i + 1,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    Table.EXPAND_COLUMN,

    {
      title: "Prasadam name",
      dataIndex: "prasadamName",
      key: "prasadamName",
    },
    {
      title: "Available Stock",
      dataIndex: "totalAvailable",
      key: "totalAvailable",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Assigned Stock",
      dataIndex: "assignedQuantity",
      key: "assignedQuantity",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Total Booked",
      dataIndex: "totalBooked",
      key: "totalBooked",
      //   ...getColumnSearchProps("darshanam_name"),
    },
  ];

  return (
    <>
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={prasadamInventoryData}
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <Row
              // style={{ border: "1px solid grey", borderCollapse: "collapse" }}
              >
                <Col span={1}></Col>
                <Col span={6}>
                  <b>POS Name</b>
                </Col>
                <Col span={6}>
                  <b>Available Quanity</b>
                </Col>
                <Col span={6}>
                  <b>Assigned Quanity</b>
                </Col>
                <Col span={5}>
                  <b>Booked Quanity</b>
                </Col>
              </Row>
              {record.counterAvailability.map((item, i) => {
                return (
                  <Row
                    key={i + "table-row"}
                    justify="space-evenly"
                    style={
                      {
                        // border: "1px solid grey",
                        // borderBottom:
                        //   i + 1 === record.counterAvailability.length
                        //     ? "1px solid grey"
                        //     : "none",
                        // borderCollapse: "collapse",
                      }
                    }
                  >
                    <Col span={1}></Col>
                    <Col span={6}>{item.counterName}</Col>
                    <Col span={6}>{item.counterAvailable}</Col>
                    <Col span={6}>{item.assignedQuantity}</Col>
                    <Col span={5}>{item.bookedQuantity}</Col>
                  </Row>
                );
              })}{" "}
            </div>
          ),
        }}
      />
    </>
  );
};

export default withRouter(TmsSmPrasadamInventoryStockLevel);
