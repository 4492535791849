import { Card, Row } from "antd";
import React from "react";
import { useContext } from "react";
import "../../../assets/css/tms-accommodation/tms-accommodation-dashboard.scss";
import { BaseContext } from "../../../contexts/BaseContext";
const TmsAccommodationStats = ({ shiftData, getLiveAvailableRoomsData }) => {
  const { roomMapData } = useContext(BaseContext);
  return (
    <>
      <Card
        className="roomCount"
        style={{
          width: "100%",
          background: "transparent",
          marginTop: "15px",
          border: "none",
        }}
      >
        {/* <Row justify="start">
          <Col
            style={{
              textAlign: "left",
              padding: "10px 20px",
              fontSize: "18px",
            }}
          >
            Total Rooms: {shiftData["TOTAL"]?.totalRooms ?? 0}
          </Col>
          <Col
            style={{
              textAlign: "left",
              padding: "10px 20px",
              fontSize: "18px",
            }}
          >
            Total Available Rooms: {shiftData["TOTAL"]?.availableRooms ?? 0}
          </Col>
          <Col
            style={{
              textAlign: "left",
              padding: "10px 20px",
              fontSize: "18px",
            }}
          >
            Total Booked Rooms: {shiftData["TOTAL"]?.occupiedRooms ?? 0}
          </Col>
          <Col
            style={{
              textAlign: "left",
              padding: "10px 20px",
              fontSize: "18px",
            }}
          >
            Under Maintenance Rooms:{" "}
            {shiftData["TOTAL"]?.underMaintenanceRooms ?? 0}
          </Col>
        </Row> */}
      </Card>
      {roomMapData
        .filter(
          (item) =>
            shiftData[item.id]?.totalRooms !== 0 &&
            shiftData[item.id]?.totalRooms !== null
        )
        .map((item, i) => {
          let offline =
            shiftData[item.id]?.totalCounterOccupiedDays === 0 ||
            shiftData[item.id]?.counterAssignedRooms === 0 ||
            shiftData[item.id]?.totalDays === 0;

          const online =
            shiftData[item.id]?.totalOnlineOccupiedDays === 0 ||
            shiftData[item.id]?.onlineAssignedRoom === 0 ||
            shiftData[item.id]?.totalDays === 0;

          const offlinePercentage =
            (shiftData[item.id]?.totalCounterOccupiedDays /
              (shiftData[item.id]?.totalRooms *
                shiftData[item.id]?.totalDays)) *
            100;

          const onlinePercentage =
            (shiftData[item.id]?.totalOnlineOccupiedDays /
              (shiftData[item.id]?.onlineAssignedRoom *
                shiftData[item.id]?.totalDays)) *
            100;
          return (
            <Card
              key={"stats" + i}
              id={i}
              className="acc-cards"
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "14px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{ whiteSpace: "break-spaces", textAlign: "left" }}
                  >
                    {item.name}
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    Occupancy:{" "}
                    {(offlinePercentage ?? 0 + onlinePercentage ?? 0).toFixed(
                      2
                    )}
                    %
                  </div>
                </div>
              }
              style={{
                width: "23%",
                marginLeft: i % 4 === 0 || i === 0 ? "" : "2.65%",
              }}
            >
              <Row gutter={20}>
                <div
                  style={{ width: "47.5%" }}
                  className="acc-status-rooms green-ot"
                  onClick={() => {
                    getLiveAvailableRoomsData(item.id, "available");
                  }}
                >
                  Available: {shiftData[item.id]?.availableRooms ?? 0}
                </div>
                <div
                  style={{ width: "47.5%" }}
                  className="acc-status-rooms green "
                  onClick={() => {
                    getLiveAvailableRoomsData(item.id, "occupied");
                  }}
                >
                  Booked: {shiftData[item.id]?.occupiedRooms ?? 0}
                </div>
                <div
                  style={{ width: "100%" }}
                  className="acc-status-rooms blue"
                  onClick={() => {
                    getLiveAvailableRoomsData(item.id, "total");
                  }}
                  span={15}
                >
                  Total Rooms: {shiftData[item.id]?.totalRooms ?? 0}
                </div>
              </Row>

              {/* <Col
                span={24}
                
              >
                <Statistic
                  style={{ cursor: "pointer" }}
                  className="sd-large"
                  title="Available Rooms"
                  value={shiftData[item.id]?.availableRooms ?? 0}
                />
              </Col> */}
              {/* <Row>
                <Col span={12}>
                  <Statistic
                    style={{ cursor: "pointer" }}
                    title="Booked Rooms"
                    className="color-red"
                    value={shiftData[item.id]?.occupiedRooms ?? 0}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    style={{ cursor: "pointer" }}
                    title="Total Rooms"
                    value={shiftData[item.id]?.totalRooms ?? 0}
                  />
                </Col>
              </Row> */}
              <div className="occupancy">
                <div className="o-inner">
                  <p>Online Occupancy</p>
                  <div className="o-i-inner">
                    {/* <p> {shiftData[item.id]?.onlineAssignedRooms ?? 0}</p> */}
                    <div className="oii-inner">
                      <div className="skill-container">
                        <div
                          className="skill"
                          style={{
                            width: `${
                              online ? 0 : onlinePercentage.toFixed(2) ?? 0
                            }%`,
                          }}
                        ></div>
                      </div>{" "}
                      <p className="percentage-skill">
                        {online ? 0 : onlinePercentage.toFixed(2) ?? 0}%
                      </p>
                    </div>
                  </div>
                </div>
                <div className="o-inner">
                  <p>Offline Occupancy</p>
                  <div className="o-i-inner">
                    {/* <p> {shiftData[item.id]?.counterAssignedRooms ?? 0}</p> */}
                    <div className="oii-inner">
                      <div className="skill-container">
                        <div
                          className="skill"
                          style={{
                            width: `${
                              offline ? 0 : offlinePercentage.toFixed(2) ?? 0
                            }%`,
                          }}
                        ></div>
                      </div>{" "}
                      <p className="percentage-skill">
                        {offline ? 0 : offlinePercentage.toFixed(2) ?? 0}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="maintanence">
                Rooms Under Maintenance:{" "}
                <span style={{ fontWeight: "600" }}>
                  {shiftData[item.id]?.underMaintenanceRooms ?? 0}
                </span>
              </div>
            </Card>
          );
        })}
    </>
  );
};
export default TmsAccommodationStats;
