import Axios from "../utils/tms-axios/tms-axios";
const TmsPartnerService = {
  getPartners: async (page, limit) => {
    const resp = await Axios.get(
      `v1/partner/list?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getPartnerPermissionsById: async (partnerId) => {
    const resp = await Axios.get(`v1/partner/permissions/${partnerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  partnerCreation: async (body) => {
    const resp = await Axios.post(`v1/partner/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  partnerPermissionUpdate: async (body) => {
    const resp = await Axios.post(`v1/partner/update-permissions`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getPartnerByCode: async (code) => {
    const resp = await Axios.get(`v1/partner/get-by-partner-code/${code}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  rotatePrimaryKey: async (partnerId) => {
    const resp = await Axios.patch(
      `v1/partner/primary-key/rotate/${partnerId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  rotateSecondaryKey: async (partnerId) => {
    const resp = await Axios.patch(
      `v1/partner/secondary-key/rotate/${partnerId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getPartnersByTemple: async () => {
    const resp = await Axios.get(`v1/partner/temple`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  deletePartnerByTemple: async (partnerId) => {
    const resp = await Axios.delete(`v1/partner/tremple/${partnerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getPartnerByTempleCode: async (partnerId) => {
    const resp = await Axios.get(`v1/partner/temple/code/${partnerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getPartnerByTemplePId: async (partnerId) => {
    const resp = await Axios.get(`v1/partner/temple/id/${partnerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};
export default TmsPartnerService;
