/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
// import RoomsService from "../../Services/rooms.service";
import {
  Button,
  notification,
  Table,
  Modal,
  Row,
  Col,
  Input,
  Space,
  Divider,
} from "antd";
import moment from "moment";
import tmsRouteMap from "../../../constants/tms-route.map";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsPrintTicket from "../../tms-print-ticket/tms-print-ticket";
import { FormatNumberINRCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
import TmsAccommodationService from "../../../services/tms-accommodation.service";
import TmsCartService from "../../../services/tms-cart.service";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import { BaseContext } from "../../../contexts/BaseContext";

const TmsPosOnlineRooms = ({ router, data, from, captured, type }) => {
  const [blockData, setBlockData] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [ticketDetails, setTicketDetails] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [roomNumber, setRoomNumber] = useState();
  const [roomId, setRoomId] = useState();
  const [ticketVisible, setTicketVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  const [array, setArray] = useState([]);
  const [ticketId, setTicketId] = useState();
  const { roomsModal, setRoomsModal } = useContext(BaseContext);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );

  console.log(type);

  const formatBookingData = (data, roomDetails) => {
    let schemaBody = [];
    data?.map((item) => {
      setTicketId(item?.ticketId);
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.dailyCategoryTransactionSequence?.split("-")?.pop(),
          item?.ticketId,
        ],
        ["Ticket Id", item?.ticketId, { margin: "0" }],

        [
          "Devotee Name",
          item?.counter_accommodation?.devoteeName ??
            item?.online_accommodation?.devoteeName,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Room Details",
          `${
            item?.counter_accommodation?.roomType ??
            item?.online_accommodation?.roomType
          } - ${
            item?.counter_accommodation?.blockName ??
            item?.online_accommodation?.blockName
          } - ${
            item?.counter_accommodation?.roomNumber ??
            item?.online_accommodation?.roomNumber
          }`,
          { margin: "0" },
        ],
        [
          "Checkin Time",
          moment(
            item?.counter_accommodation?.checkInTime ??
              item?.online_accommodation?.checkInTime
          ).format("DD-MM-YYYY hh:mm A"),
          { margin: "0" },
        ],
        [
          "Checkout Time",
          moment(
            item?.counter_accommodation?.currentCheckOutTime ??
              item?.online_accommodation?.currentCheckOutTime
          ).format("DD-MM-YYYY hh:mm A"),
          { margin: "0" },
        ],
        [
          "Room Price",
          `Rs. ${
            item?.counter_accommodation?.roomPrice ??
            item?.online_accommodation?.roomPrice
          }.00`,
          { margin: "0" },
        ],
        [
          "GST Amount",
          type === "protocol"
            ? `Rs. ${roomDetails?.roomBooking?.gst}.00`
            : "null",
          { margin: "0" },
        ],
        [
          "Deposit Amount",
          `Rs. ${
            Number(Math.abs(roomDetails?.roomBooking?.depositAmount)) ?? 0
          }.00`,
          { margin: "0" },
        ],
        [
          "Maintenance Amount",
          `Rs. ${
            Number(Math.abs(roomDetails?.roomBooking?.maintenanceCharge)) ?? 0
          }.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${Number(
            (type === "protocol"
              ? Number(roomDetails?.roomBooking?.gst) +
                Number(roomDetails?.roomBooking?.price)
              : 0) +
              Number(roomDetails?.roomBooking?.depositAmount) +
              Number(roomDetails?.roomBooking?.maintenanceCharge)
          )}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(
            Number(
              (type === "protocol"
                ? Number(roomDetails?.roomBooking?.gst) +
                  Number(roomDetails?.roomBooking?.price)
                : 0) +
                Number(roomDetails?.roomBooking?.depositAmount) +
                Number(roomDetails?.roomBooking?.maintenanceCharge)
            )
          )} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.ticketId,
          {},
          {},
          {},
          null,
          item?.counterCart?.createdAt,
        ],
      ];
    });
    setArray([...schemaBody]);
    notification.success({ message: "Success" });
    setIsModalVisible(true);
    setExternalPrint(true);
    setButtonLoading(false);
  };

  const fetchRooms = async () => {
    setButtonLoading(true);
    try {
      const resp = await TmsAccommodationService.fetchValidRooms(
        data[0]?.counter_accommodation_advance?.accBlockCategoryId ??
          data[0]?.online_accommodation?.accBlockCategoryId
      );
      setBlockData(resp);
      setButtonLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (
      data[0]?.counter_accommodation_advance?.accBlockCategoryId ||
      data[0]?.online_accommodation?.accBlockCategoryId
    ) {
      data[0]?.counter_accommodation_advance?.accPreAssignedRoomId === null ||
      data[0]?.online_accommodation?.accPreAssignedRoomId === null
        ? fetchRooms()
        : "";
    }
  }, [
    data[0]?.counter_accommodation_advance?.accBlockCategoryId,
    data[0]?.online_accommodation?.accBlockCategoryId,
  ]);

  useEffect(() => {
    if (
      data[0]?.counter_accommodation_advance?.accPreAssignedRoomId !== null &&
      roomsModal
    ) {
      from === "accoScan" && setIsModalVisible(true);
    }
  }, [
    data[0]?.counter_accommodation_advance?.accPreAssignedRoomId,
    roomsModal,
  ]);

  const bookAdvRoom = async () => {
    setTableLoading(true);
    setButtonLoading(true);
    let body = {
      accCounterBookingId: data[0]?.accCounterAdvanceBookingId,
      maintenanceCharge:
        data[0]?.counter_accommodation_advance?.accBlockCategory
          ?.maintenanceCharge,
      depositAmount:
        data[0]?.counter_accommodation_advance?.accBlockCategory?.depositAmount,
      roomId: Number(
        data[0]?.counter_accommodation_advance?.accPreAssignedRoomId ?? roomId
      ),
      fingerPrintTemplate: captured?.IsoTemplate,
      paymentType: "CASH",
      cashRemarks: "",
      cashReference: "",
    };

    if (
      data[0]?.counter_accommodation_advance?.servicePaymentStatus === "created"
    ) {
      body = {
        ...body,
        ...{
          serviceAmount:
            data[0]?.counter_accommodation_advance?.accBlockCategory?.price,
          gstAmount:
            data[0]?.counter_accommodation_advance?.accBlockCategory?.gstAmount,
          advanceBookingCharge:
            data[0]?.counter_accommodation_advance?.accBlockCategory
              ?.advanceBookingCharge,
        },
      };
    }

    try {
      const roomDetails =
        await TmsAccommodationService.advanceAccommodationBooking(body);
      setTableLoading(false);

      const data = await TmsCartService.getAdminTicketDetails(
        roomDetails?.newCounterCartItem?.ticketId
      );
      formatBookingData(data, roomDetails);
      setTicketVisible(true);
      setTicketLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! please login again.", router);

      setTableLoading(false);
    }
    setButtonLoading(false);
  };

  const bookOnRoom = async () => {
    setTableLoading(true);
    setButtonLoading(true);
    let body = {
      accOnlineBookingId: data[0]?.accOnlineBooking?.id,
      maintenanceCharge:
        data[0]?.online_accommodation?.accBlockCategory?.maintenanceCharge,
      depositAmount:
        data[0]?.online_accommodation?.accBlockCategory?.depositAmount,
      roomId: Number(roomId),
      fingerPrintTemplate: captured?.IsoTemplate,
      paymentType: "CASH",
      cashRemarks: "",
      cashReference: "",
    };
    try {
      const roomDetails =
        await TmsAccommodationService.onlineAccommodationBooking(body);
      setTableLoading(false);

      const data = await TmsCartService.getAdminTicketDetails(
        roomDetails?.newCounterCartItem?.ticketId
      );
      formatBookingData(data, roomDetails);
      setTicketVisible(true);
      setTicketLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! please login again.", router);

      setTableLoading(false);
    }
    setButtonLoading(false);
  };

  // const fetchDetails = async (roomDetail) => {
  //   setTicketLoading(true);
  //   try {
  //     //   const ticketDetails = await RoomsService.printTicket(roomDetail.id);
  //     //   setTicketDetails(ticketDetails);
  //   } catch (error) {
  //     catchErrorFunction(error, "Token expired!! please login again.", router);
  //   }
  //   setTableLoading(false);
  //   setTicketVisible(true);
  //   setTicketLoading(false);
  // };

  const columns = [
    {
      title: "Room Number",
      dataIndex: "roomNumber",
      key: "roomNumber",
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Button
            type="primary"
            disabled={
              templeDetails?.configuration?.enableAccommodationFingerScan
                ? captured?.ErrorCode === "0"
                  ? false
                  : true
                : false
            }
            onClick={(e) => {
              e.preventDefault();
              setRoomId(record.id);
              setRoomNumber(record?.roomNumber);
              setIsModalVisible(true);
            }}
          >
            Book Room
          </Button>
        );
      },
    },
  ];

  const totalAmount = (align) => {
    return (
      <Row
        style={{ justifyContent: align, marginBottom: "10px", width: "100%" }}
      >
        <Col style={{ fontWeight: "bold", width: "40%" }}> Total Amount : </Col>
        <Col style={{ width: "60%" }}>
          {data[0]?.productType === "counter_accommodation_advance"
            ? `Rs.${Number(
                Number(
                  data[0]?.counter_accommodation_advance
                    ?.servicePaymentStatus === "created"
                    ? data[0]?.counter_accommodation_advance?.accBlockCategory
                        ?.price +
                        data[0]?.counter_accommodation_advance?.accBlockCategory
                          ?.gstAmount +
                        data[0]?.counter_accommodation_advance?.accBlockCategory
                          ?.advanceBookingCharge
                    : 0
                ) +
                  data[0]?.counter_accommodation_advance?.accBlockCategory
                    ?.depositAmount +
                  data[0]?.counter_accommodation_advance?.accBlockCategory
                    ?.maintenanceCharge
              )}.00`
            : `Rs.${Number(
                data[0]?.online_accommodation?.accBlockCategory?.depositAmount +
                  data[0]?.online_accommodation?.accBlockCategory
                    ?.maintenanceCharge
              )}.00`}
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {data[0]?.counter_accommodation_advance?.accBlockCategory?.roomType !==
        "KALYANA_MANDAPAM" && (
        <>
          <Row
            style={{
              paddingBottom: "20px",
              borderBottom: "1px dashed black",
            }}
          >
            {data[0]?.counter_accommodation_advance?.servicePaymentStatus ===
              "created" && (
              <>
                <Row style={{ width: "100%" }}>
                  <Col style={{ fontWeight: "bold", width: "40%" }}>
                    {" "}
                    Room Price :{" "}
                  </Col>
                  <Col style={{ width: "60%" }}>
                    Rs.{" "}
                    {data[0]?.counter_accommodation_advance?.accBlockCategory
                      ?.price ??
                      data[0]?.online_accommodation?.accBlockCategory?.price}
                    .00
                  </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                  <Col style={{ fontWeight: "bold", width: "40%" }}>
                    {" "}
                    GST :{" "}
                  </Col>
                  <Col style={{ width: "60%" }}>
                    Rs.{" "}
                    {data[0]?.counter_accommodation_advance?.accBlockCategory
                      ?.gstAmount ??
                      data[0]?.online_accommodation?.accBlockCategory
                        ?.gstAmount}
                    .00
                  </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                  <Col style={{ fontWeight: "bold", width: "40%" }}>
                    {" "}
                    Advance Booking Charge :{" "}
                  </Col>
                  <Col style={{ width: "60%" }}>
                    Rs.{" "}
                    {data[0]?.counter_accommodation_advance?.accBlockCategory
                      ?.advanceBookingCharge ??
                      data[0]?.online_accommodation?.accBlockCategory
                        ?.advanceBookingCharge}
                    .00
                  </Col>
                </Row>
              </>
            )}

            <Row style={{ width: "100%" }}>
              <Col style={{ fontWeight: "bold", width: "40%" }}>
                {" "}
                Deposit Amount :{" "}
              </Col>
              <Col style={{ width: "60%" }}>
                Rs.{" "}
                {data[0]?.counter_accommodation_advance?.accBlockCategory
                  ?.depositAmount ??
                  data[0]?.online_accommodation?.accBlockCategory
                    ?.depositAmount}
                .00
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col style={{ fontWeight: "bold", width: "40%" }}>
                {" "}
                Maintenance Amount :{" "}
              </Col>
              <Col style={{ width: "60%" }}>
                Rs.{" "}
                {data[0]?.counter_accommodation_advance?.accBlockCategory
                  ?.maintenanceCharge ??
                  data[0]?.online_accommodation?.accBlockCategory
                    ?.maintenanceCharge}
                .00
              </Col>
            </Row>
          </Row>
          {totalAmount("start")}
          <Table
            size="small"
            scroll={{ x: true }}
            loading={tableLoading}
            style={{ width: "100%", textTransform: "capitalize" }}
            columns={columns}
            dataSource={blockData}
            pagination={{ pageSize: 10 }}
          ></Table>
        </>
      )}
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        // onCancel={() => setIsModalVisible(false)}
      >
        {!ticketVisible && (
          <>
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
              Amount Received ?
            </Row>
            <Row style={{ justifyContent: "center", margin: "auto" }}>
              <Space>
                <Button
                  type="primary"
                  onClick={
                    data[0]?.productType === "online_accommodation"
                      ? bookOnRoom
                      : bookAdvRoom
                  }
                  loading={buttonLoading}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setIsModalVisible(false);
                    setRoomsModal(false);
                  }}
                >
                  No
                </Button>
              </Space>
            </Row>
          </>
        )}
        {ticketVisible && (
          <TmsPrintTicket
            type="accommodation-advance"
            from="Counter"
            setData={setArray}
            data={array}
            externalPrint={externalPrint}
            setExternalPrint={setExternalPrint}
            setButtonLoading={setButtonLoading}
            setPrintTicketModal={setIsModalVisible}
            ticketId={ticketId}
            printType="a4"
          />
        )}
      </Modal>
    </div>
  );
};

export default TmsPosOnlineRooms;
