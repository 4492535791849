import { notification, Popconfirm, Space } from "antd";
import React from "react";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsPublicationService from "../../../services/tms-publications.service";
// import tmsRouteMap from "../../../constants/tms-route.map";

const TmPublicationFileManagementActions = ({
  record,
  setLoading,
  setViewProductData,
  settmsModalVisible,
  setUpdateFlag,
  setEditFlag,
  setProductId,
  router,
}) => {
  return (
    <Space size="middle">
      <Popconfirm
        title="Are you sure delete this publication file?"
        onConfirm={async () => {
          setLoading(true);
          try {
            await TmsPublicationService.deletePublicationFile(record?.key);
            notification.success({
              message: "File Deleted Successful",
            });
            setUpdateFlag(Math.random() * 1000 + 99);
          } catch (error) {
            catchErrorFunction(
              error,
              "Token Expired, redirecting to login page in 5 seconds",
              router
            );
          }
          setLoading(false);
        }}
        okText="Yes"
        cancelText="No"
      >
        <a>
          <DeleteOutlined />
        </a>
      </Popconfirm>
      <a
        onClick={async () => {
          setLoading(true);
          try {
            const productData = await TmsPublicationService.viewPublicationFile(
              record?.key
            );
            setViewProductData(productData);
            settmsModalVisible(true);
            setEditFlag(true);
            setProductId(record?.key);
          } catch (error) {
            catchErrorFunction(
              error,
              "Token Expired, redirecting to login page in 5 seconds",
              router
            );
          }
          setLoading(false);
        }}
      >
        <EditOutlined />
      </a>
    </Space>
  );
};
export default withRouter(TmPublicationFileManagementActions);
