import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../../utils/tms-catch-error/tms-catch-error";
import { Button, Col, Row, Switch } from "antd";
import TmsModal from "../../../../../../components/tms-customs/tms-modal";
import "../../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmsKalayanakattaService from "../../../../../../services/tms-kalayanakatta.service";
import TmsKalyanaKattaAddPerformer from "./tms-performer-add-new";

const TmsKKPerformerAll = ({ router }) => {
  const [updateFlag, setUpdateFlag] = useState(2);
  const [loading, setLoading] = useState(false);
  const [kalayanakattaData, setKalyanakattaPerformersData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewKalyanakattaData, setViewKalyanakattaData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  const switchTrigger = async (id, status) => {
    setLoading(true);
    try {
      await TmsKalayanakattaService.editPerformerStatus(id, status);
      setUpdateFlag(Math.random() + 1);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const getKalyanakattaPerformersList = async () => {
    setLoading(true);
    try {
      let data = [];
      setKalyanakattaPerformersData([]);

      data = await TmsKalayanakattaService.getKalayanakattaPerformers(
        tableCurrentPage,
        tablePageSize
      );

      setTabelTotalSize(data?.meta?.totalItems);
      setKalyanakattaPerformersData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    router.location?.pathname ===
      "/service-management/kalyanakatta/manage-performer" &&
      getKalyanakattaPerformersList();
    if (!editFlag) {
      settmsModalVisible(false);
    }
  }, [
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  const columns = [
    {
      title: "Performer Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sitting Number",
      dataIndex: "sittingNumber",
      key: "sittingNumber",
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text, record) => {
        return (
          <Switch
            size={"small"}
            checked={text}
            style={{
              background: text ? "#4CBB17 " : "EFEFEF ",
            }}
            onClick={() => {
              switchTrigger(record?.key, !text);
            }}
          />
        );
      },
    },
    {
      title: "Counter ID",
      dataIndex: "counterId",
      key: "counterId",
    },
    {
      title: "Shift ID",
      dataIndex: "counterShiftTimingId",
      key: "counterShiftTimingId",
    },
    {
      title: "mobile",
      dataIndex: "mobile",
      key: "mobile",
    },

    {
      title: "Action",
      render: (record) => {
        return (
          <a
            onClick={async () => {
              setLoading(true);
              try {
                const darData =
                  await TmsKalayanakattaService.viewKalyanakattaPerformer(
                    record?.key
                  );
                setViewKalyanakattaData(darData);
                settmsModalVisible(true);
                setEditFlag(true);
                setProductId(record?.key);
              } catch (error) {
                catchErrorFunction(
                  error,
                  "Token Expired, redirecting to login page in 5 seconds",
                  router
                );
              }
              setLoading(false);
            }}
          >
            Edit{" "}
          </a>
        );
      },
    },
  ];

  return (
    <>
      <Row style={{ width: "100%", marginBottom: "15px" }} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              settmsModalVisible(true);
            }}
          >
            Add Performer
          </Button>
        </Col>
      </Row>
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={kalayanakattaData.sort(
          (a, b) => a.sittingNumber - b.sittingNumber
        )}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <TmsModal
        closable={true}
        className="DetailsModal"
        centered={true}
        width={"800px"}
        visible={tmsModalVisible}
        onCancel={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewKalyanakattaData(null);
        }}
        title={editFlag ? "Edit Performer" : "Performer Details"}
      >
        <TmsKalyanaKattaAddPerformer
          viewKalyanakattaData={viewKalyanakattaData}
          editFlag={editFlag}
          productId={productId}
          setEditFlag={setEditFlag}
          setUpdateFlag={setUpdateFlag}
          settmsModalVisible={settmsModalVisible}
        />
      </TmsModal>
    </>
  );
};

export default withRouter(TmsKKPerformerAll);
