import { Modal } from "antd";
import React from "react";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
const TmsModal = (props) => {
  return (
    <Modal
      className={props.className && props.className}
      centered={props.centered && props.centered}
      width={props.width ? props.width : "auto"}
      visible={props.visible}
      title={props.title}
      onOk={props.onOk}
      onCancel={props.onCancel ? props.onCancel : ""}
      footer={props.footer ? props.footer : ""}
      closable={props.closable ? props.closable : ""}
      maskClosable={props.maskClosable ? props.maskClosable : ""}
    >
      {props.children}
    </Modal>
  );
};
export default withRouter(TmsModal);
