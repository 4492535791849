import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { useLocation, useNavigate } from "react-router";
import tmsRouteMap from "../../../constants/tms-route.map";
import tmsBreadcrumbMap from "../../../constants/tms-breadcrumb.map";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import "../../../assets/css/tms-layout/tms-layout.scss";

const TmsBreadCrumb = ({ router }) => {
  const [path, setPath] = useState(tmsRouteMap.login);
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    setPath(router?.location?.pathname);
  }, [location?.pathname]);

  return (
    <Breadcrumb className="tms-layout-bc">
      {tmsBreadcrumbMap[path]?.map((item) => {
        return (
          <Breadcrumb.Item key={item.path}>
            <a
              onClick={() => {
                if (item.path === "") return;
                if (
                  item.path.includes("service-management/sevas/manage-slots") ||
                  item.path.includes(
                    "service-management/darshanam/manage-slots"
                  ) ||
                  item.path.includes(
                    "service-management/accommodation/manage-room-categories"
                  )
                ) {
                  navigate(
                    `${item.path}?id=${new URLSearchParams(
                      router?.location?.search
                    ).get("id")}&name=${new URLSearchParams(
                      router?.location?.search
                    ).get("name")}`
                  );
                } else {
                  navigate(item.path);
                }
              }}
            >
              {item.name}
            </a>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default withRouter(TmsBreadCrumb);
