import React from "react";
import useRentsAndLandStore from "../../../zustand/rents-and-leases.store";
import TmsSelect from "../tms-select";

const InstitutionSelect = ({ span }) => {
  const {
    setSelectedInstitution,
    selectedInstitution,
    institutionData,
    clearStates,
  } = useRentsAndLandStore((state) => {
    return {
      setSelectedInstitution: state.setSelectedInstitution,
      selectedInstitution: state.selectedInstitution,
      institutionData: state.institutionData,
      clearStates: state.clearStates,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select Institution"
      setValue={(e) => {
        setSelectedInstitution(e);
        clearStates(
          [
            "createdSurveryNumberData",
            "createdSNBreakupData",
            "selectedSurveyNumber",
            "selectedSNBreakup",
            "surveyNoDataByLandId",
            "selectedLandLease",
            "createdLandLeaseData",
            "selectedLand",
            "createdLandList",
            "createdArrearsData",
          ],
          []
        );
      }}
      selectClass="w-100"
      value={selectedInstitution}
      data={institutionData?.items?.map((item) => {
        return { name: item.name, id: item.id };
      })}
      optional={true}
    />
  );
};

export default InstitutionSelect;
