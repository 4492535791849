import { notification } from "antd";
import moment from "moment";
import { create } from "zustand";
import {
  additionalLicenseArrearStates,
  arrearLicenseStates,
  createLicenseStates,
  licenseLeaseAddtionalStates,
  licenseLeaseCreationStates,
} from "../constants/states-data.data";
import TmsLicenseService from "../services/tms-license.service";
import catchErrorFunction from "../utils/tms-catch-error/tms-catch-error";
import { exportExcel } from "../utils/tms-excel/tms-excel";
const regex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[0-9A-Z]{1}/;

const useLicensesStore = create((set, get) => {
  const initialState = {
    loading: false,
    submitLoading: false,
    excelLoading: false,
    selectedInstitution: "",
    licenseName: "",
    licenseFee: "",
    natureOfBusiness: "",
    village: "",
    status: false,
    mandal: "",
    district: "",
    country: "India",
    state: "",
    zip: "",
    remarks: "",
    reason: "",
    file: "",
    caseNo: "",
    licenseeName: "",
    legalAction: false,
    institutionData: [],
    selectedLicense: "",
    selectedLicenseLease: "",
    legalReason: "",
    createdLicenseData: [],
    createdLicenseLeaseData: [],
    licensePeriodStartDate: moment(),
    licensePeriodEndDate: moment(),
    enhancementStartPeriod: moment(),
    enhancementEndPeriod: moment(),
    enhancementPercentage: "",
    licenseEnhancement: false,
    dueDate: "",
    paymentTerm: "",
    gstAmount: "",
    paymentType: "",
    leaseRate: "",
    casePDF: [],
    depositAmount: "",
    mrNo: "",
    mrDate: moment(),
    licenseeType: "",
    mobileNumber: "",
    gender: "",
    address: "",
    gstNo: "",
    incorporationOrPanNo: "",
    idProofType: "",
    idProofNumber: "",
    uploadIdProof: [],
    leaseApprovedByCED: "",
    uploadApprovedDocument: [],
    uploadApprovedDocumentOrderNo: "",
    uploadApprovedDocumentOrderDate: "",
    uploadCEDOrder: [],
    eoProceedingUploadDocument: [],
    arrearAmount: "",
    interestAmount: "",
    arrearPeriodInMonths: "",
    createdArrearsData: [],
    leaseReports: [],
    licenseReports: [],
    arrearReport: [],
    arrayStates: [
      "eoProceedingUploadDocument",
      "uploadCEDOrder",
      "uploadIdProof",
      "casePDF",
      "institutionData",
      "createdLicenseData",
      "createdLicenseLeaseData",
      "createdArrearsData",
      "uploadApprovedDocument",
    ],
    datesArray: [
      "uploadApprovedDocumentOrderDate",
      "mrDate",
      "licensePeriodStartDate",
      "licensePeriodEndDate",
      "enhancementEndPeriod",
      "enhancementStartPeriod",
    ],
  };

  const services = {
    "lease-list": "licenseLeaseChangeStatus",
  };

  const generateSetterFunctions = (stateObject) => {
    const setters = {};
    for (const key in stateObject) {
      setters[`set${key.charAt(0).toUpperCase()}${key.slice(1)}`] = (value) =>
        set({ [key]: value });
    }
    return setters;
  };
  return {
    ...initialState,
    ...generateSetterFunctions(initialState),
    //Actions
    checkForEmptyStates(array) {
      array.forEach((element) => {
        const value = get()[element];
        if (value === "") {
          throw new Error(
            `${element
              .split(/(?=[A-Z])/)
              .join(" ")} should not be empty. Please fill all the fields`
          );
        }
      });
    },
    clearStates(array, dataArrays) {
      const setters = {};
      const data = [...array, ...dataArrays];
      data.forEach((item) => {
        if (get().datesArray.includes(item)) {
          setters[item] = moment();
        } else if (get().arrayStates.includes(item)) {
          setters[item] = [];
        } else setters[item] = "";
      });
      set({ ...setters });
    },
    async handleChangeStatus(id, status, router) {
      set({ submitLoading: true });
      try {
        await TmsLicenseService[
          services[router?.location?.pathname?.split("/")?.pop()]
        ](id, status);
        notification.success({ message: "Updated successfully" });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async fetchInstitutionList(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getCreatedInstitutions(
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          institutionData: res,
          loading: false,
          selectedInstitution: "",
          selectedLand: "",
          selectedSNBreakup: "",
          selectedSurveyNumber: "",
          createdSNBreakupData: [],
          createdSurveryNumberData: [],
          createdLandData: [],
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token Expired!!,Login Again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchCreatedLicenses(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getCreatedLicenses(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({
            createdLicenseData: res,
            loading: false,
            selectedLicense: "",
          });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleCreateLicense(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createLicenseStates);
        const body = {
          licenseName: get().licenseName,
          institutionId: get().selectedInstitution,
          status: get().status,
        };
        await TmsLicenseService.createLicense(body);
        await get().clearStates(createLicenseStates, []);
        notification.success({
          message: "License category created successfully",
        });
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false, status: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedLicense(landId, router) {
      try {
        set({ deleteLoading: true });
        await TmsLicenseService.deleteCreatedLicense(landId);
        notification.success({
          message: `License with ID:${landId} Deleted Successful`,
        });
        set({ deleteLoading: false });
        get().fetchCreatedLicenses(1, 1000, "ASC", "createdAt");
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchLicenseLeases(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getCreatedLicenseLeases(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({ createdLicenseLeaseData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLeaseInvoices(
      licenseleaseId,
      latest,
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getLicenseLeaseInvoices(
          licenseleaseId,
          latest,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({ loading: false });
        return res;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLeaseInvoiceByID(invoiceID, router) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getInvoiceByInvoiceID(invoiceID);
        set({ loading: false });
        return res;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleLeaseCreation(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(licenseLeaseCreationStates);

        if (!get().leaseApprovedByCED) {
          if (get().reason === "") {
            throw new Error(
              "Please enter the lease approval reason in reason field"
            );
          }
        }

        const body = {
          licenseFee: Number(get().licenseFee),
          licensePeriodStartDate: moment(get().licensePeriodStartDate).format(
            "YYYY-MM-DD"
          ),
          licensePeriodEndDate: moment(get().licensePeriodEndDate).format(
            "YYYY-MM-DD"
          ),
          dueDate: Number(get().dueDate),
          gstPercentage: get().gstAmount,
          natureOfBusiness: get().natureOfBusiness,
          paymentTerm: get().paymentTerm,
          paymentType: get().paymentType,
          depositAmount: Number(get().depositAmount),
          mrNo: get().mrNo,
          mrDate: moment(get().mrDate).format("YYYY-MM-DD"),
          licenseEnhancement: get().licenseEnhancement,
          licenseeType: get().licenseeType?.toUpperCase(),
          licenseeName: get().licenseeName,
          //   lesseName: get().lesseName,
          mobileNumber: `+91${get().mobileNumber}`,
          address: get().address,
          village: get().village,
          mandal: get().mandal,
          district: get().district,
          state: get().state,
          pincode: get().zip,
          idProofType: get().idProofType,
          idProofNumber: get().idProofNumber,
          uploadIdProof: get().uploadIdProof[0]?.url,
          leaseApprovedByCED: get().leaseApprovedByCED,
          eoProceedingUploadDocument: get().eoProceedingUploadDocument[0]?.url,
          status: get().status,
          licenseId: get().selectedLicense?.id,
        };

        if (get().licenseEnhancement) {
          body.enhancementStartPeriod = moment(
            get().enhancementStartPeriod
          ).format("YYYY-MM-DD");
          body.enhancementEndPeriod = moment(get().enhancementEndPeriod).format(
            "YYYY-MM-DD"
          );
          body.enhancementPercentage = get().enhancementPercentage;
        }

        if (get().licenseeType !== "individual") {
          if (get().gstNo !== "") {
            if (!regex.test(get().gstNo)) {
              throw new Error(`Invalid GST Number`);
            }
          }
          body.gstNo = get().gstNo;
          body.contact_person_name = get().contact_person_name;
          body.incorporationOrPanNo = get().incorporationOrPanNo;
        }
        if (get().leaseApprovedByCED) {
          get().checkForEmptyStates(
            [
              "uploadCEDOrder",
              "uploadApprovedDocumentOrderNo",
              "uploadApprovedDocumentOrderDate",
            ],
            []
          );
          body.uploadCEDOrder = get().uploadCEDOrder[0]?.url;
          body.uploadApprovedDocumentOrderNo =
            get().uploadApprovedDocumentOrderNo;
          body.uploadApprovedDocumentOrderDate = moment(
            get().uploadApprovedDocumentOrderDate
          ).format("YYYY-MM-DD");
        } else {
          body.reason = get().reason;
        }

        if (get().remarks !== "") {
          body.remarks = get().remarks;
        }

        await TmsLicenseService.createLicenseLeases(body);
        notification.success({ message: "License Lease created successfully" });
        await get().clearStates(licenseLeaseCreationStates, [
          ...licenseLeaseAddtionalStates,
          ...["institutionData", "createdLicenseData"],
        ]);
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleLeaseUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          licenseFee: Number(record.licenseFee),
          licensePeriodStartDate: moment(record.licensePeriodStartDate).format(
            "YYYY-MM-DD"
          ),
          licensePeriodEndDate: moment(record.licensePeriodEndDate).format(
            "YYYY-MM-DD"
          ),
          dueDate: record.dueDate,
          gstPercentage: record.gstPercentage,
          paymentTerm: record.paymentTerm,
          paymentType: record.paymentType,
          depositAmount: record.depositAmount,
          mrNo: record.mrNo,
          mrDate: moment(record.mrDate).format("YYYY-MM-DD"),
          licenseType: record.licenseType,
          licenseeName: record.licenseeName,
          mobileNumber: record.mobileNumber,
          address: record.address,
          village: record.village,
          mandal: record.mandal,
          district: record.district,
          state: record.state,
          pincode: record.pincode,
          idProofType: record.idProofType,
          idProofNumber: record.idProofNumber,
          uploadIdProof: record.uploadIdProof,
          leaseApprovedByCED: record.leaseApprovedByCED,
          eoProceedingUploadDocument: record.eoProceedingUploadDocument,
          status: record.status,
          licenseId: record.licenseId,
        };
        if (record.licenseeType === "institution") {
          body.gstNo = record.gstNo;
          body.incorporationOrPanNo = record.incorporationOrPanNo;
        }
        if (record.leaseApprovedByCED) {
          body.uploadCEDOrder = record.uploadCEDOrder;
          body.uploadApprovedDocumentOrderNo =
            record.uploadApprovedDocumentOrderNo;
          body.uploadApprovedDocumentOrderDate =
            record.uploadApprovedDocumentOrderDate;
        } else {
          body.reason = record.reason;
        }

        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }

        await TmsLicenseService.updateCreatedLicenseLease(body, record.id);
        notification.success({ message: "License Lease Updated succesfully" });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedLease(licenseId, router) {
      try {
        set({ deleteLoading: true });
        await TmsLicenseService.deleteCreatedLicenseLease(licenseId);
        notification.success({
          message: `License Lease with ID:${licenseId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },

    //arrear
    async fetchArrears(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getCreatedArrears(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy,
          router
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({ createdArrearsData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchArrearsByLease(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getCreatedArrearsByLease(
          get().selectedLicenseLease?.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy,
          router
        );
        set({ createdArrearsData: res, loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleArrearCreation(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(arrearLicenseStates);
        if (!get().legalAction && get().legalReason === "") {
          throw new Error(
            "Please enter the lease approval reason in reason field"
          );
        }
        const body = {
          arrearAmount: Number(get().arrearAmount),
          interestAmount: Number(get().interestAmount),
          arrearPeriodInMonths: Number(get().arrearPeriodInMonths),
          legalAction: get().legalAction,
          status: true,
          licenseLeaseId: get().selectedLicenseLease?.id,
        };
        if (!get().legalAction) {
          body.reason = get().legalReason;
        } else {
          body.caseNumber = get().caseNo;
          body.file = get().casePDF[0]?.url;
        }
        if (get().remarks !== "") {
          body.remarks = get().remarks;
        }
        await TmsLicenseService.createArrear(body);
        notification.success({
          message: "Arrear has been successfully creation.",
        });
        await get().clearStates(
          arrearLicenseStates,
          additionalLicenseArrearStates
        );
        await get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleArrearUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          arrearAmount: record.arrearAmount,
          interestAmount: record.interestAmount,
          arrearPeriodInMonths: Number(record.arrearPeriodInMonths),
          legalAction: record.legalAction,
          status: record.status,
          licenseLeaseId: record.licenseLeaseId,
        };

        if (!record.legalAction) {
          body.reason = record.reason;
        } else {
          body.caseNumber = record.caseNumber;
          body.file = record.file;
        }
        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }

        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }

        await TmsLicenseService.updateCreatedArrears(body, record.id);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleArrearBillUpdate(id, body, router) {
      set({ submitLoading: true });
      try {
        await TmsLicenseService.updateArrearBill(body, id);
        notification.success({
          message: "Arrear Bill has been successfully updated.",
        });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedArrear(arrearId, router) {
      try {
        set({ deleteLoading: true });
        await TmsLicenseService.deleteCreatedArrear(arrearId);
        notification.success({
          message: `Arrear with ID:${arrearId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchTotalReports(
      type,
      tableCurrentPage,
      tablePageSize,
      // sortType,
      // sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getCreatedReportsByType(
          type,
          tableCurrentPage,
          tablePageSize
          // sortType,
          // sortBy
        );
        if (type === "BUILDING") {
          set({
            buildingReport: res,
          });
        } else if (type === "ENCROACHMENT") {
          set({
            encroachmentReport: res,
          });
        } else if (type === "LEASE") {
          set({
            leaseReports: res,
          });
        } else if (type === "ARREAR") {
          set({
            arrearReport: res,
          });
        }
        set({ loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchBuildingReports(
      tableCurrentPage,
      tablePageSize,
      // sortType,
      // sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getCreatedLicenseReports(
          get().selectedInstitution ?? "",
          tableCurrentPage,
          tablePageSize
          // sortType,
          // sortBy
        );
        set({
          landReports: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLeaseReports(
      tableCurrentPage,
      tablePageSize,
      leaseStartDate,
      leaseEndDate,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getLeaseReports(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          moment(leaseStartDate).format("YYYY-MM-DD"),
          moment(leaseEndDate).format("YYYY-MM-DD")
        );
        set({
          leaseReports: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchArrearReports(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsLicenseService.getArrearsReports(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          arrearReport: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleExcelExport(type, router) {
      set({ excelLoading: true });
      const funtionType = {
        license: get().fetchCreatedLicenses,
        lease: get().fetchLicenseLeases,
        arrear: get().fetchArrears,
      };
      try {
        const data = await funtionType[type](
          1,
          1000,
          "ASC",
          "createdAt",
          router,
          "return"
        );
        if (data.length === 0) {
          notification.info({ message: "No records found to Export" });
          set({ excelLoading: false });
          return;
        }
        exportExcel(data, `${type}-${moment().format("DD-MM-YYYY")}`, () => {
          set({ excelLoading: false });
        });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token expired!! Please login again.",
          router
        );
        set({ excelLoading: false });
      }
    },
  };
});
export default useLicensesStore;
