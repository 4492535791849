/* eslint-disable no-undef */
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { BaseContext } from "../../../../contexts/BaseContext";
import TmsAuthService from "../../../../services/tms-user/tms-auth.service";
import TmsStringUtils from "../../../../utils/tms-string-utils/tms-string-utils";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import HeaderDropdown from "./tms-headerdropdown";

const TmsAvatarDropdown = ({ router }) => {
  const { userDetails, clearData, clearLocalStorage } = useContext(BaseContext);

  const [version, setVersion] = useState();
  useEffect(() => {
    if (process.env.REACT_APP_ELECTRON) {
      try {
        window.ipcRenderer.send("app_version");
        window.ipcRenderer.on("app_version", (event, arg) => {
          window.ipcRenderer.removeAllListeners("app_version");
          setVersion(arg.version);
          // console.log(arg.version);
        });
      } catch (e) {
        //
      }
    }
  }, []);

  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} style={{ maxWidth: "160px" }}>
      <Menu.Item
        key="user"
        style={{
          textOverflow: "ellipsis",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {TmsStringUtils.CaptilizeWords(
          userDetails?.user?.displayName ?? userDetails?.user?.phone
        )}
      </Menu.Item>
      <Menu.Divider />
      {/* <Menu.Item key="settings">
        <SettingOutlined />
        <span style={{ marginRight: "5px" }}>Settings</span>
      </Menu.Item> */}
      {process.env.REACT_APP_ELECTRON === true && (
        <>
          <Menu.Divider />
          <Menu.Item key="version">
            V - {version}
          </Menu.Item> <Menu.Divider />{" "}
        </>
      )}
      <>
        <Menu.Item
          onClick={() => {
            router.navigate(tmsRouteMap?.profile);
          }}
          style={{ textAlign: "center" }}
          key="version"
        >
          Profile
        </Menu.Item>{" "}
        <Menu.Divider />{" "}
      </>
      <Menu.Item
        key="logout"
        onClick={async () => {
          await TmsAuthService.logout();
          clearLocalStorage();
          clearData();
          router.navigate(tmsRouteMap.login);
        }}
      >
        <LogoutOutlined style={{ marginRight: "5px" }} />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span>
        <UserOutlined />
      </span>
    </HeaderDropdown>
  );
};

export default withRouter(TmsAvatarDropdown);
