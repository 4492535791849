import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsBusAdd from "./tms-sm-inner-pages-bus/tms-sm-bus-add";
import TmsSmBusAll from "./tms-sm-inner-pages-bus/tms-sm-bus-all";

const TmsSmBus = ({ router }) => {
  const [active, setActive] = useState("all");
  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.sm_bus) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sm_products_and)) {
      setActive("Add New Bus");
    } else if (location.includes(tmsRouteMap.sm_bus_manage_pos)) {
      setActive("all");
      return;
    }
  }, [router?.location?.pathname]);

  const location = router?.location?.pathname;
  const components = [
    {
      name: location.includes(tmsRouteMap.sm_bus_manage_pos)
        ? "Manage POS"
        : "All",
      Element: location.includes(tmsRouteMap.sm_bus_manage_pos) ? (
        <TmsCmsSlots type="bus-counters" />
      ) : (
        <TmsSmBusAll />
      ),
      key: "all",
      route: tmsRouteMap.sm_bus,
    },
    {
      name: "Add New Bus",
      Element: <TmsBusAdd />,
      key: "Add New Bus",
      route: tmsRouteMap.sm_bus_add,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmBus);
