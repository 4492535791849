import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportBusReport from "./tms-report-bus-report";

const TmsReportBus = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.reports_bus) {
      setActive("Bus Report");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Bus Report",
      Element: <TmsReportBusReport />,
      key: "Bus Report",
      route: tmsRouteMap.reports_bus,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportBus);
