import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportTollgateReport from "./tms-report-tollgate-report";
import TmsReportTollgateSummaryReport from "./tms-report-tollgate-summary-report";

const TmsReportTollgate = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.reports_tollgate) {
      setActive("Tollgate Report");
      return;
    } else if (location.includes(tmsRouteMap.reports_tollgate_summary_report)) {
      setActive("Summary Report");
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Tollgate Report",
      Element: <TmsReportTollgateReport />,
      key: "Tollgate Report",
      route: tmsRouteMap.reports_tollgate,
    },

    {
      name: "Summary Report",
      Element: <TmsReportTollgateSummaryReport />,
      key: "Summary Report",
      route: tmsRouteMap.reports_tollgate_summary_report,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportTollgate);
