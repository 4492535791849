import { Col, notification, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsTable from "../../../../components/tms-customs/tms-table";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsManualEntryService from "../../../../services/tms-manual-entry.service";

const TmsReportManualReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  //   const [bookingType, setBookingType] = useState("pos");
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [sortType, setSortType] = useState("DESC");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [Book, setBook] = useState("");
  const [bookList, setBookList] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);

  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);

  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);

  useEffect(() => {
    fetchBookList();
    fetchCounterList();
    fetchShiftList();
  }, []);
  useEffect(() => {
    fetchBookingDetails();
  }, [
    bookingFromDate,
    bookingToDate,
    sortType,
    tableCurrentPage,
    tablePageSize,
    counter,
    shift,
    Book,
    // bookingType,
  ]);

  const fetchBookList = async () => {
    try {
      let res = await TmsManualEntryService.getAll(1, 100);
      setBookList(res.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };

  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      let data = await TmsManualEntryService.manualEntryReport(
        "json",
        Number(tableCurrentPage),
        50000,
        bookingFromDate,
        bookingToDate,
        sortType,
        counter,
        shift,
        Book
      );
      data = data?.items?.reverse();
      let compiledDataOffile = [];
      {
        compiledDataOffile = data?.map((item, i) => {
          return {
            "S.no": i + 1,
            "POS Name": item.counterName,
            "Shift ID": item.counterShiftTimingId,
            "Transaction Id": item.categoryTransactionSequence
              ?.split("-")
              ?.pop(),
            "Serial No": item.dailyCategoryTransactionSequence
              ?.split("-")
              ?.pop(),
            "Booking Id": item.bookingId,
            "Book Name": item.donationName,
            "Series Start": item.seriesStart,
            "Series End": item?.seriesEnd,
            Category: item?.categories[0]?.manualEntryBookCategory?.name,
            Price: item.categories[0]?.manualEntryBookCategory?.price,
            Quantity: item.categories[0]?.quantity,
            Amount: item.amount,
            "Booked on": moment(item?.createdDate).format("DD-MM-YYYY hh:mm A"),
          };
        });
      }
      exportExcel(
        compiledDataOffile,
        `manual-entry-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "POS Name",
      dataIndex: "counterName",
      key: "counterName",
    },

    {
      title: "Txn Id",
      dataIndex: "categoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "S No",
      dataIndex: "dailyCategoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "Book Name",
      dataIndex: "bookName",
      key: "bookName",
    },
    {
      title: "Series Start",
      dataIndex: "seriesStart",
      key: "seriesStart",
    },
    {
      title: "Series End",
      dataIndex: "seriesEnd",
      key: "seriesEnd",
    },
    {
      title: "Category",
      dataIndex: "categories",
      key: "categories",
      render: (record) => {
        return record[0]?.manualEntryBookCategory?.name;
      },
    },
    {
      title: "Price",
      dataIndex: "categories",
      key: "categories",
      render: (record) => {
        return `Rs. ${record[0]?.manualEntryBookCategory?.price}`;
      },
      sorter: (a, b) => a.price > b.price,
    },
    {
      title: "Quantity",
      dataIndex: "categories",
      key: "categories",
      render: (record) => {
        return `${record[0]?.quantity}`;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return `Rs. ${text}`;
      },
      sorter: (a, b) => a.amount > b.amount,
    },
    {
      title: "Booked On",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY hh:mm A");
      },
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.createdDate > b.createdDate,
    },
  ];

  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await TmsManualEntryService.manualEntryReport({
        format: "json",
        tableCurrentPage: Number(tableCurrentPage),
        tablePageSize: Number(tablePageSize),
        fromDate: bookingFromDate,
        toDate: bookingToDate,
        sortType,
        counter,
        shift,
        Book,
      });
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Book Name"
          setValue={(e) => {
            setBook(e);
          }}
          value={Book}
          data={bookList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="POS"
          setValue={setCounter}
          value={counter}
          data={counterList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shift"
          setValue={setShift}
          value={shift}
          data={shiftList}
        />
        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <TmsExcelButton
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={true}
        />
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </>
  );
};

export default TmsReportManualReport;
