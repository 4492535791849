import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  notification,
} from "antd";
import React, { useRef, useState } from "react";
import moment from "moment";
import { RightOutlined } from "@ant-design/icons";
import TmsStringUtils from "../../../../utils/tms-string-utils/tms-string-utils";
import { getParamByParam } from "iso-country-currency";
import "../../../../assets/css/tms-parakamani/tms-parakamani.scss";
import TmsSmParakamaniPersons from "../../../../components/tms-service-management/tms-sm-parakamani/tms-sm-parakamani-persons";
import TmsSmParakamaniHundi from "../../../../components/tms-service-management/tms-sm-parakamani/tms-sm-parakamani-hundi";
import TmsSmParakamaniJewellery from "../../../../components/tms-service-management/tms-sm-parakamani/tms-sm-parakamani-jewellery";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import TmsHundiService from "../../../../services/tms-hundi.service";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";

const TmsSmParakamani = ({ router }) => {
  const [installedDate, setInstalledDate] = useState(moment());
  const [openedDate, setOpenedDate] = useState(moment());
  const [MRDate, setMRDate] = useState(moment());
  const [hundisOpened, setHunduisOpened] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [hundisInstalled, setHundisInstalled] = useState();
  const [hundiPicList, setHundiPicList] = useState([]);
  const [MRno, setMRno] = useState();
  const ref = useRef(null);
  const [hundiCurrencyArray, SetHundiCurrencyArray] = useState([
    {
      id: 0,
      countryName: "India",
      currency: getParamByParam("countryName", "India", "currency"),
      symbol: getParamByParam("countryName", "India", "symbol"),
      validBills: [
        {
          countryId: 0,
          type: "notes",
          bills: [{ denomination: 0, quantity: 0 }],
        },
        {
          countryId: 0,
          type: "coins",
          bills: [{ denomination: 0, quantity: 0 }],
        },
      ],
      invalidBills: [
        {
          countryId: 0,
          type: "notes",
          bills: [{ denomination: 0, quantity: 0 }],
        },
        {
          countryId: 0,
          type: "coins",
          bills: [{ denomination: 0, quantity: 0 }],
        },
      ],
    },
  ]);
  const [jewelleryArray, setJewelleryArray] = useState([
    {
      type: "",

      desc: "",
      weightKgs: 0,
      weightGms: 0,
      weightMgs: 0,
      fileURl: [],
    },
  ]);
  const [personsArray, setPersonsArray] = useState([
    { name: "", designation: "", address: "" },
  ]);
  const [itemVerified, setItemVerified] = useState(false);
  const [goldsmithName, setGoldsmithName] = useState("");
  const [goldsmithNumber, setGoldSmithNumber] = useState("");
  const [classification, setClassification] = useState("");

  const StatesClear = () => {
    setInstalledDate(moment());
    setOpenedDate(moment());
    setMRDate(moment());
    setHunduisOpened(null);
    setButtonLoading(false);
    setHundiPicList([]);
    setMRno(null);
    setClassification("");
    SetHundiCurrencyArray([
      {
        id: 0,
        countryName: "India",
        currency: getParamByParam("countryName", "India", "currency"),
        symbol: getParamByParam("countryName", "India", "symbol"),
        validBills: [
          {
            countryId: 0,
            type: "notes",
            bills: [{ denomination: 0, quantity: 0 }],
          },
          {
            countryId: 0,
            type: "coins",
            bills: [{ denomination: 0, quantity: 0 }],
          },
        ],
        invalidBills: [
          {
            countryId: 0,
            type: "notes",
            bills: [{ denomination: 0, quantity: 0 }],
          },
          {
            countryId: 0,
            type: "coins",
            bills: [{ denomination: 0, quantity: 0 }],
          },
        ],
      },
    ]);
    setJewelleryArray([
      {
        type: "",

        desc: "",
        weightKgs: 0,
        weightGms: 0,
        weightMgs: 0,
        fileURl: [],
      },
    ]);
    setPersonsArray([{ name: "", designation: "", address: "" }]);
    setItemVerified(false);
    setGoldsmithName(null);
    setGoldSmithNumber(null);
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    let currencyTemp = [];
    let body = {};

    if (
      moment(installedDate).format("DD-MM-YYYY") ===
      moment(openedDate).format("DD-MM-YYYY")
    ) {
      notification?.error({
        message: "Hundis opened date and installed date can't be same",
      });
      setButtonLoading(false);
      return;
    }

    if (Number(hundisOpened) <= 0 || hundisOpened === undefined) {
      notification?.error({ message: "Hundis opened must be greater than 0" });
      setButtonLoading(false);
      return;
    }
    if (classification === "") {
      notification?.error({ message: "Please enter classification" });
      setButtonLoading(false);
      return;
    }

    personsArray?.forEach((item) => {
      if (item?.name?.length <= 2) {
        notification?.error({
          message: "Person present name must be greater that 2",
        });
        setButtonLoading(false);
        return;
      }
      if (
        item?.designation === "" ||
        item?.designation === undefined ||
        !item?.designation
      ) {
        notification?.error({
          message: "Person present desgination is invalid",
        });
        setButtonLoading(false);
        return;
      }
    });

    hundiCurrencyArray.map((item) => {
      const validBills = item?.validBills || [];
      const invalidBills = item?.invalidBills || [];

      const validCurrency = validBills.flatMap((billsType) =>
        (billsType?.bills || []).map((bills) => {
          return {
            currencyType: billsType?.type?.toUpperCase() || "",
            validCurrency: true,
            denomination: Number(bills?.denomination || 0),
            quantity: Number(bills?.quantity || 0),
            amount:
              Number(bills?.denomination || 0) * Number(bills?.quantity || 0),
          };
        })
      );

      const invalidCurrency = invalidBills.flatMap((billsType) =>
        (billsType?.bills || []).map((bills) => {
          return {
            currencyType: billsType?.type?.toUpperCase() || "",
            validCurrency: false,
            denomination: Number(bills?.denomination || 0),
            quantity: Number(bills?.quantity || 0),
            amount:
              Number(bills?.denomination || 0) * Number(bills?.quantity || 0),
          };
        })
      );

      currencyTemp = [
        ...currencyTemp,
        {
          country: item?.countryName,
          currencyType: item?.currency,
          currency: [...validCurrency, ...invalidCurrency].filter((res) => {
            return res?.amount > 0;
          }),
        },
      ];
    });
    let error = false;
    currencyTemp?.some((item) => {
      let filter = currencyTemp?.filter((cou) => {
        return cou?.country === item?.country;
      })?.length;
      if (filter > 1 || item?.currency?.length === 0) {
        notification?.error({
          message:
            filter > 1
              ? `${item?.country} is already selected`
              : `Please enter hundi count for ${item?.country}`,
        });
        error = true;
        setButtonLoading(false);
        return true;
      }
    });
    if (error) {
      return;
    }

    var jwelleryError = false;
    jewelleryArray?.map((item) => {
      if (item?.type?.length > 0) {
        // if (item?.name?.length === 0) {
        //   notification?.error({ message: "Please enter item name" });
        //   setButtonLoading(false);
        //   return;
        // }
        if (
          item?.weightKgs === 0 &&
          item?.weightGms === 0 &&
          item?.weightMgs === 0
        ) {
          notification?.error({ message: "Please enter item weight" });
          jwelleryError = true;
          setButtonLoading(false);
          return;
        }
        if (item?.desc?.length === 0) {
          notification?.error({ message: "Please enter description" });
          jwelleryError = true;
          setButtonLoading(false);
          return;
        }
        // if (
        //   item?.fileURl[0]?.url?.length === 0 ||
        //   item?.fileURl[0]?.url === undefined
        // ) {
        //   notification?.error({ message: "Please upload jewellery image" }); // need to uncomment when it is mandatory
        //   setButtonLoading(false);
        //   return;
        // }
      }
    });
    if (jwelleryError) {
      return;
    }

    body = {
      hundiInstalledDate: moment(installedDate).format("YYYY-MM-DD"),
      hundiOpenedDate: moment(openedDate).format("YYYY-MM-DD"),
      totalInstalledHundis: Number(hundisOpened),
      mrNumber: MRno,
      classification: classification,
      mrDate: moment(MRDate).format("YYYY-MM-DD"),
      personsPresent: personsArray,
      hundiCountingImages: hundiPicList?.map((item) => {
        return item?.url;
      }),
      currency: currencyTemp,
      jewellery: jewelleryArray
        ?.filter((item) => {
          return item?.type?.length > 1;
        })
        ?.map((item) => {
          return {
            jewelleryType: item?.type,
            // itemName: item?.name,
            weightInKgs: Number(item?.weightKgs),
            weightInGms: Number(item?.weightGms),
            weightInMgs: Number(item?.weightMgs),
            description: item?.desc,
            jewelleryImage: item?.fileURl?.map((img) => {
              return img?.url;
            }),
            itemVerifiedByGoldSmith: itemVerified,
          };
        }),
    };

    if (itemVerified) {
      if (goldsmithName === "" || !goldsmithName) {
        notification?.error({ message: "Please enter goldsmith name" });
        setButtonLoading(false);
        return;
      }

      if (
        goldsmithNumber === "" ||
        !goldsmithNumber ||
        goldsmithNumber?.length < 10
      ) {
        notification?.error({ message: "Please enter valid goldsmith number" });
        setButtonLoading(false);
        return;
      }
      body.jewelleryGoldsmith = {
        goldsmithName: goldsmithName,
        contactNumber: goldsmithNumber,
      };
    }

    if (MRno?.length === 0 || MRno === undefined) {
      notification?.error({
        message: "Please enter valid MR Number",
      });
      setButtonLoading(false);
      return;
    }

    try {
      await TmsHundiService.createHundi(body);
      notification?.success({ message: "Data entered successfully" });
      StatesClear();
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
  };

  return (
    <div className="parakamani">
      <div className="parakamaniChild">
        <Row gutter={[20, 20]}>
          <Col span={6}>
            <Row className="mb-5">
              <label>
                Hundi Installed Date
                <span className="mandatory">*</span>
              </label>
            </Row>
            <DatePicker
              format={"DD-MM-YYYY"}
              className="w-full"
              picker={"date"}
              defaultValue={installedDate}
              value={installedDate}
              onChange={(e) => {
                setInstalledDate(e);
              }}
              allowClear={false}
              disabledDate={(d) =>
                !d || d.isAfter(TmsStringUtils.addDays(new Date(), 0))
              }
            />
          </Col>
          <Col span={6}>
            <Row className="mb-5">
              <label>
                Hundi Opened Date
                <span className="mandatory">*</span>
              </label>
            </Row>
            <DatePicker
              format={"DD-MM-YYYY"}
              className="w-full"
              picker={"date"}
              defaultValue={openedDate}
              value={openedDate}
              onChange={(e) => {
                setOpenedDate(e);
              }}
              allowClear={false}
              disabledDate={(d) =>
                !d || d.isAfter(TmsStringUtils.addDays(new Date(), 0))
              }
            />
          </Col>
          <Col span={6}>
            <Row className="mb-5">
              <label>Hundi Period</label>
            </Row>
            <Input
              maxLength={220}
              disabled={true}
              value={moment(openedDate).diff(moment(installedDate), "days") + 1}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={[20, 20]}>
          {/* <Col span={6}>
            <Row className="mb-5">
              <label>
                Total No. of Hundi Installed
                <span className="mandatory">*</span>
              </label>
            </Row>
            <Input
              placeholder="Enter No. of Hundis Installed"
              type="number"
              value={hundisInstalled}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onChange={(e) => {
                setHundisInstalled(e.target.value);
              }}
            />
          </Col> */}
          <Col span={6}>
            <Row className="mb-5">
              <label>
                Total No. of Hundi Opened
                <span className="mandatory">*</span>
              </label>
            </Row>
            <Input
              maxLength={220}
              placeholder="Enter No. of Hundis Opened"
              type="number"
              min={0}
              value={Number(hundisOpened)}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onChange={(e) => {
                if (e.target.value > 99 || e.target.value < 0) {
                  return;
                }
                setHunduisOpened(e.target.value);
              }}
            />
          </Col>
          <Col span={6}>
            <Row className="mb-5">
              <label>
                Classification
                <span className="mandatory">*</span>
              </label>
            </Row>
            <Input
              placeholder="Enter classification"
              type="text"
              value={classification}
              onChange={(e) => {
                setClassification(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Divider />
        <TmsSmParakamaniPersons
          personsArray={personsArray}
          setPersonsArray={setPersonsArray}
        />
        <Divider />
        <TmsSmParakamaniHundi
          hundiCurrencyArray={hundiCurrencyArray}
          SetHundiCurrencyArray={SetHundiCurrencyArray}
          hundiPicList={hundiPicList}
          setHundiPicList={setHundiPicList}
        />
        <Divider />
        <TmsSmParakamaniJewellery
          jewelleryArray={jewelleryArray}
          setJewelleryArray={setJewelleryArray}
          itemVerified={itemVerified}
          setItemVerified={setItemVerified}
          goldsmithName={goldsmithName}
          setGoldsmithName={setGoldsmithName}
          goldsmithNumber={goldsmithNumber}
          setGoldSmithNumber={setGoldSmithNumber}
        />
        <br />
        <label style={{ fontWeight: "bold" }}>MR Details</label>
        <br />
        <Row gutter={[20, 20]}>
          <Col span={6}>
            <Row className="mb-5">
              <label>
                MR no. <span className="mandatory">*</span>
              </label>
            </Row>
            <Input
              placeholder="Enter MR no."
              value={MRno}
              onChange={(e) => {
                setMRno(e.target.value);
              }}
            />
          </Col>
          <Col span={6}>
            <Row className="mb-5">
              <label>
                MR Date <span className="mandatory">*</span>
              </label>
            </Row>
            <DatePicker
              format={"DD-MM-YYYY"}
              className="w-full"
              picker={"date"}
              defaultValue={MRDate}
              value={MRDate}
              onChange={(e) => {
                setMRDate(e);
              }}
              allowClear={false}
              disabledDate={(d) =>
                !d || d.isAfter(TmsStringUtils.addDays(new Date(), 0))
              }
            />
          </Col>
        </Row>
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              ref={ref}
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                buttonLoading === false && handleSubmit();
              }}
              loading={buttonLoading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(TmsSmParakamani);
