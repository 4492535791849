import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import {
  Button,
  Col,
  DatePicker,
  Row,
  notification,
  Modal,
  Card,
  Dropdown,
  Menu,
  Switch,
  Input,
} from "antd";
import moment from "moment";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { RightOutlined } from "@ant-design/icons";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import "../../../assets/css/tms-tollgate/tms-pos-tollgate.scss";
import {
  dragEnter,
  dragStart,
  drop,
  updateData,
} from "../../../utils/tms-card-dragger/tms-card-dragger";
import TmsBusService from "../../../services/tms-bus-service";
import "../../../assets/css/tms-prasadam/tms-pos-prasadam.scss";

const TmsPosBus = ({ router }) => {
  const ref = useRef(null);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [routeList, setRouteList] = useState([]);
  // const [vehicleNumber, setVehicleNumber] = useState("");
  const [array, setArray] = useState([]);
  const [externalPrint, setExternalPrint] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateFlag, setUpdateFlag] = useState();
  const [menuVisible, setMenuVisible] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedBusIds, setSelectedBusIds] = useState([]);
  const [busCount, setBusCount] = useState([]);

  const toggleSwitch = (id) => {
    let newIds = [];
    if (selectedBusIds?.includes(id)) {
      newIds = selectedBusIds.filter((e) => e !== id);
    } else {
      newIds = [...selectedBusIds, id];
    }
    console.log(newIds);
    setSelectedBusIds(newIds);
    localStorage.setItem("selectedCounterBusIds", newIds);
  };

  const menu = (
    <Menu onClick={() => setMenuVisible(true)}>
      {fetchedData.map((item) => {
        return (
          <Menu.Item
            key={item?.id}
            onClick={() => {
              toggleSwitch(item?.id);
            }}
          >
            {item?.product?.name}
            <Switch
              size="small"
              style={{ float: "right" }}
              checked={selectedBusIds?.includes(item?.id)}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const dragItem = useRef();
  const dragOverItem = useRef();

  const BusData = (data) => {
    let schemaBody = [];

    data?.cartItems?.bus_ticket?.forEach((item) => {
      const basicBody = [
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
        ],
        [
          "Ticket Id",
          item?.counterCartItem?.ticketId?.split("-")?.pop(),
          { margin: "0" },
        ],
      ];
      const generalBody = [
        ["Date", moment().format("DD-MM-YYYY"), { margin: "0" }],
        ["Route", item?.productDetails?.productName, { margin: "0" }],

        [
          "Ticket Price",
          `Rs. ${item?.productDetails?.productActualPrice}.00`,
          { margin: "0" },
        ],
        ["Quantity", item?.busTicketBooking?.numberOfTickets, { margin: "0" }],
        ["Validity", item?.productDetails?.validity, { margin: "0" }],
        [
          "Total Amount",
          `Rs. ${item?.busTicketBooking?.amount}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.busTicketBooking?.amount)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
      const miniTicketBody = [
        ["Vehicle", item?.productDetails?.productName, { margin: "0" }],
        ["Validity", item?.productDetails?.validity, { margin: "0" }],
        [
          "Amount",
          `Rs. ${item?.busBooking?.amount}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          null,
          item?.counterCartItem?.createdAt,
        ],
      ];
      if (item?.productDetails?.productMetadata?.ticketType === "mini_ticket") {
        schemaBody = [...schemaBody, ...basicBody, ...miniTicketBody];
      } else {
        schemaBody = [...schemaBody, ...basicBody, ...generalBody];
      }
    });
    setArray([...schemaBody]);
  };

  useEffect(() => {
    fetchBusList();
  }, [updateFlag]);

  useEffect(() => {
    const localStorageSelectedBusIds = localStorage.getItem(
      "selectedCounterBusIds"
    );

    if (localStorageSelectedBusIds) {
      const ids = localStorageSelectedBusIds.split(",").map(Number);
      setSelectedBusIds(ids);
    } else {
      setSelectedBusIds([]);
    }
  }, []);

  const fetchBusList = async () => {
    setButtonLoading(true);
    try {
      let resp = await TmsBusService.fetchBusByCounters();
      setBusCount(
        resp.map((item) => {
          return {
            id: item?.product?.id,
            price: item?.product?.price,
            count: "",
          };
        })
      );
      setFetchedData(resp);
      updateData(resp, setRouteList, "routeList");
      setButtonLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (buttonLoading) {
      return;
    }
    setButtonLoading(true);

    let a = false;
    busCount?.forEach((e) => {
      if (e.count !== 0 && e.count > 0) {
        a = true;
      }
    });
    if (!a) {
      notification.error({ message: "Please select atleast one bus" });

      setButtonLoading(false);
      return;
    }

    try {
      let body = {
        busItems: [],
      };
      busCount.forEach((item) => {
        if (item.count > 0 && item?.count !== 0) {
          body.busItems.push({
            busId: Number(item?.id),
            numberOfTickets: Number(item?.count),
            bookingDate: moment()?.format("YYYY-MM-DD"),
          });
        }
      });
      const resp = await TmsBusService.busBooking(body);
      BusData(resp);
      setExternalPrint(true);
      setIsModalVisible(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  const getAmount = () => {
    let amount = 0;
    busCount.forEach((e) => {
      if (e?.count === 0) {
        return;
      } else {
        amount += e?.count * e?.price;
      }
    });
    return amount;
  };

  const handleVisibleChange = () => {
    if (menuVisible) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
          textAlign: "left",
        }}
      >
        <Row style={{ justifyContent: "space-between" }}>
          <Col style={{ width: "48%" }}>
            <label>
              Booking Date & Time
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <DatePicker
              style={{ width: "100%" }}
              value={moment()}
              format="DD-MM-YYYY hh:mm:ss A"
              disabled
            />
          </Col>
          <Col style={{ width: "48%", justifyContent: "start" }}>
            <label className="labelStyles">Bus List </label>
            <br />{" "}
            <Dropdown
              overlay={menu}
              placement="bottomLeft"
              onVisibleChange={() => handleVisibleChange()}
              visible={menuVisible}
            >
              <Button
                style={{ width: "100%", textAlign: "left" }}
                size="medium"
              >
                Select Bus
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <br />
        <Row
          style={{
            backgroundColor: "#ECF0F5",
            borderRadius: "2px 2px 0px 0px",
            paddingRight: "18px",
          }}
        >
          <Col
            style={{
              width: "20%",
              padding: "15px 10px",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            Item Name
          </Col>

          <Col
            style={{
              width: "20%",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Unit Price
          </Col>
          <Col
            style={{
              width: "20%",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Validity
          </Col>
          <Col
            style={{
              width: "20%",
              display: "flex",
              alignItems: "center",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Quantity
          </Col>
          <Col
            style={{
              width: "20%",
              display: "flex",
              alignItems: "center",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Net Amount
          </Col>
        </Row>
        <Row className="VehicleTypeDiv">
          {routeList
            ?.filter((item) => selectedBusIds?.includes?.(item?.id))
            ?.map((item, i) => {
              return (
                <Card
                  key={i}
                  hoverable
                  style={{ width: "100%" }}
                  className="itemCard"
                  onDragStart={(e) => dragStart(e, i, dragItem)}
                  onDragEnter={(e) => dragEnter(e, i, dragOverItem)}
                  onDragEnd={(e) =>
                    drop(
                      e,
                      setUpdateFlag,
                      dragItem,
                      dragOverItem,
                      "routeList",
                      selectedBusIds
                    )
                  }
                  draggable
                >
                  <Row style={{ padding: "0", width: "100%" }}>
                    <Col
                      style={{
                        width: "20%",
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontWeight: "600",
                        padding: "10px",
                      }}
                    >
                      {item?.product.name}
                    </Col>

                    <Col
                      style={{
                        width: "20%",
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontWeight: "600",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      Rs. {item?.product?.price}
                    </Col>
                    <Col
                      style={{
                        width: "20%",
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontWeight: "600",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      {item?.product?.validity}
                    </Col>
                    <Col
                      style={{
                        width: "20%",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      <Input
                        id={`input-navigation-${i}`}
                        tabIndex={i}
                        onWheel={(event) => event.currentTarget.blur()}
                        style={{ width: "70px", textAlign: "center" }}
                        type="number"
                        min="0"
                        max={"10"}
                        maxLength="2"
                        key="edit"
                        placeholder="0"
                        autoFocus={i === 0 ? true : false}
                        value={
                          busCount?.find((e) => e.id === item?.product?.id)
                            ?.count
                        }
                        controls={false}
                        step="false"
                        onKeyDown={(E) => {
                          if (E.key === "Enter") {
                            E.preventDefault();
                            buttonLoading === false && handleSubmit();
                          }
                          if (E.key === "ArrowUp") {
                            E.preventDefault();
                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i - 1}`)
                                ?.focus();
                            }, 0);
                          }
                          if (E.key === "ArrowDown") {
                            E.preventDefault();

                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i + 1}`)
                                ?.focus();
                            }, 0);
                          }
                        }}
                        onChange={(event) => {
                          let limit = 10;
                          if (event.target.value > limit) {
                            return;
                          } else {
                            console.log(busCount);
                            let data = [...busCount];
                            data.forEach((e) => {
                              console.log(e.id);
                              if (e?.id === item?.product?.id) {
                                e.count = event.target.value;
                              }
                            });
                            setBusCount(data);
                          }
                        }}
                      />
                    </Col>
                    <Col
                      style={{
                        width: "20%",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      <div key={i}>
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Rs.{" "}
                          {busCount?.find((e) => e.id === item?.product?.id)
                            ?.price *
                            busCount?.find((e) => e.id === item?.product?.id)
                              ?.count}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              );
            })}
        </Row>
      </div>
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "#FFFCDC",
        }}
      >
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "500",
            padding: "10px 50px",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              textAlign: "left",
              margin: "0",
            }}
          >
            Total Amount :
          </p>
          <p
            style={{
              fontSize: "24px",
              margin: "0",
              textAlign: "right",
            }}
          >
            Rs. {Number(getAmount() ?? 0)}.00
          </p>
        </Row>
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              ref={ref}
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                buttonLoading === false && handleSubmit();
              }}
              loading={buttonLoading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="Bus"
          from="Counter"
          setData={setArray}
          data={array}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setButtonLoading}
          setPrintTicketModal={setIsModalVisible}
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosBus);
