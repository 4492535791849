import { Button, Row, Space, Table } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import TmsModal from "../../components/tms-customs/tms-modal";
import { BaseContext } from "../../contexts/BaseContext";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import usePartnersStore from "../../zustand/partners.store";
import TmsAddNewPartner from "./tms-add-new-partner";

const TmsPartners = ({ router }) => {
  const {
    editFlag,
    clearStates,
    setEditData,
    setEditFlag,
    fetchPartners,
    visible,
    setVisible,
    loading,
    data,
    fetchPartnerPermissions,
    permissionsData,
    permissionFlag,
    setPermissionFlag,
    setPermisionData,
    permsisionDataColumns,
    fetchPartnersByTemple,
    fetchPartnersByTempleIDorCode,
    handleKeyRotate,
    keyPopup,
    setKeyPopup,
    setNewPartner,
    newPartner,
  } = usePartnersStore();
  const { userDetails } = useContext(BaseContext);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(20);
  const [partnerName, setPartnerName] = useState("");
  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
      align: "center",
    },
    {
      title: "Partner Name",
      key: "partnerName",
      dataIndex: "partnerName",
      align: "center",
    },
    {
      title: "Partner Code ",
      dataIndex: "partnerCode",
      align: "center",
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (record) => {
        return <div>{moment(record).format("DD-MM-YYYY HH:mm:ss")}</div>;
      },
      align: "center",
    },
    {
      title: "Actions",
      render: (record) => {
        return (
          <Space size="middle">
            {userDetails?.permissions?.includes("SUPER_ADMIN") && (
              <>
                {" "}
                <a
                  onClick={() => {
                    setEditData(record);
                    setEditFlag(true);
                    setVisible(true);
                  }}
                >
                  Edit
                </a>
                <a
                  onClick={() => {
                    handleKeyRotate("primary", record.id, router);
                  }}
                >
                  Rotate Primary
                </a>
                <a
                  onClick={() => {
                    handleKeyRotate("secondary", record.id, router);
                  }}
                >
                  Rotate Secondary
                </a>
              </>
            )}
            <a
              onClick={() => {
                setPartnerName(record?.partnerName);
                userDetails?.permissions?.includes("SUPER_ADMIN")
                  ? fetchPartnerPermissions(record.id, router, false)
                  : fetchPartnersByTempleIDorCode(
                      "Id",
                      record.id,
                      router,
                      false
                    );
              }}
            >
              View Permissions
            </a>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (userDetails?.permissions) {
      if (userDetails?.permissions?.includes("SUPER_ADMIN")) {
        fetchPartners(tableCurrentPage, tablePageSize, router);
      } else {
        fetchPartnersByTemple();
      }
    }
    return () => {
      clearStates(["data"], []);
    };
  }, [tableCurrentPage, tablePageSize, userDetails]);

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };
  return (
    <div>
      <Row justify="left">
        {" "}
        {userDetails?.permissions?.includes("SUPER_ADMIN") && (
          <Button
            onClick={() => {
              setVisible(true);
            }}
            type="primary"
          >
            Add New Partner
          </Button>
        )}
      </Row>
      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={columns}
        dataSource={
          data?.items?.map((item) => {
            return { key: item?.id, ...item };
          }) ?? []
        }
        onChange={handleTableChange}
        pagination={{
          total: data?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <TmsModal
        closable={true}
        className="DetailsModal"
        centered={true}
        width={"800px"}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setEditFlag(false);
          setEditData(null);
        }}
        title={editFlag ? "Edit Partner" : "Create Partner"}
      >
        <TmsAddNewPartner />
      </TmsModal>

      <TmsModal
        closable={true}
        className="DetailsModal"
        centered={true}
        width={"1000px"}
        visible={permissionFlag}
        onCancel={() => {
          setPermissionFlag(false);
          setPermisionData([]);
        }}
        title={"Permissions"}
      >
        <Row>
          <b style={{ marginBottom: "20px" }}>Partner Name - {partnerName}</b>

          <Table
            style={{ width: "100%" }}
            dataSource={permissionsData ?? []}
            columns={permsisionDataColumns}
            bordered
            pagination={false}
          />
        </Row>
      </TmsModal>
      <TmsModal
        visible={keyPopup}
        width={"800px"}
        onOk={() => {
          setKeyPopup(false);
          setNewPartner(null);
        }}
        closable={true}
        centered
        onCancel={() => {
          setKeyPopup(false);
        }}
      >
        <Row>
          Below are {newPartner?.partnerName}'s API Keys. Do Save these as these
          will now be available{" "}
        </Row>
        <br />
        <Row>
          <b>Primary API KEY:</b> {newPartner?.apiKey}
        </Row>{" "}
        <Row>
          <b>Secondary API KEY:</b> {newPartner?.secondaryApiKey}
        </Row>
        <br />
        <Row justify="center">
          <Button
            type="primary"
            onClick={() => {
              const data = `Primary API KEY: ${newPartner?.apiKey}\nSecondary API KEY: ${newPartner?.secondaryApiKey}`;
              const blob = new Blob([data], { type: "text/plain" });

              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "partnerKeys.txt";
              link.click();
            }}
          >
            Download Txt
          </Button>
        </Row>
      </TmsModal>
    </div>
  );
};
export default withRouter(TmsPartners);
