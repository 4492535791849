import React from "react";
import TmsReload from "../tms-reload/tms-reload";
// import CommsService from "../services/sd-comms.service";
class TmsErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <TmsReload />;
    }

    return this.props.children;
  }
}
export default TmsErrorBoundary;
