import { Popconfirm, Row, Space, Table } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import { createBankAccountStates } from "../../../../../../constants/states-data.data";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import { DeleteOutlined } from "@ant-design/icons";
import {
  FdrInstitutionSelect,
  SortTypeAndBy,
} from "../../../../../../components/tms-customs/rents-and-leases";
import useFdrStore from "../../../../../../zustand/fdr.store";

const TmsFdrInvestmentList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");

  const {
    investmentTypeData,
    selectedInstitution,
    loading,
    fetchInstitutionList,
    deleteCreatedInvestmentType,
    deleteLoading,
    clearStates,
    fetchInvestmentTypes,
  } = useFdrStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  useEffect(() => {
    return () => {
      clearStates(createBankAccountStates, ["selectedInstitution"]);
    };
  }, []);
  useEffect(() => {
    router.location.pathname === tmsRouteMap.sm_ral_fdr_investment_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchInvestmentTypes(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedInstitution,
    update,
  ]);

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <FdrInstitutionSelect span={6} />
        <SortTypeAndBy
          span={6}
          sortType={sortType}
          setSortType={setSortType}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        scroll={{
          x: "max-content",
        }}
        loading={loading}
        style={{
          textTransform: "capitalize",
        }}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 80,
            render: (text, object, index) => index + 1,
            align: "center",
          },
          {
            title: "Institution Name",
            dataIndex: "institution",
            key: "institution",
            render: (record) => record.name,
          },
          {
            title: "Investment Type",
            dataIndex: "investmentType",
            key: "investmentType",
          },

          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (record) => moment(record).format("DD-MM-YYYY hh:mm A"),
            align: "center",
          },

          {
            title: "Action",
            render: (record) => {
              return (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure delete this investment type?"
                    onConfirm={async () => {
                      deleteCreatedInvestmentType(record.id, router);
                      setUpdate(Math.random() * 100000 + 21);
                    }}
                    okText="Yes"
                    cancelText="No"
                    loading={deleteLoading}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Space>
              );
            },
            align: "center",
          },
        ]}
        dataSource={investmentTypeData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: investmentTypeData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsFdrInvestmentList);
