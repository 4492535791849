import { Col, Row, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  additionalBuildingEncroahmentStates,
  buildingEncroachmentStates,
} from "../../../../../../constants/states-data.data";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import useBuildingsStore from "../../../../../../zustand/buildings-and-shops.store";
import {
  BuildingInstitutionSelect,
  SortTypeAndBy,
} from "../../../../../../components/tms-customs/rents-and-leases";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";
const TmsSmRalEncroachmentList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");

  const {
    selectedInstitution,
    loading,
    clearStates,
    fetchInstitutionList,
    fetchEncroachments,
    createdEncroachmentsData,
    handleChangeStatus,
    excelLoading,
    handleExcelExport,
  } = useBuildingsStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  useEffect(() => {
    return () => {
      clearStates(
        buildingEncroachmentStates,
        additionalBuildingEncroahmentStates
      );
    };
  }, []);

  useEffect(() => {
    router.location.pathname ===
      tmsRouteMap.sm_ral_buildings_enchroament_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  // useEffect(() => {
  //   if (selectedInstitution && selectedInstitution !== "")
  //     fetchCreatedBuildingsList(1, 200, "ASC", "createdAt", router);
  // }, [selectedInstitution]);

  useEffect(() => {
    if (selectedInstitution && selectedInstitution !== "")
      fetchEncroachments(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedInstitution,
    update,
  ]);

  const switchTrigger = async (record, status) => {
    await handleChangeStatus(record, status, router);
    setUpdate(Math.random() * 100000 + 21);
  };
  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <BuildingInstitutionSelect span={6} />
        <SortTypeAndBy
          span={6}
          sortType={sortType}
          setSortType={setSortType}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        {selectedInstitution !== "" && selectedInstitution && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() =>
                handleExcelExport("encroachment", router)
              }
              top={false}
            />
          </Col>
        )}
      </Row>

      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          {
            title: "Building Name",
            dataIndex: "building",
            render: (record) => record?.buildingName,
          },
          {
            title: "Block Name",
            dataIndex: "blockOrFloor",
            render: (record) => record?.blockOrFloorName,
          },
          {
            title: "Shop Number",
            dataIndex: "shop",
            render: (record) => record.shopNumber,
          },
          {
            title: "Encroacher Name",
            dataIndex: "encroacherName",
            key: "encroacherName",
          },
          {
            title: "Encroacher Address",
            dataIndex: "encroacherAddress",
            key: "encroacherAddress",
          },
          {
            title: "Property Type",
            dataIndex: "propertyType",
            key: "propertyType",
          },

          {
            title: "Encroach (Months)",
            dataIndex: "encroachmentDurationInMonths",
          },
          {
            title: "Legal Action",
            render: (record) =>
              record.legalAction
                ? `YES, Case.no: ${record.caseNumber ?? ""}`
                : `NO`,
          },
          {
            title: "Status",
            key: "status",
            render: (record) => {
              return (
                <Switch
                  size={"small"}
                  checked={record.status}
                  onClick={() => {
                    switchTrigger(record?.id, !record.status);
                  }}
                />
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={createdEncroachmentsData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdEncroachmentsData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsSmRalEncroachmentList);
