import React, { memo } from "react";
import useRentsAndLandStore from "../../../zustand/rents-and-leases.store";
import TmsSelect from "../tms-select";

const SurveyBreakUpNoSelect = ({ span }) => {
  const {
    setSelectedSNBreakup,
    selectedSNBreakup,
    createdSNBreakupData,
    setExtent,
  } = useRentsAndLandStore((state) => {
    return {
      setSelectedSNBreakup: state.setSelectedSNBreakup,
      selectedSNBreakup: state.selectedSNBreakup,
      createdSNBreakupData: state.createdSNBreakupData,
      clearStates: state.clearStates,
      setExtent: state.setExtent,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select Breakup No"
      setValue={(e) => {
        const item = createdSNBreakupData?.items?.find((item) => item.id === e);
        setSelectedSNBreakup(item);
        setExtent(item.extent);
      }}
      selectClass="w-100"
      value={selectedSNBreakup?.id}
      data={createdSNBreakupData?.items?.map((item) => {
        return { name: `Breakup.no: ${item.blockNumber}`, id: item.id };
      })}
      optional={true}
    />
  );
};

export default memo(SurveyBreakUpNoSelect);
