import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import TmsTabsLayout from "../../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import TmsRalEncroachmentReports from "./tms-ral-encroachment-reports";
import TmsRalLeaseReports from "./tms-ral-lease-reports";
import TmsRalArrearsReports from "./tms-ral-arrears-reports";
import TmsRalLandReports from "./tms-ral-land-reports";

const TmsRalReportsLands = ({ router }) => {
  const [active, setActive] = useState("Lands Report");
  useEffect(() => {
    let location = router?.location?.pathname;

    if (
      location.includes(tmsRouteMap.reports_ral_lands_lands) ||
      location === "/reports/rents-and-leases/lands"
    ) {
      setActive("Lands Report");
      return;
    } else if (location.includes(tmsRouteMap.reports_ral_lands_leases)) {
      setActive("Lease Report");
      return;
    } else if (location.includes(tmsRouteMap.reports_ral_lands_encroachment)) {
      setActive("Encroachment Report");
      return;
    } else if (location.includes(tmsRouteMap.reports_ral_lands_arrears)) {
      setActive("Arrear Report");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Lands Report",
      Element: <TmsRalLandReports router={router} />,
      key: "Lands Report",
      route: tmsRouteMap.reports_ral_lands_lands,
    },
    {
      name: "Lease Report",
      Element: <TmsRalLeaseReports router={router} />,
      key: "Lease Report",
      route: tmsRouteMap.reports_ral_lands_leases,
    },
    {
      name: "Encroachment Report",
      Element: <TmsRalEncroachmentReports router={router} />,
      key: "Encroachment Report",
      route: tmsRouteMap.reports_ral_lands_encroachment,
    },
    {
      name: "Arrear Report",
      Element: <TmsRalArrearsReports router={router} />,
      key: "Arrear Report",
      route: tmsRouteMap.reports_ral_lands_arrears,
    },
  ];

  return (
    <TmsTabsLayout
      components={components}
      active={active}
      setActive={setActive}
      //
    />
  );
};
export default withRouter(TmsRalReportsLands);
