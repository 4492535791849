import { notification } from "antd";
import React, { useContext, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import InputPhoneNumber from "../tms-customs/tms-input-phone-number";
import InputPassword from "../tms-customs/tms-input-password";
import CustomButton from "../tms-customs/tms-custom-button";
import TmsAuthService from "../../services/tms-user/tms-auth.service";
import { BaseContext } from "../../contexts/BaseContext";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import tmsRouteMap from "../../constants/tms-route.map";
import TmsTempleService from "../../services/tms-temple.service";
// import ReCAPTCHA from "react-google-recaptcha";
// import { useRef } from "react";

const TmsLoginStepOne = ({
  userName,
  setUsername,
  password,
  setPassword,
  router,
}) => {
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { setUserDetails } = useContext(BaseContext);

  /*---uncomment below for recaptcha---*/
  // const [auth, setAuth] = useState("");
  // const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);
  // const SITE_KEY = "6Lf1xjcpAAAAAHYxi3wW23xkzwyRqFbOBGIyBdca";
  // const recaptchaRef = useRef(null);
  // const handleRecaptchaVerify = (value) => {
  //   // You can send the token to your server for verification.
  //   setRecaptchaVerified(value !== null && value !== "");
  //   setAuth(value);
  // };

  const loginClick = async () => {
    setLoading(true);
    if (userName === "" || userName.length !== 10 || password === "") {
      if (userName === "" || userName.length !== 10) {
        setPhoneError("Enter a valid Phone Number");
      }
      if (password === "") setPasswordError("Enter a valid Password");
      setLoading(false);
      return;
    }

    /*---uncomment below for recaptcha---*/
    // if (!isRecaptchaVerified) {
    //   setPhoneError("Invalid recaptcha");
    //   setLoading(false);
    //   return;
    // }

    try {
      const token = await TmsAuthService.login(
        userName,
        password
        /*---uncomment below for recaptcha---*/
        // auth
      );
      setLoading(false);
      let flag =
        token.userPermission.includes("SUPER_ADMIN") ||
        token.userPermission.includes("DASHBOARD_READ");
      if (!flag) {
        notification.error({
          message:
            "This login is only for Super Admins and Dashboard Read Users. To access, Login through your respective Application.",
        });
        localStorage.clear();
        return;
      }
      if (token.userPermission?.length === 0 || !token.userPermission) {
        setLoading(false);
        notification.error({
          message:
            "User Permissions are not assigned to this account. Please contact your administrator.",
        });
      } else {
        localStorage.setItem("accessToken", token.accessToken);
        localStorage.setItem("refreshToken", token.refreshToken);
        localStorage.setItem("templeId", token.user.templeId);
        let res = await TmsTempleService.getTempleDetails(token.user.templeId);
        if (res !== null && res !== undefined && Object.keys(res).length > 0) {
          if (
            res.configuration === null ||
            Object.keys(res.configuration).length === 0
          ) {
            await TmsTempleService.updateTempleSettings(false, "A4");
            let res2 = await TmsTempleService.getTempleDetails(
              token.user.templeId
            );
            localStorage.setItem("templeDetails", JSON.stringify(res2));
          } else {
            localStorage.setItem("templeDetails", JSON.stringify(res));
          }
        }

        setUserDetails({
          user: token.user,
          permissions: token.userPermission,
          // permissions: ["SUPER_ADMIN"],
        });
        {
          router.navigate(tmsRouteMap.welcome);
        }
        setLoading(false);
      }
    } catch (error) {
      /*---uncomment below for recaptcha---*/
      // setRecaptchaVerified("");
      // setAuth("");
      // recaptchaRef.current.reset();

      setLoading(false);
      if (
        error?.response?.status === 401 ||
        error.response?.data?.message ===
          "Devotee number entered is not registered"
      ) {
        notification.error({ message: "Invalid Credentials." });
        router.navigate(tmsRouteMap.login);
        return;
      } else {
        notification.error({
          message: error.response?.data?.message
            ? error.response?.data?.message
            : error?.message
            ? error?.message
            : "something is wrong",
        });
      }
    }
  };

  return (
    <div className="tms-login-step-one">
      <div className="tms-input">
        <InputPhoneNumber
          size="large"
          setState={setUsername}
          value={userName}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              loginClick();
            } else if (["e", "E", "+", "-", "."].includes(e.key)) {
              e.preventDefault();
            }
          }}
          error={phoneError}
          setError={setPhoneError}
        />
        <p className="tms-error-p">{phoneError !== "" ? phoneError : ""}</p>
      </div>
      <div className="tms-input">
        <InputPassword
          maxLength={220}
          autoComplete="off"
          size="large"
          label={"Password"}
          setState={setPassword}
          value={password}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              loginClick();
            }
          }}
          error={passwordError}
          setError={setPasswordError}
        />
        <p className="tms-error-p">
          {passwordError !== "" ? passwordError : ""}
        </p>
      </div>
      <p
        className="cursor"
        style={{ color: "#407dd0" }}
        onClick={() => {
          window.open(
            "https://develop.aped-tms-devotee-portal.pages.dev/en-in/devotee/reset",
            "_blank"
          );
        }}
      >
        Forgot Password ?
      </p>

      {/*---uncomment below for recaptcha---*/}
      {/* <ReCAPTCHA
        sitekey={SITE_KEY}
        onChange={handleRecaptchaVerify}
        ref={recaptchaRef}
      /> */}

      <CustomButton
        type={"danger"}
        size="large"
        loading={loading}
        onClickFunction={loginClick}
        icon={
          <ArrowRightOutlined
            style={{ marginRight: "-5px", fontSize: "12px" }}
          />
        }
      />
    </div>
  );
};
export default withRouter(TmsLoginStepOne);
