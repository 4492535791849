import React from "react";
import useRentsAndLandStore from "../../../zustand/rents-and-leases.store";
import TmsSelect from "../tms-select";

const LeaseSelect = ({ span }) => {
  const { createdLandLeaseData, setSelectedLandLease, selectedLandLease } =
    useRentsAndLandStore((state) => {
      return {
        createdLandLeaseData: state.createdLandLeaseData,
        setSelectedLandLease: state.setSelectedLandLease,
        selectedLandLease: state.selectedLandLease,
      };
    });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select a Lease No"
      setValue={(e) => {
        setSelectedLandLease(
          createdLandLeaseData?.items?.find((item) => item.id === e)
        );
      }}
      selectClass="w-100"
      value={selectedLandLease?.id}
      data={createdLandLeaseData?.items
        ?.filter((item) => item.paymentType !== "KIND")
        ?.map((item) => {
          return {
            name: `Lease:${item.id},${
              item.surveyNumber?.surveyNo
                ? `Breakup.no: ${item?.surveyNumberBreakup?.blockNumber}`
                : ``
            }`,
            id: item.id,
          };
        })}
      optional={true}
    />
  );
};

export default LeaseSelect;
