import { Col, Row, Input, DatePicker, TimePicker } from "antd";
import React from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import "../../../assets/css/tms-accommodation/tms-pos-accommodation.scss";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";

const TmsPosKalyanaForm = ({
  kalayanaMandapam,
  setkalayanaMandapam,
  setMuhurthamDate,
  muhurthamDate,
  muhurthamTime,
  setMuhurthamTime,
}) => {
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };

  const devoteeList = [
    "brideDetails",
    "brideFatherDetails",
    "brideMotherDetails",
    "groomDetails",
    "groomFatherDetails",
    "groomMotherDetails",
  ];

  return (
    <>
      <Row
        style={{
          fontWeight: "400",
          justifyContent: "center",
          fontSize: "20px",
        }}
      >
        Bride & Groom Details
      </Row>
      <br />
      {devoteeList?.map((item, i) => {
        return (
          <div key={i}>
            <Row
              style={{
                fontWeight: "400",
                fontSize: "20px",
                textTransform: "capitalize",
              }}
            >
              {item?.replace(/[A-Z]/g, " $&").trim()}
            </Row>
            <br />
            <Row style={{ width: "100%", justifyContent: "space-between" }}>
              <Col style={{ width: "32%" }}>
                <label style={labelStyles}>
                  Name
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />{" "}
                <Input
                  value={kalayanaMandapam[item]?.name}
                  onChange={(e) => {
                    let data = { ...kalayanaMandapam };
                    data[item].name = e.target.value;
                    setkalayanaMandapam({ ...data });
                  }}
                  type="text"
                  placeholder="Enter Name"
                  maxLength="30"
                />
              </Col>
              <Col style={{ width: "32%" }}>
                <label style={labelStyles}>
                  DOB <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <DatePicker
                  disabled={false}
                  value={kalayanaMandapam[item]?.dob}
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                  disabledDate={(d) =>
                    !d ||
                    d.isAfter(
                      TmsStringUtils.addDays(
                        new Date(),
                        item === "brideDetails" ? -6570 : -7665
                      )
                    )
                  }
                  onChange={(date) => {
                    let data = { ...kalayanaMandapam };
                    data[item].dob = date;
                    setkalayanaMandapam({ ...data });
                  }}
                  size="medium"
                />{" "}
              </Col>
              <Col style={{ width: "32%" }}>
                <label style={labelStyles}>
                  Aadhaar Number
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />{" "}
                <Input
                  size="medium"
                  type="number"
                  min="0"
                  maxLength={220}
                  placeholder="Enter ID Proof No"
                  style={{ width: "100%" }}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  value={kalayanaMandapam[item].aadharNumber}
                  onChange={(e) => {
                    if (e.target.value?.length > 12) {
                      return;
                    }
                    let data = { ...kalayanaMandapam };
                    data[
                      item
                    ].aadharNumber = `${e.target.value?.toUpperCase()}`;
                    setkalayanaMandapam({ ...data });
                  }}
                />
              </Col>
            </Row>
            <br />
          </div>
        );
      })}

      <Row
        style={{
          fontWeight: "400",
          fontSize: "20px",
        }}
      >
        Muhurtham Details
      </Row>
      <br />
      <Row style={{ width: "100%", justifyContent: "flex-start" }}>
        <Col style={{ width: "32%" }}>
          <label style={labelStyles}>
            Muhurtham Date{" "}
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />{" "}
          <DatePicker
            value={muhurthamDate}
            style={{ width: "100%" }}
            format="DD-MM-YYYY"
            disabledDate={(d) =>
              !d || d.isBefore(TmsStringUtils.addDays(new Date(), -1))
            }
            onChange={(date) => {
              setMuhurthamDate(date);
            }}
            size="medium"
          />
        </Col>
        <Col style={{ width: "32%", marginLeft: "23px" }}>
          <label style={labelStyles}>
            Muhurtham Time{" "}
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </label>
          <br />
          <TimePicker
            value={muhurthamTime}
            style={{ width: "100%" }}
            use12Hours
            format="h:mm a"
            onChange={(time, timeString) => {
              console.log(time, timeString);
              setMuhurthamTime(time);
            }}
          />
        </Col>
      </Row>
      <br />
    </>
  );
};

export default withRouter(TmsPosKalyanaForm);
