import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportSevaReport from "./tms-report-seva-report";
import TmsReportSevaSummaryReport from "./tms-report-seva-summary-report";
const TmsReportsSeva = ({ router }) => {
  const [active, setActive] = useState("Seva Report");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.reports_seva) {
      setActive("Seva Report");
      return;
    } else if (location.includes(tmsRouteMap.reports_seva_summary_report)) {
      setActive("Summary Report");
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Seva Report",
      Element: <TmsReportSevaReport />,
      key: "Seva Report",
      route: tmsRouteMap.reports_seva,
    },

    {
      name: "Summary Report",
      Element: <TmsReportSevaSummaryReport />,
      key: "Summary Report",
      route: tmsRouteMap.reports_seva_summary_report,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportsSeva);
