import { Badge, Button, Col, Row, Space, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsCounterService from "../../../../services/tms-counter.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import { BaseContext } from "../../../../contexts/BaseContext";
import TmsCMPreShiftsModal from "../../../../components/tms-service-management/tms-counter-management/tms-counter-pre-shifts-modal";
import TmsTable from "../../../../components/tms-customs/tms-table";
const TmsSmCounterManagementManageShifts = ({ router }) => {
  const [loading, setLoading] = useState();
  const [counterId, setCounterId] = useState("");
  const { userDetails } = useContext(BaseContext);
  const [list, setList] = useState([]);
  const [updateTable, setUpdateTable] = useState("none");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  const [showPreShifsModal, setShowPreShiftsModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const handleModify = async (record) => {
    setEditData(record);
    setEditFlag(true);
    setShowPreShiftsModal(true);
  };

  useEffect(() => {
    getCountersShifts();
  }, [updateTable]);

  const getCountersShifts = async () => {
    setLoading(true);
    try {
      const id = new URLSearchParams(router?.location?.search).get("counterID");
      setCounterId(id);
      if (!id || id === "") {
        router.navigate(tmsRouteMap.serviceManagement_counter_management);
        // console.log("returning");
        return;
      }
      const resp = await TmsCounterService.getCounterPreShifts(id);
      setList(resp.items);
      setTabelTotalSize(resp?.meta?.totalItems);

      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    { title: "id", dataIndex: "id", key: "id" },
    {
      title: "Assigned to User",
      dataIndex: "user",
      render: (record) => {
        return <Space size="middle">{record.displayName}</Space>;
      },
    },
    {
      title: "User phone",
      dataIndex: "user",
      render: (record) => {
        return <Space size="middle">{record.phone}</Space>;
      },
    },
    {
      title: "Shift Name",
      dataIndex: "counterShiftTiming",
      render: (record) => {
        return (
          <Tooltip
            placement="top"
            title={`${moment(record?.startTime, "HH:mm:ss Z").format(
              "hh:mm A"
            )} - ${moment(record?.endTime, "HH:mm:ss Z").format("hh:mm A")}`}
          >
            <Space size="middle">{record.name}</Space>
          </Tooltip>
        );
      },
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (record) => {
        return (
          <Space size="middle">{moment(record).format("DD-MM-YYYY")}</Space>
        );
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      render: (record) => {
        return (
          <Space size="middle">{moment(record).format("DD-MM-YYYY")}</Space>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "enabled",
      render: (record) => {
        return !record ? <Badge color="red" /> : <Badge color="green" />;
      },
    },
    {
      title: "Action",
      fixed: "right",
      responsive: ["md", "lg", "xl", "xsl"],
      render: (record) => {
        return (
          <Space size="middle">
            {/* <Popconfirm
              title="Are you sure remove this Shift?"
              onConfirm={async () => {
                setLoading(true);
                try {
                  await TmsCounterService.deleteCounterPreShiftById(record.id);
                  notification.success({
                    message: `Shift with id:${record.id} has been deleted`,
                  });
                  getCountersShifts();
                } catch (error) {
                  catchErrorFunction(
                    error,
                    "Token Expired, redirecting to login page in 5 seconds",
                    router
                  );
                }
                setLoading(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm> */}

            <a
              onClick={(e) => {
                e.preventDefault();
                handleModify(record);
              }}
            >
              Edit
            </a>
          </Space>
        );
      },
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <Row style={{ width: "100%", marginBottom: "15px" }} gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              // setUpdateTable(Math.random(100));
              setShowPreShiftsModal(true);
            }}
            disabled={
              userDetails?.permissions?.includes("SUPER_ADMIN") ||
              userDetails?.permissions?.includes("ADMIN")
                ? false
                : true
            }
          >
            Add New Pre-Shift
          </Button>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <TmsTable
          dataSource={list}
          loading={loading}
          searchable={["display_name"]}
          columns={columns}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        />
      </Row>

      <TmsCMPreShiftsModal
        counterId={counterId}
        showPreShifsModal={showPreShifsModal}
        setShowPreShiftsModal={setShowPreShiftsModal}
        setUpdateFlag={setUpdateTable}
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        editData={editData}
        setEditData={setEditData}
      />
    </div>
  );
};
export default withRouter(TmsSmCounterManagementManageShifts);
