import React, { useEffect, useState } from "react";
import TmsTable from "../../../components/tms-customs/tms-table";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsUserService from "../../../services/tms-user/tms-user.service";
import moment from "moment";

const TmsUmInnerPagesUsersAuthLogs = ({ router, phone }) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(20);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const [authLogs, setAuthLogs] = useState([]);

  const fetchActionLogs = async () => {
    setLoading(true);
    try {
      const data = await TmsUserService.userAuthLogs(
        `+91${phone}`,
        currentPage,
        pageSize
      );
      setAuthLogs(data.items);
      setTabelTotalSize(data.meta.totalItems);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchActionLogs();
  }, [currentPage, pageSize]);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "IP",
      dataIndex: "ipAddress",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Temple Id",
      dataIndex: "templeId",
      render: (record) => record ?? "-",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (record) => moment(record).format("DD-MM-YYYY HH:mm A"),
    },
    {
      title: "Outcome",
      dataIndex: "outcome",
    },
  ];

  return (
    <TmsTable
      columns={columns}
      loading={loading}
      dataSource={authLogs ?? []}
      handleTableChange={handleTableChange}
      pagination={{
        total: tableTotalSize,
        current: tableCurrentPage,
        pageSize: 20,
        pageSizeOptions: [10, 20],
      }}
    />
  );
};

export default TmsUmInnerPagesUsersAuthLogs;
