import { Button, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import { createBuildingStates } from "../../../../../constants/states-data.data";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import { BuildingInstitutionSelect } from "../../../../../components/tms-customs/rents-and-leases";
import useBuildingsStore from "../../../../../zustand/buildings-and-shops.store";

const TmsBasBuildingReports = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  // const [sortType, setSortType] = useState("ASC");
  // const [sortBy, setSortBy] = useState("createdAt");
  const {
    selectedInstitution,
    setSelectedInstitution,
    loading,
    fetchBuildingReports,
    fetchInstitutionList,
    buildingReport,
    clearStates,
    fetchTotalReports,
  } = useBuildingsStore((state) => {
    return {
      selectedInstitution: state.selectedInstitution,
      loading: state.loading,
      setSelectedInstitution: state.setSelectedInstitution,
      buildingReport: state.buildingReport,
      fetchBuildingReports: state.fetchBuildingReports,
      fetchInstitutionList: state.fetchInstitutionList,
      clearStates: state.clearStates,
      fetchTotalReports: state.fetchTotalReports,
    };
  }, shallow);

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    if (
      router.location.pathname === tmsRouteMap.reports_ral_buildings_building ||
      router.location.pathname === tmsRouteMap.reports_ral_buildings
    )
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createBuildingStates, ["landReports"]);
    };
  }, [router.location.pathname]);

  useEffect(() => {
    if (
      router.location.pathname.includes(
        tmsRouteMap.reports_ral_buildings_building
      ) ||
      router.location.pathname === "/reports/rents-and-leases/buildings"
    )
      if (selectedInstitution !== "" && selectedInstitution) {
        fetchBuildingReports(tableCurrentPage, tablePageSize, router);
      } else {
        fetchTotalReports("BUILDING", tableCurrentPage, tablePageSize, router);
      }
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedInstitution,
    router?.location?.pathname,
  ]);
  const innerBuildingColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Name of Temple",
      dataIndex: "institutionName",
      key: "institutionName",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Property type",
      dataIndex: "propertyType",
      key: "propertyType",
    },
  ];
  const totalbuildingColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Institution",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Village",
      dataIndex: "village",
      key: "village",
    },
    {
      title: "Total Buildings",
      dataIndex: "totalBuildings",
      key: "totalBuildings",
    },
    {
      title: "Total Blocks",
      dataIndex: "totalBlocks",
      key: "noOfBlocks",
    },
    {
      title: "Total shops",
      dataIndex: "totalShops",
      key: "totalShops",
    },
    {
      title: "Builtup Area(sft)",
      dataIndex: "totalBuiltAreaInSqFeet",
      key: "totalBuiltAreaInSqFeet",
    },
  ];
  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <BuildingInstitutionSelect span={6} />
        {selectedInstitution !== "" && (
          <Button
            style={{ marginTop: "27px" }}
            onClick={() => {
              setSelectedInstitution("");
            }}
          >
            Clear
          </Button>
        )}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        loading={loading}
        style={{
          width: "100%",
          textTransform: "capitalize",
        }}
        columns={
          selectedInstitution !== "" && selectedInstitution
            ? innerBuildingColumns.map((item) => {
                return { ...item, align: "center" };
              })
            : totalbuildingColumns.map((item) => {
                return { ...item, align: "center" };
              })
        }
        dataSource={buildingReport?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: buildingReport?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsBasBuildingReports);
