import React, { useEffect, useState } from "react";
import TmsTable from "../../components/tms-customs/tms-table";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import { Space, Row, Col, Button, Switch, Input, Badge, Card } from "antd";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsTempleService from "../../services/tms-temple.service";
import moment from "moment";
import TmsTMreateUserModal from "../../components/tms-temple-management/tms-tm-create-modal";
import { services } from "../../utils/tms-services/tms-services";
import tmsRouteMap from "../../constants/tms-route.map";

const TmsTempleManagement = ({ router }) => {
  const [loading, setLoading] = useState(false);
  const [templeData, setTempleData] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [showCreateTempleModal, setShowCreateTempleModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState(null);
  const [allOnlineStatus, setAllOnlineStatus] = useState([]);
  const [allCounterStatus, setAllCounterStatus] = useState([]);
  const [templeSearch, setTempleSearch] = useState("");

  useEffect(() => {
    getTemples();
  }, [updateFlag]);

  const getTemples = async () => {
    setLoading(true);
    let onlineStatus = [];
    let counterStatus = [];
    let temples = [];
    try {
      const data = await TmsTempleService.getAllTempleDetails();
      data?.map((item) => {
        if (item?.name?.toLowerCase()?.includes(templeSearch.toLowerCase())) {
          temples = [...temples, item];
        }
        setTempleData(
          temples?.map((x, i) => {
            return { ...x, key: i };
          })
        );
      });

      data?.map?.((item) => {
        onlineStatus = [...onlineStatus, item?.onlineBookingEnabled];
        counterStatus = [...counterStatus, item?.counterBookingEnabled];
      });
      setAllOnlineStatus(onlineStatus?.includes(true));
      setAllCounterStatus(counterStatus?.includes(true));
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleModify = async (record) => {
    // console.log(record);
    setEditData(record);
    setEditFlag(true);
    setShowCreateTempleModal(true);
  };

  const handleSwitch = async (type, status, templeID, flag) => {
    setLoading(true);
    try {
      if (flag === "single") {
        await TmsTempleService.bookingStatusTemple(type, status, templeID);
      } else if (flag === "all") {
        await TmsTempleService.bookingStatusAllTemple(type, status);
      }
      setUpdateFlag(Math.random() * 100 + 7);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setUpdateFlag(Math.random() * 100 + 7);
      setLoading(false);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      getTemples();
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Temple Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.setItem("templeDetails", JSON.stringify(record));
              localStorage.setItem("templeId", record.id);
              router.navigate(
                `${tmsRouteMap.temple_settings}?tab=service-settings`
              );
              window.location.reload();
            }}
          >
            {text}
          </a>
        );
      },
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
    },
    { title: "Display Name", dataIndex: "displayName", key: "displayName" },
    {
      title: "Temple Category",
      dataIndex: "templeCategory",
      key: "templeCategory",
    },
    {
      title: "Online Status",
      dataIndex: "onlineBookingEnabled",
      key: "onlineBookingEnabled",
      render: (text, record) => {
        return (
          <Switch
            size="small"
            id={record?.id}
            checked={text}
            onClick={() => handleSwitch("online", !text, record?.id, "single")}
          />
        );
      },
    },
    {
      title: "POS Status",
      dataIndex: "counterBookingEnabled",
      key: "counterBookingEnabled",
      render: (text, record) => {
        return (
          <Switch
            size="small"
            id={record?.id}
            checked={text}
            onClick={() => handleSwitch("counter", !text, record?.id, "single")}
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },

    {
      title: "Action",
      render: (record) => {
        return (
          <Space size="middle">
            {/* <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={() => {
                removeTemple(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm> */}
            <a
              onClick={() => {
                handleModify(record);
              }}
            >
              Modify
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row style={{ marginTop: "15px", justifyContent: "space-between" }}>
        <Col flex="initial">
          <Row gutter={[20, 20]} style={{ flexDirection: "row" }}>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  setShowCreateTempleModal(true);
                }}
              >
                Create Temple
              </Button>
            </Col>
            <Col>
              <Input
                maxLength={220}
                placeholder="Search Temple ..."
                value={templeSearch}
                onChange={(e) => {
                  setTempleSearch(e.target.value);
                }}
                onKeyDown={onKeyDown}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[20, 20]}>
            <Col>
              <Switch
                checked={allOnlineStatus}
                onClick={() =>
                  handleSwitch("online", !allOnlineStatus, "", "all")
                }
              />
              Online
            </Col>
            <Col>
              <Switch
                checked={allCounterStatus}
                onClick={() =>
                  handleSwitch("counter", !allCounterStatus, "", "all")
                }
              />
              Counter
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <TmsTable
        dataSource={templeData}
        loading={loading}
        searchable={["display_name"]}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <Row>
              <Card>
                <Row>
                  <Col className="mr-10">
                    <b>Created At:</b>{" "}
                    {moment(record.createdAt).format("YYYY-MM-DD")}
                  </Col>
                  <Col className="mr-10">
                    <b>Description:</b> {record.description}
                  </Col>
                  <Col className="mr-10">
                    <b>Color Code:</b> {record.colorCode}
                  </Col>
                </Row>
              </Card>
              <Card title="Service Status">
                <Row
                  gutter={[20, 20]}
                  style={{
                    background: "white",
                    width: "100%",
                  }}
                >
                  {services?.map((item, i) => {
                    return (
                      <Col style={{ width: "20%" }} key={i}>
                        <Row style={{ width: "100%" }}>
                          <Col style={{ width: "50%" }}> {item?.name}</Col>
                          <Col style={{ width: "50%" }}>
                            {record?.configuration[item?.key] ? (
                              <Badge color="green" />
                            ) : (
                              <Badge color="red" />
                            )}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </Row>
          ),
        }}
      />
      <TmsTMreateUserModal
        showCreateTempleModal={showCreateTempleModal}
        setShowCreateTempleModal={setShowCreateTempleModal}
        setUpdateFlag={setUpdateFlag}
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};
export default withRouter(TmsTempleManagement);
