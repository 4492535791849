import { Button, Col, Row } from "antd";
import React, { useEffect } from "react";
import "../../../../assets/css/tms-pos-donor/tms-pos-donor-p.scss";
import { EditOutlined } from "@ant-design/icons";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { useState } from "react";
import TmsPrivilegesService from "../../../../services/tms-privileges.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";

const TmsSmDonorPrivileges = ({ router }) => {
  const [privileges, setPrivileges] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPrivileges = async () => {
    setLoading(true);
    try {
      let res = await TmsPrivilegesService.getAllPrivileges(1, 100);
      setPrivileges(res?.items);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrivileges();
  }, []);

  return (
    <Row className="parent">
      <Row className="p-10 w-full">
        <Button
          type="primary"
          className="border-r-2 button-w"
          loading={loading}
          onClick={() => {
            router.navigate(tmsRouteMap.sm_donor_p_create);
          }}
        >
          Create
        </Button>
      </Row>
      <br />
      <Row className="w-full" gutter={[20, 20]}>
        {privileges?.map((item, i) => {
          return (
            <Col key={i} className="justify-center w-3">
              <Row className="card">
                <Row className="justify-center w-full">
                  <h2 className="text-center capitalize">
                    {item?.donationName}
                  </h2>
                </Row>
                <Row
                  className="capitalize w-full align-start text-left pb-10"
                  gutter={[10, 10]}
                >
                  <Col className="subHeadings w-30">Accomodation</Col>
                  <Col className="subHeadings w-10">:</Col>
                  <Col className="w-60">
                    {item?.accommodationPrivilege?.map((res, j) => {
                      return (
                        <span className="subData mb-10" key={j}>
                          {`${res?.accBlockCategory?.roomType}, ${
                            res?.noOfCoupons
                          } ${
                            res?.noOfCoupons >= 2 ? "Days" : "Day"
                          }, Valid till ${Math.ceil(
                            res?.tenureInMonths / 12
                          )} ${
                            Math.ceil(res?.tenureInMonths / 12) > 1
                              ? "Years"
                              : "Year"
                          }`}
                          <br />
                        </span>
                      );
                    })}
                  </Col>
                </Row>
                <Row
                  className="capitalize w-full align-start text-left pb-10"
                  gutter={[10, 10]}
                >
                  <Col className="subHeadings w-30">Darshanam</Col>
                  <Col className="subHeadings w-10">:</Col>
                  <Col className="w-60 divider">
                    {item?.darshanamPrivilege?.map((res, j) => {
                      return (
                        <span className="subData" key={j}>
                          {`${res?.darshanam?.name}, ${res?.noOfCoupons} ${
                            res?.noOfCoupons >= 2 ? "Days" : "Day"
                          }, Valid till ${Math.ceil(
                            res?.tenureInMonths / 12
                          )} ${
                            Math.ceil(res?.tenureInMonths / 12) > 1
                              ? "Years"
                              : "Year"
                          }`}
                          <br />
                        </span>
                      );
                    })}
                  </Col>
                </Row>
                <Row
                  className="capitalize w-full align-start text-left pb-10"
                  gutter={[10, 10]}
                >
                  <Col className="subHeadings w-30">Seva</Col>
                  <Col className="subHeadings w-10">:</Col>
                  <Col className="w-60 divider">
                    {item?.sevaPrivilege?.map((res, j) => {
                      return (
                        <span className="subData" key={j}>
                          {`${res?.seva?.name}, Valid till ${Math.ceil(
                            res?.tenureInMonths / 12
                          )} ${
                            Math.ceil(res?.tenureInMonths / 12) > 1
                              ? "Years"
                              : "Year"
                          }`}
                          <br />
                        </span>
                      );
                    })}
                  </Col>
                </Row>
                <Row
                  className="capitalize w-full align-start text-left pb-10"
                  gutter={[10, 10]}
                >
                  <Col className="subHeadings w-30">Products</Col>
                  <Col className="subHeadings w-10">:</Col>
                  <Col className="w-60 divider">
                    {item?.productPrivilege
                      ?.filter(
                        (record) =>
                          record?.shopProduct?.type === "GENERAL_WITH_QUOTA" ||
                          record?.shopProduct?.type === "GENERAL" ||
                          record?.shopProduct?.type === "BAHUMANAM"
                      )
                      ?.map((res, j) => {
                        return (
                          <span className="subData" key={j}>
                            {`${res?.shopProduct?.name}, Valid till ${Math.ceil(
                              res?.tenureInMonths / 12
                            )} ${
                              Math.ceil(res?.tenureInMonths / 12) > 1
                                ? "Years"
                                : "Year"
                            }`}
                            <br />
                          </span>
                        );
                      })}
                  </Col>
                </Row>
                <Row
                  className="capitalize w-full align-start text-left pb-10"
                  gutter={[10, 10]}
                >
                  <Col className="subHeadings w-30">Publication</Col>
                  <Col className="subHeadings w-10">:</Col>
                  <Col className="w-60 divider">
                    {item?.productPrivilege
                      ?.filter(
                        (record) => record?.shopProduct?.type === "PUBLICATION"
                      )
                      ?.map((res, j) => {
                        return (
                          <span className="subData" key={j}>
                            {`${res?.shopProduct?.name}, Valid till ${Math.ceil(
                              res?.tenureInMonths / 12
                            )} ${
                              Math.ceil(res?.tenureInMonths / 12) > 1
                                ? "Years"
                                : "Year"
                            }`}
                            <br />
                          </span>
                        );
                      })}
                  </Col>
                </Row>
              </Row>
              <Row gutter={[20, 20]} justify="center">
                <Col>
                  <Button
                    className="border-r-2 button-w"
                    type="primary"
                    onClick={() => {
                      router.navigate(
                        `${tmsRouteMap.sm_donor_p_create}?id=${item?.id}`
                      );
                    }}
                  >
                    Edit <EditOutlined />
                  </Button>
                </Col>
                {/* <Col>
                  <Button
                    className="border-r-2 button-w"
                    onClick={async () => {
                      await TmsPrivilegesService.deletePrivilege(item?.id);
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </Col> */}
              </Row>
            </Col>
          );
        })}
      </Row>
    </Row>
  );
};

export default withRouter(TmsSmDonorPrivileges);
