import React from "react";
import TmsPosRoutes from "../../routes/tms-pos-routes";
const TmsPos = () => {
  return (
    <div>
      <TmsPosRoutes />
    </div>
  );
};
export default TmsPos;
