import React, { useEffect, useState } from "react";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsAccInnerPagesAdd from "./tms-acc-inner-pages/tms-acc-add";
import TmsAccInnerPagesAll from "./tms-acc-inner-pages/tms-acc-all";
import TmsAccInnerPagesDisableDates from "./tms-acc-inner-pages/tms-acc-disable-dates";
import { Route, Routes } from "react-router";
import TmsSmAccManageBlocks from "./tms-acc-inner-pages/tms-manage-room-categories/tms-mrc";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsBatchSlots from "../../../../components/tms-customs/tms-batch-slots";
const TmsSmAccommodation = ({ router }) => {
  const [active, setActive] = useState("all");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_accommodation) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sm_accommodation_and)) {
      setActive("Add New Block");
    } else if (location.includes(tmsRouteMap.sm_accommodation_disable)) {
      setActive("Disable Dates");
      return;
    } else if (location.includes(tmsRouteMap.sms_acc_quota_management)) {
      setActive("Batch Quota");
      return;
    }
  }, [router?.location?.pathname]);
  const components = [
    {
      name: "All",
      Element: router?.location?.pathname?.includes(
        tmsRouteMap.sm_acc_manage_pos
      ) ? (
        <TmsCmsSlots type="manage-acc-pos" />
      ) : (
        <TmsAccInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_accommodation,
    },

    {
      name: "Add New Block",
      Element: <TmsAccInnerPagesAdd />,
      key: "Add New Block",
      route: tmsRouteMap.sm_accommodation_and,
    },
    {
      name: "Disable Dates",
      Element: <TmsAccInnerPagesDisableDates />,
      key: "Disable Dates",
      route: tmsRouteMap.sm_accommodation_disable,
    },
    {
      name: "Batch Quota",
      Element: <TmsBatchSlots type="Accommodation" />,
      key: "Batch Quota",
      route: tmsRouteMap.sms_acc_quota_management,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Routes>
        <Route
          exact
          path={
            tmsRouteMap.sm_acc_manage_room_categories?.replace(
              "/service-management/accommodation",
              ""
            ) + "/*"
          }
          element={<TmsSmAccManageBlocks />}
        />
        <Route
          path="*"
          element={
            <TmsTabsLayout
              components={components}
              active={active}
              setActive={setActive}
            />
          }
        />
      </Routes>
    </div>
  );
};
export default withRouter(TmsSmAccommodation);
