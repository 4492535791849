import React, { useRef, useContext, useState } from "react";
import { Button, Card, Col, Row } from "antd";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import { useReactToPrint } from "react-to-print";
import "../../../assets/css/tms-print-ticket/tms-print-ticket.scss";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import Barcode from "react-barcode";
import moment from "moment";
import { BaseContext } from "../../../contexts/BaseContext";

const TmsPosDonationBond = ({ data, setBondPrintTicket }) => {
  const [loading, setLoading] = useState(false);
  const { userDetails, shiftData } = useContext(BaseContext);

  let refContainer = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sd-bond-ticket-container",
    onAfterPrint: () => {
      setBondPrintTicket(false);
      window.location.reload();
    },
    pageStyle: "@page{size:landscape}",
  });

  const handleFunction = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    setTimeout(() => {
      handlePrint();
    }, 1000);
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Button
          type="primary"
          style={{ margin: "20px" }}
          onClick={() => {
            handleFunction();
          }}
        >
          Print
        </Button>
        {loading ? (
          "Loading"
        ) : (
          <>
            {" "}
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <div ref={refContainer} className="sd-bond-ticket-container">
                <Card
                  style={{
                    width: "100%",
                    // backgroundColor: "white",
                    color: "black",
                    textAlign: "left",
                  }}
                  className="sd-ticket-card"
                >
                  <Row>
                    <Col className="ticket_label">
                      <p>Donor Id :</p>
                    </Col>
                    <Col
                      className="ticket_data"
                      style={{ textAlign: "right", paddingRight: "130px" }}
                    >
                      <p>
                        {data?.counterCartItem?.categoryTransactionSequence}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <Col
                      className="ticket_data"
                      style={{
                        fontWeight: "bold",
                        width: "100% ",
                        textAlign: "center",
                      }}
                    >
                      {data?.productDetails?.productName}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        width: "65%",
                        float: "left",
                        paddingLeft: "150px",
                      }}
                    >
                      <Row>
                        <Col className="ticket_label">Sl No :</Col>
                        <Col className="ticket_data">
                          {
                            data?.counterCartItem
                              ?.dailyCategoryTransactionSequence
                          }
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="ticket_label">Devotee Name :</Col>
                        <Col className="ticket_data">
                          {data?.donationBooking?.devoteeName} |{" "}
                          {data?.donationBooking?.contact}
                        </Col>
                      </Row>
                      <Row style={{ height: "130px", overflow: "hidden" }}>
                        <Col className="ticket_label">Address :</Col>
                        <Col className="ticket_data">
                          {data?.donationBooking?.address},
                          {data?.donationBooking?.city},
                          {data?.donationBooking?.state},
                          {data?.donationBooking?.pinCode}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="ticket_label">Donation Date :</Col>
                        <Col className="ticket_data">
                          {data?.donationBooking?.calendarType === "english"
                            ? moment(data?.donationBooking?.bookingDate).format(
                                "DD-MM"
                              )
                            : `${data?.donationBooking?.masam} Masam - ${data?.donationBooking?.paksham} Paksham - ${data?.donationBooking?.tithi} Tithi`}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="ticket_label">in the Name of :</Col>
                        <Col className="ticket_data">
                          {data?.donationBooking?.inTheNameOf}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      style={{
                        width: "35%",
                        float: "right",
                        paddingTop: "50px",
                      }}
                    >
                      <Row
                        style={{ paddingTop: "20px", paddingBottom: "10px" }}
                      >
                        <Col className="ticket_label">Gothram :</Col>
                        <Col className="ticket_data">
                          {data?.donationBooking?.gothram ?? "NA"}
                        </Col>
                      </Row>
                      {/* <Row>
                      <Col className="ticket_label">Booking Date :</Col>
                      <Col className="ticket_data">{bookingDate}</Col>
                    </Row> */}
                      {data?.donationBooking?.amount && (
                        <Row style={{ paddingBottom: "15px" }}>
                          <Col className="ticket_label">
                            <p>Total Amount :</p>
                          </Col>
                          <Col className="ticket_data">
                            <p>
                              Rs. {data?.donationBooking?.amount}.00 (
                              {data?.paymentType}) <br />(
                              {numberToWords(data?.donationBooking?.amount)}{" "}
                              Rupees)
                              <br />
                            </p>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col className="ticket_label">
                          <p>Receipt No :</p>
                        </Col>
                        <Col className="ticket_data">
                          <p>
                            {data?.counterCartItem?.categoryTransactionSequence}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Barcode
                      height="25"
                      width="1"
                      fontSize="15px"
                      value={
                        data?.counterCartItem?.ticketId
                          ? data?.counterCartItem?.ticketId
                          : "transactionId=127"
                      }
                      style={{
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    />
                  </Row>
                  <Row style={{ fontSize: "12px", paddingLeft: "125px" }}>
                    <b>Terms & Conditions :</b>
                    <p style={{ margin: "0" }}>
                      Saswatha Sevas performed only 10 Years
                    </p>
                  </Row>
                  <div
                    style={{
                      display: "grid",
                      fontSize: "10px",
                      justifyContent: "start",
                      paddingLeft: "125px",
                    }}
                  >
                    <div style={{ width: "100%", display: "inline-flex" }}>
                      <p style={{ marginRight: "10px", marginBottom: "0" }}>
                        User :
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          marginBottom: "0",
                          marginRight: "10px",
                        }}
                      >
                        {userDetails?.user?.displayName ??
                          userDetails?.user?.phone}
                      </p>
                      <p style={{ marginRight: "10px", marginBottom: "0" }}>
                        Counter :
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          marginBottom: "0",
                        }}
                      >
                        {shiftData?.counter?.name}
                      </p>

                      <p
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        Time :
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        {moment()
                          .utcOffset("+05:30")
                          .format("DD-MM-YYYY hh:mm:ss A")}
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(TmsPosDonationBond);
