import { Col, Popconfirm, Row, Space, Table } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import { createLandStates } from "../../../../../../constants/states-data.data";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import { shallow } from "zustand/shallow";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { SortTypeAndBy } from "../../../../../../components/tms-customs/rents-and-leases";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";
import PrintIndividual from "../../../../../../components/tms-customs/rents-and-leases/print-individual";
const TmsCreatedLandList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const {
    selectedInstitution,
    setSelectedInstitution,
    loading,
    institutionData,
    createdLandData,
    fetchCreatedLandList,
    fetchInstitutionList,
    deleteCreatedLand,
    deleteLoading,
    clearStates,
    handleExcelExport,
    excelLoading,
  } = useRentsAndLandStore((state) => {
    return {
      selectedInstitution: state.selectedInstitution,
      loading: state.loading,
      setSelectedInstitution: state.setSelectedInstitution,
      institutionData: state.institutionData,
      createdLandData: state.createdLandData,
      fetchInstitutionList: state.fetchInstitutionList,
      fetchCreatedLandList: state.fetchCreatedLandList,
      deleteCreatedLand: state.deleteCreatedLand,
      clearStates: state.clearStates,
      handleExcelExport: state.handleExcelExport,
      excelLoading: state.excelLoading,
    };
  }, shallow);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  useEffect(() => {
    return () => {
      clearStates(createLandStates, ["selectedInstitution"]);
    };
  }, []);

  useEffect(() => {
    (router.location.pathname === tmsRouteMap.sm_ral_lands_land_creation ||
      router.location.pathname === tmsRouteMap.sm_ral_lands) &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedLandList(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
  }, [sortBy, tableCurrentPage, tablePageSize, sortType, selectedInstitution]);

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <TmsSelect
          selectClass="w-100 "
          classname=" align-center"
          labelClass="mr-10 mb-0"
          type="col"
          value={selectedInstitution}
          setValue={setSelectedInstitution}
          label="Select Institution"
          data={institutionData?.items?.map((item) => {
            return { name: item.name, id: item.id };
          })}
          span={6}
          optional
        />
        <SortTypeAndBy
          span={6}
          sortBy={sortBy}
          sortType={sortType}
          setSortBy={setSortBy}
          setSortType={setSortType}
        />
        {selectedInstitution !== "" && selectedInstitution && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() => handleExcelExport("land", router)}
              top={false}
            />
          </Col>
        )}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        scroll={{
          x: "max-content",
        }}
        loading={loading}
        style={{
          textTransform: "capitalize",
        }}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 80,
            render: (text, object, index) => index + 1,
            align: "center",
          },

          {
            title: "Agricultural",
            children: [
              {
                title: "Acres",
                dataIndex: "agricultureLandAcres",
                key: "agricultureLandAcres",
                align: "center",
              },
              {
                title: "Cents",
                dataIndex: "agricultureLandCents",
                key: "agricultureLandCents",
                align: "center",
              },
            ],
          },
          {
            title: "Non Agricultural",
            children: [
              {
                title: "Acres",
                dataIndex: "nonAgricultureLandAcres",
                key: "nonAgricultureLandAcres",
                align: "center",
              },
              {
                title: "Cents",
                dataIndex: "nonAgricultureLandCents",
                key: "nonAgricultureLandCents",
                align: "center",
              },
            ],
          },
          {
            title: "Total Land",
            children: [
              {
                title: "Acres",
                dataIndex: "totalLandAcres",
                key: "totalLandAcres",
                align: "center",
              },
              {
                title: "Cents",
                dataIndex: "totalLandCents",
                key: "totalLandCents",
                align: "center",
              },
            ],
          },
          {
            title: "Land Mark",
            dataIndex: "landmark",
            key: "landmark",
            align: "center",
          },
          {
            title: "Village",
            dataIndex: "village",
            align: "center",
          },
          {
            title: "Mandal",
            dataIndex: "mandal",
            align: "center",
          },
          {
            title: "District",
            dataIndex: "district",
            align: "center",
          },
          {
            title: "State",
            dataIndex: "state",
            align: "center",
          },
          {
            title: "Country",
            dataIndex: "country",
            align: "center",
          },
          {
            title: "Zip",
            dataIndex: "pincode",
            align: "center",
          },

          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (record) => moment(record).format("DD-MM-YYYY hh:mm A"),
            align: "center",
          },

          {
            title: "Action",
            render: (record) => {
              return (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure delete this land?"
                    onConfirm={async () => deleteCreatedLand(record.id, router)}
                    okText="Yes"
                    cancelText="No"
                    loading={deleteLoading}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                  <PrintIndividual label="Rents and Leases" data={record} />
                </Space>
              );
            },
            align: "center",
          },
        ]}
        dataSource={createdLandData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdLandData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsCreatedLandList);
