import React from "react";

import TmsServiceRoutes from "../../routes/tms-service-routes";
const TmsServiceManagement = () => {
  return (
    <div>
      <TmsServiceRoutes />
    </div>
  );
};
export default TmsServiceManagement;
