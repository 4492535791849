import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportThulabharamSummaryReport from "./tms-report-thulabharam-summary-report";
import TmsReportThulabharamReport from "./tms-report-thulabharam-report";
const TmsReportThulabharam = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.reports_thulabharam) {
      setActive("Thulabharam Report");
      return;
    } else if (
      location.includes(tmsRouteMap.reports_thulabharam_summary_report)
    ) {
      setActive("Summary Report");
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Thulabharam Report",
      Element: <TmsReportThulabharamReport />,
      key: "Thulabharam Report",
      route: tmsRouteMap.reports_thulabharam,
    },

    {
      name: "Summary Report",
      Element: <TmsReportThulabharamSummaryReport />,
      key: "Summary Report",
      route: tmsRouteMap.reports_thulabharam_summary_report,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportThulabharam);
