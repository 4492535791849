import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
//-------------------Components------------------//
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { BaseContextProvider } from "./contexts/BaseContext";
//-------------------Css-------------------------//
import "./index.css";
import "antd/dist/antd.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
// Sentry.init({
//   dsn: "https://1d4bb08ce5c64219bd812e5fa82af6da@o1216506.ingest.sentry.io/6358562",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.5,
// });

if (window.self === window.top) {
  root.render(
    <Router>
      <BaseContextProvider>
        <App />
      </BaseContextProvider>
    </Router>
  );
} else {
  <Router>
    <h1>Please close the iframe and redirect to website through URL.</h1>
  </Router>;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
