import { Input } from "antd";
import React, { memo } from "react";
const TmsPhoneNumberBasic = ({ value, setValue, placeHolder, disable }) => {
  return (
    <Input
      size="medium"
      type="number"
      min="0"
      onWheel={(event) => event.currentTarget.blur()}
      maxLength={10}
      onKeyDown={(e) =>
        ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
      }
      addonBefore={"+91"}
      placeholder={placeHolder}
      style={{ width: "100%" }}
      value={value}
      onChange={(e) => {
        // console.log(e);
        if (e.target.value.length > 10) {
          return;
        }
        setValue(`${e.target.value}`);
      }}
      disabled={disable}
    />
  );
};
export default memo(TmsPhoneNumberBasic);
