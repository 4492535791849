import {
  Button,
  DatePicker,
  Row,
  Checkbox,
  Input,
  notification,
  Col,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import TmsDarshanamService from "../../../services/tms-darshanam/tms-darshanam.service";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsTable from "../../tms-customs/tms-table";

const TmsDInnerPagesDisableDate = ({ router, darshanamData, loading }) => {
  const { TextArea } = Input;
  const [selectedDarshanams, setSelectedDarshanams] = useState([]);
  const [fromDate, setFromDate] = useState(moment().format(`YYYY-MM-DD`));
  const [toDate, setToDate] = useState(moment().format(`YYYY-MM-DD`));
  const [description, setDescription] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [onlineDisable, setOnlineDisable] = useState(true);
  const [counterDisable, setCounterDisable] = useState(true);

  const columns = [
    {
      title: "Darshanam Id",
      dataIndex: "key",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
  ];

  const toggleSelectAll = () => {
    setSelectedDarshanams((keys) =>
      keys.length === darshanamData.length
        ? []
        : darshanamData?.map((r) => r.key)
    );
  };

  const clearFields = () => {
    setDescription("");
    setSelectedDarshanams([]);
  };

  const submit = async () => {
    setSubmitLoading(true);
    try {
      await TmsDarshanamService.createDisableDates(
        selectedDarshanams,
        fromDate,
        toDate,
        description,
        onlineDisable,
        counterDisable
      );
      notification.success({
        message: "Blocked selected Darshanams on selected dates.",
      });
      clearFields();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setSubmitLoading(false);
    }
    setSubmitLoading(false);
  };

  return (
    <>
      <Row style={{ width: "100%" }}>
        <TmsTable
          rowSelection={{
            selectedRowKeys: selectedDarshanams,
            onChange: (e) => {
              setSelectedDarshanams(e);
            },
            type: "checkbox",
            fixed: true,
            onSelect: (record, selected) => {
              if (selected) {
                setSelectedDarshanams((keys) => [...keys, record.key]);
              } else {
                setSelectedDarshanams((keys) => {
                  const index = keys.indexOf(record.key);
                  return [...keys.slice(0, index), ...keys.slice(index + 1)];
                });
              }
            },
            columnTitle: (
              <Checkbox
                checked={selectedDarshanams.length}
                indeterminate={
                  selectedDarshanams.length > 0 &&
                  selectedDarshanams.length < darshanamData.length
                }
                onChange={toggleSelectAll}
              />
            ),
          }}
          loading={loading}
          columns={columns}
          dataSource={darshanamData}
          pagination={{ pageSize: 10 }}
          size="small"
        />
      </Row>

      <Row justify="center" style={{ padding: "10px", fontWeight: "bold" }}>
        <a>{selectedDarshanams?.length}</a>{" "}
        <span style={{ marginLeft: "3px" }}>Darshanams selected.</span>
      </Row>
      <Row
        style={{
          fontWeight: "bold",
          paddingBottom: "10px",
        }}
      >
        Date Period:
      </Row>
      <Row style={{ paddingBottom: "10px" }} gutter={[20, 20]}>
        <Col>
          <DatePicker.RangePicker
            onChange={(e) => {
              if (e?.[0] && e?.[1]) {
                setFromDate(moment(e[0]).format(`YYYY-MM-DD`));
                setToDate(moment(e[1]).format(`YYYY-MM-DD`));
              } else {
                setFromDate(undefined);
                setToDate(undefined);
              }
            }}
            value={[moment(fromDate), moment(toDate)]}
          />
        </Col>
        <Col>
          <label style={{ paddingRight: "10px" }}>Disable Online</label>
          <Checkbox
            style={{ paddingRight: "10px" }}
            checked={onlineDisable}
            onChange={(e) => setOnlineDisable(e.target.checked)}
          />
        </Col>
        <Col>
          <label style={{ paddingRight: "10px" }}>Disable POS</label>
          <Checkbox
            checked={counterDisable}
            onChange={(e) => setCounterDisable(e.target.checked)}
          />
        </Col>
      </Row>
      <Row style={{ paddingBottom: "10px" }}>
        <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>
          Description:{" "}
        </div>
        <TextArea
          maxLength={2000}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Row>

      <Row justify="end" style={{ marginTop: "5px" }}>
        <Button type="primary" loading={submitLoading} onClick={submit}>
          Submit
        </Button>
      </Row>
    </>
  );
};

export default withRouter(TmsDInnerPagesDisableDate);
