export const services = [
  { name: "Protocol", key: "protocolEnabled" },
  { name: "Rents & Leases", key: "rentsAndLeasesEnabled" },
  { name: "Donor Privileges", key: "donorPrivilegeEnabled" },
  { name: "Seva", key: "sevaEnabled" },
  { name: "Darshanam", key: "darshanamEnabled" },
  { name: "Accommodation", key: "accommodationEnabled" },
  { name: "Donation", key: "donationEnabled" },
  { name: "Toll Gate", key: "tollgateEnabled" },
  { name: "Product", key: "productEnabled" },
  { name: "Prasadam", key: "prasadamEnabled" },
  { name: "Thulabaram", key: "thulabaramEnabled" },
  { name: "Hundi", key: "hundiEnabled" },
  { name: "Miscellenous", key: "miscellaneousEnabled" },
  { name: "Publication", key: "publicationEnabled" },
  { name: "Bus", key: "busEnabled" },
  { name: "Add on", key: "addOnEnabled" },
  { name: "Foot Fall", key: "footfallEnabled" },
  { name: "Kalyanakatta", key: "kalyanakattaEnabled" },
  { name: "Pos Device", key: "posDevicePaymentEnabled" },
];
