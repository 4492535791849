import { Button, Row } from "antd";
import React, { memo } from "react";

const TmsCancelSubmitButtons = ({
  cancelOnlick,
  submitOnclick,
  icon,
  loading,
}) => {
  return (
    <Row style={{ margin: "20px 0px" }} justify="center">
      <Button type="ghost" onClick={cancelOnlick} loading={loading}>
        Cancel
      </Button>
      <Button
        type="danger"
        style={{ marginLeft: "20px" }}
        onClick={submitOnclick}
        loading={loading}
      >
        Submit{" "}
        <img
          src={icon}
          alt=""
          style={{
            paddingLeft: "3px",
            filter: "invert(1)",
            marginTop: "-2px",
          }}
        />
      </Button>
    </Row>
  );
};

export default memo(TmsCancelSubmitButtons);
