import React, { useContext, useState } from "react";
import { Route, Routes } from "react-router";
import tmsRouteMap from "../constants/tms-route.map";
import TmsPosAccommodation from "../views/tms-pos/tms-pos-pages/tms-pos-accommodation";
import TmsPosDarshanam from "../views/tms-pos/tms-pos-pages/tms-pos-darshanam";
import TmsPosDonation from "../views/tms-pos/tms-pos-pages/tms-pos-donation";
import TmsPosPettyDonation from "../views/tms-pos/tms-pos-pages/pos-petty-donation";

import TmsPosKalayanakatta from "../views/tms-pos/tms-pos-pages/tms-pos-kalayanakatta";
// import TmsPosParokshaSeva from "../views/tms-pos/tms-pos-pages/tms-pos-paroksha-seva";
import TmsPosPrasadam from "../views/tms-pos/tms-pos-pages/tms-pos-prasadam";
import TmsPosPratyakshaSeva from "../views/tms-pos/tms-pos-pages/tms-pos-pratyaksha-seva";
import TmsPosProducts from "../views/tms-pos/tms-pos-pages/tms-pos-products";
import TmsPosThulabharam from "../views/tms-pos/tms-pos-pages/tms-pos-thulabharam";
import TmsPosTollgate from "../views/tms-pos/tms-pos-pages/tms-pos-tollgate";
import { withRouter } from "../utils/tms-with-router/tms-with-router";
import TmsPosHome from "../views/tms-pos/tms-pos-pages/tms-pos-home";
import TmsPosPerformanceScan from "../views/tms-pos/tms-pos-pages/tms-pos-performance-scan";
import TmsPosPrasadamScan from "../views/tms-pos/tms-pos-pages/tms-pos-prasdam-scan";
import TmsPosCheckInScan from "../views/tms-pos/tms-pos-pages/tms-pos-check-in-scan";
import TmsPosTicketDetails from "../views/tms-pos/tms-pos-pages/tms-pos-ticket-details";
import TmsPosAccommodationRooms from "../views/tms-pos/tms-pos-pages/tms-pos-accommodation-rooms";
import TmsPosAccommodationScan from "../views/tms-pos/tms-pos-pages/tms-pos-accommodation-scan";
import TmsPosAccommodationDashboard from "../views/tms-pos/tms-pos-pages/tms-pos-accommodation-dashbaord";
import TmsPosManualEntry from "../views/tms-pos/tms-pos-pages/tms-pos-manual-entry";
import TmsPosPreassignedCheckin from "../views/tms-pos/tms-pos-pages/tms-pos-preassigned-checkin";
import TmsPosMisc from "../views/tms-pos/tms-pos-pages/tms-pos-misc";
import TmsPosEntryScan from "../views/tms-pos/tms-pos-pages/tms-pos-entry-scan";
import TmsPosRentsAndLeases from "../views/tms-pos/tms-pos-pages/tms-pos-rents-and-lease";
import TmsPosProtocol from "../views/tms-pos/tms-pos-pages/tms-pos-protocol/tms-pos-protocol";
import TmsPosProtocolDashboard from "../views/tms-pos/tms-pos-pages/tms-pos-protocol/tms-pos-protocol-dashboard";
import TmsPosProtocolPrint from "../views/tms-pos/tms-pos-pages/tms-pos-protocol/tms-pos-protocol-print";
import TmsPosBus from "../views/tms-pos/tms-pos-pages/tms-pos-bus";
import TmsPosPublication from "../views/tms-pos/tms-pos-pages/tms-pos-publication";
import checkPermissions from "../utils/tms-permissions/tms-permissions";
import TmsPosMap from "../constants/tms-pos.map";
import { BaseContext } from "../contexts/BaseContext";
const TmsPosRoutes = ({ router }) => {
  const { userDetails } = useContext(BaseContext);
  const [widthFlag, setWidthFlag] = useState(false);

  const templeDetails = JSON.parse(localStorage.getItem("templeDetails"));
  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      {router.location.pathname !== "/pos" && (
        <div
          style={{
            width: "17%",
            minWidth: "158px",
            maxWidth: "230px",
            padding: "0px 20px 10px 0px",
          }}
        >
          <ul style={{ listStyle: "none", paddingLeft: "0" }}>
            {" "}
            {TmsPosMap.filter((item) => {
              if (item.hidden !== "") {
                return templeDetails.configuration[item.hidden];
              } else {
                return true;
              }
            }).map((card, i) => {
              return checkPermissions(userDetails, card) ? (
                <li
                  key={"key" + i}
                  style={{
                    backgroundColor:
                      router.location.pathname === card.route
                        ? "#1890ff"
                        : "white",
                    color:
                      router.location.pathname === card.route
                        ? "white"
                        : "black",
                    // border: `1px solid ${card.textColor}`,
                    cursor: "pointer",
                    padding: "10px",
                    textAlign: "left",
                  }}
                  onClick={() => router.navigate(card.route)}
                >
                  {" "}
                  {card.title}
                </li>
              ) : (
                ""
              );
            })}
          </ul>
        </div>
      )}
      <div style={{ width: widthFlag ? "100%" : "83%" }}>
        <Routes>
          <Route
            exact
            path={tmsRouteMap.pos_accommodation.replace("/pos", "")}
            element={<TmsPosAccommodation />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_accommodation_dashboard.replace("/pos", "")}
            element={<TmsPosAccommodationDashboard />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_accommodation_rooms.replace("/pos", "")}
            element={<TmsPosAccommodationRooms />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_acc_scan.replace("/pos", "")}
            element={<TmsPosAccommodationScan />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_darshanam.replace("/pos", "")}
            element={<TmsPosDarshanam />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_donation.replace("/pos", "")}
            element={<TmsPosDonation />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_petty_donation.replace("/pos", "")}
            element={<TmsPosPettyDonation />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_kalayanakatta.replace("/pos", "")}
            element={<TmsPosKalayanakatta />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_prasadam.replace("/pos", "")}
            element={<TmsPosPrasadam />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_sevas.replace("/pos", "")}
            element={<TmsPosPratyakshaSeva />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_products.replace("/pos", "")}
            element={<TmsPosProducts />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_thulabharam.replace("/pos", "")}
            element={<TmsPosThulabharam />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_tollgate.replace("/pos", "")}
            element={<TmsPosTollgate />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_performance_sccanner.replace("/pos", "")}
            element={<TmsPosPerformanceScan />}
          />{" "}
          <Route
            exact
            path={tmsRouteMap.pos_prasadm_scanner.replace("/pos", "")}
            element={<TmsPosPrasadamScan />}
          />{" "}
          <Route
            exact
            path={tmsRouteMap.pos_check_in_scan.replace("/pos", "")}
            element={<TmsPosCheckInScan />}
          />{" "}
          <Route
            exact
            path={tmsRouteMap.pos_pre_assigned_check_in_scan.replace(
              "/pos",
              ""
            )}
            element={<TmsPosPreassignedCheckin />}
          />{" "}
          <Route
            exact
            path={tmsRouteMap.pos_ticket_details.replace("/pos", "")}
            element={<TmsPosTicketDetails />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_manual_entry.replace("/pos", "")}
            element={<TmsPosManualEntry />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_misc.replace("/pos", "")}
            element={<TmsPosMisc />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_entryScan.replace("/pos", "")}
            element={<TmsPosEntryScan />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_protocol.replace("/pos", "")}
            element={<TmsPosProtocol />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_protocol_dashboard.replace("/pos", "")}
            element={<TmsPosProtocolDashboard />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_protocol_print.replace("/pos", "")}
            element={<TmsPosProtocolPrint />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_rents_and_leases.replace("/pos", "")}
            element={<TmsPosRentsAndLeases />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_bus.replace("/pos", "")}
            element={<TmsPosBus />}
          />
          <Route
            exact
            path={tmsRouteMap.pos_publication.replace("/pos", "")}
            element={<TmsPosPublication />}
          />
          <Route
            path={"/"}
            element={<TmsPosHome setWidthFlag={setWidthFlag} />}
          />
        </Routes>
      </div>
    </div>
  );
};
export default withRouter(TmsPosRoutes);
