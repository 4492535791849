import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import TmsTabsLayout from "../../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import TmsLicenseLeaseReports from "./license-lease-reports";
import TmsLicenseArrearsReports from "./arrears-reports";
// import TmsLicenseReports from "./license-reports";

const TmsRalReportsLicenses = ({ router }) => {
  const [active, setActive] = useState("Lands Report");
  useEffect(() => {
    let location = router?.location?.pathname;

    if (
      location === tmsRouteMap.reports_ral_licenses_leases ||
      location === "/reports/rents-and-leases/licenses"
    ) {
      setActive("License Lease Report");
      return;
    } else if (location.includes(tmsRouteMap.reports_ral_licenses_arrears)) {
      setActive("Arrear Report");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    // {
    //   name: "License Report",
    //   Element: <TmsLicenseReports router={router} />,
    //   key: "License Report",
    //   route: tmsRouteMap.reports_ral_lands_lands,
    // },
    {
      name: "License Lease Report",
      Element: <TmsLicenseLeaseReports router={router} />,
      key: "License Lease Report",
      route: tmsRouteMap.reports_ral_licenses_leases,
    },

    {
      name: "Arrear Report",
      Element: <TmsLicenseArrearsReports router={router} />,
      key: "Arrear Report",
      route: tmsRouteMap.reports_ral_licenses_arrears,
    },
  ];

  return (
    <TmsTabsLayout
      components={components}
      active={active}
      setActive={setActive}
      //
    />
  );
};
export default withRouter(TmsRalReportsLicenses);
