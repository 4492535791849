/* eslint-disable no-unused-vars */
import { Button, Col, Input, InputNumber, notification, Row } from "antd";
import React, { useState, useEffect } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import TmsDonationService from "../../../../../services/tms-donation.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { FormatNumberINRCurrency } from "../../../../../utils/tms-currency-formatter/tms-curreny-formatter";
import TmsAccommodationService from "../../../../../services/tms-accommodation.service";
import TmsModal from "../../../../../components/tms-customs/tms-modal";
import TmsSelect from "../../../../../components/tms-customs/tms-select";

const TmsDonationInnerPagesDonorRegistartion = ({ router }) => {
  const [loading, setLoading] = useState();
  const [visible, setVisible] = useState(false);
  const [blockData, setBlockData] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [RoomData, setRoomData] = useState([]);

  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [donorName, setDonorName] = useState("");
  const [phone, setPhone] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [noOfCoupons, setNoOfCoupons] = useState(1);

  const [donorData, setDonorData] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [sortType, setSortType] = useState("DESC");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [registeredId, setRegisteredId] = useState("");

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    getregisteredDonors();
  }, [tableCurrentPage, tablePageSize]);
  useEffect(() => {
    visible && getBlocks();
  }, [visible]);

  const getregisteredDonors = async () => {
    setLoading(true);
    try {
      const data = await TmsDonationService.getDonationDonors(
        tableCurrentPage,
        tablePageSize
      );
      setDonorData(data.items);
      setTabelTotalSize(data?.meta?.totalItems);
    } catch (error) {
      catchErrorFunction(error, "Token expired! Please login again.", router);
    }
    setLoading(false);
  };

  const getBlocks = async () => {
    try {
      let res = await TmsAccommodationService.getAllBlocks(1, 500);
      setBlockData(res?.items);
    } catch (error) {
      catchErrorFunction(error, "Token expired!", router);
    }
  };

  const getCategories = async (item) => {
    try {
      let res = await TmsAccommodationService.getAllBlockCategorys(
        item ?? selectedBlock,
        1,
        500
      );
      setcategoryData(
        res?.items.map((item) => {
          return { id: item.id, name: item.roomType };
        })
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!", router);
    }
  };

  const getCategoryRooms = async (item) => {
    try {
      let res = await TmsAccommodationService.getAllBlockCategoryRoom(
        item ?? selectedCategory,
        1,
        500
      );
      setRoomData(
        res?.items.map((item) => {
          return {
            id: item.id,
            name: item.roomNumber,
          };
        })
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!", router);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          maxLength={220}
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination);
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  const columns = [
    { title: "id", dataIndex: "id", key: "id" },
    {
      title: "Donor Name",
      dataIndex: "donorName",
      key: "donorName",
    },
    {
      title: "Phone",
      dataIndex: "user",
      key: "user",
      render: (record) => record.phone,
    },
    {
      title: "Block",
      dataIndex: "accBlock",
      key: "accBlock",
      render: (record) => record.name,
    },
    {
      title: "Room Number",
      dataIndex: "accRoom",
      key: "accRoom",
      render: (record) => (record?.roomNumber ? record?.roomNumber : "-"),
    },
    {
      title: "Coupon Value",
      dataIndex: "couponValue",
      key: "couponValue",
      render: FormatNumberINRCurrency,
    },
    {
      title: "No of coupons",
      dataIndex: "noOfCoupons",
      key: "noOfCoupons",
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              clearState();
              setVisible(true);
              setRegisteredId(record?.id);
              setDonorName(record?.donorName);
              setPhone(record?.user?.phone?.slice(3));
              setSelectedBlock(record?.accBlockId);
              setSelectedRoom(record?.accRoomId);
              setCouponValue(record?.couponValue);
              setNoOfCoupons(record?.noOfCoupons);
              setEdit(true);
            }}
          >
            <EditOutlined />
          </a>
        );
      },
    },
  ];

  const clearState = () => {
    setSelectedBlock(undefined);
    setSelectedCategory(undefined);
    setSelectedRoom(undefined);
    setDonorName(undefined);
    setPhone(undefined);
    setCouponValue(undefined);
    setNoOfCoupons(undefined);
    setRegisteredId(undefined);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (phone.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      setLoading(false);
      return;
    }
    let body = {
      donorName: donorName,
      phone: `+91${phone}`,
      blockId: Number(selectedBlock),
      roomId: Number(selectedRoom),
      couponValue: couponValue,
      noOfCoupons: noOfCoupons,
    };
    try {
      edit
        ? await TmsDonationService.editDonor(body, registeredId)
        : await TmsDonationService.createDonor(body);
      notification.success({ message: "Donor successfully registered" });
      setVisible(false);
      clearState();
      setLoading(false);
      getregisteredDonors();
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Login again", router);
      setLoading(false);
    }
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col>
          <br />
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              clearState();
              setVisible(true);
              setEdit(false);
            }}
          >
            Donor Registration
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={columns}
          dataSource={donorData}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
      <TmsModal
        centered={true}
        width={"700px"}
        closable={true}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        title={"Donor Registartion"}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
              setSelectedBlock("");
              setSelectedCategory("");
              setSelectedRoom("");
              getregisteredDonors();
            }}
          >
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Row className="formRow">
          <label className="formLabel">Donor Name</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={donorName}
            onChange={(e) => setDonorName(e.target.value)}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Phone Number</label>
          <Input
            className="formInput"
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              setPhone(e.target.value);
            }}
            type="number"
            min="0"
            prefix="+91"
            onWheel={(event) => event.currentTarget.blur()}
            maxLength={10}
            value={phone}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            disabled={edit}
          />
        </Row>
        <TmsSelect
          type="row"
          span={24}
          label="Block Name"
          classname="formRow"
          labelClass={"formLabel"}
          setValue={(e) => {
            setSelectedBlock(e);
            getCategories(e);
          }}
          value={selectedBlock}
          data={blockData}
          optional={true}
          disabled={edit}
        />
        <TmsSelect
          type="row"
          labelClass={"formLabel"}
          span={24}
          classname="formRow"
          label="Room Category"
          setValue={(e) => {
            setSelectedCategory(e);
            getCategoryRooms(e);
          }}
          value={selectedCategory}
          data={categoryData}
          optional={true}
          disabled={edit}
        />
        <TmsSelect
          type="row"
          span={24}
          classname="formRow"
          label="Room Number"
          labelClass={"formLabel"}
          setValue={setSelectedRoom}
          value={selectedRoom}
          data={RoomData}
          optional={true}
          disabled={edit}
        />
        <Row className="formRow">
          <label className="formLabel">Coupon value</label>
          <InputNumber
            maxLength={220}
            defaultValue={couponValue}
            value={couponValue}
            min={0}
            style={{ width: "100%" }}
            placeholder="Enter Coupon value"
            onChange={(e) => {
              setCouponValue(e);
            }}
            className="formInput"
            disabled={edit}
          ></InputNumber>
        </Row>
        <Row className="formRow">
          <label className="formLabel">No of Coupons</label>
          <InputNumber
            maxLength={220}
            defaultValue={noOfCoupons}
            value={noOfCoupons}
            min={0}
            style={{ width: "100%" }}
            placeholder="Enter No of Coupons"
            onChange={(e) => {
              setNoOfCoupons(e);
            }}
            className="formInput"
            disabled={edit}
          ></InputNumber>
        </Row>
      </TmsModal>
    </>
  );
};

export default withRouter(TmsDonationInnerPagesDonorRegistartion);
