import { Col, notification, Row } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";

import TmsEntryScanService from "../../../../services/tms-entry-scan.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";

const TmsReportEntryScanReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [excelLoading, setExcelLoading] = useState(false);

  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [bookingType, setBookingType] = useState("POS");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [entryScanType, setentryScanType] = useState("");
  const [entryScanList, setEntryScanList] = useState([]);
  const [dateType, setDateType] = useState("payment");
  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);
  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);

  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await TmsEntryScanService.entryScanReport({
        tableCurrentPage: Number(tableCurrentPage),
        tablePageSize: Number(tablePageSize),
        fromDate: bookingFromDate,
        toDate: bookingToDate,
        counter,
        shift,
        entryScanType,
        dateType,
      });
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    // if (sorter.field === "createdDate") {
    //   if (sorter.order === "ascend") {
    //     setSortType("ASC");
    //   } else if (sorter.order === "descend") {
    //     setSortType("DESC");
    //   }
    // }
  };
  const fetchEntryScanList = async () => {
    try {
      let res = await TmsEntryScanService.getEntryScan(1, 200);
      setEntryScanList(res.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const data = await TmsEntryScanService.entryScanReport(
        Number(tableCurrentPage),
        Number(50000),
        bookingFromDate,
        bookingToDate,
        counter,
        shift,
        entryScanType,
        dateType
      );

      const compiledData = data.items.map((item, i) => {
        return {
          "S.no": i + 1,
          "POS Name": item.counterName,
          "User Name": item.counterShiftLoginLog.user.displayName,
          "Shift ID": item.counterShiftTimingId,
          "Entry Scan Name  ": item.entryScanName,
          "Created Date": item.createdAt
            ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A")
            : "-",
          "Last Updated": item.updatedAt
            ? moment(item.updatedAt).format("DD-MM-YYYY hh:mm A")
            : "-",
        };
      });
      exportExcel(
        compiledData,
        `entryscan-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };
  useEffect(() => {
    fetchEntryScanList();
    fetchCounterList();
    fetchShiftList();
  }, []);
  useEffect(() => {
    fetchBookingDetails();
  }, [
    bookingFromDate,
    bookingToDate,
    tableCurrentPage,
    tablePageSize,
    counter,
    shift,
    entryScanType,
  ]);

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "POS Name",
      dataIndex: "counter",
      render: (record) => record.name,
    },
    {
      title: "User Name",
      dataIndex: "counterShiftLoginLog",
      key: "counterShiftLoginLog",
      render: (record) => record.user.displayName,
    },

    {
      title: "Entry Scan Name",
      dataIndex: "product",
      key: "product",
      render: (record) => record.name,
    },

    {
      title: "Created Date",
      dataIndex: "createdAt",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY hh:mm A");
      },
      sorter: (a, b) => a.createdDate > b.createdDate,
    },

    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY hh:mm A");
      },
    },
  ];

  return (
    <>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
        />
        <Col span={3} style={{ padding: "0" }}>
          <TmsSelect
            type="col"
            label="Date Type"
            setValue={setDateType}
            value={dateType}
            data={[
              { id: "booking", name: "Performance" },
              { id: "payment", name: "Booking" },
            ]}
            optional={true}
          />
        </Col>
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Entry Scan Type"
          setValue={(e) => {
            setentryScanType(e);
          }}
          value={entryScanType}
          data={entryScanList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="POS"
          setValue={setCounter}
          value={counter}
          data={counterList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shift"
          setValue={setShift}
          value={shift}
          data={shiftList}
        />

        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <TmsExcelButton
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={true}
        />
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </>
  );
};

export default withRouter(TmsReportEntryScanReport);
