import React, { memo } from "react";
import useLicensesStore from "../../../../zustand/licenses.store";
import TmsSelect from "../../tms-select";

const LicenseSelect = ({ span }) => {
  const {
    createdLicenseData,
    selectedLicense,
    clearStates,
    setSelectedLicense,
  } = useLicensesStore((state) => {
    return {
      createdLicenseData: state.createdLicenseData,
      clearStates: state.clearStates,
      setSelectedLicense: state.setSelectedLicense,
      selectedLicense: state.selectedLicense,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      setValue={(e) => {
        setSelectedLicense(
          createdLicenseData?.items?.find((item) => item.id === e)
        );
        clearStates(["selectedLicenseLease"], []);
      }}
      label="Select License Category"
      data={
        createdLicenseData?.items?.map((item) => {
          return {
            name: `ID: ${item?.licenseName}  `,
            id: item?.id,
          };
        }) ?? []
      }
      value={selectedLicense?.id}
      optional={true}
    />
  );
};

export default memo(LicenseSelect);
