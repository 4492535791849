import React, { useEffect, useState } from "react";
import "../../../../assets/css/tms-common/tms-common.scss";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsSmLandsandLeases from "./tms-ral-lands";
import TmsSmRalFdr from "./tms-ral-fdr";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsRalLicenses from "./tms-ral-licenses";
import {
  leaseDarkBlue,
  leaseYellow,
  leasePink,
  leaseGreen,
} from "../../../../assets/images/tms-image-list";
import TmsRalBuildings from "./tms-ral-buildings";
import { Divider } from "antd";

const TmsSmRentsAndLeases = ({ router }) => {
  const [currentActive, setCurrentActive] = useState("");
  const routes = [
    {
      name: "Lands and Lease",
      logo: leaseYellow,
      color: "#FCCA24",
      path: "lands",
      black: true,
      element: <TmsSmLandsandLeases />,
    },
    {
      name: "Buildings and Shops",
      logo: leaseDarkBlue,
      color: "#0F4C81",
      path: "buildings",
      black: false,
      element: <TmsRalBuildings />,
    },
    {
      name: "Licenses",
      logo: leasePink,
      color: "#E8A798",
      path: "license",
      black: false,
      element: <TmsRalLicenses />,
    },
    {
      name: "FDR",
      logo: leaseGreen,
      element: <TmsSmRalFdr />,
      color: "#55C6A9",
      path: "fdr",
      black: false,
    },
  ];
  useEffect(() => {
    if (router?.location?.pathname) {
      let split = router?.location?.pathname
        .split("/rents-and-leases")[1]
        .split("/");
      if (split?.[1]) {
        setCurrentActive(split?.[1].replace("/", ""));
      } else {
        setCurrentActive("");
      }
    }
  }, [router?.location?.pathname]);

  return (
    <div>
      {currentActive === "" ? (
        <div className="lease-parent" key="lease-parent">
          {routes.map((item, i) => {
            return (
              <div
                key={item.path + i}
                tabIndex={item.path}
                className="lease-div"
                style={{
                  backgroundColor: item.color,
                }}
                onClick={() => {
                  router.navigate(
                    tmsRouteMap.serviceManagement_rents_and_leases +
                      "/" +
                      item.path
                  );
                }}
              >
                <img src={item.logo} alt="" />
                <h2 className={item.black ? "c-black" : "c-white"}>
                  {item.name}
                </h2>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <div className="creation-main">
            {currentActive !== "" && (
              <button
                onClick={() => {
                  router.navigate(
                    tmsRouteMap.serviceManagement_rents_and_leases
                  );
                }}
              >
                Menu
              </button>
            )}
            <h2
              style={{ cursor: "pointer", textAlign: "center", width: "100%" }}
              onClick={() => {
                setCurrentActive("");
                router.navigate(tmsRouteMap.sm_ral_lands_created_List);
              }}
            >
              {routes.find((item) => item.path === currentActive)?.name}
            </h2>{" "}
          </div>
          <Divider />
          {routes.find((item) => item.path === currentActive)?.element}
        </div>
      )}
    </div>
  );
};

export default withRouter(TmsSmRentsAndLeases);
