import Axios from "../utils/tms-axios/tms-axios";
// import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsBuildingsService = {
  getCreatedInstitutions: async (page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/institution/get-all?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createInstitution: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/institution/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateInstitution: async (body, institutionID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/institution/update/${institutionID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteInstitution: async (institutionID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/institution/delete/${institutionID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  //Created -buildings
  buildingChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/building/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getCreatedBuildings: async (institution, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/building/get-by-institution/${institution}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createBuildings: async (body) => {
    const resp = await Axios.post(`v1/rent-and-lease/building/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateCreatedBuildings: async (body, landID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/building/update/${landID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedBuildings: async (landID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/building/delete/${landID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getCreatedBuildingReports: async (
    institution,
    page,
    limit
    // sortOrder,
    // sortBy
  ) => {
    let url = `v1/rent-and-lease/building/reports/${institution}&page=${page}&limit=${limit}`;

    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  //Survey Number -Blocks
  getCreatedBlocks: async (buildingId, page, limit, sortOrder, sortBy) => {
    console.log({ path: buildingId });
    const resp = await Axios.get(
      `v1/rent-and-lease/building/block-or-floor/get-by-building/${buildingId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  blockChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/building/block-or-floor/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  // getSurveyNosByIstitution: async (
  //   institutionID,
  //   page,
  //   limit,
  //   sortOrder,
  //   sortBy
  // ) => {
  //   const resp = await Axios.get(
  //     `v1/rent-and-lease/survey-number/get-by-institution/${institutionID}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
  //     {
  //       headers: {
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //       },
  //     }
  //   );
  //   return resp.data;
  // },
  createBlock: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/building/block-or-floor/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateBlock: async (body, blockId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/building/block-or-floor/update/${blockId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteBlock: async (blockId) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/building/block-or-floor/delete/${blockId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //Shops Number BreakUps -shops
  getCreatedShopsByBlock: async (blockID, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/building/shop/get-by-block/${blockID}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  shopChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/building/shop/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getCreatedShopsByBuilding: async (
    building,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/building/shop/get-by-building/${building}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createShops: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/building/shop/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateShops: async (body, shopId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/building/shop/update/${shopId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteShops: async (shopId) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/building/shop/delete/${shopId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //Encroachment
  enchrochmentChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/building/encroachment/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getCreatedEncroachment: async (
    institutionID,
    buildingID,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    let url = `v1/rent-and-lease/building/encroachment/get-by-institution/${institutionID}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`;
    if (buildingID && buildingID !== "") {
      url += `&buildingID=${buildingID}`;
    }
    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  createEncroachment: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/building/encroachment/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateEncroachment: async (body, EncroachmentID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/building/encroachment/update/${EncroachmentID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteEncroachment: async (EncroachmentID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/encroachment/delete/${EncroachmentID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getEncroahmentReports: async (institution, page, limit) => {
    let url = `v1/rent-and-lease/building/reports/${institution}/encroachment?page=${page}&limit=${limit}`;

    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  //Land Lease
  shopLeaseChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/shop-lease/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getCreatedLandLeases: async (
    institutionId,
    buildingId,
    blockId,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    let url = `v1/rent-and-lease/shop-lease/get-by-institution/${institutionId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`;
    if (blockId && blockId !== "") {
      url += `&blockId=${blockId}`;
    }
    if (buildingId && buildingId !== "") {
      url += `&buildingId=${buildingId}`;
    }
    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  createLandLeases: async (body) => {
    const resp = await Axios.post(`v1/rent-and-lease/shop-lease/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateCreatedLandLease: async (body, shopLeaseID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/shop-lease/update/${shopLeaseID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedShopLease: async (shopLeaseID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/shop-lease/delete/${shopLeaseID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getShopLeaseInvoices: async (
    shopLeaseId,
    latest,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/shop-lease/invoice/${shopLeaseId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}&latestRecord=${latest}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getInvoiceByInvoiceID: async (invoiceID) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/shop-lease/get-by-invoice/${invoiceID}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getInvoiceAcknowledgement: async (ackID) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/shop-lease-transaction/get-by-acknowledgement/${ackID}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  handleInvoicePayment: async (invoiceID, body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/shop-lease-transaction/${invoiceID}/payment`,
      body,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  handleInvoiceAcknowledgement: async (ackID, body) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/shop-lease-transaction/${ackID}/updatePayment`,
      body,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getLeaseReports: async (
    institution,
    page,
    limit,
    leaseStartDate,
    leaseEndDate
  ) => {
    let url = `v1/rent-and-lease/building/reports/${institution}/leaseReport?page=${page}&limit=${limit}&leaseStartDate=${leaseStartDate}&leaseEndDate=${leaseEndDate}`;

    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  //Arrears

  getCreatedArrears: async (
    institutionId,
    buildingId,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    let url = `v1/rent-and-lease/building/arrear/get-by-institution/${institutionId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`;
    if (buildingId !== "" && !buildingId) {
      url += `&buildingId=${buildingId}`;
    }
    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
  createArrear: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/building/arrear/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateCreatedArrears: async (body, arreaId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/building/arrear/update/${arreaId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateArrearBill: async (body, arreaId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/building/arrear/billAdjustment/${arreaId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedArrear: async (arrearID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/building/arrear/delete/${arrearID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getArrearsReports: async (
    institution,
    page,
    limit
    // leaseStartDate,
    // leaseEndDate
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/building/reports/${institution}/arrear?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getCreatedReportsByType: async (type, page, limit) => {
    let url = `v1/rent-and-lease/building/reports/totalReports?reportType=${type}&page=${page}&limit=${limit}`;
    type;
    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },
};
export default TmsBuildingsService;
