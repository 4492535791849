import React, { useState } from "react";
import TmsCartService from "../../../services/tms-cart.service";
import {
  Alert,
  Button,
  Col,
  Input,
  notification,
  Row,
  // Space,
  Typography,
} from "antd";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsRenderDetailsRenderer from "../../../components/tms-ticket-details-renderer/tms-ticket-details-renderer";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
// import "../TicketScan.scss";
const TmsPosPerformanceScan = ({ router }) => {
  const [loading, setLoading] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [data, setData] = useState();
  const [valid, setValid] = useState(false);
  const [errMessage, setErrMessage] = useState();

  const ticketScan = async () => {
    setValid(false);
    setErrMessage(undefined);
    setLoading(true);
    if (!ticketId) {
      notification.error({ message: "Enter Ticket Number!" });
    }

    try {
      const data = await TmsCartService.performanceScan(ticketId);
      if (data) {
        setData(data);
      }
      setTicketId("");
      setValid(true);
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        setValid(false);
        setErrMessage(error?.response?.data?.message);
      }
      setTicketId("");
      catchErrorFunction(error, "Token expired!! Login again.", router);
      setLoading(false);
    }
  };

  const onChangeTicketId = (e) => {
    setTicketId(e.target.value);
    setValid(false);
    setErrMessage(undefined);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      ticketScan();
    }
  };

  return (
    <>
      <Row justify="center">
        <Typography.Title> Performance Scan </Typography.Title>
      </Row>
      <Row justify="center">
        <Col
          className="mobile-responsive-width"
          style={{ margin: "8px", textAlign: "left" }}
        >
          {" "}
          Ticket No:{" "}
        </Col>
        <Col className="mobile-responsive-width" style={{ margin: "8px" }}>
          <Input
            maxLength={220}
            value={ticketId}
            onChange={onChangeTicketId}
            onKeyDown={onKeyDown}
            className="mobile-responsive-width"
          />
        </Col>
        <Col className="mobile-responsive-width" style={{ margin: "8px" }}>
          <Button
            className="mobile-responsive-width"
            type="primary"
            onClick={ticketScan}
            loading={loading}
          >
            GO!
          </Button>
        </Col>
        <Col className="mobile-responsive-width">
          {/* <Button
            className="mobile-button-width"
            type="primary"
            onClick={() => {
              Scan();
            }}
            style={{ margin: "8px" }}
          >
            {scanning ? "Close Scan" : " Barcode Scan"}
          </Button>
          {scanning ? (
            <TmsQuaggaClass
              onDetected={OnDetected}
              closeFlag={closeFlag}
              setCloseFlag={setCloseFlag}
            />
          ) : null} */}
          {/* <br />
              <ul className="results">
                <p>{results?.codeResult?.code}</p>
              </ul> */}
        </Col>
      </Row>
      <Row justify="center" style={{ margin: "20px" }}>
        {valid && data && (
          <Alert
            message={`Ticket Scanned ${data?.[0]?.ticketId ?? ""}`}
            description={`Ticket has been scanned successfully.`}
            type="success"
            showIcon
          />
        )}
        {!valid && errMessage && (
          <Alert
            style={{
              paddingRight: "30px",
              backgroundColor: "red",
              color: "white",
              height: "150px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              padding: "25px",
            }}
            message={
              <span
                style={{
                  color: "white",
                  fontSize: "30px",
                }}
              >
                Error Scanning Ticket {data?.[0]?.ticketId ?? ""}
              </span>
            }
            description={errMessage}
            type="error"
          />
        )}
      </Row>
      {valid && data && (
        <Row
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xsl={12}
          style={{
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <TmsRenderDetailsRenderer data={data} />
        </Row>
      )}
    </>
  );
};

export default withRouter(TmsPosPerformanceScan);
