import { Button, Col, Drawer, Input, notification, Row } from "antd";
import React from "react";
import { FormatNumberINRCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
const BillAdjustment = ({
  type,
  editFlag,
  setEditFlag,
  loading,
  editData,
  setEditData,
  adjustedAmount,
  setAdjustedAmount,
  adjustedAmountRemarks,
  setAdjustedAmountRemarks,
  handleArrearBillUpdate,
  setUpdate,
  router,
}) => {
  return (
    <Drawer
      centered={true}
      width="300px"
      closable={true}
      visible={editFlag}
      title={"Edit Arrear"}
      footer={[
        <Button
          key="back"
          className="mr-10"
          onClick={() => {
            setEditFlag(false);
            setEditData({});
          }}
        >
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={async () => {
            if (adjustedAmountRemarks === "") {
              notification.error({
                message: "Please enter a remark for adjusting arrear amount",
              });
              return;
            }
            let body = {
              arrearAmount:
                Number(editData?.arrearAmount) + Number(adjustedAmount),
              adjustedAmount: Number(adjustedAmount),
              adjustedAmountRemarks: adjustedAmountRemarks,
            };
            if (type === "land-lease") {
              body.landLeaseId = editData?.landLeaseId;
            } else if (type === "shop-lease") {
              body.shopLeaseId = editData?.shopLeaseId;
            } else {
              body.licenseLeaseId = editData?.licenseLeaseId;
            }
            await handleArrearBillUpdate(editData?.arrearId, body, router);
            setAdjustedAmount("");
            setAdjustedAmountRemarks("");
            setEditFlag(false);
            setEditData({});
            setUpdate(Math.random() * 100000 + 975);
          }}
        >
          Adjust and Update
        </Button>,
      ]}
      onClose={() => {
        setEditFlag(false);
        setEditData({});
      }}
    >
      <Row justify="flex-start" gutter={[20, 20]}>
        <Col span={24}>
          <table className="DetailsTable">
            <tr>
              <th>
                {type === "land-lease"
                  ? `Survey Number`
                  : type === "shop-lease"
                  ? "Building Name"
                  : "License Name"}
              </th>
              <td>
                {type === "land-lease"
                  ? editData?.surveyNumber
                  : type === "shop-lease"
                  ? editData?.buildingName
                  : editData?.licenseName}
              </td>
            </tr>
            <tr>
              <th>
                {type === "land-lease"
                  ? `Survey Breakup Number`
                  : type === "shop-lease"
                  ? "Block/Floor Name"
                  : "Nature of Business"}
              </th>
              <td>
                {type === "land-lease"
                  ? editData?.surveyBreakup
                  : type === "shop-lease"
                  ? editData?.blockName
                  : editData?.natureOfBusiness}
              </td>
            </tr>
            {type === "shop-lease" && (
              <tr>
                <th>Shop Number</th>
                <td>{editData?.shopNumber ?? "-"}</td>
              </tr>
            )}
            <tr>
              <th>Name of Leese</th>
              <td>{editData?.lesseName}</td>
            </tr>
            <tr>
              <th>
                {type === "license-lease" ? "License Type" : `Lesse Type`}
              </th>
              <td>{editData?.lesseType}</td>
            </tr>
            <tr>
              <th>Lease Amount</th>
              <td>{FormatNumberINRCurrency(Number(editData?.leaseAmount))}</td>
            </tr>
            <tr>
              <th>Arrear Amount</th>
              <td>{FormatNumberINRCurrency(Number(editData?.arrearAmount))}</td>
            </tr>
            {/* <tr>
              <th>Interest Amount</th>
              <td>{editData?.interestAmount ?? "-"}</td>
            </tr> */}
          </table>
        </Col>
        <Col span={24}>
          <Row className={"t-left"}>Adjustment Amount*</Row>
          <Input
            maxLength={220}
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            placeholder="Enter New Adjusted Amount"
            value={adjustedAmount}
            onChange={(e) => setAdjustedAmount(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Row className={"t-left"}>Adjustment Reason/Remarks</Row>
          <Input
            maxLength={220}
            type="text"
            onWheel={(event) => event.currentTarget.blur()}
            placeholder="Enter Adjustment Reason"
            value={adjustedAmountRemarks}
            onChange={(e) => setAdjustedAmountRemarks(e.target.value)}
          />
          <p>
            Updated Arrear Amount :{" "}
            {Number(editData?.arrearAmount) + Number(adjustedAmount)}
          </p>
        </Col>
      </Row>
    </Drawer>
  );
};
export default BillAdjustment;
