import { Button, Card, Col, Modal, Row } from "antd";
import React from "react";
const TmsPosDonationPreviewModal = ({
  previewFlag,
  setPreviewFlag,
  buttonLoading,
  handleSubmit,
  previewData,
}) => {
  return (
    <Modal
      visible={previewFlag}
      width={700}
      footer={
        <>
          <Button
            type="danger"
            onClick={(e) => {
              e.preventDefault();
              setPreviewFlag(false);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={buttonLoading}
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              buttonLoading === false && handleSubmit();
            }}
          >
            Submit
          </Button>
        </>
      }
      closable={false}
      centered
      maskClosable={false}
      onCancel={() => {
        setPreviewFlag(false);
      }}
    >
      <Card title="Donation Preview">
        {Object?.entries?.(previewData)?.map((item, i) => {
          return (
            <Row key={i}>
              <Col span={12}>{item[0]}</Col> <Col span={12}>{item[1]}</Col>
            </Row>
          );
        })}
      </Card>
    </Modal>
  );
};
export default TmsPosDonationPreviewModal;
