import React, { useContext, useEffect, useState } from "react";

import { Space, Row, Col, Button, notification, Select, Switch } from "antd";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsSevasService from "../../../../../services/tms-sevas.service";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import TmsModal from "../../../../../components/tms-customs/tms-modal";
import TmsCheckbox from "../../../../../components/tms-customs/tms-checkbox";
import TmsPartnerService from "../../../../../services/partners.service";
const { Option } = Select;

const TmsSipPartners = ({ router }) => {
  const { userDetails } = useContext(BaseContext);
  const [loading, setLoading] = useState(true);
  const [partnerData, setPartnerData] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState("");

  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [allPartnerList, setAllPartnerList] = useState([]);
  const [name, setName] = useState("");
  const [enabled, setEnabled] = useState(false);
  //   const { Search } = Input;

  useEffect(() => {
    setName(new URLSearchParams(router?.location?.search).get("name"));
    fetchData();
  }, [updateFlag, router?.location?.pathname]);

  useEffect(() => {
    fetchAllPartners();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      let id = new URLSearchParams(router?.location.search).get("id");
      const data = await TmsSevasService.getPartnersForSeva(id);
      setPartnerData(data);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const fetchAllPartners = async () => {
    setLoading(true);
    try {
      const data = await TmsPartnerService.getPartnersByTemple();
      setAllPartnerList(data);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleSubmit = async (partnerId, flag) => {
    try {
      setSubmitLoading(true);
      let body = {
        productId: Number(
          new URLSearchParams(router?.location?.search).get("id")
        ),
        partnerId: partnerId ?? selectedPartner.id,
        enabled: flag ?? enabled,
      };
      await TmsSevasService.sevPartnerAccess(body);
      notification.success({
        message:
          partnerId && flag
            ? `Partner status updated for ${name}`
            : `Partner succesfully added to ${name}`,
      });
      setUpdateFlag(Math.random() * 100 + 9);
      setSubmitLoading(false);
      setShowPartnerModal(false);
    } catch (e) {
      catchErrorFunction(e, "Token expired!.Login again", router);
      setSubmitLoading(false);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Partner Name",
      dataIndex: "partner",
      key: "partner",
      render: (record) => {
        return record?.partnerName;
      },
    },

    {
      title: "Partner Code",
      dataIndex: "partner",
      key: "partner",
      render: (record) => {
        return record?.partnerCode;
      },
    },

    {
      title: "Action",
      render: (record) => {
        return userDetails?.permissions.includes("SUPER_ADMIN") ||
          userDetails?.permissions.includes("ADMIN") ? (
          <Space size="middle">
            <Switch
              size={"small"}
              checked={record?.enabled}
              style={{
                background: record?.isActive ? "#4CBB17" : "EFEFEF",
              }}
              onClick={() => {
                handleSubmit(record?.partnerId, !record?.enabled);
              }}
            />
          </Space>
        ) : (
          ""
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={[20, 20]} justify="space-between">
        <Col flex="initial">
          <Button
            type="primary"
            onClick={() => {
              setShowPartnerModal(true);
            }}
            disabled={
              userDetails?.permissions.includes("SUPER_ADMIN") ||
              userDetails?.permissions.includes("ADMIN")
                ? false
                : true
            }
          >
            Add Partner
          </Button>
        </Col>
        <Col justify="end" style={{ fontSize: "18px" }}>
          Seva Name: <b>{name}</b>
        </Col>
      </Row>

      <br />
      <TmsTable
        dataSource={partnerData}
        loading={loading}
        searchable={["display_name"]}
        columns={columns}
      />
      <TmsModal
        visible={showPartnerModal}
        width={400}
        onOk={handleSubmit}
        closable={false}
        centered
        onCancel={() => {
          setShowPartnerModal(false);
          setSelectedPartner("");
          setEnabled(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setShowPartnerModal(false);
              setSelectedPartner("");
              setEnabled(false);
            }}
          >
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={submitLoading}
            onClick={() => submitLoading === false && handleSubmit()}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify="center" style={{ width: "100%", padding: "10px 0px" }}>
          <Select
            width="100%"
            style={{ width: "100%" }}
            value={selectedPartner?.partnerName}
            defaultValue={selectedPartner?.partnerName}
            placeholder="Please select a Partner"
            onChange={(e) => {
              let temp = allPartnerList?.find((item) => item.partnerName === e);
              setSelectedPartner(temp);
            }}
          >
            {allPartnerList?.map((item, i) => {
              return (
                <Option
                  key={item.partnerName + "seva-list" + i}
                  value={item.partnerName}
                >
                  {item?.partnerName} - {item?.partnerCode}
                </Option>
              );
            })}
          </Select>
        </Row>
        <TmsCheckbox
          label={"Enable Partner"}
          value={enabled}
          setValue={setEnabled}
        />
      </TmsModal>
    </>
  );
};
export default withRouter(TmsSipPartners);
