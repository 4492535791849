import { FormatNumberINRCurrency } from "../utils/tms-currency-formatter/tms-curreny-formatter";

export const TmsConsildatedColums = (type, bookingType, groupBy) => {
  const columns = [
    { title: "ID", dataIndex: "productId", key: "productId" },
    {
      title:
        type === "Darshanam"
          ? "Darshanam Type"
          : type === "Sevas"
          ? "Seva Type"
          : type === "Thulabharam"
          ? "Thulabahram Type"
          : type === "Toll Gate"
          ? "Vehicle Type"
          : type === "Prasadam"
          ? "Prasadam Type"
          : type === "Sevas"
          ? "Seva Type"
          : type === "Donation"
          ? "Donation Type"
          : type === "Accommodation"
          ? "Block Name"
          : type === "Accommodation Update"
          ? "Block Name"
          : type === "Products"
          ? "Products"
          : "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Date ",
      dataIndex: "Date",
      key: "Date",
      hidden: groupBy === "date" ? true : false,
    },

    {
      title: "Ticket No",
      hidden: bookingType === "pos" ? false : true,
      children: [
        {
          title: "From",
          dataIndex: "ticketStart",
          key: "ticketStart",
          sorter: (a, b) => a.ticketStart - b.ticketStart,
          defaultSortOrder: "ascend",
        },
        {
          title: "To",
          dataIndex: "ticketEnd",
          key: "ticketEnd",
          sorter: (a, b) => a.ticketEnd - b.ticketEnd,
          defaultSortOrder: "ascend",
        },
      ],
    },
    {
      title: "Unit Price ",
      dataIndex: "productPrice",
      key: "productPrice",
      render: FormatNumberINRCurrency,
    },
    {
      title: "Quantity",
      dataIndex: "totalTickets",
      key: "totalTickets",
    },
    {
      title: "GST",
      dataIndex: "productGst",
      key: "productGst",
      hidden: !["Accommodation", "Accommodation Update"].includes(type)
        ? true
        : false,
    },
    {
      title: "Renewal Count",
      dataIndex: "renewalCount",
      render: (record) => record ?? "-",
      key: "renewalCount",
      hidden: !["Accommodation", "Accommodation Update"].includes(type)
        ? true
        : false,
    },
    {
      title: "Amount",
      dataIndex: "totalCollection",
      key: "totalCollection",
      render: FormatNumberINRCurrency,
    },
  ].filter((item) => !item.hidden);
  return columns;
};
