import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsProductsService = {
  getProducts: async (page, limit) => {
    const resp = await Axios.get(
      `v1/shop-product/all?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createProduct: async (body) => {
    const resp = await Axios.post(`v1/shop-product/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteProduct: async (productId) => {
    const resp = await Axios.delete(`v1/shop-product/delete/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewProduct: async (productId) => {
    const resp = await Axios.get(`v1/shop-product/by-id/${productId}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  editProduct: async (productId, body) => {
    const resp = await Axios.put(`v1/shop-product/update/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  //product-counters
  getProductCounters: async (id) => {
    const resp = await Axios.get(`v1/shop-product-counter/by-product/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  createProductCounters: async (body) => {
    const resp = await Axios.post(`v1/shop-product-counter/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editProductCounters: async (body, slotId) => {
    const resp = await Axios.put(
      `v1/shop-product-counter/update/${slotId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteProductCounters: async (slotId) => {
    const resp = await Axios.delete(
      `v1/shop-product-counter/delete/${slotId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  updateTerms: async (productId, termsData, onlineTerms) => {
    const resp = await Axios.patch(
      `v1/shop-product/metadata/${productId}`,
      {
        termsAndConditions: termsData,
        onlineTermsAndConditions: onlineTerms,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //products booking

  fetchValidProducts: async (type, date, noOfItems) => {
    let url = `v1/shop-product-counter-booking/valid-products?type=${type}&date=${date}`;
    if (type === "GENERAL_WITH_QUOTA") {
      url += `&noOfItems=${Number(noOfItems)}`;
    }
    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  productBooking: async (body, payment, cashReference, cashRemarks) => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        shopProductBookings: body,
        paymentType: payment,
        cashRemarks: cashReference,
        cashReference: cashRemarks,
        multiTicketsLinked: true,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //product - publication API's
  getPublicationsFiles: async (productId, page, limit) => {
    const resp = await Axios.get(
      `v1/shop-product-pub-file/all/${productId}?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createPublicationFile: async (body) => {
    const resp = await Axios.post(`v1/shop-product-pub-file/new`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deletePublicationFile: async (productId) => {
    const resp = await Axios.delete(`v1/shop-product-pub-file/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewPublicationFile: async (productId) => {
    const resp = await Axios.get(
      `v1/shop-product-pub-file/by-id/${productId}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  editPublicationFile: async (productId, body) => {
    const resp = await Axios.put(
      `v1/shop-product-pub-file/edit/${productId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //   donationBookingDetails: async (
  //     format,
  //     fromDate,
  //     toDate,
  //     dateType,
  //     sortType,
  //     page,
  //     limit,
  //     selectedDonationId
  //   ) => {
  //     let url = `v1/donation-counter-booking/reports?format=${format}&page=${page}&limit=${limit}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;
  //     if (selectedDonationId && selectedDonationId !== "") {
  //       url += `&productId=${Number(selectedDonationId)}`;
  //     }
  //     const resp = await Axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     });
  //     return resp.data;
  //   },

  productReport: async (
    format,
    page,
    limit,
    fromDate,
    toDate,
    counter,
    shift,
    productId
  ) => {
    let url = `v1/shop-product-counter-report/booking?format=${format}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${page}&limit=${limit}`;
    if (productId && productId !== "") {
      url += `&productId=${Number(productId)}`;
    }
    if (counter && counter !== "") {
      url += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      url += `&counterShiftTimingId=${shift}`;
    }
    const resp = await ReportsAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/shop-product-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  //   donationOnlineReport: async (
  //     format,
  //     page,
  //     limit,
  //     fromDate,
  //     toDate,
  //     sortType,
  //     counter,
  //     shift,
  //     selectedDonationId
  //   ) => {
  //     let url = `v1/donation-online-report/booking?format=${format}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${page}&limit=${limit}&order=${sortType}`;
  //     if (selectedDonationId && selectedDonationId !== "") {
  //       url += `&productId=${Number(selectedDonationId)}`;
  //     }
  //     // if (counter && counter !== "") {
  //     //   url += `&counterId=${counter}`;
  //     // }
  //     // if (shift && shift !== "") {
  //     //   url += `&counterShiftTimingId=${shift}`;
  //     // }
  //     const resp = await Axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     });
  //     return resp.data;
  //   },

  //   getConsolidateReport: async (
  //     format,
  //     productId,
  //     from,
  //     to,

  //     counter,
  //     shift
  //   ) => {
  //     let query = `v1/donation-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

  //     if (productId !== "") {
  //       query += `&productId=${productId}`;
  //     }
  //     if (counter !== "") {
  //       query += `&counterId=${counter}`;
  //     }
  //     if (shift !== "") {
  //       query += `&counterShiftTimingId=${shift}`;
  //     }
  //     let res = await Axios.get(query, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     });
  //     return res.data;
  //   },
  //   getOnlineConsolidateReport: async (format, productId, from, to) => {
  //     let query = `v1/donation-online-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

  //     if (productId !== "") {
  //       query += `&productId=${productId}`;
  //     }
  //     let res = await Axios.get(query, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     });
  //     return res.data;
  //   },
};

export default TmsProductsService;
