import Axios from "../utils/tms-axios/tms-axios";
// import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsRentsService = {
  //Institutions
  getCreatedInstitutions: async (page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/institution/get-all?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createInstitution: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/institution/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateInstitution: async (body, institutionID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/institution/update/${institutionID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteInstitution: async (institutionID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/institution/delete/${institutionID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //Created
  getCreatedLands: async (institution, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/land/get-all/${institution}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createLands: async (body) => {
    const resp = await Axios.post(`v1/rent-and-lease/land/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateCreatedLand: async (body, landID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/land/update/${landID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedLand: async (landID) => {
    const resp = await Axios.delete(`v1/rent-and-lease/land/delete/${landID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getCreatedLandReports: async (institution, page, limit) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/reports/${institution}/land?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getCreatedReportsByType: async (type, page, limit) => {
    let url = `v1/rent-and-lease/reports/totalReports?reportType=${type}&page=${page}&limit=${limit}`;
    type;
    const resp = await Axios.get(url, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return resp.data;
  },

  //Survey Number
  surveyChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/survey-number/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getCreatedSurveyNumbers: async (landId, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/survey-number/get-all/${landId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getSurveyNosByIstitution: async (
    institutionID,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/survey-number/get-by-institution/${institutionID}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createSurveyNumber: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/survey-number/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateSurveyNumber: async (body, surveyNumberID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/survey-number/update/${surveyNumberID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteSurveyNumber: async (surveyNumberID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/survey-number/delete/${surveyNumberID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //Survey Number BreakUps
  getCreatedSurveyBreakUps: async (
    surveyNumber,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/survey-number-breakup/get-all/${surveyNumber}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createSurveyNumberBreakUp: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/survey-number-breakup/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateSurveyNumberBreakUp: async (body, surveyNumberBreakUpID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/survey-number-breakup/update/${surveyNumberBreakUpID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  surveyBreakupChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/survey-number-breakup/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteSurveyNumberBreakUp: async (surveyNumberBreakUpID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/survey-number-breakup/delete/${surveyNumberBreakUpID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //Encroachment
  encroachmentChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/encroachment/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getCreatedEncroachment: async (
    surveyNumber,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/encroachment/get-all/${surveyNumber}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createEncroachment: async (body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/encroachment/create`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateEncroachment: async (body, EncroachmentID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/encroachment/update/${EncroachmentID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteEncroachment: async (EncroachmentID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/encroachment/delete/${EncroachmentID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getEncroahmentReports: async (institution, page, limit) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/reports/${institution}/encroachment?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  //Land Lease
  getCreatedLandLeases: async (
    surveyNumber,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/land-lease/get-all/${surveyNumber}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getLandLeaseInvoices: async (
    landLeaseId,
    latest,
    page,
    limit,
    sortOrder,
    sortBy
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/land-lease/invoice/${landLeaseId}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}&latestRecord=${latest}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getInvoiceByInvoiceID: async (invoiceID) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/land-lease/get-by-invoice/${invoiceID}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  getInvoiceAcknowledgement: async (ackID) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/land-lease-transaction/get-by-acknowledgement/${ackID}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  handleInvoicePayment: async (invoiceID, body) => {
    const resp = await Axios.post(
      `v1/rent-and-lease/land-lease-transaction/${invoiceID}/payment`,
      body,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  handleInvoiceAcknowledgement: async (ackID, body) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/land-lease-transaction/${ackID}/updatePayment`,
      body,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createLandLeases: async (body) => {
    const resp = await Axios.post(`v1/rent-and-lease/land-lease/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  landLeaseChangeStatus: async (id, status) => {
    const resp = await Axios.patch(
      `v1/rent-and-lease/land-lease/change-status/${id}?status=${status}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateCreatedLandLease: async (body, landLeaseID) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/land-lease/update/${landLeaseID}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedLandLease: async (landLeaseID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/land-lease/delete/${landLeaseID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getLeaseReports: async (
    institution,
    page,
    limit,
    leaseStartDate,
    leaseEndDate
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/reports/${institution}/leaseReport?page=${page}&limit=${limit}&leaseStartDate=${leaseStartDate}&leaseEndDate=${leaseEndDate}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  //Arrears

  getCreatedArrears: async (surveyNo, page, limit, sortOrder, sortBy) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/arrear/get-all/${surveyNo}?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createArrear: async (body) => {
    const resp = await Axios.post(`v1/rent-and-lease/arrear/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateCreatedArrears: async (body, arreaId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/arrear/update/${arreaId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateArrearBill: async (body, arreaId) => {
    const resp = await Axios.put(
      `v1/rent-and-lease/arrear/billAdjustment/${arreaId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteCreatedArrear: async (arrearID) => {
    const resp = await Axios.delete(
      `v1/rent-and-lease/land/delete/${arrearID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  getArrearsReports: async (
    institution,
    page,
    limit
    // leaseStartDate,
    // leaseEndDate
  ) => {
    const resp = await Axios.get(
      `v1/rent-and-lease/reports/${institution}/arrearList?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
};
export default TmsRentsService;
