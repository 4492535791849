import { Col, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import moment from "moment";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import TmsPrintService from "../../../../services/tms-print.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import getColumnSearchProps from "../../../../utils/tms-table-search/tms-search";

const TmsReportPrintLog = ({ router }) => {
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [sortType, setSortType] = useState("ASC");
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [loading, setLoading] = useState();
  const [excelLoading, setExcelLoading] = useState(false);
  const [printDetails, setPrintDetails] = useState([]);
  const [type, setType] = useState("print");

  const API = {
    print: TmsPrintService.printLogs,
    reprint: TmsPrintService.reprintLogs,
  };

  const fetchPrintDetails = async () => {
    setLoading(true);
    try {
      const data = await API[type](
        Number(tableCurrentPage),
        Number(tablePageSize),
        bookingFromDate,
        bookingToDate,
        sortType
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setPrintDetails(
        data?.items?.map((item) => {
          return type === "print"
            ? { key: item?.id, ...item }
            : {
                createdAt: moment(item?.createdAt).format("DD-MM-YYY hh:mm A"),
                ticketId: item?.ticketId,
                outcome: item?.outcome,
                userName: item?.userName,
                categoryTransactionSequence:
                  item?.ticketDetails[0]?.categoryTransactionSequence,
              };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPrintDetails();
  }, [
    bookingFromDate,
    bookingToDate,
    sortType,
    tableCurrentPage,
    tablePageSize,
    type,
  ]);

  const printColumns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "ticketId",
      hidden: type === "print",
    },
    {
      title: "Ticket Id",
      dataIndex: "ticketId",
      key: "ticketId",
      ...getColumnSearchProps("ticketId"),
    },
    {
      title: "Txn Id",
      dataIndex: "categoryTransactionSequence",
      key: "categoryTransactionSequence",
      ...getColumnSearchProps("categoryTransactionSequence"),
    },

    {
      title: "Status",
      dataIndex: "outcome",
      key: "ticketId",
      render: (text) => (
        <Tag color={text?.toLowerCase() === "success" ? "success" : "error"}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Failed Reason",
      dataIndex: "failureReason",
      key: "failureReason",
      hidden: type === "reprint",
      render: (text) => (text?.length > 0 ? text : "-"),
    },

    {
      title: "created at",
      dataIndex: "createdAt",
      key: "createdAt",
      hidden: type === "print",
    },
  ].filter((item) => !item.hidden);

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdDate") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      let data = await API[type](
        Number(tableCurrentPage),
        Number(type === "print" ? 50000 : 100),
        bookingFromDate,
        bookingToDate
      );

      data = data?.items?.reverse();
      let reprintData = [];
      let printData = [];
      if (type === "print") {
        printData = data.map((item, i) => {
          return {
            "S.no": i + 1,
            "Ticket Id": item?.ticketId,
            "Txn Id": item?.categoryTransactionSequence,
            Status: item?.outcome,
            "Failed Reason":
              item?.failureReason?.length > 0 ? item?.failureReason : "-",
          };
        });
      } else {
        reprintData = data.map((item, i) => {
          return {
            "S.no": i + 1,
            "User Name": item?.userName,
            "Ticket Id": item?.ticketId,
            "Txn Id": item?.ticketDetails[0]?.categoryTransactionSequence,
            Status: item?.outcome,
            "created At": moment(item?.createdAt).format("DD-MM-YYY hh:mm A"),
          };
        });
      }

      exportExcel(
        type === "print" ? printData : reprintData,
        `${type}-logs-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setType}
          value={type}
          data={[
            { id: "print", name: "print" },
            { id: "reprint", name: "reprint" },
          ]}
          optional={true}
        />
        <Col>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <Col>
          <TmsExcelButton
            loading={excelLoading}
            handleExcelExport={handleExcelExport}
            top={true}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={printColumns}
          dataSource={printDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </>
  );
};

export default TmsReportPrintLog;
