import {
  Button,
  Col,
  Divider,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import moment from "moment";
import {
  InstitutionSelect,
  LeaseInvoice,
  SortTypeAndBy,
  SurveyNoSelect,
} from "../../../../../../components/tms-customs/rents-and-leases/index";
import BillAdjustment from "../../../../../../components/tms-customs/rents-and-leases/bill-adjustment";
import TmsModal from "../../../../../../components/tms-customs/tms-modal";
import { useReactToPrint } from "react-to-print";
import { DeleteOutlined } from "@ant-design/icons";
import {
  additionalArrearStates,
  arrearStates,
} from "../../../../../../constants/states-data.data";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";

const TmsSmRalArrearList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState(false);
  const [view, setView] = useState(false);
  const [adjustedAmount, setAdjustedAmount] = useState("");
  const [adjustedAmountRemarks, setAdjustedAmountRemarks] = useState("");
  const refContainer = useRef(null);

  const {
    handleExcelExport,
    excelLoading,
    selectedInstitution,
    loading,
    clearStates,
    fetchInstitutionList,
    fetchArrears,
    fetchSurveyNumbers,
    selectedSurveyNumber,
    createdArrearsData,
    handleArrearUpdate,
    handleArrearBillUpdate,
    deleteCreatedArrear,
    deleteLoading,
    fetchLeaseInvoices,
  } = useRentsAndLandStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  useEffect(() => {
    return () => {
      clearStates(arrearStates, additionalArrearStates);
    };
  }, []);

  useEffect(() => {
    router.location.pathname === tmsRouteMap.sm_ral_lands_arears_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution) {
      fetchSurveyNumbers(1, 200, "ASC", "createdAt", router);
    }
  }, [selectedInstitution]);

  useEffect(() => {
    if (selectedSurveyNumber && selectedSurveyNumber !== "")
      fetchArrears(tableCurrentPage, tablePageSize, sortType, sortBy, router);
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedSurveyNumber,
    update,
  ]);
  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "tms-lease-container",
    onAfterPrint: () => {
      setView(false);
    },
  });

  const handleUpdate = async (record, status) => {
    const records = { ...record, status: status };
    await handleArrearUpdate(records, router);
    setUpdate(Math.random() * 100000 + 21);
  };
  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <InstitutionSelect span={5} />
        <SurveyNoSelect span={5} />
        <SortTypeAndBy
          span={5}
          sortBy={sortBy}
          sortType={sortType}
          setSortBy={setSortBy}
          setSortType={setSortType}
        />{" "}
        {selectedSurveyNumber !== "" && selectedSurveyNumber && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() => handleExcelExport("arrear", router)}
              top={false}
            />
          </Col>
        )}
      </Row>
      <br />
      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          {
            title: "Survey No",
            dataIndex: "surveyNumber",
            render: (record) => record?.surveyNo,
          },
          {
            title: "Survey No Breakup",
            dataIndex: "surveyNumberBreakup",
            render: (record) => record?.blockNumber,
          },
          { title: "land Lease Id", dataIndex: "landLeaseId" },

          {
            title: "interestAmount",
            dataIndex: "interestAmount",
          },

          {
            title: "Arrear Amount",
            dataIndex: "arrearAmount",
          },

          {
            title: "legalAction",
            dataIndex: "legalAction",
            render: (record) => `${record ? "No" : "Yes"}`,
          },
          {
            title: "Due Date",
            dataIndex: "dueDate",
            render: (record) => moment(record).format("DD-MM-YYYY"),
          },
          {
            title: "Lease Period From & To",
            render: (record) =>
              `${moment(record.leasePeriodStartDate).format(
                "DD-MM-YYYY"
              )} to ${moment(record.leasePeriodEndDate).format("DD-MM-YYYY")}`,
          },

          {
            title: "Status",
            key: "status",
            render: (record) => {
              return (
                <Switch
                  size={"small"}
                  checked={record.status}
                  onClick={() => {
                    handleUpdate(record, !record.status);
                  }}
                />
              );
            },
          },
          {
            title: "Actions",
            key: "status",
            render: (record) => {
              return (
                <Space>
                  <a
                    onClick={async () => {
                      try {
                        let records = await fetchLeaseInvoices(
                          record.id,
                          true,
                          1,
                          200,
                          "ASC",
                          "createdAt",
                          router
                        );
                        if (!records?.id) {
                          notification.error({
                            message: "No latest invoices are generated",
                          });
                          return;
                        } else {
                          setView(true);
                          setEditData(records);
                        }
                      } catch (e) {
                        console.log(e);
                        setView(false);
                        notification.error({
                          message: "No latest invoices",
                        });
                        setEditData({});
                      }
                    }}
                  >
                    View
                  </a>
                  <Divider type="vertical" />
                  <a
                    style={{ whiteSpace: "pre" }}
                    onClick={() => {
                      setEditData(record);
                      setEditFlag(true);
                    }}
                  >
                    Bill Adjustment
                  </a>{" "}
                  <Divider type="vertical" />
                  <Space size="middle">
                    <Popconfirm
                      title="Are you sure delete this arrear?"
                      onConfirm={async () => {
                        await deleteCreatedArrear(record.id, router);
                        setUpdate(Math.random() * 100000 + 21);
                      }}
                      okText="Yes"
                      cancelText="No"
                      loading={deleteLoading}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Space>{" "}
                </Space>
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={createdArrearsData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdArrearsData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
      <TmsModal
        visible={view}
        width={"50%"}
        onOk={() => {
          setView(false);
          setEditData({});
        }}
        closable={true}
        centered
        onCancel={() => {
          setView(false);
          setEditData({});
        }}
      >
        <Row justify={"center"}>
          <Button onClick={handlePrint} type="primary">
            Print
          </Button>
        </Row>
        <br />
        <LeaseInvoice
          data={editData}
          type="land-lease"
          refContainer={refContainer}
          scantype={"payment"}
        />
      </TmsModal>
      <BillAdjustment
        type="land-lease"
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        loading={loading}
        editData={{
          surveyNumber: editData?.surveyNumber?.surveyNo,
          surveyBreakup: editData?.surveyNumberBreakup?.blockNumber,
          lesseName: editData?.lesseName,
          lesseType: editData?.lesseType,
          leaseAmount: editData?.leaseRate,
          arrearAmount: editData?.arrearAmount,
          arrearId: editData?.id,
          landLeaseId: editData.landLeaseId,
        }}
        setEditData={setEditData}
        adjustedAmount={adjustedAmount}
        setAdjustedAmount={setAdjustedAmount}
        adjustedAmountRemarks={adjustedAmountRemarks}
        setAdjustedAmountRemarks={setAdjustedAmountRemarks}
        handleArrearBillUpdate={handleArrearBillUpdate}
        setUpdate={setUpdate}
        router={router}
      />
    </>
  );
};

export default withRouter(TmsSmRalArrearList);
