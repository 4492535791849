import Axios from "../utils/tms-axios/tms-axios";

const TmsProtocolService = {
  protocolRequestPost: async (body) => {
    const res = await Axios.post(`v1/protocol/request`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  protocolRequestGet: async (page, limit) => {
    const res = await Axios.get(
      `v1/protocol/requests?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return res.data;
  },
  requestStatus: async (body) => {
    const resp = await Axios.post(`v1/protocol/request/review`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  protocolRequestbyId: async (body, id) => {
    const res = await Axios.put(`v1/protocol/request/${id}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  protocolHistoryGet: async (page, limit, id) => {
    const res = await Axios.get(
      `v1/protocol/request/history/${id}?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return res.data;
  },
  protocolReviewHistoryGet: async (page, limit, id) => {
    const res = await Axios.get(
      `v1/protocol/request/review/${id}?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return res.data;
  },
  protocolprint: async (body) => {
    const res = await Axios.post(`v1/protocol-booking`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
};

export default TmsProtocolService;
