import { Input, Row } from "antd";
import React, { memo } from "react";
import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";

const InputPhoneNumber = ({
  size,
  setState,
  value,
  onKeyDown,
  error,
  setError,
  label,
  mandatory,
}) => {
  return (
    <Row className="d-flex-c">
      <label>
        {label ? label : "Mobile Number"}
        {mandatory && <span className="mandatory">*</span>}
      </label>
      <Input
        controls={false}
        size={size}
        onWheel={(event) => event.currentTarget.blur()}
        className="mobile-field"
        placeholder="Enter Phone Number"
        autoComplete="off"
        onChange={(e) => {
          console.log(e.target.value);
          if (error !== "") {
            setError("");
          }
          if (e.target.value.length > 10) {
            return;
          }
          let input = e.target.value;
          let pattern = /^\d*$/;

          if (pattern.test(input)) {
            setState(input);
          }
        }}
        value={value}
        type="number"
        min="0"
        addonBefore={<p style={{ margin: "0" }}>+91</p>}
        onKeyDown={onKeyDown}
        style={{ margin: "5px 0px 10px 0px" }}
        maxLength={220}
      />
    </Row>
  );
};
export default memo(InputPhoneNumber);
