import React, { memo } from "react";
import useRentsAndLandStore from "../../../zustand/rents-and-leases.store";
import TmsSelect from "../tms-select";

const LandCreationSelect = ({ span }) => {
  const { createdLandData, setSelectedLand, selectedLand, clearStates } =
    useRentsAndLandStore((state) => {
      return {
        createdLandData: state.createdLandData,
        clearStates: state.clearStates,
        setSelectedLand: state.setSelectedLand,
        selectedLand: state.selectedLand,
      };
    });

  return (
    <TmsSelect
      type="col"
      span={span}
      setValue={(e) => {
        setSelectedLand(createdLandData?.items?.find((item) => item.id === e));
        clearStates(["selectedSNBreakup", "selectedSurveyNumber"], []);
      }}
      label="Land Location"
      data={createdLandData?.items?.map((item) => {
        return {
          name: `ID: ${item?.id} - ${item?.landmark ?? "-"} ${
            item?.village ?? ""
          } ${item?.mandal ?? ""} ${item?.district ?? ""} ${
            item?.state ?? ""
          } ${item?.country ?? ""} ${item?.pincode ?? ""}`,
          id: item.id,
        };
      })}
      value={selectedLand?.id}
      optional={true}
    />
  );
};

export default memo(LandCreationSelect);
