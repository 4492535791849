import { Button, Col, Input, notification, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TmsDatepickerRange from "./tms-datepicker-range";
import TmsSelect from "./tms-select";
import TmsAccommodationService from "../../services/tms-accommodation.service";
import TmsDarshanamService from "../../services/tms-darshanam/tms-darshanam.service";
import TmsSevasService from "../../services/tms-sevas.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import TmsStringUtils from "../../utils/tms-string-utils/tms-string-utils";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import TmsLoading from "./tms-loading";
const TmsBatchSlots = ({ router, type }) => {
  const [sevaType, setSevaType] = useState("");
  const [loading, setLoading] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [quota, setQuota] = useState(0);
  const [quotaLoading, setQuotaLoading] = useState(false);
  const [quotaType, setQuotaType] = useState("online");
  const [slotsData, setSlotsData] = useState([]);
  const [selectedSlotId, setSelectedSlotId] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const sevaTypeServices = {
    Sevas: TmsSevasService,
    Darshanam: TmsDarshanamService,
    Accommodation: TmsAccommodationService,
  };

  useEffect(() => {
    if (type !== "Sevas") {
      getData(type ?? "Accommodation");
    }
  }, [type]);
  useEffect(() => {
    if (type && selectedId !== "") {
      getSlotData();
      fetchPartners(type);
    }
  }, [selectedId]);

  const handleSubmit = async () => {
    if (selectedId === "") {
      notification?.error({ message: `Please select a ${type}.` });
      setQuotaLoading(true);
      return;
    }
    if (selectedSlotId === "") {
      notification?.error({
        message: `Please select a ${
          type === "Accommodation" ? "Room category" : "slot"
        } .`,
      });
      setQuotaLoading(true);
      return;
    }
    if (quota === "") {
      notification?.error({ message: "Please enter quota to be added." });
      setQuotaLoading(true);
      return;
    }
    if (to === "" || from === "") {
      notification?.error({ message: "Please enter valid dates." });
      setQuotaLoading(true);
      return;
    }

    try {
      let body = {
        dates: TmsStringUtils.enumerateDaysBetweenDates(
          moment(from).format("YYYY-MM-DD"),
          moment(to).format("YYYY-MM-DD")
        ),
        quantity: Number(quota),
      };
      if (router.location?.pathname.includes("accommodation")) {
        body.channel = quotaType;

        body.accBlockCategoryIds = [Number(selectedSlotId)];
      } else {
        body.type = quotaType;
        body.productSlotIds = [Number(selectedSlotId)];
      }

      await sevaTypeServices[type].updateQuotaData(body);

      notification.success({ message: "Quota Updated successfully" });
      setSelectedId("");
      setSelectedSlotId("");
      setQuotaLoading(false);
      setQuotaType("");
      setQuota(0);
    } catch (e) {
      catchErrorFunction(e, "Token expired! Please login again", router);
      setQuotaLoading(false);
    }
  };

  const getData = async (type, e) => {
    setLoading(true);
    try {
      setAPIData([]);
      setSelectedId("");
      setSelectedSlotId("");
      setQuota(0);
      let data = null;
      if (type === "Sevas") {
        data = await sevaTypeServices[type].getSevasByType(e, 1, 1000);
      } else if (type === "Darshanam") {
        data = await sevaTypeServices[type].getDarshanam(1, 1000);
      } else if (type === "Accommodation") {
        data = await sevaTypeServices[type].getAllBlocks(1, 1000);
      } else {
        return;
      }

      setAPIData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const getSlotData = async () => {
    setLoading(true);
    try {
      setSlotsData([]);
      setSelectedSlotId("");
      let data = null;
      if (type === "Sevas") {
        data = await sevaTypeServices[type].getSevaSlots(selectedId, 1, 1000);
      } else if (type === "Darshanam") {
        data = await sevaTypeServices[type].getDarshanamSlots(selectedId);
      } else if (type === "Accommodation") {
        data = await sevaTypeServices[type].getAllBlockCategorys(
          selectedId,
          1,
          1000
        );
      } else {
        return;
      }
      if (type === "Accommodation") {
        setSlotsData(
          data?.items.map((item) => {
            return {
              key: item.id,
              ...item,
            };
          })
        );
      } else {
        setSlotsData(
          data?.map((item) => {
            return {
              key: item.id,
              ...item,
            };
          })
        );
      }

      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };
  const fetchPartners = async (type) => {
    setLoading(true);
    try {
      let data = null;
      if (type === "Sevas") {
        data = await TmsSevasService.getPartnersForSeva(selectedId);
      } else if (type === "Darshanam") {
        data = await TmsDarshanamService.getPartnersForDarshanam(selectedId);
      }
      setPartnerData(data);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };
  return (
    <div>
      {loading ? (
        <div style={{ padding: "20px 0px" }}>
          <TmsLoading />
        </div>
      ) : (
        <Row gutter={[20, 20]} style={{ marginBottom: "10px" }}>
          <Col span={6}>
            <TmsDatepickerRange
              label="Select Batch Dates"
              to={to}
              from={from}
              setFrom={setFrom}
              setTo={setTo}
              // disabledDate={false}
            />
          </Col>

          {type === "Sevas" && (
            <Col span={6}>
              <Row>
                {" "}
                <label>Seva Type</label>
              </Row>
              <Row>
                {" "}
                <Select
                  style={{
                    width: "100%",
                    margin: "5px 10px 0px 0px",
                    maxHeight: "32px",
                  }}
                  onChange={(e) => {
                    setSevaType(e);
                    getData("Sevas", e);
                  }}
                  defaultValue={sevaType}
                  value={sevaType}
                >
                  <Select.Option value="parokshaSeva">
                    Paroksha Seva
                  </Select.Option>
                  <Select.Option value="pratyakshaSeva">
                    Pratyaksha Seva
                  </Select.Option>
                  <Select.Option value="saswataSeva">
                    Saswata Seva
                  </Select.Option>{" "}
                </Select>
              </Row>
            </Col>
          )}

          <TmsSelect
            type="col"
            span={6}
            label={`Select ${type ?? ""}`}
            selectClass="w-100"
            value={selectedId}
            data={APIData?.map((item) => {
              return { name: item.name, id: item.id };
            })}
            setValue={setSelectedId}
            optional={true}
          />
          {selectedId !== "" && (
            <>
              <TmsSelect
                type="col"
                span={6}
                label={`Select ${type ?? ""} Slot`}
                selectClass="w-100"
                value={selectedSlotId}
                data={slotsData?.map((item) => {
                  return {
                    name:
                      type === "Accommodation"
                        ? `ID: ${item.id} | ${item?.roomType} - Rs.${item?.price}`
                        : item.name ?? item?.description,
                    id: item.id,
                  };
                })}
                setValue={setSelectedSlotId}
                optional={true}
              />
            </>
          )}
          <Col span={6}>
            <Row className="cal-select">Quota Type</Row>
            <Select
              width="100%"
              style={{ width: "100%" }}
              value={quotaType}
              placeholder="Please select a Quota Type"
              onChange={(e) => {
                setQuotaType(e);
                setQuota(0);
              }}
            >
              <Select.Option key={"online"} value={"online"}>
                {"Online"}{" "}
              </Select.Option>
              <Select.Option key={"protocol"} value={"protocol"}>
                {"Protocol"}{" "}
              </Select.Option>
              {type === "Accommodation" && (
                <Select.Option
                  key={"advance_counter"}
                  value={"advance_counter"}
                >
                  {"Advance Counter"}{" "}
                </Select.Option>
              )}
              {partnerData?.map((item) => {
                // const temp = allPartnerList.find(
                //   (items) => items.id === item.partnerId
                // );
                return (
                  <Select.Option
                    key={item.partner.partnerCode}
                    value={item.partner.partnerCode}
                  >
                    {item.partner.partnerCode} - {item.partner.partnerName}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
          <Col span={6}>
            <Row className="cal-select">Quota</Row>
            <Row>
              <Input
                maxLength={220}
                type="number"
                min="0"
                defaultValue={quota}
                value={quota}
                style={{ width: "100%" }}
                placeholder="Enter Quota"
                onChange={(e) => {
                  setQuota(Number(e.target.value));
                }}
              ></Input>
            </Row>
          </Col>

          <Col span={3}>
            <Row className="cal-select" style={{ visibility: "hidden" }}>
              Submit
            </Row>
            <Row>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={quotaLoading}
              >
                Submit
              </Button>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default withRouter(TmsBatchSlots);
