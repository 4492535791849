import { Button, Col, DatePicker, Input, Row, notification } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import "../../../../assets/css/tms-protocol/tms-protocol.scss";
import TmsImageUpload from "../../../../components/tms-customs/tms-image-upload";
import TmsDarshanamService from "../../../../services/tms-darshanam/tms-darshanam.service";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import moment from "moment";
import { RightOutlined } from "@ant-design/icons";
import TmsSevasService from "../../../../services/tms-sevas.service";

import TmsCommonService from "../../../../services/tms-common.service";
import TmsAccommodationService from "../../../../services/tms-accommodation.service";
import TmsProtocolService from "../../../../services/tms-protocol.service";
import TmsStringUtils from "../../../../utils/tms-string-utils/tms-string-utils";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsPosProtocolDevoteeList from "../../../../components/tms-pos/tms-pos-protocol/tms-pos-protocol-devoteeList";
import { BaseContext } from "../../../../contexts/BaseContext";
import tmsRouteMap from "../../../../constants/tms-route.map";

const TmsPosProtocol = ({ router }) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const [fileList, setFileList] = useState([]);
  const [persons, setPersons] = useState(1);
  const [personStates, setPersonStates] = useState([
    // { id: 0, name: "", idType: "aadhar", idNum: "", fileUrl: "" }, //remove cmnt for uploading ID file
    { id: 0, name: "", idType: "aadhar", idNum: "" },
  ]);
  const [phone, setPhone] = useState("");
  const [err, setErr] = useState([]);
  const [darshanamList, setDarshanamList] = useState([]);
  const [selectedDarshanArray, setSelectedDarshanArray] = useState([]);
  const [slotsDarshanArray, setSlotsDarshanArray] = useState([]);
  const [bookingDarshanData, setBookingDarshandata] = useState([]);
  const [sevaList, setSevaList] = useState([]);
  const [selectedSevaArray, setSelectedSevaArray] = useState([]);
  const [slotsSevaArray, setSlotsSevaArray] = useState([]);
  const [bookingSevaData, setBookingSevadata] = useState([]);
  const [roomtypesArray, setRoomTypesArray] = useState([]);
  const [roomType, setRoomType] = useState("");
  const [blockList, setBlockList] = useState([]);
  const [blockSelected, setBlockSelected] = useState([]);
  const [bookingAccoData, setBookingAccodata] = useState([
    { productData: [], devoteeData: [""] },
  ]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [performanceDate, setPerformanceDate] = useState(
    moment()?.add(1, "days")
  );
  const { requestEditData } = useContext(BaseContext);
  const [requestId, setRequestId] = useState();

  const [name, setName] = useState("");
  const [protoType, setProtoType] = useState("self");
  const [designation, setDesignation] = useState("");

  const API = [{ Darshanam: TmsDarshanamService, Seva: TmsSevasService }];

  const [blockPersons, setBlockPersons] = useState();

  const personStatesFunc = useCallback(() => {
    let arrPerson = [...personStates];
    if (persons === personStates.length) {
      return;
    }
    if (persons < personStates.length) {
      let b = personStates.length - persons;
      let total = personStates.length;
      while (b !== 0) {
        let data = err.filter((e) => {
          if (
            e.type !== `${total}-name` &&
            e.type !== `${total}-idType` &&
            e.type !== `${total}-idNum` &&
            e.type !== `${total}-fileUrl`
          ) {
            return e;
          }
        });
        setErr(data);
        arrPerson.pop();
        total--;
        b--;
      }
      setPersonStates([...arrPerson]);
      return;
    }
    if (persons > personStates.length) {
      for (let i = personStates.length; i < persons; i++) {
        arrPerson.push({
          id: i,
          name: "",
          idType: "aadhar",
          idNum: "",
          fileUrl: "",
        });
      }
      setPersonStates([...arrPerson]);
      return;
    }
  }, [persons]);

  const handleChange = (index, event, name) => {
    let data = [...personStates];
    if (name === "idType") {
      let value = data[index][`${name}`];
      data[index][`${name}`] = event;
      if (value !== event) {
        data[index][`idNum`] = "";
      }
      setPersonStates(data);
    } else if (name === "idNum") {
      data[index][`idNum`] = event.target.value
        ?.toUpperCase()
        ?.replaceAll?.(" ", "");
      setPersonStates(data);
    } else if (name === "fileUrl") {
      let data = [...personStates];
      data[index][`${name}`] = event;
      setPersonStates(data);
    } else {
      data[index][`${name}`] = event.target.value;
      setPersonStates(data);
    }
  };

  const fetchDarshanam = async () => {
    document.getElementById("searchable")?.focus();

    if (persons) {
      setButtonLoading(true);
      try {
        setDarshanamList([]);
        let id = [];
        let list = [];
        let res = await TmsDarshanamService.getDarshanam(1, 100);

        res?.items
          ?.filter((item) => item?.protocolEnabled)
          ?.map((item) => {
            id = [...id, item?.id];
            list = [
              ...list,
              {
                productId: item?.id,
                name: item?.name,
                price: item?.price,
              },
            ];
          });
        setDarshanamList(list);
        setButtonLoading(false);
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        setButtonLoading(false);
      }
    }
  };

  const commonSlotQuota = async (arr, type) => {
    setButtonLoading(true);
    try {
      let res = await API[0][type].fetchProtocolSlots(
        moment(performanceDate)?.format("YYYY-MM-DD"),
        moment(performanceDate)?.format("YYYY-MM-DD"),
        arr
      );
      if (type === "Darshanam") {
        setSlotsDarshanArray(res);
      } else if (type === "Seva") {
        setSlotsSevaArray(res);
      }
      setButtonLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  const fetchSeva = async () => {
    document.getElementById("searchable")?.focus();

    if (persons) {
      setButtonLoading(true);
      try {
        setSevaList([]);
        let id = [];
        let list = [];
        let res = await TmsSevasService.getSevas(1, 100);

        res?.items
          ?.filter((item) => item?.protocolEnabled)
          ?.map((item) => {
            id = [...id, item?.id];
            list = [
              ...list,
              {
                productId: item?.id,
                name: item?.name,
                price: item?.price,
              },
            ];
          });
        setSevaList(list);
        setButtonLoading(false);
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        setButtonLoading(false);
      }
    }
  };

  const getroomType = async () => {
    setButtonLoading(true);
    try {
      let res = await TmsCommonService.fetchRoomTypes();
      setRoomTypesArray(res);
      setButtonLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  const getBlocks = async () => {
    let id = [];
    let list = [];
    setButtonLoading(true);

    try {
      let res = await TmsAccommodationService.getProtocolQuotaData(
        moment(performanceDate)
          ?.subtract(
            moment(performanceDate).isSame(currentDate, "day") ? 0 : 1,
            "days"
          )
          ?.format("YYYY-MM-DD"),
        moment(performanceDate)
          ?.subtract(
            moment(performanceDate).isSame(currentDate, "day") ? 0 : 1,
            "days"
          )
          ?.format("YYYY-MM-DD"),
        roomType,
        1
      );
      res?.map((item) => {
        id = [...id, item?.id];
        list = [
          ...list,
          {
            productId: item?.id,
            name: item?.accBlockCategory?.accBlock?.name,
            price: item?.accBlockCategory?.price,
            deposit: item?.accBlockCategory?.depositAmount,
            gst: item?.accBlockCategory?.gstAmount,
            maintenance: item?.accBlockCategory?.maintenanceCharge,
            persons: item?.accBlockCategory?.noOfPersons,
          },
        ];
      });
      setBlockList(list);
      setButtonLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  const defaultStates = () => {
    setSelectedDarshanArray([]);
    setBookingDarshandata([]);
    setSelectedSevaArray([]);
    setBookingSevadata([]);
    setRoomType("");
    setBlockSelected([]);
    setBookingAccodata([{ productData: [], devoteeData: [""] }]);
  };

  useEffect(() => {
    personStatesFunc();
    fetchDarshanam();
    fetchSeva();
    getroomType();
    if (requestId === undefined) {
      defaultStates();
    }
  }, [persons, performanceDate]);

  useEffect(() => {
    setBlockList([]);
    roomType?.length > 0 && getBlocks();
  }, [roomType, performanceDate, personStates]);

  useEffect(() => {
    if (new URLSearchParams(router?.location?.search).get("id")) {
      setRequestId(new URLSearchParams(router?.location?.search).get("id"));
      setFileList([
        { url: requestEditData?.refDocumentUrl, status: "done", name: "file" },
      ]);
      setPersons(requestEditData?.noOfDevotees);

      if (requestEditData?.devoteeDetails?.length > 0) {
        let contextPersonStates = [];
        requestEditData?.devoteeDetails?.map((item, i) => {
          contextPersonStates?.push({
            id: i,
            name: item?.personName,
            idType: item?.documentType,
            idNum: item?.documentId,
            //remove cmnt for uploading ID file
            // fileUrl: [
            //   {
            //     url: item?.documentFileURL,
            //     status: "done",
            //     name: "file",
            //   },
            // ],
          });
        });
        setPersonStates(contextPersonStates);
      }

      setPhone(requestEditData?.phoneNumber?.replaceAll("+91", ""));
      setPerformanceDate(moment(requestEditData?.bookingDate));
      setName(requestEditData?.name);
      setProtoType(requestEditData?.type);
      setDesignation(requestEditData?.cader);

      if (requestEditData?.darshanamServices?.length > 0) {
        let selectedDarshanam = [];
        let bookingDarshanam = [];
        requestEditData?.darshanamServices?.map((item) => {
          selectedDarshanam?.push(item?.productQuota?.productSlot?.product?.id);
          bookingDarshanam?.push({
            productData: [item?.productQuota],
            devoteeData: item?.devoteeDetailsIndexes,
          });
        });
        if (selectedDarshanam?.length > 0) {
          commonSlotQuota(selectedDarshanam, "Darshanam");
        }
        setSelectedDarshanArray(selectedDarshanam);
        setBookingDarshandata(bookingDarshanam);
      }

      if (requestEditData?.sevaServices?.length > 0) {
        let selectedSeva = [];
        let bookingSeva = [];
        requestEditData?.sevaServices?.map((item) => {
          let id = item?.productQuota?.productSlot?.product?.id;

          if (selectedSeva?.includes(id)) {
            bookingSeva[selectedSeva?.indexOf(id)].devoteeData = [
              ...bookingSeva[selectedSeva?.indexOf(id)].devoteeData,
              ...item.devoteeDetailsIndexes,
            ];
          } else {
            selectedSeva?.push(id);
            bookingSeva?.push({
              productData: [item?.productQuota],
              devoteeData: item?.devoteeDetailsIndexes,
            });
          }
        });
        if (selectedSeva?.length > 0) {
          commonSlotQuota(selectedSeva, "Seva");
        }
        setSelectedSevaArray(selectedSeva);
        setBookingSevadata(bookingSeva);
      }

      if (requestEditData?.accommodationServices?.length > 0) {
        let acco = requestEditData?.accommodationServices[0]?.productQuota;
        let bookingAcco = [
          {
            productData: [
              {
                productId: acco?.id,
                name: acco?.accBlockCategory?.accBlock?.name,
                price: acco?.accBlockCategory?.price,
                deposit: acco?.accBlockCategory?.depositAmount,
                gst: acco?.accBlockCategory?.gstAmount,
                maintenance: acco?.accBlockCategory?.maintenanceCharge,
                persons: acco?.accBlockCategory?.noOfPersons,
              },
            ],
            devoteeData: [],
          },
        ];
        requestEditData?.accommodationServices?.map((item) => {
          let temp = bookingAcco[0]?.devoteeData;
          bookingAcco[0].devoteeData = [...temp, ...item.devoteeDetailsIndexes];
        });
        // requestEditData?.accommodationServices?.map((item) => {
        //   bookingAcco?.push({
        //     productData: [
        //       {
        //         productId: item?.productQuota?.id,
        //         name: item?.productQuota?.accBlockCategory?.accBlock?.name,
        //         price: item?.productQuota?.accBlockCategory?.price,
        //         deposit: item?.productQuota?.accBlockCategory?.depositAmount,
        //         gst: item?.productQuota?.accBlockCategory?.gstAmount,
        //         maintenance:
        //           item?.productQuota?.accBlockCategory?.maintenanceCharge,
        //         persons: item?.productQuota?.accBlockCategory?.noOfPersons,
        //       },
        //     ],
        //     devoteeData: item?.devoteeDetailsIndexes,
        //   });
        // });
        setBookingAccodata(bookingAcco);
        setRoomType(
          requestEditData?.accommodationServices[0]?.productQuota
            ?.accBlockCategory?.roomType
        );
        setBlockSelected([
          requestEditData?.accommodationServices[0]?.productQuota?.id,
        ]);
      }
    }
  }, []);

  const handleSubmit = async () => {
    setButtonLoading(true);

    const data = [];
    let tempAccoBookingData = [];

    if (bookingAccoData[0]?.productData?.length !== 0) {
      tempAccoBookingData = [...bookingAccoData];
    }

    if (name?.length < 3) {
      notification?.error({ message: "Please enter valid referer name" });
      setButtonLoading(false);
      return;
    }
    if (designation?.length < 1) {
      notification?.error({ message: "Please enter valid designation" });
      setButtonLoading(false);
      return;
    }
    if (fileList?.length === 0) {
      notification?.error({ message: "Please upload letter head" });
      setButtonLoading(false);
      return;
    }
    if (phone === "") {
      notification.error({ message: "Enter Phone No." });
      setButtonLoading(false);
      return;
    }
    if (phone.length !== 10) {
      notification.error({ message: "Phone number must be 10 digits" });
      setButtonLoading(false);
      // clearLoadings();
      return;
    }
    personStates.map((item) => {
      for (var key of Object.keys(item)) {
        if (item[key] === "") {
          if (key === "idType") {
            data.push({
              type: `${item["id"]}-${key}`,
              msg: `Select a id Proof`,
            });
          } else if (key === "idNum") {
            data.push({
              type: `${item["id"]}-${key}`,
              msg: `Invalid id number`,
            });
          } else if (key === "name") {
            data.push({
              type: `${item["id"]}-${key}`,
              msg: `Invalid Name`,
            });
          }
          //remove cmnt for uploading ID file
          // else if (key === "fileUrl") {
          //   data.push({ type: `${item["id"]}-${key}`, msg: `Upload a File` });
          // }
          // else if (key === "covid_file_url") {
          // else {
          //   data.push({
          //     type: `${item["id"]}-${key}`,
          //     msg: `Invalid ${key}`,
          //   });
          // }
        } else {
          if (key === "name") {
            if (item["name"].length < 3) {
              data.push({
                type: `${item["id"]}-name`,
                msg: "Minimun 3 characters",
              });
            }
          }

          if (key === "idType") {
            if (item["idNum"] !== "") {
              if (item["idType"] === "pan") {
                let txt = item["idNum"].toUpperCase();
                let regex =
                  /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
                let pan = {
                  C: "Company",
                  P: "Personal",
                  H: "Hindu Undivided Family (HUF)",
                  F: "Firm",
                  A: "Association of Persons (AOP)",
                  T: "AOP (Trust)",
                  B: "Body of Individuals (BOI)",
                  L: "Local Authority",
                  J: "Artificial Juridical Person",
                  G: "Govt",
                };
                pan = pan[txt[3]];
                if (regex.test(txt)) {
                  if (pan !== "Personal") {
                    data.push({
                      type: `${item["id"]}-idNum`,
                      msg: "Invalid Pan Number",
                    });
                  }
                } else
                  data.push({
                    type: `${item["id"]}-idNum`,
                    msg: "Invalid Pan Number",
                  });
              } else if (item["idType"] === "ration") {
                let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
                if (!regex.test(item["idNum"].toUpperCase())) {
                  data.push({
                    type: `${item["id"]}-idNum`,
                    msg: "Invalid ration number",
                  });
                }
              } else if (item["idType"] === "voter") {
                let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
                if (!regex.test(item["idNum"].toUpperCase())) {
                  data.push({
                    type: `${item["id"]}-idNum`,
                    msg: "Invalid voter id",
                  });
                }
              } else if (item["idType"] === "passport") {
                let regex = /^[A-Za-z0-9]{6,15}$/;
                if (!regex.test(item["idNum"].toUpperCase())) {
                  data.push({
                    type: `${item["id"]}-idNum`,
                    msg: "Invalid passport id",
                  });
                }
              } else if (item["idType"] === "aadhar") {
                let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

                if (!regex.test(item["idNum"])) {
                  data.push({
                    type: `${item["id"]}-idNum`,
                    msg: "Invalid AADHAAR number",
                  });
                }
              }
              // if (item["fileUrl"] === "") {
              //   data.push({
              //     type: `${item["id"]}-fileUrl`,
              //     msg: "Please attach a valid id proof",
              //   });
              // }
            }
          }
        }
      }
    });
    if (data.length > 0) {
      setErr(data);
      setButtonLoading(false);
      return;
    }
    if (
      bookingDarshanData?.length === 0 &&
      bookingSevaData?.length === 0 &&
      tempAccoBookingData?.length === 0
    ) {
      notification?.error({
        message: "Please select one of the darshanam, seva, accommodation",
      });
      setButtonLoading(false);
      return;
    }

    bookingDarshanData?.map((item) => {
      item?.devoteeData?.map((name) => {
        if (name === "") {
          notification?.error({
            message: "Please select valid name in darshanam",
          });
          setButtonLoading(false);
          return;
        }
      });
    });

    bookingSevaData?.map((item) => {
      item?.devoteeData?.map((name) => {
        if (name === "") {
          notification?.error({
            message: "Please select valid name in seva",
          });
          setButtonLoading(false);
          return;
        }
      });
    });

    tempAccoBookingData?.map((item) => {
      item?.devoteeData?.map((name) => {
        if (name === "") {
          notification?.error({
            message: "Please select valid name in Accommodation",
          });
          setButtonLoading(false);
          return;
        }
      });
    });

    let devoteeDetails = [];
    let darshanamServices = [];
    let sevaServices = [];
    let accommodationServices = [];

    personStates?.map((item) => {
      devoteeDetails?.push({
        personName: item?.name,
        documentType: item?.idType,
        documentId: item?.idNum,
        // documentFileURL: item?.fileUrl[0]?.url, //remove cmnt for uploading ID file
      });
    });

    bookingDarshanData?.map((item, i) => {
      darshanamServices?.push({
        quotaId: item?.productData[0]?.id,
        quantity: item?.devoteeData?.length,
        devoteeDetailsIndexes: [],
      });
      item?.devoteeData?.map((id) => {
        darshanamServices[i]?.devoteeDetailsIndexes?.push(id);
      });
    });

    // bookingSevaData?.map((item, i) => {
    //   sevaServices?.push({
    //     quotaId: item?.productData[0]?.id,
    //     quantity: item?.devoteeData?.length,
    //     devoteeDetailsIndexes: [],
    //   });
    //   item?.devoteeData?.map((id) => {
    //     sevaServices[i]?.devoteeDetailsIndexes?.push(id);
    //   });
    // });

    bookingSevaData?.map((item) => {
      item?.devoteeData?.map((id) =>
        sevaServices?.push({
          quotaId: item?.productData[0]?.id,
          quantity: 1,
          devoteeDetailsIndexes: [id],
        })
      );
    });

    let flag = Math?.ceil(
      Number(tempAccoBookingData[0]?.devoteeData?.length / blockPersons)
    );

    for (let a = 1; a <= flag; a++) {
      tempAccoBookingData?.map((item) => {
        let temp = item?.devoteeData;
        accommodationServices?.push({
          quotaId: item?.productData[0]?.productId,
          quantity: 1,
          devoteeDetailsIndexes: temp?.slice(
            (a - 1) * blockPersons,
            blockPersons * a
          ),
        });
        // item?.devoteeData?.map((id) => {
        //   accommodationServices[a - 1]?.devoteeDetailsIndexes?.push(id);
        // });
      });
    }

    let body = {
      type: protoType,
      name: name,
      cader: designation,
      refDocumentUrl: fileList[0]?.url,
      phoneNumber: `+91${phone}`,
      bookingDate: moment(performanceDate).format("YYYY-MM-DD"),
      noOfDevotees: persons,
      devoteeDetails: devoteeDetails,
      darshanamServices: darshanamServices,
      sevaServices: sevaServices,
      accommodationServices: accommodationServices,
    };

    try {
      if (requestId) {
        await TmsProtocolService.protocolRequestbyId(body, requestId);
        notification?.success({
          message: `Request edited`,
        });
        router.navigate(`${tmsRouteMap.pos_protocol_dashboard}`);
      } else {
        await TmsProtocolService.protocolRequestPost(body);
        notification?.success({
          message: `Request sent`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }

      setButtonLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setButtonLoading(false);
    }
  };

  return (
    <div className="w-full flex align-center col">
      <Row
        className="bg-white w-full text-left pxy maxw-1300 flex justify-between col"
        gutter={[20, 20]}
      >
        <Row className="w-full m-0" gutter={[20, 20]}>
          <TmsSelect
            classname="w-4"
            label="Protocol Type"
            type="col"
            data={[
              { name: "Self", id: "self" },
              { name: "Referal", id: "reference" },
            ]}
            optional={true}
            mandatory={true}
            value={protoType}
            setValue={setProtoType}
          />
          <Col className="w-4">
            <Row className="mb-5">
              <label>
                Referer Name
                <span className="mandatory">*</span>
              </label>
            </Row>
            <Input
              maxLength={220}
              type="text"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => {
                const input = e.target.value;
                const pattern = /^[A-Za-z\s]+$/;

                if (pattern.test(input) || input === "") {
                  setName(input);
                }
              }}
            />
          </Col>
          <Col className="w-4">
            <Row className="mb-5">
              <label>
                Designation<span className="mandatory">*</span>
              </label>
            </Row>
            <Input
              maxLength={220}
              type="text"
              placeholder="Enter Designation"
              value={designation}
              onChange={(e) => {
                setDesignation(e.target.value);
              }}
            />
          </Col>
          <Col className="w-4">
            <Row className="mb-5">
              <label>
                Performance Date
                <span className="mandatory">*</span>
              </label>
            </Row>
            <DatePicker
              format={"DD-MM-YYYY"}
              className="w-full"
              picker={"date"}
              defaultValue={performanceDate}
              value={performanceDate}
              onChange={(e) => {
                setPerformanceDate(e);
              }}
              allowClear={false}
              disabledDate={(d) =>
                !d || d.isBefore(TmsStringUtils.addDays(new Date(), 0))
              }
            />
          </Col>
        </Row>
        <Row className="w-full m-0" gutter={[20, 20]}>
          <TmsSelect
            classname="w-4"
            label="No of People"
            type="col"
            data={[
              { name: 1, id: 1 },
              { name: 2, id: 2 },
              { name: 3, id: 3 },
              { name: 4, id: 4 },
              { name: 5, id: 5 },
              { name: 6, id: 6 },
            ]}
            optional={true}
            mandatory={true}
            value={persons}
            setValue={setPersons}
          />
          <Col className="w-4">
            <Row className="mb-5">
              <label>
                Upload Letter Head<span className="mandatory">*</span>
              </label>{" "}
            </Row>
            <TmsImageUpload
              flag="private"
              className="w-100"
              listType="text"
              accept={true}
              fileList={fileList}
              setFileList={setFileList}
            />
          </Col>
          {/* <Col className="w-4">
            <Row className="mb-5">
              <label>
                Phone Number<span className="mandatory">*</span>
              </label>
            </Row>
            <Input
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  return;
                }
                setPhone(e.target.value);
              }}
              type="number"
              min="0"
              prefix="+91"
              onWheel={(event) => event.currentTarget.blur()}
              maxLength={10}
              value={phone}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
            />
          </Col> */}
        </Row>
        <Row className="w-full m-0">
          <Row className="align-baseline w-full">
            <h1 className="text-lg px-10">Devotee Details</h1>
            <span className="p-0 text-desc text-med">
              (This Fields are Based on no of Tickets Selected)
            </span>
          </Row>
          <Row className="w-full m-0" gutter={[20, 20]}>
            {personStates?.map((item, i) => {
              return (
                <Row className="w-full m-0" key={i}>
                  <h1 className="px-10">Devotee-{i + 1}</h1>
                  <Row className="w-full m-0" gutter={[20, 20]}>
                    <Row className="w-full m-0" gutter={[20, 20]}>
                      <Col className="w-4">
                        <Row className="mb-5">
                          <label>
                            Name
                            <span className="mandatory">*</span>
                          </label>
                        </Row>
                        <Input
                          maxLength={220}
                          type="text"
                          placeholder="Enter Name"
                          value={item?.name}
                          onChange={(e) => {
                            if (
                              /^[a-zA-Z ]*$/.test(
                                String(e.target.value) || e.target.value === ""
                              )
                            ) {
                              if (err.find((e) => e.type === `${i}-name`)) {
                                let data = err.filter(
                                  (e) => e.type !== `${i}-name`
                                );
                                setErr(data);
                              }
                              handleChange(i, e, "name");
                            }
                          }}
                        />
                        <p className="sd-EM mr-15">
                          {err.find((e) => e.type === `${i}-name`)?.msg}
                        </p>
                      </Col>
                      <TmsSelect
                        classname="w-4"
                        label="ID Proof Type"
                        type="col"
                        data={[
                          { name: "Aadhaar", id: "aadhar" },
                          { name: "Pan", id: "pan" },
                        ]}
                        value={item?.idType}
                        onChange={(e) => {
                          if (err.find((e) => e.type === `${i}-idType`)) {
                            let data = err.filter(
                              (e) => e.type !== `${i + 1}-idType`
                            );
                            setErr(data);
                          }
                          handleChange(i, e, "idType");
                        }}
                        optional={true}
                        mandatory={true}
                        error={[{ err: err, i: i }]}
                      />
                      <Col className="w-4">
                        <Row className="mb-5">
                          <label>
                            ID Number
                            <span className="mandatory">*</span>
                          </label>
                        </Row>
                        <Input
                          maxLength={220}
                          type={item?.idType === "aadhar" ? "number" : "text"}
                          placeholder="Enter ID Number"
                          value={item?.idNum}
                          onChange={(e) => {
                            if (item?.idType === "aadhar") {
                              if (e.target.value.length > 12) {
                                return;
                              }
                            }

                            if (
                              /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              if (
                                err.find(
                                  (e) =>
                                    e.type === `${i}-idNum` ||
                                    e.type === "idcheck"
                                )
                              ) {
                                let data = err.filter(
                                  (e) =>
                                    e.type !== `${i}-idNum` ||
                                    e.type === "idcheck"
                                );
                                setErr(data);
                              }
                              handleChange(i, e, "idNum");
                            }
                          }}
                        />
                        <p className="sd-EM mr-15">
                          {err.find((e) => e.type === `${i}-idNum`)?.msg}
                        </p>
                      </Col>
                      {/* <Col className="w-4">
                        <Row className="mb-5">
                          <label>
                            Upload ID<span className="mandatory">*</span>
                          </label>{" "}
                        </Row>
                        <TmsImageUpload
                          flag="private"
                          className="w-100"
                          listType="text"
                          accept={true}
                          fileList={item?.fileUrl ? item?.fileUrl : []}
                          setFileList={(e) => {
                            if (err.find((e) => e.type === `${i}-fileUrl`)) {
                              let data = err.filter(
                                (e) => e.type !== `${i + 1}-fileUrl`
                              );
                              setErr(data);
                            }
                            handleChange(i, e, "fileUrl");
                          }}
                        />
                        <p className="sd-EM mr-15">
                          {err.find((e) => e.type === `${i}-fileUrl`)?.msg}
                        </p>
                      </Col> */}
                      {i === 0 && (
                        <Col className="w-4">
                          <Row className="mb-5">
                            <label>
                              Phone Number<span className="mandatory">*</span>
                            </label>
                          </Row>
                          <Input
                            onChange={(e) => {
                              if (e.target.value.length > 10) {
                                return;
                              }
                              setPhone(e.target.value);
                            }}
                            type="number"
                            min="0"
                            prefix="+91"
                            onWheel={(event) => event.currentTarget.blur()}
                            maxLength={10}
                            value={phone}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-", "."].includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                        </Col>
                      )}
                    </Row>
                  </Row>
                </Row>
              );
            })}
          </Row>
        </Row>
        <Row className="w-full m-0" gutter={[20, 20]}>
          <TmsPosProtocolDevoteeList
            type="Darshanam"
            commonLogicList={darshanamList}
            commonSelectedArray={selectedDarshanArray}
            setCommonSelectedArray={setSelectedDarshanArray}
            commonSlotsArray={slotsDarshanArray}
            bookingData={bookingDarshanData}
            setBookingData={setBookingDarshandata}
            personStates={personStates}
            commonSlotQuota={commonSlotQuota}
          />
          <TmsPosProtocolDevoteeList
            type="Seva"
            commonLogicList={sevaList}
            commonSelectedArray={selectedSevaArray}
            setCommonSelectedArray={setSelectedSevaArray}
            commonSlotsArray={slotsSevaArray}
            bookingData={bookingSevaData}
            setBookingData={setBookingSevadata}
            personStates={personStates}
            commonSlotQuota={commonSlotQuota}
          />
          {currentDate !== moment(performanceDate)?.format("YYYY-MM-DD") && (
            <TmsPosProtocolDevoteeList
              type="Accommodation"
              commonLogicList={blockList}
              commonSelectedArray={blockSelected}
              setCommonSelectedArray={setBlockSelected}
              bookingData={bookingAccoData}
              setBookingData={setBookingAccodata}
              personStates={personStates}
              roomtypesArray={roomtypesArray}
              roomType={roomType}
              setRoomType={setRoomType}
              setBlockPersons={setBlockPersons}
            />
          )}
        </Row>
      </Row>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                buttonLoading === false && handleSubmit();
              }}
              loading={buttonLoading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(TmsPosProtocol);
