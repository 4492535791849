import { Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  breakupSurveyNoStates,
  createShopStates,
  ShopStatusData,
} from "../../../../../../constants/states-data.data";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import {
  BuildingBlockNoSelect,
  BuildingInstitutionSelect,
  BuildingSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";
import useBuildingsStore from "../../../../../../zustand/buildings-and-shops.store";
const TmsShopsCreation = ({ router, setVisible }) => {
  const {
    shopNumber,
    setShopNumber,
    shopStatus,
    setShopStatus,
    submitLoading,
    fetchInstitutionList,
    selectedInstitution,
    handleShopCreation,
    clearStates,
    setSubmitLoading,
    status,
    setStatus,
    selectedBuilding,
    fetchCreatedBuildingsList,
    fetchBlocks,
    setExtentLength,
    extentLength,
    extentBreadth,
    setExtentBreadth,
  } = useBuildingsStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createShopStates, [
        "institutionData",
        "createdBuildingsData",
        "selectedBuilding",
        "createdBlocksData",
        "selectedBlock",
      ]);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedBuildingsList(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  useEffect(() => {
    if (
      selectedInstitution !== "" &&
      selectedInstitution &&
      selectedBuilding?.id
    ) {
      fetchBlocks(1, 200, "ASC", "createdAt", router);
    }
  }, [selectedInstitution, selectedBuilding]);

  return (
    <div>
      {" "}
      <Row gutter={[20, 20]} style={{ marginBottom: "15px" }}>
        <BuildingInstitutionSelect span={12} />
        <BuildingSelect span={12} from="shops" />
        <Col span={12}>
          <Row className={"t-left"}>Property Type</Row>
          <Input
            value={selectedBuilding?.propertyType}
            disabled={true}
            maxLength={220}
          />
        </Col>
        <BuildingBlockNoSelect span={12} />
        <Col span={12}>
          <Row className={"t-left"}>Shop Number</Row>
          <Input
            maxLength={220}
            type="text"
            onKeyDown={(e) => ["+", "."].includes(e.key) && e.preventDefault()}
            placeholder="Enter Shop Number"
            value={shopNumber}
            onChange={(e) => {
              setShopNumber(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Extent Area</Row>
          <Row>
            <Col span={12}>
              <Input
                maxLength={220}
                value={extentLength ?? 0}
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                min="0"
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  setExtentLength(Number(e.target.value));
                }}
                suffix={<span className="suffix-border">Length</span>}
              />
            </Col>
            <Col span={12}>
              <Input
                maxLength={220}
                value={extentBreadth ?? 0}
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                min="0"
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  setExtentBreadth(Number(e.target.value));
                }}
                suffix={<span className="suffix-border">Breadth</span>}
              />
            </Col>
          </Row>
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={shopStatus}
          setValue={setShopStatus}
          label="Shop Status"
          data={ShopStatusData.map((item) => {
            return { name: item.name, id: item.id };
          })}
          span={12}
          optional={true}
        />
      </Row>
      <br />
      <TmsCheckbox label={"Status"} value={status} setValue={setStatus} />
      <TmsCancelSubmitButtons
        icon={arrowRight}
        loading={submitLoading}
        cancelOnlick={() => {
          clearStates(breakupSurveyNoStates, [
            "institutionData",
            "createdBuildingsData",
            "selectedBuilding",
            "createdBlocksData",
            "selectedBlock",
          ]);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleShopCreation();
        }}
      />
    </div>
  );
};

export default withRouter(TmsShopsCreation);
