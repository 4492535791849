import { Col, Row, Input, DatePicker, Radio } from "antd";
import React, { useEffect } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  IdProofData,
  LeeseType,
  paymentTerms,
  paymentTypes,
  shopleaseAddtionalStates,
  shopLeaseCreationStates,
  yesOrNotData,
} from "../../../../../../constants/states-data.data";
import TmsDatePickerRange from "../../../../../../components/tms-customs/tms-datepicker-range";
import TmsAddress from "../../../../../../components/tms-customs/tms-address";
import TmsImageUpload from "../../../../../../components/tms-customs/tms-image-upload";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsPhoneNumberBasic from "../../../../../../components/tms-customs/tms-phone-number-basic";
import {
  BuildingBlockNoSelect,
  BuildingInstitutionSelect,
  BuildingSelect,
  ShopExtentInput,
  ShopNoSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";
import useBuildingsStore from "../../../../../../zustand/buildings-and-shops.store";
const TmsBasLeaseCreation = ({ router, setVisible }) => {
  const {
    submitLoading,
    selectedInstitution,
    setStatus,
    status,
    address,
    setAddress,
    fetchInstitutionList,
    lesseType,
    setLesseType,
    clearStates,
    setIdProofType,
    idProofType,
    setUploadIdProof,
    uploadIdProof,
    setLeasePeriodEndDate,
    leasePeriodEndDate,
    leasePeriodStartDate,
    setLeasePeriodStartDate,
    handleLeaseCreation,
    paymentTerm,
    idProofNumber,
    setIdProofNumber,
    setPaymentTerm,
    paymentType,
    setPaymentType,
    leaseRate,
    setLeaseRate,
    depositAmount,
    setDepositAmount,
    gender,
    setGender,
    mrNo,
    setMrNo,
    gstAmount,
    setGstAmount,
    mrDate,
    setMrDate,
    setLesseName,
    lesseName,
    setMobileNumber,
    gstNo,
    setGstNo,
    mobileNumber,
    setIncorporationOrPanNo,
    incorporationOrPanNo,
    contactPersonName,
    setContactPersonName,
    zip,
    setZip,
    setSubmitLoading,
    state,
    setState,
    country,
    setCountry,
    district,
    setDistrict,
    village,
    setVillage,
    mandal,
    setMandal,
    reason,
    setReason,
    setLeaseApprovedByCompetentAuthority,
    leaseApprovedByCompetentAuthority,
    setUploadContemptoryCopy,
    uploadContemptoryCopy,
    uploadApprovedDocumentOrderNo,
    setUploadApprovedDocumentOrderNo,
    uploadApprovedDocumentOrderDate,
    setUploadApprovedDocumentOrderDate,
    eoProceedingUploadDocument,
    setEoProceedingUploadDocument,
    dueDate,
    setDueDate,
    fetchCreatedBuildingsList,
    selectedBuilding,
    fetchBlocks,
    fetchShopsByBlock,
    selectedBlock,
    setNatureOfBusiness,
    natureOfBusiness,
    goodWillAmount,
    setGoodWillAmount,
  } = useBuildingsStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(shopLeaseCreationStates, [
        "institutionData",
        "createdBuildingsData",
        "selectedBuilding",
        "createdBlocksData",
        "selectedBlock",
      ]);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedBuildingsList(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  useEffect(() => {
    if (
      selectedInstitution !== "" &&
      selectedInstitution &&
      selectedBuilding?.id
    ) {
      fetchBlocks(1, 200, "ASC", "createdAt", router);
    }
  }, [selectedInstitution, selectedBuilding]);

  useEffect(() => {
    if (
      selectedInstitution !== "" &&
      selectedInstitution &&
      selectedBuilding?.id &&
      selectedBlock?.id
    ) {
      fetchShopsByBlock(1, 200, "ASC", "createdAt", router);
    }
  }, [selectedInstitution, selectedBuilding, selectedBlock]);

  return (
    <div>
      <Row gutter={[20, 20]} style={{ marginBottom: "15px" }}>
        <BuildingInstitutionSelect span={6} />
        <BuildingSelect span={6} />
        <BuildingBlockNoSelect span={6} />
        <ShopNoSelect span={6} />
        <Row
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333333",
            padding: "0px 20px 0px 10px",
            width: "100%",
          }}
        >
          Shop Details
        </Row>
        <ShopExtentInput disabled={true} />
        <Col span={12}>
          <Row className={"t-left"}>Nature of Business</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Nature of Business Kind item"
            value={natureOfBusiness}
            onChange={(e) => {
              setNatureOfBusiness(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <TmsDatePickerRange
            label="Select Lease Period"
            to={leasePeriodEndDate}
            from={leasePeriodStartDate}
            setFrom={setLeasePeriodStartDate}
            setTo={setLeasePeriodEndDate}
            // disabledDate={false}
          />{" "}
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Due Date (Ex. 15)</Row>
          <Input
            maxLength={220}
            type="number"
            min="1"
            max={31}
            placeholder="Due Date"
            value={dueDate}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onChange={(e) => {
              if (e.target.value > 31) {
                return;
              }
              setDueDate(e.target.value);
            }}
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={paymentTerm}
          setValue={setPaymentTerm}
          label="Payment Term"
          span={12}
          optional={true}
          data={paymentTerms}
        />
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={paymentType}
          setValue={setPaymentType}
          label="Payment Type"
          span={12}
          optional={true}
          data={paymentTypes}
        />
        <Col span={12}>
          <Row className={"t-left"}>Lease Rate/Rent</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Lease rate"
            value={leaseRate}
            onChange={(e) => {
              setLeaseRate(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Deposit Amount</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder=""
            value={depositAmount}
            onChange={(e) => {
              setDepositAmount(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>GoodWill Amount</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder=""
            value={goodWillAmount}
            onChange={(e) => {
              setGoodWillAmount(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>GST Amount(%)</Row>

          <Input
            maxLength={220}
            type="number"
            value={gstAmount}
            min={0}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            max={101}
            style={{ width: "100%" }}
            placeholder="Enter Gst Percentage"
            onChange={(e) => {
              if (Number(e.target.value) < 101) {
                setGstAmount(Number(e.target.value));
              }
            }}
            suffix={`Rs.${gstAmount ? (leaseRate / 100) * gstAmount : 0}`}
          ></Input>
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>MR No</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder=""
            value={mrNo}
            onChange={(e) => {
              setMrNo(e.target.value);
            }}
          />
        </Col>{" "}
        <Col span={12}>
          <Row className={"t-left"}>MR Date</Row>
          <DatePicker
            disabled={false}
            value={mrDate}
            style={{ width: "100%" }}
            format="DD-MM-YYYY"
            onChange={(date) => {
              setMrDate(date);
            }}
            size="medium"
          />{" "}
        </Col>
        <Row
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333333",
            padding: "0px 20px 0px 10px",
            width: "100%",
          }}
        >
          Lessee Details
        </Row>
        <Row style={{ width: "100%", padding: "0px 20px 0px 10px" }}>
          <Radio.Group
            options={LeeseType}
            value={lesseType}
            checked={lesseType}
            id={lesseType}
            onChange={(e) => {
              setLesseType(e.target.value);
            }}
          />
        </Row>
        <Col span={12}>
          <Row className={"t-left"}>Lessee Name</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Lessee Name"
            value={lesseName}
            onChange={(e) => {
              setLesseName(e.target.value);
            }}
          />
        </Col>
        {lesseType === "individual" ? (
          <TmsSelect
            selectClass="w-100 "
            classname="ml-0 "
            type="col"
            value={gender}
            setValue={setGender}
            label="Gender"
            span={12}
            optional={true}
            data={[
              { name: "male", id: "male" },
              { name: "female", id: "female" },
            ]}
          />
        ) : (
          <>
            <Col span={12}>
              <Row className={"t-left"}>Incorporation Number/PAN No</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Incorporation/PAN No"
                value={incorporationOrPanNo}
                onChange={(e) => {
                  setIncorporationOrPanNo(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>GST NO</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter GST No"
                value={gstNo}
                onChange={(e) => {
                  setGstNo(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Contact Person Name</Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Person Name"
                value={contactPersonName}
                onChange={(e) => {
                  setContactPersonName(e.target.value);
                }}
              />
            </Col>
          </>
        )}
        <Col span={12}>
          <Row className={"t-left"}>Mobile Number</Row>
          <TmsPhoneNumberBasic
            value={mobileNumber}
            setValue={setMobileNumber}
            placeHolder={"Enter mobile number"}
            disable={false}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"}>Address</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Col>
        <TmsAddress
          span={12}
          village={village}
          setVillage={setVillage}
          mandal={mandal}
          setMandal={setMandal}
          district={district}
          setDistrict={setDistrict}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          type="general"
        />
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={idProofType}
          setValue={setIdProofType}
          label="Select Id Proof"
          data={IdProofData}
          span={12}
          optional={true}
        />
        <Col span={12}>
          <Row className={"t-left"} style={{ marginBottom: "5px" }}>
            ID Proof Number
          </Row>
          <Input
            maxLength={220}
            size="medium"
            type="text"
            placeholder="Enter ID Proof No"
            style={{ width: "100%" }}
            value={idProofNumber}
            onChange={(e) => {
              if (e.target.value?.length > 12) {
                return;
              }
              setIdProofNumber(`${e.target.value?.toUpperCase()}`);
            }}
          />
        </Col>
        <Col span={12}>
          <Row className={"t-left"} style={{ marginBottom: "5px" }}>
            Upload ID Proof
          </Row>
          <TmsImageUpload
            fileList={uploadIdProof}
            setFileList={setUploadIdProof}
            router={router}
            type="private"
            accept={true}
            className="w-100"
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={leaseApprovedByCompetentAuthority}
          setValue={setLeaseApprovedByCompetentAuthority}
          label="Lease Approved by Contemptory Orders"
          data={yesOrNotData}
          span={12}
          optional={true}
        />
        {leaseApprovedByCompetentAuthority ? (
          <>
            {" "}
            <Col span={12}>
              <Row className={"t-left"} style={{ marginBottom: "5px" }}>
                Upload Approved Documents
              </Row>
              <TmsImageUpload
                fileList={uploadContemptoryCopy}
                setFileList={setUploadContemptoryCopy}
                router={router}
                type="private"
                accept={true}
                className="w-100"
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>If Yes, Enter Orders No </Row>
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter Order Number"
                value={uploadApprovedDocumentOrderNo}
                onChange={(e) => {
                  setUploadApprovedDocumentOrderNo(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <Row className={"t-left"}>Approved Document Order Date</Row>
              <DatePicker
                disabled={false}
                value={uploadApprovedDocumentOrderDate}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                onChange={(date) => {
                  setUploadApprovedDocumentOrderDate(date);
                }}
                size="medium"
              />{" "}
            </Col>
          </>
        ) : (
          <Col span={12}>
            <Row className={"t-left"}>If No, Enter Reason</Row>
            <Input
              maxLength={220}
              type="text"
              placeholder="Enter Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Col>
        )}
        <Col span={12}>
          <Row className={"t-left"} style={{ marginBottom: "5px" }}>
            Eo proceedings Upload Documents
          </Row>
          <TmsImageUpload
            fileList={eoProceedingUploadDocument}
            setFileList={setEoProceedingUploadDocument}
            router={router}
            type="private"
            accept={true}
            className="w-100"
          />
        </Col>
        {/* <Col span={12}>
          {" "}
          <Row className={"t-left"}>Reason</Row>
          <Input
            type="text"
            placeholder="Enter Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Col> */}
      </Row>
      <TmsCheckbox label={"Lease Status"} value={status} setValue={setStatus} />
      <TmsCancelSubmitButtons
        loading={submitLoading}
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(shopleaseAddtionalStates, [
            ...shopleaseAddtionalStates,
            "institutionData",
            "createdShopData",
            "createdBuildingsData",
            "createdBlocksData",
          ]);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleLeaseCreation(router);
        }}
      />
    </div>
  );
};

export default withRouter(TmsBasLeaseCreation);
