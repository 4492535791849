import { Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  additionalBreakup,
  breakupSurveyNoStates,
  extentTypes,
  holdingStatusData,
} from "../../../../../../constants/states-data.data";
import TmsLandAcreCentsInput from "../../../../../../components/tms-customs/tms-land-acre-cents-input";
import TmsBoundayElement from "../../../../../../components/tms-customs/tms-bounday-element";
import TmsCheckbox from "../../../../../../components/tms-customs/tms-checkbox";
import {
  InstitutionSelect,
  LandCreationSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";
const TmsSurveyNoBreakup = ({ router, setVisible }) => {
  const {
    selectedLand,
    selectedSurveyNumber,
    setSelectedSurveyNumber,
    blockNumber,
    setBlockNumber,
    extent,
    setExtent,
    holdingStatus,
    nonAgriculturalAcres,
    setNonAgriculturalAcres,
    agriculturalAcres,
    setAgriculturalAcres,
    agriculturalCents,
    setAgriculturalCents,
    nonAgriculturalCents,
    setNonAgriculturalCents,
    setHoldingStatus,
    setGeoTagging,
    geoTagging,
    submitLoading,
    fetchInstitutionList,
    selectedInstitution,

    remarks,
    setRemarks,
    fetchCreatedLandList,
    handleBreakupSurveyNumber,
    clearStates,
    setSubmitLoading,
    surveyNoDataByLandId,
    status,
    setStatus,
    fetchSurveyNumbersByLandID,
  } = useRentsAndLandStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(breakupSurveyNoStates, additionalBreakup);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedLandList(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  useEffect(() => {
    if (
      selectedLand !== "" &&
      selectedLand &&
      selectedInstitution !== "" &&
      selectedInstitution
    )
      fetchSurveyNumbersByLandID(1, 200, "ASC", "createdAt", router);
  }, [selectedLand, selectedInstitution]);
  return (
    <div>
      {" "}
      <Row gutter={[20, 20]} style={{ marginBottom: "15px" }}>
        {/* <Col span={12}> */}
        <InstitutionSelect span={12} />
        <LandCreationSelect span={12} />
        <TmsSelect
          type="col"
          span={12}
          label="Select Survey No"
          setValue={(e) => {
            setSelectedSurveyNumber(
              surveyNoDataByLandId?.items?.find((item) => item.id === e)
            );
          }}
          selectClass="w-100"
          value={selectedSurveyNumber?.id}
          data={surveyNoDataByLandId?.items?.map((item) => {
            return { name: `Survey.no: ${item.surveyNo}`, id: item.id };
          })}
          optional={true}
        />
        <Col span={12}>
          <Row className={"t-left"}>Block Numbers</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Block Number"
            value={blockNumber}
            onChange={(e) => {
              setBlockNumber(e.target.value);
            }}
          />
        </Col>
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={extent}
          setValue={setExtent}
          label="Enter Extent"
          data={extentTypes}
          span={12}
          optional={true}
        />
        <TmsLandAcreCentsInput
          valueOne={
            extent === "AGRICULTURE"
              ? selectedSurveyNumber?.agricultureLandAcres
              : selectedSurveyNumber?.nonAgricultureLandAcres
          }
          setValueOne={() => {}}
          valueTwo={
            extent === "AGRICULTURE"
              ? selectedSurveyNumber?.agricultureLandCents
              : selectedSurveyNumber?.nonAgricultureLandCents
          }
          setValueTwo={() => {}}
          colspace={12}
          label={`Available ${extent} Land`}
          disabled={true}
        />{" "}
        <TmsLandAcreCentsInput
          valueOne={
            extent === "AGRICULTURE" ? agriculturalAcres : nonAgriculturalAcres
          }
          setValueOne={
            extent === "AGRICULTURE"
              ? setAgriculturalAcres
              : setNonAgriculturalAcres
          }
          valueTwo={
            extent === "AGRICULTURE" ? agriculturalCents : nonAgriculturalCents
          }
          setValueTwo={
            extent === "AGRICULTURE"
              ? setAgriculturalCents
              : setNonAgriculturalCents
          }
          colspace={12}
          label="Lease Extent"
          maxValueOne={
            extent === "AGRICULTURE"
              ? selectedSurveyNumber?.balanceAgriLandAcres
              : selectedSurveyNumber?.balanceNonAgriLandAcres
          }
          // maxValueTwo={
          //   extent === "AGRICULTURE"
          //     ? selectedSurveyNumber?.agricultureLandCents
          //     : selectedSurveyNumber?.nonAgricultureLandCents
          // }
        />
        <TmsLandAcreCentsInput
          valueOne={
            extent === "AGRICULTURE"
              ? selectedSurveyNumber?.balanceAgriLandAcres
              : selectedSurveyNumber?.balanceNonAgriLandAcres
          }
          setValueOne={() => {}}
          valueTwo={
            extent === "AGRICULTURE"
              ? selectedSurveyNumber?.balanceAgriLandCents
              : selectedSurveyNumber?.balanceNonAgriLandCents
          }
          colspace={12}
          label="Balance Land"
          disabled={true}
        />{" "}
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={selectedSurveyNumber?.natureOfLand}
          setValue={() => {}}
          label="Nature of Land"
          span={12}
          optional={true}
          disabled={true}
        />
        <TmsSelect
          selectClass="w-100 "
          classname="ml-0 "
          type="col"
          value={holdingStatus}
          setValue={setHoldingStatus}
          label="Holding Status"
          data={holdingStatusData.map((item) => {
            return { name: item, id: item };
          })}
          span={12}
          optional={true}
        />
        <TmsBoundayElement />
        <Col span={8}>
          <Row className={"t-left"}>Geo tagging </Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter GeoTag"
            value={geoTagging}
            onChange={(e) => setGeoTagging(e.target.value)}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Remarks</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <TmsCheckbox
        label={"Survey Breakup Status"}
        value={status}
        setValue={setStatus}
      />
      <TmsCancelSubmitButtons
        icon={arrowRight}
        loading={submitLoading}
        cancelOnlick={() => {
          clearStates(breakupSurveyNoStates, additionalBreakup);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleBreakupSurveyNumber();
        }}
      />
    </div>
  );
};

export default withRouter(TmsSurveyNoBreakup);
