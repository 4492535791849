import React, { memo } from "react";
import useFdrStore from "../../../../zustand/fdr.store";
import TmsSelect from "../../tms-select";

const AccountCategorySelect = ({ span }) => {
  const {
    accountCategoryData,
    clearStates,
    setSelectedAccountCategory,
    selectedAccountCategory,
  } = useFdrStore((state) => {
    return {
      accountCategoryData: state.accountCategoryData,
      clearStates: state.clearStates,
      setSelectedAccountCategory: state.setSelectedAccountCategory,
      selectedAccountCategory: state.selectedAccountCategory,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      setValue={(e) => {
        setSelectedAccountCategory(
          accountCategoryData?.items?.find((item) => item.id === e)
        );
        clearStates([""], []);
      }}
      label="Select Account Category"
      data={
        accountCategoryData?.items?.map((item) => {
          return {
            name: `ID: ${item?.accountCategoryName}  `,
            id: item?.id,
          };
        }) ?? []
      }
      value={selectedAccountCategory?.id}
      optional={true}
    />
  );
};

export default memo(AccountCategorySelect);
