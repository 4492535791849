import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsPrintService = {
  printLogs: async (page, limit, fromDate, toDate) => {
    let query = `v1/ticket-reports/ticket-print-log?page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}`;

    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  reprintLogs: async (page, limit, fromDate, toDate) => {
    let query = `v1/ticket-reports/ticket-reprint-log?page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}`;

    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};

export default TmsPrintService;
