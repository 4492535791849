import React, { useContext, useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { tmsLogo } from "../../../assets/images/tms-image-list";
import { BaseContext } from "../../../contexts/BaseContext";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import { tmsSiderMap } from "../../../constants/tms-sider.map";
import "../../../assets/css/tms-layout/tms-layout.scss";
import checkPermissions from "../../../utils/tms-permissions/tms-permissions";

const TmsHomeSider = ({
  siderCollapsed,
  router,
  toggleSider,
  templeDetails,
}) => {
  const [selectedNavItem, setSelectedNavItem] = useState(["/home"]);
  const [routeMapState, setRouteMapState] = useState();
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const { userDetails } = useContext(BaseContext);

  const { Sider } = Layout;
  const screens = useBreakpoint();

  useEffect(() => {
    // console.log(userDetails);
    if (screens?.xl || screens?.xxl || screens?.lg) {
      setCollapsedWidth(80);
    } else {
      setCollapsedWidth(0);
    }
  }, [screens]);

  const onMenuClick = (event) => {
    const { key } = event;
    if (collapsedWidth === 0) {
      toggleSider();
    }
    router?.navigate(key);
  };

  useEffect(() => {
    setRouteMapState(
      tmsSiderMap.map((item) => {
        return item.inLayout && checkPermissions(userDetails, item)
          ? {
              label: item.name,
              key: item.route,
              icon: <item.icon />,
            }
          : null;
      })
    );

    setSelectedNavItem(
      tmsSiderMap.find((_item) =>
        router?.location?.pathname?.startsWith(_item.route)
      )?.route
    );
  }, [router?.location?.pathname, userDetails]);

  return (
    <Sider
      className="tms-menu_sider"
      collapsible
      onCollapse={toggleSider}
      collapsed={siderCollapsed}
      collapsedWidth={collapsedWidth}
      defaultCollapsed={false}
    >
      <div
        className="tms-sider-logo"
        style={{
          backgroundImage: templeDetails?.logo ? templeDetails?.logo : tmsLogo,
        }}
      >
        <img
          className="tms-sider-inner-logo"
          src={templeDetails?.logo ? templeDetails?.logo : tmsLogo}
          alt="tms_logo"
        />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={selectedNavItem}
        onClick={onMenuClick}
        items={routeMapState}
      ></Menu>
    </Sider>
  );
};

export default withRouter(TmsHomeSider);
