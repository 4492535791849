import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsProductsService from "../../../../../services/tms-products.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsImageUpload from "../../../../../components/tms-customs/tms-image-upload";

const TmsProductsInnerPagesANP = ({
  viewProductData,
  editFlag,
  productId,
  setEditFlag,
  settmsModalVisible,
  width,
  router,
}) => {
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [description, setdescription] = useState("");
  const [counterEnabled, setcounterEnabled] = useState(false);
  const [onlineEnabled, setonlineEnabled] = useState(false);
  const [price, setprice] = useState();
  const [fileList, setFileList] = useState([]);
  const [type, setType] = useState("GENERAL");
  const [gst, setGst] = useState(0);
  const [sku, setSku] = useState("");
  const [changeRequestFile, setChangeRequestFile] = useState([]);
  const [changeRequestDesc, setChangeRequestDesc] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    if (
      productName === "" ||
      gst === "" ||
      fileList?.length === 0 ||
      type === "" ||
      price === "" ||
      sku === "" ||
      description === ""
    ) {
      notification.error({
        message: "Please make sure you fill all the fields.",
      });
      setLoading(false);
      return;
    }
    if (editFlag) {
      if (changeRequestFile?.length === 0 || changeRequestDesc === "") {
        notification.error({
          message: "Please make sure you fill all the fields.",
        });
        setLoading(false);

        return;
      }
    }
    let body = {
      name: productName,
      description: description,
      counterEnabled: counterEnabled,
      onlineEnabled: onlineEnabled,
      price: price,
      gst: gst,
      imageUrl: fileList[0]?.url,
      type: type,
      sku: sku,
    };
    if (editFlag) {
      body.changeRequestPdfUrl = changeRequestFile[0].url;
      body.changeDescription = changeRequestDesc;
    }
    try {
      if (editFlag) {
        await TmsProductsService.editProduct(productId, body);
        // setDarshanamData(darData);
        notification.success({ message: "Product Edited Successful" });
        setEditFlag(false);
        setLoading(false);
      } else {
        await TmsProductsService.createProduct(body);
        // setDarshanamData(data?.items);
        notification.success({ message: "Product Created Successful" });
        setLoading(false);
        handleFuction();
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    setGst(0);
    setProductName("");
    setdescription("");
    setcounterEnabled(false);
    setonlineEnabled(false);
    setprice("");
    setSku("");
    setFileList([]);
    setType("");
    setChangeRequestFile([]);
    setChangeRequestDesc("");
  };

  useEffect(() => {
    if (editFlag) {
      setType(viewProductData?.type);
      setProductName(viewProductData?.name);
      setdescription(viewProductData?.description);
      setcounterEnabled(viewProductData?.counterEnabled);
      setonlineEnabled(viewProductData?.onlineEnabled);
      setprice(viewProductData?.price);
      setFileList([{ url: viewProductData?.imageUrl, status: "done" }]);
      setGst(viewProductData?.gst);
      setSku(viewProductData?.sku);
    }
  }, [viewProductData]);

  return (
    <Row className="formRow" style={{ width: "100%", minWidth: "500px" }}>
      <Card style={{ width: width ? "100%" : "60%", minWidth: "500px" }}>
        <Row className="formRow">
          <label className="formLabel">Product Name</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <label className="formLabel">Product Type</label>

          <Select
            className="formInput"
            onChange={(e) => {
              setType(e);
            }}
            value={type}
            defaultValue={type}
          >
            <Select.Option value="GENERAL">General</Select.Option>
            <Select.Option value="GENERAL_WITH_QUOTA">
              General with Quota
            </Select.Option>
            <Select.Option value="PUBLICATION">Publication</Select.Option>
            <Select.Option value="BAHUMANAM">Bahumanam</Select.Option>
          </Select>
        </Row>

        <Row className="formRow">
          <label className="formLabel">Description</label>
          <TextArea
            maxLength={2000}
            className="formInput"
            rows={4}
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">SKU</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={sku}
            onChange={(e) => {
              let regex = /^[a-zA-Z0-9]+$/;
              if (!regex.test(e.target.value)) {
                // clearLoadings();
                return;
              }
              setSku(e.target.value);
            }}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Price</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={price}
            onChange={(e) => setprice(Number(e.target.value))}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">GST</label>
          <Input
            maxLength={220}
            type="number"
            min="0"
            className="formInput"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={gst}
            onChange={(e) => setGst(Number(e.target.value))}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Image</label>
          <TmsImageUpload
            fileList={fileList}
            setFileList={setFileList}
            className="w-100"
          />
        </Row>
        <Row className="formRow contantCenter">
          <label className="formLabel" style={{ visibility: "hidden" }}>
            Image
          </label>
          <label style={{ paddingRight: "10px" }}>Enable POS</label>
          <Checkbox
            style={{ paddingRight: "10px" }}
            checked={counterEnabled}
            onChange={(e) => setcounterEnabled(e.target.checked)}
          />
          <label style={{ paddingRight: "10px" }}>Enable Online</label>
          <Checkbox
            checked={onlineEnabled}
            onChange={(e) => setonlineEnabled(e.target.checked)}
          />
        </Row>
        {editFlag && (
          <>
            <Row gutter={[20, 20]} className="formRow">
              <label className="formLabel">Request Copy</label>
              <Col className="formInput ">
                <TmsImageUpload
                  fileList={changeRequestFile}
                  setFileList={setChangeRequestFile}
                  router={router}
                  type="private"
                  accept={true}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="formRow">
              <label className="formLabel">Edit Description</label>
              <Input
                maxLength={220}
                className="formInput"
                value={changeRequestDesc}
                onChange={(e) => setChangeRequestDesc(e.target.value)}
              />
            </Row>
          </>
        )}
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsProductsInnerPagesANP);
