import React, { useState } from "react";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { useEffect } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsSmAllCoupons from "./tms-sm-all-coupons.jsx";

const TmsSmCounponManagement = ({ router }) => {
  const [active, setActive] = useState("All Coupons");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location.includes(tmsRouteMap.serviceManagement_coupon_management)) {
      setActive("All Coupons");
    }
  }, [router?.location?.pathname]);
  const components = [
    {
      name: "All Coupons",
      hidden: "",
      Element: <TmsSmAllCoupons />,
      key: "All Coupons",
      route: tmsRouteMap.serviceManagement_coupon_management,
    },
  ].filter((item) => !item.hidden);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};

export default withRouter(TmsSmCounponManagement);
