import { Button, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import useRentsAndLandStore from "../../../../../zustand/rents-and-leases.store";
import tmsRouteMap from "../../../../../constants/tms-route.map";
import {
  additionalEncroahmentStates,
  encroachmentStates,
} from "../../../../../constants/states-data.data";
import TmsSelect from "../../../../../components/tms-customs/tms-select";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import moment from "moment/moment";

const TmsRalEncroachmentReports = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  // const [sortType, setSortType] = useState("ASC");
  // const [sortBy, setSortBy] = useState("createdAt");
  const {
    selectedInstitution,
    setSelectedInstitution,
    loading,
    institutionData,
    fetchEncroachmentReports,
    fetchInstitutionList,
    encroachmentReport,
    clearStates,
    fetchTotalReports,
  } = useRentsAndLandStore((state) => {
    return {
      selectedInstitution: state.selectedInstitution,
      loading: state.loading,
      setSelectedInstitution: state.setSelectedInstitution,
      institutionData: state.institutionData,
      encroachmentReport: state.encroachmentReport,
      fetchEncroachmentReports: state.fetchEncroachmentReports,
      fetchInstitutionList: state.fetchInstitutionList,
      clearStates: state.clearStates,
      fetchTotalReports: state.fetchTotalReports,
    };
  }, shallow);

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    router.location.pathname === tmsRouteMap.reports_ral_lands_encroachment &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(encroachmentStates, [
        ...additionalEncroahmentStates,
        "encroachmentReport",
      ]);
    };
  }, [router.location.pathname]);

  useEffect(() => {
    if (
      router.location?.pathname === tmsRouteMap.reports_ral_lands_encroachment
    ) {
      if (selectedInstitution !== "" && selectedInstitution) {
        fetchEncroachmentReports(
          tableCurrentPage,
          tablePageSize,
          // sortType,
          // sortBy,
          router
        );
      } else {
        fetchTotalReports(
          "ENCROACHMENT",
          tableCurrentPage,
          tablePageSize,
          router
        );
      }
    }
  }, [
    tableCurrentPage,
    tablePageSize,
    selectedInstitution,
    router?.location?.pathname,
  ]);

  const innerEncroachedColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Name of Temple",
      dataIndex: "institutionName",
      key: "institutionName",
    },
    {
      title: "Encroacher Name",
      dataIndex: "encroacherName",
      key: "encroacherName",
    },
    {
      title: "Encroach Date",
      dataIndex: "encroachmentDate",
      render: (record) => moment(record).format("DD-MM-YYYY"),
    },
    {
      title: "Legal Action",
      render: (record) =>
        record.legalAction ? `YES, Case.no: ${record.caseNumber ?? ""}` : `NO`,
    },
    {
      title: "Landmark",
      dataIndex: "landmark",
      key: "landmark",
    },
    {
      title: "Nature of Land",
      dataIndex: "natureofLand",
      key: "natureofLand",
    },
    {
      title: "Extent",
      dataIndex: "extent",
      key: "extent",
    },
    {
      title: "Survey No.",
      dataIndex: "surveyNumber",
      key: "surveyNumber",
    },
    {
      title: "Block/Breakup No.",
      dataIndex: "blockNumber",
      key: "blockNumber",
    },
  ];
  const totalEncroachedColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 80,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Institution",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Village",
      dataIndex: "village",
      key: "village",
    },

    {
      title: "Encroached Land (In Acres)",
      dataIndex: "encroachedAcres",
      key: "encroachedAcres",
    },
    {
      title: "Encroached Land (In Cents)",
      dataIndex: "encroachedCents",
      key: "encroachedCents",
    },

    {
      title: "Total Encroached Land (In Acres)",
      dataIndex: "totalEncroachedAcres",
      key: "totalEncroachedAcres",
    },
  ];
  return (
    <>
      <Row justify="flex-start">
        <TmsSelect
          selectClass="w-100 "
          classname="mr-20 align-center"
          labelClass="mr-10 mb-0"
          type="col"
          value={selectedInstitution}
          setValue={setSelectedInstitution}
          label="Select Institution"
          data={institutionData?.items?.map((item) => {
            return { name: item.name, id: item.id };
          })}
          span={5}
          optional
        />
        {selectedInstitution !== "" && (
          <Button
            style={{ marginTop: "27px" }}
            onClick={() => {
              setSelectedInstitution("");
            }}
          >
            Clear
          </Button>
        )}
        {/* <TmsSelect
          selectClass="w-100 "
          classname="mr-20 align-center"
          labelClass="mr-10 mb-0"
          type="col"
          value={sortBy}
          setValue={setSortBy}
          label="Sort By:"
          data={sortByData}
          span={5}
        /> */}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        loading={loading}
        style={{
          width: "100%",
          textTransform: "capitalize",
        }}
        columns={
          selectedInstitution !== "" && selectedInstitution
            ? innerEncroachedColumns.map((item) => {
                return { ...item, align: "center" };
              })
            : totalEncroachedColumns.map((item) => {
                return { ...item, align: "center" };
              })
        }
        dataSource={encroachmentReport?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: encroachmentReport?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsRalEncroachmentReports);
