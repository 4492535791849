import React, { useState, useEffect } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import TmsTabsLayout from "../../../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import TmsProductsFmAll from "./tms-products-fm-all";
import TmsProductsFMANF from "./tms-products-fm-anf";
import "../../../../../../assets/css/tms-tabs/tms-tabs.scss";

const TmsSmProductsFileManagement = ({ router }) => {
  const [active, setActive] = useState("File Management");
  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.sm_products_manage_files) {
      setActive("File Management");
      return;
    } else {
      setActive("Add New File");
      return;
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "File Management",
      Element: <TmsProductsFmAll />,
      key: "File Management",
      route: `${tmsRouteMap.sm_products_manage_files}${location.search}`,
    },
    {
      name: "Add New File",
      Element: <TmsProductsFMANF />,
      key: "Add New File",
      route: `${tmsRouteMap.sm_products_manage_files_add}${location.search}`,
    },
  ];
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmProductsFileManagement);
