import { Col, Popconfirm, Row, Space, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import { createBuildingStates } from "../../../../../../constants/states-data.data";
import { shallow } from "zustand/shallow";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import { DeleteOutlined } from "@ant-design/icons";
import useBuildingsStore from "../../../../../../zustand/buildings-and-shops.store";
import { SortTypeAndBy } from "../../../../../../components/tms-customs/rents-and-leases";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";
const TmsBasBuildingsList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");
  const {
    selectedInstitution,
    setSelectedInstitution,
    loading,
    institutionData,
    createdBuildingsData,
    fetchCreatedBuildingsList,
    fetchInstitutionList,
    deleteCreatedBuilding,
    deleteLoading,
    clearStates,
    handleExcelExport,
    excelLoading,
  } = useBuildingsStore((state) => {
    return {
      selectedInstitution: state.selectedInstitution,
      excelLoading: state.excelLoading,
      handleExcelExport: state.handleExcelExport,
      loading: state.loading,
      setSelectedInstitution: state.setSelectedInstitution,
      institutionData: state.institutionData,
      createdBuildingsData: state.createdBuildingsData,
      fetchInstitutionList: state.fetchInstitutionList,
      fetchCreatedBuildingsList: state.fetchCreatedBuildingsList,
      deleteCreatedLand: state.deleteCreatedLand,
      clearStates: state.clearStates,
      deleteCreatedBuilding: state.deleteCreatedBuilding,
    };
  }, shallow);
  const { handleChangeStatus } = useBuildingsStore();

  const switchTrigger = async (record, status) => {
    await handleChangeStatus(record, status, router);
    setUpdate(Math.random() * 100000 + 21);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  useEffect(() => {
    return () => {
      clearStates(createBuildingStates, ["selectedInstitution"]);
    };
  }, []);
  useEffect(() => {
    (router.location.pathname === tmsRouteMap.sm_ral_buildings_creation ||
      router.location.pathname === tmsRouteMap.sm_ral_buildings) &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchCreatedBuildingsList(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedInstitution,
    update,
  ]);

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <TmsSelect
          selectClass="w-100 "
          classname=" align-center"
          labelClass="mr-10 mb-0"
          type="col"
          value={selectedInstitution}
          setValue={setSelectedInstitution}
          label="Select Institution"
          data={institutionData?.items?.map((item) => {
            return { name: item.name, id: item.id };
          })}
          span={5}
          optional
        />
        <SortTypeAndBy
          span={6}
          sortBy={sortBy}
          sortType={sortType}
          setSortBy={setSortBy}
          setSortType={setSortType}
        />{" "}
        {selectedInstitution !== "" && selectedInstitution && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() => handleExcelExport("building", router)}
              top={false}
            />
          </Col>
        )}
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        scroll={{
          x: "auto",
        }}
        loading={loading}
        style={{
          width: "100%",
          textTransform: "capitalize",
        }}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 80,
            render: (text, object, index) => index + 1,
          },
          { title: "buildingName", dataIndex: "buildingName" },
          { title: "propertyType", dataIndex: "propertyType" },
          {
            title: "Built Area(Sqft)",
            dataIndex: "builtAreaInSqFeet",
          },
          {
            title: "Blocks Count",
            dataIndex: "numberOfBlocks",
            key: "numberOfBlocks",
          },
          {
            title: "Shops Count",
            dataIndex: "numberOfShops",
            key: "numberOfShops",
          },
          // {
          //   title: "Land Mark",
          //   dataIndex: "landmark",
          //   key: "landmark",
          // },
          {
            title: "Village",
            dataIndex: "village",
          },
          {
            title: "Mandal",
            dataIndex: "mandal",
          },
          {
            title: "District",
            dataIndex: "district",
          },
          {
            title: "State",
            dataIndex: "state",
          },
          {
            title: "Country",
            dataIndex: "country",
          },
          {
            title: "Zip",
            dataIndex: "pincode",
          },

          // {
          //   title: "geoLocation",
          //   dataIndex: "geoLocation",
          //   // render: (record) => moment(record).format("DD-MM-YYYY hh:mm A"),
          //   //
          // },
          {
            title: "Status",
            key: "status",
            render: (record) => {
              return (
                <Switch
                  size={"small"}
                  checked={record.status}
                  onClick={() => {
                    switchTrigger(record?.id, !record.status);
                  }}
                />
              );
            },
          },
          {
            title: "Action",
            render: (record) => {
              return (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure delete this institution?"
                    onConfirm={async () =>
                      deleteCreatedBuilding(record.id, router)
                    }
                    okText="Yes"
                    cancelText="No"
                    loading={deleteLoading}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Space>
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={createdBuildingsData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdBuildingsData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsBasBuildingsList);
