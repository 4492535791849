import { Button, Row } from "antd";
import React, { useState } from "react";
import TmsModal from "../../../../../../components/tms-customs/tms-modal";
import TmsFdrAccountCreation from "../creation-inner-pages/tms-account-creation";
import TmsSmFdrAccountList from "../created-inner-pages/fdr-account-list";
const AccountCategoryIndex = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Row justify="start">
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
          }}
        >
          New Account Category
        </Button>
      </Row>
      <br />
      {!visible && <TmsSmFdrAccountList />}
      <TmsModal
        centered={true}
        width="80%"
        closable={false}
        visible={visible}
        title={"Building Creation "}
        onClose={() => {
          setVisible(false);
        }}
      >
        {visible && <TmsFdrAccountCreation setVisible={setVisible} />}
      </TmsModal>
    </div>
  );
};

export default AccountCategoryIndex;
