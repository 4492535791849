import { Col, Row, Statistic } from "antd";
import React from "react";
import moment from "moment";
import { FormatNumberINRCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
const TmsSRShiftDetailsCard = ({ shiftData }) => {
  return (
    <>
      <Row justify="space-between" style={{ flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            textTransform: "capitalize",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "white",
              padding: "20px",
              width: "calc(40% - 5px)",
            }}
          >
            <Col span={24} style={{ padding: "10px" }}>
              <Statistic
                title="Transaction Count"
                value={shiftData?.transactionsCount ?? 0}
              />
            </Col>
            <Col span={12} style={{ padding: "10px" }}>
              <Statistic
                title="User Name"
                value={shiftData?.user?.displayName}
              />
            </Col>
            <Col span={12} style={{ padding: "10px" }}>
              <Statistic
                title="POS Name"
                value={shiftData?.counterShiftTiming?.name}
              />
            </Col>{" "}
            <Col span={24} style={{ padding: "10px" }}>
              <Statistic
                className="sd-stat"
                title="Log In "
                value={
                  shiftData?.login
                    ? moment(new Date(shiftData?.login)).format(
                        `DD-MM-YYYY  (hh:mm A)`
                      )
                    : "None"
                }
              />
            </Col>
            <Col span={24} style={{ padding: "10px" }}>
              <Statistic
                className="sd-stat"
                title="Log Out "
                value={
                  shiftData?.logout
                    ? moment(new Date(shiftData?.logout)).format(
                        "DD-MM-YYYY  (hh:mm A)"
                      )
                    : "None"
                }
              />
            </Col>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "white",
              padding: "20px",
              width: "calc(60% - 5px)",
            }}
          >
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Checkin Count(POS)"
                value={shiftData?.accTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Checkin Count(Online)"
                value={shiftData?.accOnlineCheckinCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Checkout Count"
                value={shiftData?.accCheckoutCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Renewal Count"
                value={shiftData?.accRenewalCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Darshanam Count"
                value={shiftData?.darshanamTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Donation Count"
                value={shiftData?.donationTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Seva Count"
                value={shiftData?.sevaTicketsCount ?? 0}
              />
            </Col>

            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Prasadam  Count"
                value={shiftData?.prasadamTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Tollgate Count"
                value={shiftData?.tollgateTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Thulabharam Count"
                value={shiftData?.thulabharamTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Kalaynakatta Count"
                value={shiftData?.kalyanakattaTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Products Count"
                value={shiftData?.shopProductCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Publication Count"
                value={shiftData?.publicationTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Advance Booking Count"
                value={shiftData?.accAdvanceBookingCount ?? 0}
              />
            </Col>

            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Manual Entry Count"
                value={shiftData?.manualEntryTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Miscellaneous Count"
                value={shiftData?.miscProductTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Bus Count"
                value={shiftData?.busTicketsCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Land Lease Count"
                value={shiftData?.landLeaseTransactionCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="License Lease Count"
                value={shiftData?.licenseLeaseTransactionCount ?? 0}
              />
            </Col>
            <Col span={6} style={{ padding: "10px" }}>
              <Statistic
                title="Shop Lease Count"
                value={shiftData?.shopLeaseTransactionCount ?? 0}
              />
            </Col>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            backgroundColor: "white",
            padding: "20px",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Total Deposited Amount"
              value={FormatNumberINRCurrency(
                shiftData?.accDepositedAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Total Previous Deposit"
              value={FormatNumberINRCurrency(
                shiftData?.accPreviousDepositAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Total Returned Amount"
              value={FormatNumberINRCurrency(
                shiftData?.accDepositReturnedAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Accomadation Amount"
              value={FormatNumberINRCurrency(shiftData?.accServicesAmount ?? 0)}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Darshanam Amount"
              value={FormatNumberINRCurrency(
                shiftData?.darshanamServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Pratyaksha Seva Amount"
              value={FormatNumberINRCurrency(
                shiftData?.sevaServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Thulabharam Amount"
              value={FormatNumberINRCurrency(
                shiftData?.thulabharamServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Donation Amount"
              value={FormatNumberINRCurrency(
                shiftData?.donationServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Shop Lease Amount"
              value={FormatNumberINRCurrency(
                shiftData?.shopLeaseServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="License Lease Amount"
              value={FormatNumberINRCurrency(
                shiftData?.licenseLeaseServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Land Lease Amount"
              value={FormatNumberINRCurrency(
                shiftData?.landLeaseServicesAmount ?? 0
              )}
            />
          </Col>

          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Prasadam Amount"
              value={FormatNumberINRCurrency(
                shiftData?.prasadamServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Tollgate Amount"
              value={FormatNumberINRCurrency(
                shiftData?.tollgateServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Kalaynakatta Amount"
              value={FormatNumberINRCurrency(
                shiftData?.kalyanakattaServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="GST Amount"
              value={FormatNumberINRCurrency(shiftData?.gstAmount ?? 0)}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Advance Booking Amount"
              value={FormatNumberINRCurrency(
                shiftData?.accAdvanceBookingCharge ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Maintenance Amount"
              value={FormatNumberINRCurrency(
                shiftData?.accMaintenanceCharge ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Products Amount"
              value={FormatNumberINRCurrency(shiftData?.shopProductAmount ?? 0)}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Publication Amount"
              value={FormatNumberINRCurrency(
                shiftData?.publicationServicesAmount ?? 0
              )}
            />
          </Col>

          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Manual Entry Amount"
              value={FormatNumberINRCurrency(
                shiftData?.manualEntryServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Miscellaneous Amount"
              value={FormatNumberINRCurrency(
                shiftData?.miscProductServicesAmount ?? 0
              )}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Discount Amount"
              value={FormatNumberINRCurrency(shiftData?.discountAmount ?? 0)}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Delivery Amount"
              value={FormatNumberINRCurrency(shiftData?.deliveryAmount ?? 0)}
            />
          </Col>
          <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Bus Amount"
              value={FormatNumberINRCurrency(shiftData?.busServicesAmount ?? 0)}
            />
          </Col>
          {/* <Col span={4} style={{ padding: "10px" }}>
            <Statistic
              title="Accommodation Count"
              value={shiftData?.accTicketsCount ?? 0}
            />
          </Col> */}
        </div>
      </Row>
      <br />
    </>
  );
};
export default TmsSRShiftDetailsCard;
