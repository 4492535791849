import { Button, Col, Input, Row, Select, notification } from "antd";
import React, { useContext } from "react";
import TmsCounterService from "../../../services/tms-counter.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { BaseContext } from "../../../contexts/BaseContext";

const TmsPosCoupon = ({
  router,
  Id,
  price,
  gst,
  type,
  width,
  numberOfTickets,
}) => {
  const {
    couponCode,
    setCouponCode,
    verifiedCouponData,
    setVerifiedCouponData,
    usedBy,
    setUsedBy,
  } = useContext(BaseContext);
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };

  const handleRemove = () => {
    setCouponCode(null);
    setVerifiedCouponData(null);
  };

  const verifyCoupon = async () => {
    if (Id === "" || !Id || Id?.length === 0) {
      notification.error({
        message: `Please select a ${type
          ?.replaceAll("DONOR_", "")
          ?.replaceAll("DONAR_", "")
          ?.replaceAll("_", " ")}`,
      });
      return;
    }
    if (couponCode === "" || couponCode === null) {
      notification.error({ message: "Enter Coupon Code" });
      return;
    }
    setVerifiedCouponData(null);
    try {
      let res = await TmsCounterService.couponRegistry(
        couponCode.trim(),
        [Id],
        Number(price),
        usedBy,
        Number(gst),
        type,
        numberOfTickets
      );

      setVerifiedCouponData(res);
    } catch (error) {
      catchErrorFunction(error, "Token expired!!", router);
      setVerifiedCouponData(null);
    }
  };

  return (
    <Row
      style={{
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Col style={{ width: width }}>
        {/* <Row
          style={{
            fontWeight: "400",
            fontSize: "20px",
          }}
        >
          Coupon Details
        </Row>
        <br /> */}
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Col style={{ width: "37%" }}>
            <label style={labelStyles}>Coupon Code</label>
            <Input
              maxLength={220}
              disabled={verifiedCouponData ? true : false}
              style={{ width: "100%" }}
              type="text"
              placeholder="Enter Coupon Code"
              value={couponCode}
              defaultValue={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </Col>
          <Col style={{ width: "37%" }}>
            <label style={labelStyles}>Used by</label>
            <Select
              size="medium"
              style={{ width: "100%" }}
              placeholder="Select Used by"
              optionFilterProp="children"
              value={usedBy}
              defaultValue={usedBy}
              onChange={(e) => {
                setUsedBy(e);
                setVerifiedCouponData(null);
              }}
            >
              <Select.Option key={"direct"} value={"direct"}>
                Donor
              </Select.Option>
              <Select.Option key={"indirect"} value={"indirect"}>
                Relative/Reference
              </Select.Option>
            </Select>
          </Col>
          <Col style={{ width: "19%" }}>
            <Button
              style={{ width: "100%" }}
              onClick={verifiedCouponData ? handleRemove : verifyCoupon}
              type={verifiedCouponData ? "danger" : "default"}
            >
              {verifiedCouponData ? "Remove" : "Verify"}
            </Button>
          </Col>
        </Row>
        {verifiedCouponData && (
          <h4 className="mt-10">
            <span style={{ color: "green" }}>Coupon Verified</span> | Amount:
            Rs.
            {verifiedCouponData?.gstDiscountAmount +
              verifiedCouponData?.priceDiscountAmount}
          </h4>
        )}
      </Col>
    </Row>
  );
};

export default TmsPosCoupon;
