import { Button, Col, Input, Modal, Row, Typography, notification } from "antd";
import React, { useState } from "react";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsProtocolService from "../../../../services/tms-protocol.service";
import moment from "moment";
import TmsTable from "../../../../components/tms-customs/tms-table";
import { FormatNumberINRCurrency } from "../../../../utils/tms-currency-formatter/tms-curreny-formatter";
import numberToWords from "../../../../utils/tms-number-to-words/tms-number-to-words";
import TmsPrintTicket from "../../../../components/tms-print-ticket/tms-print-ticket";

const TmsPosProtocolPrint = ({ router }) => {
  const [loading, setLoading] = useState(false);
  const [referenceId, setReferenceId] = useState();
  const [data, setData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [array, setArray] = useState([]);
  const [externalPrint, setExternalPrint] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [devoteeDetails, setDevoteeDetails] = useState([]);
  let temple = JSON.parse(localStorage.getItem("templeDetails"));

  let tempArray = [];

  const darshanamData = (data) => {
    let schemaBody = [];
    data?.map((item) => {
      const devoteeBody = [];
      item?.devoteeDetailsIndexes?.map((index, i) => {
        devoteeBody?.push([
          `${i === 0 ? "Devotee Names" : ""}`,
          devoteeDetails[index]?.personName,
          i === 0 ? { margin: "0" } : { margin: "0px 0px 0px 4px" },
        ]);
      });
      const generalBody = [
        [
          "Date",
          moment(item?.darshanamCounterBooking?.bookingDate).format(
            "DD-MM-YYYY"
          ),
          { margin: "0" },
        ],
        [
          "Time Slot",
          `${moment(
            item?.darshanamCounterBooking?.darshanamSlot?.startTime,
            "HH:mm:ss"
          ).format("hh:mm A")}`,
          { margin: "0" },
        ],
        [
          "Ticket Count",
          item?.darshanamCounterBooking?.noOfTickets,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Ticket Price",
          `Rs. ${Number(
            item?.darshanamCounterBooking?.darshanamSlot?.product?.price
          )}.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.darshanamCounterBooking?.price}.00 `,
          { margin: "0", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.darshanamCounterBooking?.price)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.ticketId,
          {},
          item?.darshanamCounterBooking?.darshanamSlot?.product?.name,
          `<p style="margin:0">TI No:${item?.categoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">S No:${item?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">${moment(
            item?.darshanamCounterBooking?.bookingDate
          ).format(
            "DD-MM-YYYY"
          )}</p><p style="font-weight:bold;font-size:22px;margin:0">Rs. ${
            item?.darshanamCounterBooking?.price
          }.00</p> `,
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.createdAt,
        ],
      ];
      const basicBody = [
        [
          "header",
          item?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.dailyCategoryTransactionSequence?.split("-")?.pop(),
          item?.ticketId,
        ],
        ["Ticket Id", item?.ticketId, { margin: "0" }],
        [
          "Darshanam",
          item?.darshanamCounterBooking?.darshanamSlot?.product?.name,
          { margin: "0", fontWeight: "bold" },
        ],
      ];

      schemaBody = [
        ...schemaBody,
        ...basicBody,
        ...devoteeBody,
        ...generalBody,
      ];
    });
    tempArray = [...tempArray, ...schemaBody];
    // setArray([...array, ...schemaBody]);
  };

  const formatSevaData = (data) => {
    let schemaBody = [];
    data?.map((item) => {
      // const devoteeBody = [];
      // item?.devoteeDetailsIndexes?.map((index, i) => {
      //   devoteeBody?.push([
      //     `${i === 0 ? "Devotee Names" : ""}`,
      //     devoteeDetails[index]?.personName,
      //     i === 0 ? { margin: "0" } : { margin: "0px 0px 0px 4px" },
      //   ]);
      // });
      const generalBody = [
        [
          "Calender",
          item?.sevaCounterBooking?.calendarType ?? "null",
          { margin: "0" },
        ],
        [
          item?.sevaCounterBooking?.sevaSlot?.product?.sevaType ===
          "saswataSeva"
            ? "Booked On"
            : "Date",
          item?.sevaCounterBooking?.sevaSlot?.product?.sevaType ===
          "saswataSeva"
            ? moment(item?.sevaCounterBooking?.createdAt).format("DD-MM-YYYY")
            : moment(item?.sevaCounterBooking?.bookingDate).format(
                "DD-MM-YYYY"
              ),
          { margin: "0" },
        ],
        [
          "Performace Date",
          item?.sevaCounterBooking?.sevaSlot?.product?.sevaType ===
          "saswataSeva"
            ? item?.sevaCounterBooking?.calendarType === "telugu"
              ? `${item?.sevaCounterBooking?.masam} ${item?.sevaCounterBooking?.paksham} ${item?.sevaCounterBooking?.tithi}`
              : moment(item?.sevaCounterBooking?.bookingDate).format(
                  "DD-MM-YYYY"
                )
            : "null",
          { margin: "0" },
        ],
        [
          "Time Slot",
          `${moment(
            item?.sevaCounterBooking?.sevaSlot?.startTime,
            "HH:mm:ss"
          ).format("hh:mm A")}`,
          { margin: "0" },
        ],
        [
          "Devotee Name",
          item?.sevaCounterBooking?.devoteeName
            ? item?.sevaCounterBooking?.contact
              ? `${item?.sevaCounterBooking?.devoteeName
                  ?.split(",")[0]
                  .trim()} | ${item?.sevaCounterBooking?.contact}`
              : item?.sevaCounterBooking?.devoteeName?.split(",")[0].trim()
            : item?.sevaCounterBooking?.contact ?? "NA",
          { margin: "0" },
        ],
        [
          "ID Proof No",
          item?.sevaCounterBooking?.idProofNumber
            ?.toString()
            ?.replace(/.(?=.{4,}$)/g, "X"),
          { margin: "0" },
        ],
        [
          "No of People",
          ["SBMSD"].includes(temple?.shortName)
            ? item?.sevaCounterBooking?.sevaSlot?.product?.noOfPeople === 1
              ? "Single"
              : item?.sevaCounterBooking?.sevaSlot?.product?.noOfPeople === 2
              ? "Husband & Wife"
              : item?.sevaCounterBooking?.sevaSlot?.product?.noOfPeople
            : item?.sevaCounterBooking?.sevaSlot?.product?.noOfPeople,

          { margin: "0" },
        ],
        [
          "Ticket Price",
          item?.sevaCounterBooking?.sevaSlot?.product?.name ===
            "AC vratam 2000" && temple?.shortName === "SVVSSD"
            ? `Rs. 1500.00 + 500.00 (A/C Hall)`
            : `Rs. ${item?.sevaCounterBooking?.sevaSlot?.product?.price}.00`,
          { margin: "0" },
        ],
        [
          "Total Amount",
          `Rs. ${item?.sevaCounterBooking?.price}.00 `,
          { margin: "0", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.sevaCounterBooking?.price)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.ticketId,
          {},
          item?.sevaCounterBooking?.sevaSlot?.product?.name,
          `<p style="margin:0">TI No:${item?.categoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">S No:${item?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop()}</p><p style="margin:0">${moment(
            item?.sevaCounterBooking?.bookingDate
          ).format(
            "DD-MM-YYYY"
          )}</p><p style="font-weight:bold;font-size:22px;margin:0">Rs. ${
            item?.sevaCounterBooking?.price
          }.00</p> `,
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.createdAt,
        ],
      ];
      const basicBody = [
        [
          "header",
          item?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.dailyCategoryTransactionSequence?.split("-")?.pop(),
          item?.ticketId,
          null,
        ],
        ["Ticket Id", item?.ticketId, { margin: "0" }],
        [
          "Seva",
          item?.sevaCounterBooking?.sevaSlot?.product?.name,
          { margin: "0", fontWeight: "bold" },
        ],
      ];
      schemaBody = [...schemaBody, ...basicBody, ...generalBody];
      // schemaBody = [
      //   ...schemaBody,
      //   ...basicBody,
      //   ...devoteeBody,
      //   ...generalBody,
      // ];
    });
    tempArray = [...tempArray, ...schemaBody];
  };

  const formatBookingData = (data) => {
    let schemaBody = [];
    data?.map((item) => {
      // const devoteeBody = [];
      // item?.devoteeDetailsIndexes?.map((index, i) => {
      //   devoteeBody?.push([
      //     `${i === 0 ? "Devotee Names" : ""}`,
      //     devoteeDetails[index]?.personName,
      //     i === 0 ? { margin: "0" } : { margin: "0px 0px 0px 4px" },
      //   ]);
      // });
      const headerBody = [
        [
          "header",
          item?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.dailyCategoryTransactionSequence?.split("-")?.pop(),
          item?.ticketId,
        ],
        ["Ticket Id", item?.ticketId, { margin: "0" }],
        [
          "Devotee Name",
          item?.accCounterAdvanceBooking.devoteeName ??
            item?.accommodationBooking?.devoteeName,
          { margin: "0", fontWeight: "bold" },
        ],
        ["Phone no.", item?.accCounterAdvanceBooking.contact, { margin: "0" }],
        [
          "ID Proof No",
          item?.accCounterAdvanceBooking.documentId
            ?.toString()
            ?.replace(/.(?=.{4,}$)/g, "X"),
          { margin: "0" },
        ],
        [
          "No. of Persons",
          ["KALYANA_MANDAPAM", "KALYANA MANDAPAM"]?.includes(
            item.accCounterAdvanceBooking.accBlockCategory.roomType
          )
            ? "null"
            : `${item?.accCounterAdvanceBooking?.noOfPeople}`,
          { margin: "0" },
        ],
        [
          "Room Details",
          `${item?.accCounterAdvanceBooking?.accBlockCategory?.accBlock?.name}-(${item.accCounterAdvanceBooking.accBlockCategory.roomType})`,
          { margin: "0" },
        ],
        [
          "Booking Date",
          `${moment(item?.accCounterAdvanceBooking?.bookingDate).format(
            "DD-MM-YYYY"
          )}`,
          { margin: "0" },
        ],
      ];

      const footerBody = [
        [
          "footer",
          item?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.createdAt,
        ],
      ];
      // schemaBody = [
      //   ...schemaBody,
      //   ...headerBody,
      //   ...devoteeBody,
      //   ...footerBody,
      // ];
      schemaBody = [...schemaBody, ...headerBody, ...footerBody];
    });
    tempArray = [...tempArray, ...schemaBody];
  };

  const ticketScan = async (flag) => {
    setLoading(true);
    if (!referenceId) {
      notification.error({ message: "Enter Reference ID!" });
    }
    let body = {
      protocolReferenceId: referenceId?.trim(),
      paymentType: "CASH",
      cashRemarks: "string",
      cashReference: "string",
      priceStats: flag,
    };
    try {
      const res = await TmsProtocolService.protocolprint(body);
      if (res && flag) {
        setData([res?.protocolRequest]);
        setTotalAmount(res?.totalAmount);
        setDevoteeDetails(res?.protocolRequest.devoteeDetails);
      } else if (flag === false) {
        res?.counterCartItems?.map((item) => {
          if (item?.darshanamCounterBooking !== undefined) {
            res?.protocolRequest.darshanamServices?.map((pro) => {
              if (
                item?.darshanamCounterBooking?.darshanamSlotId ===
                pro.productQuota.productSlotId
              ) {
                darshanamData([
                  {
                    ...item,
                    devoteeDetailsIndexes: pro?.devoteeDetailsIndexes,
                  },
                ]);
              }
            });
          }
          if (item?.sevaCounterBooking !== undefined) {
            formatSevaData([item]);
          }
          if (item?.accCounterAdvanceBooking !== undefined) {
            formatBookingData([item]);
          }
        });
        setArray([...tempArray]);
        setIsModalVisible(true);
        setExternalPrint(true);
      }
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      ticketScan(true);
    }
  };

  const columns = [
    {
      title: "Performance Date",
      key: "bookingDate",
      render: (text, object) => moment(object.bookingDate).format("DD-MM-YYYY"),
    },
    {
      title: "No of Persons",
      dataIndex: "noOfDevotees",
      key: "noOfDevotees",
    },
    {
      title: "Reference Number",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
    },
    {
      title: "Darshanam",

      children: [
        {
          title: "Name",
          key: "name",

          render: (text, object, i) => {
            return object?.darshanamServices?.length > 0 ? (
              object?.darshanamServices?.map((item) => {
                return (
                  <Row key={i}>
                    {item.productQuota.productSlot.product.name}
                  </Row>
                );
              })
            ) : (
              <>-</>
            );
          },
        },
        {
          title: "Quantity",
          key: "qty",
          render: (text, object, i) => {
            return object?.darshanamServices?.length > 0 ? (
              object?.darshanamServices?.map((item) => {
                return <Row key={i}>{item.quantity}</Row>;
              })
            ) : (
              <>-</>
            );
          },
        },
      ],
    },
    {
      title: "Seva",

      children: [
        {
          title: "Name",
          key: "name",

          render: (text, object, i) => {
            return object?.sevaServices?.length > 0 ? (
              object?.sevaServices?.map((item) => {
                return (
                  <Row key={i}>
                    {item.productQuota.productSlot.product.name}
                  </Row>
                );
              })
            ) : (
              <>-</>
            );
          },
        },
        {
          title: "Quantity",
          key: "qty",
          render: (text, object, i) => {
            return object?.sevaServices?.length > 0 ? (
              object?.sevaServices?.map((item) => {
                return <Row key={i}>{item.quantity}</Row>;
              })
            ) : (
              <>-</>
            );
          },
        },
      ],
    },
    {
      title: "Accommodation",

      children: [
        {
          title: "Name",
          key: "name",

          render: (text, object, i) => {
            return object?.accommodationServices?.length > 0 ? (
              object?.accommodationServices?.map((item) => {
                return (
                  <Row key={i}>
                    {item?.productQuota?.accBlockCategory?.accBlock?.name}
                  </Row>
                );
              })
            ) : (
              <>-</>
            );
          },
        },
        {
          title: "Quantity",
          key: "qty",
          render: (text, object, i) => {
            return object?.accommodationServices?.length > 0 ? (
              object?.accommodationServices?.map((item) => {
                return <Row key={i}>{item.quantity}</Row>;
              })
            ) : (
              <>-</>
            );
          },
        },
      ],
    },
    {
      title: "Action",
      key: "id",
      render: () => {
        return (
          <Button
            type="primary"
            onClick={() => {
              ticketScan(false);
            }}
          >
            Print
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Row justify="center">
        <Typography.Title> Protocol Tickets </Typography.Title>
      </Row>
      <Row justify="center">
        <Col
          className="mobile-responsive-width"
          style={{ margin: "8px", textAlign: "left" }}
        >
          {" "}
          Reference ID:{" "}
        </Col>
        <Col className="mobile-responsive-width" style={{ margin: "8px" }}>
          <Input
            maxLength={220}
            value={referenceId}
            onChange={(e) => {
              setReferenceId(e.target.value);
            }}
            onKeyDown={onKeyDown}
            className="mobile-responsive-width"
          />
        </Col>
        <Col className="mobile-responsive-width" style={{ margin: "8px" }}>
          <Button
            className="mobile-responsive-width"
            type="primary"
            onClick={() => {
              ticketScan(true);
            }}
            loading={loading}
          >
            GO!
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          maxWidth: "1300px",
          justifyContent: "center",
          margin: "auto",
          paddingTop: "60px",
        }}
      >
        <TmsTable
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={false}
        />
      </Row>
      {data?.length > 0 && (
        <div
          style={{
            maxWidth: "1300px",
            width: "100%",
            background: "#FFFCDC",
            padding: "10px 50px",
            margin: "auto",
          }}
        >
          {data[0]?.darshanamServices?.length > 0 && (
            <>
              <Row
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                <Row
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "23px",
                    textAlign: "left",
                  }}
                >
                  Darshanam
                </Row>
                {data[0]?.darshanamServices?.map((item, i) => {
                  return (
                    <Row
                      key={i}
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "500",
                      }}
                    >
                      <Col
                        style={{
                          width: "25%",
                          fontSize: "18px",
                          textAlign: "left",
                        }}
                      >
                        {item?.productQuota?.productSlot?.product?.name}
                      </Col>
                      <Col
                        style={{
                          width: "75%",
                          textAlign: "right",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        {FormatNumberINRCurrency(
                          item?.productQuota?.productSlot?.product?.price *
                            item?.devoteeDetailsIndexes?.length
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Row>
            </>
          )}
          <br />
          {data[0]?.sevaServices?.length > 0 && (
            <>
              <Row
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                <Row
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "23px",
                    textAlign: "left",
                  }}
                >
                  Seva
                </Row>
                {data[0]?.sevaServices?.map((item, i) => {
                  return (
                    <Row
                      key={i}
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "500",
                      }}
                    >
                      <Col
                        style={{
                          width: "25%",
                          fontSize: "18px",
                          textAlign: "left",
                        }}
                      >
                        {item?.productQuota?.productSlot?.product?.name}
                      </Col>
                      <Col
                        style={{
                          width: "75%",
                          textAlign: "right",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        {FormatNumberINRCurrency(
                          item?.productQuota?.productSlot?.product?.price *
                            item?.devoteeDetailsIndexes?.length
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Row>
            </>
          )}
          <br />
          {/* {data[0]?.accommodationServices?.length > 0 && (
            <>
              <Row
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                <Row
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "23px",
                    textAlign: "left",
                  }}
                >
                  Accommodation
                </Row>
                {data[0]?.accommodationServices?.map((item, i) => {
                  return (
                    <Row
                      key={i}
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "500",
                      }}
                    >
                      {item?.productQuota?.accBlockCategory?.accBlock?.name} (
                      {item?.productQuota?.accBlockCategory?.roomType})
                      {footer(
                        "Room Price :",
                        item.productQuota.accBlockCategory.price
                      )}
                      {footer(
                        "GST Amount :",
                        item.productQuota.accBlockCategory.gstAmount
                      )}
                      {footer(
                        "Deposit Amount :",
                        item.productQuota.accBlockCategory.depositAmount
                      )}
                      {footer(
                        "Maintenance Amount :",
                        item.productQuota.accBlockCategory.maintenanceCharge
                      )}
                    </Row>
                  );
                })}
              </Row>
            </>
          )} */}
          <hr />
          <>
            <Row
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                <Col
                  style={{
                    width: "25%",
                    fontSize: "18px",
                    textAlign: "left",
                  }}
                >
                  Total Amount :
                </Col>
                <Col
                  style={{
                    width: "75%",
                    textAlign: "right",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {FormatNumberINRCurrency(totalAmount ?? 0)}
                </Col>
              </Row>
            </Row>
          </>
        </div>
      )}
      <Row>
        <Modal
          visible={isModalVisible}
          width={450}
          footer={null}
          closable={false}
          centered
          maskClosable={false}
        >
          <TmsPrintTicket
            data={array}
            setData={setArray}
            type="Protocol"
            from="Counter"
            externalPrint={externalPrint}
            setExternalPrint={setExternalPrint}
            setButtonLoading={setLoading}
            setPrintTicketModal={setIsModalVisible}
          />
        </Modal>
      </Row>
    </div>
  );
};

export default withRouter(TmsPosProtocolPrint);
