import { Divider, notification, Table } from "antd";
import React, { useEffect, useState } from "react";
// import { useReactToPrint } from "react-to-print";
import TmsModal from "../tms-modal";
// import LeaseInvoice from "./lease-invoice";
import "../../../assets/css/tms-common/tms-rents.scss";
import { FormatNumberINRCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";
import moment from "moment/moment";

const PastInvoices = ({
  type,
  editData,
  pastTxnFlag,
  setPastTxnFlag,
  setEditData,
  fetchLeaseInvoices,
  router,
}) => {
  const [data, setData] = useState([]);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  // const [sortType, setSortType] = useState("ASC");
  //   const [sortBy, setSortBy] = useState("createdAt");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, []);

  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    // if (sorter.field === "createdAt") {
    //   if (sorter.order === "ascend") {
    //     setSortType("ASC");
    //   } else if (sorter.order === "descend") {
    //     setSortType("DESC");
    //   }
    // }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let records = await fetchLeaseInvoices(
        editData?.id,
        false,
        tableCurrentPage,
        tablePageSize,
        "ASC",
        "createdAt",
        router
      );
      if (records.items.length === 0) {
        notification.error({
          message: "No latest invoices are generated",
        });
        setPastTxnFlag(false);
        setEditData({});
        return;
      } else {
        setData(records);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: "No latest invoices are generated",
      });
    }
  };
  const leaseColumns = [
    {
      title: "S.no",
      key: "sno",
      width: 70,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
    },
    {
      title: "Generated On",
      dataIndex: type === "land-lease" ? "landLease" : "shopLease",
      render: (record) =>
        moment(record?.lastInvoiceGeneratedOn).format("DD-MM-YYYY"),
    },

    {
      title:
        type === "land-lease"
          ? "Survey No"
          : type === "shop-lease"
          ? "Building Name"
          : "License",
      dataIndex:
        type === "land-lease"
          ? "landLease"
          : type === "shop-lease"
          ? "shopLease"
          : "licenseLease",
      render: (record) => {
        return type === "land-lease"
          ? record?.surveyNumberBreakup?.surveyNumber.surveyNo
          : type === "shop-lease"
          ? record.shop?.blockOrFloor?.building?.buildingName
          : record?.license?.licenseName;
      },
    },
    {
      title:
        type === "land-lease"
          ? "Breakup No"
          : type === "shop-lease"
          ? "Block/Floor"
          : "Licensee Name",
      dataIndex:
        type === "land-lease"
          ? "landLease"
          : type === "shop-lease"
          ? "shopLease"
          : "licenseLease",

      render: (record) => {
        return type === "land-lease"
          ? record?.surveyNumberBreakup?.blockNumber
          : type === "shop-lease"
          ? record?.shop?.blockOrFloor?.building?.buildingName
          : record?.licenseeName;
      },
    },
    {
      title: type === "license-lease" ? "License type" : "Extent",
      dataIndex:
        type === "land-lease"
          ? "landLease"
          : type === "shop-lease"
          ? "shopLease"
          : "licenseLease",
      render: (record) =>
        type === "land-lease"
          ? `${record?.leasedExtentAcres}(Acres), ${record?.leasedExtentCents}(Cents)`
          : type === "shop-lease"
          ? `${record?.shopExtentBreadth}(L) * ${record?.shopExtentBreadth}(B)`
          : record?.licenseeType,
    },

    {
      title: "Lease Rate",
      render: (record) =>
        record?.landLease?.paymentType !== "KIND"
          ? FormatNumberINRCurrency(record?.lastMonthLeaseAmount)
          : `${record?.lastMonthLeaseKindQuantity} - ${record?.landLease?.kindItem}`,
    },
    {
      title: "Arrear Amount",
      dataIndex:
        type === "land-lease"
          ? "landLease"
          : type === "shop-lease"
          ? "shopLease"
          : "licenseLease",

      render: (record) =>
        FormatNumberINRCurrency(
          type === "license-lease"
            ? Number(record?.licenseArrear?.arrearAmount)
            : Number(record?.arrear?.arrearAmount) ?? 0
        ),
    },
    {
      title: "Total Amount",
      render: (record) =>
        record?.lastInvoiceGeneratedOn === null
          ? "Not generated"
          : type === "land-lease"
          ? FormatNumberINRCurrency(
              (Number(record?.landLease?.arrear?.arrearAmount) ?? 0) +
                (Number(record?.lastMonthLeaseAmount) ?? 0)
            )
          : type === "shop-lease"
          ? FormatNumberINRCurrency(
              (Number(record?.shopLease?.arrear?.arrearAmount) ?? 0) +
                (Number(record?.lastMonthLeaseAmount) ?? 0)
            )
          : FormatNumberINRCurrency(
              (Number(record?.licenseLease?.licenseArrear?.arrearAmount) ?? 0) +
                (Number(record?.lastMonthLeaseAmount) ?? 0)
            ),
    },
    {
      title: "Payment",
      dataIndex: "isPaymentCompleted",
      render: (record) => (record ? "Paid" : "Pending"),
    },
  ].map((item) => {
    return { ...item, align: "center" };
  });
  return (
    <TmsModal
      visible={pastTxnFlag}
      width={"80%"}
      onOk={() => {
        setEditData({});
        setPastTxnFlag(false);
      }}
      closable={true}
      centered
      onCancel={() => {
        setEditData({});
        setPastTxnFlag(false);
      }}
    >
      <div>
        <h2>Invoice History</h2>
        <h4>
          Lesse: {editData?.lesseName} | Mobile: {editData?.mobileNumber}{" "}
        </h4>
        <Divider />
        <Table
          className={" accomadation_table captalise-center mt-10 "}
          tableLayout="fixed"
          scroll={{ x: "max-content" }}
          loading={loading}
          columns={leaseColumns}
          dataSource={data?.items ?? []}
          onChange={handleTableChange}
          pagination={{
            total: data?.meta?.totalItems ?? 0,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></Table>
      </div>
    </TmsModal>
  );
};

export default PastInvoices;
