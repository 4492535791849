import React, { useEffect, useState } from "react";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsKalyanakattaInnerPagesAll from "./tms-sm-kalayanakatta-inner-pages/tms-kalyanakatta-inner-pages-all";
import TmsKalyanakattaInnerPagesAnd from "./tms-sm-kalayanakatta-inner-pages/tms-kalyanakatta-inner-pages-and";
import TmsKKPerformerAll from "./tms-sm-kalayanakatta-inner-pages/tms-sm-kk-performer/tms-performer-all";
const TmsSmKalayanakatta = ({ router }) => {
  const [active, setActive] = useState("all");

  useEffect(() => {
    let location = router.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_kalayanakatta) {
      setActive("all");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_kalyanakatta_add_new_kalyanakatta)
    ) {
      setActive("Add New Kalyanakatta");
    } else if (location.includes(tmsRouteMap.sm_kalyanakatta_manage_tonsure)) {
      setActive("all");
      return;
    } else if (
      location.includes(tmsRouteMap.sm_kalyanakatta_manage_performer)
    ) {
      setActive("Manage Performer");
      return;
    }
  }, [router.location?.pathname]);
  const location = router.location?.pathname;
  const components = [
    {
      name: "All",
      Element: location.includes(tmsRouteMap.sm_kalyanakatta_manage_tonsure) ? (
        <TmsCmsSlots type="kalyankatta-tonsure" />
      ) : (
        <TmsKalyanakattaInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_kalayanakatta,
    },

    {
      name: "Add New Kalyanakatta",
      Element: <TmsKalyanakattaInnerPagesAnd />,
      key: "Add New Kalyanakatta",
      route: tmsRouteMap.sm_kalyanakatta_add_new_kalyanakatta,
    },
    {
      name: "Manage Performer",
      Element: <TmsKKPerformerAll />,
      key: "Manage Performer",
      route: tmsRouteMap.sm_kalyanakatta_manage_performer,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};

export default withRouter(TmsSmKalayanakatta);
