import { Popconfirm, Row, Space, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsModal from "../../tms-customs/tms-modal";
import TmsSelect from "../../tms-customs/tms-select";
import useRentsAndLandStore from "../../../zustand/rents-and-leases.store";
import { sortByData } from "../../../constants/states-data.data";

const TmsInstitutionList = ({ visible, setVisible, router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");
  //zustand variables
  const { institutionData, loading, deleteInstitution, fetchInstitutionList } =
    useRentsAndLandStore((state) => {
      return {
        institutionData: state.institutionData,
        loading: state.loading,
        deleteInstitution: state.deleteInstitution,
        fetchInstitutionList: state.fetchInstitutionList,
      };
    });

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  //useeffect to trigger on dependent variables
  useEffect(() => {
    visible &&
      fetchInstitutionList(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
  }, [visible, sortBy, tableCurrentPage, tablePageSize, sortType, update]);

  return (
    <TmsModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closable={true}
      footer={false}
      centered
      width={"70%"}
      height={"80%"}
      maskClosable={true}
    >
      <Row justify="space-between ">
        <h2 className="mr-10">Created Institutions</h2>
        <TmsSelect
          selectClass="w-100 "
          classname=" align-center"
          labelClass="mr-10 mb-0"
          type="col"
          value={sortBy}
          setValue={setSortBy}
          label="Sort By:"
          data={sortByData}
          span={6}
          optional={true}
        />
      </Row>

      <Table
        className={" accomadation_table captalise-center mt-10"}
        tableLayout="fixed"
        scroll={{ x: true }}
        loading={loading}
        style={{
          width: "100%",
          textTransform: "capitalize",
        }}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: "20px",
            render: (text, object, index) => index + 1,
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Short Name",
            dataIndex: "shortName",
            key: "shortName",
          },
          {
            title: "Category",
            dataIndex: "category",
            key: "category",
          },

          {
            title: "Address",
            render: (record) => {
              return `${record.village},${record.mandal},${record.district},${record.state},${record.country} - ${record.pincode}`;
            },
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (record) => moment(record).format("DD-MM-YYYY hh:mm A"),
          },
          {
            title: "Action",
            render: (record) => {
              return (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure delete this institution?"
                    onConfirm={async () => {
                      await deleteInstitution(record?.id);
                      setUpdate(Math.random() * 1000 + 9);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Space>
              );
            },
          },
        ]}
        dataSource={institutionData.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: institutionData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </TmsModal>
  );
};

export default withRouter(TmsInstitutionList);
