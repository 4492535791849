import { Col, Input, notification, Row, Select } from "antd";
import React, { useState } from "react";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsAddress from "../../../../../../components/tms-customs/tms-address";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import TmsRentsService from "../../../../../../services/tms-rents.service";
import catchErrorFunction from "../../../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";

const TmsInstitutionCreation = ({ router }) => {
  const [village, setVillage] = useState("");
  const [mandal, setMandal] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [institution, setInstitution] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [hsnNo, setHsnNo] = useState("");
  const [shortName, setShortName] = useState("");
  const validateGSTNumber = (gstNumber) => {
    const regex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[0-9A-Z]{1}/;
    return regex.test(gstNumber);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (
        village === "" ||
        mandal === "" ||
        district === "" ||
        state === "" ||
        zip === "" ||
        institution === "" ||
        hsnNo === "" ||
        gstNo === "" ||
        shortName == ""
      ) {
        setLoading(false);
        notification.error({
          message: "Make sure you fill all mandatory fields",
        });
        return;
      }
      if (!validateGSTNumber(gstNo)) {
        setLoading(false);
        notification.error({
          message: "Make sure to fill a valid GST Number",
        });
        return;
      }
      const body = {
        name: institution,
        category: category,
        country: country,
        state: state,
        district: district,
        mandal: mandal,
        village: village,
        pincode: zip,
        gstNumber: gstNo,
        hsnNumber: Number(hsnNo),
        shortName: shortName,
      };
      await TmsRentsService.createInstitution(body);
      notification.success({
        message: "Institution Created successfully",
      });
      setLoading(false);
      reset();
    } catch (error) {
      catchErrorFunction(error, "Token expired!!.Login again", router);
      setLoading(false);
    }
  };
  const reset = () => {
    setInstitution("");
    setVillage("");
    setMandal("");
    setState("");
    setCountry("India");
    setZip("");
    setDistrict("");
    setCategory("");
    setGstNo("");
    setHsnNo("");
  };
  return (
    <div>
      <Row gutter={[20, 30]} style={{ marginBottom: "15px" }}>
        <Col span={8}>
          <Row className={"t-left"}>Name of Institution</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Institution Name"
            value={institution}
            onChange={(e) => {
              setInstitution(e.target.value);
            }}
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Short Name</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Short Name"
            value={shortName}
            onChange={(e) => {
              setShortName(e.target.value);
            }}
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>Category</Row>
          <Row>
            <Select
              value={category}
              onChange={(e) => {
                setCategory(e);
              }}
              style={{ width: "100%", textDecoration: "capitalise" }}
            >
              <Select.Option value={"6A"}> 6A </Select.Option>
              <Select.Option value={"6B"}> 6B </Select.Option>
              <Select.Option value={"6C"}> 6C </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>GST No</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter GST Number"
            value={gstNo}
            onChange={(e) => {
              e.target.value.length <= 15 && setGstNo(e.target.value);
            }}
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={8}>
          <Row className={"t-left"}>HSN No</Row>
          <Input
            maxLength={220}
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            type="number"
            min="0"
            placeholder="Enter HSN Number"
            value={hsnNo}
            onChange={(e) => {
              if (e.target.value.length > 8) {
                return;
              }
              setHsnNo(e.target.value);
            }}
            rules={[{ required: true }]}
          />
        </Col>
        <TmsAddress
          span={8}
          village={village}
          setVillage={setVillage}
          mandal={mandal}
          setMandal={setMandal}
          district={district}
          setDistrict={setDistrict}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          type="general"
        />{" "}
      </Row>

      <br />

      <TmsCancelSubmitButtons
        icon={arrowRight}
        loading={loading}
        cancelOnlick={reset}
        submitOnclick={handleSubmit}
      />
    </div>
  );
};

export default withRouter(TmsInstitutionCreation);
