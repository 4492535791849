import React, { useEffect, useState } from "react";
import TmsTable from "../../../components/tms-customs/tms-table";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsUserService from "../../../services/tms-user/tms-user.service";

const TmsUmInnerPagesUsersActionLogs = ({ router, userId }) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [total, setTotal] = useState(0);

  const fetchActionLogs = async () => {
    setLoading(true);
    try {
      const data = await TmsUserService.userActionLogs(
        userId,
        currentPage,
        pageSize
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchActionLogs();
  }, [currentPage, pageSize]);

  return <TmsTable loading={loading} />;
};

export default TmsUmInnerPagesUsersActionLogs;
