import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Col, Drawer, Row } from "antd";
import TmsPrasadamService from "../../../../../services/tms-prasadam.service";
import TmsCounterService from "../../../../../services/tms-counter.service";

import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import moment from "moment-timezone";

const TmsPrasadamInventoryAll = ({ router }) => {
  const [updateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prasadamInventoryData, setPrasadamInventoryData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewPrasadamData, setViewPrasadamData] = useState();
  const [editFlag, setEditFlag] = useState();
  // const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [counters, setCounters] = useState([]);
  const tableColums = [
    ["Name", viewPrasadamData?.name],
    ["Description", viewPrasadamData?.description],
    ["Price", viewPrasadamData?.price],
    ["POS-(Status)", viewPrasadamData?.counterEnabled ? "enabled" : "disabled"],
    [
      "Online-(Status)",
      viewPrasadamData?.onlineEnabled ? "enabled" : "disabled",
    ],
  ];

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    router.location?.pathname === "/service-management/prasadam-inventory" &&
      getPrasadamInventory();
    if (!editFlag) {
      settmsModalVisible(false);
    }
  }, [
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);
  useEffect(() => {
    getCounters();
  }, []);
  const getCounters = async () => {
    try {
      const counters = await TmsCounterService.getAllCounter();
      setCounters(counters?.items);
    } catch (error) {
      catchErrorFunction(error, "Token exipred! Please login again.", router);
    }
  };
  const getPrasadamInventory = async () => {
    setLoading(true);
    try {
      let data = [];
      setPrasadamInventoryData([]);

      data = await TmsPrasadamService.getPrasadamInventory(
        tableCurrentPage,
        tablePageSize
      );

      setTabelTotalSize(data?.meta?.totalItems);
      setPrasadamInventoryData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Indent No",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (record) =>
        moment(record, "YYYY-MM-DD HH:mm:ss Z").format("DD-MM-YYYY (hh:mm A)"),
    },
    {
      title: "Added by",
      dataIndex: "user",
      render: (record) => record.displayName,
    },
    {
      title: "Item Name",
      dataIndex: "prasadam",
      render: (record) => record.name, //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Opening Stock",
      dataIndex: "openingQuantity",
      key: "openingQuantity",
    },
    {
      title: "Inward Stock",
      dataIndex: "inwardQuantity",
      key: "inwardQuantity",
    },
    {
      title: "Outward Stock",
      dataIndex: "outwardQuantity",
      key: "outwardQuantity",
    },
    {
      title: "Total Stock",
      render: (record) => {
        return (
          record.openingQuantity +
          record.inwardQuantity -
          record.outwardQuantity
        );
      },
    },
  ];

  return (
    <>
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={prasadamInventoryData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {record?.prasadamCounterInventory?.map((item, i) => {
                return (
                  <Row style={{ paddingLeft: "60px" }} key={i}>
                    <Col span={6}>
                      Counter:{" "}
                      {counters?.find(
                        (innerItem) => innerItem.id === item.counterId
                      )?.name ?? record?.counterId}{" "}
                    </Col>
                    <Col span={6}>
                      Quantity:{" "}
                      {(item.percentage / 100) *
                        (record?.inwardQuantity ?? record?.outwardQuantity)}
                    </Col>
                  </Row>
                );
              })}
            </>
          ),
        }}
      />
      <Drawer
        className="DetailsModal"
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewPrasadamData(null);
        }}
        title={editFlag ? "Edit Prasadam" : "Prasadam Details"}
      >
        {editFlag ? (
          ""
        ) : (
          //   <TmsPrasadamInnerPagesAND
          //     viewPrasadamData={viewPrasadamData}
          //     editFlag={editFlag}
          //     productId={productId}
          //     setEditFlag={setEditFlag}
          //     settmsModalVisible={settmsModalVisible}
          //   />
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
            <br />
            <Col className="ImgCol">
              <img src={viewPrasadamData?.imageUrl} alt="Image" />
            </Col>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default withRouter(TmsPrasadamInventoryAll);
