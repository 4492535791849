import React, { useEffect, useState } from "react";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsReportAccommodationReport from "./tms-report-accommodation-report";
import TmsReportAccommodationSummaryReport from "./tms-report-accommodation-summary-report";
import TmsReportAccommodationRenewal from "./tms-report-accommodation-renewal";
const TmsReportsAccommodation = ({ router }) => {
  const [active, setActive] = useState("Users");

  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.reports_accommodation) {
      setActive("Accommodation Report");
      return;
    } else if (
      location.includes(tmsRouteMap.reports_accommodation_summary_report)
    ) {
      setActive("Summary Report");
    } else if (
      location.includes(tmsRouteMap.reports_accommodation_renewal_report)
    ) {
      setActive("Renewal Report");
    }
  }, [router?.location?.pathname]);

  const components = [
    {
      name: "Accommodation Report",
      Element: <TmsReportAccommodationReport />,
      key: "Accommodation Report",
      route: tmsRouteMap.reports_accommodation,
    },

    {
      name: "Summary Report",
      Element: <TmsReportAccommodationSummaryReport />,
      key: "Summary Report",
      route: tmsRouteMap.reports_accommodation_summary_report,
    },
    {
      name: "Renewal Report",
      Element: <TmsReportAccommodationRenewal />,
      key: "Renewal Report",
      route: tmsRouteMap.reports_accommodation_renewal_report,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsReportsAccommodation);
