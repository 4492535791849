import { Card, Col, Row } from "antd";
import React, { useContext } from "react";
import cardDetails from "../../constants/tms-pos.map";
import { BaseContext } from "../../contexts/BaseContext";
import checkPermissions from "../../utils/tms-permissions/tms-permissions";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";
import "../../assets/css/tms-custom-elements/tms-custom-elements.scss";
const TmsBooking = () => {
  const { userDetails } = useContext(BaseContext);

  return (
    <Row gutter={[20, 20]}>
      {cardDetails.map((card, i) => {
        return checkPermissions(userDetails, card) ? (
          <Col
            key={"pos-booking-cards" + i}
            style={{
              float: "left",
              width:
                window.innerWidth < 650
                  ? "100%"
                  : window.innerWidth < 1000 && window.innerWidth > 650
                  ? "50%"
                  : "25%",
            }}
          >
            <Card
              hoverable
              className="antd-custom"
              onClick={() => history?.push(card.route)}
            >
              <Row style={{ fontSize: "20px" }}>{card.title}</Row>
            </Card>
          </Col>
        ) : null;
      })}
    </Row>
  );
};
export default withRouter(TmsBooking);
