import { Button, Card, Checkbox, Col, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsImageUpload from "../../../../../components/tms-customs/tms-image-upload";
import TmsAccommodationService from "../../../../../services/tms-accommodation.service";

const TmsAccInnerPagesADD = ({
  router,
  editFlag,
  productId,
  setEditFlag,
  viewAccommodationData,
  settmsModalVisible,
}) => {
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [minOnlineHours, setminOnlineHours] = useState("");
  const [maxOnlineHours, setmaxOnlineHours] = useState("");
  const [minCounterHours, setminCounterHours] = useState("");
  const [maxCounterHours, setmaxCounterHours] = useState("");
  const [blockName, setBlockName] = useState("");
  const [description, setdescription] = useState("");
  const [counterEnabled, setcounterEnabled] = useState(false);
  const [onlineEnabled, setonlineEnabled] = useState(false);
  // const [price, setprice] = useState("");
  const [fileList, setFileList] = useState([]);
  const [changeRequestFile, setChangeRequestFile] = useState([]);
  const [changeRequestDesc, setChangeRequestDesc] = useState("");
  const [isAddon, setIsAddOn] = useState(false);
  const [isProtocol, setIsProtocol] = useState(false);

  const handleSumit = async () => {
    setLoading(true);
    if (
      minOnlineHours === null ||
      minOnlineHours === "" ||
      maxOnlineHours === null ||
      maxOnlineHours === "" ||
      minCounterHours === null ||
      minCounterHours === "" ||
      maxCounterHours === null ||
      maxCounterHours === "" ||
      blockName === "" ||
      description === "" ||
      // price === "" ||
      fileList.length === 0
    ) {
      notification.error({ message: "Fill all the fields" });
      setLoading(false);
      return;
    }
    if (editFlag) {
      if (changeRequestFile?.length === 0 || changeRequestDesc === "") {
        notification.error({ message: "Fill all the fields" });
        setLoading(false);

        return;
      }
    }
    let body = {
      name: blockName,
      description: description,
      counterEnabled: counterEnabled,
      onlineEnabled: onlineEnabled,
      imageUrl: fileList[0].url,
      minOnlineHours: minOnlineHours,
      maxOnlineHours: maxOnlineHours,
      minCounterHours: minCounterHours,
      maxCounterHours: maxCounterHours,
      isAddon: isAddon,
      protocolEnabled: isProtocol,
    };
    if (editFlag) {
      body.changeRequestPdfUrl = changeRequestFile[0].url;
      body.changeDescription = changeRequestDesc;
    }
    try {
      if (editFlag) {
        await TmsAccommodationService.editBlock(productId, body);
        notification.success({
          message: "Accommodation Block Edited Successful",
        });
        setEditFlag(false);
      } else {
        await TmsAccommodationService.createNewBlock(body);
        notification.success({
          message: "Accommodation Block Created Successful",
        });
        handleFuction();
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const handleFuction = () => {
    setminOnlineHours();
    setmaxOnlineHours();
    setminCounterHours();
    setmaxCounterHours();
    setBlockName("");
    setdescription("");
    setcounterEnabled(false);
    setonlineEnabled(false);
    setIsAddOn(false);
    setFileList([]);
    setChangeRequestFile([]);
    setChangeRequestDesc("");
    setIsProtocol(false);
  };
  useEffect(() => {
    if (editFlag) {
      setminOnlineHours(Number(viewAccommodationData.minOnlineHours));
      setmaxOnlineHours(Number(viewAccommodationData.maxOnlineHours));
      setminCounterHours(viewAccommodationData.minCounterHours);
      setmaxCounterHours(viewAccommodationData.maxCounterHours);
      setBlockName(viewAccommodationData.name);
      setdescription(viewAccommodationData.description);
      setcounterEnabled(viewAccommodationData.counterEnabled);
      setonlineEnabled(viewAccommodationData.onlineEnabled);
      setIsAddOn(viewAccommodationData?.isAddon);
      setIsProtocol(viewAccommodationData.protocolEnabled);
      setFileList([{ url: viewAccommodationData.imageUrl, status: "done" }]);
    }
  }, [viewAccommodationData]);
  return (
    <Row style={{ width: "100%" }}>
      <Card>
        <Row className="formRow">
          <label className="formLabel">Block Name</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={blockName}
            onChange={(e) => setBlockName(e.target.value)}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">Description</label>
          <TextArea
            maxLength={2000}
            className="formInput"
            rows={4}
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        </Row>

        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Cut Off Time</label>
          <Col className="formCols-2">
            <label>Online (Hours)</label>
            <Input.Group compact>
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "50%" }}
                placeholder="Min Hours"
                value={minOnlineHours}
                onChange={(e) => setminOnlineHours(Number(e.target.value))}
              />
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "50%" }}
                placeholder="Max Hours"
                value={maxOnlineHours}
                onChange={(e) => setmaxOnlineHours(Number(e.target.value))}
              />
            </Input.Group>
          </Col>

          <Col className="formCols-2">
            <label>POS (Hours)</label>
            <Input.Group compact>
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "50%" }}
                placeholder="Min Hours"
                value={minCounterHours}
                onChange={(e) => setminCounterHours(Number(e.target.value))}
              />
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "50%" }}
                placeholder="Max Hours"
                value={maxCounterHours}
                onChange={(e) => setmaxCounterHours(Number(e.target.value))}
              />
            </Input.Group>
          </Col>
        </Row>
        {/* <Row className="formRow">
          <label className="formLabel">Image</label>
        </Row> */}
        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Image Upload</label>
          <Col className="formCols-2 ">
            <TmsImageUpload
              fileList={fileList}
              setFileList={setFileList}
              className="w-100"
              router={router}
              accept={false}
            />
          </Col>

          {/* <Col className="formCols-2">
            <Input
              type="number"
                            min="0"

              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={price}
              onChange={(e) => setprice(Number(e.target.value))}
              disabled={editFlag ? true : false}
            />
          </Col> */}
        </Row>
        <Row>
          <label className="formLabel"></label>
          <Row gutter={[20, 20]} className="formRow contantCenter">
            <Col>
              <label style={{ paddingRight: "10px" }}>Enable POS</label>
              <Checkbox
                style={{ paddingRight: "10px" }}
                checked={counterEnabled}
                onChange={(e) => setcounterEnabled(e.target.checked)}
              />
            </Col>
            <Col>
              {" "}
              <label style={{ paddingRight: "10px" }}>Enable Online</label>
              <Checkbox
                checked={onlineEnabled}
                onChange={(e) => setonlineEnabled(e.target.checked)}
              />
            </Col>
            <Col>
              <label style={{ paddingRight: "10px" }}>Enable Add on</label>
              <Checkbox
                checked={isAddon}
                onChange={(e) => setIsAddOn(e.target.checked)}
              />
            </Col>
            <Col>
              {" "}
              <label style={{ paddingRight: "10px" }}>Enable Protocol</label>
              <Checkbox
                checked={isProtocol}
                onChange={(e) => setIsProtocol(e.target.checked)}
              />
            </Col>
          </Row>
        </Row>
        {editFlag && (
          <>
            <Row gutter={[20, 20]} className="formRow">
              <label className="formLabel">Request Copy</label>
              <Col className="formInput ">
                <TmsImageUpload
                  fileList={changeRequestFile}
                  setFileList={setChangeRequestFile}
                  router={router}
                  type="private"
                  accept={true}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="formRow">
              <label className="formLabel">Edit Description</label>
              <Input
                maxLength={220}
                className="formInput"
                value={changeRequestDesc}
                onChange={(e) => setChangeRequestDesc(e.target.value)}
              />
            </Row>
          </>
        )}

        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                handleSumit();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsAccInnerPagesADD);
