import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";
const TmsCommonService = {
  uploadFile: async (file, access, fileFor) => {
    const res = await Axios.post(
      `v1/file-upload/restricted/upload/${access}?fileFor=${fileFor}`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return res.data;
  },
  uploadPrivateFile: async (file, fileFor) => {
    const res = await Axios.post(
      `v1/file-upload/upload/private?fileFor=${fileFor}`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return res.data;
  },
  getConsolidateReport: async ({
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/dashboard/consolidated-report/counter?bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter) {
      query += `&counterId=${counter}`;
    }
    if (shift) {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType !== "") {
      query += `&dateType=${bookingType}`;
    }

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  getOnlineConsolidateReport: async ({
    productId,
    from,
    to,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/dashboard/consolidated-report/online?bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  fetchRoomTypes: async () => {
    let query = `v1/accommodation/block/category/room-types-by-temple`;

    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
};
export default TmsCommonService;
