import { Card, Col, Row } from "antd";
import React from "react";
import { memo } from "react";

const TmsDisplayCard = ({ label, card, i, router }) => {
  return (
    <Col
      key={label + "-booking-cards" + i}
      style={{
        float: "left",
        width:
          window.innerWidth < 650
            ? "100%"
            : window.innerWidth < 1000 && window.innerWidth > 650
            ? "50%"
            : "25%",
      }}
    >
      <Card
        hoverable
        className="antd-custom"
        style={{
          backgroundColor: card.color,
          color: card.textColor,
          borderRadius: "10px",
          border: `1px solid ${card.textColor}`,
        }}
        onClick={() => router.navigate(card.route)}
      >
        <Row
          style={{
            fontSize: "20px",
            alignSelf: "flex-start",
            textAlign: "left",
          }}
        >
          {card.title}
        </Row>
        <img
          height={"45px"}
          src={card.logo}
          alt={"Logo"}
          style={{
            position: "absolute",
            alignSelf: "flex-end",
            bottom: "20px",
          }}
        />
      </Card>
    </Col>
  );
};
export default memo(TmsDisplayCard);
