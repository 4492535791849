export const dragStart = (e, position, dragItem) => {
  dragItem.current = position;
};

export const dragEnter = (e, position, dragOverItem) => {
  dragOverItem.current = position;
};

export const drop = (
  e,
  setUpdateFlag,
  dragItem,
  dragOverItem,
  type,
  selectedIds
) => {
  const copyListItems = JSON.parse(localStorage.getItem(type))?.filter((item) =>
    selectedIds?.includes(item?.id)
  );
  const dragItemContent = copyListItems[dragItem.current];
  copyListItems.splice(dragItem.current, 1);
  copyListItems.splice(dragOverItem.current, 0, dragItemContent);
  dragItem.current = null;
  dragOverItem.current = null;
  localStorage.setItem(type, JSON.stringify(copyListItems));
  setUpdateFlag(Math?.random());
};

export const updateData = (data, setList, type) => {
  let newlist = data?.map((item) => {
    return item?.id;
  });
  let temp = JSON.parse(localStorage.getItem(type))
    ? JSON.parse(localStorage.getItem(type))?.filter((item) =>
        newlist?.includes(item?.id)
      )
    : data;
  if (temp?.length === data?.length) {
    let res = [];
    temp?.map((item) => {
      if (newlist?.includes(item?.id)) {
        res = [...res, data[newlist?.indexOf(item?.id)]];
      }
    });
    setList(res);
    localStorage?.setItem(type, JSON?.stringify(res));
  } else {
    let nottempList = temp?.map((item) => {
      return item?.id;
    });
    let nottemp = data?.filter((item) => !nottempList?.includes(item?.id));
    setList([...temp, ...nottemp]);
    localStorage?.setItem(type, JSON?.stringify([...temp, ...nottemp]));
  }
};
