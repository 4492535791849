import React, { useState, useEffect } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";

import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsDonationService from "../../../services/tms-donation.service";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";

const { Option } = Select;
const TmsPosPettyDonation = ({ router }) => {
  const [selectedDonationId, setSelectedDonationId] = useState();
  const [externalPrint, setExternalPrint] = useState(false);
  const [sevaList, setSevaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [gothram, setGothram] = useState("");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  const [devoteeName, setDevoteeName] = useState("");
  const [array, setArray] = useState([]);
  const [payment, setPayment] = useState("CASH");

  useEffect(() => {
    getDonationSevaList();
    setDevoteeName("");
  }, []);

  const DoantionData = (data) => {
    let schemaBody = [];
    data?.cartItems?.donation?.map((item) => {
      schemaBody = [
        ...schemaBody,
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(),
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence
            ?.split("-")
            ?.pop(),
          item?.counterCartItem?.ticketId,
        ],
        ["Ticket Id", item?.counterCartItem?.ticketId, { margin: "0" }],

        [
          "Donation",
          item?.productDetails?.productName,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "Devotee Name",
          item?.donationBooking?.devoteeName
            ? `${item?.donationBooking?.devoteeName?.split(",")[0].trim()}`
            : "null",
          { margin: "0" },
        ],
        ["Booking Date", `${moment().format("DD-MM-YYYY")}`, { margin: "0" }],
        ["Phone", `${item?.donationBooking?.contact}`, { margin: "0" }],
        ["Gothram", `${item?.donationBooking?.gothram}`, { margin: "0" }],
        ["Address", `${item?.donationBooking?.address}`, { margin: "0" }],
        [
          "Total Amount",
          `Rs. ${item?.donationBooking?.amount}.00 `,
          { margin: "0", fontSize: "22px", fontWeight: "800" },
        ],
        [
          "",
          `(${numberToWords(item?.donationBooking?.amount)} Rupees)`,
          { margin: "0", fontWeight: "bold" },
        ],
        [
          "footer",
          item?.counterCartItem?.ticketId,
          {},
          {},
          {},
          !item?.productDetails?.productMetadata?.termsAndConditions ||
          item?.productDetails?.productMetadata?.termsAndConditions[0] === ""
            ? null
            : item?.productDetails?.productMetadata?.termsAndConditions
                ?.map((item, i) => {
                  return `<p style="margin:0" key=${i}>${item}</p>`;
                })
                .join(" "),
          item?.counterCartItem?.createdAt,
        ],
      ];
    });
    setArray([...schemaBody]);
  };

  const getDonationSevaList = async () => {
    setLoading(true);
    try {
      let res = await TmsDonationService.fetchDonationBookingSlots("petty");
      setSevaList(res);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const clearLoadings = () => {
    setLoading(false);
    setButtonLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setButtonLoading(true);
    if (!selectedDonationId) {
      notification.error({ message: "Please select a valid donation" });
      clearLoadings();
      return;
    }
    if (devoteeName !== "") {
      if (devoteeName.length < 4) {
        notification.error({ message: "Name must be more than 4 letters" });
        clearLoadings();

        return;
      }
    }
    let donation = sevaList.find((e) => e?.product?.id === selectedDonationId);

    if (amount < donation?.product?.price) {
      notification?.error({
        message: `Amount cannot be less than minimum amount of Rs.${donation?.product?.price}`,
      });
      clearLoadings();
      return;
    }

    if (donation?.product?.maxAmount && amount > donation?.product?.maxAmount) {
      notification?.error({
        message: `Amount cannot be more than maximum amount of Rs.${donation?.product?.maxAmount}`,
      });
      clearLoadings();
      return;
    }

    if (phone !== "") {
      if (phone.length !== 10) {
        notification.error({ message: "Phone number must be 10 digits" });
        clearLoadings();
        return;
      }
    }

    try {
      let body = {
        donationId: selectedDonationId,
        amount: Number(amount),
      };
      if (phone !== "") {
        body.contact = `+91${phone}`;
      }
      if (devoteeName !== "") {
        body.devoteeName = devoteeName;
      }
      if (address !== "") {
        body.address = address;
      }
      if (gothram !== "") body.gothram = gothram;

      let res = await TmsDonationService.donationBooking(body, "CASH", "", "");
      notification.success({ message: "Success" });
      DoantionData(res);
      clearFields();
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      clearLoadings();
    }
  };

  const clearFields = () => {
    setPhone("");
    setSelectedDonationId("");
    setAmount("");
    setDevoteeName("");
    setMaxAmount("");
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            maxWidth: "1300px",
            width: "100%",
            background: "white",
            padding: "30px 50px",
          }}
        >
          <Row justify="space-between">
            <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>
                Booking Date
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <DatePicker
                value={moment(new Date())}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                defaultValue={moment(new Date())}
                disabled={true}
              />
            </Col>
            <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>
                Select Donation Type
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <Select
                size="medium"
                style={{ width: "100%" }}
                showSearch
                placeholder="Select Donation Type"
                optionFilterProp="children"
                value={selectedDonationId}
                onChange={(e) => {
                  setSelectedDonationId(e);
                  setMaxAmount(
                    sevaList.find((item) => item.product.id === e)?.product
                      ?.maxAmount
                  );
                }}
              >
                {sevaList?.map((item) => {
                  return (
                    <Option key={item?.product?.name} value={item?.product?.id}>
                      {item?.product?.name} - ( Min -Rs.
                      {item?.product?.price} - Max Rs.{" "}
                      {item?.product?.maxAmount ?? "-"})
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <br />
          <Row justify="space-between">
            <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>Devotee Name</label>
              <br />
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter a Name"
                value={devoteeName}
                onChange={(e) => {
                  setDevoteeName(e.target.value);
                }}
              />
            </Col>
            <Col style={{ width: "48%" }}>
              <label style={labelStyles}>Mobile Number</label>
              <br />
              <Input
                size="medium"
                min="0"
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                maxLength={10}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                addonBefore={"+91"}
                placeholder="Please Enter Mobile Number"
                style={{ width: "100%" }}
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  }
                  setPhone(`${e.target.value}`);
                }}
              />
            </Col>{" "}
          </Row>
          <Row justify="space-between" style={{ marginTop: "15px" }}>
            <Col style={{ width: "48%" }}>
              <label style={labelStyles}>Gothram</label>
              <br />
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter a Gothram"
                value={gothram}
                onChange={(e) => {
                  setGothram(e.target.value);
                }}
              />
            </Col>

            <Col style={{ width: "48%" }}>
              <label style={labelStyles}>Address </label>
              <br />
              <Input
                maxLength={220}
                type="text"
                placeholder="Enter address"
                value={address}
                onKeyDown={(e) =>
                  ["#", "+"].includes(e.key) && e.preventDefault()
                }
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row justify="space-between">
            <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>
                Amount
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <Input
                maxLength={220}
                type="number"
                min="0"
                onWheel={(event) => event.currentTarget.blur()}
                size="medium"
                onkeydown={(e) => {
                  return e.keyCode !== 69;
                }}
                style={{ textTransform: "none" }}
                placeholder="Amount"
                value={amount}
                defaultValue={amount}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault();
                }}
                onChange={(e) => {
                  if (maxAmount >= e.target.value) setAmount(e.target.value);
                }}
              />
            </Col>
            <Col style={{ width: "48%", justifyContent: "start" }}>
              <label style={labelStyles}>
                Select Payment Type
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </label>
              <br />
              <Select
                size="medium"
                style={{ width: "100%" }}
                placeholder="Select payment type"
                optionFilterProp="children"
                value={payment}
                onChange={(e) => {
                  setPayment(e);
                }}
              >
                <Option key="CASH" value="CASH">
                  Cash
                </Option>
                {/* <Option key="UPI" value="UPI">
                  UPI
                </Option> */}
                <Option key="CARD" value="CARD">
                  Card
                </Option>
              </Select>
            </Col>
          </Row>
          <br />
          <Row
            style={{
              height: "50px",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: "23px",
                textAlign: "left",
              }}
            >
              Total Amount : <b>Rs. {Number(amount ?? 0)}.00</b>
            </p>
          </Row>
          <Row>
            <Col
              style={{
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  buttonLoading === false && handleSubmit();
                }}
                loading={buttonLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <Modal
        visible={isModalVisible}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="donation"
          from="Counter"
          setData={setArray}
          data={array}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setButtonLoading}
          setPrintTicketModal={setIsModalVisible}
          printType={"thermal"}
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosPettyDonation);
