import { Button, Col, Input, Modal, notification, Row } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";
import TmsPublicationService from "../../../../services/tms-publications.service";

import TmsPrintTicket from "../../../../components/tms-print-ticket/tms-print-ticket";
const TmsReportPublicationReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [excelLoading, setExcelLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("success");
  const [labelLoading, setLabelLoading] = useState(false);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [publicationId, setPublicationId] = useState("");
  const [publicationList, setProductList] = useState([]);
  const [tableData, setTableData] = useState("");
  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);
  const [bookingType, setBookingType] = useState("pos");
  const [modal, setModal] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);

  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);

  const API = {
    online: TmsPublicationService.publicationOnlineReport,
    pos: TmsPublicationService.publicationReport,
  };

  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await API[bookingType]({
        format: "json",
        tableCurrentPage: Number(tableCurrentPage),
        tablePageSize: Number(tablePageSize),
        fromDate: bookingFromDate,
        toDate: bookingToDate,
        sortType: "DESC",
        counter,
        shift,
        publicationId,
        paymentStatus,
      });
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    // if (sorter.field === "createdDate") {
    //   if (sorter.order === "ascend") {
    //     setSortType("ASC");
    //   } else if (sorter.order === "descend") {
    //     setSortType("DESC");
    //   }
    // }
  };
  const fetchProductList = async () => {
    try {
      let res = await TmsPublicationService.getPublications(1, 500);
      setProductList(res.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const handleExcelExport = async (type) => {
    type ? setLabelLoading(true) : setExcelLoading(true);

    try {
      let data = await API[bookingType]({
        format: "json",
        tableCurrentPage: Number(tableCurrentPage),
        tablePageSize: Number(tablePageSize),
        fromDate: bookingFromDate,
        toDate: bookingToDate,
        sortType: "DESC",
        counter,
        shift,
        publicationId,
        paymentStatus,
      });
      if (data?.items.length === 0) {
        notification.info({ message: "No Data" });
        type ? setLabelLoading(true) : setExcelLoading(true);
      }
      data = data?.items?.reverse();
      let compiledDataOffline = [];
      let compiledDataOnline = [];
      if (bookingType === "online") {
        compiledDataOnline = data?.map((item, i) => {
          const commonProperties = {
            "S.no": i + 1,
            Devotee: item?.devoteeName ?? "-",
            Phone: item?.contact === null ? "-" : item?.contact,
            Address: `${item?.address ?? ""} ${item?.address2 ?? ""} \n ${
              item.city ?? ""
            }  ${item.state ?? ""} \n ${item?.country ?? ""} ${item?.pinCode}`,
            Publication: item?.publicationName,
            Books: item.subscriptionDetails
              .map((item, i) => {
                return `${i + 1}.${item.name} -(${item.month}/${
                  item.year
                }) - (${item.type})\n`;
              })
              .join("/n "),
          };

          return type === "label"
            ? {
                ...commonProperties,
              }
            : {
                ...commonProperties,
                "Booked on": moment(item?.createdAt).format(
                  "DD-MM-YYYY hh:mm A"
                ),
                "Booking phone": item?.contact,
                "Payment ID": item?.onlinePaymentId,
                "Booking ID": item?.bookingId,
                "Order ID":
                  item?.onlinePaymentType === "razorpay"
                    ? item?.razorPayOrderId
                    : item?.ccavenueOrderId,
                "Payment gateway": item?.onlinePaymentType,
                "Payment Status": item?.paymentStatus,
              };
        });
      } else {
        compiledDataOffline = data?.map((item, i) => {
          const commonProperties = {
            "S.no": i + 1,
            Name: item?.devoteeName ?? "-",
            Phone: item?.contact === null ? "-" : item?.contact,
            Address: `${item?.address ?? ""} ${item?.address2 ?? ""} \n ${
              item.city ?? ""
            } ${item.state ?? ""} \n ${item?.country ?? ""} ${
              item?.pinCode ?? ""
            }`,
            "Publication ": item?.publicationName,
            Books: item.subscriptionDetails
              .map((item, i) => {
                return type !== "label"
                  ? `${i + 1}.${item.name} -(${item.month}/${item.year}) - (${
                      item.type
                    })\n`
                  : `${i + 1}.${item.name}\n`;
              })
              .join("/n "),

            "Booked on": moment(item?.createdAt).format("DD-MM-YYYY hh:mm A"),
          };

          return type === "label"
            ? {
                ...commonProperties,
              }
            : {
                ...commonProperties,
                "Booking ID": item?.bookingId,
                "Transaction Id": item.categoryTransactionSequence
                  ?.split("-")
                  ?.pop(),
                "Booking Id": item.bookingId,
                "Serial No": item.dailyCategoryTransactionSequence
                  ?.split("-")
                  ?.pop(),
                "Payment ID": item?.onlinePaymentId,
                "Order ID":
                  item?.onlinePaymentType === "razorpay"
                    ? item?.razorPayOrderId
                    : item?.ccavenueOrderId,
                "Payment gateway": item?.onlinePaymentType,
                "Payment Status": item?.paymentStatus,
                "Counter Name": item?.counterName,
                "Counter User": item?.userName,
              };
        });
      }
      if (type === "label") {
        setLabelLoading(false);
        return bookingType === "online"
          ? compiledDataOnline
          : compiledDataOffline;
      }
      exportExcel(
        bookingType === "online" ? compiledDataOnline : compiledDataOffline,
        `${
          type === "label"
            ? "publication-label-report"
            : `publication-report-${bookingType}`
        }-${moment().format("DD-MM-YYYY")}`,
        type ? setLabelLoading : setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      type ? setLabelLoading(false) : setExcelLoading(false);
    }
  };
  useEffect(() => {
    fetchProductList();
    fetchCounterList();
    fetchShiftList();
  }, []);
  useEffect(() => {
    fetchBookingDetails();
  }, [tableCurrentPage, tablePageSize, bookingType]);

  const renderTable = async () => {
    const data = await handleExcelExport("label");

    const generalBody = data.map((item) => {
      return Object.entries(item)
        .filter(([key]) => key !== "S.no")
        .map(([key, value]) => {
          return [key, value, { margin: "0" }];
        });
    });

    setTableData(...generalBody);
  };

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "POS Name",
      dataIndex: "counterName",
      key: "counterName",
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Shift Id",
      dataIndex: "counterShiftTimingId",
      key: "counterShiftTimingId",
    },
    {
      title: "Booking Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Txn Id",
      dataIndex: "categoryTransactionSequence",
      key: "categoryTransactionSequence",
    },
    {
      title: "Product Name",
      dataIndex: "publicationName",
      key: "publicationName",
      ...getColumnSearchProps("publicationName"),
    },
    {
      title: "Products",
      dataIndex: "subscriptionDetails",
      key: "subscriptionDetails",
      render: (record) => {
        return record.map((item, i) => {
          return `${i + 1}.${item.name} -(${item.month}/${item.year}) - (${
            item.type
          })\n`;
        });
      },
    },
    {
      title: "Booking Date",
      dataIndex: "createdAt",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY");
      },
    },
    {
      title: "Total Price",
      dataIndex: "subscriptionDetails",
      key: "subscriptionDetails",
      render: (record) => {
        let price = 0;
        record.forEach((element) => {
          price += element.price + element.gstAmount + element.deliveryPrice;
        });
        return price;
      },
    },

    // {
    //   title: "Order Date",
    //   dataIndex: "createdDate",
    //   key: "createdDate",
    //   render: (text) => {
    //     return moment(text).format("DD-MM-YYYY");
    //   },
    //   defaultSortOrder: "descend",
    //   sorter: (a, b) => a.createdDate > b.createdDate,
    // },
  ];
  const onlineColumns = [];

  return (
    <>
      <Row gutter={[20, 20]}>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            { id: "online", name: "online" },
          ]}
          optional={true}
        />
        <TmsSelect
          type="col"
          span={3}
          selectClass="w-100"
          label="Publication Name"
          setValue={(e) => {
            setPublicationId(e);
          }}
          value={publicationId}
          data={publicationList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="POS"
          setValue={setCounter}
          value={counter}
          data={counterList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shift"
          setValue={setShift}
          value={shift}
          data={shiftList}
        />

        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        {bookingType === "online" && (
          <>
            {" "}
            <TmsSelect
              type="col"
              span={3}
              selectClass="w-100"
              label="Payment Status"
              setValue={setPaymentStatus}
              value={paymentStatus}
              data={[
                { id: "created", name: "created" },
                { id: "success", name: "success" },
                { id: "failure", name: "failure" },
                { id: "refund", name: "refund" },
                { id: "canceled", name: "canceled" },
              ]}
              optional={true}
            />
          </>
        )}
        <Col>
          <Row justify="start" style={{ marginBottom: "5px" }}>
            Update
          </Row>
          <Button type="primary" onClick={fetchBookingDetails}>
            Fetch
          </Button>
        </Col>
        <TmsExcelButton
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={true}
        />
        <TmsExcelButton
          span={24}
          label="Label Print"
          loading={labelLoading}
          handleExcelExport={async () => {
            setLabelLoading(true);
            await renderTable();
            setModal(true);
            setExternalPrint(true);
          }}
          top={true}
        />
      </Row>

      <br />
      <Row>
        <TmsTable
          columns={bookingType === "online" ? onlineColumns : columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>

      <Modal
        visible={modal}
        width={850}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="label-print"
          from="Counter"
          data={tableData}
          setData={setTableData}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setLabelLoading}
          setPrintTicketModal={setModal}
          ticketType={"label-print"}
        />
      </Modal>
    </>
  );
};

export default withRouter(TmsReportPublicationReport);
