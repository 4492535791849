import React from "react";
import useBuildingsStore from "../../../../zustand/buildings-and-shops.store";
import TmsSelect from "../../tms-select";

const BuildingLeaseSelect = ({ span }) => {
  const {
    createdBuildingLeaseData,
    setSelectedBuildingLease,
    selectedBuildingLease,
  } = useBuildingsStore((state) => {
    return {
      createdBuildingLeaseData: state.createdBuildingLeaseData,
      setSelectedBuildingLease: state.setSelectedBuildingLease,
      selectedBuildingLease: state.selectedBuildingLease,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select a Lease No"
      setValue={(e) => {
        setSelectedBuildingLease(
          createdBuildingLeaseData?.items?.find((item) => item.id === e)
        );
      }}
      selectClass="w-100"
      value={selectedBuildingLease?.id}
      data={createdBuildingLeaseData?.items?.map((item) => {
        return {
          name: `LeaseID: ${item.id}, ${
            item.shop ? `Shop.no: ${item?.shop?.shopNumber}` : ``
          }`,
          id: item.id,
        };
      })}
      optional={true}
    />
  );
};

export default BuildingLeaseSelect;
