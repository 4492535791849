// import {notification} from "antd";
import React from "react";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
  Text,
} from "recharts";
import "../../assets/css/tms-dashboard/tms-chart.scss";
import { FormatNumberINRCurrency } from "../../utils/tms-currency-formatter/tms-curreny-formatter";
const TmsChart = ({ update, dataCollection }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // console.log(label, payload);
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="Collection">
            {FormatNumberINRCurrency(payload[0].value)}
          </p>
          <p className="Collection">
            {payload[0].payload.type === "darshanam"
              ? "Devotee Count"
              : payload[0].payload.type === "prasadam"
              ? `${label} Count`
              : payload[0].payload.type === "tollgate"
              ? "Total Vehicles"
              : "Count"}
            : {payload[0].payload.total}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    if (payload && payload.value) {
      return (
        <Text
          style={{ fontWeight: 400, fontSize: "14px" }}
          width={"15px"}
          x={x}
          y={y}
          textAnchor="middle"
          verticalAnchor="start"
        >
          {payload.value}
        </Text>
      );
    }
    return null;
  };
  return (
    <div style={{ width: "100%", height: "350px", position: "relative" }}>
      {
        <>
          {update ? (
            <p
              style={{
                margin: "0px",
                textAlign: "left",
                position: "absolute",
                right: "30px",
                marginTop: "-6px",
              }}
            >
              Last Updated at
              {moment(update).format("HH:mm:ss DD-MM-YYYY")}
            </p>
          ) : (
            ""
          )}
        </>
      }
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={320}
          data={dataCollection}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={40}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            allowDataOverflow={false}
            dataKey="bookingType"
            // interval={0}
            // angle={-25}
            // height={60}
            // tickMargin={25}
            tick={<CustomXAxisTick />}
          />
          <YAxis
            tickFormatter={(value) =>
              new Intl.NumberFormat("en-in", {
                notation: "compact",
                compactDisplay: "short",
              }).format(value)
            }
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="collection"
            stackId="a"
            fill="#51459E"
            barSize={dataCollection.length > 6 ? 25 : 40}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default TmsChart;
