import React, { memo } from "react";
import { tmsLogo } from "../../assets/images/tms-image-list";
import "../../App.scss";
const TmsLoading = () => {
  return (
    <div className="loading-logo">
      {" "}
      <img src={tmsLogo} className="tms-login-logo" height="150px" />
      <p>Loading...</p>
    </div>
  );
};

export default memo(TmsLoading);
