import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsPublicationInnerPagesANP from "./tms-sm-publication-inner-pages/tms-publication-inner-pages-anp";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsPublicationInnerPagesAll from "./tms-sm-publication-inner-pages/tms-publication-inner-pages-all";
import TmsPublicationFileMangement from "./tms-sm-publication-inner-pages/tms-publication-file-mangement";

const TmsSmPublications = ({ router }) => {
  const [active, setActive] = useState("all");
  useEffect(() => {
    let location = router?.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_publications) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sm_publication_and)) {
      setActive("Add New Publication");
    } else if (location.includes(tmsRouteMap.sm_publication_manage_pos)) {
      setActive("all");
      return;
    }
  }, [router?.location?.pathname]);

  const location = router?.location?.pathname;
  const components = [
    {
      name: location.includes(tmsRouteMap.sm_publication_manage_pos)
        ? "Manage POS"
        : "All",
      Element: location.includes(tmsRouteMap.sm_publication_manage_files) ? (
        <TmsPublicationFileMangement />
      ) : location.includes(tmsRouteMap.sm_publication_manage_pos) ? (
        <TmsCmsSlots type="publication-counters" />
      ) : (
        <TmsPublicationInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_publications,
    },
    {
      name: "Add New Publication",
      Element: <TmsPublicationInnerPagesANP />,
      key: "Add New Publication",
      route: tmsRouteMap.sm_publication_and,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmPublications);
