import { Button, Card, Col, Input, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsImageUpload from "../../../../../components/tms-customs/tms-image-upload";
import TmsPublicationService from "../../../../../services/tms-publications.service";
const TmsPublicationFMANF = ({
  setUpdateFlag,
  viewProductData,
  editFlag,
  productId,
  shopProductId,
  setEditFlag,
  settmsModalVisible,
  width,
  addFlag,
  setAddFlag,
  router,
}) => {
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [productName, setProductName] = useState("");
  const [languages, setLanguages] = useState("");
  const [price, setprice] = useState();
  const [fileList, setFileList] = useState([]);
  const [type, setType] = useState("");
  const [sendFrequencyMonths, setSendFrequencyMonths] = useState("");
  const [subscriptionPeriodInYears, setSubscriptionPeriodInYears] =
    useState("");
  const [gstAmount, setGstAmount] = useState(0);
  const languageData = [
    "telugu",
    "hindi",
    "kannada",
    "tamil",
    "english",
    "malayalam",
  ];

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const handleSubmit = async () => {
    setLoading(true);
    if (productName === "" || type === "" || price === "" || languages === "") {
      notification.error({
        message: "Please make sure you fill all the fields.",
      });
      setLoading(false);
      return;
    }
    if (type !== "SUBSCRIPTION") {
      if (year === "" || month === "") {
        notification.error({
          message: "Please make sure you fill all the fields.",
        });
        setLoading(false);
        return;
      }
    }
    if (type === "SUBSCRIPTION") {
      if (
        subscriptionPeriodInYears === "" ||
        sendFrequencyMonths === "" ||
        deliveryFee === ""
      ) {
        notification.error({
          message: "Please make sure you fill all the fields.",
        });
        setLoading(false);
        return;
      }
    } else if (type === "DOWNLOAD") {
      if (fileList?.length === 0) {
        notification.error({
          message: "Please make sure you fill all the fields.",
        });
        setLoading(false);
        return;
      }
    }
    let shopID = new URLSearchParams(router.location.search).get("id");
    let body = {
      publicationId: shopProductId ? parseInt(shopProductId) : parseInt(shopID),
      language: [languages],
      type: type,
      name: productName,
      price: price,
      gstAmount: gstAmount,
    };

    if (type === "SUBSCRIPTION") {
      body.sendFrequencyMonths = sendFrequencyMonths;
      body.subscriptionPeriodInYears = subscriptionPeriodInYears;
    } else {
      body.year = year;
      body.month = month;
    }

    if (type === "DOWNLOAD") {
      body.pdfUrl = fileList[0]?.url;
    } else if (type !== "DOWNLOAD") {
      body.deliveryPrice = deliveryFee;
    }

    try {
      if (editFlag) {
        await TmsPublicationService.editPublicationFile(productId, body);
        // setDarshanamData(darData);
        notification.success({ message: "File Edited Successful" });

        handleFuction();
      } else {
        await TmsPublicationService.createPublicationFile(body);
        // setDarshanamData(data?.items);
        notification.success({ message: "File Created Successful" });
        handleFuction();
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    setDeliveryFee(0);
    setProductName("");
    setMonth("");
    setYear("");
    setprice("");
    setFileList([]);
    setType("");
    setLanguages([]);
    setSubscriptionPeriodInYears("");
    setSendFrequencyMonths("");
    setDeliveryFee("");
    setGstAmount("");
    setAddFlag(false);
    setLoading(false);
    setUpdateFlag(Math.random() * 1000 + 99);
  };

  useEffect(() => {
    if (editFlag) {
      setType(viewProductData?.type);
      setProductName(viewProductData?.name);
      setYear(viewProductData?.year);
      setMonth(viewProductData?.month);
      setprice(viewProductData?.price);
      setFileList([{ url: viewProductData?.imageUrl, status: "done" }]);
      setDeliveryFee(viewProductData?.deliveryPrice);
      setLanguages(viewProductData?.language[0]);
      setGstAmount(viewProductData?.gstAmount);
    }
  }, [viewProductData]);

  return (
    <Row className="formRow" style={{ width: "100%", minWidth: "500px" }}>
      <Card style={{ width: width ? "100%" : "60%", minWidth: "500px" }}>
        <Row className="formRow">
          <label className="formLabel">Product Name</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel"> Type</label>
          <Select
            className="formInput"
            onChange={(e) => {
              setType(e);
            }}
            value={type}
            defaultValue={type}
          >
            <Select.Option value="SUBSCRIPTION">Subscription</Select.Option>
            <Select.Option value="DOWNLOAD">Download</Select.Option>
            <Select.Option value="PURCHASE">Purchase</Select.Option>
          </Select>
        </Row>

        <Row className="formRow">
          <label className="formLabel">Languages</label>
          <Select
            allowClear
            className={"formInput"}
            dropdownClassName="sd-select-dropdown-r"
            value={languages}
            onChange={(e) => {
              setLanguages(e);
            }}
            placeholder={`Select Languages`}
          >
            {languageData?.map((item) => {
              return (
                <Select.Option key={item} value={item}>
                  <span style={{ textTransform: "capitalize" }}> {item}</span>
                </Select.Option>
              );
            })}
          </Select>
        </Row>

        {type !== "SUBSCRIPTION" && (
          <>
            {" "}
            <Row className="formRow">
              <label className="formLabel">Month</label>
              <Select
                className="formInput"
                onChange={(e) => {
                  setMonth(e);
                }}
                value={month}
                defaultValue={month}
              >
                {months?.map((item, i) => {
                  return (
                    <Select.Option key={item} value={i + 1}>
                      {item} - {i + 1}
                    </Select.Option>
                  );
                })}
              </Select>
            </Row>
            <Row className="formRow">
              <label className="formLabel">Year</label>
              <Input
                maxLength={220}
                className="formInput"
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                value={year}
                onChange={(e) => setYear(Number(e.target.value))}
              />
            </Row>
          </>
        )}

        <Row className="formRow">
          <label className="formLabel">Price</label>

          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={price}
            onChange={(e) => setprice(Number(e.target.value))}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">GST Amount</label>

          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={gstAmount}
            onChange={(e) => setGstAmount(Number(e.target.value))}
          />
        </Row>
        {type === "SUBSCRIPTION" && (
          <>
            <Row className="formRow">
              <label className="formLabel">Subscription Period(Yrs)</label>

              <Input
                maxLength={220}
                className="formInput"
                placeholder="Period in Years"
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                value={subscriptionPeriodInYears}
                onChange={(e) =>
                  setSubscriptionPeriodInYears(Number(e.target.value))
                }
              />
            </Row>
            <Row className="formRow">
              <label className="formLabel">Delivery Frequency</label>

              <Input
                maxLength={220}
                className="formInput"
                placeholder="Delivery (Months)"
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                value={sendFrequencyMonths}
                onChange={(e) => setSendFrequencyMonths(Number(e.target.value))}
              />
            </Row>
          </>
        )}

        {type === "DOWNLOAD" ? (
          <Row className="formRow">
            <label className="formLabel">PDF URL</label>
            <Col className="formInput ">
              <TmsImageUpload
                fileList={fileList}
                setFileList={setFileList}
                selectedClass="w-100"
                accept={true}
              />
            </Col>
          </Row>
        ) : (
          <Row className="formRow">
            <label className="formLabel">Delivery Fee</label>
            <Input
              maxLength={220}
              type="number"
              min="0"
              className="formInput"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={deliveryFee}
              onChange={(e) => setDeliveryFee(Number(e.target.value))}
            />
          </Row>
        )}

        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                if (editFlag) {
                  setEditFlag(false);
                }
                if (addFlag) {
                  setAddFlag(false);
                }
                settmsModalVisible(false);
                handleFuction();
              }}
            >
              {editFlag || addFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsPublicationFMANF);
