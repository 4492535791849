import {
  Button,
  Col,
  Divider,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import {
  additionalShopArrearStates,
  arrearShopStates,
} from "../../../../../../constants/states-data.data";
import useBuildingsStore from "../../../../../../zustand/buildings-and-shops.store";
import {
  BillAdjustment,
  BuildingInstitutionSelect,
  BuildingSelect,
  LeaseInvoice,
  SortTypeAndBy,
} from "../../../../../../components/tms-customs/rents-and-leases";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import TmsModal from "../../../../../../components/tms-customs/tms-modal";
import "../../../../../../assets/css/tms-common/tms-rents.scss";
import { FormatNumberINRCurrency } from "../../../../../../utils/tms-currency-formatter/tms-curreny-formatter";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";
const TmsSmBasArrearList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState({});
  const [view, setView] = useState(false);
  const [adjustedAmount, setAdjustedAmount] = useState("");
  const [adjustedAmountRemarks, setAdjustedAmountRemarks] = useState("");
  const refContainer = useRef(null);

  const {
    deleteLoading,
    selectedInstitution,
    loading,
    clearStates,
    fetchInstitutionList,
    fetchArrears,
    createdArrearsData,
    fetchCreatedBuildingsList,
    handleArrearUpdate,
    selectedBlock,
    selectedBuilding,
    handleArrearBillUpdate,
    fetchLeaseInvoices,
    deleteCreatedShopLease,
    excelLoading,
    handleExcelExport,
  } = useBuildingsStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  useEffect(() => {
    router.location.pathname === tmsRouteMap.sm_ral_buildings_arears_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    return () => {
      clearStates(arrearShopStates, additionalShopArrearStates);
    };
  }, []);

  useEffect(() => {
    if (selectedInstitution && selectedInstitution !== "")
      fetchCreatedBuildingsList(1, 200, "ASC", "createdAt", router);
  }, [selectedInstitution]);

  useEffect(() => {
    if (
      selectedInstitution !== "" &&
      selectedInstitution &&
      selectedBuilding &&
      selectedBuilding !== ""
    ) {
      1, 200, "ASC", "createdAt", router;
    }
  }, [selectedInstitution, selectedBuilding]);

  useEffect(() => {
    if (
      selectedInstitution !== "" &&
      selectedInstitution &&
      selectedBuilding?.id
    )
      fetchArrears(tableCurrentPage, tablePageSize, sortType, sortBy, router);
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedBlock,
    selectedBuilding,
    update,
    selectedInstitution,
  ]);

  const handleUpdate = async (record, status) => {
    const records = { ...record, status: status };
    await handleArrearUpdate(records, router);
    setUpdate(Math.random() * 100000 + 21);
  };

  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "tms-lease-container",
    onAfterPrint: () => {
      setView(false);
    },
  });

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <BuildingInstitutionSelect span={5} />
        <BuildingSelect span={5} />
        <SortTypeAndBy
          span={5}
          sortBy={sortBy}
          sortType={sortType}
          setSortBy={setSortBy}
          setSortType={setSortType}
        />{" "}
        {selectedBuilding !== "" && selectedBuilding && (
          <Col style={{ padding: "0" }}>
            <p style={{ paddingBottom: "4px" }}>Download</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() => handleExcelExport("arrear", router)}
              top={false}
            />
          </Col>
        )}
      </Row>
      <br />
      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          {
            title: "Building Name",
            dataIndex: "building",
            render: (record) => record?.buildingName,
          },
          {
            title: "Block Name",
            dataIndex: "blockOrFloor",
            render: (record) => record?.blockOrFloorName,
          },
          {
            title: "Shop Number",
            dataIndex: "shop",
            render: (record) => record?.shopNumber ?? "-",
          },

          {
            title: "Name of Lessee",
            dataIndex: "shopLease",
            render: (record) => record?.lesseName,
          },
          {
            title: "Lessee type",
            dataIndex: "shopLease",
            render: (record) => record?.lesseType,
          },
          {
            title: "Lease Amount",
            dataIndex: "shopLease",
            render: (record) => FormatNumberINRCurrency(record?.leaseRate),
          },

          {
            title: "Due Date",
            dataIndex: "dueDate",
            render: (record) => record ?? "-",
          },

          {
            title: "Arrear Amount",
            dataIndex: "arrearAmount",
            render: (record) => FormatNumberINRCurrency(record),
          },
          {
            title: "Interest Amount",
            dataIndex: "interestAmount",
            render: (record) => FormatNumberINRCurrency(record),
          },

          {
            title: "Status",
            key: "status",
            render: (record) => {
              return (
                <Switch
                  size={"small"}
                  checked={record.status}
                  onClick={() => {
                    handleUpdate(record, !record.status);
                  }}
                />
              );
            },
          },
          {
            title: "Actions",
            key: "status",
            render: (record) => {
              return (
                <Space>
                  {/* {record.lastInvoiceGeneratedOn === null ? (
                    ""
                  ) : ( */}
                  <a
                    onClick={async () => {
                      try {
                        let records = await fetchLeaseInvoices(
                          record.id,
                          true,
                          1,
                          200,
                          "ASC",
                          "createdAt",
                          router
                        );
                        if (!records?.id) {
                          notification.error({
                            message: "No latest invoices are generated",
                          });
                          return;
                        } else {
                          setView(true);
                          setEditData(records);
                        }
                      } catch (e) {
                        setView(false);
                        notification.error({
                          message: "No latest invoices",
                        });
                        setEditData({});
                      }
                    }}
                  >
                    Invoice
                  </a>{" "}
                  <Divider type="vertical" />
                  <a
                    onClick={() => {
                      setEditFlag(true);
                      setEditData(record);
                    }}
                  >
                    Bill Adjustment
                  </a>{" "}
                  <Divider type="vertical" />
                  <Space size="middle">
                    <Popconfirm
                      title="Are you sure delete this lease?"
                      onConfirm={async () => {
                        await deleteCreatedShopLease(record.id, router);
                        setUpdate(Math.random() * 100000 + 21);
                      }}
                      okText="Yes"
                      cancelText="No"
                      loading={deleteLoading}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Space>{" "}
                </Space>
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={createdArrearsData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdArrearsData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
      <TmsModal
        visible={view}
        width={"50%"}
        onOk={() => {
          setView(false);
          setEditData({});
        }}
        closable={true}
        centered
        onCancel={() => {
          setView(false);
          setEditData({});
        }}
      >
        <Row justify={"center"}>
          <Button onClick={handlePrint} type="primary">
            Print
          </Button>
        </Row>
        <br />
        <LeaseInvoice data={editData} type="shop-lease" scantype={"payment"} />
      </TmsModal>
      <BillAdjustment
        type="shop-lease"
        editFlag={editFlag}
        setEditFlag={setEditFlag}
        loading={loading}
        editData={{
          buildingName: editData?.building?.buildingName,
          blockName: editData?.blockOrFloor?.blockOrFloorName,
          shopNumber: editData?.shop?.shopNumber,
          lesseName: editData?.shopLease?.lesseName,
          lesseType: editData?.shopLease?.lesseType,
          leaseAmount: editData?.shopLease?.leaseRate,
          arrearAmount: editData?.arrearAmount,
          arrearId: editData?.id,
          shopLeaseId: editData?.shopLeaseId,
        }}
        setEditData={setEditData}
        adjustedAmount={adjustedAmount}
        setAdjustedAmount={setAdjustedAmount}
        adjustedAmountRemarks={adjustedAmountRemarks}
        setAdjustedAmountRemarks={setAdjustedAmountRemarks}
        handleArrearBillUpdate={handleArrearBillUpdate}
        setUpdate={setUpdate}
        router={router}
      />
    </>
  );
};

export default withRouter(TmsSmBasArrearList);
