import React from "react";
import TmsTable from "../../tms-customs/tms-table";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { Col, Row, Space } from "antd";
import { FormatNumberAllCurrency } from "../../../utils/tms-currency-formatter/tms-curreny-formatter";

const TmsParakamaniTable = ({
  dataSource,
  loading,
  handleTableChange,
  pagination,
  setRecord,
  setModalVisible,
  flag,
  scroll,
}) => {
  const columns = [
    {
      title: "S.no",
      flag: flag,
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Parakamani Date",
      flag: true,
      dataIndex: "parakamaniDate",
      key: "parakamaniDate",
      render: (text) => {
        return <span>{moment(text)?.format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: "Installed Date",
      flag: true,
      dataIndex: "installedDate",
      key: "installedDate",
      render: (text) => {
        return <span>{moment(text)?.format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: "Opened Date",
      flag: true,
      dataIndex: "openedDate",
      key: "openedDate",
      render: (text) => {
        return <span>{moment(text)?.format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: "Period",
      flag: true,
      dataIndex: "hundiPeriod",
      key: "hundiPeriod",
      render: (text) => {
        return <span>{text} days</span>;
      },
    },

    {
      title: "Hundis",
      flag: true,
      dataIndex: "totalInstalledHundis",
      key: "totalInstalledHundis",
    },
    {
      title: "Classification",
      flag: true,
      dataIndex: "classification",
      key: "classification",
    },
    {
      title: "Country",
      flag: true,
      key: "countryName",
      render: (text, record) => {
        return (
          <>
            {Object.keys(record?.totalAmount).map((key, i) => {
              return (
                <Row key={i}>
                  <Col>
                    <b>{record?.totalAmount[key]?.countryName}</b>
                  </Col>
                </Row>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Valid Amount",
      flag: true,
      key: "totalInstalledHundis",
      render: (text, record) => {
        return (
          <>
            {Object.keys(record?.totalAmount).map((key, i) => {
              return (
                <Row key={i}>
                  <Col>
                    {FormatNumberAllCurrency(
                      Number(
                        record?.totalAmount[key].totalValidNotesValue +
                          record?.totalAmount[key].totalValidCoinsValue
                      ),
                      record?.totalAmount[key]?.countryName
                    )}
                    <br />
                  </Col>
                </Row>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Invalid Amount",
      flag: true,
      key: "totalInstalledHundis",
      render: (text, record) => {
        return (
          <>
            {Object.keys(record?.totalAmount).map((key, i) => {
              return (
                <Row key={i}>
                  <Col style={{ width: "100%" }}>
                    {FormatNumberAllCurrency(
                      Number(
                        record?.totalAmount[key].totalInvalidNotesValue +
                          record?.totalAmount[key].totalInvalidCoinsValue
                      ),
                      record?.totalAmount[key]?.countryName
                    )}
                    <br />
                  </Col>
                </Row>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Total Amount",
      flag: true,
      key: "totalInstalledHundis",
      render: (text, record) => {
        return (
          <>
            {Object.keys(record?.totalAmount).map((key, i) => {
              return (
                <Row key={i}>
                  <Col style={{ width: "100%" }}>
                    {FormatNumberAllCurrency(
                      Number(
                        record?.totalAmount[key].totalInvalidNotesValue +
                          record?.totalAmount[key].totalInvalidCoinsValue +
                          record?.totalAmount[key].totalValidNotesValue +
                          record?.totalAmount[key].totalValidCoinsValue
                      ),
                      record?.totalAmount[key]?.countryName
                    )}
                    <br />
                  </Col>
                </Row>
              );
            })}
          </>
        );
      },
    },
    {
      title: "MR Date",
      flag: true,
      dataIndex: "mrDate",
      key: "mrDate",
      render: (text) => {
        return <span>{moment(text)?.format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: "MR Number",
      flag: true,
      dataIndex: "mrNumber",
      key: "mrNumber",
    },
    {
      title: "",
      flag: flag,
      render: (text, record) => {
        return (
          <Space>
            <a
              onClick={() => {
                setRecord(record);
                setModalVisible(true);
              }}
            >
              {" "}
              <EyeOutlined />
            </a>
          </Space>
        );
      },
    },
  ].filter((item) => {
    return item?.flag;
  });
  return (
    <TmsTable
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      handleTableChange={handleTableChange}
      pagination={pagination}
      scroll={scroll}
    />
  );
};

export default TmsParakamaniTable;
