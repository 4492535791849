import { Col, Row, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import useRentsAndLandStore from "../../../../../../zustand/rents-and-leases.store";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import {
  InstitutionSelect,
  SortTypeAndBy,
  SurveyNoSelect,
} from "../../../../../../components/tms-customs/rents-and-leases";
import TmsExcelButton from "../../../../../../components/tms-customs/tms-excel-button";
const TmsSmRalEncroachmentList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");

  const {
    selectedInstitution,
    loading,
    clearStates,
    fetchInstitutionList,
    fetchEncroachments,
    fetchSurveyNumbers,
    selectedSurveyNumber,
    createdEncroachmentsData,
    handleChangeStatus,
    excelLoading,
    handleExcelExport,
  } = useRentsAndLandStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };
  useEffect(() => {
    return () => {
      clearStates(
        [
          "institutionData",
          "createdSurveryNumberData",
          "selectedInstitution",
          "createdEncroachmentsData",
          "selectedSurveyNumber",
        ],
        []
      );
    };
  }, []);
  useEffect(() => {
    router.location.pathname ===
      tmsRouteMap.sm_ral_lands_enchroament_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution) {
      fetchSurveyNumbers(1, 200, "ASC", "createdAt", router);
    }
  }, [selectedInstitution]);

  useEffect(() => {
    if (selectedSurveyNumber && selectedSurveyNumber !== "")
      fetchEncroachments(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router
      );
  }, [
    sortBy,
    tableCurrentPage,
    tablePageSize,
    sortType,
    selectedSurveyNumber,
    update,
  ]);

  const switchTrigger = async (record, status) => {
    await handleChangeStatus(record, status, router);
    setUpdate(Math.random() * 100000 + 21);
  };

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <InstitutionSelect span={5} />
        <SurveyNoSelect span={5} />
        <SortTypeAndBy
          span={5}
          sortType={sortType}
          setSortType={setSortType}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />{" "}
        {selectedSurveyNumber !== "" && selectedSurveyNumber && (
          <Col style={{ padding: "0" }}>
            <p style={{ visibility: "hidden", paddingBottom: "4px" }}>.</p>
            <TmsExcelButton
              loading={excelLoading}
              handleExcelExport={() =>
                handleExcelExport("encroachment", router)
              }
              top={false}
            />
          </Col>
        )}
      </Row>

      <Table
        className={" accomadation_table captalise-center mt-10 "}
        tableLayout="fixed"
        scroll={{ x: "auto" }}
        loading={loading}
        style={{
          width: "auto",
          textTransform: "capitalize",
          textAlign: "center !important",
        }}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          {
            title: "encroacher",
            dataIndex: "encroacherName",
          },
          {
            title: "Address",
            dataIndex: "encroacherAddress",
          },
          {
            title: "Survey No",
            dataIndex: "surveyNumber",
            render: (record) => record?.surveyNo,
          },
          {
            title: "BreakUp/Block No",
            dataIndex: "surveyNumberBreakup",
            render: (record) => record?.blockNumber,
          },
          {
            title: "Extent",
            dataIndex: "extent",
          },
          {
            title: "Encroached Acres",
            dataIndex: "encroachedAcres",
          },
          {
            title: "Encroached Cents",
            dataIndex: "encroachedCents",
          },
          {
            title: "legal Action",
            dataIndex: "legalAction",
            render: (record) => `${record ? "Yes" : "No"}`,
          },
          {
            title: "Legals",
            render: (record) => {
              return record.legalAction
                ? `Case no: ${record.caseNumber}`
                : `Reason: ${record.reason ?? "-"}`;
            },
          },
          {
            title: "Duration(Months)",
            dataIndex: "encroachmentDurationInMonths",
          },
          {
            title: "Duration(Days)",
            dataIndex: "encroachmentDurationInDays",
          },
          {
            title: "Status",
            key: "status",
            render: (record) => {
              return (
                <Switch
                  size={"small"}
                  checked={record.status}
                  onClick={() => {
                    switchTrigger(record?.id, !record.status);
                  }}
                />
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={createdEncroachmentsData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: createdEncroachmentsData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        // expandable={{
        //   expandedRowRender: (record) => {
        //     return (
        //       <p>
        //         {" "}
        //         Donor Doc: {record.donorDocumentType}-{" "}
        //         {record.donorDocumentNumber}, Donor Address:{" "}
        //         {record.donorAddress} - City: {record.donorCity}, State:{" "}
        //         {record.donorState}, Pincode: {record.donorPincode} Country:{" "}
        //         {record.donorCountry}
        //       </p>
        //     );
        //   },
        // }}
      ></Table>
    </>
  );
};

export default withRouter(TmsSmRalEncroachmentList);
