import React, { useEffect, useState } from "react";
import TmsEntryScanService from "../../../services/tms-entry-scan.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import { LoadingOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Radio, Row, notification } from "antd";
import {
  captureBiometric,
  checkConnection,
} from "../../../utils/tms-biometric/tms-biometric";
import { biometric } from "../../../assets/images/tms-image-list";

const TmsPosEntryScan = (router) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [biometricFlag, setBiometricFlag] = useState(false);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  const [selectedId, setSelectedId] = useState("");
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [captured, setCaptured] = useState(null);
  const [biometricLoading, setBiometricLoading] = useState(false);

  const clearStates = () => {
    setTimeout(() => {
      setCaptured(null);
    }, 700);
  };

  //--------------------biometric-----------------//
  const checkIfDeviceIsReady = async () => {
    try {
      const res = await checkConnection();
      if (res.data.ErrorCode === "0") {
        setDeviceInfo(res);
      } else {
        setDeviceInfo(null);
        notification.error({ message: res.data.ErrorDescription });
      }
    } catch (E) {
      catchErrorFunction(E, "Something went wrong with Device.", router);
    }
  };

  const scanFingerPrint = async () => {
    if (selectedId === "") {
      notification.error({ message: "Select Product" });
      return;
    }
    if (biometric) {
      if (deviceInfo?.data?.ErrorCode !== "0") {
        notification.error({ message: "Biometric Device Not Connected" });
        return;
      }
    }
    setBiometricLoading(true);
    try {
      const res = await captureBiometric();
      if (res.data.ErrorCode !== "0") {
        setCaptured(res.data);
        if (res.data.ErrorCode === "-1307") {
          checkIfDeviceIsReady();
        } else {
          notification.error({ message: res.data.ErrorDescription });
        }
      } else {
        setCaptured(res.data);
        book(res?.data?.IsoTemplate);
      }
      setBiometricLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Something went wrong with the device", router);
      setBiometricLoading(false);
    }
  };
  //--------------------biometric-----------------//

  const fetchProducts = async () => {
    setLoading(true);
    try {
      let res = await TmsEntryScanService.fetchEntryScanProducts();
      setList(res);
      setSelectedId(res[0]?.id);
      setBiometricFlag(res[0]?.isFingerPrintRequired);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const book = async (capture) => {
    let body = {
      productId: selectedId,
      fingerPrintTemplate: capture,
    };
    try {
      await TmsEntryScanService.bookEntryScanProducts(body);
      notification.success({ message: "Captured Successfully" });
      clearStates();
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (biometricFlag) {
      console.log(biometricFlag);

      setTimeout(() => {
        checkIfDeviceIsReady();
      }, 1000);
    }
  }, [biometricFlag, selectedId]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
          textAlign: "left",
        }}
      >
        {loading ? (
          <LoadingOutlined />
        ) : (
          <>
            <Row style={{ width: "100%" }} gutter={[20, 20]}>
              {list?.map((item, i) => {
                return (
                  <Col
                    key={i}
                    style={{
                      width: "25%",
                    }}
                  >
                    <Card
                      style={{
                        borderRadius: "20px",
                        border: `2px solid #${templeDetails?.colorCode}`,
                      }}
                      hoverable
                      onClick={() => {
                        setSelectedId(item?.id);
                        setBiometricFlag(item?.isFingerPrintRequired);
                      }}
                    >
                      <Row style={{ justifyContent: "space-between" }}>
                        {item?.name}
                        <Radio
                          defaultChecked={false}
                          value={item?.id}
                          checked={item?.id === selectedId ? true : false}
                          id={item?.id}
                          onClick={() => {
                            setSelectedId(item?.id);
                            setBiometricFlag(item?.isFingerPrintRequired);
                          }}
                        />
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <Divider />
            <Row>
              {biometricFlag && (
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: "18px",
                  }}
                >
                  Biometric Device :
                  <p
                    style={{
                      color: `${
                        deviceInfo?.data?.ErrorCode === "0" ? "green" : "red"
                      }`,
                      marginLeft: "10px",
                      fontWeight: "600",
                    }}
                  >
                    {deviceInfo?.data?.ErrorDescription === "Success"
                      ? "Connected"
                      : "Not Connected"}
                  </p>
                  {deviceInfo?.data?.ErrorCode !== "0" && (
                    <p
                      style={{
                        color: "skyblue",
                        textDecoration: "underline",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        checkIfDeviceIsReady();
                      }}
                    >
                      Refresh
                    </p>
                  )}
                </Col>
              )}
            </Row>
          </>
        )}
        {biometricFlag && (
          <Col style={{ width: "48%" }}>
            <Card
              className="fingerprint"
              hoverable
              onClick={() => {
                scanFingerPrint();
              }}
            >
              {biometricLoading ? (
                <img src={biometric} />
              ) : (
                <p
                  style={{
                    margin: "0",
                    color: `${
                      captured === null
                        ? "Black"
                        : captured?.ErrorCode === "0"
                        ? "Green"
                        : "Red"
                    }`,
                  }}
                >
                  {captured === null
                    ? "Start Capture"
                    : captured?.ErrorCode === "0"
                    ? "Captured"
                    : "Re-Capture"}
                </p>
              )}
            </Card>
          </Col>
        )}
        {!biometricFlag && (
          <Row style={{ color: "red" }}>
            Please enable Fingerprint in Service Management
          </Row>
        )}
      </div>
    </div>
  );
};

export default withRouter(TmsPosEntryScan);
