import React from "react";
import useFdrStore from "../../../../zustand/fdr.store";
import TmsSelect from "../../tms-select";

const InvestementSelect = ({ span }) => {
  const {
    setSelectedInvestmentType,
    selectedInvestmentType,
    investmentTypeData,
    // clearStates,
  } = useFdrStore((state) => {
    return {
      setSelectedInvestmentType: state.setSelectedInvestmentType,
      selectedInvestmentType: state.selectedInvestmentType,
      investmentTypeData: state.investmentTypeData,
      clearStates: state.clearStates,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select Investment Type"
      setValue={(e) => {
        setSelectedInvestmentType(
          investmentTypeData?.items?.find((item) => item.id === e)
        );
      }}
      selectClass="w-100"
      value={selectedInvestmentType?.id}
      data={investmentTypeData?.items?.map((item) => {
        return { name: item.investmentType, id: item.id };
      })}
      optional={true}
    />
  );
};

export default InvestementSelect;
