import { Button, Card, Col, Input, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsProductsService from "../../../../../../services/tms-products.service";
import catchErrorFunction from "../../../../../../utils/tms-catch-error/tms-catch-error";
import TmsImageUpload from "../../../../../../components/tms-customs/tms-image-upload";
const TmsProductsFMANF = ({
  viewProductData,
  editFlag,
  productId,
  shopProductId,
  setEditFlag,
  settmsModalVisible,
  width,
  router,
}) => {
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const [productName, setProductName] = useState("");
  const [languages, setLanguages] = useState("");
  const [price, setprice] = useState();
  const [fileList, setFileList] = useState([]);
  const [type, setType] = useState("");

  const languageData = [
    "telugu",
    "hindi",
    "kannada",
    "tamil",
    "english",
    "malayalam",
  ];

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const handleSubmit = async () => {
    setLoading(true);
    if (
      productName === "" ||
      year === "" ||
      month === "" ||
      fileList?.length === 0 ||
      deliveryFee === "" ||
      type === "" ||
      price === "" ||
      languages === ""
    ) {
      notification.error({
        message: "Please make sure you fill all the fields.",
      });
      setLoading(false);
      return;
    }
    let shopID = new URLSearchParams(router.location.search).get("id");
    let body = {
      shopProductId: shopProductId ? parseInt(shopProductId) : parseInt(shopID),
      year: year,
      month: month,
      languageOptions: [languages],
      type: type,
      name: productName,
      fileUrl: fileList[0]?.url,
      price: price,
      deliveryPrice: deliveryFee,
    };

    try {
      if (editFlag) {
        await TmsProductsService.editPublicationFile(productId, body);
        // setDarshanamData(darData);
        notification.success({ message: "File Edited Successful" });
        setEditFlag(false);
        setLoading(false);
      } else {
        await TmsProductsService.createPublicationFile(body);
        // setDarshanamData(data?.items);
        notification.success({ message: "File Created Successful" });
        setLoading(false);
        handleFuction();
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    setDeliveryFee(0);
    setProductName("");
    setMonth("");
    setYear("");
    setprice("");
    setFileList([]);
    setType("");
    setLanguages([]);
  };

  useEffect(() => {
    if (editFlag) {
      setType(viewProductData?.type);
      setProductName(viewProductData?.name);
      setYear(viewProductData?.year);
      setMonth(viewProductData?.month);
      setprice(viewProductData?.price);
      setFileList([{ url: viewProductData?.imageUrl, status: "done" }]);
      setDeliveryFee(viewProductData?.deliveryPrice);
      setLanguages(viewProductData?.languageOptions);
    }
  }, [viewProductData]);

  return (
    <Row className="formRow" style={{ width: "100%", minWidth: "500px" }}>
      <Card style={{ width: width ? "100%" : "60%", minWidth: "500px" }}>
        <Row className="formRow">
          <label className="formLabel">Product Name</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel"> Type</label>
          <Select
            className="formInput"
            onChange={(e) => {
              setType(e);
            }}
            value={type}
            defaultValue={type}
          >
            <Select.Option value="subscription">Subscription</Select.Option>
            <Select.Option value="download">Download</Select.Option>
            <Select.Option value="purchase">Purchase</Select.Option>
          </Select>
        </Row>

        <Row className="formRow">
          <label className="formLabel">Languages</label>
          <Select
            allowClear
            className={"formInput"}
            dropdownClassName="sd-select-dropdown-r"
            value={languages}
            onChange={(e) => {
              setLanguages(e);
            }}
            placeholder={`Select Languages`}
          >
            {languageData?.map((item) => {
              return (
                <Select.Option key={item} value={item}>
                  <span style={{ textTransform: "capitalize" }}> {item}</span>
                </Select.Option>
              );
            })}
          </Select>
        </Row>

        <Row className="formRow">
          <label className="formLabel">Month</label>
          <Select
            className="formInput"
            onChange={(e) => {
              setMonth(e);
            }}
            value={month}
            defaultValue={month}
          >
            {months.map((item, i) => {
              return (
                <Select.Option key={item} value={i + 1}>
                  {item} - {i + 1}
                </Select.Option>
              );
            })}
          </Select>
        </Row>

        <Row className="formRow">
          <label className="formLabel">Year</label>
          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={year}
            onChange={(e) => setYear(Number(e.target.value))}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">Price</label>

          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={price}
            onChange={(e) => setprice(Number(e.target.value))}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">Delivery Fee</label>
          <Input
            maxLength={220}
            type="number"
            min="0"
            className="formInput"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            value={deliveryFee}
            onChange={(e) => setDeliveryFee(Number(e.target.value))}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">Image</label>
          <Col className="formInput ">
            <TmsImageUpload
              fileList={fileList}
              setFileList={setFileList}
              className="w-100"
              accept={true}
            />
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsProductsFMANF);
