import moment from "moment";

const TmsStringUtils = {
  emptyOrValue: (vaule) => {
    return vaule ?? "";
  },

  CaptilizeWords: (value) => {
    if (!value) {
      return;
    }
    return value.replace(/\w\S*/g, (w) =>
      w.replace(/^\w/, (c) => c.toUpperCase())
    );
  },
  addDays: (date, days) => {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  },
  enumerateDaysBetweenDates: (startDate, endDate) => {
    let date = [];
    while (moment(startDate) <= moment(endDate)) {
      date.push(startDate);
      startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
    }
    return date;
  },
};

export default TmsStringUtils;
