import React, { useContext, useEffect, useState } from "react";
import TmsTable from "../../../components/tms-customs/tms-table";
import { BaseContext } from "../../../contexts/BaseContext";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsUserService from "../../../services/tms-user/tms-user.service";
import { Space, Row, Col, Button, Input } from "antd";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import TmsUMCreateUserModal from "../../../components/tms-user-management/tms-user-create-modal";
import TmsUMAssignModal from "../../../components/tms-user-management/tms-user-assign-modal";
import tmsRouteMap from "../../../constants/tms-route.map";

const TmsUmInnerPagesUsers = ({ router }) => {
  const { userDetails } = useContext(BaseContext);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [assignPermissionsFlag, setAssignPermissionsFlag] = useState(false);
  const [editPermissionsFlag, setEditPermissionsFlag] = useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [transfer, setTransfer] = useState(false);
  const { Search } = Input;

  useEffect(() => {
    // console.log("inner pages");
    getUsers();
  }, [updateFlag]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const data = await TmsUserService.getUsers(userDetails?.user?.templeId);
      setUserData(data);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const handleModify = async (userId, phone, flag) => {
    setEditData({ phone: phone.substr(3, 10), userId: userId });
    setEditPermissionsFlag(true);
    setShowAssignModal(true);
    setTransfer(flag);
  };

  // const handleDelete = async (record) => {
  //   setLoading(true);
  //   try {
  //     await TmsUserService.deleteUser(record.id);
  //     notification.success({ message: "User Deleted Successfully" });
  //     setUpdateFlag(Math.random() * 1001 + 8);
  //   } catch (error) {
  //     // console.log(error.response);
  //     catchErrorFunction(
  //       error,
  //       "Token Expired, redirecting to login page",
  //       router
  //     );
  //   }
  //   setLoading(false);
  // };
  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "User",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              router.navigate(
                `${tmsRouteMap.userManagement_user_details}?userId=${record.id}`
              );
            }}
            style={{ textTransform: "capitalize" }}
          >
            {text}
          </a>
        );
      },
    },

    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
      render: (record) => {
        return (
          <div style={{ display: "flex", maxWidth: "40vw", flexWrap: "wrap" }}>
            {record.map((item, i) => (
              <div
                key={i}
                style={{
                  margin: "0px 5px 5px 0px",
                  border: item?.includes("REPORT")
                    ? "1px solid blue"
                    : item?.includes("ADMIN")
                    ? "1px solid #FC4B35"
                    : item?.includes("COUNTER")
                    ? "1px solid #FF9128"
                    : "1px solid green",
                  padding: "3px 8px",
                  color: item?.includes("REPORT")
                    ? "blue"
                    : item?.includes("ADMIN")
                    ? "#FC4B35"
                    : item?.includes("COUNTER")
                    ? "#FF9128"
                    : "green",
                  borderRadius: "4px",
                }}
              >
                {item}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <Space size="middle">
            {/* <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={() => {
                if (record.phone?.substring(3)) {
                  handleDelete(record);
                }
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>Remove</a>
            </Popconfirm> */}
            <a
              onClick={() => {
                handleModify(record?.id, record?.phone, false);
              }}
            >
              Modify
            </a>
            {userDetails?.permissions?.includes("SUPER_ADMIN") && (
              <a
                onClick={() => {
                  handleModify(record?.id, record?.phone, true);
                }}
              >
                Transfer
              </a>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={[20, 20]} justify="space-between">
        <Col>
          <Row style={{ marginTop: "15px" }}>
            <Col flex="initial">
              <Button
                type="primary"
                onClick={() => {
                  setShowCreateUserModal(true);
                }}
              >
                Create User
              </Button>
            </Col>
            <Col style={{ marginLeft: "10px" }}>
              <Button
                type="primary"
                onClick={() => {
                  setShowAssignModal(true);
                  setAssignPermissionsFlag(true);
                }}
              >
                Assign Permissions
              </Button>
            </Col>{" "}
          </Row>
        </Col>
        <Col>
          <Row style={{ marginTop: "15px" }}>
            <Search
              placeholder="Search User"
              allowClear
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              style={{
                width: 200,
              }}
            />
          </Row>
        </Col>
      </Row>

      <br />
      <TmsTable
        dataSource={userData?.filter((item) => {
          return item?.displayName
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase());
        })}
        loading={loading}
        searchable={["display_name"]}
        columns={columns}
      />
      <TmsUMCreateUserModal
        userDetails={userDetails}
        showCreateUserModal={showCreateUserModal}
        setShowCreateUserModal={setShowCreateUserModal}
      />
      <TmsUMAssignModal
        setUpdateFlag={setUpdateFlag}
        showAssignModal={showAssignModal}
        setShowAssignModal={setShowAssignModal}
        assignPermissionsFlag={assignPermissionsFlag}
        editPermissionsFlag={editPermissionsFlag}
        setEditPermissionsFlag={setEditPermissionsFlag}
        setAssignPermissionsFlag={setAssignPermissionsFlag}
        editData={editData}
        transfer={transfer}
        setTransfer={setTransfer}
      />
    </>
  );
};
export default withRouter(TmsUmInnerPagesUsers);
