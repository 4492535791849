import { Button, Row } from "antd";
import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import TmsModal from "../tms-modal";
import { EyeOutlined } from "@ant-design/icons";
import { formatRentsData } from "../../../constants/rents-data.data";
import moment from "moment";

const PrintIndividual = ({ data, label }) => {
  const refContainer = useRef(null);
  const [visible, setVisible] = useState(false);
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  const handlePrint = useReactToPrint({
    content: () => refContainer.current,
    copyStyles: true,
    bodyClass: "sd-ticket-container",

    onAfterPrint: async () => {
      setVisible(false);
    },
  });

  return (
    <>
      <a
        onClick={async () => {
          setVisible(true);
        }}
      >
        <EyeOutlined />
      </a>
      <TmsModal
        width="60%"
        closable={true}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Row justify="center">
          {" "}
          <Button
            type="primary"
            onClick={() => {
              handlePrint();
            }}
          >
            Print
          </Button>
        </Row>
        <div ref={refContainer}>
          <h2
            style={{
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            {templeDetails?.name} - ({templeDetails?.shortName})
          </h2>
          <h6 style={{ textAlign: "center" }}>
            {moment().format("DD-MM-YYYY HH:mm A")}
          </h6>
          <h3
            style={{
              textAlign: "center",
              marginTop: "10px",
              paddingBottom: "5px",
              borderBottom: "1px solid gray",
              borderBottomStyle: "dotted",
            }}
          >
            {label}
          </h3>
          <table className="DetailsTable">
            {formatRentsData("Lands", data)?.map((item) => {
              return (
                <tr key={"table" + item[0]}>
                  <th>{item[0]}</th>
                  <td>{item[1]}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </TmsModal>
    </>
  );
};

export default PrintIndividual;
