import React, { useState, useEffect } from "react";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsDashboardService from "../../../../services/tms-dashboard.service";
import { Button, Row, Table } from "antd";
import tmsRouteMap from "../../../../constants/tms-route.map";
import moment from "moment";
import TmsLoading from "../../../../components/tms-customs/tms-loading";
const TmsReportsRequestsAll = ({ router }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const handleTableChange = (pagination) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };
  useEffect(() => {
    router.location?.pathname === tmsRouteMap.reports_requests && getRequests();
  }, [router.location.pathanme]);

  const getRequests = async () => {
    try {
      setLoading(true);
      let res = await TmsDashboardService.getReportRequests(1, 5000);
      setData(res?.items);
      setTabelTotalSize(res?.meta.totalItems);
      setLoading(false);
    } catch (e) {
      catchErrorFunction(e, "Token expired!. Login again", router);
      setLoading(false);
    }
  };
  const downloadFile = async (id) => {
    try {
      let res = await TmsDashboardService.downloadReport(id);
      const downloadUrl = URL.createObjectURL(res);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "filename.xlsx");
      link.click();

      URL.revokeObjectURL(downloadUrl); // Cleanup
    } catch (e) {
      catchErrorFunction(e, "Token expired!. Login again", router);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Service Type",
      dataIndex: "productType",
      render: (record) => {
        return record.replace("_", " ");
      },
    },
    {
      title: "From - To",
      render: (record) => {
        return `(${moment(record?.reportData.bookingFromDate).format(
          "DD-MM-YYYY"
        )})  -  (${moment(record?.reportDatabookingToDate).format(
          "DD-MM-YYYY"
        )})`;
      },
    },
    {
      title: "report type",
      dataIndex: "reportType",
      key: "reportType",
    },
    {
      title: "Download Status",
      render: (record) => {
        return record.status !== "completed" ? (
          "Requested"
        ) : (
          <Button
            onClick={() => {
              downloadFile(record.fileUploadId);
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];
  return (
    <div>
      {loading ? (
        <TmsLoading />
      ) : (
        <div>
          <Row justify="left">
            <Button
              onClick={() => {
                router.navigate(tmsRouteMap.reports_requests_new);
              }}
              type="primary"
            >
              Request New Report
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              loading={loading}
              onClick={() => {
                !loading && getRequests();
              }}
              type="dashed"
            >
              Refresh
            </Button>
          </Row>
          <br />
          <Table
            style={{ width: "100%", textTransform: "capitalize" }}
            columns={columns}
            dataSource={data ?? []}
            loading={loading}
            size="small"
            scroll={{ x: true }}
            pagination={{
              total: tableTotalSize,
              current: tableCurrentPage,
              pageSize: tablePageSize,
            }}
            onChange={handleTableChange}
          ></Table>
        </div>
      )}
    </div>
  );
};

export default withRouter(TmsReportsRequestsAll);
