import { notification } from "antd";
import React, { useEffect, useState } from "react";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsSmPrasadamInventoryStockLevel from "./tms-sm-prasadam-inventoty-inner-pages/tms-sm-prasadam-incentory-stock-level";
import TmsSmPrasadamInventoryAddNew from "./tms-sm-prasadam-inventoty-inner-pages/tms-sm-prasadam-inventory-add-new";
import TmsSmPrasadamInventoryAll from "./tms-sm-prasadam-inventoty-inner-pages/tms-sm-prasadam-inventory-all";

const TmsSmPrasadamInventory = ({ router }) => {
  const [active, setActive] = useState("Inventory");
  const [templeDetails] = useState(
    JSON.parse(localStorage.getItem("templeDetails"))
  );
  useEffect(() => {
    if (!templeDetails.configuration.prasadamInventory) {
      notification.info({ message: "Prasadam Inventory is not enabled " });
      router?.navigate(tmsRouteMap.serviceManagement);
      return;
    }
    let location = router.location?.pathname;
    if (location === tmsRouteMap.sm_prasadam_inventory) {
      setActive("Inventory");
      return;
    } else if (location.includes(tmsRouteMap.sm_prasadam_inventory_add_new)) {
      setActive("Add New");
    } else if (location.includes(tmsRouteMap.sm_prasadam_inventory_stock)) {
      setActive("Stock Level");
      return;
    }
  }, [router.location?.pathname]);
  const components = [
    {
      name: "Inventory",
      Element: <TmsSmPrasadamInventoryAll />,
      key: "Inventory",
      route: tmsRouteMap.sm_prasadam_inventory,
    },

    {
      name: "Add New",
      Element: <TmsSmPrasadamInventoryAddNew />,
      key: "Add New",
      route: tmsRouteMap.sm_prasadam_inventory_add_new,
    },
    {
      name: "Stock Level",
      Element: <TmsSmPrasadamInventoryStockLevel />,
      key: "Stock Level",
      route: tmsRouteMap.sm_prasadam_inventory_stock,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmPrasadamInventory);
