import React, { useState, useEffect } from "react";
import { Row, Col, Table, Space, Button, Input, notification } from "antd";
import moment from "moment";
import TmsShiftService from "../../../services/tms-shift.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import TmsDatePickerRange from "../../../components/tms-customs/tms-datepicker-range";
import TmsSelect from "../../../components/tms-customs/tms-select";
import { exportExcel } from "../../../utils/tms-excel/tms-excel";
import TmsExcelButton from "../../../components/tms-customs/tms-excel-button";

const TmsReportsScanLogs = ({ router }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [reportData, setReportData] = useState("");
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [outcome, setOutcome] = useState("");
  const [productType, setProductType] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);

  const productMap = {
    counter_seva: "Sevas",
    counter_darshanam: "Darshanam",
    counter_donation: "Donation",
    counter_accommodation: "Accommodation",
    counter_prasadam: "Prasadam",
    counter_misc_product: "Miscellaneous",
    counter_thulabharam: "Thulabharam",
    counter_tollgate: "Tollgate",
    counter_kalyanakatta: "Kalyanakatta",
  };

  const product = [
    "counter_darshanam",
    "counter_seva",
    "counter_donation",
    "counter_prasadam",
    "counter_tollgate",
    "counter_thulabharam",
    "counter_accommodation",
    "counter_accommodation_update",
    "counter_accommodation_advance",
    "counter_kalyanakatta",
    "counter_manual_entry",
    "counter_shop_product",
    "counter_misc_product",
    "counter_publication",
    "counter_hundi",
    "online_donation",
    "online_darshanam",
    "online_seva",
    "online_accommodation",
    "online_shop_product",
    "protocol_request",
  ];

  const fetchAllTransactions = async () => {
    setDataLoading(true);
    try {
      setReportData("");
      const data = await TmsShiftService.getAllScanLogs(
        tableCurrentPage,
        tablePageSize,
        toDate,
        fromDate,
        ticketId,
        outcome,
        productType
      );
      setReportData(data?.items);
      setTabelTotalSize(data?.meta?.totalItems);
    } catch (error) {
      catchErrorFunction(error, "Token expired! Please login again.", router);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    fetchAllTransactions();
  }, [tableCurrentPage, tablePageSize]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Display Name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "POS Name",
      dataIndex: "counterName",
      key: "counterName",
    },
    {
      title: "Seva Type",
      dataIndex: "productType",
      render: (record) => {
        return productMap[record];
      },
    },

    { title: "Ticket Id", dataIndex: "ticketId", key: "ticketId" },

    {
      title: "Order Date - Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY hh:mm A"),
    },

    { title: "outcome", dataIndex: "outcome", key: "outcome" },
    {
      title: "Failure reason",
      dataIndex: "failureReason",
      render: (record) => record ?? "-",
    },
  ];
  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      let data = await TmsShiftService.getAllScanLogs(
        tableCurrentPage,
        tablePageSize,
        toDate,
        fromDate,
        ticketId,
        outcome,
        productType
      );
      if (data.items.length === 0) {
        notification.info({ message: "No Data" });
        setExcelLoading(true);
      }
      data = data?.items?.reverse();
      const compiledData = data.map((item, i) => {
        return {
          "S.no": i + 1,
          Counter: `${item.counterId} - ${item.counterName}`,
          Service: item.productType.replaceAll("_", " "),
          "Ticket ID": item.ticketId,
          Date: moment(item.createdAt).format("DD-MM-YYYY"),
          Outcome: item.outcome,
          "Failure Reason": item.failureReason,
          User: `${item.userId} - ${item.displayName}`,
          "C.S.LoginLogId": item.counterShiftLoginLogId,
        };
      });
      exportExcel(
        compiledData,
        `scan-logs-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };
  return (
    <>
      <Row style={{ marginBottom: "20px", display: "flex" }} gutter={[20, 20]}>
        <Col>
          <TmsDatePickerRange
            label="Select Duration"
            to={toDate}
            from={fromDate}
            setFrom={setFromDate}
            setTo={setToDate}
          />
        </Col>

        <Col style={{ padding: "0" }}>
          <p style={{ marginBottom: "5px", textAlign: "left" }}>Ticket ID</p>
          <Input
            maxLength={220}
            value={ticketId}
            style={{ width: "200px" }}
            onChange={(e) => setTicketId(e.target.value)}
          />
        </Col>
        <TmsSelect
          type={"col"}
          span={4}
          label="Status"
          value={outcome}
          setValue={setOutcome}
          data={[
            { name: "Success", id: "SUCCESS" },
            { name: "Failure", id: "FAILURE" },
          ]}
        />
        <TmsSelect
          type={"col"}
          span={4}
          label="Product Type"
          value={productType}
          setValue={setProductType}
          data={product?.map((item) => {
            return { name: item?.replaceAll("_", " "), id: item };
          })}
        />

        <Col
          style={{
            maxWidth: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Button type="primary" onClick={() => fetchAllTransactions()}>
            Fetch
          </Button>
        </Col>
        <TmsExcelButton
          span={24}
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={true}
        />
      </Row>

      <Row>
        <Space size="small">
          <a>{tableTotalSize}</a>
          <span>Scan Logs</span>
        </Space>
      </Row>
      <Table
        style={{ width: "100%", textTransform: "capitalize" }}
        columns={columns}
        dataSource={reportData}
        loading={dataLoading}
        size="small"
        scroll={{ x: true }}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
        onChange={handleTableChange}
      ></Table>
    </>
  );
};

export default withRouter(TmsReportsScanLogs);
