import { Popconfirm, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import { createBankAccountStates } from "../../../../../../constants/states-data.data";
import tmsRouteMap from "../../../../../../constants/tms-route.map";
import { DeleteOutlined } from "@ant-design/icons";
import {
  FdrInstitutionSelect,
  SortTypeAndBy,
} from "../../../../../../components/tms-customs/rents-and-leases";
import useFdrStore from "../../../../../../zustand/fdr.store";

const TmsCreatedBankAccountList = ({ router }) => {
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [update, setUpdate] = useState("");
  const {
    selectedInstitution,
    loading,
    bankAccountData,
    fetchBankAccounts,
    fetchInstitutionList,
    deleteLoading,
    clearStates,
    deleteCreatedBankAccount,
  } = useFdrStore();

  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
    if (sorter.field === "createdAt") {
      if (sorter.order === "ascend") {
        setSortType("ASC");
      } else if (sorter.order === "descend") {
        setSortType("DESC");
      }
    }
  };

  useEffect(() => {
    return () => {
      clearStates(createBankAccountStates, ["accountCategoryData"]);
    };
  }, []);
  useEffect(() => {
    router.location.pathname === tmsRouteMap.sm_ral_fdr_bank_account_creation &&
      fetchInstitutionList(1, 200, "ASC", "createdAt", router);
  }, [router.location.pathname]);

  useEffect(() => {
    if (selectedInstitution !== "" && selectedInstitution)
      fetchBankAccounts(
        tableCurrentPage,
        tablePageSize,
        sortType,
        sortBy,
        router,
        update
      );
  }, [sortBy, tableCurrentPage, tablePageSize, sortType, selectedInstitution]);

  return (
    <>
      <Row justify="flex-start" gutter={[20, 20]}>
        <FdrInstitutionSelect span={6} />
        <SortTypeAndBy
          span={6}
          sortType={sortType}
          setSortType={setSortType}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      </Row>

      <Table
        align="center"
        className={" accomadation_table captalise-center mt-10 "}
        scroll={{
          x: "max-content",
        }}
        loading={loading}
        style={{
          textTransform: "capitalize",
        }}
        columns={[
          {
            title: "S.no",
            key: "sno",
            width: 70,
            render: (text, object, index) => index + 1,
          },
          {
            title: "Institution Name",
            dataIndex: "accountCategory",
            render: (record) => record?.institution?.name,
          },

          {
            title: "Account Category",
            dataIndex: "accountCategory",
            render: (record) => record?.accountCategoryName,
          },
          {
            title: "Account Number",
            dataIndex: "accountNumber",
          },
          {
            title: "Account Type",
            dataIndex: "accountType",
          },
          {
            title: "Bank Name",
            dataIndex: "bankName",
          },
          {
            title: "Bank Branch",
            dataIndex: "branchName",
          },
          {
            title: "IFSC Code",
            dataIndex: "ifsccode",
          },
          {
            title: "Location",
            dataIndex: "location",
          },

          {
            title: "Actions",
            key: "status",
            render: (record) => {
              return (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure delete this bank account?"
                    onConfirm={async () => {
                      await deleteCreatedBankAccount(record.id, router);
                      setUpdate(Math.random() * 100000 + 21);
                    }}
                    okText="Yes"
                    cancelText="No"
                    loading={deleteLoading}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Space>
              );
            },
          },
        ].map((item) => {
          return { ...item, align: "center" };
        })}
        dataSource={bankAccountData?.items ?? []}
        onChange={handleTableChange}
        pagination={{
          total: bankAccountData?.meta?.totalItems ?? 0,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      ></Table>
    </>
  );
};

export default withRouter(TmsCreatedBankAccountList);
