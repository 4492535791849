import { Button, Col, Input, Row } from "antd";
import React from "react";
import TmsSelect from "../../tms-customs/tms-select";
import TmsImageUpload from "../../tms-customs/tms-image-upload";
import InputPhoneNumber from "../../tms-customs/tms-input-phone-number";

const TmsSmParakamaniJewellery = ({
  jewelleryArray,
  setJewelleryArray,
  itemVerified,
  setItemVerified,
  goldsmithName,
  setGoldsmithName,
  goldsmithNumber,
  setGoldSmithNumber,
}) => {
  const addJewellery = () => {
    let temp = [...jewelleryArray];
    temp = [
      ...temp,
      {
        type: "",
        desc: "",
        weightKgs: 0,
        weightGms: 0,
        weightMgs: 0,
        fileURl: [],
      },
    ];
    setJewelleryArray([...temp]);
  };

  const removeJewellery = (index) => {
    let temp = [...jewelleryArray];
    temp?.splice(index, 1);
    setJewelleryArray([...temp]);
  };

  const handleJewellery = (val, index, key) => {
    let temp = [...jewelleryArray];
    temp[index][key] = val;
    setJewelleryArray([...temp]);
  };

  return (
    <div>
      {jewelleryArray?.map((item, i) => {
        return (
          <Row key={i} gutter={[20, 20]} style={{ marginBottom: "20px" }}>
            <TmsSelect
              span={6}
              type="col"
              label="Jewellery Type"
              placeholder="select Type"
              data={[
                { name: "Gold", id: "GOLD" },
                { name: "Silver", id: "SILVER" },
                { name: "Diamond", id: "DIAMOND" },
                { name: "Platinum", id: "PLATINUM" },
                { name: "Others", id: "OTHERS" },
              ]}
              optional={true}
              mandatory={item?.type?.length > 0 ? true : false}
              value={item?.type}
              setValue={(e) => handleJewellery(e, i, "type")}
            />
            <Col span={6}>
              <Row className="mb-5">
                <label>
                  Item description
                  {item?.type?.length > 0 && (
                    <span className="mandatory">*</span>
                  )}
                </label>
              </Row>
              <Input
                placeholder="Enter item description"
                value={item?.desc}
                onChange={(e) => {
                  handleJewellery(e.target.value, i, "desc");
                }}
              />
            </Col>
            {/* <Col span={6}>
              <Row className="mb-5">
                <label>
                  Item name
                  {item?.type?.length > 0 && (
                    <span className="mandatory">*</span>
                  )}
                </label>
              </Row>
              <Input
                maxLength={220}
                placeholder="Enter item name"
                value={item?.name}
                onChange={(e) => {
                  const input = e.target.value;
                  const pattern = /^[A-Za-z\s]+$/;
                  if (pattern.test(input) || input === "") {
                    handleJewellery(e.target.value, i, "name");
                  }
                }}
              />
            </Col> */}
            <Col span={6}>
              <Input.Group>
                <Row>
                  <Col span={8}>
                    <Row className="mb-5">
                      <label>
                        Kilos
                        {item?.type?.length > 0 && (
                          <span className="mandatory">*</span>
                        )}
                      </label>
                    </Row>
                    <Input
                      placeholder="kg"
                      type="number"
                      value={Number(item?.weightKgs)}
                      onChange={(e) => {
                        if (e.target.value > 999 || e.target.value < 0) {
                          return;
                        }
                        handleJewellery(e.target.value, i, "weightKgs");
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Row className="mb-5">
                      <label>
                        Grams
                        {item?.type?.length > 0 && (
                          <span className="mandatory">*</span>
                        )}
                      </label>
                    </Row>
                    <Input
                      placeholder="gm"
                      type="number"
                      value={Number(item?.weightGms)}
                      onChange={(e) => {
                        if (e.target.value > 999 || e.target.value < 0) {
                          return;
                        }
                        handleJewellery(e.target.value, i, "weightGms");
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Row className="mb-5">
                      <label>
                        Milli Gm
                        {item?.type?.length > 0 && (
                          <span className="mandatory">*</span>
                        )}
                      </label>
                    </Row>
                    <Input
                      placeholder="mg"
                      type="number"
                      value={Number(item?.weightMgs)}
                      onChange={(e) => {
                        if (e.target.value > 999 || e.target.value < 0) {
                          return;
                        }
                        handleJewellery(e.target.value, i, "weightMgs");
                      }}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Col>
            <Col>
              <Row style={{ marginBottom: "4px" }}>
                <label style={{ visibility: "hidden" }}>.</label>
              </Row>

              <Button
                type="primary"
                onClick={() => {
                  i + 1 === jewelleryArray?.length
                    ? addJewellery()
                    : removeJewellery(i);
                }}
              >
                {i + 1 === jewelleryArray?.length ? "Add" : "Remove"}
              </Button>
            </Col>
            <Col span={24}>
              <Row className="mb-5">
                <label>
                  Jewellery Image
                  {item?.type?.length > 0 && (
                    <span className="mandatory">*</span>
                  )}
                </label>{" "}
              </Row>
              <TmsImageUpload
                dragger={true}
                flag="public"
                className="w-100"
                listType="text"
                accept={false}
                fileList={item?.fileURl}
                index={i}
                overrideFunc={handleJewellery}
              />
            </Col>
          </Row>
        );
      })}
      <label style={{ fontWeight: "bold" }}>Appraisement Details</label>
      <br />
      <Row gutter={[20, 20]} style={{ marginTop: "10px" }}>
        <TmsSelect
          span={6}
          type="col"
          label="If item verified by Goldsmith"
          placeholder="select Yes or No"
          mandatory={true}
          data={[
            { name: "Yes", id: true },
            { name: "No", id: false },
          ]}
          optional={true}
          value={itemVerified}
          setValue={(e) => setItemVerified(e)}
        />
        {itemVerified && (
          <>
            <Col span={6}>
              <Row className="mb-5">
                <label>
                  Goldsmith Name
                  <span className="mandatory">*</span>
                </label>
              </Row>
              <Input
                maxLength={220}
                placeholder="Enter Name"
                value={goldsmithName}
                onChange={(e) => {
                  const input = e.target.value;
                  const pattern = /^[A-Za-z\s]+$/;
                  if (pattern.test(input) || input === "") {
                    setGoldsmithName(e.target.value);
                  }
                }}
              />
            </Col>
            <Col span={6}>
              {/* <Row className="mb-5">
            <label>
              
              <span className="mandatory">*</span>
            </label>
          </Row> */}
              <InputPhoneNumber
                value={goldsmithNumber}
                setState={setGoldSmithNumber}
                label={"Goldsmith Contact Number"}
                mandatory={true}
                error={""}
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default TmsSmParakamaniJewellery;
