import React, { useEffect, useState } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import { LoadingOutlined } from "@ant-design/icons";
import TmsManualEntryService from "../../../services/tms-manual-entry.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { Button, Col, Input, notification, Row, Select, Table } from "antd";

const Option = Select;

const TmsPosManualEntry = ({ router }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const labelStyles = { float: "left", fontSize: "17px", marginBottom: "5px" };
  const [book, setBook] = useState();
  const [manualEntryCategoryLogs, setmanualEntryCategoryLogs] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  useEffect(() => {
    fetchValidProducts();
  }, []);

  useEffect(() => {
    setmanualEntryCategoryLogs([]);
    let data = [];
    list
      .filter((item) => {
        return item?.id === book;
      })[0]
      ?.manualEntryBookCategories?.map(() => {
        data = manualEntryCategoryLogs;
        data.push({
          bookCategoryId: 0,
          amount: 0,
          quantity: 0,
        });
        setmanualEntryCategoryLogs(data);
      });
  }, [book]);

  const clearFields = () => {
    setList([]);
    setBook();
    setmanualEntryCategoryLogs([]);
    setFrom();
    setTo();
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `Rs. ${text}`;
      },
    },
    {
      title: "Quantity",
      render: (text, record, i) => {
        return (
          <Input
            maxLength={220}
            type="number"
            min=""
            value={manualEntryCategoryLogs[i]?.quantity}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
            }}
            onChange={(e) => {
              let data = [...manualEntryCategoryLogs];
              data[i].quantity = Number(e.target.value);
              data[i].bookCategoryId = Number(record?.id);
              data[i].amount = Number(record?.price * Number(e.target.value));
              setmanualEntryCategoryLogs(data);
            }}
          />
        );
      },
    },
    {
      title: "Amount",
      render: (text, record, i) => {
        return `Rs. ${Number(manualEntryCategoryLogs[i]?.amount ?? 0)}`;
      },
    },
  ];

  const fetchValidProducts = async () => {
    setLoading(true);
    try {
      const resp = await TmsManualEntryService.validProducts();
      setList(resp);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let body = [
      {
        bookId: book,
        seriesStart: from,
        seriesEnd: to,
        manualEntryCategoryLogs: manualEntryCategoryLogs?.filter(
          (item) => item?.quantity > 0
        ),
      },
    ];
    try {
      const resp = await TmsManualEntryService.manualEntryBooking(body);
      if (resp) {
        setLoading(false);
        clearFields();
        notification.success({ message: "Booking Entered" });
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              maxWidth: "1300px",
              width: "100%",
              background: "white",
              padding: "30px 50px",
            }}
          >
            <Row style={{ justifyContent: "space-between" }}>
              <Col>
                <label style={labelStyles}>
                  Select Book
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
                <br />
                <Select
                  style={{ width: "200px" }}
                  value={book}
                  onChange={(e) => {
                    setBook(e);
                    let data = list?.find((a) => a.id === e);
                    setFrom(
                      data?.lastSeriesEnd
                        ? Number(data?.lastSeriesEnd + 1)
                        : data?.seriesStart
                    );
                  }}
                >
                  {list?.map((item) => {
                    return (
                      <Option key={item?.id} value={item?.id}>
                        {item?.name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col>
                <Row gutter={[20, 20]} style={{ textAlign: "left" }}>
                  <Col>
                    <label style={labelStyles}>From</label>
                    <br />
                    <Input
                      maxLength={220}
                      type="number"
                      min=""
                      value={from}
                      onChange={(e) => {
                        setFrom(Number(e.target.value));
                      }}
                    />
                  </Col>
                  <Col>
                    <label style={labelStyles}>To</label>
                    <br />
                    <Input
                      maxLength={220}
                      type="number"
                      min=""
                      value={to}
                      onChange={(e) => {
                        setTo(Number(e.target.value));
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row style={{ width: "100%" }}>
              <Table
                style={{ width: "100%" }}
                dataSource={
                  list.filter((item) => {
                    return item?.id === book;
                  })[0]?.manualEntryBookCategories
                }
                columns={columns}
              />
            </Row>
            <br />
            <Row>
              <Button
                type="primary"
                onClick={() => handleSubmit()}
                loading={loading}
              >
                Submit
              </Button>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(TmsPosManualEntry);
