import React, { useState } from "react";
import { withRouter } from "../../../../../../../utils/tms-with-router/tms-with-router";
import TmsAccInnerPagesManageBlocksAll from "./tms-mcr-mr-all";
import TmsAccIpMcrManageRoomAddNew from "./tms-mcr-mr-add-new";
import { Tabs } from "antd";
const TmsSmAccIpMcrManageRoom = () => {
  const [active, setActive] = useState("Manage Room");

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="card-container">
        <Tabs
          type="card"
          activeKey={active}
          onChange={(e) => {
            setActive(e);
          }}
        >
          <Tabs.TabPane tab={"Manage Room "} key={"Manage Room"}>
            <TmsAccInnerPagesManageBlocksAll active={active} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Add New Room"} key={"Add New Room"}>
            <TmsAccIpMcrManageRoomAddNew active={active} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default withRouter(TmsSmAccIpMcrManageRoom);
