import { Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import { arrowRight } from "../../../../../../assets/images/tms-image-list";
import TmsAddress from "../../../../../../components/tms-customs/tms-address";
import TmsCancelSubmitButtons from "../../../../../../components/tms-customs/tms-cancel-submit-buttons";
import "../../../../../../assets/css/tms-common/tms-common.scss";
import TmsSelect from "../../../../../../components/tms-customs/tms-select";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import {
  createBuildingStates,
  propertyTypes,
} from "../../../../../../constants/states-data.data";
import useBuildingsStore from "../../../../../../zustand/buildings-and-shops.store";
import TmsInput from "../../../../../../components/tms-customs/tms-input";

const TmsBasCreation = ({ router, setVisible }) => {
  const {
    landmark,
    setLandmark,
    builtAreaInSqFeet,
    setBuiltAreaInSqFeet,
    zip,
    setZip,
    setSubmitLoading,
    state,
    setState,
    country,
    setCountry,
    district,
    setDistrict,
    institutionData,
    village,
    setVillage,
    mandal,
    setMandal,
    submitLoading,
    fetchInstitutionList,
    selectedInstitution,
    setSelectedInstitution,
    handleCreateBuilding,
    clearStates,
    setBuildingName,
    buildingName,
    numberOfBlocks,
    setNumberOfBlocks,
    numberOfShops,
    setNumberOfShops,
    geoLocation,
    setGeoLocation,
    propertyType,
    setPropertyType,
  } = useBuildingsStore();

  useEffect(() => {
    fetchInstitutionList(1, 200, "ASC", "createdAt", router);
    return () => {
      clearStates(createBuildingStates, [
        "institutionData",
        "numberOfShops",
        "numberOfBlocks",
      ]);
    };
  }, []);

  return (
    <div>
      <Row gutter={[20, 30]} style={{ marginBottom: "15px" }}>
        <TmsSelect
          type="col"
          span={12}
          label="Select Institution"
          setValue={setSelectedInstitution}
          selectClass="w-100"
          value={selectedInstitution}
          data={institutionData?.items?.map((item) => {
            return { name: item.name, id: item.id };
          })}
          optional={true}
        />
        <Col span={6}>
          <Row className={"t-left"}>Land Mark</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Landmark"
            value={landmark}
            onChange={(e) => {
              setLandmark(e.target.value);
            }}
          />
        </Col>
        <TmsInput
          type="text"
          label={"Building Name"}
          value={buildingName}
          setValue={setBuildingName}
          span={6}
          placeholder={"Enter Name"}
        />
      </Row>
      <Row gutter={[20, 30]} style={{ marginBottom: "15px" }}>
        <TmsSelect
          type="col"
          span={6}
          label="Property Type"
          setValue={(e) => {
            setPropertyType(e);
            setNumberOfBlocks("");
            setNumberOfShops("");
          }}
          selectClass="w-100"
          value={propertyType}
          data={propertyTypes}
          optional={true}
        />
        <Col span={6}>
          <Row className={"t-left"}>Built Area (Sqft)</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            placeholder="Enter Built Area"
            value={builtAreaInSqFeet}
            onChange={(e) => setBuiltAreaInSqFeet(e.target.value)}
          />
        </Col>
        <Col span={6}>
          <Row className={"t-left"}>No of Blocks</Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter No of Blocks"
            value={numberOfBlocks}
            onChange={(e) => {
              setNumberOfBlocks(e.target.value);
            }}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>
        <Col span={6}>
          <Row className={"t-left"}>No of Shops </Row>
          <Input
            maxLength={220}
            type="number"
            min="0"
            placeholder="Enter No Of Shops"
            value={numberOfShops}
            onChange={(e) => {
              setNumberOfShops(e.target.value);
            }}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
          />
        </Col>

        <TmsAddress
          span={12}
          village={village}
          setVillage={setVillage}
          mandal={mandal}
          setMandal={setMandal}
          district={district}
          setDistrict={setDistrict}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          type="general"
        />
      </Row>

      <Row gutter={[20, 30]} style={{ marginTop: "15px" }}>
        <Col span={12}>
          <Row className={"t-left"}>Geo Location</Row>
          <Input
            maxLength={220}
            type="text"
            placeholder="Enter Geo Location"
            value={geoLocation}
            onChange={(e) => {
              setGeoLocation(e.target.value);
            }}
          />
        </Col>
      </Row>

      <TmsCancelSubmitButtons
        loading={submitLoading}
        icon={arrowRight}
        cancelOnlick={() => {
          clearStates(createBuildingStates, [
            "institutionData",
            "numberOfShops",
            "numberOfBlocks",
          ]);
          setSubmitLoading(false);
          setVisible(false);
        }}
        submitOnclick={() => {
          handleCreateBuilding(router);
        }}
      />
    </div>
  );
};

export default withRouter(TmsBasCreation);
