import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsSevasService from "../../../../../services/tms-sevas.service";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import TmsImageUpload from "../../../../../components/tms-customs/tms-image-upload";

const TmsSevasInnerPagesANS = ({
  viewSevasData,
  editFlag,
  productId,
  setEditFlag,
  settmsModalVisible,
  router,
}) => {
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [maxCounterBookings, setMaxCounterBookings] = useState();
  const [maxOnlineBookings, setmaxOnlineBookings] = useState();
  const [minOnlineHours, setminOnlineHours] = useState();
  const [maxOnlineHours, setmaxOnlineHours] = useState();
  const [minCounterHours, setminCounterHours] = useState();
  const [maxCounterHours, setmaxCounterHours] = useState();
  const [sevasName, setSevasName] = useState("");
  const [description, setdescription] = useState("");
  const [counterEnabled, setcounterEnabled] = useState(false);
  const [onlineEnabled, setonlineEnabled] = useState(false);
  const [price, setPrice] = useState("");
  const [fileList, setFileList] = useState([]);
  const [sevaType, setSevaType] = useState("pratyakshaSeva");
  const [noOfPeople, setNoOfPeople] = useState("");
  const [changeRequestFile, setChangeRequestFile] = useState([]);
  const [changeRequestDesc, setChangeRequestDesc] = useState("");
  const [isAddon, setIsAddOn] = useState(false);
  const [isProtocol, setIsProtocol] = useState(false);
  const [maxTicketsPerBooking, setMaxTicketsPerBooking] = useState();

  const handleSubmit = async () => {
    setLoading(true);
    if (
      sevasName === "" ||
      noOfPeople === "" ||
      fileList?.length === 0 ||
      sevaType === "" ||
      price === "" ||
      description === "" ||
      maxCounterBookings === "" ||
      maxOnlineBookings === "" ||
      minCounterHours === "" ||
      maxCounterHours === "" ||
      minOnlineHours === "" ||
      maxOnlineHours === "" ||
      maxTicketsPerBooking === ""
    ) {
      notification.error({
        message: "Please make sure you fill all the fields.",
      });
      setLoading(false);
      return;
    }
    if (editFlag) {
      if (changeRequestFile?.length === 0 || changeRequestDesc === "") {
        notification.error({ message: "Fill all the fields" });
        setLoading(false);

        return;
      }
    }
    let body = {
      sevaType: sevaType,
      maxCounterBookings: maxCounterBookings,
      maxOnlineBookings: maxOnlineBookings,
      minOnlineHours: minOnlineHours,
      maxOnlineHours: maxOnlineHours,
      minCounterHours: minCounterHours,
      maxCounterHours: maxCounterHours,
      name: sevasName,
      description: description,
      counterEnabled: counterEnabled,
      onlineEnabled: onlineEnabled,
      price: price,
      imageUrl: fileList[0]?.url,
      noOfPeople: Number(noOfPeople),
      isAddon: isAddon,
      protocolEnabled: isProtocol,
      maxTicketsPerBooking: maxTicketsPerBooking,
    };
    if (editFlag) {
      body.changeRequestPdfUrl = changeRequestFile[0].url;
      body.changeDescription = changeRequestDesc;
    }
    try {
      if (editFlag) {
        await TmsSevasService.editSeva(productId, body);
        // setDarshanamData(darData);
        notification.success({ message: "Seva Edited Successful" });
        setEditFlag(false);
        setLoading(false);
      } else {
        await TmsSevasService.createSeva(body);
        // setDarshanamData(data?.items);
        notification.success({ message: "Seva Created Successful" });
        setLoading(false);
        handleFuction();
      }
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    setMaxCounterBookings("");
    setmaxOnlineBookings("");
    setminOnlineHours("");
    setmaxOnlineHours("");
    setminCounterHours("");
    setmaxCounterHours("");
    setSevasName("");
    setdescription("");
    setcounterEnabled(false);
    setonlineEnabled(false);
    setPrice("");
    setFileList([]);
    setNoOfPeople("");
    setChangeRequestFile([]);
    setChangeRequestDesc("");
    setIsAddOn(false);
    setIsProtocol(false);
    setMaxTicketsPerBooking();
  };

  useEffect(() => {
    if (editFlag) {
      setMaxCounterBookings(viewSevasData?.maxCounterBookings);
      setmaxOnlineBookings(viewSevasData?.maxOnlineBookings);
      setminOnlineHours(Number(viewSevasData?.minOnlineHours));
      setmaxOnlineHours(Number(viewSevasData?.maxOnlineHours));
      setminCounterHours(viewSevasData?.minCounterHours);
      setmaxCounterHours(viewSevasData?.maxCounterHours);
      setSevasName(viewSevasData?.name);
      setdescription(viewSevasData?.description);
      setcounterEnabled(viewSevasData?.counterEnabled);
      setonlineEnabled(viewSevasData?.onlineEnabled);
      setPrice(viewSevasData?.price);
      setFileList([{ url: viewSevasData?.imageUrl, status: "done" }]);
      setSevaType(viewSevasData?.sevaType);
      setNoOfPeople(viewSevasData.noOfPeople);
      setIsAddOn(viewSevasData.isAddon);
      setIsProtocol(viewSevasData.protocolEnabled);
      setMaxTicketsPerBooking(viewSevasData.maxTicketsPerBooking);
    }
  }, [viewSevasData]);

  return (
    <Row className="formRow">
      <Card>
        <Row style={{ marginBottom: "10px" }}>
          <label className="formLabel">Seva Type</label>

          <Select
            className="formInput"
            onChange={(e) => {
              setSevaType(e);
            }}
            value={sevaType}
            defaultValue={sevaType}
          >
            <Select.Option value="parokshaSeva">Paroksha Seva</Select.Option>
            <Select.Option value="pratyakshaSeva">
              Pratyaksha Seva
            </Select.Option>
            <Select.Option value="saswataSeva">Saswatha Seva</Select.Option>
          </Select>
        </Row>
        <Row className="formRow">
          <label className="formLabel">Seva Name</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={sevasName}
            onChange={(e) => setSevasName(e.target.value)}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Image</label>
          <Col>
            <TmsImageUpload
              fileList={fileList}
              setFileList={setFileList}
              accept={false}
            />
            <p style={{ margin: "10px 0px" }}>
              File must be 680 x 300 and less than 1MB
            </p>
          </Col>
        </Row>
        <Row className="formRow">
          <label className="formLabel">Description</label>
          <TextArea
            maxLength={2000}
            className="formInput"
            rows={4}
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        </Row>

        <Row className="formRow">
          <label className="formLabel">No of People Allowed</label>
          <Input
            maxLength={220}
            className="formInput"
            type="number"
            min="0"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) => setNoOfPeople(e.target.value)}
            value={noOfPeople}
          />
        </Row>
        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Max Bookings Per Day</label>
          <Col className="formCols-1">
            <label>Total</label>
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              disabled
              value={Number(maxOnlineBookings) + Number(maxCounterBookings)}
            />
          </Col>
          <Col className="formCols-1">
            <label>Online</label>
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={maxOnlineBookings}
              onChange={(e) => setmaxOnlineBookings(Number(e.target.value))}
            />
          </Col>
          <Col className="formCols-1">
            <label>POS</label>
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={maxCounterBookings}
              onChange={(e) => setMaxCounterBookings(Number(e.target.value))}
            />
          </Col>
        </Row>
        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Max Tickets Per Booking</label>

          <Col className="formCols-1">
            <label>POS</label>
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={maxTicketsPerBooking}
              onChange={(e) => setMaxTicketsPerBooking(Number(e.target.value))}
            />
          </Col>
        </Row>
        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Cut Off Time</label>
          <Col className="formCols-2">
            <label>Online (Hours)</label>
            <Input.Group compact>
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "50%" }}
                placeholder="Min Days"
                value={minOnlineHours}
                onChange={(e) => setminOnlineHours(Number(e.target.value))}
              />
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "50%" }}
                placeholder="Max Days"
                value={maxOnlineHours}
                onChange={(e) => setmaxOnlineHours(Number(e.target.value))}
              />
            </Input.Group>
          </Col>

          <Col className="formCols-2">
            <label>POS (Hours)</label>
            <Input.Group compact>
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "50%" }}
                placeholder="Min Hours"
                value={minCounterHours}
                onChange={(e) => setminCounterHours(Number(e.target.value))}
              />
              <Input
                maxLength={220}
                type="number"
                min="0"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                style={{ width: "50%" }}
                placeholder="Max Hours"
                value={maxCounterHours}
                onChange={(e) => setmaxCounterHours(Number(e.target.value))}
              />
            </Input.Group>
          </Col>
        </Row>
        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Price</label>
          <Col className="formCols-2">
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
            />
          </Col>
        </Row>
        <Row>
          <label className="formLabel"></label>
          <Row gutter={[20, 20]} className="formRow contantCenter">
            <Col>
              <label style={{ paddingRight: "10px" }}>Enable POS</label>
              <Checkbox
                style={{ paddingRight: "10px" }}
                checked={counterEnabled}
                onChange={(e) => setcounterEnabled(e.target.checked)}
              />
            </Col>
            <Col>
              {" "}
              <label style={{ paddingRight: "10px" }}>Enable Online</label>
              <Checkbox
                checked={onlineEnabled}
                onChange={(e) => setonlineEnabled(e.target.checked)}
              />
            </Col>
            <Col>
              <label style={{ paddingRight: "10px" }}>Enable Add on</label>
              <Checkbox
                checked={isAddon}
                onChange={(e) => setIsAddOn(e.target.checked)}
              />
            </Col>
            <Col>
              {" "}
              <label style={{ paddingRight: "10px" }}>Enable Protocol</label>
              <Checkbox
                checked={isProtocol}
                onChange={(e) => setIsProtocol(e.target.checked)}
              />
            </Col>
          </Row>
        </Row>
        {editFlag && (
          <>
            <Row gutter={[20, 20]} className="formRow">
              <label className="formLabel">Request Copy</label>
              <Col className="formInput ">
                <TmsImageUpload
                  fileList={changeRequestFile}
                  setFileList={setChangeRequestFile}
                  router={router}
                  type="public"
                  accept={true}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="formRow">
              <label className="formLabel">Edit Description</label>
              <Input
                maxLength={220}
                className="formInput"
                value={changeRequestDesc}
                onChange={(e) => setChangeRequestDesc(e.target.value)}
              />
            </Row>
          </>
        )}
        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                editFlag ? settmsModalVisible(false) : handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              loading={loading}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsSevasInnerPagesANS);
