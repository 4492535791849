import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Col, Drawer, Switch } from "antd";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmsBusService from "../../../../../services/tms-bus-service.js";
import TmsSmBusAdd from "./tms-sm-bus-add.jsx";
import TmsSmBusInnerActions from "../../../../../components/tms-service-management/tms-sm-bus/tms-sm-bus-inner-actions.jsx";

const TmsSmBusAll = ({ router }) => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [busdata, setBusData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewBusData, setViewBusData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);

  const tableColums = [
    ["Product type", viewBusData?.name],
    ["Description", viewBusData?.description],
    ["Price", viewBusData?.price],
    ["Validity", viewBusData?.validity],
    ["POS-(Status)", viewBusData?.counterEnabled ? "enabled" : "disabled"],
  ];

  const switchTrigger = async (key) => {
    setLoading(true);
    try {
      const darData = await TmsBusService.fetchABusbyId(key);
      setViewBusData(darData);
      settmsModalVisible(true);
      setEditFlag(true);
      setProductId(key);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }

    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    router.location?.pathname === "/service-management/bus" && getAllBus();
    if (!editFlag) {
      settmsModalVisible(false);
    }
  }, [
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  const getAllBus = async () => {
    setLoading(true);
    try {
      let data = [];
      setBusData([]);

      data = await TmsBusService.fetchAllBus(tableCurrentPage, tablePageSize);

      setTabelTotalSize(data?.meta?.totalItems);
      setBusData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Route Name",
      dataIndex: "name",
      key: "name",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Validity",
      dataIndex: "validity",
      key: "validity",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price > b.price,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },

    {
      title: "POS-(Status)",
      dataIndex: "counterEnabled",
      key: "counterEnabled",
      render: (text, record) => {
        return (
          <Switch
            size={"small"}
            checked={text}
            style={{ background: text ? "#4CBB17" : "EFEFEF" }}
            onClick={() => {
              switchTrigger(record?.key, !text, "counter");
            }}
          />
        );
      },
    },

    {
      title: "Action",
      render: (record) => {
        return (
          <TmsSmBusInnerActions
            record={record}
            setLoading={setLoading}
            setViewBusData={setViewBusData}
            settmsModalVisible={settmsModalVisible}
            setUpdateFlag={setUpdateFlag}
            setEditFlag={setEditFlag}
            setProductId={setProductId}
            // setShowDrawer={setShowDrawer}
            // setTermsData={setTermsData}
            // setMiniTicket={setMiniTicket}
          />
        );
      },
    },
  ];

  return (
    <>
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={busdata}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <Drawer
        className="DetailsModal"
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewBusData(null);
        }}
        title={editFlag ? "Edit Bus" : "Bus Details"}
      >
        {editFlag ? (
          <TmsSmBusAdd
            viewBusData={viewBusData}
            editFlag={editFlag}
            productId={productId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
          />
        ) : (
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default withRouter(TmsSmBusAll);
