import tmsRouteMap from "./tms-route.map";
import {
  CrownOutlined,
  BarChartOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
  PartitionOutlined,
  SettingOutlined,
  ContactsOutlined,
  ReadOutlined,
  LaptopOutlined,
  UserOutlined,
} from "@ant-design/icons";
export const tmsSiderMap = [
  {
    route: tmsRouteMap.home,
    icon: HomeOutlined,
    inLayout: true,
    name: "Dashboard",
    accessedByKey: [
      "DASHBOARD_READ",
      "ADMIN",
      "SUPER_ADMIN",
      "SUPER_DASHBOARD_READ",
    ],
  },
  // {
  //   route: tmsRouteMap.devotees,
  //   icon: UsergroupAddOutlined,
  //   inLayout: true,
  //   name: "Devotees",
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  // },
  {
    route: tmsRouteMap.pos,
    icon: PartitionOutlined,
    inLayout: true,
    name: "POS",
    accessedByKey: [
      "ACCOMMODATION_COUNTER",
      "SEVA_COUNTER",
      "DARSHANAM_COUNTER",
      "PRASADAM_COUNTER",
      "KALYANAKATTA_COUNTER",
      "DONATION_COUNTER",
      "DONATION_PETTY_COUNTER",
      "PUBLICATION_COUNTER",
      "TOLLGATE_COUNTER",
      "TICKET_DETAILS_SCAN",
      "TOLLGATE_TICKET_SCAN",
      "PRASADAM_TICKET_SCAN",
      "DARSHANAM_TICKET_SCAN",
      "SEVA_TICKET_PERFORMANCE_SCAN",
      "ACCOMMODATION_TICKET_SCAN",
      "THULABHARAM_COUNTER",
      "ADMIN",
      "MISC_PRODUCT_COUNTER",
      "SUPER_ADMIN",
      "ENTRY_SCANNING_COUNTER",
      "SHOP_PRODUCT_TICKET_SCAN",
      "SHOP_PRODUCT_COUNTER",
      "PROTOCOL_LEVEL_1",
      "PROTOCOL_LEVEL_2",
      "PROTOCOL_LEVEL_3",
      "PROTOCOL_LEVEL_4",
      "PROTOCOL_BOOKING",
      "BUS_TICKET_COUNTER",
      "RENT_AND_LEASE_COUNTER",
    ],
  },
  {
    route: tmsRouteMap.serviceManagement,
    icon: BarChartOutlined,
    inLayout: true,
    name: "Service Management",
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "PRASAD_INVENTORY_MANAGEMENT",
      "HUNDI",
      "RENT_AND_LEASE_WRITE",
    ],
  },
  // {
  //   route: tmsRouteMap.orders,
  //   icon: UnorderedListOutlined,
  //   inLayout: true,
  //   name: "Orders",
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  // },
  {
    route: tmsRouteMap.reports,
    icon: ReadOutlined,
    inLayout: true,
    name: "Reports",
    accessedByKey: [
      "ADMIN",
      "SUPER_ADMIN",
      "ACCOMMODATION_REPORT",
      "SEVA_REPORT",
      "DARSHANAM_REPORT",
      "PRASADAM_REPORT",
      "KALYANAKATTA_REPORT",
      "DONATION_REPORT",
      "PUBLICATION_REPORT",
      "TOLLGATE_REPORT",
      "SHIFT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "THULABHARAM_REPORT",
      "SHIFT_LOGIN",
      "BUS_TICKET_REPORT",
      "HUNDI_REPORT",
      "RENT_AND_LEASE_REPORT",
    ],
  },
  {
    route: tmsRouteMap.userManagement,
    icon: UserOutlined,
    inLayout: true,
    name: "User Management",
    accessedByKey: ["SUPER_ADMIN", "ADMIN"],
  },

  {
    route: tmsRouteMap.support,
    icon: LaptopOutlined,
    inLayout: true,
    name: "Support",
    accessedByKey: ["CUSTOMER_SUPPORT", "ADMIN", "SUPER_ADMIN"],
  },
  // {
  //   route: tmsRouteMap.calender,
  //   icon: CalendarOutlined,
  //   inLayout: true,
  //   name: "Calender",
  //   accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  // },
  {
    route: tmsRouteMap.temple_management,
    icon: CrownOutlined,
    inLayout: true,
    name: "Temple Management",
    accessedByKey: ["SUPER_ADMIN"],
  },
  {
    route: tmsRouteMap.temple_settings,
    icon: SettingOutlined,
    inLayout: true,
    name: "Temple Settings",
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  },
  {
    route: tmsRouteMap.partners,
    icon: UsergroupAddOutlined,
    inLayout: true,
    name: "Partners",
    accessedByKey: ["ADMIN", "SUPER_ADMIN"],
  },
  {
    route: tmsRouteMap.profile,
    icon: ContactsOutlined,
    inLayout: true,
    name: "My Profile",
    accessedByKey: [
      "SUPER_ADMIN",
      "ADMIN",
      "SHIFT_LOGIN",
      "DASHBOARD_READ",
      "ACCOMMODATION_READ",
      "ACCOMMODATION_WRITE",
      "ACCOMMODATION_COUNTER",
      "ACCOMMODATION_REPORT",
      "ACCOMMODATION_ADMIN_READ",
      "ACCOMMODATION_TICKET_SCAN",
      "SEVA_COUNTER",
      "SEVA_REPORT",
      "SEVA_TICKET_SCAN",
      "SEVA_TICKET_PERFORMANCE_SCAN",
      "DARSHANAM_COUNTER",
      "DARSHANAM_REPORT",
      "DARSHANAM_TICKET_SCAN",
      "PRASADAM_COUNTER",
      "PRASADAM_REPORT",
      "PRASADAM_TICKET_SCAN",
      "PRASAD_INVENTORY_MANAGEMENT",
      "KALYANAKATTA_COUNTER",
      "KALYANAKATTA_REPORT",
      "KALYANAKATTA_TICKET_SCAN",
      "DONATION_COUNTER",
      "DONATION_PETTY_COUNTER",
      "DONATION_REPORT",
      "DONATION_WRITE",
      "PUBLICATION_COUNTER",
      "PUBLICATION_REPORT",
      "TOLLGATE_COUNTER",
      "TOLLGATE_REPORT",
      "TOLLGATE_TICKET_SCAN",
      "TICKET_DETAILS_SCAN",
      "SHIFT_REPORT",
      "GST_REPORT",
      "SCAN_LOG_REPORT",
      "TRANSACTIONS_REPORT",
      "CUSTOMER_SUPPORT",
      "THULABHARAM_COUNTER",
      "THULABHARAM_REPORT",
      "VISITOR_COUNT",
      "SHOP_PRODUCT_COUNTER",
      "SHOP_PRODUCT_REPORT",
      "SHOP_PRODUCT_TICKET_SCAN",
      "MISC_PRODUCT_COUNTER",
      "MISC_PRODUCT_REPORT",
      "ENTRY_SCANNING_COUNTER",
      "ENTRY_SCANNING_REPORT",
      "RENT_AND_LEASE_WRITE",
      "RENT_AND_LEASE_READ",
      "RENT_AND_LEASE_COUNTER",
      "PRIEST",
      "DONOR_PRIVILEGE",
      "PROTOCOL_LEVEL_1",
      "PROTOCOL_LEVEL_2",
      "PROTOCOL_LEVEL_3",
      "PROTOCOL_LEVEL_4",
      "PROTOCOL_BOOKING",
      "HUNDI",
      "HUNDI_REPORT",
      "BUS",
      "BUS_TICKET_COUNTER",
      "BUS_TICKET_REPORT",
      "RENT_AND_LEASE_REPORT",
      "PUBLICATION",
    ],
  },
];
