import { Button, Col, Input, Row, notification, DatePicker } from "antd";
import React, { useEffect, useRef, useState } from "react";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
// import { BaseContext } from "../../../contexts/BaseContext";
// import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
// import postBookingDataFunction from "../../../utils/tms-post-booking-data/tms-post-booking-data";
import TmsCancelSubmitButtons from "../../../components/tms-customs/tms-cancel-submit-buttons";
import TmsSelect from "../../../components/tms-customs/tms-select";
import TmsRentsService from "../../../services/tms-rents.service";
import TmsBuildingsService from "../../../services/tms-buildings-and-shops";
import LeaseInvoice from "../../../components/tms-customs/rents-and-leases/lease-invoice";
import { arrowRight } from "../../../assets/images/tms-image-list";
import moment from "moment/moment";
import Modal from "antd/lib/modal/Modal";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import TmsLicenseService from "../../../services/tms-license.service";
const TmsPosRentsAndLeases = ({
  router,
  // from,
  // bookingUpdate,
  // match,
  // captured,
}) => {
  const inputEl = useRef();
  // const [printType, setPrintType] = useState("a4");
  // const { userDetails } = useContext(BaseContext);
  const [invoiceId, setInvoiceId] = useState("");
  const [data, setData] = useState();
  const [array, setArray] = useState([]);
  const [amount, setAmount] = useState("");
  const [cheqDDNo, setCheqDDNo] = useState("");
  const [date, setDate] = useState();
  const [nameOfBank, setNameOfBank] = useState("");
  const [remarks, setRemarks] = useState("");
  const [devAccount, setDevAccount] = useState();
  const [scantype, setScanType] = useState("");
  const [clearDate, setClearDate] = useState("");
  // const [postBookingData, setPostBookingData] = useState();
  const setInputFocus = () => {
    inputEl?.current?.input?.focus();
  };
  const [externalPrint, setExternalPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("land-lease");
  // const [templeDetails] = useState(
  //   JSON.parse(localStorage.getItem("templeDetails"))
  // );

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFetchInvoice();
    }
  };

  const receiptData = (data) => {
    let schemaBody = [];
    data?.forEach((item) => {
      const generalBody = [
        ["Invoice Number", item?.invoiceNumber ?? "null", { margin: "0" }],
        [
          "Acknowledgement Number",
          item?.acknowledgementNumber === ""
            ? "null"
            : item?.acknowledgementNumber,
          { margin: "0" },
        ],
        [
          "Invoice Date",
          item?.lastInvoiceGeneratedOn
            ? moment(item?.lastInvoiceGeneratedOn).format("DD-MM-YYYY")
            : "null",
          { margin: "0" },
        ],
        ["Location", item?.village ?? "null", { margin: "0" }], // data not available
        ["Survey No", item?.surveyNo ?? "null", { margin: "0" }], // data not available
        ["Survey Breakup", item?.blockNumber ?? "null", { margin: "0" }], // data not available
        ["Building Name", item?.buildingName ?? "null", { margin: "0" }],
        ["Block/Floor No", item?.blockOrFloorName ?? "null", { margin: "0" }],
        ["Shop Number", item?.shopNumber ?? "null", { margin: "0" }],
        ["Extent", item?.extent ?? "null", { margin: "0" }],
        ["Mode of Payment", item?.paymentType ?? "null", { margin: "0" }],
        ["Cheque Number", item?.chequeNumber ?? "null", { margin: "0" }],
        ["Bank Name", item?.bankName ?? "null", { margin: "0" }],
        ["Payment ID", item?.cartCounterPaymentId ?? "null", { margin: "0" }],
        [
          "Payment Date",
          item?.createdAt
            ? moment(item?.createdAt).format("DD-MM-YYYY")
            : "null",
          { margin: "0" },
        ],
        ["MR Number", item?.mrNo ?? "null", { margin: "0" }],
        [
          "MR Date",
          item?.mrDate ? moment(item?.mrDate).format("DD-MM-YYYY") : "null",
          { margin: "0" },
        ],
        [
          "Lease Amount",
          item?.leaseRate ? `Rs. ${Number(item?.leaseRate)}.00` : "null",
          { margin: "0" },
        ],
        [
          "Arrear Amount",
          item?.arrearAmount ? `Rs. ${Number(item?.arrearAmount)}.00` : "null",
          { margin: "0" },
        ],
        [
          "Gst",
          item?.gst ? `Rs. ${Number(item?.gst)}.00` : "null",
          { margin: "0" },
        ],
        [
          "Total Amount",
          item?.amount ? `Rs. ${Number(item?.amount ?? 0)}.00 ` : "null",
          { margin: "0", fontWeight: "800" },
        ],
        ["Lease Name", item?.lesseName ?? "null", { margin: "0" }],
        ["Lease Type", item?.lesseType ?? "null", { margin: "0" }],
        [
          "Lease Period",
          item?.leasePeriodStartDate
            ? `${moment(item?.leasePeriodStartDate)?.format(
                "DD-MM-YYYY"
              )}-${moment(item?.leasePeriodEndDate)?.format("DD-MM-YYYY")}`
            : "null",
          { margin: "0" },
        ],
        ["Mobile Number", item?.mobileNumber ?? "null", { margin: "0" }],
        // [
        //   "",
        //   `(${numberToWords(item?.darshanamBooking?.price)} Rupees)`,
        //   { margin: "0", fontWeight: "bold" },
        // ],
        ["footer", item?.ticketId, {}, {}, {}, {}, item?.createdAt],
      ];
      const basicBody = [
        [
          "header",
          item?.counterCartItem?.categoryTransactionSequence?.split("-")?.pop(), // data not available
          {},
          item?.counterCartItem?.dailyCategoryTransactionSequence // data not available
            ?.split("-")
            ?.pop(),
          item?.ticketId,
        ],
        ["Ticket Id", item?.ticketId ?? "null", { margin: "0" }],
        [
          "Category",
          item?.category ?? "null",
          { margin: "0", fontWeight: "bold" },
        ], // data not available
      ];

      schemaBody = [...schemaBody, ...basicBody, ...generalBody];
    });
    setArray([...schemaBody]);
  };

  const handleFetchInvoice = async () => {
    setLoading(true);
    setData(undefined);
    // setPostBookingData(undefined);
    if (!type) {
      notification.error({ message: "Select Module type!" });
      return;
    }
    if (!invoiceId) {
      notification.error({ message: "Enter Invoice Number!" });
      return;
    }
    try {
      let data = {};
      if (scantype === "payment") {
        console.log(type);
        if (type === "land-lease") {
          data = await TmsRentsService.getInvoiceByInvoiceID(invoiceId);
        } else if (type === "shop-lease") {
          data = await TmsBuildingsService.getInvoiceByInvoiceID(invoiceId);
        } else if (type === "license-lease") {
          data = await TmsLicenseService.getInvoiceByInvoiceID(invoiceId);
        }
      } else {
        if (type === "land-lease") {
          data = await TmsRentsService.getInvoiceAcknowledgement(invoiceId);
        } else if (type === "shop-lease") {
          data = await TmsBuildingsService.getInvoiceAcknowledgement(invoiceId);
        } else if (type === "license-lease") {
          data = await TmsLicenseService.getInvoiceAcknowledgement(invoiceId);
        }
      }
      if (data) {
        setData(data);
      }
      setInputFocus();
      setLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! please login again.", router);
      setLoading(false);
      // setPostBookingData("");
    }
  };

  const handlePay = async () => {
    setLoading(true);

    try {
      let response = {};
      if (scantype === "payment") {
        if (amount === "" || amount === 0) {
          notification.error({ message: "Please enter a valid amount" });
          setLoading(false);
          return;
        }
        const temptype =
          type === "land-lease"
            ? data?.landLease?.paymentType
            : type === "shop-lease"
            ? data?.shopLease?.paymentType
            : data?.licenseLease?.paymentType;

        let body = {
          paymentMode: temptype,
        };
        if (temptype === "CASH" || temptype === "UPI" || temptype === "CARD") {
          if (remarks) body.cashRemarks = remarks;
          body.paymentProcessed = true;
          if (temptype === "UPI") {
            body.transactionId = cheqDDNo;
            body.chequeDepositDateOrTransactionDate =
              moment(date).format("YYYY-MM-DD");
          }
        } else if (
          temptype === "CHEQUE" ||
          temptype === "DD" ||
          temptype === "RTGS" ||
          temptype === "NEFT" ||
          temptype === "IMPS"
        ) {
          body.chequeNumber = cheqDDNo;
          body.chequeDepositDateOrTransactionDate =
            moment(date).format("YYYY-MM-DD");
          body.paymentProcessed = false;
        } else if (temptype === "ONLINE") {
          body.transactionId = cheqDDNo;
          body.devasthanamAccountNumber = devAccount;
        }
        if (temptype === "KIND") {
          body.kindItemQuantityKg = Number(amount);
          body.paymentProcessed = true;
        } else {
          body.amount = Number(amount);
        }
        if (type === "land-lease") {
          response = await TmsRentsService.handleInvoicePayment(
            invoiceId,
            body
          );
        } else if (type === "shop-lease") {
          response = await TmsBuildingsService.handleInvoicePayment(
            invoiceId,
            body
          );
        } else if (type === "license-lease") {
          response = await TmsLicenseService.handleInvoicePayment(
            invoiceId,
            body
          );
        }
      } else {
        if (clearDate === "" || !clearDate) {
          notification.error({
            message: "Please enter a clear transaction date.",
          });
          return;
        }
        let body = {
          remarks: remarks,
          chequeClearDateOrTransactionClearDate:
            moment(clearDate).format("YYYY-MM-DD"),
          paymentProcessed: true,
          amount:
            Number(
              data?.counterCartItem?.counterCart?.counterCartPayment.amount
            ) +
            Number(data?.counterCartItem?.counterCart?.counterCartPayment.gst),
        };

        if (type === "land-lease") {
          response = await TmsRentsService.handleInvoiceAcknowledgement(
            invoiceId,
            body
          );
        } else if (type === "shop-lease") {
          response = await TmsBuildingsService.handleInvoiceAcknowledgement(
            invoiceId,
            body
          );
        } else if (type === "license-lease") {
          response = await TmsLicenseService.handleInvoiceAcknowledgement(
            invoiceId,
            body
          );
        }
      }
      receiptData([response]);
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
      notification.success({ message: `${type} has been successfull` });
      setInvoiceId("");
      setData({});
    } catch (error) {
      catchErrorFunction(error, "Token expired!! please login again.", router);
      setLoading(false);
      // setPostBookingData("");
    }
  };

  useEffect(() => {
    setInputFocus();
  }, []);

  // const handlePrint = () => {
  //   setPostBookingData(postBookingDataFunction(data));
  //   setIsModalVisible(true);
  // };

  return (
    <>
      <Row
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row justify={"center"}>
          <p
            style={{
              fontSize: "30px",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            {type === "land-lease"
              ? "Land Lease Payment"
              : type === "shop-lease"
              ? "Shop Lease Payment"
              : type === "licenses"
              ? "License Payment"
              : "Select Payment Type"}
          </p>
        </Row>{" "}
        <Row
          gutter={[10, 10]}
          style={{ width: "100%", marginTop: "20px" }}
          justify="center"
        >
          <Col>
            <TmsSelect
              classname={"w-300"}
              type="col"
              setValue={(e) => {
                setType(e);
                setData({});
              }}
              label="Select Module"
              data={[
                { name: "Land Lease ", id: "land-lease" },
                { name: "Shop Lease ", id: "shop-lease" },
                { name: "Licence ", id: "license-lease" },
                // { name: "FDR ", id: "fdr" },
              ]}
              value={type}
              optional={true}
            />
          </Col>
          <Col>
            <TmsSelect
              classname={"w-300"}
              type="col"
              setValue={(e) => {
                setScanType(e);
              }}
              label="Scan type"
              data={[
                { name: "Payment Scan", id: "payment" },
                { name: "Acknowlegdement Scan", id: "acknowlegdement" },
              ]}
              value={scantype}
              optional={true}
            />
          </Col>
          <Col>
            <Row style={{ marginBottom: "5px" }}> Invoice No: </Row>
            <div className="flex" style={{ display: "flex" }}>
              <Input
                maxLength={220}
                style={{ width: "300px" }}
                size="medium"
                ref={inputEl}
                value={invoiceId}
                onChange={(e) => {
                  setInvoiceId(e.target.value.trim());
                }}
                onKeyDown={onKeyDown}
              />
              <Button
                className="ml-20"
                type="primary"
                onClick={handleFetchInvoice}
                style={{
                  borderRadius: "2px",
                  background: "#E11513 0% 0% no-repeat padding-box",
                  border: "none",
                }}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Row>
      <br />
      <Row gutter={[40, 40]} justify="center">
        {data?.id && (
          <>
            <Col span={15}>
              <LeaseInvoice
                data={data}
                type={type}
                display={true}
                scantype={scantype}
                invoiceId={invoiceId}
              />
            </Col>
            <Col span={8} style={{ background: "white", padding: "50px" }}>
              {scantype === "payment" ? (
                <>
                  <Row>
                    <Row className={"t-left"}>Payment Mode</Row>
                    <Input
                      maxLength={220}
                      type="text"
                      placeholder="Payment Type"
                      value={
                        type === "land-lease"
                          ? data?.landLease?.paymentType
                          : type === "shop-lease"
                          ? data?.shopLease?.paymentType
                          : data?.licenseLease?.paymentType
                      }
                      disabled={true}
                    />
                  </Row>
                  <br />
                  <Row>
                    <Row className={"t-left"}>Amount/Collection</Row>
                    <Input
                      maxLength={220}
                      min="0"
                      type="number"
                      placeholder="Amount/Collection"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </Row>{" "}
                  <br />
                  {["CHEQUE", "DD", "ONLINE", "UPI"]?.includes(
                    type === "land-lease"
                      ? data?.landLease?.paymentType
                      : type === "shop-lease"
                      ? data?.shopLease?.paymentType
                      : data?.licenseLease?.paymentType
                  ) && (
                    <>
                      {["land-lease", "shop-lease", "license-lease"].includes(
                        type
                      )
                        ? data?.landLease?.paymentType !== "UPI" && (
                            <Row>
                              <Row className={"t-left"}>Name of Bank</Row>
                              <Input
                                maxLength={220}
                                type="text"
                                placeholder="Name of Bank"
                                value={nameOfBank}
                                onChange={(e) => setNameOfBank(e.target.value)}
                              />
                            </Row>
                          )
                        : ""}
                      <br />

                      <Row>
                        <Row className={"t-left"}>({type}) Txn No</Row>
                        <Input
                          maxLength={220}
                          type="text"
                          placeholder="Amount/Collection"
                          value={cheqDDNo}
                          onChange={(e) => setCheqDDNo(e.target.value)}
                        />
                      </Row>
                      <br />
                      <Row>
                        <Row className={"t-left"}>Date</Row>
                        <DatePicker
                          disabled={false}
                          value={date}
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                          onChange={(date) => {
                            setDate(date);
                          }}
                          size="medium"
                        />{" "}
                      </Row>
                      <br />
                    </>
                  )}
                  {["RTGS", "NEFT", "IMPS"].includes(
                    data?.landLease?.paymentType
                  ) && (
                    <>
                      {" "}
                      <br />
                      <Row>
                        <Row className={"t-left"}>Devasthanam AC Number</Row>
                        <Input
                          maxLength={220}
                          min="0"
                          type="number"
                          placeholder="Account Number"
                          value={devAccount}
                          onChange={(e) => setDevAccount(e.target.value)}
                        />
                      </Row>{" "}
                      <br />
                    </>
                  )}
                </>
              ) : (
                <>
                  {scantype === "acknowlegdement" && (
                    <>
                      <br />
                      <Row>
                        <Row className={"t-left"}>Txn Clear Date</Row>
                        <DatePicker
                          disabled={false}
                          value={clearDate}
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                          onChange={(record) => {
                            setClearDate(record);
                          }}
                          size="medium"
                        />{" "}
                      </Row>
                      <br />
                    </>
                  )}
                </>
              )}
              <Row>
                <Row className={"t-left"}>Remarks</Row>
                <Input
                  maxLength={220}
                  type="text"
                  placeholder="Remarks"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Row>{" "}
              <TmsCancelSubmitButtons
                icon={arrowRight}
                cancelOnlick={() => {
                  setLoading(false);
                  setData();
                  setAmount("");
                  setCheqDDNo("");
                  setRemarks("");
                  setDevAccount("");
                  setDate("");
                }}
                submitOnclick={() => !loading && handlePay(router)}
                loading={loading}
              />{" "}
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Modal
          visible={isModalVisible}
          width={450}
          footer={null}
          closable={false}
          centered
          maskClosable={false}
        >
          <TmsPrintTicket
            data={array}
            setData={setArray}
            type={scantype === "payment" ? "PAYMENT" : "ACKNOWLEDGEMENT"} //need to make it dynamic for ACKNOWLEDGEMENT
            from="RECEIPT"
            externalPrint={externalPrint}
            setExternalPrint={setExternalPrint}
            setButtonLoading={setLoading}
            setPrintTicketModal={setIsModalVisible}
            ticketType={"A4"}
          />
        </Modal>
      </Row>
    </>
  );
};

export default withRouter(TmsPosRentsAndLeases);
