import { Button, Divider, Input, Row } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import TmsInput from "../../components/tms-customs/tms-input";
import { BaseContext } from "../../contexts/BaseContext";
import TmsUserService from "../../services/tms-user/tms-user.service";
import catchErrorFunction from "../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../utils/tms-with-router/tms-with-router";

const TmsProfile = ({ router }) => {
  const { userDetails } = useContext(BaseContext);

  const [email, setEmail] = useState(userDetails?.user?.email ?? "");
  const [emailFlagLoading, setEmailFlagLoading] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    console.log(userDetails);
    if (!userDetails?.user?.email) {
      getEmailStatus();
    } else {
      setEmail(userDetails?.user?.email);
    }
  }, [userDetails]);

  const verfiyEmail = async () => {
    setEmailFlagLoading(true);
    try {
      await TmsUserService.verifyEmail(email);
      setMsg(
        "A verification Link has been sent to your Email Address.Please verify your email and reload this page after verfication."
      );
      setEmailFlagLoading(false);
    } catch (e) {
      if (
        e?.message ===
        "You have already requested an email change today. Please try again tomorrow."
      ) {
        setEmail("");
      }
      setMsg(e?.message);
      setEmailFlagLoading(false);
    }
  };

  const getEmailStatus = async () => {
    try {
      let res = await TmsUserService.getEmailStatus(userDetails?.user?.email);
      if (
        userDetails?.user?.email === null &&
        res.email !== "" &&
        res.createdAt &&
        moment().diff(moment(res.created_at), "days") === 0
      ) {
        setMsg(
          `You have already sent an verification email to ${res.email}. Please verify your email.`
        );
      }
    } catch (e) {
      catchErrorFunction(e, "Token expired!! Login again", router);
      setMsg(e?.message);
    }
  };

  return (
    <div>
      <h2 style={{ textAlign: "left" }}>Profile Details</h2>
      <Divider />
      <Row>
        {" "}
        <TmsInput
          type="text"
          label="Phone Number"
          value={userDetails?.user?.phone}
          span={6}
          placeholder={"Enter Email"}
          disabled={true}
        />
      </Row>
      <br />
      <Row>
        {" "}
        <TmsInput
          type="text"
          label="Enter Display Name"
          value={userDetails?.user?.displayName ?? ""}
          setValue={""}
          span={6}
          placeholder={"Enter D"}
          disabled={true}
        />
      </Row>{" "}
      <br />
      <Row justify="start">Email</Row>
      <Row justify="baseline">
        <Input
          style={{ maxWidth: "310px", height: "max-content" }}
          className="formRow"
          type="email"
          placeholder="Enter Email ID"
          maxLength={220}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <Button
          loading={emailFlagLoading}
          style={{
            marginLeft: "10px",
            backgroundColor:
              userDetails?.user.email === email ? "lightGreen" : "",
            color: userDetails?.user.email === email ? "black" : "white",
          }}
          onClick={async () => {
            await verfiyEmail();
          }}
          type={userDetails?.user.email === email ? "success" : "primary"}
          disabled={userDetails?.user.email === email ? true : false}
        >
          {userDetails?.user.email === email ? "Verified" : "Verify"}
        </Button>
      </Row>
      <p style={{ color: "red", textAlign: "left" }}>{msg}</p>
    </div>
  );
};

export default withRouter(TmsProfile);
