import {
  Button,
  Select,
  Card,
  Checkbox,
  Col,
  Input,
  notification,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../../../utils/tms-with-router/tms-with-router";
import "../../../../../../assets/css/tms-darshanam/tms-d-inner-pages-and.scss";
import TmsKalayanakattaService from "../../../../../../services/tms-kalayanakatta.service";
import catchErrorFunction from "../../../../../../utils/tms-catch-error/tms-catch-error";
import TmsShiftService from "../../../../../../services/tms-shift.service";
import TmsCounterService from "../../../../../../services/tms-counter.service";

const TmsKalyanaKattaAddPerformer = ({
  viewKalyanakattaData,
  editFlag,
  productId,
  setEditFlag,
  settmsModalVisible,
  setUpdateFlag,
  router,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const [name, setName] = useState("");
  const [sittingNumber, setSittingNumber] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [mobile, setMobile] = useState("");
  const [counterId, setCounterId] = useState("");
  const [shiftId, setShiftId] = useState("");
  const [shiftList, setShiftList] = useState([]);
  const [counterList, setCounterList] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    if (
      name === "" ||
      mobile === "" ||
      shiftId === "" ||
      counterId === "" ||
      sittingNumber === ""
    ) {
      notification.error({ message: "Please enter all the details" });
      setLoading(false);
      return;
    }
    let body = {
      name,
      sittingNumber,
      mobile: `+91${mobile}`,
      isActive,
      counterId: counterId.id,
      counterShiftTimingId: shiftId.id,
    };

    try {
      if (editFlag) {
        await TmsKalayanakattaService.editKalyanakattaPerformer(
          productId,
          body
        );
        notification.success({ message: "Performer Edited Successful" });
        setEditFlag(false);
      } else {
        await TmsKalayanakattaService.createKalayanakattaPerformer(body);
        notification.success({ message: "Performer Created Successful" });
        handleFuction();
      }
      setUpdateFlag(Math.random() + 12);
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const handleFuction = () => {
    setName("");
    setMobile("");
    setIsActive(false);
    setShiftId("");
    setSittingNumber("");
    setCounterId("");
  };

  const loadShiftsandCounters = async () => {
    try {
      setDataLoading(true);
      const shifts = await TmsShiftService.getAllShifts();
      const counters = await TmsCounterService.getAllCounter();
      setCounterList(counters?.items);
      setShiftList(shifts.items);
      setDataLoading(false);
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login", router);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (editFlag && !dataLoading) {
      setDataLoading(true);

      setShiftId(
        shiftList.find(
          (item) => item.id === viewKalyanakattaData?.counterShiftTimingId
        )
      );
      setCounterId(
        counterList.find((item) => item.id === viewKalyanakattaData?.counterId)
      );

      setName(viewKalyanakattaData?.name);
      setMobile(viewKalyanakattaData?.mobile?.replaceAll("+91", ""));
      setIsActive(viewKalyanakattaData?.isActive);
      setSittingNumber(viewKalyanakattaData?.sittingNumber);
      setDataLoading(false);
    }
  }, [viewKalyanakattaData, dataLoading]);

  useEffect(() => {
    loadShiftsandCounters();
  }, []);

  return (
    <Row className="formRow" style={{ width: "100%" }}>
      <Card style={{ width: "100%" }}>
        <Row className="formRow">
          <label className="formLabel">Performer Name</label>
          <Input
            maxLength={220}
            className="formInput"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Row>
        <Row className="formRow">
          <label className="formLabel">Mobile </label>
          <Input
            maxLength={220}
            className="formInput"
            size="medium"
            type="number"
            min="0"
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            addonBefore={"+91"}
            placeholder="Please Enter Mobile Number"
            style={{ width: "100%" }}
            value={mobile}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              setMobile(`${e.target.value}`);
            }}
          />
        </Row>

        <Row justify="center" style={{ width: "100%", paddingBottom: "20px" }}>
          <label className="formLabel">Select POS</label>

          <Select
            width="100%"
            className="formInput"
            value={counterId?.name}
            placeholder="Please select a POS"
            onChange={(e) => {
              let temp = counterList?.find((item) => item.name === e);
              setCounterId(temp);
            }}
          >
            {counterList?.map((item, i) => {
              return (
                <Select.Option
                  key={item.name + "pos-list" + i}
                  value={item.name}
                >
                  {item.name}{" "}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
        <Row justify="center" style={{ width: "100%", paddingBottom: "20px" }}>
          <label className="formLabel">Select Shift</label>

          <Select
            width="100%"
            className="formInput"
            value={shiftId?.name}
            placeholder="Please select a Shift"
            onChange={(e) => {
              let temp = shiftList?.find((item) => item.name === e);
              setShiftId(temp);
            }}
          >
            {shiftList?.map((item, i) => {
              return (
                <Select.Option
                  key={item.name + "shift-list" + i}
                  value={item.name}
                >
                  {item.name}{" "}
                </Select.Option>
              );
            })}
          </Select>
        </Row>

        <Row gutter={[20, 20]} className="formRow">
          <label className="formLabel">Sitting Number</label>
          <Col className="formCols-2" style={{ paddingLeft: "5px" }}>
            <Input
              maxLength={220}
              type="number"
              min="0"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              value={sittingNumber}
              onChange={(e) => setSittingNumber(Number(e.target.value))}
            />
          </Col>

          <Col className="formCols-2 contantCenter">
            <label style={{ paddingRight: "10px" }}>
              Enable Performer Status
            </label>
            <Checkbox
              style={{ paddingRight: "10px" }}
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col span={5}></Col>
          <Col className="formCols-2  pl-0">
            <Button
              className="formButton"
              type="primary"
              ghost
              loading={loading}
              onClick={() => {
                if (editFlag) {
                  settmsModalVisible(false);
                }
                handleFuction();
              }}
            >
              {editFlag ? "Return" : "Clear"}
            </Button>
          </Col>
          <Col className="formCols-2 buttonPad">
            <Button
              className="formButton"
              type="primary"
              // loading={loading}
              onClick={() => {
                fetchData();
              }}
            >
              {dataLoading ? "Loading.." : "Submit"}
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default withRouter(TmsKalyanaKattaAddPerformer);
