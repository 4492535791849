import React from "react";
import useLicensesStore from "../../../../zustand/licenses.store";
import TmsSelect from "../../tms-select";

const LicenseLeaseSelect = ({ span }) => {
  const {
    createdLicenseLeaseData,
    setSelectedLicenseLease,
    selectedLicenseLease,
  } = useLicensesStore((state) => {
    return {
      createdLicenseLeaseData: state.createdLicenseLeaseData,
      setSelectedLicenseLease: state.setSelectedLicenseLease,
      selectedLicenseLease: state.selectedLicenseLease,
    };
  });

  return (
    <TmsSelect
      type="col"
      span={span}
      label="Select a Lease No"
      setValue={(e) => {
        setSelectedLicenseLease(
          createdLicenseLeaseData?.items?.find((item) => item.id === e)
        );
      }}
      selectClass="w-100"
      value={selectedLicenseLease?.id}
      data={createdLicenseLeaseData?.items?.map((item) => {
        return {
          name: `Name: ${item?.license?.licenseName}, Lease ID: ${item.id}`,
          id: item.id,
        };
      })}
      optional={true}
    />
  );
};

export default LicenseLeaseSelect;
