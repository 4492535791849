import { notification } from "antd";
import moment from "moment";
import { create } from "zustand";
import {
  additionalArrearStates,
  additionalBuildingEncroahmentStates,
  arrearStates,
  buildingEncroachmentStates,
  createBlockStates,
  createBuildingStates,
  createShopStates,
  shopleaseAddtionalStates,
  shopLeaseCreationStates,
} from "../constants/states-data.data";
import TmsBuildingsService from "../services/tms-buildings-and-shops";
import TmsRentsService from "../services/tms-rents.service";
import catchErrorFunction from "../utils/tms-catch-error/tms-catch-error";
import { exportExcel } from "../utils/tms-excel/tms-excel";
const regex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[0-9A-Z]{1}/;

const useBuildingsStore = create((set, get) => {
  const initialState = {
    loading: false,
    submitLoading: false,
    excelLoading: false,
    village: "",
    status: false,
    mandal: "",
    district: "",
    country: "India",
    state: "",
    zip: "",
    landmark: "",
    propertyType: "",
    builtAreaInSqFeet: "",
    numberOfBlocks: "",
    idProofNumber: "",
    numberOfShops: "",
    geoLocation: "",
    buildingName: "",
    shopNumber: "",
    holdingStatus: false,
    shopStatus: "",
    extentLength: "",
    extentBreadth: "",
    category: "",
    surveyNumber: "",
    totalLandValue: "",
    natureOfBuissness: "",
    landArea: "",
    landValue: "",
    blockNumber: "",
    geoTagging: "",
    waterSource: "",
    remarks: "",
    extent: "",
    reason: "",
    file: "",
    legalAction: false,
    encroachmentDurationInDays: "",
    encroacherAddress: "",
    encroacherName: "",
    deleteLoading: "",
    westBoundary: "",
    eastBoundary: "",
    northBoundary: "",
    southBoundary: "",
    natureOfBusiness: "",
    goodWillAmount: "",
    encroacherNatureOfBusiness: "",
    arrearPeriodInMonths: "",
    //Data from API's & selected field
    institutionData: [],
    selectedInstitution: "",
    createdBuildingsData: [],
    selectedBuilding: "",
    createdBlocksData: [],
    surveyNoDataByLandId: [],
    selectedBlock: "",
    createdShopData: [],
    selectedShop: "",
    createdEncroachmentsData: [],
    selectedEncroachment: "",
    createdArrearsData: [],
    selectedArrear: "",
    createdBuildingLeaseData: [],
    selectedBuildingLease: "",
    address: "",
    enchrochmentDuration: "",
    encroachmentDurationInMonths: "",
    casePDF: [],
    caseNo: "",
    legalReason: "",
    mobileNumber: "",

    //lease creation
    leasePeriodStartDate: moment(),
    leasePeriodEndDate: moment(),
    dueDate: "",
    paymentTerm: "",
    gstAmount: "",
    paymentType: "",
    kindItem: "",
    kindItemUnitsInKg: "",
    leaseRate: "",
    depositAmount: "",
    mrNo: "",
    mrDate: moment(),
    lesseType: "",
    lesseName: "",
    gstNo: "",
    incorporationOrPanNo: "",
    contactPersonName: "",
    gender: "",
    idProofType: "",
    uploadIdProof: [],
    leaseApprovedByCompetentAuthority: "",
    uploadContemptoryCopy: [],
    uploadApprovedDocumentOrderNo: "",
    uploadApprovedDocumentOrderDate: "",
    eoProceedingUploadDocument: [],
    //arrear
    arrearAmount: "",
    interestAmount: "",
    //reports-data
    leaseReports: [],
    buildingReport: [],
    encroachmentReport: [],
    arrearReport: [],
    arrayStates: [
      "eoProceedingUploadDocument",
      "uploadContemptoryCopy",
      "uploadIdProof",
      "donorDoc",
      "casePDF",
      "institutionData",
      "createdBuildingsData",
      "createdBlocksData",
      "createdBuildingLeaseData",
      "createdEncroachmentsData",
      "createdShopsData",
      "surveyNoDataByLandId",
    ],
    datesArray: [
      "uploadApprovedDocumentOrderDate",
      "mrDate",
      "leasePeriodStartDate",
      "leasePeriodEndDate",
    ],
  };

  const services = {
    "blocks-list": "blockChangeStatus",
    "lease-list": "shopLeaseChangeStatus",
    "encroachment-list": "enchrochmentChangeStatus",
    "shops-list": "shopChangeStatus",
    "buildings-list": "buildingChangeStatus",
  };

  const generateSetterFunctions = (stateObject) => {
    const setters = {};
    for (const key in stateObject) {
      setters[`set${key.charAt(0).toUpperCase()}${key.slice(1)}`] = (value) =>
        set({ [key]: value });
    }
    return setters;
  };

  return {
    ...initialState,
    ...generateSetterFunctions(initialState),
    //Actions
    checkForEmptyStates(array) {
      array.forEach((element) => {
        const value = get()[element];
        if (value === "") {
          throw new Error(
            `${element
              .split(/(?=[A-Z])/)
              .join(" ")} should not be empty. Please fill all the fields`
          );
        }
      });
    },
    clearStates(array, dataArrays) {
      const setters = {};
      const data = [...array, ...dataArrays];
      data.forEach((item) => {
        if (get().datesArray.includes(item)) {
          setters[item] = moment();
        } else if (get().arrayStates.includes(item)) {
          setters[item] = [];
        } else setters[item] = "";
      });
      set({ ...setters });
    },
    async handleChangeStatus(id, status, router) {
      set({ submitLoading: true });
      try {
        await TmsBuildingsService[
          services[router?.location?.pathname?.split("/")?.pop()]
        ](id, status);
        notification.success({ message: "Updated successfully" });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async fetchInstitutionList(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsRentsService.getCreatedInstitutions(
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          institutionData: res,
          loading: false,
          selectedInstitution: "",
          selectedBuilding: "",
          selectedShop: "",
          selectedBlock: "",
          selectedBuildingLease: "",
          createdBuildingsData: [],
          createdBlocksData: [],
          createdBuildingLeaseData: [],
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token Expired!!,Login Again", router);
        set({ loading: false });
        return;
      }
    },
    async deleteInstitution(id, router) {
      try {
        set({ loading: true });

        await TmsBuildingsService.deleteInstitution(id);
        notification.success({
          message: "Institution Deleted Successful",
        });
        set({ loading: false });

        // setUpdateFlag(Math.Random + 1);
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ loading: false });
      }
    },
    async fetchCreatedBuildingsList(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getCreatedBuildings(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({
            createdBuildingsData: res,
            loading: false,
            selectedShop: "",
            createdShopData: [],
            selectedBlock: "",
            createdBlocksData: [],
          });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },

    async handleCreateBuilding(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createBuildingStates);

        const body = {
          buildingName: get().buildingName,
          propertyType: get().propertyType,
          builtAreaInSqFeet: Number(get().builtAreaInSqFeet),
          geoLocation: get().geoLocation,
          country: get().country,
          state: get().state,
          district: get().district,
          mandal: get().mandal,
          village: get().village,
          pincode: get().zip,
          landmark: get().landmark,
          institutionId: get().selectedInstitution,
          numberOfBlocks: Number(get().numberOfBlocks),
          numberOfShops: Number(get().numberOfShops),
        };

        await TmsBuildingsService.createBuildings(body);
        get().clearStates(createBuildingStates, []);
        notification.success({ message: "Building created successfully" });
        get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false, status: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedBuilding(landId, router) {
      try {
        set({ deleteLoading: true });
        await TmsBuildingsService.deleteCreatedBuildings(landId);
        notification.success({
          message: `Land with ID:${landId} Deleted Successful`,
        });
        set({ deleteLoading: false });
        get().fetchCreatedLandList(1, 1000, "ASC", "createdAt");
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchBlocks(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getCreatedBlocks(
          get().selectedBuilding.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({
            createdBlocksData: res,
            loading: false,
            selectedShop: "",
            createdShopData: [],
          });

          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    // async fetchSurveyNumbersByLandID(
    //   tableCurrentPage,
    //   tablePageSize,
    //   sortType,
    //   sortBy,
    //   router
    // ) {
    //   try {
    //     set({ loading: true });
    //     let res = await TmsBuildingsService.getCreatedSurveyNumbers(
    //       get().selectedLand.id,
    //       tableCurrentPage,
    //       tablePageSize,
    //       sortType,
    //       sortBy
    //     );
    //     set({
    //       surveyNoDataByLandId: res,
    //       loading: false,
    //       selectedSNBreakup: "",
    //       createdShopData: [],
    //     });

    //     return;
    //   } catch (e) {
    //     catchErrorFunction(e, "Token expired!! Login again", router);
    //     set({ loading: false });
    //     return;
    //   }
    // },
    async handleCreateBlock(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createBlockStates);

        const body = {
          propertyType: get().selectedBuilding?.propertyType,
          blockOrFloorName: get().blockNumber,
          numberOfShops: Number(get().numberOfShops),
          buildingId: get().selectedBuilding?.id,
          institutionId: get().selectedInstitution,
          status: get().status,
        };

        await TmsBuildingsService.createBlock(body);
        get().clearStates(createBlockStates, ["createdBuildingsData"]);
        notification.success({
          message: "Block/Floor Number created successfully",
        });
        get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async handleUpdateBlock(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          propertyType: record?.propertyType,
          blockOrFloorName: record?.blockOrFloorName,
          numberOfShops: Number(record?.numberOfShops),
          buildingId: record?.buildingId,
          institutionId: get().selectedInstitution,
          status: record.status,
        };

        await TmsBuildingsService.updateBlock(body, record.id);
        notification.success({
          message: "Block/Floor Number updated successfully",
        });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async deleteBlock(block, router) {
      try {
        set({ deleteLoading: true });
        await TmsBuildingsService.deleteBlock(block);
        notification.success({
          message: `Block with ID:${block} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchShopsByBlock(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getCreatedShopsByBlock(
          get().selectedBlock?.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({ createdShopData: res, loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchShopsByBuilding(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getCreatedShopsByBuilding(
          get().selectedBuilding?.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({ createdShopData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleShopCreation(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(createShopStates);

        const body = {
          shopNumber: get().shopNumber,
          propertyType: get().selectedBuilding?.propertyType,
          shopExtentLength: get().extentLength,
          shopExtentBreadth: get().extentBreadth,
          shopStatus: get().shopStatus,
          institutionId: get().selectedInstitution,
          buildingId: get().selectedBuilding?.id,
          status: get().status,
          blockOrFloorId: get().selectedBlock?.id,
        };

        await TmsBuildingsService.createShops(body);
        get().clearStates(createShopStates, []);
        notification.success({
          message: "Shop created successfully",
        });
        get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);

        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async handleShopUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          shopNumber: record.shopNumber,
          propertyType: record?.propertyType,
          shopExtentLength: record.shopExtentLength,
          shopExtentBreadth: record.shopExtentBreadth,
          shopStatus: record.shopStatus,
          institutionId: get().selectedInstitution,
          buildingId: get().selectedBuilding?.id,
          status: record.status,
          blockOrFloorId: record.blockOrFloorId,
        };

        await TmsBuildingsService.updateShops(body, record.id);
        notification.success({
          message: "Shop updated successfully",
        });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again", router);
        set({ submitLoading: false });
      }
    },
    async deleteShop(shopID, router) {
      try {
        set({ deleteLoading: true });
        await TmsBuildingsService.deleteShops(shopID);
        notification.success({
          message: `Shop with ID:${shopID} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchEncroachments(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getCreatedEncroachment(
          get().selectedInstitution,
          get().selectedBuilding?.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy,
          router
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({ createdEncroachmentsData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLandLeases(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getCreatedLandLeases(
          get().selectedInstitution,
          get().selectedBuilding?.id,
          get().selectedBlock?.id,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({ createdBuildingLeaseData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleLeaseCreation(router) {
      //TO-DO Add status and GST amount
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates([
          ...shopLeaseCreationStates,
          "leaseRate",
          "depositAmount",
          "mrNo",
          "mrDate",
        ]);

        if (!get().leaseApprovedByCompetentAuthority) {
          if (get().reason === "") {
            throw new Error(
              "Please enter the lease approval reason in reason field"
            );
          }
        }

        const body = {
          natureOfBusiness: get().natureOfBusiness,
          shopExtentLength: get().selectedShop?.shopExtentLength,
          shopExtentBreadth: get().selectedShop?.shopExtentLength,
          leasePeriodStartDate: moment(get().leasePeriodStartDate).format(
            "YYYY-MM-DD"
          ),
          leasePeriodEndDate: moment(get().leasePeriodEndDate).format(
            "YYYY-MM-DD"
          ),
          goodWillAmount: Number(get().goodWillAmount),
          dueDate: Number(get().dueDate),
          paymentTerm: get().paymentTerm,
          paymentType: get().paymentType,
          lesseType: get().lesseType,
          lesseName: get().lesseName,
          mobileNumber: `+91${get().mobileNumber}`,
          gstPercentage: Number(get().gstAmount),
          address: get().address,
          village: get().village,
          mandal: get().mandal,
          district: get().district,
          state: get().state,
          pincode: get().zip,
          idProofType: get().idProofType,
          idProofNumber: get().idProofNumber,
          uploadIdProof: get().uploadIdProof[0]?.url,
          leaseApprovedByContemptoryOrders:
            get().leaseApprovedByCompetentAuthority,
          eoProceedingUploadDocument: get().eoProceedingUploadDocument[0]?.url,
          status: get().status,
          buildingId: get().selectedBuilding?.id,
          blockOrFloorId: get().selectedBlock?.id,
          shopId: get().selectedShop?.id,
          institutionId: get().selectedInstitution,
        };

        body.leaseRate = Number(get().leaseRate);
        body.depositAmount = Number(get().depositAmount);
        body.mrNo = get().mrNo;
        body.mrDate = moment(get().mrDate).format("YYYY-MM-DD");

        if (get().lesseType === "individual") {
          body.gender = get().gender;
        } else {
          if (get().gstNo !== "") {
            if (!regex.test(get().gstNo)) {
              throw new Error(`Invalid GST Number`);
            }
          }
          body.gstNo = get().gstNo;
          body.contactPersonName = get().contactPersonName;
          body.incorporationOrPanNo = get().incorporationOrPanNo;
        }
        if (get().leaseApprovedByCompetentAuthority) {
          get().checkForEmptyStates(
            [
              "uploadContemptoryCopy",
              "uploadApprovedDocumentOrderNo",
              "uploadApprovedDocumentOrderDate",
            ],
            []
          );
          body.uploadContemptoryCopy = get().uploadContemptoryCopy[0]?.url;
          body.uploadApprovedDocumentOrderNo =
            get().uploadApprovedDocumentOrderNo;
          body.uploadApprovedDocumentOrderDate = moment(
            get().uploadApprovedDocumentOrderDate
          ).format("YYYY-MM-DD");
        } else {
          body.reason = get().reason;
        }

        await TmsBuildingsService.createLandLeases(body);
        notification.success({ message: "Shop Lease created successfully" });
        get().clearStates(shopLeaseCreationStates, [
          ...shopleaseAddtionalStates,
          ...[
            "institutionData",
            "createdShopData",
            "createdBlocksData",
            "createdBuildingData",
          ],
        ]);
        get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleLeaseUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          natureOfBusiness: record.natureOfBusiness,
          shopExtentLength: record.shopExtentLength,
          shopExtentBreadth: record.shopExtentLength,
          leasePeriodStartDate: record.leasePeriodStartDate,
          leasePeriodEndDate: record.leasePeriodEndDate,
          gstPercentage: record.gstPercentage,
          dueDate: Number(record.dueDate),
          paymentTerm: record.paymentTerm,
          paymentType: record.paymentType,
          goodWillAmount: Number(record.goodWillAmount),

          leaseRate: record.leaseRate,
          depositAmount: record.depositAmount,
          mrNo: record.mrNo,
          mrDate: record.mrDate
            ? moment(record.mrDate ?? "").format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          lesseType: record.lesseType,
          lesseName: record.lesseName,
          mobileNumber: record.mobileNumber,
          address: record.address,
          village: record.village,
          mandal: record.mandal,
          district: record.district,
          state: record.state,
          pincode: record.pincode,
          idProofType: record.idProofType,
          idProofNumber: record.idProofNumber,
          uploadIdProof: record.uploadIdProof,
          leaseApprovedByContemptoryOrders:
            record.leaseApprovedByCompetentAuthority,
          eoProceedingUploadDocument: record.eoProceedingUploadDocument,
          status: record.status,
          buildingId: record.buildingId,
          blockOrFloorId: record.blockOrFloorId,
          shopId: record.shopId,
          institutionId: get().selectedInstitution,
        };
        if (record.lesseType === "individual") {
          body.gender = record.gender;
        } else {
          body.gstNo = record.gstNo;
          body.contactPersonName = record.contactPersonName;
          body.incorporationOrPanNo = record.incorporationOrPanNo;
        }
        if (record.leaseApprovedByCompetentAuthority) {
          body.uploadContemptoryCopy = record.uploadContemptoryCopy;
          body.uploadApprovedDocumentOrderNo =
            record.uploadApprovedDocumentOrderNo;
          body.uploadApprovedDocumentOrderDate =
            record.uploadApprovedDocumentOrderDate;
        } else {
          body.reason = record.reason;
        }

        await TmsBuildingsService.updateCreatedLandLease(body, record.id);
        notification.success({ message: "Shop Lease Updated succesfully" });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedShopLease(landId, router) {
      try {
        set({ deleteLoading: true });
        await TmsBuildingsService.deleteCreatedShopLease(landId);
        notification.success({
          message: `Shop Lease with ID:${landId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },
    async fetchLeaseInvoices(
      shopLeaseId,
      latest,
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getShopLeaseInvoices(
          shopLeaseId,
          latest,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({ loading: false });
        return res;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleEncroachment(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(buildingEncroachmentStates);

        const body = {
          encroacherName: get().encroacherName,
          encroacherAddress: get().encroacherAddress,
          encroachmentDurationInMonths: Number(
            get().encroachmentDurationInMonths
          ),
          legalAction: `${get().legalAction}`,
          status: get().status,
          propertyType: get().selectedShop.propertyType,
          encroacherNatureOfBusiness: get().encroacherNatureOfBusiness,
          buildingId: get().selectedBuilding?.id,
          institutionId: get().selectedInstitution,
          blockOrFloorId: get().selectedBlock?.id,
          shopId: get().selectedShop?.id,
        };
        if (get().remarks !== "") {
          body.remarks = get().remarks;
        }
        if (get().legalAction) {
          body.caseNumber = get().caseNo;
          body.file = get().casePDF[0]?.url;
        } else {
          body.reason = get().legalReason;
        }
        await TmsBuildingsService.createEncroachment(body);
        notification.success({
          message: "Successfully created encroachment record",
        });
        get().clearStates(
          buildingEncroachmentStates,
          additionalBuildingEncroahmentStates
        );
        get().fetchInstitutionList(1, 200, "ASC", "createdAt", router);

        set({
          submitLoading: false,
        });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleEncroachmentUpdate(record, router) {
      try {
        set({ loading: true });

        const body = {
          encroacherName: record.encroacherName,
          encroacherAddress: record.encroacherAddress,
          encroachmentDurationInMonths: Number(
            record.encroachmentDurationInMonths
          ),
          legalAction: `${record.legalAction}`,
          status: record.status,
          propertyType: record.propertyType,
          encroacherNatureOfBusiness: record.encroacherNatureOfBusiness,
          buildingId: record.buildingId,
          institutionId: get().selectedInstitution,
          blockOrFloorId: record.blockOrFloorId,
          shopId: record.shopId,
        };
        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }
        if (record.legalAction) {
          body.caseNumber = record.caseNo; //not registering
          body.file = record.file;
        } else {
          body.reason = record.reason;
        }

        await TmsBuildingsService.updateEncroachment(body, record.id);
        notification.success({
          message: "Successfully updated encroahment record",
        });
        set({
          loading: false,
        });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ loading: false });
      }
    },
    async fetchArrears(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router,
      dataType
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getCreatedArrears(
          get().selectedInstitution,
          get().selectedBuilding?.id ?? "",
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy,
          router
        );
        if (dataType === "return") {
          set({ loading: false });
          return res.items;
        } else {
          set({ createdArrearsData: res, loading: false });
          return;
        }
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleArrearCreation(router) {
      set({ submitLoading: true });
      try {
        get().checkForEmptyStates(arrearStates);

        if (!get().legalAction && get().legalReason === "") {
          throw new Error(
            "Please enter the lease approval reason in reason field"
          );
        }

        const body = {
          arrearAmount: Number(get().arrearAmount),
          interestAmount: Number(get().interestAmount),
          legalAction: `${get().legalAction}`,
          status: true,
          shopId: get().selectedShop?.id,
          blockOrFloorId: get().selectedBlock?.id,
          buildingId: get().selectedBuilding?.id,
          institutionId: get().selectedInstitution,
          shopLeaseId: get().selectedBuildingLease?.id,
          arrearPeriodInMonths: Number(get().arrearPeriodInMonths),
        };
        if (!get().legalAction) {
          body.reason = get().legalReason;
        } else {
          body.caseNumber = get().caseNo;
          body.file = get().casePDF[0]?.url;
        }
        if (get().remarks !== "") {
          body.remarks = get().remarks;
        }

        await TmsBuildingsService.createArrear(body);
        notification.success({
          message: "Arrear has been successfully creation.",
        });
        set({ submitLoading: false });
        get().clearStates(arrearStates, additionalArrearStates);
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleArrearUpdate(record, router) {
      set({ submitLoading: true });
      try {
        const body = {
          leasePeriodStartDate: record.leasePeriodStartDate,
          leasePeriodEndDate: record.leasePeriodEndDate,
          arrearAmount: record.arrearAmount,
          interestAmount: record.interestAmount,
          legalAction: record.legalAction,
          status: record.status,
          shopId: record.shopId,
          blockOrFloorId: record.blockOrFloorId,
          buildingId: record.buildingId,
          institutionId: get().selectedInstitution,
          shopLeaseId: record.shopLeaseId,
          arrearPeriodInMonths: Number(record.arrearPeriodInMonths),
        };

        if (!record.legalAction) {
          body.reason = record.reason;
        } else {
          body.caseNumber = record.caseNumber;
          body.file = record.file;
        }
        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }

        if (record.remarks !== "") {
          body.remarks = record.remarks;
        }

        await TmsBuildingsService.updateCreatedArrears(body, record.id);
        notification.success({
          message: "Arrear has been successfully updated.",
        });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async handleArrearBillUpdate(id, body, router) {
      set({ submitLoading: true });
      try {
        await TmsBuildingsService.updateArrearBill(body, id);
        notification.success({
          message: "Arrear Bill has been successfully updated.",
        });
        set({ submitLoading: false });
      } catch (e) {
        catchErrorFunction(e, "Token expired! Please Login again.", router);
        set({ submitLoading: false });
      }
    },
    async deleteCreatedArrear(arrearId, router) {
      try {
        set({ deleteLoading: true });
        await TmsBuildingsService.deleteCreatedArrear(arrearId);
        notification.success({
          message: `Arrear with ID:${arrearId} Deleted Successful`,
        });
        set({ deleteLoading: false });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token Expired, redirecting to login page in 5 seconds",
          router
        );
        set({ deleteLoading: false });
      }
    },

    //reports

    async fetchTotalReports(
      type,
      tableCurrentPage,
      tablePageSize,
      // sortType,
      // sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getCreatedReportsByType(
          type,
          tableCurrentPage,
          tablePageSize
          // sortType,
          // sortBy
        );
        if (type === "BUILDING") {
          set({
            buildingReport: res,
          });
        } else if (type === "ENCROACHMENT") {
          set({
            encroachmentReport: res,
          });
        } else if (type === "LEASE") {
          set({
            leaseReports: res,
          });
        } else if (type === "ARREAR") {
          set({
            arrearReport: res,
          });
        }
        set({ loading: false });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchBuildingReports(
      tableCurrentPage,
      tablePageSize,
      // sortType,
      // sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getCreatedBuildingReports(
          get().selectedInstitution ?? "",
          tableCurrentPage,
          tablePageSize
          // sortType,
          // sortBy
        );
        set({
          landReports: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchLeaseReports(
      tableCurrentPage,
      tablePageSize,
      leaseStartDate,
      leaseEndDate,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getLeaseReports(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          moment(leaseStartDate).format("YYYY-MM-DD"),
          moment(leaseEndDate).format("YYYY-MM-DD")
        );
        set({
          leaseReports: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchArrearReports(
      tableCurrentPage,
      tablePageSize,
      sortType,
      sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getArrearsReports(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize,
          sortType,
          sortBy
        );
        set({
          arrearReport: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async fetchEncroachmentReports(
      tableCurrentPage,
      tablePageSize,
      // sortType,
      // sortBy,
      router
    ) {
      try {
        set({ loading: true });
        let res = await TmsBuildingsService.getEncroahmentReports(
          get().selectedInstitution,
          tableCurrentPage,
          tablePageSize
          // sortType,
          // sortBy
        );
        set({
          encroachmentReport: res,
          loading: false,
        });
        return;
      } catch (e) {
        catchErrorFunction(e, "Token expired!! Login again", router);
        set({ loading: false });
        return;
      }
    },
    async handleExcelExport(type, router) {
      set({ excelLoading: true });
      const funtionType = {
        building: get().fetchCreatedBuildingsList,
        blocks: get().fetchBlocks,
        shop: get().fetchShopsByBuilding,
        lease: get().fetchLandLeases,
        arrear: get().fetchArrears,
        encroachment: get().fetchEncroachments,
      };
      try {
        const data = await funtionType[type](
          1,
          1000,
          "ASC",
          "createdAt",
          router,
          "return"
        );
        if (data.length === 0) {
          notification.info({ message: "No records found to Export" });
          set({ excelLoading: false });
          return;
        }
        exportExcel(data, `${type}-${moment().format("DD-MM-YYYY")}`, () => {
          set({ excelLoading: false });
        });
      } catch (error) {
        catchErrorFunction(
          error,
          "Token expired!! Please login again.",
          router
        );
        set({ excelLoading: false });
      }
    },
  };
});

export default useBuildingsStore;
