import React, { useEffect, useState } from "react";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsPrasadamInnerPagesAll from "./tms-sm-prasadam-inner-pages/tms-prasadam-inner-pages-all";
import TmsPrasadamInnerPagesAnd from "./tms-sm-prasadam-inner-pages/tms-prasadam-inner-pages-and";

const TmsSmPrasadam = ({ router }) => {
  const [active, setActive] = useState("all");

  useEffect(() => {
    let location = router.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_prasadam) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.smd_prasadam_add_new_prasadam)) {
      setActive("Add New Prasadam");
    } else if (location.includes(tmsRouteMap.smd_prasadam_manage_counter)) {
      setActive("all");
      return;
    }
  }, [router.location?.pathname]);
  const location = router.location?.pathname;
  const components = [
    {
      name: "All",
      Element: location.includes(tmsRouteMap.smd_prasadam_manage_counter) ? (
        <TmsCmsSlots type="prasadam-counters" />
      ) : (
        <TmsPrasadamInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_prasadam,
    },

    {
      name: "Add New Prasadam",
      Element: <TmsPrasadamInnerPagesAnd />,
      key: "Add New Prasadam",
      route: tmsRouteMap.smd_prasadam_add_new_prasadam,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmPrasadam);
