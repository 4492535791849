import { Col, Input, Row } from "antd";
import React, { memo } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import "../../assets/css/tms-common/tms-common.scss";
import TmsInput from "../../components/tms-customs/tms-input";
const TmsAddress = ({
  span,
  village,
  setVillage,
  mandal,
  setMandal,
  district,
  setDistrict,
  country,
  setCountry,
  state,
  setState,
  zip,
  setZip,
  type,
  disabled,
}) => {
  return (
    <>
      <TmsInput
        type="text"
        label="Enter Village"
        value={village}
        setValue={setVillage}
        span={span}
        placeholder={"Enter Village"}
        disabled={disabled}
      />

      <TmsInput
        type="text"
        label={type === "donor" ? "City" : "Mandal"}
        value={mandal}
        setValue={setMandal}
        span={span}
        placeholder={"Enter Mandal"}
        disabled={disabled}
      />

      <TmsInput
        type="text"
        label={"District"}
        value={district}
        setValue={setDistrict}
        span={span}
        placeholder={"Enter District"}
        disabled={disabled}
      />
      {disabled ? (
        <>
          <TmsInput
            type="text"
            label={"Country"}
            value={country}
            span={span}
            disabled={disabled}
          />
          <TmsInput
            type="text"
            label={"State"}
            value={state}
            span={span}
            disabled={disabled}
          />
        </>
      ) : (
        <>
          <Col span={span}>
            <Row className={"t-left"}>Country</Row>
            <CountryDropdown
              blankoptionlabel="Select a Country"
              className={
                disabled
                  ? "c-disabled CountryRegionDropDown"
                  : "CountryRegionDropDown"
              }
              style={{
                border: "1px solid #d9d9d9",
                height: "32px",
                fontSize: "14px",
                padding: "0px 8px",
                width: "100%",
              }}
              value={country}
              onChange={(val) => {
                setCountry(val);
              }}
              disabled={disabled ? true : false}
            />
          </Col>
          <Col span={span}>
            <Row className={"t-left"}>State</Row>
            <RegionDropdown
              className={
                disabled
                  ? "c-disabled CountryRegionDropDown"
                  : "CountryRegionDropDown"
              }
              style={{
                border: "1px solid #d9d9d9",
                height: "32px",
                fontSize: "14px",
                padding: "0px 8px",
                width: "100%",
              }}
              country={country}
              value={state}
              showDefaultOption={true}
              blankoptionlabel="Select a State"
              onChange={(val) => {
                setState(val);
              }}
              disabled={disabled ? true : false}
            />
          </Col>
        </>
      )}

      <Col span={span}>
        <Row className={"t-left"}>Pincode</Row>
        <Input
          maxLength={6}
          type="number"
          min="0"
          onKeyDown={(e) =>
            ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
          }
          onWheel={(event) => event.currentTarget.blur()}
          placeholder="Enter Pincode"
          value={zip}
          onChange={(e) => {
            if (e.target.value.length > 6) {
              return;
            }
            setZip(e.target.value);
          }}
          disabled={disabled ? true : false}
        />
      </Col>
    </>
  );
};

export default memo(TmsAddress);
