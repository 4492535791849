import React, { useEffect, useState } from "react";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsEsInnerPagesAll from "./tms-sm-entry-scan-inner-pages/tms-es-inner-pages-all";
import TmsESInnerPagesAND from "./tms-sm-entry-scan-inner-pages/tms-es-inner-pages-and";

const TmsSmEntryScan = ({ router }) => {
  const [active, setActive] = useState("all");

  useEffect(() => {
    let location = router.location?.pathname;
    if (location === tmsRouteMap.serviceManagement_entryScan) {
      setActive("all");
      return;
    } else if (location.includes(tmsRouteMap.sm_entry_scan_add_new)) {
      setActive("Add New Entry Scan");
    } else if (location.includes(tmsRouteMap.sm_entry_scan_manage_counter)) {
      setActive("all");
      return;
    }
  }, [router.location?.pathname]);
  const location = router.location?.pathname;
  const components = [
    {
      name: "All",
      Element: location.includes(tmsRouteMap.sm_entry_scan_manage_counter) ? (
        <TmsCmsSlots type="entry-scan-counters" />
      ) : (
        <TmsEsInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_entryScan,
    },

    {
      name: "Add New Entry Scan",
      Element: <TmsESInnerPagesAND />,
      key: "Add New Entry Scan",
      route: tmsRouteMap.sm_entry_scan_add_new,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};

export default withRouter(TmsSmEntryScan);
