import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Button, Col, Drawer, Switch } from "antd";
import TmsPrasadamService from "../../../../../services/tms-prasadam.service";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmsPrasadaminnerActions from "../../../../../components/tms-service-management/tms-sm-prasadam/tms-sm-prasadam-inner-actions";
import TmsPrasadamInnerPagesAND from "./tms-prasadam-inner-pages-and";
import TextArea from "antd/lib/input/TextArea";

const TmsPrasadamInnerPagesAll = ({ router }) => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prasadamData, setPrasadamData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewPrasadamData, setViewPrasadamData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [termsData, setTermsData] = useState([]);
  const [onlinetermsData, setOnlineTermsData] = useState([]);

  const tableColums = [
    ["Name", viewPrasadamData?.name],
    ["Description", viewPrasadamData?.description],
    ["Price", viewPrasadamData?.price],
    ["Max Items Per Order", viewPrasadamData?.maxItemsPerOrder],
    ["POS-(Status)", viewPrasadamData?.counterEnabled ? "enabled" : "disabled"],
    [
      "Online-(Status)",
      viewPrasadamData?.onlineEnabled ? "enabled" : "disabled",
    ],
  ];

  const switchTrigger = async (key) => {
    setLoading(true);
    try {
      const darData = await TmsPrasadamService.viewPrasadam(key);
      setViewPrasadamData(darData);
      settmsModalVisible(true);
      setEditFlag(true);
      setProductId(key);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    // try {
    //   const prasadamData = await TmsPrasadamService.viewPrasadam(key);
    //   let body = {
    //     name: prasadamData?.name,
    //     description: prasadamData?.description,
    //     counterEnabled:
    //       from === "counter" ? status : prasadamData?.counterEnabled,
    //     onlineEnabled: from === "online" ? status : prasadamData?.onlineEnabled,
    //     price: prasadamData?.price,
    //     imageUrl: prasadamData?.imageUrl,
    //     targetAmount: prasadamData?.targetAmount,
    //     targetDate: moment(prasadamData?.targetDate, "YYYY-MM-DD").toDate(),
    //   };
    //   await TmsPrasadamService.editPrasadam(key, body);
    //   setUpdateFlag(Math.random() + 1);
    //   // console.log(updateFlag);
    // } catch (error) {
    //   catchErrorFunction(
    //     error,
    //     "Token Expired, redirecting to login page in 5 seconds",
    //     router
    //   );
    // }
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    router.location?.pathname === "/service-management/prasadam" &&
      getPrasadams();
    if (!editFlag) {
      settmsModalVisible(false);
    }
  }, [
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  const getPrasadams = async () => {
    setLoading(true);
    try {
      let data = [];
      setPrasadamData([]);

      data = await TmsPrasadamService.getPrasadam(
        tableCurrentPage,
        tablePageSize
      );

      setTabelTotalSize(data?.meta?.totalItems);
      setPrasadamData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Prasadam Name",
      dataIndex: "name",
      key: "name",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price > b.price,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },

    {
      title: "POS-(Status)",
      dataIndex: "counterEnabled",
      key: "counterEnabled",
      render: (text, record) => {
        return (
          <Switch
            size={"small"}
            checked={text}
            style={{ background: text ? "#4CBB17" : "EFEFEF" }}
            onClick={() => {
              switchTrigger(record?.key, !text, "counter");
            }}
          />
        );
      },
    },
    {
      title: "Online-(Status)",

      dataIndex: "onlineEnabled",
      key: "onlineEnabled",
      render: (text, record) => {
        return (
          <Switch
            size={"small"}
            checked={text}
            style={{
              background: text ? "#4CBB17 " : "EFEFEF ",
            }}
            onClick={() => {
              switchTrigger(record?.key, !text, "online");
            }}
          />
        );
      },
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <TmsPrasadaminnerActions
            record={record}
            setLoading={setLoading}
            setViewPrasadamData={setViewPrasadamData}
            settmsModalVisible={settmsModalVisible}
            setUpdateFlag={setUpdateFlag}
            setEditFlag={setEditFlag}
            setProductId={setProductId}
            setShowDrawer={setShowDrawer}
            setTermsData={setTermsData}
            setOnlineTermsData={setOnlineTermsData}
          />
        );
      },
    },
  ];

  return (
    <>
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={prasadamData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <Drawer
        className="DetailsModal"
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewPrasadamData(null);
        }}
        title={editFlag ? "Edit Prasadam" : "Prasadam Details"}
      >
        {editFlag ? (
          <TmsPrasadamInnerPagesAND
            viewPrasadamData={viewPrasadamData}
            editFlag={editFlag}
            productId={productId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
          />
        ) : (
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
            <br />
            <Col className="ImgCol">
              <img src={viewPrasadamData?.imageUrl} alt="Image" />
            </Col>
          </div>
        )}
      </Drawer>
      <Drawer
        className="TermsDrawer"
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setTermsData([]);
          setOnlineTermsData([]);
        }}
        title="Terms & Conditions"
        extra={
          <Button
            type="primary"
            onClick={async () => {
              setLoading(true);
              try {
                await TmsPrasadamService.updateTerms(
                  productId,
                  termsData?.toString()?.replaceAll(",", "\n")?.split("\n"),
                  onlinetermsData
                    ?.toString()
                    ?.replaceAll(",", "\n")
                    ?.split("\n")
                );
                setUpdateFlag(Math.random() + 1);
                setShowDrawer(false);
                setTermsData([]);
                setOnlineTermsData([]);
              } catch (error) {
                catchErrorFunction(
                  error,
                  "Token Expired, redirecting to login page in 5 seconds",
                  router
                );
              }
              setLoading(false);
            }}
          >
            Submit
          </Button>
        }
      >
        POS
        <TextArea
          maxLength={2200}
          rows={5}
          value={termsData?.toString()?.replaceAll(",", "\n")}
          onChange={(e) => {
            setTermsData(e.target.value);
          }}
        />
        <br />
        <br />
        Online
        <TextArea
          maxLength={2200}
          rows={5}
          value={onlinetermsData?.toString()?.replaceAll(",", "\n")}
          onChange={(e) => {
            setOnlineTermsData(e.target.value);
          }}
        />
      </Drawer>
    </>
  );
};

export default withRouter(TmsPrasadamInnerPagesAll);
