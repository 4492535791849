import { Carousel } from "antd";
import React, { memo } from "react";
import {
  tms_annavaram,
  tms_dwaraka_tirumala,
  tms_penu,
  tms_simha,
  tms_slider_1,
  tms_slider_srisailam,
  tms_srikalahasthi,
} from "../../assets/images/tms-image-list";
const TmsCarousel = () => {
  const slides = [
    { slide: tms_slider_srisailam, slide_desc: "Srisailam" },
    { slide: tms_slider_1, slide_desc: "Kanipakam" },
    { slide: tms_srikalahasthi, slide_desc: "Sri Kalahasthi" },
    { slide: tms_dwaraka_tirumala, slide_desc: "Dwaraka Tirumala" },
    { slide: tms_annavaram, slide_desc: "Annavaram" },
    { slide: tms_penu, slide_desc: "Penuganchiprolu" },
    { slide: tms_simha, slide_desc: "Simhachalam" },
  ];
  const contentStyle = {
    height: "100vh",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
    width: "100%",
  };
  return (
    <Carousel
      autoplay
      className="autoplay tms-carousel"
      easing
      effect="fade"
      dots={false}
    >
      {/* <div className="tms-carousel-slide" key={`slider-${1}`}>
        <img src={tms_slider_1} style={contentStyle} alt="" />
        <p className="tms-carousel-inner-text" key={`slider-text-${1}`}>
          {" "}
          {slides[0].slide_desc}
        </p>
      </div> */}
      {slides.map((item, i) => {
        return (
          <div className="tms-carousel-slide" key={`slider-${i}`}>
            <img src={item.slide} style={contentStyle} alt="" />
            <p className="tms-carousel-inner-text" key={`slider-text-${i}`}>
              {" "}
              {item.slide_desc}
            </p>
          </div>
        );
      })}
    </Carousel>
  );
};
export default memo(TmsCarousel);
