import React, { useEffect, useState } from "react";
import TmsTable from "../../../../../components/tms-customs/tms-table";
import { withRouter } from "../../../../../utils/tms-with-router/tms-with-router";
import catchErrorFunction from "../../../../../utils/tms-catch-error/tms-catch-error";
import { Col, Row, Switch, Drawer, Button, Checkbox } from "antd";
import TmsProductsService from "../../../../../services/tms-products.service";
import "../../../../../assets/css/tms-darshanam/tms-d-inner-pages-all.scss";
import TmProductsinnerActions from "../../../../../components/tms-service-management/tms-sm-products/tms-products-inner-pages-actions";
import TextArea from "antd/lib/input/TextArea";
import TmsProductsInnerPagesANP from "./tms-products-inner-pages-anp";

const TmsProductsInnerPagesAll = ({ router }) => {
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [miniTicket, setMiniTicket] = useState(false);
  const [productData, setProductData] = useState([]);
  const [tmsModalVisible, settmsModalVisible] = useState(false);
  const [viewProductData, setViewProductData] = useState();
  const [editFlag, setEditFlag] = useState();
  const [productId, setProductId] = useState();
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [termsData, setTermsData] = useState([]);
  const [onlinetermsData, setOnlineTermsData] = useState([]);

  const tableColums = [
    ["Name", viewProductData?.name],
    ["Product Type", viewProductData?.type],
    ["SKU", viewProductData?.sku],
    ["Description", viewProductData?.description],
    ["POS-(Status)", viewProductData?.counterEnabled ? "enabled" : "disabled"],
    [
      "Online-(Status)",
      viewProductData?.onlineEnabled ? "enabled" : "disabled",
    ],
    ["Price", viewProductData?.price],
    ["GST", viewProductData?.gst],
  ];

  const switchTrigger = async (key) => {
    setLoading(true);
    try {
      const productData = await TmsProductsService.viewProduct(key);
      setViewProductData(productData);
      settmsModalVisible(true);
      setEditFlag(true);
      setProductId(key);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    // try {
    //   const productData = await TmsProductsService.viewProduct(key);
    //   let body = {
    //     name: productData?.name,
    //     description: productData?.description,
    //     counterEnabled:
    //       from === "counter" ? status : productData?.counterEnabled,
    //     onlineEnabled: from === "online" ? status : productData?.onlineEnabled,
    //     price: productData?.price,
    //     imageUrl: productData?.imageUrl,
    //     type: productData.type,
    //     gst: productData?.gst,
    //     sky: productData?.sku,
    //   };
    //   await TmsProductsService.editProduct(key, body);
    //   setUpdateFlag(Math.random() + 1);
    // } catch (error) {
    //   catchErrorFunction(
    //     error,
    //     "Token Expired, redirecting to login page in 5 seconds",
    //     router
    //   );
    // }
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  useEffect(() => {
    router.location?.pathname === "/service-management/products" &&
      getproducts();
    if (!editFlag) {
      settmsModalVisible(false);
    }
  }, [
    updateFlag,
    editFlag,
    tableCurrentPage,
    tablePageSize,
    router.location?.pathname,
  ]);

  const getproducts = async () => {
    setLoading(true);
    try {
      const data = await TmsProductsService.getProducts(
        tableCurrentPage,
        tablePageSize
      );
      setTabelTotalSize(data?.meta?.totalItems);
      setProductData(
        data?.items?.map((item) => {
          return {
            key: item.id,
            ...item,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      //   ...getColumnSearchProps("darshanam_name"),
    },

    {
      title: "Product Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return (
          <div
            key={text}
            style={{
              border:
                text === "GENERAL"
                  ? "1px solid blue"
                  : text === "GENERAL_WITH_QUOTA"
                  ? "1px solid #FC4B35"
                  : "1px solid green",
              padding: "3px 8px",
              margin: "0px 5px 5px 0px",
              borderRadius: "4px",
              width: "fit-content",
              color:
                text === "GENERAL"
                  ? "blue"
                  : text === "GENERAL_WITH_QUOTA"
                  ? "#FC4B35"
                  : "green",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      //   ...getColumnSearchProps("darshanam_name"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price > b.price,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
      sorter: (a, b) => a.price > b.price,
      render: (text) => {
        return `Rs. ${text}`;
      },
    },

    {
      title: "Status",
      children: [
        {
          title: "POS Status",
          dataIndex: "counterEnabled",
          key: "counterEnabled",
          align: "center",
          render: (text, record) => {
            return (
              <Switch
                size={"small"}
                checked={text}
                style={{
                  background: text ? "#4CBB17" : "EFEFEF",
                  textAlign: "center !important",
                }}
                onClick={() => {
                  switchTrigger(record?.key, !text, "counter");
                }}
              />
            );
          },
        },
        {
          title: "Online Status",
          dataIndex: "onlineEnabled",
          key: "onlineEnabled",
          align: "center",

          render: (text, record) => {
            return (
              <Switch
                size={"small"}
                checked={text}
                style={{
                  background: text ? "#4CBB17 " : "EFEFEF ",
                }}
                onClick={() => {
                  switchTrigger(record?.key, !text, "online");
                }}
              />
            );
          },
        },
      ],
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <TmProductsinnerActions
            record={record}
            setLoading={setLoading}
            setViewProductData={setViewProductData}
            settmsModalVisible={settmsModalVisible}
            setUpdateFlag={setUpdateFlag}
            setEditFlag={setEditFlag}
            setProductId={setProductId}
            setShowDrawer={setShowDrawer}
            setTermsData={setTermsData}
            setOnlineTermsData={setOnlineTermsData}
            setMiniTicket={setMiniTicket}
          />
        );
      },
    },
  ];

  return (
    <>
      <TmsTable
        columns={columns}
        loading={loading}
        dataSource={productData}
        handleTableChange={handleTableChange}
        pagination={{
          total: tableTotalSize,
          current: tableCurrentPage,
          pageSize: tablePageSize,
        }}
      />
      <Drawer
        className="DetailsModal"
        width={editFlag ? "800px" : "600px"}
        visible={tmsModalVisible}
        onClose={() => {
          settmsModalVisible(false);
          setEditFlag(false);
          setViewProductData(null);
        }}
        title={editFlag ? "Edit Product" : "Product Details"}
      >
        {editFlag ? (
          <TmsProductsInnerPagesANP
            viewProductData={viewProductData}
            editFlag={editFlag}
            productId={productId}
            setEditFlag={setEditFlag}
            settmsModalVisible={settmsModalVisible}
            width={true}
          />
        ) : (
          <div className="viewDetails">
            <Col style={{ width: "100%" }}>
              <table className="DetailsTable">
                {tableColums.map((item) => {
                  return (
                    <tr key={"table" + item[0]}>
                      <th>{item[0]}</th>
                      <td>{item[1]}</td>
                    </tr>
                  );
                })}
              </table>
            </Col>
            <br />
            <Col className="ImgCol">
              <img src={viewProductData?.imageUrl} alt="Image" />
            </Col>
          </div>
        )}
      </Drawer>
      <Drawer
        className="TermsDrawer"
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setTermsData([]);
          setOnlineTermsData([]);
        }}
        title="Terms & Conditions"
        extra={
          <Button
            type="primary"
            onClick={async () => {
              setLoading(true);
              try {
                await TmsProductsService.updateTerms(
                  productId,
                  termsData?.toString()?.replaceAll(",", "\n")?.split("\n"),
                  onlinetermsData
                    ?.toString()
                    ?.replaceAll(",", "\n")
                    ?.split("\n"),
                  miniTicket
                );
                setUpdateFlag(Math.random() + 1);
                setShowDrawer(false);
                setTermsData([]);
                setOnlineTermsData([]);
                setMiniTicket(false);
              } catch (error) {
                catchErrorFunction(
                  error,
                  "Token Expired, redirecting to login page in 5 seconds",
                  router
                );
              }
              setLoading(false);
            }}
          >
            Submit
          </Button>
        }
      >
        POS
        <TextArea
          maxLength={2200}
          rows={5}
          value={termsData?.toString()?.replaceAll(",", "\n")}
          onChange={(e) => {
            setTermsData(e.target.value);
          }}
        />
        <br />
        <br />
        Online
        <TextArea
          maxLength={2200}
          rows={5}
          value={onlinetermsData?.toString()?.replaceAll(",", "\n")}
          onChange={(e) => {
            setOnlineTermsData(e.target.value);
          }}
        />
        <Row className="formRow" style={{ width: "100%", paddingTop: "10px" }}>
          <Checkbox
            style={{ paddingRight: "10px" }}
            checked={miniTicket}
            onChange={(e) => setMiniTicket(e.target.checked)}
          />
          <label>Mini ticket</label>
        </Row>
      </Drawer>
    </>
  );
};

export default withRouter(TmsProductsInnerPagesAll);
